import { Component } from "react";
import inhand from '../../../img/inhand.png'
import store from '../../../img/store.png'
import money from '../../../img/money.png'
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
// import { API_URL } from "../../constant/API_Settings";
import { useState } from 'react';
import FuelLoginHeaderOperational from './FuelLoginHeader.component';
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";
import NozzleEditModal from './NozzleEditModal.component'
import FuelTabHeader from "./FuelTabHeader.Component";
import { API_URL } from '../../../constant/API_Settings';
import Loader from "../Employee/Loader.component";
import { preventNonNumericalInput } from '../../layouts/Operational_Sidebar_Progress.component'
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import Modal from "react-modal";
import PaginationComponent from '../../layouts/PaginationComponent';
const NozzleModal = (props) => ReactDOM.createPortal(
   <NozzleEditModal  {...props} />, document.body
);
const PaginationComp = (props) => (
   < PaginationComponent  {...props} />
);
const NozzleEdit = (props, progress) => {
   const [userName, setUserName] = useState(localStorage.getItem("PROFILE_NAME"))
   const [list_data, setList] = useState([])
   const [list_data2, setList2] = useState([])
   const [double, setDouble] = useState(true);
   const [dropdownData, setDropdownData] = useState([])
   const [loading, setLoading] = useState(false)
   const [inputValues, setInputValues] = useState({});
   const [success, setSuccess] = useState([])
   const [errors, setErrors] = useState({})
   const [permission, setPermission] = useState([])
   const history = useHistory()




   const [pages, setpages] = useState(
      {
         current_page: 1,
         last_page: 0,
         total_pages: 0,
         pagination_item: [],
         total_pages: 0,
      })
   const [search, setSearch] = useState('')
   const handleSearch = (event) => {
      setSearch(event.target.value)
   }

   //---On Load---//		

   
   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (search !== null || search === "") {
         listNozzleData(1)
        }
      }, 500);
  
      return () => clearTimeout(delayDebounceFn);
    }, []);


   const UseModal = () => {

      const [isShowing, setIsShowing] = useState(false);

      function toggle() {
         if (isShowing) {
            setInputValues({})
            setErrors([''])

         }
         if (!isShowing) {
            setDouble(true)


         }
         setIsShowing(!isShowing);
      }

      return {
         isShowing,
         toggle,
      }
   };
   const handlePagination = (number) => {
      setpages({
         ...pages,
         current_page: number        //---pagination---//
      }
      )

      listNozzleData(number)
   }
   function listNozzleData(page) {
      const requestOptions = {
         method: 'get',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },

      };
      fetch(`${API_URL}/op-nozzle-configuration?search_keyword=${search}&page=${page}`, requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            console.log(responseData.message)
            if (responseData.status == 3) {
               setPermission({ ...permission, status: responseData.status, message: responseData.message })
            }
            setList(responseData.nozzleData.data)
            var datas = []
            responseData.tankList.forEach(elem => {
               datas.push([elem.id, elem.tank_name])
            })
            setDropdownData(datas);

            setpages({
               ...pages,
               current_page: responseData.nozzleData.current_page,
               last_page: responseData.nozzleData.last_page,
               per_pages: responseData.nozzleData.per_page,
               total_pages: responseData.nozzleData.total,

            })

            setLoading(true)
            return responseData;
         })
         .catch(e => {


         });
   }

   const handleInput = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }

   function handleSubmit() {
      setDouble(false);
      const requestOptions = {
         method: 'POST',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify(inputValues)
      };
      fetch(API_URL + "/op-nozzle-configuration", requestOptions)
         .then(response => {
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            if (responseData.status == false) {
               errors["nozzle_name"] = responseData.response.nozzle_name
               errors["opening_reading"] = responseData.response.opening_reading
               errors["tank_name"] = responseData.response.tank_id
               setDouble(true);

            }
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")

               setDouble(true);
            }

            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               history.push("/op-nozzle-edit")
               setErrors([''])
               setInputValues([])
               toggle();
               setDouble(true)
               listNozzleData(pages.current_page);

            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }

   function editNozzle(id) {
      const requestOptions = {
         method: 'get',
         data: { id: id },
         headers: {
            "Access-Control-Allow-Origin": "*",
            "content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },
      };
      fetch(API_URL + "/op-nozzle-configuration/" + id + "/edit", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {

            setInputValues(responseData)

            toggle();

            return responseData;

         })
         .catch(e => {
            console.log(e);
         });
   }
   const [delete_id, deleteId] = useState([])


   function deleteNoz(id) {
      deleteId(id)

      toggleModal()
   }
   const [isOpen, setIsOpen] = useState(false);
   function toggleModal() {
      setIsOpen(!isOpen);
   }
   function deleteNozzle() {
      setDouble(false)
      const requestOptions = {
         method: 'DELETE',

         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },
         body: JSON.stringify(delete_id)
      };
      fetch(API_URL + "/op-nozzle-configuration/" + delete_id, requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")
               toggleModal()
               setDouble(true)
            }
            if (responseData.status === true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               listNozzleData(pages.current_page);
               toggleModal()
               setDouble(true)
            }


            return responseData;


         })

         .catch(err => {

         })
   }



   const { isShowing, toggle } = UseModal();
   return (
      <>
         <div className="main-container page-wrap">
            <div className="d-md-flex">
               <Sidebar_Progress props={props} progress={7} />
               <div className="content-area ">
                  <FuelLoginHeaderOperational props={props} page_title="" />
                  <div className="col-md-11 mt-1"><Link to="/nozzle-employee-list">
	<a href="/nozzle-employee-list" class="float-end btn btn-gray" >Back</a></Link>
	</div>

                  <div className="container pb-3 tab-view">
                     <div className="row mt-md-5 mt-3 form-container">
                        <div className="col-md-12">
                        <div className="form-wrap ">
                           <FuelTabHeader tab={3} />
                           {permission.status == 3 ?
                              <div className="form-wrap mt-5">
                                 <div className="text-center">
                                    {permission.message}
                                 </div></div> :
                              <>

                           {/* <div class="form-wrap "> */}
                           <div>
                                 {loading ? listNozzleData : <Loader />}
                                    {/* <div className="form-wrap "> */}
                                    <div className="tab-content" id="myTabContent" >
                                       <div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
                                    <div class="">
                                       <div class="row mb-3">
                                          <div class="col-md">
                                             <div class="row">
                                                <div class="col-md-2 col pl-0">
                                                   <h3 class="table-title">Nozzle</h3>
                                                </div>
                                                <div class="col-md col pl-0">
                                                   <a href="#" class="btn btn-primary " onClick={toggle}>Add New</a>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-md-4">
                                             <div class="input-group mb-3 search">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                                <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1" placeholder="Search With Nozzle Name " value={search.search_keyword} onChange={handleSearch}  />
                                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={listNozzleData} >Submit</a>

                                             </div>
                                          </div>
                                       </div>
                                       <div class="table-widget">
                                          <table class="table border">
                                             <thead>
                                                <tr>
                                                   <th> Name</th>
                                                   <th> Opening Reading</th>
                                                   <th>Tank Name </th>
                                                   <th>Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {list_data.map(obj => (
                                                   <tr>
                                                      <td>{obj.nozzle_name}</td>
                                                      <td>{obj.opening_reading}</td>
                                                      <td>{obj.tank_name}</td>
                                                      <td><a href="#" class="text-secondary me-2" onClick={() => editNozzle(obj.id)}><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                                                         <a href="#" class="text-secondary" onClick={() => deleteNoz(obj.id)} ><i class="fa fa-trash-o" aria-hidden="true"></i></a></td>
                                                   </tr>
                                                ))}
                                             </tbody>
                                          </table>
                                          {loading ? <PaginationComp
                                             pages={pages}
                                             handlePagination={handlePagination}
                                          /> : ""
                                          }
                                       </div>
                                       <div>

                                       </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                         </>}
                         </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {isShowing ? (<NozzleModal
            isShowing={isShowing}
            hide={toggle}
            double={double}
            preventNonNumericalInput={preventNonNumericalInput}
            dropdownData={dropdownData}
            handleInput={handleInput}
            handleSubmit={handleSubmit}
            inputValues={inputValues}
            errors={errors}

         />) : null
         }
         <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal custommodal"
            overlayClassName="myoverlay"
         >
            <div class="modal-content">
               <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Nozzle</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
               <div class=" modal-body  modal-body"><p>Are you sure you want to delete this Nozzle</p></div>
               <div class="justify-content-end  modal-footer"><div class="d-flex">
                  <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
                  {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteNozzle}>Yes</button> : <button type="submit" class="btn btn-primary" disabled onClick={deleteNozzle}>Yes</button>}
               </div>
               </div>
            </div>




         </Modal>
      </>
   )
}


export default NozzleEdit;

