import React, { useState } from 'react';
import customer from '../../../../img/customer-review.png'

// import { API_URL } from "../../../constant/API_Settings";
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import { useEffect } from 'react';
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import museum from '../../../../img/museum.svg'
import "jspdf-autotable";
import swal from "sweetalert";
import Loader from "../Loader.component"
import Modal from "react-modal";
import { Link } from "react-router-dom";
import logo from '../../../../img/logo.svg';
import { hasData } from 'jquery';
import moment from 'moment';

const DensityConfig = (props) => {

	const [productData, setProduct] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [inputVal, setInputVal] = useState({});
	const [input, setInput] = useState({});
	const [drop, setDrop] = useState([]);
	const [inputdate, setInputDate] = useState({});
	const [TankData, setTankData] = useState([]);
	const [search, setSearch] = useState('')
	const [NozzleData, setNozzleData] = useState([]);
	const [data, setData] = useState([]);
	const history = useHistory()
	const [errors, setErrors] = useState({})
	const [form_nozzle, setFuel] = useState([]);
	const [ReportData, setReportData] = useState([])
	const [Loose, setLoose] = useState([])
	const [Packed, setPacked] = useState([])
	const [Others, setOthers] = useState([])
	const [Sales_sum, setSalesum] = useState([])
	const [Test, setTest] = useState([])
	const [Receipt, setReceipt] = useState([])
	const [inputNozzle, setInputNozzle] = useState({});
	const [inputTank, setInputTank] = useState({});
	const [list_data, setList] = useState([])
	const [delete_id, deleteId] = useState([])
	const [inputFuel, setInputFuel] = useState([])
	const [list, setListSearch] = useState([])
	const [dip, setDip] = useState([])
	const [cummulative, setCummulative] = useState([])
	const [loading, setLoading] = useState(false)
	const [view, setView] = useState([])
	const [gain_loss, setGainLoose] = useState([])
	const [Report, setReport] = useState({})
	const [total_stock, setTotalOpenStock] = useState([])
	const [total, setTotalStock] = useState([])
	const [pump, setPump] = useState([])
    const [permission, setPermission] = useState([])
	const[err,setErr]=useState({})
	//    const[report,setReport]=useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})



	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues({ vehicle_no: [''] })
				setErrors({ vehicle_no: [''] })

			}
			if (!isShowing) {

				setErrors({ vehicle_no: [''] })

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}


	useEffect(() => {

		getProduct();

		const currentYear = new Date().getFullYear()
		var startyear = 2010
		var array = []
		for (var i = startyear; i <= currentYear + 2; i++) {
			array.push([i])
		}

		console.log(array)
		setData({
			...data,
			year: array
		})
	}, [])


	//------------------get fuel name data- to dropdown--------------//
	function getProduct() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};
		fetch(API_URL + "/density", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData)

				if(responseData.status==3)
                {
                    setPermission({...permission,status:responseData.status,message:responseData.message})
                }
				var product_data = []
				responseData.product_data.forEach(elem => {
					product_data.push([elem.product_id, elem.fuel_name, elem.tank_name])
				})
				setProduct(product_data)
				console.log(product_data)
				// setLoading(true)
				//var fuel_name=responseData.product_data[0].fuel_name
				//var tank_name=responseData.product_data[1].tank_name
				//             setInputFuel({
				//                 ...inputFuel,
				// fuel_name:fuel_name,


				//             })
				var pump_name = responseData.pump_data[0].pump_name
				var pump_address = responseData.pump_data[0].pump_address
				setInputValues({
					...inputValues,
					pump_name: pump_name,
					pump_address: pump_address
				})

				setLoading(true)
				return responseData;
			})
			.catch(e => {


			});
	}



	const handleSelect = (e) => {
		var product_id = e.target.value
		var date = e.target.value
		getTank(product_id);
		setInputVal({
			...inputVal,

			[e.target.name]: e.target.value,
			product_id: product_id

		});


	}
	const handletank = (e) => {
		var tank_id = e.target.value
		var tank_name = e.target.value
		setInputValues({
			...inputValues,
			tank_id: tank_id,
			tank_name: tank_name
		})
	}

	function getTank(product_id, ida, e) {
		//console.log(product_id)

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				product_id: product_id,
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get-tank-info", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData)
				var tank_data = []

				//tank_data.push([responseData[0].tank_id,responseData[0].tank_name,responseData[0].fuel_name])
				responseData.tank.forEach(elem => {
					tank_data.push([elem.tank_id, elem.tank_name])
				})

				// var tank_name=responseData.tank[0].tank_name
				var fuel_name = responseData.tank[0].fuel_name
				// var tank_id=responseData.tank[0].tank_id
				setInputNozzle({
					...inputNozzle,
					// tank_name:tank_name,
					fuel_name: fuel_name,
					// tank_id:tank_id

				})
				setTankData(tank_data)
				console.log(tank_data)
				setDrop(tank_data)




				return responseData;
			})
			.catch(e => {

			});
	}
	const handles = (e) => {

		setInputDate({
			...inputdate,
			[e.target.name]: e.target.value
		})
	}

	function getDensityReport() {
		var month = inputdate.month
		var year = inputdate.year
		var date = year + "-" + month + "-" + "01"
		setInputDate({
			...inputdate,
			fro_date: date
		})
		const requestOptions = {
			method: "post",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				product_id: inputVal.product_id,
				tank_id: inputValues.tank_id,
				fro_date: date,
				to_date: inputValues.to_date,
			}),
		};
		//  fetch(API_URL + "/task-remainder", requestOptions)
		fetch(API_URL + "/density-report", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData)
				if (responseData.status == false) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
				}

				// console.log(responseData.pump)
				// console.log(responseData.fuel_tank)
				var unloading = []
				// responseData.unloading.push( {


				// })


				var fuel_name = responseData.product[0].fuel_name
				var tank_name = responseData.product[0].tank_name
				var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

				var date1 = new Date(responseData.unloading[0][0].op_date);
				//var date=responseData.unloading.op_date
				//console.log(date1)
				//date.toLocaleString('default',{month:'long'})
				var month = (monthNames[date1.getMonth()]);
				var year = date1.getFullYear();
				console.log(month)
				setInputValues({
					...inputValues,

					month: month,
					year: year
				})
				setInput({
					...input,
					fuel_name: fuel_name,
					tank_name: tank_name
				})

				responseData.unloading.map(elem => {
					unloading.push({ density: elem.density, e: elem.density_after_unload })
				})

				var datamul = responseData.unloading
				var flat = Array.prototype.concat.apply([], datamul)

				console.log(flat)
				setReportData(flat)
				console.log(unloading)


				var comp1 = responseData.compartments

				if (comp1 = 1) {
					var hmv_after_unload = responseData.unloading[0][0].hmv_after_unload

				}
				else {
					var comp1 = 0
				}
				setReport({
					...Report,
					comp1: comp1,
					hmv_after_unload: hmv_after_unload
				})


				//console.log(pump_name)


				return responseData;

			})
			.catch((e) => {
				console.log(e);
			});
	}

	const handleSubmit = (e) => {
	if (validatedata())
		getDensityReport()

	}
	
	function validatedata(){
		let err=[]
		let hasdata=true
		if(!inputVal.product_id){
			hasdata=false
			err["product"]="Product name is Required"
		}
		if(!inputValues.tank_id)
		{
			hasdata=false
			err["tank"]="Tank name is Required"
		}
		if(!inputdate.month)
		{
			hasdata=false
			err["month"]="Month name is Required"
		}
		if(!inputdate.year)
		{
			hasdata=false
			err["year"]="Year name is Required"
		}
		setErr(err)
		return hasdata
	}

	function printDocument() {

		const requestOptions = {
			method: 'post',
			responseType: 'blob',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				product_id: inputVal.product_id,
				tank_id: inputValues.tank_id,
				fro_date: inputdate.fro_date,
				to_date: inputValues.to_date,
			})
		};
		fetch(API_URL + "/density-download", requestOptions)
			.then(response => { return response.blob(); })
			.then(responseData => {
				var blob = new Blob([responseData]);
				var link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = "density.pdf";
				link.click();

				console.log(responseData)

				// getBalanceSheet();
				// check_url()

				// window.location.reload();

				return responseData;
			})
			.catch(e => {
				console.log(e);
			});

	}
	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={15} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />


						<div class="col-6"></div>
						<div class="col-11 mt-3 text-end">

							<Link to="/op-density-report">
								<a href="op-density-report" className="float-end btn btn-gray">Back</a>
							</Link>
						</div>

						<div class="row mt-3"></div>
						{permission.status==3 ?
										<div  className="form-wrap mt-5">
											<div className="text-center">
											{permission.message}
											</div></div>:
						<div className="container pb-3 mt-5">
							
							{loading ? getProduct : <Loader />}
							<div className=" form-container">

								<div class="form-wrap ">


									<div className="row">
										<div className="col-2"></div>
										<div className="col-1"></div>
										<div className="col-1"></div>


									</div>
									<div class="row col-12">


										<div class="col-2">
											<div className="select-container">
												<select id="3" className="form-control" name="product_id" value={inputVal.product_id} onChange={handleSelect}>
													<option>Products</option>
													{
														productData.map((data) => {

															return <option value={data[0]}>{data[1]}</option>
														})}
												</select>

											</div>
											<div className="errorMsg">{err.product}</div>
										</div>
										<div class="col-2">
											<div className="select-container">
												<select className="form-control" name="tank_id" value={inputValues.tank_id} onChange={handletank}>
													<option>Tanks</option>
													{
														TankData.map((data) => {

															return <option value={data[0]}>{data[1]}</option>
														})}
												</select>

											</div>
											<div className="errorMsg">{err.tank}</div>
										</div>
										<div class="col-2 ">
											<div class="select-container">
												<select id="" class="form-control highlight" name="month" value={inputdate.month} onChange={handles}>
													<option value="select">Month</option>
													<option value="01">January</option>
													<option value="02">February</option>
													<option value="03">March</option>
													<option value="04">April</option>
													<option value="05">May</option>
													<option value="06">June</option>
													<option value="07">July</option>
													<option value="08">August</option>
													<option value="09">September</option>
													<option value="10">October</option>
													<option value="11">November</option>
													<option value="12">December</option>
												</select>
											</div>

											<div className="errorMsg">{err.month}</div>
										</div>
										<div class="col-2 ">
											<div class="select-container">
												<select id="" class="form-control highlight" name="year" value={inputdate.year} onChange={handles}>
													<option value="select">Year</option>
													{data?.year?.map((data) => {

														return <option value={data[0]} >{data[0]}</option>
													})
													}
												</select>
											</div>
											<div className="errorMsg">{err.year}</div>
										</div>

										{console.log(data.year, "df")}

										<div class="col-2 ">
											<a class="btn btn-primary " onClick={handleSubmit} > Submit</a>
										</div>

									</div>
								</div>


							</div>
							<div className="container pb-3">
								<div className="row justify-content-center mt-md-5 mt-3 form-container">
									<div className="col-md-12">

										<div className="form-wrap ">
											<div className="row mb-3">
												<div className="col-md">
													<img src={logo} className="img-fluid float-end " width="100px" />



												</div>

											</div>
											<div className="row mb-3">
												<div className="col-md">
													<h2 className="table-title mb-1">Density</h2>


												</div>

											</div>


											<div className="row sheet-wrp">
												<div className="col-md-12">

													<table className="table table-bordered border sheet-table">
														<thead>
															<tr>
																<th colspan="15" className="text-center ">{inputValues.pump_name}</th>
															</tr>
															<tr>
																<th colspan="15" className="text-center ">{inputValues.pump_address}</th>
															</tr>
															<tr>
																<th colspan="15" className="text-center">Density Record</th>
															</tr>
															<tr>
																<td colspan="4">Product :<b>{input.fuel_name}</b></td>
																<td colspan="5">Tank Name:<b>{input.tank_name}</b></td>
																<td colspan="6">Month & year<br></br><b>{inputValues.month}&{inputValues.year}</b></td>
															</tr>
															<tr>
																<td rowspan="2">Date</td>
																<td colspan="3">Morning Density</td>
																<td rowspan="2">Load nos</td>
																<td colspan="3">TT Receipt</td>
																<td colspan="4">Receipt Density</td>
																<td colspan="3">Density after unloading and dispensing 50 Lts</td>
															</tr>
															<tr>
																<td>Den/Nat</td>
																<td>Temp</td>
																<td>Den 15 C</td>
																<td>Quantity</td>
																<td>Invoice no</td>
																<td>Invoice Density</td>
																{/* {
													
													Report.map((density) => (
													
														
																
<> */}
																<td>comp1</td>
																<td>comp2</td>
																<td>comp3</td>
																<td>comp4</td>
																{/* </>))} */}
																<td>Den/Nat</td>
																<td>Temp</td>
																<td>Den 15 C</td>
															</tr>
														</thead>

														<tbody>
															{

																ReportData.map((density, key) => (



																	<>
																		{console.log(density)}
																		<tr>
																			{/* <td>{density.op_date}</td> */}
																			<td>{moment(density.op_date).format("DD-MM-YYYY")}</td>
																			<td>{density.hmv}</td>
																			<td>{density.temp}</td>
																			<td>{density.density}</td>
																			<td>{density.load_no}</td>
																			<td>{density.quantity}</td>
																			<td>{density.invoice_no}</td>
																			<td>{density.invoice_density}</td>


																			{/* {console.log(st)} */}
																			<td>{density?.compartments?.[0] == 1 || density?.compartments?.[1] == 1 || density?.compartments?.[2] == 1 || density?.compartments?.[3] == 1 ? density.density_after_unload : null}	</td>
																			<td>{density?.compartments?.[0] == 2 || density?.compartments?.[1] == 2 || density?.compartments?.[2] == 2 || density?.compartments?.[3] == 2 ? density.density_after_unload : null}</td>
																			<td>{density?.compartments?.[0] == 3 || density?.compartments?.[1] == 3 || density?.compartments?.[2] == 3 || density?.compartments?.[3] == 3 ? density.density_after_unload : null}</td>
																			<td>{density?.compartments?.[0] == 4 || density?.compartments?.[1] == 4 || density?.compartments?.[2] == 4 || density?.compartments?.[3] == 4 ? density.density_after_unload : null}</td>
																			<td>{density.hmv_after_unload}</td>
																			<td>{density.temp_after_unload}</td>
																			<td>{density.density_after_unload}</td>


																		</tr>

																	</>
																))}





														</tbody>


													</table>
												</div>

											</div>

										</div>
									</div>
									<div className="text-end mt-4"><a className="btn btn-success px-3 mb-md-0 mb-2" onClick={printDocument}> Download Report</a></div>

								</div>

							</div>

						</div>
								}
					</div>

				</div>

			</div>

		</>
	)
}








export default DensityConfig;