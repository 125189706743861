import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const TankEditModal = ({ prevNonNumericalWithDec,preventNonNumericalInput,isShowning, hide,nozzle,dropdownData,fuelId,handleChange,handleInput,double,handleInputs,inputValues,handleChange1,handleSelect,handleNozzleChange,errors,handleTankSubmit }) => {

    return (
        <React.Fragment>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal  modalm">

                    <div class="modal-header mb-3">
                        <h5 class=" text-center" id="staticBackdropLabel">Tank</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                    <div class="row g-3 justify-content-center mb-2">
                    <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Tank name<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " name="tank_name" value={inputValues.tank_name} onChange={handleInput}/>
                         <div className="errorMsg">{errors.tank_name}</div>
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Fuel Type<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         {!fuelId.fuel_id  ?
                        <div class="select-container">
                        <select id="" class="form-control highlight" name="fuel_id" value={inputValues.fuel_id} onChange={handleSelect}>
                        <option value="">Select</option>
                        
                      {
                       dropdownData.map((data) => {
                          return <option value={data[0]}>{data[1]}</option>
                        })
                      }
                      </select>
                      <div className="errorMsg">{errors.fuel_id}</div>
                        </div>

                      :
                        <input type="text"  class="form-control " name="fuel_id" value={fuelId.fuel_name} onChange={handleInput} readOnly/>  
                    }
                     </div>
                   
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Capacity in KL.<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  class="form-control hide-arrow " name="capacity" value={inputValues.capacity} onChange={handleInput} />
                         <div className="errorMsg">{errors.capacity}</div>
                     </div>
                     {/* <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Diameter</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow " name="diameter" value={inputValues.diameter}  onChange={handleInput}/>
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Length</label>
                     </div> */}
                     {/* <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow"  name="length" value={inputValues.length} onChange={handleInput}/>
                     </div> */}
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">No of Nozzles<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                     <input type="number" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="no_of_nozzles" className="form-control" placeholder="No of Nozzles*" min={1} max={10}  onChange={handleInputs} />
                     <div className="errorMsg">{errors.no_of_nozzles}</div>
                     </div>
                     {console.log(nozzle)}
                     {nozzle.map((obj, idx) => {

                        return (
                        <>
                         
                          
                     <div class="col-3 ">
                        <label  class="col-form-label font-weight-bolder">Nozzle Name<sub class="star">*</sub></label>
                     </div>
                     <div class="col-3">
                         <input type="text"  class="form-control " name="nozzle_name" onChange={(e)=>handleNozzleChange(e,idx,e.target.name)} />
                     <div className="errorMsg">{errors.nozzle_name}</div>
                     </div>
                     <div class="col-2">
                        <label  class="col-form-label font-weight-bolder">Reading<sub class="star">*</sub></label>
                     </div>
                     <div class="col-3">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow" name="reading"  onChange={(e)=>handleNozzleChange(e,idx,e.target.name)} />
                     <div className="errorMsg">{errors.reading}</div>

                     </div>
                     </>
                     )})}
                     {/* {!fuelId.fuel_id  ?"":
                     <>
                     <div class="col-6 ">
                        <label  class="col-form-label font-weight-bolder">Current Stock(L)</label>
                     </div>
                     <div class="col-6">
                         <input type="number"  class="form-control " name="current_stock" value={inputValues.current_stock} onChange={handleChange1} />
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Avg/last purchase price/litre</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " name="last_purchase_price" value={inputValues.last_purchase_price} onChange={handleChange}/>
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Selling price /litre</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " name="selling_price" value={inputValues.selling_price} onChange={handleChange} />
                     </div>
                     </>
} */}
                    
                  </div>
                        <div className="modal-footer text-center">

                            <a className="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                           {double==true? <a className="btn btn-primary px-4 w-auto" type="submit" onClick={handleTankSubmit}>SAVE</a>:<a className="btn btn-primary px-4 w-auto" type="submit" disabled>SAVE</a>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}
export default TankEditModal