import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from '../Loader.component'
import Modal from "react-modal";
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import RecoupModal from './RecoupModal.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import DeleteModalConfig from './DeleteModalConfig.component'
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';

const CoupModal = (props) => ReactDOM.createPortal(
	<RecoupModal  {...props} />, document.body
);
const DeleteModal = (props) => ReactDOM.createPortal(
	<DeleteModalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const RecoupConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [input, setInput] = useState([]);
	const [total, setTotal] = useState([])
	const [fields, setFields] = useState([]);
	const history = useHistory()
	const [summary, setSummary] = useState([])
	const [search, setSearch] = useState('')
	const [new_price, setPrice] = useState({})
	const [input2, setInput2] = useState({});
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [double, setDouble] = useState(true);
	const [productData, setProduct] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {
		check_url()

	}, []);
	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput({ ...input, op_date: responseData.op_date })
					getSummerySheet();
					ListRecoup(pages.current_page);
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function ListRecoup(page) {
		const requestOptions = {      //--- listing---//
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(`${API_URL}/recoup?search_keyword=${search}&page=${page}&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setLoading(true);
				setList(responseData.data.data)

				setInput2({ ...input2, isdayclose: responseData.isdayclose })
				var total = responseData.total_amount.toFixed(2)
				setTotal({
					...total,
					totalamount: total,
				})

				var total_pages = responseData.data.last_page



				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})

				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}
	//product name dropdown api integration//
	function getProduct() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(`${API_URL}/get_product_name?op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.fuel_name])
				})

				setProduct(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListRecoup(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			getProduct();
			var isdayclose = input2.isdayclose;
			if (isdayclose == 1) {
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else {
				if (isShowing) {
					setInputValues({})
					setInput([])
					setErrors({})
					setPrice({})
					setSuccess({})
					// setFields([''])
				}
				if (!isShowing) {
					setDouble(true);
					setErrors({});

				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);

	//---onchange functions---//
	const handleInput = (e) => {

		if (e.target.name == "quantity" && new_price.price) {
			var amount = (new_price.price * e.target.value) ? (new_price.price * e.target.value) : ''
			var a = parseFloat(amount) ? parseFloat(amount).toFixed(2) : ''

		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
			amount: a
		});
	};

	const handleSelect = (e) => {
		if (e.target.name == "amount" && new_price.price) {
			var quantity = (e.target.value / new_price.price) ? (e.target.value / new_price.price) : ''
			var q = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : ''
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
			quantity: q
		});

		convertAmountToWords(e, e.target.value)
	};
	const handleInputs = (e) => {


		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,

		});
	};
	const handleChange = (e) => {
		if (e.target.value == "") {
			inputValues.price = ""

		}
		if (e.target.name == "product_id") {
			inputValues.tank_id = ""

		}
		localStorage.setItem("id", e.target.value)
		getPrice()
		setInputValues(inputValues)
		setInput({
			...input,
			[e.target.name]: e.target.value
		});
	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		sendRecoup()
	}

	//price field auto api integration//
	function getPrice() {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: localStorage.getItem("id"),
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};
		fetch(API_URL + "/get-tank", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.data.map(elem => {

					datas.push([elem.id, elem.tank_name])
				})

				setFields(datas);
				var price = responseData.new_price == "" ? '' : responseData.new_price
				localStorage.setItem("price", price)
				var amount = (price * inputValues.quantity) ? (price * inputValues.quantity) : ''
				var a = parseFloat(amount) ? parseFloat(amount).toFixed(2) : ''
				inputValues["amount"] = a

				setPrice({ price: price })
				setInputValues({ ...inputValues })
				var p = document.getElementById("price")
				p.value = price


				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function getPrice1() {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: localStorage.getItem("id"),
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};
		fetch(API_URL + "/get-tank", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.data.map(elem => {

					datas.push([elem.id, elem.tank_name])
				})

				setFields(datas);
				var price = responseData.new_price == "" ? '' : responseData.new_price
				localStorage.setItem("price", price)
				setPrice({ price: price })
				var p = document.getElementById("price")
				p.value = price


				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	//---Create Recoup---//
	function sendRecoup() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				product_id: input.product_id,
				quantity: inputValues.quantity,
				amount: inputValues.amount,
				price: localStorage.getItem("price"),
				tank_id: inputValues.tank_id,
				note: inputValues.note,
				op_date: localStorage.getItem("op_date"),
				emp_id: localStorage.getItem("emp_id"),
				op_emp_id: localStorage.getItem('op_emp_id'),
				id: inputValues.id

			})
		};
		fetch(API_URL + "/recoup", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = {}
				if (responseData.status == false) {
					errors["product_id"] = responseData.response.product_id
					errors["quantity"] = responseData.response.quantity
					errors["amount"] = responseData.response.amount
					errors["tank_id"] = responseData.response.tank_id
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == 12) {
					errors["quantity"] = responseData.message
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/recoup")
					setErrors([''])
					input.product_id = ""
					inputValues.quantity = ""
					inputValues.amount = ""
					inputValues.price = ""
					inputValues.tank_id = ""
					inputValues.note = ""
					inputValues.id = ""
					setInputValues(inputValues)
					setInput(input)
					getSummerySheet()
					toggle();
					setDouble(true)
					ListRecoup(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}
	//---Edit Recoup list---//	
	function edit_Recoup(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id
			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/recoup/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInputValues({
					...inputValues,
					note: responseData.note,
					product_id: responseData.product_id,
					quantity: responseData.quantity,
					price: responseData.price,
					amount: responseData.amount,
					tank_id: responseData.tank_id,
					id: responseData.id ? responseData.id : ''

				})

				setPrice({ price: responseData.price })
				setInput({ ...input, product_id: responseData.product_id })
				localStorage.setItem("id", responseData.product_id)
				getPrice1()
				toggle();
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	//---Delete RecoupList---//	
	function delete_Recoup(id) {

		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}
	}

	function deleteRecoup() {
		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/recoup/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListRecoup(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				console.log(err)
			})
	}

	const [tank_modal, setTankmodal] = useState(false)
	const Tank = () => {

		swal("", "Tank already deleted", false ? "success" : "warning")
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={6} />
									<div className="row">
										{Loading ? ListRecoup :
											// <div class="loader text-primary">Loading...</div>
											<Loader />
										}
									</div>
									<div class="form-wrap ">

										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="fleetcard" role="tabpanel" aria-labelledby="fleetcard-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Recoup </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Recoup</a>
														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

															</div>
														</div>
													</div>


													<div class="table-widget">
														<table class="table border">
															<thead>
																<tr>


																	<th>Product</th>
																	<th>Quantity</th>
																	<th>Price</th>
																	<th>Description</th>
																	<th>Recoup Tank</th>
																	<th >Amount</th>
																	<th>Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(obj => (
																		<tr>
																			<td>{obj.get_product.fuel.fuel_name}</td>
																			<td>{obj.quantity}</td>
																			<td>{obj.price}</td>
																			<td>{obj.note}</td>
																			<td>{obj.get_tank.tank_name}</td>
																			<td>{obj.amount}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>
																			{obj.get_tank.tank_status == 1 ?
																				<td class="py-2">

																					<a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => edit_Recoup(obj.id)} ><i class="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																					{localStorage.getItem("save_hide") == 'false' ?
																						<a href="#" class="text-secondary" type="submit" onClick={() => delete_Recoup(obj.id)}><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																					:''}
																				</td>
																				: ''}
																			{obj.get_tank.tank_status == 2 ?
																				<td class="py-2">

																					<a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={Tank} ><i class="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																					{localStorage.getItem("save_hide") == 'false' ?
																						<a href="#" class="text-secondary" type="submit" onClick={() => delete_Recoup(obj.id)}><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																					:''}
																				</td>
																				: ''}
																		</tr>
																	))}

																<tr>
																	<td colSpan={5} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={5}>
																		{total.totalamount}
																	</td>
																</tr>
															</tbody>
														</table>
														{Loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<CoupModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				input={input}
				inputValues={inputValues}
				fields={fields}
				new_price={new_price}
				success={success}
				double={double}
				handleSelect={handleSelect}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				preventNonNumericalInput={preventNonNumericalInput}
				productData={productData}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				handleInput={handleInput}
				errors={errors}
			/>) : null
			}
			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				double={double}
				deleteRecoup={deleteRecoup}
			/>) : null
			}

		</>
	)
}








export default RecoupConfig