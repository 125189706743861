import { Component } from "react";
import customer_review from '../../img/customer-review.png'
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from 'axios'
import { API_URL } from "../../constant/API_Settings"
import { packing_unit } from "../../constant/constant"
import { selling_mode } from "../../constant/constant"
import swal from "sweetalert";
import { Modal, ModalFooter } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from '../operational-model/Employee/Loader.component'
import Pagination from "react-js-pagination";
class RackProductList extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
       fields:{},
       modalShow: false,
      data:[],
      pagination_items: [],
      lastPage:0,
      totalDatas:1,
      total_pages: 0,
      stock_value:'',
     product_full_name:'',
     search_keyword:'',
      current_Page:1,
      loading:false,
      status:0,
      message:'',
          }
          this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount()
    {
      this.check_url();
   

      var fields ={}
       fields["product_unit"] =''
      // fields["purchase_price"] =0
       this.setState({
         fields:fields
         })
    }

     //------------------------url
 check_url=() =>{
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
			console.log(responseData)
			if(responseData.allowurl == 1)
			{
			this.props.history.push(responseData.url);
      this.callProductList(1);
			}
			else{
				
				this.props.history.goBack();
			}

			return responseData;
		})
		.catch(e => {
		});

}
 
//--------------------------//
    edit_product=(id)=>{
      axios({
        url: API_URL+"/edit-non-fuel-config",
        method: "POST",
        data: {id:id},
        headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
        }
     }).then(res=>{
  
      var fields  =this.state.fields
      fields['id']=res.data.id ? res.data.id : ''
      fields['product_name']=res.data.product_name

      fields['unit']=res.data.unit
      fields['quantity']=res.data.quantity
      fields['product_full_name']=res.data.product_full_name
      fields['selling_mode']=res.data.selling_mode
      fields['total_stock']=res.data.total_stock
      fields['pack_confg']=res.data.pack_confg
      fields['purchase_price']=res.data.purchase_price
      fields['selling_price']=res.data.selling_price
      fields['selling_price_loose']=res.data.selling_price_loose
      fields['margin_price']=res.data.margin_price
      fields['margin_price_loose']=res.data.margin_price_loose
      fields['stock_value']=res.data.stock_value
      this.setState({fields:fields ,modalShow:true
      })

    //  this.setModalShow(true)
  
        }).catch(err=>{
           
        })
       
  }
  handleSelect =(e) =>{
		let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if(e.target.value!=0){
      fields["product_unit"] =  e.target.options[e.target.options.selectedIndex].text
      var product = this.state.fields.product_name+this.state.fields.product_unit+this.state.fields.quantity?this.state.fields.product_name+' '+this.state.fields.product_unit+' '+this.state.fields.quantity:0
      fields["product_full_name"] = product
        }
		this.setState({
			fields: fields
        });
	}
  handleSearch = (event) =>
    {
       this.setState({
        search_keyword: event.target.value
       })
    }
  handleInput1Change =(e) =>{
		let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        
    
		this.setState({
			fields: fields
        });
	}
    
    handlePagination = (number) =>
    {
        this.setState(
            {
                current_Page: number
            }
        ,()=>
        this.callProductList(number))
    }
    
    callProductList = (page) =>
    {
               axios(
            {
                url: API_URL+'/get-rack-product-list?page='+page,
                method: "post",
                data: {
                  search_keyword: this.state.search_keyword
                },
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type":"application/json",
                  "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
                  "Accept": "application/json"
              }
            }
        ).then(response=>
        {
          if(response.data.status===3)
      {
        this.setState({
          status:response.data.status,message:response.data.message
        })
      }
            this.setState(
                {
              
             data:response.data.data,
             current_Page: response.data.current_page,
             lastPage:response.data.last_page,
             total_pages: response.data.total,
             per_page:response.data.per_page,
            totalDatas:response.data.data.total
                }
            )

          
                    this.setState({
                      'loading':true
                    })

        }).catch(error=>
        {
            console.log(error);
        })
    }
    handleInputChange = (event) => {
      let fields = this.state.fields;
       

      if(event.target.name=="stock_value" || event.target.name=="total_stock" || event.target.name=="purchase_price")
      {
        if(event.target.name=="total_stock" || event.target.name=="purchase_price")
        {
          fields[event.target.name] = event.target.value;

          var stock_amount = this.state.fields.total_stock*this.state.fields.purchase_price?this.state.fields.total_stock*this.state.fields.purchase_price:0
          fields["stock_value"] = stock_amount
        
        }

        // else
        // fields["stock_value"] = this.state.fields.total_stock*this.state.fields.purchase_price
      }
      if(event.target.name=="margin_price" || event.target.name=="selling_price" || event.target.name=="purchase_price" ||!event.target.name=="selling_price_loose")
      {
        if(event.target.name=="selling_price" || event.target.name=="purchase_price" ||!event.target.name=="selling_price_loose")
        {
          fields[event.target.name] = event.target.value;
          if(!!this.state.fields.selling_price)
          {
            var stock = this.state.fields.selling_price-this.state.fields.purchase_price?this.state.fields.selling_price-this.state.fields.purchase_price:0
         
          }
          fields["margin_price"] = stock
          
        
        }

        // else
        // fields["stock_value"] = this.state.fields.total_stock*this.state.fields.purchase_price
      }
      if(event.target.name=="margin_price_loose" || event.target.name=="selling_price_loose" || event.target.name=="purchase_price")
      {
        if(event.target.name=="selling_price_loose" || event.target.name=="purchase_price")
        {
          fields[event.target.name] = event.target.value;
          if(!!this.state.fields.selling_price_loose)
          {
          var opt = this.state.fields.selling_price_loose-this.state.fields.purchase_price?this.state.fields.selling_price_loose-this.state.fields.purchase_price:0
          fields["margin_price_loose"] = opt
          }
        
        }

        // else
        // fields["stock_value"] = this.state.fields.total_stock*this.state.fields.purchase_price
      }
      if(event.target.name=="product_full_name" || event.target.name=="product_name" || event.target.name=="unit" ||event.target.name=="quantity")
      {
        if( event.target.name=="product_name" || event.target.name=="unit" ||event.target.name=="quantity")
        {
        fields[event.target.name] = event.target.value;
        var product = this.state.fields.product_name+this.state.fields.product_unit+this.state.fields.quantity?this.state.fields.product_name+' '+this.state.fields.product_unit+' '+this.state.fields.quantity:0
          fields["product_full_name"] = product
      }
    }
      else
      fields[event.target.name] = event.target.value;
      
      this.setState({
          fields: fields,
         
      });   
  
    }
    setModalShow(event,show){
      this.state.fields.product_name=""
      this.state.fields.unit=""
      this.state.fields.quantity=""
      this.state.fields.product_full_name=""
      this.state.fields.selling_mode=""
      this.state.fields.total_stock=""
      this.state.fields.pack_confg=""
      this.state.fields.selling_price=""
      this.state.fields.purchase_price=""
      this.state.fields.selling_price_loose=""
      this.state.fields.margin_price=""
      this.state.fields.margin_price_loose=""
      this.state.fields.stock_value=""
      this.setState({
        modalShow:show
      })
    }
    MyVerticallyCenteredModal = (props) => {
		  

      return (
    <Modal
    {...props}  
  
      >  

     {/* <div className="modal fade " id="edit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-dialog-xl">
      <div className="modal-content"> */}
      	<Modal.Header className="modal-title">
        
          <h5  id="staticBackdropLabel">Non Fuel Product configuration</h5>
          <button type="button" className="btn-close"  onClick={(event)=>this.setModalShow(event,false)}></button>
          </Modal.Header>
          <Modal.Body className=" modal-body ">
      
          <div className="row">
            <div className="col-md-6">
         <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Product name"  name="product_name" value={this.state.fields.product_name}  onChange={this.handleInputChange}/>
       </div>
       {/* <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Packing unit" name="unit" value={this.state.fields.unit}  onChange={this.handleInputChange}/>
       </div> */}
        <div className="form-group mb-3">
         <div className="dropdown">
         <div className="select-container">
       <select
                    className="form-control"
                    onChange={this.handleSelect}
                    name="unit"
                    value={this.state.fields.unit}
                  >
                     <option value="0">Select packing unit</option>
                     <option value="1">Litre</option>
                     <option value="2" >Kilogram</option>
                     <option value="3" >Number</option>
                     </select>
                     </div>
                     </div>
       </div>
       { this.state.fields.unit == 1 &&
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Packing quantity in Litre"name="quantity" value={this.state.fields.quantity}  onChange={this.handleInputChange}/>
       </div>
    }
     { this.state.fields.unit == 2 &&
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Packing quantity in kg"name="quantity" value={this.state.fields.quantity}  onChange={this.handleInputChange}/>
       </div>
    }
      { this.state.fields.unit == 3 &&
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Packing quantity in number"name="quantity" value={this.state.fields.quantity}  onChange={this.handleInputChange}/>
       </div>
    }
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Product Name with unit"name="product_full_name" value={this.state.fields.product_full_name}  onChange={this.handleInputChange} readOnly/>
       </div>
       <div className="form-group mb-3">
         <div className="dropdown">
         <div className="select-container">
       <select
                    className="form-control"
                    onChange={this.handleInputChange}
                    name="selling_mode"
                    value={this.state.fields.selling_mode}
                  >
                     <option value="0">Select Selling Mode</option>
                     <option value="1">Sold same as purchased</option>
                     <option value="2" >Sold in loose quantities only</option>
                     <option value="3" >Sold in same size as well as loose quantities</option>
                     </select>
                     </div>
                     </div>
       </div>
       { this.state.fields.unit == 1 &&
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Total stock in litres"name="total_stock" value={this.state.fields.total_stock} onChange={this.handleInputChange}/>
       </div>
    }
    { this.state.fields.unit == 2 &&
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Total stock in kg"name="total_stock" value={this.state.fields.total_stock} onChange={this.handleInputChange}/>
       </div>
    }
       { this.state.fields.unit == 3 &&
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Total stock in number"name="total_stock" value={this.state.fields.total_stock} onChange={this.handleInputChange}/>
       </div>
    }
  </div>
            <div className="col-md-6">
            {
            this.state.fields.selling_mode==2  &&
       
                <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Pack Configuration" name="pack_confg" value={this.state.fields.pack_confg} onChange={this.handleInputChange}/>
       </div>}
       {
            this.state.fields.selling_mode==3  &&
       
                <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Pack Configuration" name="pack_confg" value={this.state.fields.pack_confg} onChange={this.handleInputChange}/>
       </div>}
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Purchase price of 5L per pack" name="purchase_price" value={this.state.fields.purchase_price} onChange={this.handleInputChange}/>
       </div>
       {
       this.state.fields.selling_mode==1 && 
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Selling price of 5L per pack" name="selling_price" value={this.state.fields.selling_price} onChange={this.handleInputChange}/>
       </div>}
       {
       this.state.fields.selling_mode==3 && 
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Selling price of 5L per pack" name="selling_price" value={this.state.fields.selling_price} onChange={this.handleInputChange}/>
       </div>}
       {
       this.state.fields.selling_mode==2 && 
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Loose Oil Selling Price" name="selling_price_loose" value={this.state.fields.selling_price_loose} onChange={this.handleInputChange}/>
       </div>
    }
      {
       this.state.fields.selling_mode==3 && 
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="Loose Oil Selling Price" name="selling_price_loose" value={this.state.fields.selling_price_loose} onChange={this.handleInputChange}/>
       </div>
    }
       
       {
       this.state.fields.selling_mode==1 &&  
   <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="margin per pack(auto)" name="margin_price" value={this.state.fields.margin_price} onChange={this.handleInput1Change} readOnly />
       </div> 
       }
       {
       this.state.fields.selling_mode==3 && 
      
   <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="margin per pack(auto)" name="margin_price" value={this.state.fields.margin_price} onChange={this.handleInputChange} readOnly />
       </div>}
       {  this.state.fields.selling_mode==2 && 
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="margin per loose LT/KG(auto)" name="margin_price_loose" value={this.state.fields.margin_price_loose} onChange={this.handleInputChange}/>
       </div> }
       {  this.state.fields.selling_mode==3 && 
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="margin per loose LT/KG(auto)" name="margin_price_loose" value={this.state.fields.margin_price_loose} onChange={this.handleInputChange}/>
       </div> }
       <div className="form-group mb-3">
         <input type="text" className="form-control"  placeholder="stock value" name="stock_value" value={this.state.fields.stock_value} onChange={this.handleInputChange} readOnly></input>
       </div>
   
              
            </div>
          </div>
       
        {/* <div className="modal-footer justify-content-end"> */}
        <ModalFooter className="justify-content-center">
           <button type="button" className="btn btn-primary px-4" onClick={this.handleSubmit} >Save</button>
           </ModalFooter>
        {/* </div> */}
      {/* </div>
    </div>
  </div>  */}
   </Modal.Body>
</Modal>
		)}
  sendCreateProduct = () => {
   
    console.log()  
    var user = {
     
      id:this.state.fields.id,
      product_name: this.state.fields.product_name,
      unit: this.state.fields.unit,
      quantity: this.state.fields.quantity,
      product_full_name: this.state.fields.product_full_name,
      selling_mode: this.state.fields.selling_mode,
      total_stock: this.state.fields.total_stock,
      pack_confg: this.state.fields.pack_confg,
      purchase_price: this.state.fields.purchase_price,
      selling_price: this.state.fields.selling_price,
      selling_price_loose:this.state.fields.selling_price_loose,
      margin_price: this.state.fields.margin_price,
      margin_price_loose:this.state.fields.margin_price_loose,
      stock_value: this.state.fields.stock_value,
    };

    console.log(user)

    axios({
      url: API_URL + "/save-non-fuel-config",
      method: "post",
      data: user ,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
    },
    })
      .then((res) => {
        this.setState({
          message: res.data.message,
          status: res.data.status ? "success" : "danger",
          
        });
       
        if(res.data.status===true)
                    {
                       
                    swal("",res.data.message,res.data.status?"success":"danger" )
                    this.props.history.push("/products")
                    this.callProductList(this.state.current_Page);
                    }
                   
        // if (this.state.status === "success") {
        //   this.setState({
        //     modalShow: false,
        //   });
        // }
        // this.setState(
        // {
        //   errors: {}
        // }
        // )
      })
      .catch((error) => {
        console.log(error);
      });
  };

    handleSubmit = (event) => {
      event.preventDefault();
       
      // if (this.validateForm()) {
       
        this.sendCreateProduct();
         this.setState({
           modalShow:false
         })
         this.callProductList(this.state.current_Page);
  
    // }
 
    };
    render()
    {
        return(<>
<div className="main-container page-wrap">
	<div className="d-md-flex">
<Sidebar_Progress />
		<div className="content-area">
            <LoginHeader />

<div className="container pb-3">

	<div className="row justify-content-center mt-3 form-container">
  <div class="col-md-12 mb-3"><Link  to="/rack-config"><a href="/rack-config" class="float-end btn btn-gray">Back</a></Link></div>
		<div className="col-md-12">
			
    {this.state.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{this.state.message}
											</div></div>:
											<>
                      <div>
                      {this.state.loading ? this.callProductList : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={customer_review} width="25px" className="img-fluid" alt=".."/>
						</div>
							<div className="col-md-4 ps-0">
							<h3 className="table-title">PRODUCTS List In RACK</h3>
						</div>
						<div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2"  onClick={(event) => this.props.history.push("/product-rack")}>ADD Products To Rack</a>
						</div>
            {/* <div className="col-md">
            
            </div> */}
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
  <input type="text" className="form-control" placeholder="Search" name="search_keywords" aria-describedby="basic-addon1" value={this.state.search_keyword}  onChange={this.handleSearch} />
  <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={this.check_url} >Submit</a>

</div>
					</div>
				</div>


				<div className="table-widget">
					<table className="table">
  <thead>
    <tr>
      <th>Product Name</th>
      <th>Total Stock</th>
      <th>Main Godown Stock</th>
      <th>Rack Stock</th>
      
    </tr>
  </thead>
  <tbody>
  {
 this.state.data.map((product_list)=>
                            {
                              
                              return  (
     <tr>
      <td>{product_list.product_name}</td>
      <td>{product_list.total_stock}</td>
      <td>{product_list.godown_stock}</td>
      <td>{product_list.rack_total_stock}</td>
    </tr>
            )
          })
      }
</tbody>
</table>
<div className="pagination mt-4 justify-content-end">
                      {this.state.loading ? this.state.total_pages > 10 && <Pagination
										activePage={ this.state.current_Page}
                    itemClass="123"
                    itemsCountPerPage={ this.state.per_page }
                    totalItemsCount={this.state.total_pages || 1}
                    pageRangeDisplayed={10}
                    onChange={this.handlePagination}
										/>:""
										}
                    </div>
				</div>
			</div>
			<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => this.props.history.push("/users")}>Skip/Continue</a></div>
		</div>
    </>}
	</div>
  
 </div>
</div>
		</div>
		
	</div>
</div>
<this.MyVerticallyCenteredModal
                  show={this.state.modalShow}
                  selectedvalue={this.state.selectedvalue}
                  selectedid={this.state.selectedid}
                  onHide={(event) => this.setModalShow()}
                  data={this.state}
                />

</>)
    }
}

export default RackProductList