import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
// import NozzleEmpModal from './NozzleAlloEmplist.component'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import NozzleEmpModal from './NozzleEmpModal.component';
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import NozzleEmpModal from './NozzleEmpModal1.component'
import DesignConfigModal from './Designdemo.component';
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Loader from '../Loader.component'
import { preventNonNumericalInput } from '../../../layouts/Operational_Sidebar_Progress.component';
import moment from 'moment';
const format = 'HH:mm '

const NozzleEmp = (props) => ReactDOM.createPortal(
	<NozzleEmpModal  {...props} />, document.body
);

const NozzleEmpDesign = (props) => ReactDOM.createPortal(
	<DesignConfigModal  {...props} />, document.body
);

const NozzleEmpConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [summary, setSummary] = useState([])


	const [input, setInputs] = useState([])
	const [total, setTotal] = useState([])
	const [single_id, setSid] = useState('')
	const [inputValues, setInputValues] = useState({ vehicle_no: [''] });
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [selling_price, setPrice] = useState({})
	const [product_id, setProduct] = useState({})
	const [opening_reading, setOpeningReading] = useState({})
	const [errors, setErrors] = useState({ vehicle_no: [''] })
	const [double, setDouble] = useState(true);
	const [change, setChange] = useState(true);
	const [changestarttime, setchangestarttime] = useState(true);
	const [changeendtime, setchangeendtime] = useState(true);
	const [timePickerClicked, setTimePickerClicked] = useState(false);
	const [cursorPosition, setCursorPosition] = useState(0);
	const [isTimePickerClosedstart, setIsTimePickerClosedstart] = useState(false);
	const [isTimePickerClosedend, setIsTimePickerClosedend] = useState(false);
	const [startendtap, setstartendtap] = useState(false);
	const [permission, setPermission] = useState([])
	const [mulerror, setMulerror] = useState([]);
	const [Values, setValues] = useState([])
	const [Valuess, setValuess] = useState([])
	const [equaldates, setequaldates] = useState(true);
	const [endDate, setEndDate] = useState('');
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})


		const handleFocus = (event) => {
			console.log(event.target,"cursorPositioncursorPositioncursorPositioncursorPosition")
			if (event.target.selectionStart) {
				
			} 
		};

		const handleBlur = (event) => {
			// alert("bnbnbn")
			
		};
	
	    const handleClose = (i,type) => {
		//  alert(type)
		let Closetime = []
		let change = false;
		let endchange =false;
		Closetime =[...Values]
		let oldtime=[]
		oldtime=[...Valuess]

		console.log(Closetime,"newupcoming")
		console.log(Closetime[i],"oldmmmmmmmmmmtime")
		console.log(oldtime[i]['sent_start_from'],"oldooooooooooootime")
		console.log(oldtime,"oldtime")
		if(Closetime[i]['sent_start_from'] != oldtime[i]['sent_start_from']){
			change = true
			

		}

		if(Closetime[i]['sent_end_from'] != oldtime[i]['sent_end_from']){
			endchange = true
			

		}
		
        // console.log(Closetime[i]["sent_start_from"],"bbbbbbbbbbbbb")
		if(type == "start" && change == true){
			// alert(1)
			setchangestarttime(false)
			setIsTimePickerClosedstart(true)

		}else if(type == "end" && endchange == true ){
			// alert(2)
			setchangeendtime(false)
			setIsTimePickerClosedstart(true)
		}
		// setIsTimePickerClosed(true);
		
		console.log(equaldates,"equaldatesequaldates")
		console.log(type,"equaldatesequaldatesgg")
				  };
				

				
	const handleInput = (value) => {
		console.log(value,"ending")
	
		let endstartdate=inputValues.op_date;
		let endstarttime=(inputValues.show_start_from).format(format);
		let endendDate=inputValues.endDate;
		let endendtime=value.format(format);

		console.log(endstartdate,"endstartdate")
		console.log(endstarttime,"endstarttime")
		console.log(endendDate,"endendDate")
		console.log(endendtime,"endendtime")

		if(endstartdate && endstarttime && endendDate && endendtime){

				let formIsValid = true
				if((endendtime < endstarttime) 
				&& (endendDate == endstartdate)
				){
				
					errors["end_from"] ="On same date,End time should be greater than start time";
					formIsValid = false
					setDouble(true)
					
				}else{
					errors["end_from"]="";
					errors["show_start_from"]="";

				}
				if(formIsValid == true){

	const endtrimmedStartTime = endstarttime.trim();
	const endstartTimeWithSeconds = `${endtrimmedStartTime}:00`;
	const endstartDateTimeString = `${endstartdate}T${endstartTimeWithSeconds}`;
	const endstartDateTime = new Date(endstartDateTimeString);
	console.log(endstartDateTime,"endstartDateTime")

	
	const endtrimmedendtTime = endendtime.trim();
	const endendTimeWithSeconds = `${endtrimmedendtTime}:00`;
	const endendDateTimeString = `${endendDate}T${endendTimeWithSeconds}`;
	const endendDateTime = new Date(endendDateTimeString);
	console.log(endendDateTime,"inputendDateTime")

	const endtimeDifference = Math.abs(endendDateTime - endstartDateTime);
	const endtotalHours = Math.floor(endtimeDifference / (1000 * 60 * 60));
	const endtotalMinutes = Math.floor((endtimeDifference % (1000 * 60 * 60)) / (1000 * 60));
	inputValues['total_hr'] = endtotalHours;
	inputValues['total_min'] = endtotalMinutes;

}else{
		inputValues['total_hr']=0;
		inputValues['total_min'] =0;
	}}

		setInputValues({
			...inputValues,
			end_from: value
		})
		console.log(inputValues,"end")

		

	};
	
	const handlestartInput = (values) => {
		console.log(values,"start")



			
			let inputstartdate=inputValues.op_date;
			let inputstarttime=values.format(format);
			let inputendDate=inputValues.endDate;
			let inputendtime=(inputValues.end_from).format(format);

			console.log(inputstartdate,"inputstartdate")
			console.log(inputstarttime,"inputstarttime")
			console.log(inputendDate,"inputendDate")
			console.log(inputendtime,"inputendtime")

			if(inputstartdate && inputstarttime && inputendDate && inputendtime){

				let formIsValid = true
				if((inputendtime < inputstarttime) 
				&& (inputendDate == inputstartdate)
				){
				
					errors["show_start_from"] ="On the same date, the starttime should come before the endtime.";
					formIsValid = false
					setDouble(true)
					
				}else{
					errors["show_start_from"]="";
					errors["end_from"]="";
				}
				if(formIsValid == true){

		const inputtrimmedStartTime = inputstarttime.trim();
        const inputstartTimeWithSeconds = `${inputtrimmedStartTime}:00`;
		const inputstartDateTimeString = `${inputstartdate}T${inputstartTimeWithSeconds}`;
        const inputstartDateTime = new Date(inputstartDateTimeString);
		console.log(inputstartDateTime,"inputstartDateTime")

		
		const inputtrimmedendtTime = inputendtime.trim();
        const inputendTimeWithSeconds = `${inputtrimmedendtTime}:00`;
		const inputendDateTimeString = `${inputendDate}T${inputendTimeWithSeconds}`;
        const inputendDateTime = new Date(inputendDateTimeString);
		console.log(inputendDateTime,"inputendDateTime")

        const inputtimeDifference = Math.abs(inputendDateTime - inputstartDateTime);
		const inputtotalHours = Math.floor(inputtimeDifference / (1000 * 60 * 60));
		const inputtotalMinutes = Math.floor((inputtimeDifference % (1000 * 60 * 60)) / (1000 * 60));
		inputValues['total_hr'] = inputtotalHours;
		inputValues['total_min'] = inputtotalMinutes;

				}else{
					inputValues['total_hr']=0;
					inputValues['total_min'] =0;
				}}
		setInputValues({
			...inputValues,
			show_start_from: values,
	
		})
	

		// calculatetotalhrs();
	};
	console.log(inputValues,"show_start_fromshow_start_from")
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {

		check_url()
	}, []);


	// useEffect(() => {

	// 	// calculatehours();
	// }, [inputValues]);




	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					//setInputs({ ...input, op_date: responseData.op_date })
					ListNozzleAllocation(pages.current_page, localStorage.getItem('op_date'))
					getSummerySheet()

				}

				return responseData;
			})
			.catch(e => {
			});

	}
	function ListNozzleAllocation(page, date) {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}, body: JSON.stringify({
				op_date: date,
				n_emp_id: localStorage.getItem("op_emp_id"),
				emp_id: localStorage.getItem("emp_id"),
				search_keyword: search
			})
		};
		fetch(`${API_URL}/get_nozzle_allocation?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				else {
					setList(responseData.data.data)

					responseData.data.data.map((obj,index) => {
              if(index==0) {
				setSid(obj.id)
			  }
					
						
					}
					)
					setTotal({
						...total,
						totalamount: responseData.totalamt,
					})

					
					const mla = responseData.mla_coupon_status
					const fleet = responseData.fleet_card_status
					const shift_time = responseData.shift_time

					
					localStorage.setItem('mla_status', mla);
					localStorage.setItem('fleet_status', fleet);
					localStorage.setItem('shift_time', shift_time);

					var total_pages = responseData.data.last_page

					var items = []
					for (let number = 1; number <= total_pages; number++) {
						number === page ? items.push(
							<li key={number} className="page-item active"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,) : items.push(
								<li key={number} className="page-item"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,)
					}

					setpages({
						...pages,
						current_page: responseData.data.current_page,
						last_page: responseData.data.last_page,
						total_pages: responseData.data.last_page,
						pagination_items: items
					})
				}
				
				setLoading(true);
				return responseData;
			})
			.catch(e => {


			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListNozzleAllocation(number, localStorage.getItem('op_date'))
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);
		const [isShowing1, setIsShowing1] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues({})
				setOpeningReading({})
				setErrors({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors({})
				setDouble(true)
			}
			setIsShowing(!isShowing);
		}

		
		function toggle_allnozzle() {
			if (isShowing1) {
				// alert("nnnnnnnnnnnn")
				setInputValues({})
				setOpeningReading({})
				// setErrors({})
				setMulerror([])
				setFields([''])
				setChange(true)
				setchangestarttime(true)
				setchangeendtime(true)
				setIsTimePickerClosedstart(false)
				setIsTimePickerClosedend(false)
				// setIsTimePickerClosed(false)
				setCursorPosition(0);
				
			}
			if (!isShowing1) {

				setErrors({})
				setDouble(true)
			}
			setIsShowing1(!isShowing1);
		}

		return {
			isShowing,
			toggle,
			isShowing1,
			toggle_allnozzle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const { isShowing1, toggle_allnozzle } = UseModal();

	inputValues['product_id'] = product_id.product_id;
	inputValues['current_stock'] = inputValues.current_stock;
	inputValues['price'] = inputValues.price ? inputValues.price: selling_price.price;
	// inputValues['end_from'] = inputValues.end_from;
	// inputValues['sugest_end_from'] = inputValues.sugest_end_from;
	inputValues['nozzle_status'] = inputValues.nozzle_status;
	inputValues['nz_count'] = inputValues.nz_count;
	inputValues['nz_close_reading'] = inputValues.nz_close_reading;
	// inputValues['start_from']=inputValues.start_from;
	// inputValues['show_start_from']=inputValues.show_start_from;


	const handleInputs = (e) => {
		
		let errors = []
		let formIsValid = true
		var quantity_saved = inputValues['quantity'];

		if (e.target.name == "clossing_reading") {
			var quantity = e.target.value - opening_reading.reading ? e.target.value - opening_reading.reading : 0
			if (quantity < 0) {
				quantity = 0;
			}
			
			var q = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : 0;
			if (Number(inputValues['current_stock']) < Number(q)) {
				//	formIsValid = false;
				errors["quantity"] = "Insufficient Quantity of fuel in the tank"
			}
			else {
				errors["quantity"] = ""
			}
			if (((e.target.value) <= (inputValues.b_opening_reading)) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {
				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				var amount = q * inputValues.b_selling_price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0;
			}
			else if ((e.target.value > inputValues.b_opening_reading) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {
				var b_qty = inputValues.b_opening_reading - opening_reading.reading ? inputValues.b_opening_reading - opening_reading.reading : 0
				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				var total_b_qty = inputValues.b_selling_price * b_qty;

				var qty = e.target.value - inputValues.b_opening_reading ? e.target.value - inputValues.b_opening_reading : 0
				var total_qty = selling_price.price * qty;

				var t_amount = total_b_qty + total_qty;
				inputValues['amount'] = parseFloat(t_amount) ? parseFloat(t_amount).toFixed(2) : 0
			}
			else if (selling_price.price) {
				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				var amount = q * selling_price.price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0
			}
			else {
			}
		}
		else if (e.target.name == "amount") {

			if (selling_price.price && inputValues.b_selling_price != '') {

				var amount = e.target.value;
				var q = amount / inputValues.b_selling_price;

				var closereading = Number(opening_reading.reading) + Number(q);

				if (closereading <= inputValues.b_opening_reading) {
					inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
					inputValues['clossing_reading'] = parseFloat(closereading) ? parseFloat(closereading).toFixed(3) : 0;
				}
				else if (closereading > inputValues.b_opening_reading) {
					var o_qty = inputValues.b_opening_reading - opening_reading.reading;

					var o_qty_amount = o_qty * inputValues.b_selling_price;
					var r_amt = amount - o_qty_amount;
					var r_qty = r_amt / selling_price.price;
					var quantity = Number(o_qty) + Number(r_qty);
					var clossing_reading = Number(opening_reading.reading) + Number(o_qty) + Number(r_qty);

					inputValues['quantity'] = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : 0;
					inputValues['clossing_reading'] = parseFloat(clossing_reading) ? parseFloat(clossing_reading).toFixed(3) : 0;

				}
			}
			else if (selling_price.price && inputValues.b_selling_price == '') {

				var amount = e.target.value;
				var q = amount / selling_price.price;

				var closereading = Number(opening_reading.reading) + Number(q);

				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				inputValues['clossing_reading'] = parseFloat(closereading) ? parseFloat(closereading).toFixed(3) : 0;

			}

		}
		else if (e.target.name == "quantity") {

			var closereading = Number(opening_reading.reading) + Number(e.target.value);
			inputValues['clossing_reading'] = parseFloat(closereading) ? parseFloat(closereading).toFixed(3) : 0;


			var quantity = e.target.value;
			var q = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : 0;
			if (Number(inputValues['current_stock']) < Number(q)) {
				//formIsValid = false;
				errors["quantity"] = "Insufficient Quantity of fuel in the tank"
			}
			else {
				errors["quantity"] = ""
			}
			if (((closereading) <= (inputValues.b_opening_reading)) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {

				var amount = q * inputValues.b_selling_price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0
			}
			else if ((closereading > inputValues.b_opening_reading) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {
				var b_qty = inputValues.b_opening_reading - opening_reading.reading ? inputValues.b_opening_reading - opening_reading.reading : 0

				var total_b_qty = inputValues.b_selling_price * b_qty;

				var qty = e.target.value - inputValues.b_opening_reading ? inputValues['clossing_reading'] - inputValues.b_opening_reading : 0

				var total_qty = selling_price.price * qty;

				var t_amount = total_b_qty + total_qty;
				inputValues['amount'] = parseFloat(t_amount) ? parseFloat(t_amount).toFixed(2) : 0
			}
			else if (selling_price.price) {
				var amount = q * selling_price.price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0
			}
			else {
			}
		}

		if (inputValues['end_from'] == null) {
			inputValues['end_from'] = inputValues['sugest_end_from'];
		}
	

		console.log(inputValues.show_start_from,"show_start_fromshow_start_from")


		if(e.target.name == "endDate"){

			
			let startstartdate=inputValues.op_date;
			let startstarttime=(inputValues.show_start_from).format(format);
			let startendDate=e.target.value;
			let startendtime=(inputValues.end_from).format(format);

			console.log(startstartdate,"startstartdate")
			console.log(startstarttime,"startstarttime")
			console.log(startendDate,"startendDate")
			console.log(startendtime,"startendtime")


		const SingletrimmedStartTime = startstarttime.trim();
        const singlestartTimeWithSeconds = `${SingletrimmedStartTime}:00`;
		const singlestartDateTimeString = `${startstartdate}T${singlestartTimeWithSeconds}`;
        const singlestartDateTime = new Date(singlestartDateTimeString);
		console.log(singlestartDateTime,"singlestartDateTime")

		
		const SingletrimmedendtTime = startendtime.trim();
        const singleendTimeWithSeconds = `${SingletrimmedendtTime}:00`;
		const singleendDateTimeString = `${startendDate}T${singleendTimeWithSeconds}`;
        const singleendDateTime = new Date(singleendDateTimeString);
		console.log(singleendDateTime,"singleendDateTime")

        const singletimeDifference = Math.abs(singleendDateTime - singlestartDateTime);
		const singletotalHours = Math.floor(singletimeDifference / (1000 * 60 * 60));
		const singletotalMinutes = Math.floor((singletimeDifference % (1000 * 60 * 60)) / (1000 * 60));
		inputValues['total_hr'] = singletotalHours;
		inputValues['total_min'] = singletotalMinutes;

		// setInputValues({
		// 	...inputValues,
		// 	[e.target.name]: e.target.value
		// });
		// NozzleAmount[i]["total_hr"]=totalHours;
		// NozzleAmount[i]["total_min"]=totalMinutes;
	    // const singleformattedDifference = `${singletotalHours}hr ${singletotalMinutes}min`;
		//  console.log(singleformattedDifference,"singleformattedDifference")
		//  NozzleAmount[i]["total_diff"]=formattedDifference;

		
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});

		
		// calculatetotalhrs();

	};
	
	// function handleInput1() {
	// }
	// function handleAmount() {
	// }



	const handleEndDateChange = (i,event) => {
		setEndDate(event.target.value);
	  };

	const handleInput1 = (i, e) => {

		var product_list = []

		product_list = [...Values]
		console.log(product_list,"default")
		product_list[i][e.target.name]=e.target.value;
		setValues(product_list)
        // console.log(Values,"updated")
		// console.log("name",e.target.name,"value",e.target.value,"index",i)
	};



	const handleAmount =(i,e,type=null) =>{

		
	

		let NozzleAmount = []
		NozzleAmount =[...Values]
		
		
		if(e.target){
			NozzleAmount[i][e.target.name]=e.target.value;
				 	// console.log(e.target.name ,"datefromdatefrom")
			if(e.target.name =='clossing_reading'){
				
				let sub = parseFloat(e.target.value) - parseFloat(NozzleAmount[i]["open_reading"])
				if(sub <0){
					sub=0;
				}
				if(sub >= 0){
					NozzleAmount[i]["quantity"]=Number(sub);
					let amount = sub * NozzleAmount[i]["price"];
					NozzleAmount[i]["amount"] = amount;
				}			
	            console.log(sub,"nini")
				console.log( NozzleAmount[i]["open_reading"],"okkkkkkkkkkkkkkkk")
	     		let array =NozzleAmount;
	    		console.log(array,"Array")
			    let indexvalue =i;
			    console.log(indexvalue,"indexvalue")
		    	let CurrentArrayValue =array[indexvalue];
			    console.log(CurrentArrayValue,"CurrentArrayValue")
			    for (let q = indexvalue+1; q < array.length;q++){
				let NextValue = array[q];
				console.log(NextValue,"NextValue")
				if(NextValue.nozzle_id == CurrentArrayValue.nozzle_id && 
					NextValue.id > CurrentArrayValue.id &&
					 NextValue.nz_count == ''){
					NozzleAmount[q]["open_reading"]= e.target.value;
		            let sub_sub = parseFloat(NozzleAmount[q]["clossing_reading"]) - parseFloat(e.target.value)
				if(sub_sub <0){
					sub_sub=0;
				}
				if(sub_sub >= 0){
					NozzleAmount[q]["quantity"]=Number(sub_sub);
					let amount = sub_sub * NozzleAmount[q]["price"];
					NozzleAmount[q]["amount"] = amount;
				}else{
					NozzleAmount[q]["quantity"] = '';
					NozzleAmount[q]["amount"]   = '';
				}
				
				}


			}
	// /***********/ 



			}
	
			if(e.target.name == 'quantity'){
				let add = parseFloat(e.target.value) + parseFloat(NozzleAmount[i]["open_reading"])
				NozzleAmount[i]["clossing_reading"] = Number(add);
				let amount = e.target.value * NozzleAmount[i]["price"];
				NozzleAmount[i]["amount"] = amount;
				console.log(add,"njnn")

/**************/ 
        let arraiii =NozzleAmount;
         console.log(NozzleAmount,"NozzleAmountoooooooooooo")


        let indexvalue =i;
	    console.log(indexvalue,"indexvalue0000000")

		let CurrentArrayValue =arraiii[indexvalue];
		console.log(CurrentArrayValue,"CurrentArrayValue0000")

		for (let k = indexvalue+1; k < arraiii.length;k++){
			let NextValue = arraiii[k];
			console.log(NextValue,"NextValue")
			if(NextValue.nozzle_id == CurrentArrayValue.nozzle_id && 
				NextValue.id > CurrentArrayValue.id &&
				 NextValue.nz_count == ''){
				
				// NozzleAmount[k]["open_reading"]=;
                if(Number(add)<=0|| isNaN(Number(add))){
					NozzleAmount[k]["open_reading"]=0;

				
				}else{
					NozzleAmount[k]["open_reading"]=Number(add);
				}

				if(NozzleAmount[k]["clossing_reading"] >= NozzleAmount[k]["open_reading"]){
					let sea=NozzleAmount[k]["clossing_reading"]- NozzleAmount[k]["open_reading"]
					NozzleAmount[k]["quantity"] = sea;
					let amou=NozzleAmount[k]["quantity"]*NozzleAmount[k]["price"]
					NozzleAmount[k]["amount"]=amou;
				}else{
				NozzleAmount[k]["quantity"] = '';
				NozzleAmount[k]["amount"]   = '';


			}
				
			


				 }
				}
/***************/ 

			}
	
			if(e.target.name == 'amount'){
						 	// console.log(e.target.name ,"datefromdatefrom")

				let t_price = parseFloat(e.target.value) / parseFloat(NozzleAmount[i]["price"])
				NozzleAmount[i]["quantity"] = parseFloat(t_price).toFixed(2);
				// alert(isNaN(NozzleAmount[i]["quantity"]))
			let CloseRead =	Number(NozzleAmount[i]["quantity"]) + Number(NozzleAmount[i]["open_reading"])
			if(CloseRead < 0){
				NozzleAmount[i]["clossing_reading"] = '';
			}else{
				NozzleAmount[i]["clossing_reading"] = parseFloat(CloseRead);
			}
				
	
				console.log(CloseRead,"kolkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
			}

			let amoi =NozzleAmount;
			console.log(NozzleAmount,"Nozzleamoi")
   
   
		   let indexvalue =i;
		   console.log(indexvalue,"indexamoi")
   
		   let CurrentArrayValue =amoi[indexvalue];
		   console.log(CurrentArrayValue,"Currentamoi")

		   for (let p = indexvalue+1; p< amoi.length;p++){
			let NextValue = amoi[p];
			console.log(NextValue,"NextValue")
			if(NextValue.nozzle_id == CurrentArrayValue.nozzle_id && 
				NextValue.id > CurrentArrayValue.id &&
				 NextValue.nz_count == ''){
				
				// NozzleAmount[p]["open_reading"]=NozzleAmount[i]["clossing_reading"];
				if(NozzleAmount[i]["clossing_reading"] == 0|| isNaN(NozzleAmount[i]["clossing_reading"])){
					NozzleAmount[p]["open_reading"]=0;

				
				}else{
					NozzleAmount[p]["open_reading"]=NozzleAmount[i]["clossing_reading"];
				}
				if(NozzleAmount[p]["clossing_reading"] >= NozzleAmount[p]["open_reading"]){
					let seas=NozzleAmount[p]["clossing_reading"]- NozzleAmount[p]["open_reading"]
					NozzleAmount[p]["quantity"] = seas;
					let amous=NozzleAmount[p]["quantity"]*NozzleAmount[p]["price"]
					NozzleAmount[p]["amount"]=amous;
				}else{
				NozzleAmount[p]["quantity"] = '';
				NozzleAmount[p]["amount"]   = '';


			}
			
			}


		}
		// ..........
		// if(e.target.name =="sugest_end_from"){
		// 	// let datefrom=NozzleAmount;
		// 	console.log(e.target.name ,"datefromdatefrom")
		// }

		// .................
		if(e.target.name =="endDate"){
			// console.log(e.target.name ,"datefromdatefrom")

			let datearray = NozzleAmount;
			console.log(datearray,"datearrayyyyyyyyyyyyy")
            let indexdate =i;
		   console.log(indexdate,"indexdateeeeeeeeeeeeee")
   
		   let Currentindxdate =datearray[indexdate];
		   console.log(Currentindxdate,"Currentindxdateeeeeeeeee")
			
		    

		   for (let u = 0; u < datearray.length; u++) {
			   console.log(u, "datearray.u");
			   console.log(i, "datearray.i");
			   console.log(change, "datearray.changed");
		   
			   let selecteddate = e.target.value;
		   
			   if (
				//    ( datearray[u]["quantity"] == null || datearray[u]["quantity"] == undefined) &&
				     change == true 
					//  && datearray[u]["quantity"] == null || datearray[u]["quantity"] == undefined
					 &&
				   (u != i)
				   && (datearray[u]["end_date"] == null || datearray[u]["end_date"] ==undefined)
			   ) {
				   datearray[u]['endDate'] = selecteddate;
				
				   
				   console.log(datearray,"changedchangedchanged")
			   }
			
			   
		   }
		   setChange(false)
		  
		  
		
		}
	
		
		
	}
		else{
			
			if(type=="show_start_from"){
				
				NozzleAmount[i]["show_start_from"]=e;
				NozzleAmount[i]["sent_start_from"]=e.format(format);
				let datearraystarttime = NozzleAmount;
				console.log(datearraystarttime,"datearraystarttimedatearraystarttime")
				let indexstarttimee =i;
				console.log(indexstarttimee,"indexstarttimeeindexstarttimee")
				let Currentindxstarttime =datearraystarttime[indexstarttimee];
				console.log(Currentindxstarttime,"Currentindxstarttime")
                for (let p = 0; p < datearraystarttime.length; p++) {
					console.log(p, "datearray.p");
					console.log(i, "datearray.i");
					console.log(changestarttime, "datearray.changestarttime");
				console.log(datearraystarttime.length,"datearraystarttime.length")
                let defaultstarttime =e;
				console.log(changestarttime,"1ok")
				console.log(isTimePickerClosedstart,"11ok")
				console.log(datearraystarttime[p]["end_date"],"111ok")
				
				if (
					changestarttime == true &&  isTimePickerClosedstart != true && (p != i)
					   && (datearraystarttime[p]["end_date"] == null || datearraystarttime[p]["end_date"] ==undefined)
				   ) {
					 datearraystarttime[p]['show_start_from'] = defaultstarttime;
					 datearraystarttime[p]["sent_start_from"]=defaultstarttime.format(format);
					   console.log(defaultstarttime,"defaultstarttime")
				   }
				
			   }
			//    setIsTimePickerClosedstart(false)

			//    changestarttime = false;
			//    setchangestarttime(false)
			//    isTimePickerClosed(false)
			//    setClicked(false)
			}
			else if(type == "end_from"){
				// alert("bbbbbbbbbbb")

				NozzleAmount[i]["end_from"]=e;
				NozzleAmount[i]["sent_end_from"] = e.format(format);
				let datearrayendtime = NozzleAmount;

                for (let w = 0; w < datearrayendtime.length; w++) {
					
				if (
					changeendtime == true 
						 &&  
						 isTimePickerClosedend != true &&
						 
					   (w != i)
					   && (datearrayendtime[w]["end_date"] == null || datearrayendtime[w]["end_date"] ==undefined)
				   ) {
					// datearrayendtime[w]['end_from'] = defaultendtime;
					datearrayendtime[w]["end_from"] = e;
					datearrayendtime[w]["sent_end_from"] = e.format(format);
					
					   
					   console.log(e,"defaultendtime")
					   console.log(w,"defaultendtimee")
				   }
				
				   
			   }
			//    setchangeendtime(false)
			//    setClicked(false)
			}
			else if(type=="end"){
				
				NozzleAmount[i]["endDate"]=e;


				
			}
			
			// else{
			// 	NozzleAmount[i]["endDate"]=e;
			// }


		
		


		}
		
		

	/**********/ 
	for (let k=0 ; k< NozzleAmount.length; k++)
	{

	   let startdateeee=NozzleAmount[k]["op_date"]
	   let startTime=NozzleAmount[k]["sent_start_from"]
	   let enddateeee=NozzleAmount[k]["endDate"]
	   let endtimeee=NozzleAmount[k]["sent_end_from"]
	   let formIsValid = true

	   if(startdateeee && startTime && enddateeee && endtimeee){

		   
		   if((NozzleAmount[k]['sent_end_from'] < NozzleAmount[k]['sent_start_from']) 
		   && (NozzleAmount[k]['endDate'] == NozzleAmount[k]['op_date'])
		   ){
		   
			   mulerror[k]['end_from_error'] = "On same date,end time should be greater than start time.";
			   formIsValid = false
			   setDouble(true)
			   
		   }else{
			   mulerror[k]['end_from_error']="";
		   }



		   if(formIsValid == true){

		   
	   
   /****************/ 

	   const trimmedStartTime = startTime.trim();
	   const startTimeWithSeconds = `${trimmedStartTime}:00`;
	   const startDateTimeString = `${startdateeee}T${startTimeWithSeconds}`;
	   const startDateTime = new Date(startDateTimeString);
		
/*********************/ 

/*************/ 

	   const trimmedendTime = endtimeee.trim();
	   const endTimeWithSeconds = `${trimmedendTime}:00`;
	   const endDateTimeString = `${enddateeee}T${endTimeWithSeconds}`;
	   const endDateTime = new Date(endDateTimeString);
	   const timeDifference = Math.abs(endDateTime - startDateTime);
			
	   // if (endtimeee < startTime) {
	   // 	// return { hours: 0, minutes: 0 }; 
	   // 	NozzleAmount[i]["sent_end_from"] = NozzleAmount[i]["sent_start_from"]
	   //   }

	   const totalHours = Math.floor(timeDifference / (1000 * 60 * 60));
	   const totalMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
	   NozzleAmount[k]["total_hr"]=totalHours;
	   NozzleAmount[k]["total_min"]=totalMinutes;
	   const formattedDifference = `${totalHours}hr ${totalMinutes}min`;
		console.log(formattedDifference,"formattedDifference")
		NozzleAmount[k]["total_diff"]=formattedDifference;

/**************/ 
		   }else{
			   NozzleAmount[k]["total_hr"]=0;
			   NozzleAmount[k]["total_min"]=0;
		   }

	   }else{
	   NozzleAmount[k]["total_hr"]=0;
	   NozzleAmount[k]["total_min"]=0;
	   
	   }
	   

/*cal for end*/ 
	}
	/*********/ 
	// multiplevalidationform() 
	console.log(NozzleAmount,"nozz")
		// if(e.target.name =='end_form'){
		// 	let end_time = e.target.value || NozzleAmount[i]['sugest_end_from']
		// 	NozzleAmount[i]['end_from'] = end_time;
		// }
		setTimePickerClicked(true);
        setMulerror(mulerror)
		setValues(NozzleAmount)
		console.log(NozzleAmount,"sent_m")
		console.log(Values,"pppppppppp")
         console.log(timePickerClicked,"setTimePickerClicked")
	}

	function multiplevalidationform(){
	
		let formIsValid = true
		let validationdata=[]
		validationdata =[...Values]
	
		console.log(validationdata,"validationdatavalidationdata")
	
		for (var i = 0; i < validationdata.length; i++) {
			// alert(validationdata[i]['quantity'] == '')
			// alert(isNaN(validationdata[i]["clossing_reading"]) == true )
			if(validationdata[i]['clossing_reading'] == null 
			
			|| validationdata[i]['clossing_reading'] == undefined  || 
			isNaN(validationdata[i]["clossing_reading"]) == true){
	
				mulerror[i]["close_reading_error"] = "Please enter the closing reading"; 
				formIsValid = false 
				setDouble(true)
																											  
			}else if(Number(validationdata[i]['clossing_reading']) < (Number(validationdata[i]["open_reading"]))){
			
				mulerror[i]["close_reading_error"] = "Closing Reading must greater than or equal to Opening Readings"; 
				formIsValid = false 
				setDouble(true)
			}
			else{
	
				mulerror[i]["close_reading_error"] = ""; 
	
			}

	if(validationdata[i]["sent_start_from"] == null || validationdata[i]["sent_start_from"] == undefined || validationdata[i]["sent_start_from"] == ''){
		mulerror[i]['start_from_error'] = "Enter start time";
		formIsValid = false
		setDouble(true)
	}else{
		mulerror[i]['start_from_error']="";
	}

	if(validationdata[i]["sent_end_from"] == null || validationdata[i]["sent_end_from"] == undefined || validationdata[i]["sent_end_from"] == ''){
		mulerror[i]['end_from_error'] = "Enter end time";
		formIsValid = false
		setDouble(true)
	}else{
		mulerror[i]['end_from_error']="";
	}

	if(validationdata[i]['endDate'] != null){
		
		mulerror[i]['end_date_error'] = "";
					
		    if((validationdata[i]['sent_end_from'] < validationdata[i]['sent_start_from']) 
					&& (validationdata[i]['endDate'] == validationdata[i]['op_date'])
					){
					
						mulerror[i]['end_from_error'] = "On same date,end time should be greater than start time.";
						formIsValid = false
						setDouble(true)
						
					}else{
						mulerror[i]['end_from_error']="";
					}
	}else{
		                mulerror[i]['end_date_error'] = "Enter End Date";
						formIsValid = false
						setDouble(true)
	}

	console.log(validationdata[i]['sugest_end_from'],"validationdata[i]['sugest_end_from']")
	console.log(validationdata[i]['show_start_from'],"validationdata[i]['show_start_from']")		 
	
			 if(validationdata[i]['quantity'] == null 
			 
			 || validationdata[i]['quantity'] == undefined || 
			 validationdata[i]['quantity'] < 0 ||
			   isNaN(validationdata[i]["quantity"]) == true){
		
				mulerror[i]['quanity_error'] ="Please enter the Quantity";
				formIsValid = false
				setDouble(true)
				
			 }
			//  else if(validationdata[i]['quantity'] == ''){
			// 	// mulerror[i]['quanity_error'] ="Please enter the Quantity";
			// 	// formIsValid = false
			//  }
			 else{
				mulerror[i]['quanity_error'] ="";
			 }
	
	
			//  alert(validationdata[i]['amount'])
			 if(validationdata[i]['amount'] == null || validationdata[i]['amount'] == undefined || validationdata[i]['amount'] < 0){
				
				mulerror[i]['amount_error'] ="Please enter the amount"
				formIsValid = false
				setDouble(true)
			 }
			//  else if(validationdata[i]['amount'] == ''){
			// 	// mulerror[i]['amount_error'] ="Please enter the amount"
			// 	// formIsValid = false
			//  }
			 else{
				mulerror[i]['amount_error'] ="";
			 }

			 var check_qty = 0;
			 if (Number(validationdata[i]['quantity']) > Number(validationdata[i]['quantity_saved'])) {
				 check_qty = Number(validationdata[i]['quantity']) - Number(validationdata[i]['quantity_saved'])
			 }
			 
			 if (Number(validationdata[i]['current_stock']) < check_qty) {
				 formIsValid = false;
				 setDouble(true)
				 mulerror[i]['current_stock_error'] = "Insufficient Quantity of fuel in the tank"
			 }
			 else {
				mulerror[i]['current_stock_error'] = ""
			 }

            
	
			//  if(Number(validationdata[i]['current_stock']) <(Number( validationdata[i]['quantity']))){
			// 	mulerror[i]['current_stock_error'] ="Insufficient Quantity of fuel in the tank"
			// 	formIsValid=false
			// 	setDouble(true)
			//  }
			//  else{
			// 	mulerror[i]['current_stock_error'] ="";
			//  }
	
	
		console.log(validationdata[i],"okno")
	
		
		}
		console.log(mulerror,"mulerrorarray")
		// setErrors(errors)
		// formIsValid = false
		setMulerror(mulerror)
		setValues(validationdata)
		console.log(mulerror,"error")
		return formIsValid;
	 }
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();

				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}


	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		//event.preventDefault();
		//	alert(2);
		setDouble(false);
		if (validateForm()) {
			sendBankData();
		}


	}
	//---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (Number(inputValues.clossing_reading) < 0) {
			setDouble(true)
			formIsValid = false;
			errors["clossing_reading"] = "Enter Clossing Reading"
		}
		else if (Number(opening_reading.reading) > Number(inputValues.clossing_reading)) {
			formIsValid = false;
			setDouble(true)
			errors["clossing_reading"] = "Closing Reading must greater than Opening Reading"
		}
		else if (inputValues.nz_close_reading && Number(inputValues.clossing_reading) > Number(inputValues.nz_close_reading)) {
			formIsValid = false;
			setDouble(true)
			errors["clossing_reading"] = "Maximum alloved reading";
		}
		else {
			errors["clossing_reading"] = ""
		}
		if (!inputValues.sugest_end_from) {
			formIsValid = false;
			setDouble(true)
			errors["end_from"] = "Enter End Time"
		}
		else {
			errors["end_from"] = ""
		}
		if ((inputValues.quantity == null)) {
			formIsValid = false;
			setDouble(true)
			errors["quantity"] = "Enter Quantity"
		}
		else {
			errors["quantity"] = ""
		}
		var check_qty = 0;
		if (Number(inputValues.quantity) > Number(inputValues.quantity_saved)) {
			check_qty = Number(inputValues.quantity) - Number(inputValues.quantity_saved)
		}
		
		if (Number(inputValues.current_stock) < check_qty) {
			formIsValid = false;
			setDouble(true)
			errors["quantity"] = "Insufficient Quantity of fuel in the tank"
		}
		else {
			errors["quantity"] = ""
		}

		if ((inputValues.amount == null)) {
			formIsValid = false;
			setDouble(true)
			errors["amount"] = "Enter Amount"
		}
		else {
			errors["amount"] = ""
		}


		if((inputValues.show_start_from > inputValues.end_from) 
            && (inputValues.endDate == inputValues.op_date)
		){
			formIsValid = false;
			setDouble(true)
			errors["show_start_from"] ="On the same date, the starttime should come before the endtime.";
		}
		else {
			errors["show_start_from"] = "";
			errors["end_from"]="";
		}

		if((inputValues.end_from < inputValues.show_start_from) 
            && (inputValues.endDate == inputValues.op_date)
		){
			formIsValid = false;
			setDouble(true)
			errors["end_from"] ="On the same date, On same date,end time should be greater than start time.";
		}
		else {
			errors["end_from"] = "";
			errors["show_start_from"] = "";
		}

		// console.log(inputValues.show_start_from ,"inputValues.show_start_from ")
		// console.log( inputValues.end_from ," inputValues.end_from ")
		// console.log(inputValues.endDate  ,"inputValues.endDate  ")
		// console.log(inputValues.op_date ,"inputValues.op_date  ")

	
		if(inputValues.endDate == null){
			formIsValid = false;
			setDouble(true)
			errors["endDate"] = "select end date"
		}
		else {
			errors["endDate"] = ""
		}
		setErrors(errors)

		return formIsValid;
	}

	const handleSubmit1 = (event) => {
		//event.preventDefault();
		
		 setDouble(false);
		if (multiplevalidationform()) {
		
			
			 sendBankDataallnozzle();
			//  alert(123)
		}


	}
	function sendBankData() {
		
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}, body: JSON.stringify({
				amount: parseFloat(inputValues.amount) ? parseFloat(inputValues.amount).toFixed(2) : 0,
				b_opening_reading: inputValues.b_opening_reading,
				b_selling_price: inputValues.b_selling_price,
				clossing_reading: parseFloat(inputValues.clossing_reading) ? parseFloat(inputValues.clossing_reading).toFixed(3) : 0,
				comma: inputValues.comma,
				current_stock: inputValues.current_stock,
				end_from: inputValues.end_from.format(format),
				id: inputValues.id,
				endDate:inputValues.endDate,
				total_hr:inputValues.total_hr,
				total_min:inputValues.total_min,
				op_emp_id: inputValues.op_emp_id,
				price: inputValues.price,
				start_from:inputValues.start_from,
				op_date: localStorage.getItem('op_date'),
				product_id: inputValues.product_id,
				quantity: parseFloat(inputValues.quantity) ? parseFloat(inputValues.quantity).toFixed(2) : 0,
				sugest_end_from: inputValues.sugest_end_from,
				show_start_from:inputValues.show_start_from.format(format),
				
				
			})
		
		};
		fetch(API_URL + "/nozzle_closing", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["amount"] = responseData.response.amount
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/nozzle-closing")
					setDouble(true)
					toggle();
					check_meta_date();
				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

				setDouble(true)
			});
	}

	function sendBankDataallnozzle() {
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}, body: JSON.stringify({
				array:Values
				// amount: parseFloat(inputValues.amount) ? parseFloat(inputValues.amount).toFixed(2) : 0,
				// b_opening_reading: inputValues.b_opening_reading,
				// b_selling_price: inputValues.b_selling_price,
				// clossing_reading: parseFloat(inputValues.clossing_reading) ? parseFloat(inputValues.clossing_reading).toFixed(3) : 0,
				// comma: inputValues.comma,
				// current_stock: inputValues.current_stock,
				// end_from: inputValues.end_from.format(format),
				// id: inputValues.id,
				// op_emp_id: inputValues.op_emp_id,
				// price: inputValues.price,
				// start_from:inputValues.start_from,
				// op_date: localStorage.getItem('op_date'),
				// product_id: inputValues.product_id,
				// quantity: parseFloat(inputValues.quantity) ? parseFloat(inputValues.quantity).toFixed(2) : 0,
				// sugest_end_from: inputValues.sugest_end_from,
				
			})
		
		};


		fetch(API_URL + "/mul_nozzle_closing", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["amount"] = responseData.response.amount
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle_allnozzle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/nozzle-closing")
					setDouble(true)
					toggle_allnozzle();
					check_meta_date();
				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

				setDouble(true)
			});
	}


	
// 	function add_nozzle_clossing_all(nozzle_name=null) {

// alert(single_id);
// 	}
	function add_nozzle_clossing_all() {
		setErrors([])
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}, body: JSON.stringify({
				op_date: localStorage.getItem('op_date'),
				id :single_id,
				nozzle_name: '',
			})
		};
		fetch(API_URL + "/get_all_product_price", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				// if (responseData.status == false) {
				// 	errors["name"] = responseData.response.name
				// }
				// setErrors([''])
				let Nozzle=[]
				let mulerror=[]
				let olddatatime=[]
			
				responseData.forEach((elem, index) => {

					mulerror.push({
						close_reading_error:"",
						quanity_error:"",
						amount_error:"",
						end_from_error:"",
						end_date_error:"",
						start_from_error:"",

					            })

					
					
					
					
					
					
					
					
					const apiDate = elem.op_date;
					const apiDateObj = new Date(apiDate);
				
					apiDateObj.setDate(apiDateObj.getDate() + 2);
					const updatedApiDateISO = apiDateObj.toISOString();

					// Extract the relevant part of the string to match the format of the original apiDate
					const updatedApiDate = updatedApiDateISO.substring(0, 10);
					console.log(updatedApiDate,"okok")


					
					
					if (elem.endDate !== null && 
						elem.endDate !== undefined &&
						elem.sugest_end_from !== null && 
						elem.sugest_end_from !== undefined &&
						elem.op_date !== null && 
						elem.op_date !== undefined &&
						elem.show_start_from !== null && 
						elem.show_start_from !== undefined) {
						 
							let resstartdateeee=elem.op_date
							let resstartTime=elem.show_start_from
							let resenddateeee=elem.end_date?elem.end_date:elem.op_date
							let resendtimeee=elem.sugest_end_from

							{console.log(resstartdateeee,"resstartdateeee")}
							{console.log(resstartTime,"resstartTime")}
							{console.log(resenddateeee,"resenddateeee")}
							{console.log(resendtimeee,"resendtimeee")}
						
							/*************/ 
							
							let formIsValid = true
					 
							if(resstartdateeee && resstartTime && resenddateeee && resendtimeee
								&&((elem.end_date == null || elem.end_date ==undefined))
								){
					 
								
								if((elem.sugest_end_from < elem.show_start_from) 
								&& (elem.endDate == elem.op_date)
								){
								
									mulerror[index]['end_from_error'] = "On same date,end time should be greater than start time.";
									formIsValid = false
									setDouble(true)
									
								}else{
									mulerror[index]['end_from_error']="";
								}
					 
					 
					 
								if(formIsValid == true){
					 
								
							
						/****************/ 
					 
							const restrimmedStartTime = resstartTime.trim();
							const resstartTimeWithSeconds = `${restrimmedStartTime}`;
							const resstartDateTimeString = `${resstartdateeee}T${resstartTimeWithSeconds}`;
							const resstartDateTime = new Date(resstartDateTimeString);
							{console.log(restrimmedStartTime,"restrimmedStartTime")}
							{console.log(resstartTimeWithSeconds,"resstartTimeWithSeconds")}
							{console.log(resstartDateTimeString,"resstartDateTimeString")}
							{console.log(resstartDateTime,"resstartDateTime")}
							 
					 /*********************/ 
					 
					 /*************/ 
					 
							const restrimmedendTime = resendtimeee.trim();
							const resendTimeWithSeconds = `${restrimmedendTime}`;
							const resendDateTimeString = `${resenddateeee}T${resendTimeWithSeconds}`;
							const resendDateTime = new Date(resendDateTimeString);
							const timeDifference = Math.abs(resendDateTime - resstartDateTime);
								 
							// if (endtimeee < startTime) {
							// 	// return { hours: 0, minutes: 0 }; 
							// 	NozzleAmount[i]["sent_end_from"] = NozzleAmount[i]["sent_start_from"]
							//   }
					 
							const restotalHours = Math.floor(timeDifference / (1000 * 60 * 60));
							const restotalMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
							elem.total_hr=restotalHours;
							elem.total_min=restotalMinutes;
							console.log(resendDateTimeString,"restotal")
							console.log(restotalHours,"restotalHours")
							console.log(restotalMinutes,"restotalMinutes")
							// const resformattedDifference = `${restotalHours}hr ${restotalMinutes}min`;
							//  console.log(resformattedDifference,"resformattedDifference")
							//  NozzleAmount[k]["total_diff"]=resformattedDifference;
					 
					 /**************/ 
								}else{
									elem.total_hr=0;
									elem.total_min=0;
								}
					 
							}else{
								elem.total_hr=elem.total_hr != null ? elem.total_hr : 0;
								elem.total_min=elem.total_min != null ? elem.total_min : 0;
							}
					}

					
				
					// const maxDate = nextTwoDays.toISOString().split('T')[0];
					  Nozzle.push({
							end_from:elem.end_from != null ? moment(elem.end_from, 'HH:mm:ss') : null,
						    sent_end_from:elem.end_from != null ? moment(elem.end_from, 'HH:mm:ss').format(format) : moment(elem.sugest_end_from, 'HH:mm:ss').format(format),
			                sugest_end_from:elem.sugest_end_from != '' ? moment(elem.sugest_end_from, 'HH:mm:ss') : null,
							clossing_reading:elem.close_reading,
						    b_opening_reading:elem.b_opening_reading,
						    b_selling_price:elem.b_selling_price,
						    comma:elem.b_selling_price != '' ? ',' : '',
							quantity:elem.quantity,
							quantity_saved: elem.quantity,
							amount:elem.amount,
							op_date: elem.op_date,
							end_date: elem.end_date,
							endDate:elem.end_date == null ? elem.op_date  : elem.end_date,
							product_id:elem.fuel_p_id,
							// price:elem.saved_price,
							current_stock:elem.current_stock,
							nozzle_id:elem.nozzle_id,
							nozzle_status:elem.nozzle_status,
							nz_close_reading:elem.nz_close_reading,
							total_diff:elem.total_diff,
							total_hr:elem.total_hr != null? elem.total_hr :0,
							total_min:elem.total_min !=null? elem.total_min:0,
							// nz_count:elem.nz_count,
							nz_count:elem.nz_count,
							start_from:elem.start_from != '' ? moment(elem.start_from, 'HH:mm:ss') : null,
						    op_emp_id:elem.op_emp_id,
							nozzle_name:elem.nozzle_name,
				            open_reading:elem.opening_reading,
							price: elem.selling_price,
							end_date_max:updatedApiDate,
							id:elem.id,
							show_start_from:elem.show_start_from!= null ? moment(elem.show_start_from, 'HH:mm:ss') : null,
							sent_start_from: elem.show_start_from!= null ? (moment(elem.show_start_from, 'HH:mm:ss')).format(format):(moment(elem.start_from, 'HH:mm:ss')).format(format)
					

					})

					olddatatime.push({
						op_date: elem.op_date,
						show_start_from:elem.show_start_from!= null ? moment(elem.show_start_from, 'HH:mm:ss') : null,
						sent_start_from: elem.show_start_from!= null ? (moment(elem.show_start_from, 'HH:mm:ss')).format(format):(moment(elem.start_from, 'HH:mm:ss')).format(format),
						end_from:elem.end_from != null ? moment(elem.end_from, 'HH:mm:ss') : null,
						sent_end_from:elem.end_from != null ? moment(elem.end_from, 'HH:mm:ss').format(format) : moment(elem.sugest_end_from, 'HH:mm:ss').format(format),
						sugest_end_from:elem.sugest_end_from != '' ? moment(elem.sugest_end_from, 'HH:mm:ss') : null,


					})
                      
					
					


				})
				 console.log(Nozzle,"okkkNozzlenew")
				 console.log(olddatatime,"olddatatimeolddatatime")
				 setMulerror(mulerror)
				 setValues(Nozzle)
				 setValuess(olddatatime)

				 
				 toggle_allnozzle();
				// responseData.foreach((elem.index))


				})

			.catch(e => {

			});
	}


	//---Edit employee list---//	
	function add_nozzle_clossing(id,nozzle_name=null) {
		setErrors([])
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}, body: JSON.stringify({
				op_date: localStorage.getItem('op_date'),
				id: id,
				nozzle_name: nozzle_name,
			})
		};
		fetch(API_URL + "/get_product_price", requestOptions)
			.then(response => {


				return response.json();
			})
			.then(responseData => {


				if (responseData.endDate !== null && 
					responseData.endDate !== undefined &&
					responseData.sugest_end_from !== null && 
					responseData.sugest_end_from !== undefined &&
					responseData.op_date !== null && 
					responseData.op_date !== undefined &&
					responseData.show_start_from !== null && 
					responseData.show_start_from !== undefined) {
					 
						let resstartdateeee=responseData.op_date
						let resstartTime=responseData.show_start_from
						let resenddateeee=responseData.end_date?responseData.end_date:responseData.op_date
						let resendtimeee=responseData.sugest_end_from

						{console.log(resstartdateeee,"resstartdateeee")}
						{console.log(resstartTime,"resstartTime")}
						{console.log(resenddateeee,"resenddateeee")}
						{console.log(resendtimeee,"resendtimeee")}
					
						/*************/ 
						
						let formIsValid = true
				 
						if(resstartdateeee && resstartTime && resenddateeee && resendtimeee
							&&((responseData.end_date == null || responseData.end_date ==undefined))
							){
				//  alert(1)
							
							if((responseData.sugest_end_from < responseData.show_start_from) 
							&& (responseData.endDate == responseData.op_date)
							){
								errors["end_from"] ="On same date,End time should be greater than start time";
								formIsValid = false
								setDouble(true)
								
							}else{
								errors["end_from"]="";
							}
				 
				 
				 
							if(formIsValid == true){
				 
							
						
					/****************/ 
				 
						const restrimmedStartTime = resstartTime.trim();
						const resstartTimeWithSeconds = `${restrimmedStartTime}`;
						const resstartDateTimeString = `${resstartdateeee}T${resstartTimeWithSeconds}`;
						const resstartDateTime = new Date(resstartDateTimeString);
						{console.log(restrimmedStartTime,"restrimmedStartTime")}
						{console.log(resstartTimeWithSeconds,"resstartTimeWithSeconds")}
						{console.log(resstartDateTimeString,"resstartDateTimeString")}
						{console.log(resstartDateTime,"resstartDateTime")}
						 
				 /*********************/ 
				 
				 /*************/ 
				 
						const restrimmedendTime = resendtimeee.trim();
						const resendTimeWithSeconds = `${restrimmedendTime}`;
						const resendDateTimeString = `${resenddateeee}T${resendTimeWithSeconds}`;
						const resendDateTime = new Date(resendDateTimeString);
						const timeDifference = Math.abs(resendDateTime - resstartDateTime);
							 
						// if (endtimeee < startTime) {
						// 	// return { hours: 0, minutes: 0 }; 
						// 	NozzleAmount[i]["sent_end_from"] = NozzleAmount[i]["sent_start_from"]
						//   }
				 
						const restotalHours = Math.floor(timeDifference / (1000 * 60 * 60));
						const restotalMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
						responseData.total_hr=restotalHours;
						responseData.total_min=restotalMinutes;
						console.log(restotalHours,"restotalHours")
						console.log(restotalMinutes,"restotalMinutes")
						// const resformattedDifference = `${restotalHours}hr ${restotalMinutes}min`;
						//  console.log(resformattedDifference,"resformattedDifference")
						//  NozzleAmount[k]["total_diff"]=resformattedDifference;
				 
				 /**************/ 
							}else{
								responseData.total_hr=0;
								responseData.total_min=0;
							}
				 
						}else{
							responseData.total_hr=responseData.total_hr != null ? responseData.total_hr : 0;
							responseData.total_min=responseData.total_min != null ? responseData.total_min : 0;
						}
				}


				const apiDatee = responseData.op_date;
				const apiDateeObj = new Date(apiDatee);
			
				apiDateeObj.setDate(apiDateeObj.getDate() + 2);
				const updatedApiDateeISO = apiDateeObj.toISOString();

				// // Extract the relevant part of the string to match the format of the original apiDate
				const updatedApiDatee = updatedApiDateeISO.substring(0, 10);
				// alert(1)
				// {console.log(updatedApiDatee,"take")}

				if (responseData.status == false) {
				errors["name"] = responseData.response.name
				}

				setErrors([''])
				setInputValues({
					...inputValues,
					id: id,
					end_from: responseData.end_from != null ? moment(responseData.end_from, 'HH:mm:ss') : null,
					sugest_end_from: responseData.sugest_end_from != '' ? moment(responseData.sugest_end_from, 'HH:mm:ss') : null,
					clossing_reading: responseData.close_reading,
					b_opening_reading: responseData.b_opening_reading,
					b_selling_price: responseData.b_selling_price,
					comma: responseData.b_selling_price != '' ? ',' : '',
					quantity: responseData.quantity,
					quantity_saved: responseData.quantity,
					amount: responseData.amount,
					price: responseData.saved_price,
					end_datee_max:updatedApiDatee,
					current_stock: responseData.current_stock,
					nozzle_status: responseData.nozzle_status,
					op_date:responseData.op_date,
					// endDate: responseData.op_date,
					// endDate:endDate != null ? responseData.endDate : null,
					endDate:responseData.end_date == null ? responseData.op_date  : responseData.end_date,
					nz_close_reading: responseData.nz_close_reading,
					nz_count: responseData.nz_count,
					total_hr:responseData.total_hr != null? responseData.total_hr :0,
					total_min:responseData.total_min !=null? responseData.total_min:0,
					total_diff:responseData.total_diff,
					// total_hr:responseData.total_hr != null? responseData.total_hr :0,
					// total_min:responseData.total_min !=null? responseData.total_min:0,
					show_start_from:responseData.show_start_from != '' ? moment(responseData.show_start_from, 'HH:mm:ss') : null,
					start_from:responseData.start_from != '' ? moment(responseData.start_from, 'HH:mm:ss') : null,
					op_emp_id: localStorage.getItem('op_emp_id')
				})
				const nozzle_name=responseData.nozzle_name
				const open_reading=responseData.opening_reading
				localStorage.setItem('nozzle_name',nozzle_name)
				localStorage.setItem('opening_reading',open_reading)
				setPrice({ price: responseData.selling_price })
				setProduct({ product_id: responseData.fuel_p_id })
				setOpeningReading({ reading: responseData.opening_reading })
				setErrors(errors)
				toggle();
				return responseData;
			})
			.catch(e => {

			});
	}

	function calculatehours() {
 
		// alert(1)
		console.log(inputValues,"inputValueslllllllllll")
	}

console.log(inputValues,"main single arrrrrri")

	///////////////

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={13} />
									<div className="col-5">
										<div className="row">
											{Loading ? ListNozzleAllocation :
												// <div class="loader text-primary">Loading...</div>
												<Loader />
											}
										</div>
									</div>
									<div class="form-wrap ">




										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="nozzleclosing" role="tabpanel" aria-labelledby="nozzleclosing-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Nozzle Closing ({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h3>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>
																{/* <div className="col-md-12 mb-2"><Link to="/newnozzle-closing"><a href="newnozzle-closing" className="btn btn-primary bt-search btn-sm">CLOSE ALL NOZZLE</a></Link></div> */}
																<div className="col-md-12 mb-2" onClick={() => add_nozzle_clossing_all()}><a className="btn btn-primary bt-search btn-sm" >CLOSE ALL NOZZLE</a></div>

															</div>
														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch}></input>
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
															</div>

														</div>
													</div>


													<div class="table-widget">
														<table class="table border">
															<thead>
																<tr>
																	<th>Nozzle Name</th>
																	<th className="col-2">Start Time</th>
																	<th className="col-2">End Time</th>
																	<th>Opening Reading</th>
																	<th>Closing Reading</th>
																	<th >Quantity in Lt.</th>
																	<th>Price</th>
																	<th>Operation Date</th>
																	<th>Amount</th>
																	<th>Action</th>
																</tr>
															</thead>

															<tbody>
																{
																	list_data.map((obj) => (
																		<tr>
																			<td>{obj.nozzle_name}</td>
																			<td>{moment(obj.start_from, 'HH:mm:ss').format('hh:mm A')}</td>
																			<td >{obj.end_from == null ? "" : moment(obj.end_from, 'HH:mm:ss').format('hh:mm A')}</td>
																			<td>{obj.opening_reading}</td>
																			<td>{obj.close_reading}</td>
																			<td>{obj.quantity}</td>
																			<td>{obj.price}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>
																			<td>{obj.amount}</td>
																			<td><div className="d-flex icon-btn">
																				<button onClick={() => add_nozzle_clossing(obj.id,obj.nozzle_name)}>  <i className="fa fa-ellipsis-h" aria-hidden="true"></i></button>
																			</div></td>
																		</tr>

																	))}
																<tr>
																	<td colSpan={8} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={2}>{total.totalamount}</td>
																</tr>

															</tbody>
														</table>
														<nav aria-label="Page navigation example">
															<ul className="pagination justify-content-end">
																{pages.total_pages > 1 && pages.pagination_items}
															</ul>
														</nav>

													</div>

												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			{isShowing ? (<NozzleEmp
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				handleInput={handleInput}
				handlestartInput={handlestartInput}
				preventNonNumericalInput={preventNonNumericalInput}
				inputValues={inputValues}
				fields={fields}
				double={double}
				handleSubmit={handleSubmit}
				errors={errors}
			/>) : null
			}

          {isShowing1 ? (<NozzleEmpDesign
				isShowing1={isShowing1}
				hide={toggle_allnozzle}
				handleInputs={handleInputs}
				handleInput={handleInput}
				handleClose={handleClose}
				endDate={endDate}
				handleEndDateChange={handleEndDateChange}
				handleInput1={handleInput1}
				preventNonNumericalInput={preventNonNumericalInput}
				inputValues={inputValues}
				Values={Values}
				fields={fields}
				double={double}
				mulerror={mulerror}
				handleFocus={handleFocus}
				handleBlur={handleBlur}
				setTimePickerClicked={setTimePickerClicked}
				handleSubmit1={handleSubmit1}
				handleAmount={handleAmount}
				errors={errors}
			/>) : null
			}
		</>
	)
}








export default NozzleEmpConfig