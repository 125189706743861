import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import swal from 'sweetalert';
import {preventNonNumericalInput,prevNonNumericalWithDec} from "../../../layouts/Operational_Sidebar_Progress.component";

const NonFuelProductPurchaseModal = ({ isShowing,  hide, invoice_data, double,edit_list, dropdowns, setVehicle, errors, success, startDate, inputValues, handleSelects, handleSelect, handleinputdata, handleInvoice, handleAdd, handleRemove, handleChange, fields, dropdownData, handleInput, handleSubmit, handleInputs }) => {

  var today = new Date().toISOString().split('T')[0];
  const handleSubmit1 = () => {
    swal("","After approval edit is not possible","warning")
    };
  return (
    <React.Fragment>


      <div class="container pb-3">
        <div class="row mb-3 justify-content-center">
          <div className="modal-overlay" />
          <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal modalxl col-12 ">
              <div class="modal-header mb-3">
                <h5 class=" text-center" id="staticBackdropLabel"></h5>
                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="container pb-3">
                <div class="row mt-md-5 mt-3 form-container justify-content-center">
                  <div class="col-md-9">
                    <div class="">
                      <div class="row mb-3 justify-content-center">
                        <div class="col-md-12 col-12 pl-0 mb-4">
                          <h3 class="table-title text-center">Purchase Return of Non Fuel Products</h3>
                        </div>
                        <div class="col-md-12">
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Invoice Number<sub class="star">*</sub></label>
                            <div class="select-container">
                              <select id="" className="form-control highlight" name="nf_purchase_id" value={inputValues.nf_purchase_id} onChange={handleInvoice}>
                                <option value="">Select</option>
                                {invoice_data.map((data) => {
                                  return <option value={data[0]}>{data[1]}</option>
                                })
                                }
                              </select>
                            </div>
                            <div className="errorMsg">{errors.invoice_number}</div>
                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Product Name<sub class="star">*</sub></label>
                            <div class="select-container">
                              <select id="" className="form-control highlight" name="product_id" value={inputValues.product_id} onChange={handleSelects}>
                                <option value="">Select</option>
                                {dropdowns.map((data) => {
                                  return <option value={data[0]}>{data[1]}</option>
                                })
                                }
                              </select>
                            </div>
                            <div className="errorMsg">{errors.product_id}</div>
                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Purchase Price</label>
                            <input type="text" className="form-control highlight" name="purchase_price" value={inputValues.purchase_price} onChange={handleInputs} readOnly />
                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Quantity<sub class="star">*</sub></label>
                            <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow highlight" name="quantity" value={inputValues.quantity} onChange={handleInput} />
                            <div className="errorMsg">{errors.quantity}</div>
                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Value</label>
                            <input type="number" className="form-control highlight" name="value" value={inputValues.value} onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} onChange={handleInputs} />

                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Total Return Value<sub class="star">*</sub></label>

                            <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow highlight" name="total_return_value" value={inputValues.total_return_value} onChange={handleinputdata} />
                            <div className="errorMsg">{errors.total_return_value}</div>

                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Vendor Name<sub class="star">*</sub></label>
                            <div class="select-container">
                              <select id="" class="form-control highlight" name="vendor_id" value={inputValues.vendor_id} onChange={handleSelect} >
                                <option>select</option>

                                {dropdownData.map((data) => {
                                  return <option value={data[0]}>{data[1]}</option>
                                })
                                }

                              </select>
                            </div>
                            <div className="errorMsg">{errors.vendor_id}</div>
                          </div>
                          <div class="form-row mb-2">
                            <label class="text-secondary mb-2">Date<sub class="star">*</sub></label>

                            <input type="date" className="form-control highlight" name="op_date" id={"m_op_date"}  min={startDate} max={today}  value={inputValues.op_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :inputValues.op_date} onChange={handleinputdata} />
                            <div className="errorMsg">{errors.op_date}</div>
                          </div>
                          <div class="modal-footer text-center">

                            <a href="#" class="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                            {double === true ? edit_list.status != 3 ? <a href="#" class="btn btn-success px-5" onClick={ handleSubmit}>save </a>: <a href="#" class="btn btn-success px-5" onClick={(event) => handleSubmit1(event)}>save </a>:<a href="#" class="btn btn-success px-5" disabled >save</a> }

                            {/* {double === true ? <a href="#" class="btn btn-primary  w-auto " onClick={handleSubmit} >Save</a> : <a href="#" class="btn btn-primary  w-auto " disabled>Save</a>} */}
                          </div>
                        </div>


                      </div>


                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>




    </React.Fragment>

  );
}
export default NonFuelProductPurchaseModal