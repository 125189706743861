import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import Select from "react-select";
const CashPaymentModal = ({ preventNonNumericalInput,prevNonNumericalWithDec,prevDec,isShowing, hide,manager,selected_options, setVehicle,double, handleInput,errors,handleSelect,outstanding,cashbalance, denm,success, input,inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs,denomination,startDate, }) => {

    return (
        <React.Fragment>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modal-dialog-xl modalxl">

                    <div class="modal-header mb-3">
                        <h5 class=" text-center" id="staticBackdropLabel">Cash Payment</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div class="">
                          
                        <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-3">
                                        <label  class="col-form-label mx-2">Account Name<sub class="star">*</sub></label>
                                      </div>
                                      <div class="col-6">
                                      
                                        <Fragment>
                                    <Select name="from_ac_name_slug" options={dropdownData} defaultValue={selected_options} onChange={handleSelect}/>
                                    </Fragment>
                                      

                                        <div className="errorMsg">{errors.ac_name_slug}</div>
                                      </div>
                                      <div class="col-3"></div>
                                      </div>
                                      <div class="row g-3 justify-content-center mb-2">
                                        <div className="col-3">
                                            <label className="col-form-label mx-2">Description <sub class="star">*</sub></label>
                                        </div>
                                        <div className="col-3">
                                            <textarea type="text" className="form-control highlight" name="note" value={input.note} onChange={handleInputs}></textarea>
                                            <div className="errorMsg">{errors.note}</div>

                                        </div>
                                                                    
                                        {manager.ismanager == 1? 
                                        <div className="col-6">
                                            <div className="accordion denomination " id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button btn-success collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            Denomination
                                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse accordion-overlay collapse" aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionExample" >
                                                        <div class="accordion-body">
                                                            <div class="table-widget">
                                                                <table class="table border-0">
                                                                    <tbody>
                                                                    { denm.map((obj,idx)=>{
                                                                  console.log(obj.amount)
                                                                   return (
                                                                     
                                                                  
                                                                  <tr>
                                                                   
                                                                  <td ><input type="text" class="form-control highlight elem" name="elem" value={obj.amount} id={"amount" + idx} onChange={ event=> {handleChange(event,idx)}}/></td>
                                                                  <td>*</td>
                                                                  <td><input type="number" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} min="0" class="form-control highlight hide-arrow" name="change" value={denomination[idx] ? denomination[idx]['count'] : '' } id={"change" + idx} onChange={ event=> {handleChange(event,idx)}}/></td>
                                                                  <td>=</td>
                                                                  <td width="110px"><strong><input type="text" readOnly class="form-control highlight" name="multiple" id={"multiple" + idx} value={denomination[idx] ? denomination[idx]['multiple'] : '' } onChange={ event=> {handleChange(event,idx)}}/></strong></td>
                                                                 
                                                                </tr>
                                                                   )
                                                                 })
                                                                 }                                <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>Total</td>

                                                                            <td>=</td>
                                                                            <td><input type="label" className="form-control highlight" name="amount" id="amountww" ></input></td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : <div className="col-6"></div>}
                                        </div>
                                    
                                    <div class="row g-3 justify-content-center   mb-2">
                                        <div className="col-3">
                                            <label className="col-form-label mx-2" >Amount <sub className="star">*</sub></label>
                                        </div>
                                        <div className="col-3">
                                            <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className="form-control highlight hide-arrow" id={"amount"} name="amount" value={input.amount} onChange={handleInputs}></input>
                                            <div className="errorMsg">{errors.amount}</div>
                                            <span id="words" class="amount-words"></span>

                                        </div>
                                        <div className="col-6"></div>
                                        

                                    </div>
                                    
                                    {manager.ismanager == 1? 
                                    <div class="row g-3 justify-content-center  mb-2">
                                        <div className="col-3">
                                            <label className="col-form-label mx-2">Voucher Number</label>
                                        </div>
                                        <div className="col-3">
                                            <input type="text" onKeyPress={(event)=>prevDec(event,event.target.value)}  className="form-control highlight hide-arrow" name="voucher_no" value={input.voucher_no} onChange={handleInputs}></input>
                                        </div>
                                        <div className="col-6"></div>
                                        {/* <div className="col-3">
                                            <label className="col-form-label mx-2" >Operational Date<sub className="star">*</sub></label>
                                        </div>
                                        <div className="col-3 d-md-flex">
                                            <input type="date" class="form-control" name="op_date" min={startDate} max={moment()}   onChange={handleInputs} value={input.op_date}></input>
                                            <div className="errorMsg">{errors.op_date}</div>
                                        </div> */}
                                    </div>
                                    :''}

                                {manager.ismanager == 1? 
                                <div className="row">
                                    <div className="col-6 mt-3">
                                        <div className="row g-3 justify-content-center mb-2">
                                            <div className="">
                                                <label className="col-form-label mx-2"><b>Outstanding Before This</b></label>
                                            </div>

                                            <div className="col-6">
                                                <label className="col-form-label mx-2">Before Approval</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" value={outstanding.before_approval?parseFloat(outstanding.before_approval).toFixed(2):'0'}  className="form-control" readOnly={true}></input>
                                            </div>
                                            <div className="col-6">
                                                <label className="col-form-label mx-2">After Approval</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" value={outstanding.after_approval?parseFloat(outstanding.after_approval).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                                            </div>
                                        </div>
                                        <div className="row g-3 justify-content-center mb-2">

                                            <div className="col-6">
                                                <label className="col-form-label mx-2">After Dayclosing</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" value={outstanding.after_dayclose?parseFloat(outstanding.after_dayclose).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                                            </div>
                                        </div>
                                        <div className="row g-3 justify-content-center mb-2">
                                            <div className="">
                                                <label className="col-form-label mx-2"><b> Outstanding  After This</b></label>
                                            </div>

                                            <div className="col-6">
                                                <label className="col-form-label mx-2">Before Approval</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" id ="outs_before_approval" value ={inputValues.outs}className="form-control" readOnly={true}></input>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-6 mt-3">
                                        <div className="row g-3 justify-content-center mb-2">
                                            <div className="">
                                                <label className="col-form-label"><b>Cash Balance Before This</b></label>
                                            </div>

                                            <div className="col-6">
                                                <label className="col-form-label">Before Approval</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" value={cashbalance.before_approval?parseFloat(cashbalance.before_approval).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                                            </div>
                                            <div className="col-6">
                                                <label className="col-form-label">After Approval</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" value={cashbalance.after_approval?parseFloat(cashbalance.after_approval).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                                            </div>
                                        </div>
                                        <div className="row g-3 justify-content-center mb-2">

                                            <div className="col-6">
                                                <label className="col-form-label">After Dayclosing</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" value={cashbalance.after_dayclose?parseFloat(cashbalance.after_dayclose).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                                            </div>

                                        </div>
                                        <div className="row g-3 justify-content-center mb-2 ">
                                            <div className="">
                                                <label className="col-form-label"><b> Cash Balance After This</b></label>
                                            </div>

                                            <div className="col-6">
                                                <label className="col-form-label">Before Approval</label>
                                            </div>
                                            <div className="col-6">
                                                <input type="text" id ="cashb_before_approval" className="form-control" readOnly={true}></input>
                                            </div>
                                           
                                        </div>
                                </div>
                            </div>
                                :''}
                                
                               {localStorage.getItem("save_hide") == 'false' ?
                                <div class="modal-footer text-center">
                                    <a href="#" class="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                                    {double===true ?<a href="#" class="btn btn-primary  w-auto "  onClick={handleSubmit} >Insert</a>:<a href="#" class="btn btn-primary  w-auto "  disabled>Insert</a>}
                                </div>
                                :''}
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>

    );
}
export default CashPaymentModal