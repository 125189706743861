import React, { useState } from 'react';
import LoginHeader from '../../components/layouts/LoginHeader.component';
import Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component";
// import customer from '../../../img/customer-review.png'
// import ReactDOM from 'react-dom';
import { API_URL } from '../../constant/API_Settings';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
// import { Link } from "react-router-dom";
// import Modal from "react-modal";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import LoginHeaderOperational from './LoginHeaderOperational';
import Modal from "react-modal";
import Loader from './Employee/Loader.component'

const OperationalDate = (props) => {


	const [inputValues, setInputValues] = useState({});
	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState({});
	const [permission, setPermission] = useState([])
	const[note,setNote]=useState({})
	const history = useHistory()
	const handleChange = (e) => {
		setInput({ ...input, op_date: e.target.value })
		if (e.target.value) {
			show_btn(e.target.value)
		}
	}
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleSubmit = () => {
		toggleModal()
	}
	useEffect(() => {
		check_url();
		//getOpDate();
		

	}, []);

	function getOpDate() {
		
		const requestOptions = {
		  method: 'get',
		  headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		  },
		};
		fetch(`${API_URL}/get-op-date`, requestOptions)
		  .then(response => { return response.json(); })
	
		  .then(responseData => {
	
			console.log(responseData.op_date.op_date,"okkkkkkkkk")
			setInput({ ...input, op_date: responseData.op_date.op_date })

			return responseData;
	
		  })
		  .catch(e => {
			console.log(e)
		  });
	
	  }

	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				console.log(responseData)
				
				if (responseData.allowurl == 1) {
					
					history.push(responseData.url);
					getDate();
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}

	function getDate() {
		var x = document.getElementById("newpost");
		x.style.display = "none";

		setInput(input)
		console.log(input)
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				next_date: input.next_date

			})
		};
		fetch(`${API_URL}/get-next-dates`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setInput({
					...input,
					next_date: responseData.next_date,
					config_date: responseData.confg_date,
					op_date: responseData.op_date,
				})
				setLoading(true)
				setNote({
					price_change:responseData.price_change

				})
				//   setInputValues({config_date:responseData.confg_date})
				
				return responseData;
				
				
			})
			
			.catch(e => {


			});
	}
	function handleYes() {
		setInput(input)
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				next_date: input.next_date
			})
		};
		fetch(`${API_URL}/save_next_date_to_meta`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				if (responseData.status == true) {
					
					history.push("/fuel-sell-price")
				}
				else {
					toggleModal()
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/operational-date")
				}
				// setList(responseData)
			
				return responseData;
			})
			.catch(e => {


			});
	}console.log(input.op_date,"check")
	function handleNo() {
		//setInput(input)
		console.log(input.op_date,"check")
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				next_date: input.op_date,
				change: "no"

			})
		};
		fetch(`${API_URL}/list-own-fuel`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				if (responseData.status == false) {
					history.push("/operational-date")
				}
				history.push("/nozzle-employee-list")
				getDate()
				toggleModal()
				// setList(responseData)
				return responseData;
			})
			.catch(e => {


			});
	}
	function show_btn(c_date) {
		var x = document.getElementById("newpost");
		//x.style.display = "block";

		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				c_date: c_date

			})
		};
		fetch(`${API_URL}/show_btn`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var x = document.getElementById("newpost");
				if (responseData.status == true) {
					x.style.display = "block";
				}
				else {
					x.style.display = "none";
				}
				return responseData;
			})
			.catch(e => {
			});
	}


	return (
		<>
			<div className="main-container page-wrap">

				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={1} operation={1} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div className="container pb-3">
							{permission.status == 3 ?
								<div className=" form-wrap mt-5">
									<div className="text-center">
										{permission.message}
									</div></div> :
								<>

									<div>
										{loading ? getDate : <Loader />}
										<div className="row mt-md-5 mt-3 form-container justify-content-center">


											<div className="col-md-9">
												<div className="form-wrap ">
													<div className="row mb-3 justify-content-center">


														<div className="col-md-12 col-12 pl-0 mb-4">
															<h3 className="table-title text-center">FUEL SELLING Price Change </h3>
														</div>
														<div className="col-md-7">
															<div className="form-row mb-2">
																<label className="me-5">Price Changing Date</label>
																{console.log(input.next_date)}
																<input type="date" disabled class="form-control" name="startDate" min={input.config_date} max={input.next_date} onChange={handleChange} value={input.op_date} />
																{/* <DatePicker

              														selected={inputValues.opdate}
																				name="startDate"
																				dateFormat="MM/dd/yyyy"
																				onChange={handleChange}
																			/> */}

															</div>
															<div className="text-end mt-5">
																{/* <a className="btn btn-primary px-5" onClick={handleClick}>Save & Continue</a> */}
																<a className=" btn text-danger text-end px-7" onClick={handleSubmit}>Do you have price change?</a>
																<a href="#" onClick={handleYes} id="newpost">View/Edit</a>
															</div>
		  														
														</div>
														

													</div>
		
										{note.price_change=='NO'?	
										<p style={{textAlign:"center",fontStyle: "italic",marginTop: "35px",color:"#6610f2"}}>
										Note : You must confirm price here, before move to other screens</p>
										:null
										
										}		
												</div>

											</div>

										</div>
									</div>
								</>}
						</div>



					</div>
				</div>
			</div>


			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className=" modal-dialogue-xl">
					<div className="modal-content">
						<div className="modal-title modal-header "><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
						<div className=" modal-body  modal-body"><p>Do you have price change?</p></div>
						<div className="justify-content-end  modal-footer"><div className="d-flex">
							<button type="button" className="btn btn-primary me-2 px-4" onClick={handleYes}>Yes</button>
							<button type="submit" className="btn btn-secondary" onClick={handleNo}>No</button>
						</div>
						</div>
					</div>
				</div>


			</Modal>


		</>
	)
}








export default OperationalDate