import React, { Component } from 'react'


class Name extends Component
{
    constructor(props)
    {
        super(props)
       
    }
    render()
    {
        console.log(this.props)
        return (<option key={this.props.id} value={this.props.id}>{this.props.product_name}</option>
        )
    }
}

export default Name