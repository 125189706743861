import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput} from "../../../layouts/Operational_Sidebar_Progress.component";

const DsrModal = ({ isShowing, hide,value,handleDate,send_date,TankData,handleDip,handleSubmit,dip_error,double,error1  }) => {
  var today = new Date().toISOString().split('T')[0];
return(
  <React.Fragment>


    <div className="modal-overlay" />
    <div className="modal-wrapper"
      aria-modal aria-hidden tabIndex={-1}
      role="dialog">
      <div className="modal modal-dialog-xl">

        <div className="modal-header mb-3">
          <h5 className=" text-center"
            id="staticBackdropLabel">
            Dsr Report</h5>
          <button type="button"
            className="modal-close-button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hide}>
            <span aria-hidden="true">
              &times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <div className="row g-2 align-items-center 
               mb-2 form-group ps-4">
                <div className="col-4">
                  <label className="col-form-label">
                    Date<sub className="star">*
                    </sub>
                  </label>
                </div>
                <div className="col-md">
                  <input type="date"
                    className="form-control"
                    name="date"
                    title="Please Select the 
                    Operational Date" 
                    value={value?.date}
                     min={value?.config_date}
                     max={today}
                     onChange={handleDate}
                     />
                    {console.log(error1.date)}
                    <div className="errorMsg">{error1.date}</div>


                </div>
                
                

              </div>
              <div className='row'>
              <div className='col-5'></div>
              <div className="col-3 justify-content-center">
                  <a href="#"
                    class="link-text  "
                    onClick={send_date} >
                    Get the Sales
                  </a>
                </div>
                </div>
              <div className="row g-2 
              align-items-center 
               mb-2 form-group ps-4 mt-3">
                <div className="col-4">
                  <label className="col-form-label ">
                    Product Name
                  </label>
                </div>
                <div className="col-md">
                  <input type="text"
                    className="form-control highlight"
                    name="fuel_name"
                    value={value?.fuel_name}
                     readOnly />
                  <input type="hidden"
                    className="form-control 
                    highlight"
                    name="fuel_name" 
                    value={value?.id}
                    
                    />

                </div>
              </div>
              {TankData.map((fuel_type,idx)=>{
                return(
                  <>

                
              <div className="form-group mb-2">
                <h6 name="tank_name">
                  <b>
                 { fuel_type?.tank_name}
                  </b>
                </h6>
              </div>
              <div className="row g-2 
              align-items-center ps-4 mb-2">
                <div className="col-4">
                  <label className="col-form-label">
                    Opening Stock
                  </label>
                </div>
                <div className="col-md">
                  <input type="text"
                    name="current_stock"
                    className="form-control"
                    value={fuel_type?.current_stock}
                    onChange={(event) => handleDip(idx, event)}
                    readOnly />
                </div>
              </div>
              <div className="row g-2 
              align-items-center
                     ps-4 mb-2">
                <div className="col-4">
                  <label className="col-form-label">
                    DIP
                    {/* <sub className="star">
                      *</sub> */}
                  </label>
                </div>
                <div className="col-md">
                  <input type="number"
                    className="form-control hide-arrow"
                    name="dip_value"
                    onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                    onChange={(event) => handleDip(idx, event)} 
                    id={"dip_id" + idx} defaultValue={fuel_type?.dip_value} />
                  <div className="errorMsg"
                  >{dip_error?.["dip_value"+idx]}</div>

                </div>

              </div>


              <div className="row g-2 align-items-center
                     ps-4 mb-2">
                <div className="col-4">
                  <label className="col-form-label">
                    Water DIP
                  </label>
                </div>
                <div className="col-md">
                  <input type="number" className="form-control hide-arrow"
                    name="water_dip_value"
                    onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                    onChange={(event) => handleDip(idx, event)}
                    id={"water_dip_id" + idx} defaultValue={fuel_type?.water_dip_value}
                  />
                  <div className="errorMsg">

                  </div>
                </div>
              </div>
              <div className="row g-2 align-items-center ps-4 mb-2">
                <div className="col-4">
                  <label className="col-form-label">
                    Physical Stock
                    <sub className="star">
                      *</sub>
                  </label>
                </div>
                <div className="col-md">
                  <input type="number"
                    className="form-control hide-arrow"
                    name="physical_stock" 
                    onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                    onChange={(event) => handleDip(idx, event)}
                    id={"physical_id" + idx} defaultValue={fuel_type?.physical_stock}/>
                  <div className="errorMsg"
                  >{dip_error?.["physical_id"+idx]}</div>

                </div>
              </div>
              </>
              )
            })}
            </div>
             
            <div class="modal-footer text-center">

              <a class="btn btn-danger w-auto"
                type="submit"
                onClick={hide}>
                Cancel</a>
                {double == true ? <a class="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit}>Save</a> : <a class="btn btn-primary px-4 w-auto" type="submit" disabled>SAVE</a>}
            </div>
          </div>
        </div>
      </div>

    </div>


  </React.Fragment>

)
          }
export default DsrModal