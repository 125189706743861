import React, { Component } from "react";
import '../App.css';
import logo from '../img/logo.svg'
import avatar from '../img/avatar.svg'
import bell_white from '../img/bell-white.svg'
import bell from '../img/bell.svg'
import LoginHeader from './layouts/LoginHeader.component'
import Sidebar_Progress from './layouts/Sidebar_Progress.component'
import { API_URL } from "../constant/API_Settings";
import axios from 'axios';
import swal from "sweetalert";
import { event } from "jquery";
import { Link } from "react-router-dom";
import Loader from './operational-model/Employee/Loader.component'
import {preventNonNumericalInput} from './layouts/Sidebar_Progress.component';
import numberToWords from 'number-to-words';



class nozzle_config extends Component {
   constructor(props) {
      super(props);


      this.state = {
         nozzle_data: [],
         nozzle_configs: [],
         nozzle: [],
         errors: [],
         disable:true,
         fuelShow: false,
         status: 0,
         stat_operation: '',
         message: '',
         i: 0,
         readonly: false, loading: false,
         words: '',
      }

   }

   check_url = () => {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            pathurl: window.location.pathname,
         }),
      };
      fetch(`${API_URL}/check_url`, requestOptions)
         .then(response => { return response.json(); })

         .then(responseData => {

            if (responseData.allowurl == 1) {
               this.props.history.push(responseData.url);
               this.getReport();
               this.getNozzle();

            }
            else {

               this.props.history.goBack();
            }

            return responseData;
         })
         .catch(e => {
            console.log(e)
         });

   }
   // componentDidMount() {

   // 	this.check_url()          //on load//
   //   }

   getNozzle() {


      let headers = {
         "Access-Control-Allow-Origin": "*",
         "Content-Type": "application/json",
         "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
         "Accept": "application/json"

      }
      axios.get(API_URL + '/nozzle', { headers: headers })
         .then(res => {
            if (res.data.status === 3) {
               this.setState({
                  status: res.data.status, message: res.data.message
               })
            }

            this.setState({ 'nozzle_data': res.data.data });

            this.setState({ stat_operation: res.data.stat_operation })

            var { nozzle_configs } = this.state;
            var { errors } = this.state;
            res.data.data.forEach(element => {
               var nozzle_name_obj = {}
               var error_nozzle_obj = {}
               var opening_reading = {}
               var error_opening_reading_obj = {}
               for (var i = 1; i < element.no_of_nozzles + 1; i++) {
                  nozzle_name_obj[i - 1] = ""
                  error_nozzle_obj[i - 1] = ""
                  opening_reading[i - 1] = ""
                  error_opening_reading_obj[i - 1] = ""

               }
               nozzle_configs.push({
                  tank_id: element.id,
                  fuel_id: element.fuel_id,
                  nozzle_name: nozzle_name_obj,
                  opening_reading: opening_reading,
                  current_stock: '',
                  last_purchase_price: '',
                  selling_price: ''

               })

               errors.push({
                  nozzle_name: error_nozzle_obj,
                  opening_reading: error_opening_reading_obj,
                  current_stock: '',
                  last_purchase_price: '',
                  selling_price: ''
               })
            })

            this.setState({ nozzle_configs: nozzle_configs })
            this.setState({ errors: errors })
            this.setState({
               'loading': true
            })

         }).catch(err => {
            console.log(err)
         })

   }
   validate_form = () => {
      var errors = this.state.errors
      var { nozzle_configs } = this.state
      var formIsValid = true
      var disable = false
      nozzle_configs.map(function (obj, index) {
         if (Object.keys(obj.nozzle_name).length != 0) {
            for (var nozzle_key in obj.nozzle_name) {
               if (obj.nozzle_name[nozzle_key] === "") {
                  formIsValid = false
                  disable = true
                  errors[index]['nozzle_name'][nozzle_key] = "Enter Nozzle Name"
               }
               else {
                  errors[index]['nozzle_name'][nozzle_key] = ""
               }
            }
            for (var opeaning_reading_key in obj.opening_reading) {

               if (obj.opening_reading[opeaning_reading_key] === "") {
                  formIsValid = false
                 disable = true
                  errors[index]['opening_reading'][opeaning_reading_key] = "Enter Nozzle Reading"
               }
               else if (isNaN(obj.opening_reading[opeaning_reading_key])) {
                  formIsValid = false
                 disable = true
                  errors[index]['opening_reading'][opeaning_reading_key] = "Nozzle Reading Must Be Number"
               }
               else if (obj.opening_reading[opeaning_reading_key] < 0 ) {
                  formIsValid = false
                 disable = true
                  errors[index]['opening_reading'][opeaning_reading_key] = "Nozzle Reading Should Be Greater Than or equal to Zero"
               }
               else {
                  errors[index]['opening_reading'][opeaning_reading_key] = ""
               }
            }

         }

         if (obj.current_stock === "") {
            formIsValid = false
           disable = true
            errors[index]['current_stock'] = " Enter Current Stock"
         }
         else if (isNaN(obj.current_stock)) {
            formIsValid = false
           disable = true
            errors[index]['current_stock'] = " Current Stock Must Be Number"
         }
         
         else if ((obj.current_stock < 0)) {
            formIsValid = false
           disable = true
            errors[index]['current_stock'] = " Current Stock Should Be Greater Than or equal to Zero"
         }
         else {
            errors[index]['current_stock'] = ""
         }
         if (obj.last_purchase_price === "") {
            formIsValid = false
           disable = true
            errors[index]['last_purchase_price'] = " Enter Last Purchase Price"
         }
         else if (isNaN(obj.last_purchase_price)) {
            formIsValid = false
           disable = true
            errors[index]['last_purchase_price'] = "Last Purchase Price Must be Number"
         }
         else if (obj.last_purchase_price <=0){
            formIsValid = false
            disable = true
            errors[index]['last_purchase_price'] = "Purchase Price Should Be Greater Than Zero"
         }
         else
         {
            errors[index]['last_purchase_price'] =""
         }
         if (obj.selling_price === "") {
            formIsValid = false
            disable = true
            errors[index]['selling_price'] = " Enter Selling Price"
         }
         else if (isNaN(obj.selling_price)) {
            formIsValid = false
            disable = true
            errors[index]['selling_price'] = "Selling Price Must be Number"
         }
         else if (obj.selling_price <=0) {
            formIsValid = false
            disable = true
            errors[index]['selling_price'] = "Selling Price Should Be Greater Than Zero"
         }
         else {
            errors[index]['selling_price'] = ""
         }

      });

      this.setState({ errors: errors,disable:disable });
      return formIsValid;
      

   }
   handleSubmit = (event) => {
     var disable = false
     this.setState({disable:disable})
      if (this.validate_form()) {

         var { nozzle_configs } = this.state
         event.preventDefault(0)
         var string = JSON.stringify(nozzle_configs)
         axios({
            url: API_URL + "/nozzle",
            method: "POST",
            data: nozzle_configs,
            headers: {
               "Access-Control-Allow-Origin": "*",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
               "Accept": "application/json"
            }
         }).then(res => {
            if (res.data.status == false) {
			   	var disable = true
               swal("", res.data.message, res.data.status ? "success" : "warning")

            }
            else {
			   	
               swal("", res.data.message, res.data.status ? "success" : "danger")
               this.props.history.push("/fuel-config-report")
            }
            this.setState({disable:disable})

         })


            .catch(error => {
			   	this.setState({disable:true})
               swal("Something went wrong", "", "warning")
               console.log(error)
            })
      }
      else {

      }
   }

   ////-----------

   componentWillMount() {

      this.check_url();
   }
   getReport = (id) => {
      axios({
         url: API_URL + "/get-user-config",
         method: "GET",
         data: { id: id },
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         }
      }).then(res => {

         var { report, fuel, tank, nozzle, rack_product, rack, product, customer, creditor_debtor, payment_methods, bank_details, fleet_card, mla_coupon, employee, vendor, asset, other_income, expense, dealership, tanker, task, fuel_unload, user } = this.state
         this.setState({ fuelShow: res.data.nozzle_status })
         res.data.nozzle_config.forEach(elem => {

            nozzle.push({
               nozzle_name: elem?.nozzle_name,
               opening_reading: elem?.opening_reading,
               current_stock: elem?.current_stock,
               selling_price: elem?.selling_price,
               last_purchase_price: elem?.last_purchase_price,
               tank_name: elem?.tank_name

            })
         })

         this.setState({
            report: report, fuel: fuel, tank: tank, nozzle: nozzle, rack_product: rack_product, rack: rack, product: product
         })

      }).catch(err => {
         console.log(err)
      })

   }

   handleoilchange = (event, index, props, ins, fuelname) => {
      const inputNumber = parseInt(event.target.value, 10);

     if (isNaN(inputNumber) || !isFinite(inputNumber) || isNaN(event.target.value)) {
      this.setState({ number: inputNumber, words: '', error: 'Please enter a valid number.' });
    } else {
      const words = numberToWords.toWords(inputNumber);
      this.setState({ number: inputNumber, words, error: null });
    
     var mainstring =  event.currentTarget.id
     const main = mainstring.replace("current_stock", "current_stocks");
     console.log(main,"main")
            var s = document.getElementById(main);
         
         var element = s
         if (element) {
            element.textContent = words;
          }
  

      var { nozzle_configs } = this.state;
      if (props == 'current_stock') {
           
         var splts = event.currentTarget.id
         console.log(splts,"props");
         console.log(ins,"fuelname");
         console.log(index,"index");
         var l = document.getElementsByClassName("letter");
         console.log(`current_stocks-${index}-${ins}`,"test")

         console.log(l,"letter")
         var nozzle_config = nozzle_configs[index]
         nozzle_config = { ...nozzle_config, [props]: event.target.value }

         nozzle_configs[index] = nozzle_config
         this.setState({ nozzle_configs: nozzle_configs })
         
      }
      var splt = event.currentTarget.id
      if (splt.split('-')[1] == 0 && props != 'current_stock') {
         
         
         var currentValue = document.getElementById(event.currentTarget.id).value;
         console.log(currentValue)
         var tt = document.getElementById(event.currentTarget.id).name + splt.split('-')[2];
         console.log(tt,"tt")
         var els = document.getElementsByClassName(tt);
         console.log(els[0],"els")
         
         for (var i = 0; i < els.length; i++) {
            els[i].value = currentValue;

            if (i != 0) {
               document.getElementById(splt.split('-')[0] + '-' + i + '-' + splt.split('-')[2]).readOnly = true;

            }  /*changes code For adding nozzle_configs */
            var nozzle_config = nozzle_configs[index + i];
            nozzle_config = { ...nozzle_config, [props]: currentValue }
            nozzle_configs[index + i] = nozzle_config
            this.setState({ nozzle_configs: nozzle_configs })
         
           
         }     
         }
      }
   
   }
   handlenozzleChange = (event, index, obj_index, props) => {
      var { nozzle_configs } = this.state
      nozzle_configs[index][props][obj_index] = event.target.value
      this.setState({ nozzle_configs: nozzle_configs })
   }

   nozzle_count = (props) => {

      var arr = [];
      for (var i = 1; i < props.name + 1; i++) {

         arr.push(i);
      }


      const nozzle_element = arr.map((obj, index) =>
         <div className="row mb-3">
            <div className="col-md-3">
               <label>Nozzle {obj}<sup class="star">*</sup></label>
            </div>
            <div className="col-md">
               <input type="text" className="form-control" name={`nozzle_name`} onChange={(event) => this.handlenozzleChange(event, props.id, index, event.target.name)} placeholder="Name the Nozzle" />
               <div className="errorMsg">{this.state.errors[props.id] ? this.state.errors[props.id]['nozzle_name'][index] : ''}</div>

            </div>
            <div className="col-md">
               <input type="number"  className="form-control hide-arrow"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name={`opening_reading`} onChange={(event) =>this.handlenozzleChange(event, props.id, index, event.target.name)}placeholder="Opening Reading"/>
               <div className="errorMsg">{this.state.errors[props.id] ? this.state.errors[props.id]['opening_reading'][index] : ''}</div>
               

            </div>
         </div>

      ); 

      return (nozzle_element)
   }
   render() {
      {/*<LoginHeader/>*/ }
      var ins = 0
      var prevfuel = ""
      return (
         <div className="main-container page-wrap">
            <div className="d-md-flex">
               <Sidebar_Progress />
               <div className="content-area">
                  <div className="">

                  </div>
                  <LoginHeader page_title='Registration setup' />
                  <div className="container pb-3">

                     <div className="row justify-content-center  mt-3 form-container">
                        <div className="col-md-12 mb-3"><Link to="/tank-config"><a href="tank-config" className="float-end btn btn-gray">Back</a></Link></div>

                        <h2>Nozzle Configuration </h2>
                        {this.state.status == 3 ?
                           <div className="form-wrap">
                              <div className="text-center">
                                 {this.state.message}
                              </div></div> :
                           <>
                              <div className="col-md-12 ">
                                 {this.state.loading ? this.getNozzle : <Loader />}
                                 <div className="row   mt-2 py-2">
                                    <div className="form-wrap">
                                       <div className="form-content  pt-3">

                                          <div className="col-md-12">
                                             {this.state.fuelShow == false ? '' :
                                                <div className="table-widget">
                                                   <table className="table border">
                                                      <thead>
                                                         <tr>
                                                            <th>Nozzle Name</th>
                                                            <th>Tank Name </th>
                                                            <th>Opening Reading </th>



                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         {

                                                            this.state.nozzle.map((obj) => {

                                                               return (
                                                                  <>
                                                                     <tr>
                                                                        <td> {obj.nozzle_name} </td>
                                                                        <td>{obj.tank_name}</td>
                                                                        <td>{obj.opening_reading}</td>
                                                                     </tr>
                                                                  </>
                                                               )
                                                            })}
                                                      </tbody>
                                                   </table>
                                                </div>
                                             }

                                          </div>


                                          <div class="mt-5">
                                             {

                                                this.state.nozzle_data.map((config, index) => {

                                                   prevfuel == config.get_fuel.fuel_name ? ins++ : ins = 0
                                                   prevfuel = config.get_fuel.fuel_name;
                                                   return (
                                                      <div>



                                                         <h5>{config.tank_name} {config.get_fuel.fuel_name}</h5>

                                                         <div className="row form-content-sm mb-3">

                                                            <div className="col-md-6">
                                                               <this.nozzle_count name={config.no_of_nozzles} id={index} />
                                                            </div>
                                                            <div className="col-md-6">
                                                               <div className="row mb-3">
                                                                  <div className="col-md-5">
                                                                     <label>Current stock (Litre) of {config.get_fuel.fuel_name}<sup class="star">*</sup></label>
                                                                  </div>
                                                                  <div className="col-md ">
                                                                     <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className={"form-control hide-arrow current_stock" + prevfuel} id={"current_stock-" + ins + "-" + prevfuel} name="current_stock" onChange={(event) => this.handleoilchange(event, index, event.target.name,config.get_fuel.fuel_name)} placeholder="Current stock in Litre" />
                                                                     <div className="errorMsg">{this.state.errors[index] ? this.state.errors[index]['current_stock'] : ''}</div>
                                                                     <span className={"letter" + prevfuel}  id={"current_stocks-" + ins + "-" + prevfuel}></span> 

                                                                     
                                                                     
                                                                  </div>

                                                               </div>
                                                               <div className="row mb-3">
                                                                  <div className="col-md-5">
                                                                     <label>Avg/last purchase price/litre<sup class="star">*</sup></label>
                                                                  </div>
                                                                  <div className="col-md">
                                                                     <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className={"form-control hide-arrow last_purchase_price" + prevfuel} id={"last_purchase_price-" + ins + "-" + prevfuel} name='last_purchase_price' onChange={(event) => this.handleoilchange(event, index, event.target.name)} placeholder="Avg/last purchase price/litre" />
                                                                     <div className="errorMsg">{this.state.errors[index] ? this.state.errors[index]['last_purchase_price'] : ''}</div>
                                                                  </div>

                                                               </div>
                                                               <div className="row mb-3">
                                                                  <div className="col-md-5">
                                                                     <label>Selling price /litre <sup class="star">*</sup></label>
                                                                  </div>
                                                                  <div className="col-md">
                                                                     <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className={"form-control hide-arrow selling_price" + prevfuel} id={"selling_price-" + ins + "-" + prevfuel} name='selling_price' onChange={(event) => this.handleoilchange(event, index, event.target.name)} placeholder="Selling price /litre " />
                                                                     <div className="errorMsg">{this.state.errors[index] ? this.state.errors[index]['selling_price'] : ''}</div>
                                                                  </div>

                                                               </div>

                                                            </div>
                                                         </div>
                                                      </div>

                                                   )

                                                })
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="text-end mt-4">
                                   
                                    {this.state.disable == true ? (this.state.stat_operation == "Yes") ?
                                       <a className="btn btn-primary w-auto" href="/fuel-config-report" >NEXT</a> :
                                       <a className="btn btn-primary w-auto" onClick={this.handleSubmit}>SAVE AND CONTINUE</a> :
                                       (this.state.stat_operation == "Yes") ?
                                       <a className="btn btn-primary w-auto" disabled href="/fuel-config-report" >NEXT</a> :
                                       <a className="btn btn-primary w-auto" disabled onClick={this.handleSubmit}>SAVE AND CONTINUE</a>}
                                        {this.state.disable == true ? 
                                        (this.state.fuelShow != false && this.state.stat_operation != "Yes")?<a className="btn btn-primary mx-3 w-auto" href="/fuel-config-report" >SKIP</a> : '' 
                                        :(this.state.fuelShow != false && this.state.stat_operation != "Yes")?  <a className="btn btn-primary mx-3 w-auto" disabled href="/fuel-config-report" >SKIP</a> : '' }
			
                                 </div>


                              </div>
                           </>}
                     </div>
                  </div>
               </div>
            </div>
         </div>


      )
   }
}
export default nozzle_config;