
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import React, { useState ,useMemo} from "react";
import museum from  '../../img/museum.svg'
import ReactDOM from 'react-dom';
import { API_URL } from "../../constant/API_Settings";
import DigitalPaymentModalConfig from "./DigitalPaymentModalConfig";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import moment from "moment";
import PaginationComponent from '../layouts/PaginationComponent';

var DigitalPaymentModal = (props) => ReactDOM.createPortal(
  <DigitalPaymentModalConfig {...props} />, document.body
       ); 
       const  PaginationComp  = (props) => (
        < PaginationComponent  {...props} />
           );
const DigitalPayment = (location) => {
  const backPage = useMemo(() => {
 
    if (location?.location?.backpage) {
      return location?.location?.backpage
    }
    else
      return undefined
  }, [location?.location?.pathname])
  const pathName = useMemo(() => {
 
		if (location?.location?.pathname) {
		  return location?.location?.pathname
		}
		else
		  return undefined
	  }, [location?.location?.backpage])
  const [double, setDouble] = useState(true);
  const [dispatchTime, setDispatchTime] = React.useState(moment());
    const UseModal = () => {
 
      const [isShowing, setIsShowing] = useState(false);
    
      function toggle() {
        var isdayclose = checkoption.isdayclose;
        if(isdayclose == 1)
        {
          swal("", "You do not have this permission after day close", false ? "success" : "warning")
        }
        else
        {
          if(isShowing) 
          {
            setInputValues([])
            setErrors({})
            setSuccess({})
          }
          if(!isShowing) 
          {
            setDouble(true);
          }
          setIsShowing(!isShowing);
        }
      }
    
      return {
        isShowing,
        toggle,
      }
    };
    const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])
    const [inputValues,setInputValues] = useState([]);
    const {isShowing, toggle} = UseModal();
    const history = useHistory()
    const [list_data,setList] = useState([]) 
    const [checkoption, ckeckOption] = useState([])
    const [payment_id,setId] = useState([]) 
    const [errors,setErrors] = useState([ ])
    const [permission, setPermission] = useState([])
    const [success,setSuccess ] = useState([]) 
    const [path, setLocation] = useState([])
    const format = 'HH:mm'
    const handleInputs = (e) => {
     
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value,
         
       });
     };
     const handleInput = (value) => {
     
   
       setInputValues({
         ...inputValues,
          settlement_time: value
       })
      
     };
  
 const [search,setSearch]=useState('')
  const  handleSearch = (event) =>
  {
	 setSearch(event.target.value)
  }

 
  useEffect(()=>{
    check_url();
  
   
  }, [location.location.pathname,location.location.backpage]); 
//------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
        if(pathName == "/digital-payment/digital-payment")
				{
					history.push(pathName);
				}
				else
				{
				history.push(responseData.url);
				}
        ListAssets(pages.current_page);
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
function check_meta_date() {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
//--------------------------//
   function	validateForm() {
    let errors = []
      let formIsValid = true
      if (!inputValues.method_name) {
          formIsValid = false;
          errors["method_name"] = "Enter Digital Payment Type"
      }
    
    //   if (!inputValues.settlement_time) {
    //     formIsValid = false;
    //     errors["settlement_time"] = "Enter Settlement Time"
    // }
  
    setErrors(errors)
  
      return formIsValid;
  
  
  }
  const [pages,setpages]=useState(
    {
    current_page:1,
    last_page:0,
    total_pages:0,
    pagination_item:[],
    total_pages:0,
  })
  
  const handlePagination = (number) =>
      {
          setpages({
            ...pages,
            current_page:number
          }
              )
    
              ListAssets(number)
    
      }

     const handleSubmit = (event ) =>
     {
       event.preventDefault();
      //  if(validateForm()){
        setDouble(false);
       sendDigitalPayment()
      //  }
       
     }
     async function sendDigitalPayment()
     {
       
   if(inputValues.settlement_time)
   {
     var time1 = inputValues.settlement_time.format(format)
   }
   else
   {
     var time1 =""
   }
      
             const requestOptions = {
             method: 'POST',
             headers: {
               "Access-Control-Allow-Origin": "*",
               "Content-Type":"application/json",
               "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
               "Accept": "application/json"
              },
                body: JSON.stringify({id:inputValues.id,
                method_name:inputValues.method_name,
                settlement_time:inputValues.settlement_time == ""  ? "":time1})
         };
         await fetch(API_URL+"/payment-methods",requestOptions)
                 .then(response => 
                   { 
                      return response.json();})
         .then(responseData => {
          setSuccess({...success,
            disabled : responseData.status === true?true:false,
           })
           let errors = []
        if(responseData.status == false)
        {
          errors["method_name"] = responseData.response.method_name
          setDouble(true);
        }
        else if(responseData.status == 3) 
         {
           swal("",responseData.message,responseData.status?"warning":"warning")
         }
        else if(responseData.status == true)
        {
          swal("",responseData.message,responseData.status?"success":"warning")
            setDouble(true)
            toggle();
            ListAssets(pages.current_page);
            setInputValues([])
        }
           
            setErrors(errors)
            return responseData;
           })  .catch(e => {
                    swal("Something went wrong","","warning")
                     console.log(e);  
                     setDouble(true)   
                 });
     }
     async function edit_payment(id)
     {
      var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
      else
      {
           const requestOptions = {
           method: 'get', 
           data: {id:id},     
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
         
            },  
          };
            await fetch(API_URL+"/payment-methods/"+id+"/edit",requestOptions)
                  .then(response => { return response.json();})
          .then(responseData => {
   
         
            setInputValues(
            {
              method_name : responseData.method_name,
              settlement_time: responseData.settlement_time==null?"":moment(responseData.settlement_time, 'HH:mm:ss'),
              id:responseData.id ? responseData.id : ''
            }
          );
                toggle();
        
        return responseData;
            })
                  .catch(e => {
                      console.log(e);
                      
                  });
      }
     }

     function delete_payment(id)
     {
      var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
      else
      {
         deleteId(id)
         toggleModal()
      }
     }

     function deleteDigitalPayment()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/payment-methods/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListAssets(pages.current_page);
           toggleModal()
           setDouble(true)
       return responseData;
     
   
         })
   
     .catch(err=>{
       swal("Something went wrong","","warning")
       console.log(err)
            
         })
  }

  //loader
const [Loading,setLoading]=useState(false);

      function ListAssets(page)
     {
             const requestOptions = {
             method: 'get',
             headers: {
               "Access-Control-Allow-Origin": "*",
               "Content-Type":"application/json",
               "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
               "Accept": "application/json"
           
              },
         };
         
          fetch(`${API_URL}/payment-methods?search_keyword=${search}&page=${page}` ,requestOptions)
                 .then(response => { return response.json();})
         .then(responseData => {


          if(responseData.status==3) 
				{
					setPermission({...permission,status:responseData.status,message:responseData.message})
				}

        setList(responseData.data.data) 
         ckeckOption({...checkoption,
					isdayclose:responseData.isdayclose})
      
     
                      
                        setpages({
                          ...pages,
                          current_page: responseData.data.current_page,
                          last_page: responseData.data.last_page,
                          per_pages:  responseData.data.per_page,
                          total_pages:responseData.data.total,
                           
                          })
                    setLoading(true);
    return responseData;
           })
                 .catch(e => {
                     console.log(e);
                    
                 });
     }
   
    return(
        <>
          <div className="main-container page-wrap">
<div className="d-md-flex">
{pathName == "/digital-payment/digital-payment"? <Sidebar_Progress path={pathName} />:<Sidebar_Progress />}
<div className="content-area">
<LoginHeader page_title="" />
    
<div className="container pb-3 mt-3">

<div class="row">

<div className="col-md-12 px-4  ">
{pathName == "/digital-payment/digital-payment" ? <Link to={backPage || "/operational-date"}>
        <a href="creditor-debitor" className="float-end btn btn-gray">Go Back</a>
      </Link>:  <Link to="/creditor-debitor">
        <a href="creditor-debitor" className="float-end btn btn-gray">Back</a>
      </Link>}
    </div>
  </div>

	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">

    {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
                      {Loading ? ListAssets : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
     
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col ps-0">
							<h3 className="table-title"> Digital Payment Configuration</h3>

						</div>
						<div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 "  data-bs-target="#bank" onClick={toggle}>Add Digital Payment</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
          <div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch}  />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

															</div>
					</div>
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Digital Payment Type</th>
      {/* <th>Reminder Time for Settlement</th> */}
      <th>Actions</th>
    </tr>
  </thead>
  <tbody> 
  {
list_data.map(payment => (    
     <tr>
      <td>{payment.method_name}</td>
       {/* <td>{payment.settlement_time}</td> */}
    
      
      <td><div className="d-flex icon-btn">
      <button onClick={()=> edit_payment(payment.id)} >  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>
      <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_payment(payment.id)} >  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
    </tr>
  ))}  
</tbody>
</table>
{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
				</div>
			</div>
      {pathName == "/digital-payment/digital-payment"? "" : <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/bank-opening")}>Skip/Continue</a></div>}
		</div>
    </>}
	</div>
  </div>
</div>
{isShowing ? (<DigitalPaymentModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        dispatchTime={dispatchTime}
        handleInput={handleInput}
        inputValues={inputValues}
        double={double}
        handleSubmit={handleSubmit}
        errors={errors}
        success={success}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">DIGITAL PAYMENT</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Digital Payment</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
				{double == true ?	<button type="submit" class="btn btn-primary"  onClick={ deleteDigitalPayment}>Yes</button>:<button type="submit" class="btn btn-primary" disabled  onClick={ deleteDigitalPayment}>Yes</button>}
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>

</div>
</div>
</div>

</>
    )
}
    
export default DigitalPayment