import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const AdminSubscriptionModel = ({ isShowing, hide, setVehicle, errors, success, inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs }) => {
    return (
        <React.Fragment>

            {console.log("dsff")}
            <div class="container pb-3">
              
            
        <div class="row mb-3 justify-content-center">
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal  modalxl col-12 ">
                          <div class="modal-header mb-3">
                                  <h5 class=" text-center" id="staticBackdropLabel">Subscription Management</h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>
                    
                           <div class="container pb-3">
           
              <div class="row g-3 justify-content-center  mt-5 mb-2">
                <div class="col-6">
                  <label class="col-form-label">Subscription Status</label>
                </div>
                <div class="col-6">
                  <a href="#" class="btn btn-success px-5 rounded-btn">Active</a>
                </div>
                </div>
                <div class="row g-3 justify-content-center  mb-2 mt-3">
                  <div class="col-6">
                    <label class="col-form-label">Subscription Date</label>
                  </div>
                  <div class="col-6">
                  <label class="col-form-label">19/3/2021</label>
                  </div>
                  </div>
                  <div class="row g-3 justify-content-center  mb-2 mt-3">
                  <div class="col-6">
                    <label class="col-form-label">Date</label>
                  </div>
                  <div class="col-6">
                  <input type="date" class="form-control highlight" />
                  </div>
                  </div>
                  <div class="modal-footer text-center">
              <a href="#" class="btn  btn-primary  w-auto text-white ms-2">View plans</a>
              <a href="#" class="btn btn-primary   w-auto">Renew</a>
      
    </div>          
          
       
          

         
                                      </div>
                                     
                                </div>
                              </div>

</div>
</div>



       </React.Fragment>

    );
}
export default AdminSubscriptionModel