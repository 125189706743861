import React,{useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import Swal from 'sweetalert';
import { Link,useLocation,useHistory} from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form } from 'react-bootstrap';
import {preventNonNumericalInput,prevNonNumericalWithDec} from "../../../layouts/Operational_Sidebar_Progress.component";

const CreditSales1Modal = ({ isShowing1,storeData,hide,hide1, double,setVehicle,mulerror,handleCustSelect,handlemulSubmit, errors, success,manager, handleIn, inputValues, new_price, handleAdd, handleRemove, handleChange, handleSelects, handleVehicleInputs, fields, dropdownData, dropdown, dropdowns, handleSubmit,mulhandleInput, handleInputs, handleInput, handleSelect }) => {
    const location_details = useLocation()
    const history = useHistory();
    let Total_amount = 0;
    let total_fuel=0;

    return (
       <React.Fragment>

            <div className="modal-overlay"  />
            <div  className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modal-dialog-xl modalxlcreditsale">

                    <div className="modal-header mb-3">
                        <h5 className=" text-center" id="staticBackdropLabel"> Add Credit sales</h5>
                
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{width: "auto"}}>
                                     {manager.ismanager == 1? 
                                     <Link to={location => ({ ...location, fields:{ fields: true }, backpage: location_details.pathname, pathname: "/customers/credit-sales" })} class="link-text"><a href="/customers">Redirect to customers configuration</a></Link>
                                     : '' }
                   
                        
                            <table  class="table border">
                                <thead>
                                <tr>
                                    
                                        <th>Customer Name<sup class="star">*</sup></th>
                                        <th>Description</th>
                                        <th>Intent Number</th>
                                        <th>Vehicle Number<sup class="star">*</sup></th>
                                        <th>Product Name<sup class="star">*</sup></th>
                                        <th>Quantity</th>
                                        <th>Price(Default)</th>
                                        <th>Amount<sup class="star">*</sup></th>
                                        <th></th>
                                    </tr>
                                </thead>

                        {
                        fields.map((obj, idx) => {
                        return (
                            <>
                            <tbody style={{width:"150px"}}>
                                <tr>
                                    <td style={{ width: "150px" }}><select id="" class="form-control " name="customer_id" value={obj.customer_id} 
                                        // onChange={handleSelect} 
                                        onChange={(event) => handleCustSelect(event,idx)}>
                                            <option value=''>Select</option>
                                            <option value={0}>New Customer</option>
                                            {dropdownData.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }</select>
                                    </td>
                                    <td>
                                    <input type="text" className="form-control " name="note" 
                                    // value={inputValues.note} 
                                    value={obj.note}
                                    // onChange={handleInputs}
                                    onChange={(event) => mulhandleInput(event,idx)} ></input>
                                    </td>
                                    <td>
                                    <input type="text"  onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} className="form-control  hide-arrow" name="intent_no"
                                    //  value={inputValues.intent_no} 
                                    value={obj.intent_no} 
                                    // onChange={handleInputs}
                                    onChange={(event) => mulhandleInput(event,idx)}></input>
                                    </td>
                                    <td style={{ width: "150px" }}><select id="" className="form-control " name="vehicle_id" 
                                        value={obj.vehicle_id} 
                                        // onChange={handleVehicleInputs}

                                        onChange={(event) => mulhandleInput(event,idx)} 

                                        >

                                            <option value=''>Select</option>
                                            <option value={0}>New Vehicle no</option>
                                            {

                                                obj.vehicle_no_dropdown.map((data) => {


                                                    return <option value={data.id}>{data.vehicle_no}</option>
                                                })
                                            }
                                        </select>
                                        </td>
                                    <td> <select id="" style={{ width: "150px" }} className="form-control " name="product_id" 
                                        value={obj.product_id} 
                                        onChange={(event) => mulhandleInput(event,idx)} 
                                        >
                                            <option value="">Select</option>
                                            {dropdowns.map((data) => {
                                                 {console.log(obj.product_id,"jjjjjjjjjjjjjjjjjjjjjjjj")}
                                                 {console.log(data,"pet")}
                                                 {console.log(data[1],"des")}
                                                
                                                return <option value={data[0]}>{data[1]}</option>
                                                
                                            })
                                            }
                                           
                                        </select>
                                        </td>
                                    <td> <input  type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  className="form-control hide-arrow" name="quantity" 
                                    value={obj.quantity} 
                                    // onChange={handleInput(e,idx)}
                                    onChange={(event) => mulhandleInput(event,idx)} 
                                     /></td>
                                    <td><input type="text" className="form-control " name="price" value={obj.price} 
                                     onChange={(event) => mulhandleInput(event,idx)} 
                                     readOnly /></td>
                                    <td><input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className="form-control  hide-arrow" name="amount" value={obj.amount} 
                                    // onChange={handleIn} 
                                    onChange={(event) => mulhandleInput(event,idx)} 
                                    />
                                    </td>
                                    
                                    <td> <div className="col-2">
                              <button className="btn-add   me-2" onClick={handleAdd}>+</button>
                              <button className="btn-add   " onClick={() => handleRemove(idx)}>-</button>

                            </div></td>
                                </tr>
                                <tr>
                                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['customer_error']:""}</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['vehicle_no_error']:""}</div></td>
                                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['product_error']:""}</div></td>
                                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['quantity_error']:""}</div></td>
                                    <td></td>
                                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['amount_error']:""}</div></td>
                                    <td hidden>{Total_amount=Total_amount+Number(obj.amount)}</td>


                                  
                                </tr>
                             
                            </tbody>
                           
                            </>
                            
                        );
                    })
                    } 

<tr>
                                    <td><h6>Total</h6></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><h6>Total Amount:</h6></td>
                                    <td><h6>{Total_amount}</h6></td>


                              
                                </tr>

                                
                    </table> 
                    {/* <table>
                    <tr>
            <th>id</th>
            <th>product_name</th>
            <th>price</th>
            <th>total quantity</th>
        </tr>
                     {
 dropdowns.map((data, idx) => {
    return ( 
        <>
        
         <tr>                                  
                                               <td>{data[0]}</td>
                                               <td >{data[1]}</td>
                                               <td >{data[2]}</td>
                                               <td>{data[3]}</td>
                                               
                                               </tr>
        
        </>
    )})
                     }         
                   
                                          
                                          
                                     
                                       
                              
                    </table> */}
                                        {localStorage.getItem("save_hide") == 'false' ?
                                                    <div className="modal-footer text-center">

                                                        <a className="btn btn-danger w-auto" onClick={hide1}>Cancel</a>
                                                        {double===true ?    <a className="btn btn-primary px-4 w-auto" type="submit" onClick={handlemulSubmit}>SAVE</a>:  <a className="btn btn-primary px-4 w-auto" type="submit" disabled>SAVE</a>}
                                                    </div>
                                                    
                                        :''}
                            
                      
    
                </div>
                
                </div>
            </div>

           
           
        </React.Fragment>
    //     :""
       
    //   }
    //     </>

    );
}
export default CreditSales1Modal
