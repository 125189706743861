import { Component } from "react";
import logo from '../../../img/logo.svg';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

const FuelTabHeader = (tab) => {
 
  

        return(
        

            <ul className="nav nav-tabs mb-3 border-0" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
            {tab.tab ==1 ? <Link to="/op-fuel-edit" > <a href="" className="nav-link px-3 active" >Fuel</a>
             </Link> : <Link to="/op-fuel-edit" > <a href="" className="nav-link px-3 " >Fuel</a>
             </Link>}</li>
            <li className="nav-item" >   {tab.tab ==2 ? <Link to="/op-tank-edit" >
               <a href="" className="nav-link px-3 active" >Tanks </a>
            </Link>:<Link to="/op-tank-edit" >
               <a href="" className="nav-link px-3 " >Tanks </a>
            </Link>}</li>
            <li className="nav-item" role="presentation"> {tab.tab ==3 ? <Link to="/op-nozzle-edit" >
               <a href="" className="nav-link px-3 active" >Nozzles</a>
          </Link> :<Link to="/op-nozzle-edit" >
               <a href="" className="nav-link px-3 " >Nozzles</a>
          </Link>} </li>
         </ul>
    
        )
    }


export default FuelTabHeader