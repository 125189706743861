import React, { useState,useMemo } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import  swal from "sweetalert";

import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import SummarySheet from '../SummarySheet.component';
import Loader from '../Loader.component'
import { useLocation } from 'react-router-dom';
import TabHeader from '../TabHeader.component';
import CreditSalesModal from './CreditSalesModal.component'; 
import CreditSales1Modal from './CreditSales1Modal.component';
import LoginHeaderOperational from '../../LoginHeaderOperational';
import DeleteModalConfig from './DeleteModalConfig.component'
import { convertAmountToWords } from "../../../layouts/Operational_Sidebar_Progress.component";
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';
import { data } from 'jquery';

const CreditModal = (props) => ReactDOM.createPortal(
	<CreditSalesModal  {...props} />, document.body
);

const CreditMulModal = (props) => ReactDOM.createPortal(
	<CreditSales1Modal  {...props} />, document.body
);

const DeleteModal = (props) => ReactDOM.createPortal(
	<DeleteModalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const CreditSalesConfig = (props) => {
	const [double, setDouble] = useState(true);
	const [mulerror, setMulerror] = useState([]);
	const location = useLocation();
	const historycredit = useHistory();
	var storedData = "";
	



	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);
		const [isShowing1, setIsShowing1] = useState(false);

		function toggle() {

		
			getCustomer();
			getProductName();
			var isdayclose = input2.isdayclose;
						if (isdayclose == 1) {
							swal("", "You do not have permission to Add  after day close", false ? "success" : "warning")
						}
								else {
									if (isShowing) {
										
										setInputValues([])
										setErrors({})
										setSuccess({})
										setFields([''])
									}
									if (!isShowing) {
										
										setDouble(true)
										setErrors({})

									}
									setIsShowing(!isShowing);
								}
		}
		
		
		function toggle1() {
			 
			getCustomer();
			getProductName();
			var isdayclose = input2.isdayclose;
						if (isdayclose == 1) {
							swal("", "You do not have permission to Add  after day close", false ? "success" : "warning")
						}
								else {
									
									
									if (isShowing1) {
								
										
										
					
										setInputValues([])
										setErrors({})
										setSuccess({})
									
										setFields([{quantity:"",
										intent_no:"",
										note:"",
										amount:"",
										price:"",
										customer_id:"",
										product_id:"",
										vehicle_id:"",
										vehicle_no_dropdown:[''],}])
										setMulerror([])
								// 	}
								// });
							
									// });
									} 
									if (!isShowing1) {
										
                                       

										 storedData = JSON.parse(localStorage.getItem('creditcust'));


										// setFields(storedData);
										console.log(storedData,"storedDatastoredData")
										console.log(typeof storedData);
										let Nozzle=[] 
										let mul_err = []
										
										if (storedData !== null && storedData !== undefined) {
                      
                                   
										storedData.forEach((elem, index) => {
											var datas = []
											if(elem.customer_id){
												
												const requestOptions = {
													method: 'post',
													body: JSON.stringify({ id: elem.customer_id }),
													headers: {
														"Access-Control-Allow-Origin": "*",
														"Content-Type": "application/json",
														"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
														"Accept": "application/json"
										
													},
										
												};
										
												fetch(API_URL + "/get_vehicle_no", requestOptions)
													.then(response => { return response.json(); })
													.then(responseData => {
														
										
														responseData.forEach(elem1 => {
										
															datas.push(elem1)
														})
													})
													.catch(e => {
														console.log(e);
										
													});

											}

											
											var v_id = 0;
											if(elem.vehicle_id){
                                                 v_id = elem.vehicle_id;
											}
											
											Nozzle.push({quantity:elem.quantity,
										            intent_no:elem.intent_no,
													note:elem.note,
													amount:elem.amount,
													price:elem.price,
													customer_id:elem.customer_id,
													product_id:elem.product_id,
													vehicle_id:elem.vehicle_id,
													vehicle_no_dropdown:datas,

									})
									

									mul_err.push({
										    quantity_error:"",
											customer_error:"",
											vehicle_no_error:"",
											product_error:"",
											amount_error:"",
				
												})

										})
										setFields(Nozzle);
										setMulerror(mul_err)
										console.log(mul_err,"mul_err___________ffggfgf")
										setDouble(true)
									
										setErrors({})
										clearLocalStorage()
										
									}
									else
									{

										
										// alert(77)
														setFields([{quantity:"",
														intent_no:"",
														note:"",
														amount:"",
														price:"",
														customer_id:"",
														product_id:"",
														vehicle_id:"",
														vehicle_no_dropdown:[''],

														}])

														setMulerror([{
															quantity_error:"",
															customer_error:"",
															vehicle_no_error:"",
															product_error:"",
															amount_error:"",

																}])
									}
								}
									setIsShowing1(!isShowing1,historycredit);
									clearLocalStorage()

			
								}			
		}

		return {
			isShowing,
			toggle,
			isShowing1,
			toggle1,
			
		}
	};
	
	  
	
	  function hide() {
		let swale=[...fields]
		let sum=0;
		{
			swale.map((summ, idx) => {
				sum = sum+Number(summ.amount)

			})
		};
		if(sum > 0){
			swal({
				title: "Confirmation?",
				text: "Your data isn't saved. Are you sure you want to close?",
				icon: "warning",
				buttons: true,
				dangerMode: true,
			  }).then((willDelete) => {
				if (willDelete) {
				  toggle1(false);
				} else {
				}
			  });
		}else{
			toggle1(false);
		}

		console.log(swale,"swalswal")
		
		} 
	  
	// function hide() {
	// 	let swale = [...fields];
	// 	console.log(swale, "swalswal");
	
	// 	if (swale == null && swale == undefined && swal == '') {
	// 		swal({
	// 			title: "Confirmation?",
	// 			text: " isn't saved. Are you sure you want to close?",
	// 			icon: "warning",
	// 			buttons: true,
	// 			dangerMode: true,
	// 		}).then((willDelete) => {
	// 			if (willDelete) {
	// 				toggle1(false);
	// 			}
	// 		});
	// 	} else {
	// 		swal({
	// 			title: "Confirmation?",
	// 			text: "Your data isn't saved. Are you sure you want to close?",
	// 			icon: "warning",
	// 			buttons: true,
	// 			dangerMode: true,
	// 		}).then((willDelete) => {
	// 			if (willDelete) {
	// 				toggle1(false);
	// 			}
	// 		});
	// 	}
	// }

	// function hide() {
	// 	let swale = [...fields];
	// 	console.log(swale, "swalswal");
	
	// 	if (swale.length === 0 || swale.every(field => field == null || field === undefined || field === '')) {
	// 		swal({
	// 			title: "Confirmation?",
	// 			text: "Are you sure you want to close?",
	// 			icon: "warning",
	// 			buttons: true,
	// 			dangerMode: true,
	// 		}).then((willDelete) => {
	// 			if (willDelete) {
	// 				toggle1(false);
	// 			}
	// 		});
	// 	} else {
	// 		swal({
	// 			title: "Confirmation?",
	// 			text: "Your data isn't saved. Are you sure you want to close?",
	// 			icon: "warning",
	// 			buttons: true,
	// 			dangerMode: true,
	// 		}).then((willDelete) => {
	// 			if (willDelete) {
	// 				toggle1(false);
	// 			}
	// 		});
	// 	}
	// }
	
	
	
	const pathName = window.location.pathname;
	const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		// alert(pathName)	
		console.log(location?.location?.backpage,"pathNamepathNamepathName")
	
			});
	
	const clearLocalStorage = () => {
        localStorage.removeItem('creditcust');
    };

	const { isShowing, toggle } = UseModal();
	const { isShowing1, toggle1 } = UseModal();


	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		


	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}

	function handleAdd() {
		const values = [...fields];
		values.push({quantity:"",intent_no:"",
		note:"",
		amount:"",
		price:"",
		customer_id:"",
		product_id:"",
		vehicle_id:"",
		vehicle_no_dropdown:[''],

		            
	});
		

	let	errorss =[...mulerror]
		errorss.push({quantity_error:"",
		customer_error:"",
		vehicle_no_error:"",
		product_error:"",
		amount_error:"",})


		setMulerror(errorss);
		setFields(values);
		// disable_rk()
		console.log(errorss,"creditvalues")


	}


	function handleRemove(i){
		let remarr=[...fields]
		console.log(remarr,"remarr")
		if (i != 0) {
			remarr.splice(i, 1);
			setFields(remarr)
		}

	}

	function mulvalidationcreditsales(){
		let formIsValid =  true

		let validationdata=[]
		validationdata =[...fields]

console.log(validationdata,'validationdatavalidationdatavalidationdata')
console.log(mulerror,'mulerrormulerror')
		// mulerror     


		for( var i =0;i<validationdata.length;i++){
			
			if(validationdata[i]['customer_id']== null
			|| validationdata[i]['customer_id'] < 0 
			||validationdata[i]['customer_id'] == undefined 
			|| validationdata[i]['customer_id'] == ''
			       ){

				mulerror[i]['customer_error'] ="Customer field is required";
				formIsValid = false
				setDouble(true)
                            }else{
									mulerror[i]['customer_error'] ="";
								}
		
	
	
	
						
		if (validationdata[i]['quantity'] == null
		  || validationdata[i]['quantity'] == undefined
		  || validationdata[i]['quantity'] ==''
		  

		  ){
			mulerror[i]['quantity_error'] ="Quantity Field is required";
			formIsValid = false
			setDouble(true)
			
		  }else{
			mulerror[i]['quantity_error'] ="";
		  }

        if (validationdata[i]['vehicle_id'] == null
		|| validationdata[i]['vehicle_id'] == undefined
		|| validationdata[i]['vehicle_id'] ==''
		// ||validationdata[i]['vehicle_id'] == 
		
		  ){

			mulerror[i]["vehicle_no_error"]="Vehicle field is required";
			formIsValid=false
			setDouble(true)
		  }else{
			mulerror[i]['vehicle_no_error'] ="";
		  }


		if (validationdata[i]['product_id'] == null
		|| validationdata[i]['product_id'] == undefined
		|| validationdata[i]['product_id'] ==''
		){
			mulerror[i]['product_error']="Product field is required";
			formIsValid=false
			setDouble(true)
		}else{
			mulerror[i]['product_error']="";
		}


		if (validationdata[i]['amount'] == null
		|| validationdata[i]['amount'] == undefined
		|| validationdata[i]['amount'] ==''
		){
			mulerror[i]['amount_error']="amount field is required";
			formIsValid=false
			setDouble(true)
		}else{
			mulerror[i]['amount_error']="";
		}

		}
		
		setMulerror(mulerror)
		setFields(validationdata)
console.log(mulerror,"mulerror")
		return formIsValid;

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput({ ...input, op_date: responseData.op_date })
					getSummerySheet()
					ListCreditSales(pages.current_page)
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function getProductName() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(`${API_URL}/get_product_name?op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {

					datas.push([elem.id, elem.fuel_name,elem.new_price,0])
				})
datas[0][3]=0;
				setDropdowns(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}


	// function mulvalidationsales(){
	// 	let formIsValid = true
	// 	let mulvalidationcreditsales =[]
	// 	mulvalidationcreditsales=[...fields]
	// 	console.log(mulvalidationcreditsales,"mulvalidationcreditsales")

	// setMulerror(mulerror)
	// setFields(mulvalidationcreditsales)
	// console.log(mulerror,"mulerrorcredit")
	// return formIsValid;
	// }

	function getCustomer() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/get_customers", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []
               console.log(datas,"datass")
				responseData.forEach(elem => {

					datas.push([elem.id, 
						elem.name,
						elem.vehicle_no,
						
						
					])
				})

				setDropdownData(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	// const handleChange = (e) => {

	// 	setInputValues({
	// 		...inputValues,
	// 		vehicle_id: e.target.value
	// 	});


	// };
	const handleVehicleInputs = (e) => {

		setInputValues({
			...inputValues,
			vehicle_id: e.target.value,

			//quantity: quantity
		});

	};

	const handleSelect = (e) => {

		setInputValues({
			...inputValues,
			customer_id: e.target.value
		});
		var id = e.target.value
		getVehicleNumber(id);
		//getStorage(id);
 

	};

	const handleCustSelect = (e,i) => {

		// setInputValues({
		// 	...inputValues,
		// 	customer_id: e.target.value
		// });

		let newarrr=[...fields]
		newarrr[i][e.target.name]=e.target.value;

		var id = e.target.value
		setFields(newarrr);
		console.log(fields,"newarrr")
		localStorage.setItem('creditcust', JSON.stringify(newarrr));
		
        
		

		// getVehicleNumber(id);
		getMulVehicleNumber(id,i);
		//getStorage(id);
 

	};
	 storedData = localStorage.getItem('creditcust');
	console.log(JSON.parse(storedData),"fgddhhgfh"); 

	
	const handleSelects = (e) => {

		console.log()
		if (e.target.value == "") {
			inputValues.price = ""

		}
		setInputValues({
			...inputValues,
			product_id: e.target.value
		});
		var id = e.target.value

		getPrice(id);
		//getStorage(id);


	};
	function getPrice(id) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: id,
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_fuel_price", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				
				// setInputValues({...inputValues,price:responseData[0].selling_price})
				var price = responseData[0].new_price == "" ? '' : responseData[0].new_price
				var amount = (price * inputValues.quantity) ? (price * inputValues.quantity) : ''
				var a = parseFloat(amount) ? parseFloat(amount).toFixed(2) : ''
				inputValues["amount"] = a
				setInputValues({ ...inputValues, product_id: id, price: price })
				//setPrice({ ...new_price, price: responseData[0].new_price })





				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	

	function getmulproPrice(id,i) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: id,
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_fuel_price", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				
				let newarrr=[...fields]
				let dd_value =[...dropdowns]
                //  let p_sum = 0;
				// console.log(responseData,"kkkkkkkkkkkkkkkkkkk")
				// setInputValues({...inputValues,price:responseData[0].selling_price})
				var price = responseData[0].new_price == "" ? '' : responseData[0].new_price
				var amount = (price * newarrr[i]["quantity"]) ? (price * newarrr[i]["quantity"]) : ''
				var a = parseFloat(amount) ? parseFloat(amount).toFixed(2) : ''
				inputValues["amount"] = a
				// setInputValues({ ...inputValues, product_id: id, price: price })
				//setPrice({ ...new_price, price: responseData[0].new_price })

				dd_value.forEach(dd_elem => {
					
					if(dd_elem[0] == id){
						dd_elem[2] = price;
					}

					
				})
			

				console.log(price,"kkkkkkkkkkkkkkkkkkk")

				
				newarrr[i]["price"]=price;
				newarrr[i]["amount"]=a;
				setFields(newarrr)


				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	function getVehicleNumber(id) {
		console.log(id,"xxxxxxxxxxxxxxxxxxxxxx")
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_vehicle_no", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {

					datas.push(elem)
				})

				setDropdown(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function getMulVehicleNumber(id,i) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};

		fetch(API_URL + "/get_vehicle_no", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {

					datas.push(elem)
				})
				let newarrr=[...fields]
				newarrr[i]["vehicle_no_dropdown"]=datas;
				setFields(newarrr)

				localStorage.setItem('creditcust', JSON.stringify(newarrr));

				//setDropdown(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	storedData = localStorage.getItem('creditcust');
	console.log(JSON.parse(storedData),"aaaaaaaaaaaa"); 

	function ListCreditSales(page) {
		const requestOptions = {
			method: "get",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				Accept: "application/json",
			},
		};
		//  fetch(API_URL + "/task-remainder", requestOptions)
		fetch(`${API_URL}/credit_sales?search_keyword=${search}&page=${page}&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then((response) => {
				return response.json();
			})
			.then((responseData) => {
				setList(responseData.data.data);
				
				setInput2({ ...input2, isdayclose: responseData.isdayclose })
				setManager({
					...manager,
					ismanager: responseData.Manager,
				})
				setTotal({
					...total,
					totalamount: responseData.totalamt,
				})


				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true);
				if(storedData !== null && storedData !== undefined)
	
				{
				    getCustomer();
					toggle1();
					
					
				}
				return responseData;
			})
			.catch((e) => {
				console.log(e);
			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)
		ListCreditSales(number)
	}
	//---Create popup function---//

	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);


	const handleInput = (e) => {
		if (e.target.name == "quantity" && inputValues.price) {
			var amount = (inputValues.price * e.target.value) ? (inputValues.price * e.target.value) : ''
			var a = parseFloat(amount) ? amount.toFixed(2) : amount
			inputValues["amount"] = a
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,

		});

	};

	const mulhandleInput = (e,index) => {
		const newarr = [...fields];
		const newqqq = [...dropdowns];
		console.log(newarr,"lllllllll")
		console.log(newarr,"drop")
		newarr[index][e.target.name]=e.target.value;
		
		
		if (e.target.name == "quantity" && newarr[index]["price"]) {
			var amount = (newarr[index]["price"] * e.target.value) ? (newarr[index]["price"] * e.target.value) : ''
			var a = parseFloat(amount) ? amount.toFixed(2) : amount
			newarr[index]["amount"] = a
		}

		if (e.target.name == "amount" && newarr[index]["price"]) {
			newarr[index]["quantity"] = (e.target.value / newarr[index]["price"]) ? (e.target.value / newarr[index]["price"]) : ''
			// var q = parseFloat(newarr[index]["quantity"]) ? [index]["quantity"].toFixed(2) : [index]["quantity"]

		}

		if(e.target.name == "product_id"){
			getmulproPrice(e.target.value,index);
		}
 let sum_quantity=0;
		
		newqqq.forEach(newqqq_elem => {
					
			if(newqqq_elem[0] == newarr[index]["product_id"]){
				sum_quantity = sum_quantity+Number(newarr[index]["quantity"])
				
				
				// newqqq_elem[2] = price;
			}
			console.log(newarr[index]["product_id"],"prrrrrrrrrrrr")

			
		})
		


		// localStorage.setItem('creditcustnew', JSON.stringify(newarrr));

		console.log(fields,"fieldsfieldsfields")
		setFields(newarr)
		localStorage.setItem('creditcust', JSON.stringify(newarr));



	};
	 storedData = localStorage.getItem('creditcust');
	console.log(JSON.parse(storedData),"creditcustINPUTcreditcustINPUT"); 

	const handleInputs = (e) => {


		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,

		});

	};
	//---onchange functions---//
	const handleIn = (e) => {
		if (e.target.name == "amount" && inputValues.price) {
			var quantity = (e.target.value / inputValues.price) ? (e.target.value / inputValues.price) : ''
			var q = parseFloat(quantity) ? quantity.toFixed(2) : quantity

		}


		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
			quantity: q
		});

		convertAmountToWords(e, e.target.value)


	}

	const [search, setSearch] = useState('')
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	useEffect(() => {

		check_url();

	}, []);
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		sendCreditSalesData()
	}

	const handlemulSubmit = (event,index) => {
		event.preventDefault();
		 setDouble(false);
		 if(mulvalidationcreditsales()){
			sendmulCreditSalesData()
			
		 }
		
		
		
	}

	function sendmulCreditSalesData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				credit_array:fields,
				op_emp_id: localStorage.getItem('op_emp_id'),
				op_date: localStorage.getItem('op_date'),
				emp_id: localStorage.getItem('emp_id'),
			})
		};
		fetch(API_URL + "/mul_credit_sales", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				// let errors = []
				if (responseData.status == false) {
					console.log(responseData,"erroro")

                  let backvalidation=[...fields]
				  console.log(responseData.errors[0].amount,'backvalidation')

				  for( var j =0;j<backvalidation.length;j++){
                        // 
						if(responseData.errors[j]){
							let error_data = responseData.errors[j];
							
							
							if(error_data.amount){
								mulerror[j]['amount_error']=responseData.errors[j].amount
							}else{
								mulerror[j]['amount_error'] = ''
							}   
						
						if(error_data.customer_id){
							mulerror[j]['customer_error']=responseData.errors[j].customer_id
							}else{
								mulerror[j]['customer_error'] = ''
						}
                        if(error_data.quantity){
							mulerror[j]['quantity_error']=responseData.errors[j].quantity
							}else{
								mulerror[j]['quantity_error'] = ''
						}
						if(error_data.product_id){
							mulerror[j]['product_error']=responseData.errors[j].product_id
							}else{
								mulerror[j]['product_error'] = ''
						}
						if(error_data.vehicle_id){
							mulerror[j]['vehicle_no_error']=responseData.errors[j].vehicle_id
							}else{
								mulerror[j]['vehicle_no_error'] = ''
						}

						
						
						}
		

				  }


					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle1();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == 5) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					toggle1();
					getSummerySheet()
					ListCreditSales(pages.current_page);
					history.push("/credit-sales")
					setDouble(true)
					clearLocalStorage()

				}
				// setErrors(errors)
				setMulerror(errors)

				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}

	

	//---Create CreditSalesData---//

	function sendCreditSalesData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				amount: inputValues.amount,
				customer_id: inputValues.customer_id,
				intent_no: inputValues.intent_no,
				note: inputValues.note,
				price: inputValues.price,
				product_id: inputValues.product_id,
				quantity: inputValues.quantity,
				id: inputValues.id,
				vehicle_id: inputValues.vehicle_id,
				op_emp_id: localStorage.getItem('op_emp_id'),
				op_date: localStorage.getItem('op_date'),
				emp_id: localStorage.getItem('emp_id'),
			})
		};
		fetch(API_URL + "/credit_sales", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["customer_id"] = responseData.response.customer_id
					errors["vehicle_id"] = responseData.response.vehicle_id
					errors["quantity"] = responseData.response.quantity
					errors["amount"] = responseData.response.amount
					errors["product_id"] = responseData.response.product_id
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == 5) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					setErrors([''])
					inputValues.amount = ""
					inputValues.quantity = ""
					inputValues.product_id = ""
					inputValues.customer_id = ""
					inputValues.vehicle_id = ""
					inputValues.intent_no = ""
					inputValues.note = ""
					inputValues.price = ""
					setInputValues(inputValues)
					toggle();
					getSummerySheet()
					ListCreditSales(pages.current_page);
					history.push("/credit-sales")
					setDouble(true)

				}
				setErrors(errors)

				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}

	//---Create EmployeeList---//

	function edit_CreditSales(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/credit_sales/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInputValues({
					// sales_type: responseData.sales_type,
					customer_id: responseData.customer_id ? responseData.customer_id : '0',
					note: responseData.note,
					product_id: responseData.product_id,
					intent_no: responseData.intent_no,
					price: responseData.price,
					amount: responseData.amount,
					quantity: responseData.quantity,
					vehicle_id: responseData.new_vehicle_no == 1 ? 0 : responseData.vehicle_id,
					// sales_value: responseData.sales_value,
					id: responseData.id ? responseData.id : ''
				})
				getVehicleNumber(responseData.customer_id);
				if (responseData.new_customer != 1) {
					setDropdown(responseData.get_customer.custom_vehicle)
				}
				else {
					setDropdown([])
				}
				toggle()
				

				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}


	function delete_credit(id) {
		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have permission to Delete  after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}
	}

	
	function deleteCredit() {

		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/credit_sales/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				//ListCustomer();
				getSummerySheet()
				setDouble(true)
				ListCreditSales(pages.current_page);

				toggling()

				return responseData;


			})

			.catch(err => {
				console.log(err)
			})
	}

	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState([]);
	const [input, setInput] = useState([]);
	const [total, setTotal] = useState([])
	const [manager, setManager] = useState([]);
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [summary, setSummary] = useState([])
	const [list_data, setList] = useState([])
	const [input2, setInput2] = useState({});
	const [dropdownData, setDropdownData] = useState([])
	const [dropdown, setDropdown] = useState([])
	const [dropdowns, setDropdowns] = useState([])
	const [new_price, setPrice] = useState({})
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	
	//const [vehicle_no, setVehicleno] = useState({})

	console.log(fields,"fieldsfieldsfieldsfields")
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={3} />
									<div className="row">
										{Loading ? ListCreditSales :
											// <div class="loader text-primary">Loading...</div>
											<Loader />
										}
									</div>

									<div class="form-wrap ">

										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Credit Sales </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle1} >Add Multiple Credit Sales</a>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Single Credit Sales</a>
														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch} />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

															</div>
														</div>
													</div>


													<div class="table-widget">
														<table class="table border">
															<thead>
																<tr>

																	<th>Customer Name</th>
																	<th>Description</th>
																	<th>Intent Number</th>
																	<th>Vehicle Number</th>
																	<th >Product Name</th>
																	<th>Quantity</th>
																	<th>Price(Default)</th>
																	<th >Amount</th>
																	<th >Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(credit => (
																		<tr>
																			<td>{credit.get_customer ? credit.get_customer.name : 'New customer'}</td>
																			<td> {credit.note}</td>
																			<td>{credit.intent_no} </td>
																			<td> {credit.new_vehicle_no == 0 ? credit.vehicle_no : 'New Vehicle No'}</td>
																			<td> {credit.fuel_name}</td>
																			<td>{credit.quantity} </td>
																			<td>{credit.price} </td>
																			<td>{credit.amount} </td>
																			<td>{moment(credit.op_date).format("DD-MM-YYYY")}</td>
																			<td class="py-3">
																				<a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => edit_CreditSales(credit.id)} ><i class="fa fa-pencil-square-o me-2" aria-hidden="true"></i></a>
																				{localStorage.getItem("save_hide") == 'false' ?
																				<a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={() => delete_credit(credit.id)}><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																			    :''}
																			</td>

																			{/* <td>
																				<div className="d-flex icon-btn">
																					<button onClick={() => edit_Reminder(reminder.id)}  >  <i className="fa fa-pencil" aria-hidden="true"></i>
																					</button>
																					<button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={() => delete_task(reminder.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

																					</button>


																				</div>
																			</td> */}
																		</tr>
																	))}

																<tr>
																	<td colSpan={7} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={3}>
																		{total.totalamount}
																	</td>
																</tr>

															</tbody>
														</table>

														{Loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}
													</div>


												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<CreditModal
				isShowing={isShowing}
				hide={toggle}
				
				handleInputs={handleInputs}
				handleInput={handleInput}
				handleVehicleInputs={handleVehicleInputs}
				inputValues={inputValues}
				manager={manager}
				fields={fields}
				success={success}
				new_price={new_price}
				dropdownData={dropdownData}
				dropdown={dropdown}
				dropdowns={dropdowns}
				handleSubmit={handleSubmit}
				
				double={double}
				handleSelect={handleSelect}
				handleSelects={handleSelects}
				handleIn={handleIn}
				//handleChange={handleChange}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}

             {isShowing1 ? (<CreditMulModal
				isShowing1={isShowing1}
				 hide1={toggle1}
				hide={hide}
				handleInputs={handleInputs}
				handleInput={handleInput}
				handleVehicleInputs={handleVehicleInputs}
				inputValues={inputValues}
				manager={manager}
				fields={fields}
				success={success}
				new_price={new_price}
				dropdownData={dropdownData}
				handleCustSelect={handleCustSelect}
				dropdown={dropdown}
				dropdowns={dropdowns}
				handleSubmit={handleSubmit}
				handlemulSubmit={handlemulSubmit}
				mulhandleInput={mulhandleInput}
				double={double}
				handleSelect={handleSelect}
				mulerror={mulerror}
				handleSelects={handleSelects}
				handleIn={handleIn}
				//handleChange={handleChange}
				handleAdd={handleAdd}
				handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}

			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				deleteCredit={deleteCredit}
				double={double}
			/>) : null
			}

		</>
	)
}








export default CreditSalesConfig