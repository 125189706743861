import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'

const TankCapacityModal = ({ show ,hide ,preventNonNumericalInput,handleCapacity,handleCapacitySubmit,errors }) => {
    return (
        <React.Fragment>
            <div className="modal-overlay" />
                <div className="modal-wrapper" >
        <div className="modal modal-dialog">
            <div class="modal-content">
					<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Change Capacity</h5><button type="button" onClick={hide} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div class=" modal-body  modal-body">
                    <div class="row g-3  mt-2 justify-content-center mb-2">
                   
                    <div class="col-3">
                        <label class="col-form-label font-weight-bolder">Capacity(KL)<sub className="star">*</sub></label>
                     </div>
                     <div class="col-6">
                        <input  type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow "  name="capacity"  onChange={handleCapacity} />
                        <div className="errorMsg">{errors.capacity}</div>
                     </div>
                    </div>
                    </div>
					<div class="justify-content-end mt-3"><div class="d-flex">
						<button type="button" class="btn btn-secondary me-2" onClick={hide} >Cancel</button>
						<button type="submit" class="btn btn-primary" onClick={handleCapacitySubmit} >Save</button>
					</div>
					</div>
				</div>
                </div>
</div>


       </React.Fragment>

    );
}
export default TankCapacityModal