import { Component } from "react";
import notification_bell from '../../img/bell.svg'
import avatar from '../../img/avatar.svg'
import { Link } from "react-router-dom";
import { API_URL } from "../../constant/API_Settings"
import { WEB_URL } from "../../constant/API_Settings"
import downloadimage from '../../img/download.png'
import moment from 'moment';
import { withRouter } from "react-router";
class LoginHeader extends Component {
  constructor(props) {
    super(props)

    this.state =
    {
      op_date: '',
      'profile_name': localStorage.getItem("PROFILE_NAME"),
      curTime: new Date().toLocaleString(),
      userId: '',
      noti_count: '',
      history:[],
      set_notification: [],
      set_download:[],
      noti_count_state:''
    }
    var time = this.state.curTime.split(',')
    var time1 = time[1]

    localStorage.setItem('time', time1);
  }

  componentDidMount() {
    this.check_meta_date() 
    this.get_notification()
    setInterval(async () => {
      this.get_notification()
    }, 30000);

  }

  check_meta_date = () => {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
       
        this.setState({ stat_operation: responseData?.stat_operation, op_date: responseData.op_date, userId: responseData.userId })
        console.log(responseData)
        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }

  approved_notification({ task_id }) {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify({
        task_id: task_id,


      })
    };
    fetch(API_URL + "/notifications_done", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
      })
      .catch(e => {
      });

  }

  get_notification = () => {

    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    };
    fetch(API_URL + "/getnotification", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        
        this.setState({ set_notification: responseData?.data,set_download:responseData?.data_statement, noti_count: responseData?.noti_count ,noti_count_state:responseData?.noti_count_statement})

        localStorage.setItem( "PERMISSIONS", responseData?.permissions || []);
        
        return responseData;
      })

      .catch(e => {
        console.log(e)
      });
  }

  render() {
    return (<div className=""><nav className="navbar navbar-expand-lg navbar-light bg-light top-menu ">

      <div className="container-fluid">
      
        <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item  ">
              <p className="mt-3 me-3">
                {/* <i className="fa fa-clock-o me-2" aria-hidden="true"></i>  */}
              </p>
            </li>
            <li className="nav-item  ">
            
            {this.state.op_date?<p className="mt-3 me-3"><i aria-hidden="true">Operational Date: </i>{moment(this.state.op_date).format("DD-MM-YYYY")}</p>:""}</li>
            <li className="nav-item dropdown notify">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={notification_bell} width="20px;" />
                <span className="badge bg-danger">{this.state.noti_count}</span>
              </a>
              <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
                <li><h4 className="notify-head">Notification</h4></li>

                {
                  this?.state?.set_notification?.map((config, index) => {

                    return (
                      <li><a className="dropdown-item" href={config.url} download><strong>{config.title}</strong><span>{config.note}</span><small>{config.created_at}</small></a>

                        {(config.task_id && config.status == 1) ?
                          <li><a className="btn btn-done ms-5" onClick={(task_id) => this.approved_notification({
                            task_id: config.task_id
                          })}>Done </a></li>
                          : ''}

                      </li>
                    )

                  })
                }
                <li><a className="dropdown-item text-center notify-all" href="all_notifications/1">show all notifications</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown notify">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={downloadimage} width="20px;" />
                <span className="badge bg-danger">{this.state.noti_count_state}</span>
              </a>
              <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
                <li><h4 className="notify-head">Notification</h4></li>

                {
                  this?.state?.set_download?.map((config, index) => {

                    return (
                      <li><a className="dropdown-item" href={config.url} download><strong>{config.title}</strong><span>{config.note}</span><small>{config.created_at}</small></a>

                        {(config.task_id && config.status == 1) ?
                          <li><a className="btn btn-done ms-5" onClick={(task_id) => this.approved_notification({
                            task_id: config.task_id
                          })}>Done </a></li>
                          : ''}

                      </li>
                    )

                  })
                }
                <li><a className="dropdown-item text-center notify-all" href="all_notifications/2">show all notifications</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle user-head" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img className="img-profile rounded-circle" src={avatar} />{this.state.profile_name}<span><i className="fa fa-angle-down font-weight-bolder" aria-hidden="true"></i></span>
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
{/*               
                {
                // this.state.stat_operation == "Yes" ? 
                // <li><Link to="/operational-settings"><a className="dropdown-item" href="/operational-settings" ><i class="fa fa-wrench me-2" aria-hidden="true"></i>Operational Settings</a></Link></li> : ''
              }
                // <li><Link to="/settings"><a className="dropdown-item" href="/settings" ><i class="fa fa-wrench me-2" aria-hidden="true"></i>Config Settings</a></Link></li>
              
                <li><Link to="/cbalance-sheet"><a className="dropdown-item" href="/cbalance-sheet" target="_blank"><i class="fa fa-file me-2" aria-hidden="true"></i> Balance Sheet</a></Link></li>
                <li><hr className="dropdown-divider" /></li> */}
                <li><Link className="dropdown-item" to="/logout"><i class="fa fa-sign-out me-2" aria-hidden="true"></i>Logout</Link></li>
              </ul>
            </li>

          </ul>

        </div>
      </div>
    </nav>
    </div>)
  }

}

export default LoginHeader;
export const preventNonNumericalInput = (e, num) => {

  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);
  var fullChar = num ? num?.toString() + charStr : charStr;
  if (!fullChar.match(/^[0-9]*(\.{1})?([0-91-9][0-9])?$/))
    e.preventDefault();

  var myArr = fullChar.toString().split('.');
  var num0 = myArr[0];
  if ((num0 = num0.toString()).length > 8)
    e.preventDefault();
}

export const prevNonNumericalWithDec = (e, num) => {

  var parent = e.currentTarget.parentNode
  var selector = parent.querySelector('.ph_no')
  if (typeof selector !== 'undefined' && selector) {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    var fullChar = num ? num?.toString() + charStr : charStr;
    if (!fullChar.match(/^[0-9]+$/))
      e.preventDefault();
  }
  else {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    var fullChar = num ? num?.toString() + charStr : charStr;
    if (!fullChar.match(/^[0-9]+$/))
      e.preventDefault();

    var myArr = fullChar.toString().split('.');
    var num0 = myArr[0];
    if ((num0 = num0.toString()).length > 8)
      e.preventDefault();

  }
}