
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import React, { useState } from "react";
import museum from '../../img/museum.svg'
import ReactDOM from 'react-dom';
import { Link, useLocation } from "react-router-dom";
import TaskModalConfig from './TaskModalConfig'
import { useHistory } from "react-router";
import swal from "sweetalert";
import { API_URL } from "../../constant/API_Settings";
import { useEffect, useMemo } from "react";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import { prevNonNumericalWithDec } from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';

var TaskModal = (props) => ReactDOM.createPortal(
  <TaskModalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
  < PaginationComponent  {...props} />
);

const TaskConfig = (location) => {
  const backPage = useMemo(() => {

    if (location?.location?.backpage) {
      return location?.location?.backpage
    }
    else
      return undefined
  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);
  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      var isdayclose = checkoption.isdayclose;
      if (isdayclose == 1) {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else {
        if (isShowing) {
          setInputValues({})
          setErrors([])
          setInput([])

        }
        if (!isShowing) {
          setDouble(true)
        }

        setIsShowing(!isShowing);
      }
    }
    return {
      isShowing,
      toggle,
    }
  };

  const handlePagination = (number) => {
    setpages({
      ...pages,
      current_page: number
    }
    )

    ListReminder(number)

  }
  const [pages, setpages] = useState(
    {
      current_page: 1,
      last_page: 0,
      total_pages: 0,
      pagination_item: [],
      total_pages: 0,
    })
  const [search, setSearch] = useState('')
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [delete_id, deleteId] = useState([])
  const [list_data, setList] = useState([]);
  const [input, setInput] = useState({});
  const [permission, setPermission] = useState([])
  const [inputValues, setInputValues] = useState([]);
  const [checkoption, ckeckOption] = useState([])
  const [path, setLocation] = useState([])


  useEffect(() => {

    check_url()

    if (input.last_action && input.frequency || input.frequency_unit) {
      listTime();
    }
  }, [input]);
  //------------------------url
  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {

        if (responseData.allowurl == 1) {

          if (location.location.pathname == "/task-config/task-config") {
            history.push(location.location.pathname);
          }
          else {
            history.push(responseData.url);
          }
          ListReminder(pages.current_page);
        }
        else {

          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        //	console.log(responseData.op_date)


        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  //--------------------------//

  function ListReminder(page) {
    const requestOptions = {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    //  fetch(API_URL + "/task-remainder", requestOptions)
    fetch(`${API_URL}/task-remainder?search_keyword=${search}&page=${page}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == 3) {
          setPermission({ ...permission, status: responseData.status, message: responseData.message })
        }
        else {
          setList(responseData.data.data);
          ckeckOption({
            ...checkoption,
            isdayclose: responseData.isdayclose
          })


          setpages({
            ...pages,
            current_page: responseData.data.current_page,
            last_page: responseData.data.last_page,
            per_pages: responseData.data.per_page,
            total_pages: responseData.data.total,

          })

        }
        setLoading(true)
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function delete_task(id) {
    var isdayclose = checkoption.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {

      deleteId(id)

      toggleModal()
    }

  }

  function deleteTask() {
    setDouble(false)
    // console.log(...delete_id)
    const requestOptions = {
      method: 'DELETE',

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify(delete_id)
    };
    fetch(API_URL + "/task-remainder/" + delete_id, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        ListReminder(pages.current_page);
        toggleModal()
        setDouble(true)
        return responseData;


      })

      .catch(err => {
        swal("Something went wrong", "", "warning")
        console.log(err)
      })
  }

  function sendTaskReminder() {

    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify(inputValues)
    };

    fetch(API_URL + "/task-remainder", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {

        let errors = []
        if (responseData.status == false) {
          errors["task_name"] = responseData.response.task_name
          errors["stop_after_unit"] = responseData.response.stop_after_unit
          errors["remind_before"] = responseData.response.remind_before
          errors["frequency_unit"] = responseData.response.frequency_unit
          setDouble(true)
        }
        else if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "danger")
          setInputValues({})
          setInput({})
          setDouble(true)
          // history.push("/task-config");
          toggle();
        }
        if (responseData.status === 3) {
          swal("", responseData.message, "warning")
          setDouble(true)


        }
        setErrors(errors)


        // ListAssets();

        return responseData;
      }).catch(e => {
        swal("Something went wrong", "", "warning")
        setDouble(true)
        console.log(e);
      });
  }
  const handleInputs = (e) => {
  inputValues.stop_after_unit=""
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,

    });
 

  };
  const handleSelect = (e) => {
    const values = { ...input, [e.target.name]: e.target.value };
    setInput(values);

    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  }

  //loader
  const [Loading, setLoading] = useState(false);

  function listTime() {

    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify(input)
    };

    fetch(API_URL + "/next_action_date", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {

        setInputValues(
          {
            ...inputValues,
            next_action: responseData.next_action

          }
        );


        return responseData;

      }).catch(e => {
        console.log(e);
      });

  }
  // const handleClick = (e) => {
  //   const values = [...input];
  //   values = e.target.value;
  //   setInput(values);
  //   setInputValues({...inputValues, last_action: values})
  //   }

  const handleSubmit = (event) => {
    event.preventDefault();
    setDouble(false)
    setInputValues({
      ...inputValues,
      last_action: input.last_action,
      frequency: input.frequency,
      frequency_unit: input.frequency_unit
    })
    if (validateForm())

      sendTaskReminder()
  }
  const handleSkip = (event) => {
    event.preventDefault();
    setDouble(false)
    history.push("fuel-unloading")
  }
  function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.task_name) {
      setDouble(true)
      formIsValid = false;
      errors["task_name"] = "Enter Task/Reminder"
    }


    if (!inputValues.description) {
      setDouble(true)
      formIsValid = false;
      errors["description"] = "Enter Description"
    }

    if (!input.last_action) {
      setDouble(true)
      formIsValid = false;
      errors["last_action"] = "Enter Last Action Date"
    }

    if (!input.frequency) {
      setDouble(true)
      formIsValid = false;
      errors["frequency"] = "Enter Frequency"
    }

    if (input.frequency === 'no_of_days') {
      if (!input.frequency_unit) {
        setDouble(true)
        formIsValid = false;
        errors["frequency_unit"] = "Enter Frequency Unit"
      }
    }

    if (!inputValues.remind_before) {
      setDouble(true)
      formIsValid = false;
      errors["remind_before"] = "Enter Remind Before"
    }

    if (!inputValues.stop_after) {
      setDouble(true)
      formIsValid = false;
      errors["stop_after"] = "Enter Stop After"
    }
    if (inputValues.stop_after === 'no_of_time') {
      if (!inputValues.stop_after_unit) {
        setDouble(true)
        formIsValid = false;
        errors["stop_after_unit"] = "Enter Stop After Unit"
      }
    }



    setErrors(errors)

    return formIsValid;


  }
  function edit_Reminder(id) {

    const requestOptions = {
      method: 'get',
      data: { id: id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    fetch(API_URL + "/task-remainder/" + id + "/edit", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {

        setInputValues(responseData.data)

        setInput({
          last_action: responseData.data.last_action,
          frequency: responseData.data.frequency,
          frequency_unit: responseData.data.frequency_unit,
          ob_is_dayclose: responseData.ob_is_dayclose == 1 ? true : false,
        })

        toggle();

        return responseData;

      })
      .catch(e => {
        console.log(e);
      });
  }
  const [errors, setErrors] = useState([])
  const { isShowing, toggle } = UseModal();
  const history = useHistory()
  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          {location.location.pathname == "/task-config/task-config" ? <Sidebar_Progress path={location.location.pathname} /> :
            <Sidebar_Progress />}
          <div className="content-area">
            <LoginHeader page_title="" />

            <div className="container pb-3">
              <div class="row">
                {/* {Loading ? listTime : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	} */}
                <div className="col-md-12 mb-3 px-4 mt-2"><Link to="/expenses">

                  {location.location.pathname == "/task-config/task-config" ? <Link to={backPage || "/operational-date"}><a href="#" className="float-end btn btn-gray">Go Back</a> </Link> : <a href="expenses" className="float-end btn btn-gray">Back</a>}
                </Link></div>
              </div>
              <div className="row justify-content-center  mt-3 form-container">
                <div className="col-md-12">
                  {permission.status == 3 ?
                    <div className="form-wrap">
                      <div className="text-center">
                        {permission.message}
                      </div></div> :
                    <>
                      <div>
                        {Loading ? ListReminder :
                          // <div class="loader text-primary">Loading...</div>
                          <Loader />
                        }
                        <div className="form-wrap list-wrp">
                          <div className="row mb-3">
                            <div className="col-md">
                              <div className="row">
                                <div className="col-md-1 col-2 pe-0">
                                  <img src={museum} className="img-fluid" alt=".." width="25px" />
                                </div>
                                <div className="col ps-0">
                                  <h3 className="table-title mt-2">Task and Reminders</h3>

                                </div>
                                <div className="col-md">
                                  <a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#bank" onClick={toggle}>Add Task and Reminders </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="input-group mb-3 search">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch}  />
                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

                              </div>
                            </div>
                          </div>
                          <div className="table-widget">
                            <table className="table border">
                              <thead>
                                <tr>
                                  <th>Task / Reminder</th>
                                  <th>Description</th>
                                  <th>Last Action Date</th>
                                  <th>Repeat Frequency</th>
                                  <th>Frequency Unit</th>
                                  <th>Next Action Date</th>
                                  <th>Remind Before</th>
                                  <th>Stop After</th>
                                  <th>Stop After No Of Times</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  list_data.map(reminder => (
                                    <tr>
                                      <td>{reminder.task_name} </td>
                                      <td> {reminder.description}</td>
                                      <td>{reminder.last_action} </td>
                                      <td> {reminder.frequency}</td>
                                      <td> {reminder.frequency_unit}</td>
                                      <td>{reminder.next_action} </td>
                                      <td>{reminder.remind_before} </td>
                                      <td>{reminder.stop_after} </td>
                                      <td>{reminder.stop_after_unit == "-1" ? "":reminder.stop_after_unit} </td>

                                      <td><div className="d-flex icon-btn">
                                        <button onClick={() => edit_Reminder(reminder.id)}  >  <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </button>
                                        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={() => delete_task(reminder.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

                                        </button>


                                      </div></td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {Loading ? <PaginationComp
                              pages={pages}
                              handlePagination={handlePagination}
                            /> : ""
                            }

                          </div>
                        </div>

                        {location.location.pathname == "/task-config/task-config" ? "" : double == true ? <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={handleSkip}>Skip/Continue</a></div> : <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" disabled onClick={handleSkip}>skip/Continue</a></div>}

                      </div>
                    </>}
                </div>

              </div>
            </div>
            {isShowing ? (<TaskModal
              isShowing={isShowing}
              hide={toggle}
              handleInputs={handleInputs}
              prevNonNumericalWithDec={prevNonNumericalWithDec}
              inputValues={inputValues}
              input={input}
              errors={errors}
              //  handleChange={handleChange}
              handleSelect={handleSelect}
              // success={success}
              double={double}
              handleSubmit={handleSubmit}

            />) : null
            }

            <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel="My dialog"
              className="mymodal custommodal"
              overlayClassName="myoverlay"
            >
              <div class="modal-content">
                <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">TASK AND REMINDERS</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
                <div class=" modal-body  modal-body"><p>Are you sure you want to delete this TASK AND REMINDER</p></div>
                <div class="justify-content-end  modal-footer"><div class="d-flex">
                  <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
                  {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteTask}>Yes</button> : <button type="submit" disabled class="btn btn-primary" onClick={deleteTask}>Yes</button>}
                </div>
                </div>
              </div>




            </Modal>


          </div>
        </div>
      </div>

    </>
  )
}

export default TaskConfig