
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import React, { useState } from "react";
import museum from  '../../img/museum.svg'
import ReactDOM from 'react-dom';
import { API_URL } from "../../constant/API_Settings";
import ExpensesConfigModal from './ExpensesConfigModal'
import swal from "sweetalert";
import { useHistory } from "react-router";
import { useEffect,useMemo } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import {convertAmountToWords,preventNonNumericalInput} from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';

var ExpensesModal = (props) => ReactDOM.createPortal(
  <ExpensesConfigModal {...props} />, document.body
       ); 

       const  PaginationComp  = (props) => (
        < PaginationComponent  {...props} />
           );
const ExpensesConfig = (location) => {
  const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);
  const UseModal = () => {
 
    const [isShowing, setIsShowing] = useState(false);
  
    function toggle() {
      var isdayclose = checkoption.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
      if(isShowing) 
			{
			   setInputValues([])
			   setErrors({})
			   //setSuccess({})
			  // setFields([''])
		   }
       if(!isShowing){
         setDouble(true)
       }
      setIsShowing(!isShowing);
    }
  }
  
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])

  const [checkoption, ckeckOption] = useState([])
  const [inputValues, setInputValues] =useState({});
  const {isShowing, toggle} = UseModal();
  const history=useHistory()
  const[list_data, setList] = useState([])
  const [payment_id, setId] = useState([])
  const [errors, setErrors] = useState([ ])
  
  const handleInputs = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,

    });
  };
 
  const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,

    });
    convertAmountToWords(e,e.target.value)
  };
 
  const [search,setSearch]=useState('')
  const  handleSearch = (event) =>
  {
	 setSearch(event.target.value)
  }

 
  useEffect(()=>{
check_url()
setLocation(location.location.pathname)	
  },[]); 
  const [path, setLocation] = useState([])
//------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
        if(location.location.pathname == "/expenses/expenses")
				{
					history.push(location.location.pathname);
				}
				else
				{
				history.push(responseData.url);
				}
        ListAssets(pages.current_page);
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
function check_meta_date() {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
//--------------------------//
 
  const [pages,setpages]=useState(
	{
	current_page:1,
	last_page:0,
	total_pages:0,
	pagination_item:[],
	total_pages:0,
})

const handlePagination = (number) =>
    {
        setpages({
          ...pages,
          current_page:number
        }
            )
  
            ListAssets(number)
  
    }

  function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.expense_head){
      formIsValid = false;
      setDouble(true)
      errors["expense_head"] = "Enter expense head"
    }
 
    
    setErrors(errors)
    
     return formIsValid;

  }

  const handleSubmit = (event) =>
  {
    event.preventDefault();
     setDouble(false)
    // if(validateForm())
    // {
      sendExpenseDetails()
    // }

  }
  const handleSkip = (event) =>
  {
    event.preventDefault();
    setDouble(false)
    history.push("task-config")
  }

      function sendExpenseDetails()
     {

       const requestOptions = {
         method: 'POST',
         headers: {
           "Access-Control-Allow-Origin": "*",
           "Content-Type":"application/json",
           "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
           "Accept": "application/json"
         },
         body: JSON.stringify(inputValues)
       };
       
        fetch(API_URL+"/expense",requestOptions)
       .then(response =>
        {
          return response.json();
        })
        .then(responseData => {
         let errors =[]
         if(responseData.status == false){
          // swal("",responseData.message,"warning")            
          // errors["expense_head"] = responseData?.response?.expense_head
          errors["expense_head"] = "Already exists"
          if (responseData?.response?.expense_head)
          {
            errors["expense_head"] =responseData?.response?.expense_head
          }
         setDouble(true)
         }
         else{
          swal("",responseData.message,responseData.status?"success":"warning")     
          toggle();
          setDouble(true)
         }
         setErrors(errors)
         ListAssets(pages.current_page);
          // setInputValues("")
          return responseData;
        }) .catch(e => {
          swal("Something went wrong","","warning")
          console.log(e);
          setDouble(true)
        });
     }
   async function edit_expense(id){

      const requestOptions = {
        method: 'get',
        data: {id:id},
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-Type":"application/json",
          "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
          "Accept": "application/json"

        },
      };
      await fetch(API_URL+"/expense/"+id+"/edit",requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {
        

          setInputValues(
                {
                  expense_head : responseData.data.accounts_head,
                  expense_amount : responseData.data.limit_amount,
                  id:responseData.data.id ? responseData.data.id : '',
                  ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,
                }
            );
                toggle();
          return responseData;

        })
        .catch(e => {
          console.log(e);
        });
    }

    //loader
    const [permission, setPermission] = useState([])
const [Loading,setLoading]=useState(false);
    function ListAssets(page)
     {
        const requestOptions = {
          method: 'get',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization":"Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
          },
        };
      
         fetch(`${API_URL}/expense?search_keyword=${search}&page=${page}` ,requestOptions)
              .then(response => {return response.json();})
              .then(responseData => {
                if(responseData.status==3) 
                {
                  setPermission({...permission,status:responseData.status,message:responseData.message})
                }
                setList(responseData.data.data)
                ckeckOption({...checkoption,
                  isdayclose:responseData.isdayclose})
              
    
                       setpages({
                        ...pages,
                        current_page: responseData.data.current_page,
                        last_page: responseData.data.last_page,
                        per_pages:  responseData.data.per_page,
                        total_pages:responseData.data.total,
                         
                        })
                   setLoading(true)
              return responseData;

              })
              .catch(e => {
                console.log(e);
                
              });
            }
            function delete_expense(id)
            {
              var isdayclose = checkoption.isdayclose;
    if(isdayclose == 1)
    {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else
    {
         
              deleteId(id)
             
              toggleModal()
            }
          }
            function deleteExpense()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/expense/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListAssets(pages.current_page);
           toggleModal()
          setDouble(true)
       return responseData;
     
   
         })
   
     .catch(err=>{
       swal("Something went wrong","","warning")
       console.log(err)
            
         })
  }

  return(
        <>
          <div className="main-container page-wrap">
<div className="d-md-flex">
{path == "/expenses/expenses"? <Sidebar_Progress path={path} />:<Sidebar_Progress />}
<div className="content-area">
<LoginHeader page_title="" />
<div className="container pb-3">

<div class="row">

 <div className="col-md-12 mb-3 px-4 mt-2">{path == "/expenses/expenses"?<Link to={backPage || "/expense"}>
        <a href="#" className="float-end btn btn-gray">Go Back</a>
      </Link>:<Link to="/other-income">
        <a href="other-income" className="float-end btn btn-gray">Back</a>
      </Link>}</div>
 </div>

	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
    {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
                      {Loading ?  ListAssets : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col-md-2 ps-0">
							<h3 className="table-title mt-2">EXPENSES</h3>            
						</div>
						<div className="col-md-4">
							<a className="btn btn-primary px-5 mb-md-0 mb-2  mx-5"  data-bs-target="#bank" onClick={toggle}>Add Expenses</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Expense Head "  value={search.search_keyword} onChange={handleSearch}    />
<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

</div>
					</div>
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Expense Head</th>
      <th>Expense Limit (amount)</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {
     list_data.map(expense => (
     <tr>
      <td> {expense.accounts_head}</td>
      <td> {expense.limit_amount}</td>
     
      <td><div className="d-flex icon-btn">
      <button onClick= {() => edit_expense(expense.id)}>  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>
      <button  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_expense(expense.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
    </tr>
  
))}
</tbody>
</table>
{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
				</div>
			</div>
      {path == "/expenses/expenses"?"": double == true ?<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={handleSkip}>skip/Continue</a></div>:<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" disabled onClick={handleSkip}>skip/Continue</a></div>}
    
  	</div>
    </>}
	</div>
</div>

</div>
</div>
</div>
</div>
{isShowing ? (<ExpensesModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        preventNonNumericalInput={preventNonNumericalInput}
        double={double}
        handleInput={handleInput}
        inputValues={inputValues}
        handleSubmit={handleSubmit}
        errors={errors}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">EXPENSES</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Expense</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
				{double == true ?	<button type="submit" class="btn btn-primary"  onClick={ deleteExpense}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={ deleteExpense}>Yes</button>}
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>
</>
    )
}
    
export default ExpensesConfig