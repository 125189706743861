import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import museum from '../../img/museum.svg'
import { useState, useMemo } from 'react';
import VendorModal from './VendorModal'
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Loader from '../operational-model/Employee/Loader.component'
import { convertAmountToWords,prevNonNumericalWithDec,preventNonNumericalInput } from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';

var SaveModal = (props) => ReactDOM.createPortal(
  <VendorModal  {...props} />, document.body
);
const  PaginationComp  = (props) => (
  < PaginationComponent  {...props} />
     );

const VendorConfig = (location) => {
  const backPage = useMemo(() => {

    if (location?.location?.backpage) {
      return location?.location?.backpage
    }
    else
      return undefined
  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);

  const handleInput = (e) => {

    convertAmountToWords(e, e.target.value)
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });


  };
  const handleInputs = (e) => {

    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });
  };
  const handleSelect = (items) => {
    console.log(items)
    var product_name = []

    items && items.map((item) => {
      product_name.push(item)
    })

    setInputValues({
      ...inputValues,
      product_name: product_name.map(product =>


        product.value)
    });

    setSelectedOptions(product_name);

  };

  const [checkoption, ckeckOption] = useState([])
  const [path, setLocation] = useState([])
  const [search, setSearch] = useState('')
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }



  useEffect(() => {
    check_url()
    setLocation(location.location.pathname)
  }, []);
  const [pages, setpages] = useState(
    {
      current_page: 1,
      last_page: 0,
      total_pages: 0,
      pagination_item: [],
      total_pages: 0,
    })

  const handlePagination = (number) => {
    setpages({
      ...pages,
      current_page: number
    }
    )

    ListVendor(number)

  }

  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);
    

    function toggle() {
      var isdayclose = checkoption.isdayclose;
      if (isdayclose == 1) {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else {
        if (isShowing) {
          setInputValues({})
          setErrors({})
          setSuccess({})
          setSelectedOptions([])
          setList([])
        }
        if (!isShowing) {
          getProduct();
          setDouble(true)
          // setInputValues({})
          setErrors({})
          // setSuccess({})
          // setSelectedOptions([])
        }
        setIsShowing(!isShowing);
      }
    }

    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [delete_id, deleteId] = useState([])
  const history = useHistory()
  const { isShowing, toggle } = UseModal();
  const [inputValues, setInputValues] = useState({});
  const [dropdownData, setDropdownData] = useState([])
  const [list_data, setList] = useState([])
  const [selected_options, setSelectedOptions] = useState([])
  const [list, setListVendor] = useState([])
  const [success, setSuccess] = useState([])
  const [permission, setPermission] = useState([])
  const [errors, setErrors] = useState([])
  const [stat_operation, setStartoperation] = useState();	

  function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.name) {
      formIsValid = false;
      setDouble(true)
      errors["name"] = "Enter Vendor Name"
    }
    if (inputValues.credit_period >100) {
      formIsValid = false;
      setDouble(true)
      errors["credit_period"] = "Maximum value 100"
    }
     
    if(stat_operation!="Yes"){
    if (inputValues.opening_balance != undefined) {
      if ((inputValues.opening_balance != "") && (inputValues.debit_credit == undefined || inputValues.debit_credit === "")) {
        formIsValid = false;
        setDouble(true)
        errors["debit_credit"] = "Select Debit/Credit"
      }
    }

    if ((inputValues.opening_balance == undefined) ){
      formIsValid = false;
      setDouble(true)
      errors["opening_balance"] = "Enter Opening Balance"
    }
    if (inputValues.debit_credit == undefined) {
      formIsValid = false;
      setDouble(true)
      errors["debit_credit"] = "Select Credit/Debit"
    }

    }
    if (inputValues["phone"] ) 
    {
     
      if (inputValues["phone"].length != 10) {
        formIsValid = false;
        setDouble(true);
        errors["phone"] = "Enter Valid Phone Number";

     }
    }
    if (inputValues["email"]) 
    {
    if (typeof inputValues["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(inputValues["email"])) {
        formIsValid = false;
        setDouble(true)
        errors["email"] = "Enter valid Email Id"
      }
    }
  }
  
    setErrors(errors)
    return formIsValid;
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    setDouble(false)
    if (validateForm())
      sendVendor()

  }

  //------------------------url
  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        setStartoperation(responseData.stat_operation)
        if (responseData.allowurl == 1) {
          if (location.location.pathname == "/vendor-config/nf-purchase") {
            history.push(location.location.pathname);
          }
          else {
            history.push(responseData.url);
          }

        
          ListVendor(pages.current_page)


        }
        else {

          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        //	console.log(responseData.op_date)


        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  //--------------------------//
  async function sendVendor() {
    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify(inputValues)
    };
    await fetch(API_URL + "/vendor", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        })
        if (responseData.status == false) {
          errors["name"] = responseData.response.name
          errors["opening_balance"] = responseData.response.opening_balance
          errors["phone"] = responseData.response.phone
          setDouble(true)
        }
        if (responseData.status == 3) {
          swal("", responseData.message, responseData.status ? "warning" : "success")
          setDouble(true)
        }
        else if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          setDouble(true)
          setInputValues("")
          setErrors("")
          toggle();
          ListVendor(pages.current_page);
        }
        setErrors(errors)

        return responseData;
      }).catch(e => {
        swal("Something went wrong", "", "warning")
        console.log(e);
        setDouble(true)
      });
  }
  function getProduct() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    fetch(API_URL + "/list-product", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {

        responseData.forEach((elem) => {
          setList((list_data) => [
            ...list_data,
            {
              value: elem.id,
              label: elem.product_name,
            },
          ]);
        });

        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }
  function edit_Vendor(id, ac_name) {

    const requestOptions = {
      method: 'get',
      data: {
        id: id,
        ac_name: ac_name

      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    //  console.log(API_URL+"/vendor/"+id+"/edit")
    fetch(API_URL + "/vendor/" + id + "/edit", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        var id_itr = 0
        var product_names = []
        if (responseData[0].product_name) {
          responseData[0].product_name.split(',').forEach((product_name) => {
            product_names.push({
              value: responseData[0].product_id[id_itr],
              label: product_name,
            })
            //  setList([
            //   ...list_data,
            //   {
            //     value: responseData[0].product_id[id_itr],
            //     label: product_name,
            //   },
            // ])
            id_itr++
          })
          setSelectedOptions(product_names)
        }

        setInputValues({
          id: responseData[0].id ? responseData[0].id : "",
          ac_name: responseData[0].ac_name ? responseData[0].ac_name : "",
          name: responseData[0].name,
          address: responseData[0].address,
          phone: responseData[0].phone,
          email: responseData[0].email,
          credit_period: responseData[0].credit_period,
          opening_balance: responseData[0].opening_balance,
          debit_credit: responseData[0].debit_credit,
          product_name: responseData[0].product_id,
          ob_is_dayclose: responseData.ob_is_dayclose == 1 ? true : false,

        });
        toggle();
        // setInputValues("")
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }

  //loader
  const [Loading, setLoading] = useState(false);


  function ListVendor(page) {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    fetch(`${API_URL}/vendor?search_keyword=${search}&page=${page}`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        if (responseData.status == 3) {
          setPermission({ ...permission, status: responseData.status, message: responseData.message })
        }
        setListVendor(responseData.data.data)
        ckeckOption({
          ...checkoption,
          isdayclose: responseData.isdayclose
        })
      

        setpages({
          ...pages,
          current_page: responseData.data.current_page,
          last_page: responseData.data.last_page,
          per_pages:  responseData.data.per_page,
          total_pages:responseData.data.total,
           
          })
        setLoading(true)
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }

  function delete_vendor(id) {
    var isdayclose = checkoption.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {
      deleteId(id)

      toggleModal()
    }
  }
  function deleteVendor() {
    setDouble(false)
    // console.log(...delete_id)
    const requestOptions = {
      method: 'DELETE',

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify(delete_id)
    };
    fetch(API_URL + "/vendor/" + delete_id, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        ListVendor(pages.current_page);
        toggleModal()
        setDouble(true)
        return responseData;


      })

      .catch(err => {
        swal("Something went wrong", "", "warning")
        console.log(err)
      })
  }

  function setProduct() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify(inputValues)
    };
    fetch(API_URL + "/check-product", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message)

        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }

  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          {path == "/vendor-config/nf-purchase" ? <Sidebar_Progress path={path} /> :
            <Sidebar_Progress />}
          <div className="content-area">
            <LoginHeader page_title="" />

            <div className="container pb-3">

              <div className="row justify-content-end mt-3">

                {path == "/vendor-config/nf-purchase" ?
                  <div className="col-md-2 ">
                    <Link to={backPage || "/operational-date"} className="btn btn-gray">Go Back</Link>
                  </div> :
                  <div className="col-md-1 ">
                    <Link to="/MLA-coupon" className="btn btn-gray">Back</Link></div>}


              </div>
              <div className="row justify-content-center  mt-3 form-container">

                <div className="col-md-12">
                  {permission.status == 3 ?
                    <div className="form-wrap">
                      <div className="text-center">
                        {permission.message}
                      </div></div> :
                    <>
                      <div>
                        {Loading ? ListVendor :
                          // <div class="loader text-primary">Loading...</div>
                          <Loader />
                        }
                        <div className="form-wrap list-wrp">
                          <div className="row mb-3">
                            <div className="col-md">
                              <div className="row">
                                <div className="col-md-1 col-2 pe-0">
                                  <img src={museum} className="img-fluid" alt=".." width="25px" />
                                </div>
                                <div className="col ps-0">
                                  <h3 className="table-title"> Vendor Configuration </h3>

                                </div>
                                <div className="col-md">
                                  <a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#vendor" onClick={toggle}>ADD Vendor Configuration</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div class="input-group mb-3 search">
                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

                              </div>
                            </div>
                          </div>


                          <div className="table-widget">
                            <table className="table border">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Address</th>
                                  <th>Phone </th>
                                  <th>Email</th>
                                  <th>Credit Period per Bill</th>
                                  <th >Product Name</th>
                                  <th>Configured Opening Balance</th>
                                  <th>Credit/Debit</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  list.map((vendor) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{vendor.name}  </td>
                                          <td>{vendor.address}   </td>
                                          <td>{vendor.phone}</td>
                                          <td>{vendor.email}</td>
                                          <td>{vendor.credit_period}</td>
                                          <td>{vendor.product_name}</td>
                                          <td className="text-end">{vendor.opening_balance}</td>
                                          <td>{vendor.debit_credit == "Cr" ? "Credit" : vendor.debit_credit == "Dr" ? "Debit" : ''}</td>
                                          <td><div className="d-flex icon-btn">
                                            <button onClick={() => edit_Vendor(vendor.id, vendor.ac_name)} >  <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </button>
                                            <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={() => delete_vendor(vendor.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

                                            </button>




                                          </div></td>
                                        </tr>
                                      </>
                                    )
                                  })}
                              </tbody>
                            </table>
                            {Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
                          </div>
                        </div>
                        {path == "/vendor-config/nf-purchase" ? "" : <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/dealership-config")}>Skip/Continue</a></div>}
                      </div>
                    </>}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {isShowing ? (<SaveModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        inputValues={inputValues}
        dropdownData={dropdownData}
        selected_options={selected_options}
        prevNonNumericalWithDec={prevNonNumericalWithDec}
        preventNonNumericalInput={preventNonNumericalInput}
        list_data={list_data}
        list={list}
        double={double}
        success={success}
        handleInput={handleInput}
        handleSelect={handleSelect}
        handleSubmit={handleSubmit}
        errors={errors}
        setProduct={setProduct}
        stat_operation={stat_operation}
      />) : null
      }
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        prevNonNumericalWithDec={prevNonNumericalWithDec}
        preventNonNumericalInput={preventNonNumericalInput}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >
        <div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">VENDOR CONFIGURATION</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body"><p>Are you sure you want to delete this Vendor</p></div>
          <div class="justify-content-end  modal-footer"><div class="d-flex">
            <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
           {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteVendor}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={deleteVendor}>Yes</button>}
          </div>
          </div>
        </div>
      </Modal>




    </>
  )
}

export default VendorConfig