import React from "react";
import { Component } from "react";
import '../../../App.css'
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";
import { Link } from "react-router-dom";
import LoginHeaderManager from "../LoginHeaderOperational";
class OperationalSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			permissions: localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(","),

		}

	}
	render() {

		return (



			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={this.props} progress={12} />
					<div className="content-area">
						<LoginHeaderManager props={this.props} page_title="" />



						<div className="container pb-3">
							<div className="row justify-content-center mt-md-5 mt-3 form-container">
								<div className="col-md-10">


									<div className="form-wrap py-3">
										<h3>Operational Settings</h3>
										<ul className="settings-menu">
											<li><Link to="/settings"><a href="/settings">Configuration Settings <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
										</ul>

										<span class="btn btn-primary">Employee Module</span>
										<br></br>
										<br></br>

										<ul className="settings-menu">
											<li><Link to="/operational-date"><a href="/operational-date">Operational Date <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/fuel-sell-price"><a href="/fuel-sell-price">Fuel Selling Price Change <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/nozzle-employee-list"><a href="/nozzle-employee-list">Employee Closing List<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/nozzle-closing"><a href="/nozzle-closing">Nozzle Closing  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/packed-oil-list"><a href="/packed-oil-list">Packed oil list  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/loose-oil-list"><a href="/loose-oil-list">Loose oil List  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/credit-sales"><a href="/credit-sales">Credit  sales <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/fleet-card-sales"><a href="/fleet-card-sales">Fleet card sales  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/mla-coupon-sales"><a href="/mla-coupon-sales">MLA coupon  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/card-sales"><a href="/card-sales">Card sales <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/cashpayment"><a href="/cashpayment">Cash Payment  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/closepayment"><a href="/closepayment">close payment  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/bag-collection"><a href="/bag-collection">Bag-collection  <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/ReceiptVoucher"><a href="/ReceiptVoucher">Receipt Voucher <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/expense"><a href="/expense">Expense <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<br></br>
											<span class="btn btn-primary">Manager Module</span>
											<br></br>
											<br></br>
											{this?.state?.permissions?.includes("Non Fuel Product Direct Sales") ?
												<li><Link to="/nonfuel-product-direct"><a href="/nonfuel-product-direct">Non-Fuel  Product Direct Sales <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''}
											<li><Link to="/account-to-account-transfer"><a href="/account-to-account-transfer">Account to Account Transfer<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{this?.state?.permissions?.includes("Non Fuel Product Purchase") ?
												<li><Link to="/nonfuel-product-purchase"><a href="/nonfuel-product-purchase">Non-Fuel Product Purchase<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''}
											<li><Link to="/product-transfer-rack"><a href="/product-transfer-rack">Product Transfer to Rack <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="NonFuel-product-Selling-Price-Change"><a href="/NonFuel-product-Selling-Price-Change">Non-Fuel Product Selling Price Change<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{/* <li><Link to="/purchase-return-non-fuel"><a href="/purchase-return-non-fuel">Purchase Return Non-Fuel Product <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li> */}
											<li><Link to="/user-management-op"><a href="/user-management">User Management <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{/* <li><Link  to="/day-closing-operation"><a href="/day-closing-operation">Day Closing Operation<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
		   <li><Link  to="/day-closing-screen"><a href="/day-closing-screen">Day Closing Screen<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li> */}
											<li><Link to="/subscription-management"><a href="/subscription-management">Subscription Management<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{this?.state?.permissions?.includes("Payment Voucher") == true ?
												<li><Link to="/payment"><a href="/payment">Payment Voucher<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''}
											{this?.state?.permissions?.includes("Reciept Voucher") == true ?
												<li><Link to="/receipt"><a href="/receipt">Reciept Voucher<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''}
											<li><Link to="/op-fuelunloading"><a href="/op-fuelunloading">Fuel Purchase And Unloading<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											<li><Link to="/day-closing"><a >Day Closing<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
											{/* {this?.state?.permissions?.includes("Edit Fuel Configuration") == true ?
												<li><Link to="/op-fuel-edit"><a >Edit Fuel Configuration<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''} */}
											{this?.state?.permissions?.includes("Density") == true ?
												<li><Link to="/op-dsr-report"><a href="/op-dsr-report">DSR Report <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ''}
											{this?.state?.permissions?.includes("Density") == true ?
												<li><Link to="/op-density-report"><a href="/op-density-report">Density Record<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></a></Link></li>
												: ""}
										</ul>

									</div>

								</div>
							</div>
						</div>
					</div>


				</div>
			</div>

		)
	}
}
export default OperationalSettings;

