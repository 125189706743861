import React from 'react';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const PrintModalConfig = ({isOpen,hide ,print_details,shift}) => {
  const componentRef = useRef();
const handlePrint = useReactToPrint({
 
  content: () => (
	  componentRef.current
	  )
  });
 
      const PrintDetails = () => {
      return(
         <>
         </>    
        );
      }
      
    return (
        <React.Fragment>
          
            <div className="modal-overlay"   />
                <div className="modal-wrapper" >
        <div className="modal modalx">
        <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle"></h5><button type="button" onClick={hide} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
        <div className="modal-body" >
          <div className="row" >
            <div className="col-md-12" >
              <div className="print" ref={componentRef}>
            <div className="row g-2 align-items-center   form-group " >

<div className="col-8">
    <label className="col-form-label">Reference Number</label>
  </div>
  <div className="col-4">
    <label >{print_details.ref_no}</label>
    
  </div>
  </div>
                <div className="row g-2 align-items-center   form-group ">

<div className="col-8">
    <label className="col-form-label">Name of Employee</label>
  </div>
  <div className="col-4">
    <label> {print_details.get_employee.name}</label>
    
  </div>
  </div>
 
  <div className="row g-2 align-items-center   form-group ">

<div className="col-8">
    <label className="col-form-label">Date</label>
  </div>
  <div className="col-4">
    <label  >{print_details.op_date}</label>
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-8">
    <label className="col-form-label">Amount Collected</label>
  </div>
  <div className="col-4">
    <label   > {print_details.amount}</label>
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-8">
    <label className="col-form-label">Shift Time</label>
  </div>
  <div className="col-4">
    <label >{shift.shift_time}</label>
  
  </div>
  </div>
  </div>
 
  <div class="modal-footer text-center">
 
      <a   href="#" className=" btn btn-success  w-auto" onClick={handlePrint}>Print<div style={{ display: "none" ,margin: "10", padding: "10" }}><PrintDetails ref={componentRef} /></div> </a>
</div>
         </div>
                </div>
                </div>
                </div>
</div>

       </React.Fragment>

    );
}
export default PrintModalConfig
