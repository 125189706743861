import React, { useEffect } from 'react';
import { account_type } from "../../constant/constant"
import Select from 'react-select';
import ReactTooltip from 'react-tooltip' 

const BankConfigModal = ({ preventNonNumericalInput,prevNonNumericalWithDec,isShowing,double, selected_options,handleIn, hide,selected,handleSelect, errors,success, inputValues, dropdownData, handleSubmit, handleInputs,stat_operation }) => {
 
 
  useEffect(() => {
  
    let testData = dropdownData.filter(data => {
      return data[0] == inputValues["digital_payment_type"]
    });
   
  })
  return (
 <React.Fragment>

<div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modalxl">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Bank</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form>
          <div class="row mt-2">
            <div class="col-md-6">
         <div class="form-group mb-3">
          <label >Bank Full Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="bank_name" value={inputValues["bank_name"]} onChange={handleInputs} placeholder=""/>
         <div className="errorMsg">{errors.bank_name}</div>
       </div>
       <div class="form-group mb-3">
         <label>Bank Short Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="short_name" value={inputValues["short_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.short_name}</div>
       </div>
       <div class="form-group mb-3">
         <label>Account Type<sup class="star">*</sup></label>
         <div class="select-container">
           <select  class="form-control" name="account_type" value={inputValues.account_type} onChange={handleInputs}>
             <option value="">Account Type </option>
             <option value="1">Current Account</option>       
             <option value="2">Savings Account</option>
             </select>
             </div>
             <div className="errorMsg">{errors.account_type}</div>
       </div>
    
       { inputValues.account_type == 1 &&
       <div class="form-group mb-3">
         <label>Current Account Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="ac_type_name" value={inputValues["ac_type_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.ac_type_name}</div>
       </div>
}
   {/* { inputValues.account_type == 2 &&
       <div class="form-group mb-3">
         <label>Loan account name</label>
         <input type="text" class="form-control" name="ac_type_name" value={inputValues["ac_type_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.ac_type_name}</div>
       </div>
  } */}
   { inputValues.account_type == 2 &&
       <div class="form-group mb-3">
         <label>Savings Account Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="ac_type_name" value={inputValues["ac_type_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.ac_type_name}</div> 
       </div>
   }
  
   { inputValues["ac_type_name"] ? inputValues["ac_type_name"] : '' } { inputValues["short_name"] ? inputValues["short_name"] : '' } { selected["account_type"] ? selected.account_type: '' }  
       <div class="form-group mb-3">
         <label>Address</label>
         <input type="text" class="form-control" name="address" value={inputValues["address"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.address}</div>
       </div>

       {stat_operation != "Yes" ?
              <div className="form-group">
              
                <label>Opening Balance</label>
                <input
                  type="number"
                  onKeyPress={(event)=>preventNonNumericalInput(event,inputValues["opening_balance"])}
                  className="form-control hide-arrow"
                  placeholder="opening balance"
                  name="opening_balance"
                  id={"open"}
                  value={inputValues["opening_balance"]}
                  onChange={handleIn}
                  readOnly={inputValues["ob_is_dayclose"]}
                />
                <div className="errorMsg">{errors.opening_balance}</div>
                <span  class="amount-words"></span>
                {/* {inputValues.opening_balance != null && (
                  <div className="errorMsg">{errors.debit_credit}</div>
                )} */}
              </div>
              :""}
            

  </div>
  
       <div class="col-md-6 ">
                <div class="form-group mb-3 ">
                  <label>Branch</label>
         <input type="text" class="form-control"  name="branch" value={inputValues["branch"]} onChange={handleInputs}/>
         <div className="errorMsg">{errors.branch}</div>
       </div>
       <div class="form-group mb-3">
         <label>Account Number<sup class="star">*</sup></label>
         <input type="number" class="form-control hide-arrow ph_no" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}  name="account_number" id={"account"} value={inputValues["account_number"]} onChange={handleInputs}/>
         <div className="errorMsg">{errors.account_number}</div>
       </div>
       <div class="form-group mb-3">
         <label>Phone</label>
         <input type="number" class="form-control hide-arrow ph_no" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="phone"  id={"phone"} value={inputValues["phone"]} onChange={handleInputs} />
         
                    <div className="errorMsg">{errors.phone}</div> 
               
         
       </div>
       <div class="form-group mb-3">
         <label>Email</label>
         <input type="email" class="form-control"  name="email" value={inputValues["email"]}  onChange={handleInputs}  />
         
                    <div className="errorMsg">{errors.email}</div> 
               
       </div>

       {/* { (inputValues.account_type == 1  ||  inputValues.account_type == 3) && */}
       <div class="form-group mb-3">
         <label>Digital Payment Type </label>
        
         <Select   
     isMulti
       value={selected_options}
       onChange={handleSelect}
       options={dropdownData}
      > 
      </Select>
            <p className="note mt-2">Note: Select digital payment type linked with bank account</p>
            <div className="errorMsg">{errors.digital_payment_type}</div>
      
          
       </div>
{/* } */}
{/* {  inputValues.account_type == 3 &&
       <div class="form-group mb-3">
         <label>Digital payment Type </label>
        <div class="select-container">
          <select id="3" class="form-control"  name="digital_payment_type" value={inputValues["digital_payment_type"]} onChange={handleInputs}>
            <option value="">Digital payment Type</option>
            {dropdownData.map((data)=>{
               return <option value={data[0]}>{data[1]}</option>
              })
            }
            </select></div>
            <div className="errorMsg">{errors.digital_payment_type}</div>
       </div>
}  */}
  
             </div> 

             {/* <div className="col-md-4 d-flex">
              <div className="form-check col-sm pr-0">
                <input
                  className="form-check-input"
                  type="radio"
                  id="exampleRadios1"
                  name="debit_credit"
                  value="Cr"
                  onClick={handleInputs}
                  checked={inputValues.debit_credit === "Cr"}
                />
                <label
                  className="form-check-label col-form-label"
                  for="exampleRadios1"
                >
                  {" "}Credit{" "}
                </label>
              </div>

              <div className="form-check col-sm pr-0">
                {/* {...inputValues.debit_credit == "Cr" && "checked"} 
                    {...inputValues.debit_credit == "Dr" && "checked"} */}

                {/* <input
                  className="form-check-input"
                  type="radio"
                  id="exampleRadios1"
                  name="debit_credit"
                  value="Dr"
                  onClick={handleInputs}
                  checked={inputValues.debit_credit === "Dr"}
                />
                <label
                  className="form-check-label col-form-label"
                  for="exampleRadios1"
                >
                  {" "}Debit{" "}
                </label>
              </div>
            </div> */} 
           

            </div>
            <div class="modal-footer justify-content-center">
           {/* <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Save Bank</button> */}
           {double===true ?  <button type="submit" class="btn btn-primary"  onClick={handleSubmit}>Save Bank</button> : <button type="submit" class="btn btn-primary" disabled>Save Bank</button>}
        </div>
  </form>
        </div>
      </div>


</React.Fragment>
    );
  }
       export default BankConfigModal;