import { Component } from "react";
import notification_bell from '../../../img/bell.svg'
import avatar from '../../../img/avatar.svg'
import { Link } from "react-router-dom";
import { API_URL } from "../../../constant/API_Settings"
import { WEB_URL } from "../../../constant/API_Settings"
import moment from 'moment'
class FuelLoginHeaderOperational extends Component
{
    constructor(props)
    {
        super(props)

        this.state = 
        {
          // inputValues={},
          op_date :'',
            'profile_name': localStorage.getItem("PROFILE_NAME"),
            // 'op_date': localStorage.getItem("op_date"),
            curTime : new Date().toLocaleString(),
            userId :''
           
        }
        var time = this.state.curTime.split(',')
        var time1 = time[1]
         console.log(time1)
         localStorage.setItem('time',time1);
       
      }

        componentDidMount()
        {
          this.check_meta_date()
         
        }
         check_meta_date=() =>{

          const requestOptions = {      
            method: 'post',
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
              "Accept": "application/json"
      
            }
          };
          fetch(`${API_URL}/check_meta_date`, requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {
            //	console.log(responseData.op_date)
              // if(!responseData.op_date )
              // {
              //   history.push("/nozzle-employee-list");
              // }
              // else{
                
                this.setState({ op_date:responseData.op_date,userId:responseData.userId})

              // ListMLAcoupon(1)
              // }
      
              return responseData;
            })
            .catch(e => {
            });
      
        }
       
    
    render()
    {
     
    return(<div className=""><nav className="navbar navbar-expand-lg navbar-light bg-light top-menu ">
			<button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
  <div className="container-fluid">
    <h3 className="navbar-head" href="#">Edit Fuel Configuration</h3>
    
    <div className="collapse navbar-collapse" id="navbarSupportedContent">

      <ul className="navbar-nav ms-auto mb-1 mb-lg-1">
      <li className="nav-item  ">
											<p className="mt-3 me-3">
                       
												{/* <i className="fa fa-clock-o me-2" aria-hidden="true"></i>  {localStorage.getItem("time")} */}
			


                  
 
                    	</p>

										</li>
										<li className="nav-item  ">

											<p className="mt-3 me-3">Operational Date:<i aria-hidden="true"></i>{moment(this.state.op_date).format("DD-MM-YYYY")}</p>


										</li>
      <li className="nav-item dropdown notify">

          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
         <img src={notification_bell} width="20px;" />
         <span className="badge bg-danger">2</span>
          </a>
          <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
          	  <li><h4 className="notify-head">Notification 6</h4></li>
            <li><a className="dropdown-item" href="#"><strong>Lorem Ipsum</strong><span>Lorem Ipsum is simply dummy text of the printing</span><small>2 day ago</small></a></li>
             <li><a className="dropdown-item" href="#"><strong>Lorem Ipsum</strong><span>Lorem Ipsum is simply dummy text of the printing</span><small>2 day ago</small></a></li>
            <li><a className="dropdown-item text-center notify-all" href="#">show all notifications</a></li>
          </ul>
       </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle user-head" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img className="img-profile rounded-circle" src={avatar}/>{this.state.profile_name}<span><i className="fa fa-angle-down font-weight-bolder" aria-hidden="true"></i></span>
          </a>
        
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            {/* <li><a className="dropdown-item" href="#"><i class="fa fa-user me-2" aria-hidden="true"></i>Profile</a></li>
            <li><Link  to="/operational-settings"><a className="dropdown-item" href="/operational-settings" ><i class="fa fa-wrench me-2" aria-hidden="true"></i>Operational Settings</a></Link></li>
          
            <li><a class="dropdown-item" href="#"><i class="fa fa-wrench me-2" aria-hidden="true"></i>Report &raquo; </a>
			  	 <ul class="submenu dropdown-menu">
				    <li><Link  to="/daily-transaction-sheet"><a class="dropdown-item" href="#">Daily Transaction</a></Link></li>
				    <li><Link  to="daily-transaction-summary"><a class="dropdown-item" href="#">Daily Transaction Summary</a></Link></li>
            <li><Link  to="op-simplified-balance-sheet"><a class="dropdown-item" href="#">Balance Sheet Summary</a></Link></li>
				    
				    <li><Link  to="/op-worksheet"><a class="dropdown-item" href="#">WorkSheet</a></Link></li>
		
				    <li><a class="dropdown-item" rel="noreferrer" target="_blank" href={`${WEB_URL}/other-report/${this.state.userId}`}>Other Reports</a></li>

				 </ul>
			  </li>
          
            <li><hr className="dropdown-divider"/></li> */}
            <li><Link className="dropdown-item" to="/logout"><i class="fa fa-sign-out me-2" aria-hidden="true"></i>Logout</Link></li>
          </ul>
        </li>
        
      </ul>
      
    </div>
  </div>
</nav>
</div>

)
    }

  }

export default FuelLoginHeaderOperational