
import ManagerLoginHeader from "../operational-model/Manager/ManagerLoginHeader.component"
import Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component"
import museum from '../../img/museum.svg'
import { useState, useMemo } from 'react';
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import { useParams } from "react-router-dom";
import PaginationComponent from '../layouts/PaginationComponent';

const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);

const AllNotification = (props, location) => {
   const [list_data, setList] = useState([])
   const [search, setSearch] = useState('')
   const handleSearch = (event) => {
      setSearch(event.target.value)
   }

   let { id } = useParams();
   console.log(id,"gh")
   const [pages, setpages] = useState(
      {
         current_page: 1,
         last_page: 0,
         total_pages: 0,
         pagination_item: [],
         total_pages: 0,
      })
   useEffect(() => {
      ListNotification(1)
   }, []);
  
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

      ListNotification(number)
	}
   function ListNotification(page) {


      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },

      };
      fetch(`${API_URL}/allnotification?search_keyword=${search}&page=${page}&n_id=${id}`, requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {

            setList(responseData.data)
            setpages({
					...pages,
					current_page: responseData.current_page,
					last_page: responseData.last_page,
					per_pages: responseData.per_page,
					total_pages: responseData.total,

				})
            var datas = []
            responseData.fuel_type.forEach(elem => {
               datas.push([elem.id, elem.fuel_name])
            })
console.log(responseData.current_page,responseData.last_page)
          
            return responseData;
         })
         .catch(e => {


         });
   }
   return (
      <>
         <div className="main-container page-wrap">
            <div className="d-md-flex">
               <Sidebar_Progress props={props} progress={8} operation={1} />
               <div className="content-area">
                  <ManagerLoginHeader props={props} page_title="" />

                  <div className="container pb-3 tab-view">
                     <div className="row mt-md-5 mt-3 form-container">
                        <div className="col-md-12">
                           {/* <FuelTabHeader tab={2} /> */}
                           <div class="form-wrap ">
                              <div class="tab-content" >
                                 <div class="tab-pane fade show active" >
                                    <div class="">
                                       <div class="row mb-3">
                                          <div class="col-md">
                                             <div class="row">
                                                <div class="col-md-2 col pl-0">
                                                   <h3 class="table-title">Notifications</h3>
                                                </div>
                                                <div class="col-md col pl-0">
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-md-4">
                                             <div class="input-group mb-3 search">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch}  />
                                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={ListNotification} >Submit</a>

                                             </div>
                                          </div>
                                       </div>
                                       <div class="table-widget">
                                          <table class="table border">
                                             <thead>
                                                <tr>
                                                   <th>Note</th>
                                                   <th> Title</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {
                                                   list_data.map(obj => (
                                                      <tr>
                                                         <td><a href={obj.url}>{obj.note}</a></td>
                                                         <td>{obj.title}</td>
                                                      </tr>
                                                   ))}
                                             </tbody>
                                           
                                            
                                          </table>
                                          <PaginationComp
																		pages={pages}
																		handlePagination={handlePagination}
																	/> 
                                       </div>
                                       <div>

                                       </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </>

   )
}

export default AllNotification