import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const NozzleEditModal = ({ preventNonNumericalInput,isShowning, hide,dropdownData,handleInput,double,handleSubmit,errors,inputValues }) => {

    return (
        <React.Fragment>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal  modalm">

                    <div class="modal-header mb-3">
                        <h5 class=" text-center" id="staticBackdropLabel">Nozzle</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                    <div class="row g-3 justify-content-center mb-2">
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Name<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control" name="nozzle_name" value={inputValues.nozzle_name}  onChange={handleInput}/>
                         <div className="errorMsg">{errors.nozzle_name}</div>
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Opening Reading<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  class="form-control hide-arrow" name="opening_reading" value={inputValues.opening_reading} onChange={handleInput} />
                         <div className="errorMsg">{errors.opening_reading}</div>

                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Tank<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                        <div class="select-container">
                           <select name="tank_id" class="form-control" onChange={handleInput} value={inputValues.tank_id}>
                           <option value="">Select Tank</option>
                           {
                            dropdownData.map((data) => {
                          return <option value={data[0]}>{data[1]}</option>
                        })
                      }
                           </select>
                         <div className="errorMsg">{errors.tank_name}</div>

                        </div>
                     </div>
                  </div>
                        <div className="modal-footer text-center">

                            <a className="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                           {double==true? <a className="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit}>SAVE</a>:<a className="btn btn-primary px-4 w-auto" type="submit" >SAVE</a>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}
export default NozzleEditModal