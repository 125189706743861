import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import ManagerLoginHeader from '../ManagerLoginHeader.component'
import Switch from "react-switch";

const NotificationSetting = (props) => {

	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [checked, setchecked] = useState(false)

	//---On Load---//		
	useEffect(() => {
		check_url()

	}, [search]);
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					ListNotiSetting();

				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function ListNotiSetting() {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(`${API_URL}/notification_setting_list`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setList(responseData.data)

				return responseData;
			})
			.catch(e => {


			});
	}

	function handleChange(e, i) {
		console.log(e)
		console.log(i)
		const selected = [...list_data]
		console.log(selected)
		selected[i].status = e ? "Yes" : "No"
		const requestOptions = {      //---employee listing---//
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				meta_name: selected[i].name,
				meta_value: selected[i].status
			})
		};
		fetch(`${API_URL}/update_notification_setting`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				return responseData;
			})
			.catch(e => {


			});

		//	setList(selected)
		ListNotiSetting();
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="Notification Settings" />

						<div className="container pb-3">
						{permissions.includes("Notification Settings") == false ?
	<div  className="form-wrap mt-5">
  <div className="text-center">
You don't have permission to access this page
  </div></div>:
							<div className="row justify-content-center mt-md-5 mt-3 form-container">

								<div className="col-md-10">


									<div className="form-wrap py-3 ">

										{
											list_data.map((arr, i) => {
												return (<>
													<div className='row '>
														<div className='col-5'>
															<label className=" col-form-label mx-3  mb-2">{arr.label}</label>
														</div>
														<div className="col-5">
															{arr.status === "Yes" ? <Switch onChange={(e) => handleChange(e, i)} checked /> : <Switch onChange={(e) => handleChange(e, i)} />}</div></div><br /></>)

											})
										}

									</div>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</div>

		</>
	)
}








export default NotificationSetting