import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const DensityView = ({ isShowing,errors,hide,inputDate,handleIn,inputNozzle,productData,Receipt,cummulative,handleDate,Test,input,inputFuel,Sales_sum,Loose,Packed,handleSubmit, handle,Others,fuelType,inputValues,handleValue,handleInputs,handleInput,data,drop,handleSelect,NozzleData,TankData,form_nozzle}) => (
  <React.Fragment>
    {console.log(inputValues)}
    {console.log(input)}
    {console.log(inputNozzle)}
    {console.log(inputFuel)}
    {console.log(inputDate)}
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl ">

        <div className="modal-header mb-3">
          <h5 className=" text-center" id="staticBackdropLabel">Density Report View</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
       
        <div className="modal-body  ">
          <div className="row">
            <div className="col-md-12">
           
            <div className="row g-2 align-items-center   form-group ">
            
<div className="col-5 text-white">
    <label className="text-white">Date</label>
  </div>
  <div className="col-md-5"> 
    {inputDate.date} 
    
  </div>
  </div>
                <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label text-white">Product Name</label>
  </div>
  <div className="col-md-5">
    {inputNozzle.fuel_name}  
    
  </div>
  </div>
 
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label text-white">Tank Name</label>
  </div>
  <div className="col-md-5">
      {inputNozzle.tank_name} 
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label text-white">HMV</label>
  </div>
  <div className="col-md-5">
 {inputValues.hmv}  
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label text-white">Temperture</label>
  </div>
  <div className="col-md-5">
  {inputValues.temp}  
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label text-white">Density</label>
  </div>
  <div className="col-md-5">
   {inputValues.density} 
    
  </div>
  </div>
  {/* <div class="modal-footer text-center">

<a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
<a class="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit} >SAVE</a>
</div> */}


                </div>
                </div>
                </div>
               
                  </div>
                  
                  </div>
                
                 

    </React.Fragment>





)
export default DensityView