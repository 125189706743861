import React from "react";
import { Component } from "react";
import '../../App.css'
import customer_review from '../../img/customer-review.png'
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from "axios";
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Modal, ModalFooter } from "react-bootstrap";
import Loader from '../operational-model/Employee/Loader.component'
import { prevNonNumericalWithDec } from '../layouts/Sidebar_Progress.component'
import Pagination from "react-js-pagination";

class RackConfig1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      no_of_rack: '',
      rack_name: '',
      no_of_rack: '',
      fields: {},
      data: [],
      checkoption:[],
      rack_config: [],
      rack_configs: [],
      backPage:this.props.location.backpage,
      fields: [],
      status:0,
      message:'',
      search_keyword: '',
      no_of_rack_configs: [],
      rack_id: [],
      loading:false,
      current_page: 1,

      delete_Id: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleRackChange = this.handleRackChange.bind(this)
  }
  
  //------------------------url
 check_url=() =>{
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
			console.log(responseData)
			if(responseData.allowurl == 1)
			{
        if(this.props.location.pathname == "/rack-config/rack-config")
				{
					this.props.history.push(this.props.location.pathname);
				}
				else
				{
					this.props.history.push(responseData.url);
				}
      this.callRack(1) 
			}
			else{
				
				this.props.history.goBack();
			}

			return responseData;
		})
		.catch(e => {
		});

}
 
//--------------------------//
componentDidMount() {
  this.check_url(); 
 
         //on load//
}

  //edit api integration//
  edit_rack = (id) => {
    var rack_id = id
    axios({
      url: API_URL + "/rack-config/" + rack_id + "/edit",
      method: "get",
      data: { id: rack_id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      console.log(res.data)
      this.setState({ rack_config: res.data, showEditmodel: true })
      console.log(this.state.rack_config)
    }).catch(err => {

    })

  }
//pagination//
  handlePagination = (number) => {
    this.state.current_page = number
    this.callRack(number) 
  }

  submit_rack = () => {


    axios({
      url: API_URL + "/edit-rack",
      method: "post",
      data: {
        rack_id: this.state.rack_config.id,
        rack_name: this.state.rack_config.rack_name

      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      if (res.data.status == false) {
        var { errors } = this.state;

        errors["rack_name"] = res.data.response.rack_name
        // swal("", res.data.response.rack_name)
        this.setState({ errors: errors })
      }
      else {
        var { errors } = this.state;

        errors["rack_name"] = ""
        // swal("", res.data.response.rack_name)

        swal("", res.data.message, res.data.status ? "success" : "warning")

     
        this.callRack( this.state.current_page) 
        this.setState({ showEditmodel: false, errors: errors })
      }


    }).catch(err => {

    })

  }

  submitdeleterack = () => {
    var rack_id = this.state.delete_Id
    // console.log(rack_id)
    axios({
      url: API_URL + "/rack-config/" + rack_id,
      method: "DELETE",
      data: { id: rack_id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      // if (res.data.status == true) {
        this.setState({ delete_rackId: '', showDeletemodel: false })
        
        swal("", res.data.message, res.data.status == true ?"success" :"warning")
        this.callRack( this.state.current_page) 
      // }

      // this.setModalShow(true)

    }).catch(err => {

    })


  }
  //delete popup//
  RackDeleteModal = (props) => {
    return (
      <Modal
        {...props}

      >
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">RACK CONFIGURATION</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setDeleteModalShow(event, false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <p>Are you sure you want to delete this Rack</p>
        </Modal.Body>
        <ModalFooter className="justify-content-end ">
          <div className="d-flex">
            <button type="button" onClick={(event) => this.setDeleteModalShow(event, false)} className="btn btn-secondary me-2">No</button>
            <button type="button" onClick={() => this.submitdeleterack()} className="btn btn-primary">Yes</button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
//edit popup funcion//
  setEditModalShow(event, show) {

    var isdayclose = this.state.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
                  if (!show) {

                    this.setState({
                      showEditmodel: show,
                      errors: [
                        {
                          rack_name: ""
                        }
                      ]
                    })
                  }
                  else
                    this.setState({
                      showEditmodel: show,

                    })
    }
  }
  //delete rack//
  delete_rack = (id) => {
    var isdayclose = this.state.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
    console.log(1)
    this.setState({ delete_Id: id, showDeletemodel: true })
      }
  }
//list api integration//
  callRack = (page) => {
    this.setState({data:[]})
    axios(
      {
        url: API_URL + '/rack-list?page=' + page,
        method: "post",
        data: {
          search_keyword: this.state.search_keyword
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        }
      }
    ).then(response => {
      this.setState(
        {

          data: response.data.data.data,
          isdayclose:response.data.isdayclose,
          current_page: response.data.data.current_page,
          lastPage: response.data.data.last_page,
          total_pages: response.data.data.total,
          per_page:response.data.data.per_page,
          totalDatas: response.data.data.total,
          'loading':true
        }
      )


      if(response.data.status===3)
      {
        this.setState({
          status:response.data.status,message:response.data.message
        })
      }
     

    }).catch(error => {
      console.log(error);
    })
  }
  //onchange function//
  handleChange = (event) => {

    var { rack_configs } = this.state
    rack_configs = []
    var { rack_config } = event.target.value
    var { errors } = this.state;

    for (var i = 1; i <= event.target.value; i++) {
      rack_configs.push({
        rack_name: ''

      })
      errors.push({
        rack_name: ''
      })
    }
    console.log(rack_configs)
    this.setState({
      [event.target.name]: event.target.value,
      rack_configs: rack_configs
    })
  }


  handleRackChange = (event, index, prop) => {
    var { rack_configs } = this.state

    index['rack_name'] = event.target.value
    this.setState({ rack_configs: rack_configs })


  }
  handleInputChange = (e) => {
    console.log(e.target.value)
    let rack_config = this.state.rack_config;
    console.log(e.target.value)
    rack_config[e.target.name] = e.target.value;
    this.setState({
      rack_config: rack_config
    });


  }
  //validation//
  validateForm() {
    var lng = this.state.lng
    var { errors } = this.state;
    let formIsValid = true;
    var rack_configs = this.state
    rack_configs.rack_configs.map(function (obj, index) {
      console.log(obj, index)
      if (obj.rack_name === "") {
        formIsValid = false
        errors[index]['rack_name'] = "Enter Rack Name"
      }
      else {
        errors[index]['rack_name'] = ""
      }
    });

    if (!(this.state.no_of_rack)) {
      formIsValid = false;
      errors["no_of_rack"] = "Please Enter Number Of Rack"
    }
    else {
      errors["no_of_rack"] = ""
    }
    if((this.state.no_of_rack !="" && this.state.no_of_rack<=0))
        {
          formIsValid = false;
           swal("","Number of rack should be greater than zero","warning")
        }
    this.setState({
      errors: errors
    }, () => console.log(this.state.errors));
    return formIsValid;
  }

  // save api integration//
  handleSubmit = () => {
    var rack_configs = this.state

    if (this.validateForm()) {
      axios(
        {
          url: API_URL + '/rack-config',
          method: "POST",
          data: rack_configs,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
          }
        }).then(res => {

          
          this.setState({
            status: res.data.status ? "success" : "danger",

          }, () => {

            if (res.data.status === false) {

              swal("", res.data.message, res.data.success ? "success" : "warning")


            }
            if (res.data.status === true) {
              this.setState({ modalShow: false })
              swal("", res.data.message, res.data.status ? "success" : "danger")
             
              this.state.no_of_rack = ""
              this.setState({ rack_configs: [""] ,  })
              this.callRack(this.state.current_page) 
            }
            this.setState({
              message: res.data.message,
              status: res.data.status ? "success" : "danger",
            });
    
          })
        }).catch(error => {
          console.log(error)
        })

    }

  }
  
  handleSearch = (event) => {
    this.setState({
      search_keyword: event.target.value
    })
    // this.callRack(event.target.value)
  }
  setModalShow(event, show) {
    if (!show) {

      this.setState({
        errors: [
          {
            rack_name: "",
            no_of_rack:""
          }
        ]
      })
    }
    var isdayclose = this.state.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
  
    this.setState({
      modalShow: show,
      
    })
  }
  }

  setDeleteModalShow(event, show) {
    this.setState({
      showDeletemodel: show
    })
  }

//edit popup //
  setEditmodel = (props) => {
    return (
      <Modal
        {...props}

      >
        <Modal.Header className="modal-title">

          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setEditModalShow(event, false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <form>

            <div className="form-group">
              <label>Rack Name</label>
              <input type="text" className="form-control" name="rack_name" value={this.state.rack_config.rack_name} onChange={this.handleInputChange} />
              <div className="errorMsg">{this.state.errors.rack_name}</div>
            </div>
          </form>

        </Modal.Body>
        <ModalFooter className="justify-content-center">

          <button type="submit" onClick={this.submit_rack} className="btn btn-primary">Submit</button>

        </ModalFooter>
      </Modal>
    )
  }
  //add popup//
  MyVerticallyCenteredModal = (props) => {
    return (
      <Modal className="rackpopup"
        {...props}

      >
        <Modal.Header className="modal-title">
          <button type="button" className="btn-close" onClick={(event) => this.setModalShow(event, false)}></button>
        </Modal.Header>
        <Modal.Body >

          <div className=" form-content-sm text-center">
            <h2 className="mb-4"> NUMBER OF RACKS YOU WANT TO ADD</h2>
            <p className="text-center">Every purchase of packed oil and other product is considered as  main godown product in software.</p>
            <div className="p-3 mx-5 text-start border ">
              <div className="row  justify-content-center">
                <div className="col-sm-6">
                  <label className=" col-form-label">ADD NUMBER OF DISPLAY STORAGE RACKS<sup class="star">*</sup> </label>
                </div>
                <div className="col-sm-6">
                  <input  className="form-control" type="number" name="no_of_rack"  onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} min={0} max={10} value={this.state.no_of_rack} onChange={this.handleChange} />
                  <div className="errorMsg">{this.state.errors.no_of_rack}</div>
                </div>
              </div>
              {this.state.rack_configs.map((config, index) => {

                return (

                  <div className="row mb-1 justify-content-center">
                    <div className="col-sm-6">
                      <label className=" col-form-label mt-2">Rack Name <sup class="star">*</sup></label>
                    </div>
                    <div className="col-sm-6 mt-2">
                      <input type="text" className="form-control" name="rack_name" onChange={(event) => this.handleRackChange(event, config, event.target.name)} required />
                      <div className="errorMsg">{this.state.errors[index] ? this.state.errors[index]['rack_name'] : ''}</div>
                    </div>

                  </div>

                )
              })}
            </div>
            <p className="text-center px-5 py-2">This rack name will be shown in employee closing packed oil sales screen if configure here, otherwise it will show main godown</p>

          </div>
          <ModalFooter className="justify-content-center">
            <div className="col-md-3 text-center  mt-1">
              <a className="btn btn-primary w-auto" onClick={this.handleSubmit}>Save and continue</a>
            </div>
          </ModalFooter>

        </Modal.Body>
      </Modal>
    )
  }
  render() {
    return (<>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
        { this.props.location.pathname == "/rack-config/rack-config"? <Sidebar_Progress  path={ this.props.location.pathname} />:<Sidebar_Progress  />}

          <div className="content-area">
            <LoginHeader />

            <div className="container pb-3">
            

              <div className="row justify-content-center mt-md-5 mt-3 form-container">
                <div class="col-md-12 mb-3">{ this.props.location.pathname == "/rack-config/rack-config"?<Link  to={this.state.backPage||"/operational-date"}><a href="#" class="float-end btn btn-gray">Go Back</a></Link>:<Link to="/products"><a href="/products" class="float-end btn btn-gray">Back</a></Link>}</div>
                <div className="col-md-12">

                {this.state.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{this.state.message}
											</div></div>:
											<>
                      <div>
                      {this.state.loading ? this.callRack : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
                  <div className="form-wrap list-wrp">
                    <div className="row mb-3">
                      <div className="col-md">
                        <div className="row">
                          <div className="col-md-1 col-2 pe-0">
                            <img src={customer_review} width="25px" className="img-fluid" alt=".." />
                          </div>
                          <div className="col-md-4 ps-0">
                            <h3 className="table-title">RACK CONFIGURATION FOR NON-FUEL PRODUCTS</h3>
                          </div>
                          <div className="col-md">
                            <a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => this.setModalShow(event, true)}>ADD RACK</a>
                          </div>
                          {/* <div className="col-md">
            
            </div> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-group mb-3 search">
                          <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                          <input type="text" className="form-control" placeholder="Search" name="search_keyword" aria-describedby="basic-addon1" value={this.state.search_keyword} onChange={this.handleSearch} />
                          <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={this.check_url} >Submit</a>

                        </div>
                      </div>
                    </div>


                    <div className="table-widget">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th>Rack Name</th>
                            <th>Action</th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.data.map((rack_list) => {
                              return (
                                <tr>
                                  <td>{rack_list.rack_name}</td>
                                  <td><div className="d-flex icon-btn">
                                    <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={() => this.edit_rack(rack_list.id)} title="Edit">	<i className="fa fa-pencil" aria-hidden="true"></i>
                                    </button>
                                    <button data-bs-toggle="tooltip" type="submit" data-bs-placement="bottom" title="Delete" onClick={() => this.delete_rack(rack_list.id)} >  <i className="fa fa-trash" aria-hidden="true"></i>

                                    </button>

                                  </div></td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <div className="pagination mt-4 justify-content-end">
                      {this.state.loading ? this.state.total_pages > 10 &&  <Pagination
										activePage={ this.state.current_page}
                    itemClass="123"
                    itemsCountPerPage={ this.state.per_page }
                    totalItemsCount={this.state.total_pages || 1}
                    pageRangeDisplayed={10}
                    onChange={this.handlePagination}
										/>:""
										}
                    </div>
                    </div>
                  </div>
                  { this.props.location.pathname == "/rack-config/rack-config"?"":  <div className="text-end mt-4"><p className="note">Note: if you don’t have rack then kindly skip</p><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => this.props.history.push("/rack-product-list")}>Skip/Continue</a></div>}
                </div>
                </>}
              </div>

</div>
            </div>
          </div>

        </div>
      </div>
      <this.MyVerticallyCenteredModal
        show={this.state.modalShow}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setModalShow(false)}
        data={this.state}
      />
      <this.setEditmodel
        show={this.state.showEditmodel}
        // selectedvalue={this.state.selectedvalue}
        // selectedid={this.state.selectedid}
        onHide={(event) => this.setEditModalShow(false)}
      // data={this.state}
      />
      <this.RackDeleteModal
        show={this.state.showDeletemodel}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setDeleteModalShow()}
        data={this.state}
      />

    </>)
  }
}

export default RackConfig1