import { Component } from "react";
import LoginHeaderOperational from './LoginHeaderOperational';
import Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg';


const StatementOfAccount = (props) => {

  const [inputValues, setInputValues] = useState({})


  useEffect(() => {


  }, [])
  const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

  };
  const handleSubmit = (event) => {

    getDailySheet();

  }

  async function getDailySheet() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify({
        from_date: inputValues.start_date,
        to_date: inputValues.end_date,
        type: "1"
      })
    };
    fetch(API_URL + "/get_daily_transaction", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        console.log(responseData)




      })
      .catch(e => {
      });
  }




  function savesheet() {

  }

  return (
    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={7} />
          <div className="content-area">
            <LoginHeaderOperational props={props} page_title="  " />

            {

            }

            <div className="container pb-3">
              <div className="row justify-content-center  mt-3 form-container">


                <div className="col-md-12">
                  <div className="row mb-3">

                    <div className="col-md-8 d-flex">
                      <div className="text-start d-flex pe-1 mt-3">
                        <div className="form-group">
                          <label  >Start date</label>
                          <input type="date" className="form-control  " placeholder="" name="start_date" value={inputValues.start_date} onChange={handleInput}></input>
                        </div>
                      </div>
                      <div className="text-start d-flex pe-3 mt-3">
                        <div className="form-group">
                          <label  >End date</label>
                          <input type="date" className="form-control  " placeholder="" name="end_date" value={inputValues.end_date} min={inputValues.start_date} onChange={handleInput}></input>
                        </div>
                      </div>
                      <div className="text-start d-flex pe-4 mt-5">
                        <div className="form-group">
                          <a className="btn btn-primary mb-md-0  ms-1" onClick={handleSubmit}>Submit </a>
                        </div>
                      </div>


                    </div>
                    <div className="col-md-4 d-flex  ps-md-5">
                      <div className="text-end d-flex  mt-5 pe-4">
                        <div className="form-group">

                          <div class="select-container">
                            <select id="" class="form-control highlight" name="tank_id">
                              <option > Category</option>

                            </select>

                          </div>
                        </div>
                      </div>
                      <div className="text-end d-flex  mt-5 ">

                        <div class="select-container">
                          <select id="" class="form-control highlight" name="tank_id">
                            <option >Account Name</option>

                          </select>

                        </div>

                      </div>
                    </div>
                    <div className="form-wrap balace-sheetwrap">

                      <div className="row mb-3">
                        <div className="col-md ">
                          <h2 className="table-title  mb-1">PUMP NAME</h2>
                          <h4 className="table-title mb-1">Statement Of Account</h4>
                          <h5>Account Name</h5>
                          <img src={logo} className="img-fluid float-end " width="100px" />
                        </div>
                      </div>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="expense" role="tabpanel" aria-labelledby="expense-tab">
                          <div className="">
                            <div className="row mb-3">
                              <div className="col-md">
                                <div className="row">



                                </div>
                              </div>

                            </div>


                            <div className="table-widget">
                              <table className="table border">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Particulars</th>
                                    <th>Description</th>
                                    <th>debit(Rs)</th>
                                    <th>credit (Rs)</th>
                                    <th>Outstanding (Rs)</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  <tr>
                                    <td>18/2/2021</td>
                                    <td>abc</td>
                                    <td>abc</td>
                                    <td>200</td>
                                    <td>200</td>
                                    <td>300</td>

                                  </tr>
                                  <tr>
                                    <td>18/2/2021</td>
                                    <td>abc</td>
                                    <td>abc</td>
                                    <td>200</td>
                                    <td>200</td>
                                    <td>300</td>

                                  </tr>
                                  <tr>
                                    <td>18/2/2021</td>
                                    <td>abc</td>
                                    <td>abc</td>
                                    <td>200</td>
                                    <td>200</td>
                                    <td>300</td>

                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td>Total/Closing Balance</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                  </tr>


                                </tbody>
                              </table>



                            </div>

                          </div>

                        </div>

                      </div>







                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



    </>
  )
}


export default StatementOfAccount