import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import Modal from "react-modal";
import Loader from '../../Employee/Loader.component'
import AccountToAccountModal from './AccountToAccountTransferModal.component';
import ManagerApprovalConfig from '../ManagerApprovalConfig.component'
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
import PaginationComponent from '../../../layouts/PaginationComponent';
import { useLocation } from 'react-router-dom';
import moment from "moment/moment";
const AccountModal = (props) => ReactDOM.createPortal(
	<AccountToAccountModal   {...props} />, document.body
);
const ApprovalModal = (props) => ReactDOM.createPortal(
	<ManagerApprovalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const AccountToAccountConfig = (props) => {

	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
	const location = useLocation();
	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [delete_id, deleteId] = useState([])
	const [startDate, setDate] = useState([])
	const [c_Date, c_setDate] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [fromoutstanding, setFromOutstanding] = useState({});
	const [tooutstanding, setToOutstanding] = useState({});
	const [cashbalance, setCashbalance] = useState({});
	const [editamount, setEditAmount] = useState({});
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [double, setDouble] = useState(true);
	const [search, setSearch] = useState('')
	const [selected_options1, setSelectedOptions1] = useState([])
	const [selected_options, setSelectedOptions] = useState([])
	const [list_data, setList] = useState([])
	const [edit_list, setProductList] = useState([])
	const [acCategory, setacCategory] = useState([])
	const [toCategory, setToacCategory] = useState([])
	const [errors, setErrors] = useState([''])
	const [success, setSuccess] = useState([])
	const [permission, setPermission] = useState([])
	const[day_closing_date,setDayclosedate]=useState({date:location?.state||""}) 
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		check_url();
		is_day_closed();

	}, []);

	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					listData(pages.current_page);

				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}

	//Loader 
	const [Loading, setLoading] = useState(false)
	function listData(page) {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/account-to-account?search_keyword=${search}&page=${page}&day_close_date=${day_closing_date?.date}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setList(responseData.data.data)
				setDate(responseData.start_date)
				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true)
				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}

	const getAcCategory = () => {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/account/get-ac-category", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setacCategory(responseData.group);
			})
			.catch(e => {
				console.log(e);

			});
	}
	const getToAcName = (id) => {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ ac_id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/account/get-to-ac-name", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setToacCategory(responseData.group);
			})
			.catch(e => {
				console.log(e);

			});
	}







	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		listData(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if(c_Date == 1){
				swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
			}else{
			getAcCategory()
			if (isShowing) {
				setInputValues([''])
				setEditAmount({})
				setFromOutstanding({})
				setToOutstanding({})
				setCashbalance({})
				setProductList([])
				setErrors([''])
				setSuccess({})
				setSelectedOptions([])
				setSelectedOptions1([])
				setFields([''])
			}
			if (!isShowing) {
				setDouble(true)
				setErrors([''])

			}
			setIsShowing(!isShowing);
		}
	}
		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleInputs = (e) => {

		convertAmountToWords(e, e.target.value)

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});


		if (e.target.name == 'amount') {
			var fromout = fromoutstanding.before_approval;
			if (fromout == null) {
				fromout = 0;
			}
			var edt_amount = 0;
			if (editamount > 0) {
				edt_amount = editamount;
			}
			var outs = Number(fromout) - Number(e.target.value) + Number(edt_amount);
			document.getElementById("outs_before_approval").value = parseFloat(outs).toFixed(2);
			var toout = tooutstanding.before_approval;
			if (toout == null) {
				toout = 0;
			}
			var outs2 = Number(toout) + Number(e.target.value) - Number(edt_amount);

			if (inputValues.to_ac_name_slug > 0) {
				document.getElementById("outs2_before_approval").value = parseFloat(outs2).toFixed(2);
			}

			//	var cfromout = cashbalance.before_approval;
			//	if(cfromout == null)
			//	{
			//		cfromout =0;
			//	}
			//	var cbal = Number(cfromout)+Number(e.target.value)
			//	document.getElementById("cashb_before_approval").value = cbal;
			//document.getElementById("cashb2_before_approval").value = cbal;
		}

	};
	const handleInput = (items) => {
		setInputValues({
			...inputValues,
			to_ac_name_slug: items.value,
		});
		setSelectedOptions1(items.value);

		getTooutstanding(items.value);


	};
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		// if (validateForm())
		sendAccountData()
	}
	const handleSelect = (items) => {

		setInputValues({
			...inputValues,
			from_ac_name_slug: items.value,
		});
		setSelectedOptions(items.value);

		let acId = items.value
		if (!isNaN(acId) && acId)
			getToAcName(acId)
		getFromoutstanding(items.value);
	};
	//---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (!inputValues.from_ac_name_slug) {
			formIsValid = false;
			errors["from_ac_name_slug"] = "Select From Account"
		}
		else {
			errors["from_ac_name_slug"] = ""
		}

		if (!inputValues.to_ac_name_slug) {
			formIsValid = false;
			errors["to_ac_name_slug"] = "Select To Account"
		}
		else {
			errors["to_ac_name_slug"] = ""
		}
		if (!inputValues.amount) {
			formIsValid = false;
			errors["amount"] = "Enter Amount"
		}
		else {
			errors["amount"] = ""
		}
		if (document.getElementById("m_op_date").value=='') {
			formIsValid = false;
			errors["date"] = "Date Required"
		}
		else {
			errors["date"] = ""
		}

		setErrors(errors)

		return formIsValid;
	}

	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				c_setDate(responseData)

			})
			.catch(e => {
				console.log(e);

			});

	}

	function getFromoutstanding(id) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_outstanding", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setFromOutstanding(responseData.outstanding)
				setCashbalance(responseData.cashbalance)

				var fromout = responseData.outstanding.before_approval;
				if (fromout == null) {
					fromout = 0;
				}
				var amount = inputValues.amount;
				if (amount == null) {
					amount = 0;
				}
				var edt_amount = 0;
				if (editamount > 0) {
					edt_amount = editamount;
				}
				var outs = Number(fromout) - Number(amount) + Number(edt_amount);

				if (amount != 0) {
					document.getElementById("outs_before_approval").value = parseFloat(outs).toFixed(2);
				} else {

					document.getElementById("outs_before_approval").value = '';
				}

				//	var cfromout = responseData.cashbalance.before_approval;
				//	if(cfromout == null)
				//	{
				//		cfromout =0;
				//	}
				//	var cbal = Number(cfromout)+Number(amount)
				//	document.getElementById("cashb_before_approval").value = cbal;
				//document.getElementById("cashb2_before_approval").value = cbal;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function getTooutstanding(id) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_outstanding", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setToOutstanding(responseData.outstanding)
				setCashbalance(responseData.cashbalance)

				var toout = responseData.outstanding.before_approval;
				if (toout == null) {
					toout = 0;
				}
				var amount = inputValues.amount;
				if (amount == null) {
					amount = 0;
				}
				var edt_amount = 0;
				if (editamount > 0) {
					edt_amount = editamount;
				}
				var outs2 = Number(toout) + Number(amount) - Number(edt_amount);
				if (amount != 0) {
					document.getElementById("outs2_before_approval").value = outs2;
					document.getElementById("outs2_before_approval").value = parseFloat(outs2).toFixed(2);
				} else {

					document.getElementById("outs2_before_approval").value = '';
				}


			})
			.catch(e => {
				console.log(e);

			});
	}




	//---Create EmployeeList---//
	function sendAccountData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify(
				{
					from_ac_name_slug:inputValues.from_ac_name_slug,
					to_ac_name_slug:inputValues.to_ac_name_slug,
					amount:inputValues.amount,
					date:document.getElementById("m_op_date").value	,	
					id: inputValues.id,
					note:inputValues.note
				})
		};
		
		fetch(API_URL + "/account-to-account", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {
					errors["from_ac_name_slug"] = responseData.response.from_ac_name_slug
					errors["to_ac_name_slug"] = responseData.response.to_ac_name_slug
					errors["amount"] = responseData.response.amount
					errors["date"] = responseData.response.date
					setDouble(true)

				}
				if (responseData.status == 8) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)

				}
				if (responseData.status == 3) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)

				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/account-to-account-transfer")
					setErrors([''])
					setInputValues([])
					toggle();
					listData(pages.current_page);
					setDouble(true)

				}

				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}

	function handleEdit(id, status) {
		setErrors([])
		setProductList({
			id: id,
			status: status
		})
		console.log(id,"iiiii");
		editAccountTransfer(id)
	}
	function editAccountTransfer(id) {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/account-to-account/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setInputValues(
					{
						...inputValues,
						id: responseData.account.id ? responseData.account.id : '',
						from_ac_name_slug: responseData.account.from_ac_name_slug ? responseData.account.from_ac_name_slug : '',
						to_ac_name_slug: responseData.account.to_ac_name_slug,
						amount: responseData.account.amount,
						note: responseData.account.note,
						date: responseData.account.op_date,
						cheque_no: responseData.account.cheque_no,
					}
				);
				let product_names = []
				product_names.push({
					value: responseData.account.from_ac_name_slug,
					label: responseData.account.ac_name_slug,
				})
				setSelectedOptions(product_names)
				getToAcName(responseData.account.from_ac_name_slug)
				let product_name = []
				product_name.push({
					value: responseData.account.to_ac_name_slug,
					label: responseData.account.to_name_ac_name_slug
				})
				setSelectedOptions1(product_name)
				setToacCategory(responseData.to_dropdown)
				setFromOutstanding(responseData.fromoutstanding)
				setToOutstanding(responseData.tooutstanding)
				setCashbalance(responseData.cashbalance)

				setEditAmount(responseData.account.amount)
				toggle();
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}

	function deleteAccount(id) {
		if(c_Date == 1){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
		}else{

		deleteId(id)

		toggleModal()
	}
	}
	function deleteTransfer() {

		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/account-to-account/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				listData(pages.current_page);
				toggleModal()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				console.log(err)
				swal("Something went wrong", "", "warning")

			})
	}
	/*****approval******/
	const UseApproveModal = () => {
		const [isApprove, setApprove] = useState(false);
		function toggleModal2() {
			setApprove(!isApprove);
		}
		return {
			isApprove,
			toggleModal2,
		}
	};
	const { isApprove, toggleModal2 } = UseApproveModal();
	function handleManagerAproval(id, status) {
        if( c_Date == 1){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false?"success":"warning")
		}else{

		approvalId(id)
		approvalStatus(status)
		toggleModal2()
	}
	}
	function handleYes() {
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				id: approval_id,
				status: approval_status
			})
		};
		fetch(`${API_URL}/account_to_account_transfer_approve`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				listData(pages.current_page);
				getAcCategory()
				toggleModal2()
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={13} operation={2} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />


						<div className="container pb-3">
							<div className="row mt-md-5 mt-3 form-container">
								{permission.status == 3 ?
									<div className="form-wrap mt-5">
										<div className="text-center">
											{permission.message}
										</div></div> :
									<>

										<div>
											{Loading ? listData : <Loader />}
											<div className="form-wrap ">

												<div className="tab-content" id="myTabContent">
													<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
														<div className="">
															<div className="row mb-3">
																<div className="col-md">
																	<div className="row">

																		<div className="col-md col pl-0 col-12">
																			<h3 className="table-title">Account To Account Transfer  </h3>
																		</div>

																	</div>
																</div>
																<div className="col-md-4">
																	<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add  Account To Account Trasnfer</a>
																</div>


																<div className="col-md-4">
																	<div className="input-group mb-3 search">
																		<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																		</span>
																		<input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch} />
																		<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																	</div>
																</div>

															</div>


															<div className="table-widget">
																<table className="table border col-12">
																	<thead>
																		<tr>
                                                                            <th>Date</th>
																			<th>From Account </th>
																			<th>Account  Sub </th>
																			<th>Description</th>
																			<th >Amount</th>
																			<th>Account Name</th>
																			<th>Action</th>

																		</tr>
																	</thead>
																	<tbody>
{console.log(list_data,"list_datalist_data")}
																		{list_data.map(obj => (
																			
																			<tr>
																				<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>
																				<td>{obj.from_ac_name}</td>
																				<td>{obj.to_ac_name}</td>
																				<td>{obj.note}</td>
																				<td>{obj.amount}</td>
																				<td>{obj.from_ac_name} - {obj.to_ac_name}</td>
																				<td className="py-2">
																					<a href="#" className="text-secondary" onClick={() => handleEdit(obj.id, obj.status)} >{(obj.status == 3 || obj.status == 4) ?"" : <i className="fa fa-pencil-square-o  pe-2" aria-hidden="true"></i>}</a>
																					{obj.status < 4 ? <a href="#" className="text-secondary" onClick={() => deleteAccount(obj.id)}><i className="fa fa-trash-o pe-2" aria-hidden="true"></i></a> : ''}
																					{permissions.includes("Deny Approval") == true ?
																						<>
																							{obj.status < 4 ? <a href="#" className="text-secondary" onClick={() => handleManagerAproval(obj.id, obj.status)}>{(obj.status == 3) ? <i className="text-success fa fa-check" aria-hidden="true"></i> : <i className="text-warning fa fa-check" aria-hidden="true"></i>}</a> : <a><i className="btn btn-success btn-xs" aria-hidden="true">Day Close</i></a>}
																						</>
																						: ''}
																				</td>


																			</tr>
																		))}



																	</tbody>
																</table>



															</div>
															{Loading ? <PaginationComp
																pages={pages}
																handlePagination={handlePagination}
															/> : ""
															}

														</div>

													</div>

												</div>

											</div>
											<div className="text-end mt-3">
												{/* <a href="#" className="btn btn-primary px-5">Save</a> */}
											</div>
										</div>
									</>}
							</div>
						</div>
					</div>
				</div>
			</div>



			{isShowing ? (<AccountModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fromoutstanding={fromoutstanding}
				tooutstanding={tooutstanding}
				cashbalance={cashbalance}
				edit_list={edit_list}
				fields={fields}
				double={double}
				startDate={startDate}
				success={success}
				handleInput={handleInput}
				handleSubmit={handleSubmit}
				selected_options={selected_options}
				preventNonNumericalInput={preventNonNumericalInput}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				acCategory={acCategory}
				toCategory={toCategory}
				c_Date={c_Date}
				handleSelect={handleSelect}
				selected_options1={selected_options1}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}


			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className="modal-content">
					<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">Account To Account</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div className=" modal-body  modal-body"><p>Are you sure you want to delete this Account Transfer</p></div>
					<div className="justify-content-end  modal-footer"><div className="d-flex">
						<button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						{double == true ? <button type="submit" className="btn btn-primary" onClick={deleteTransfer}>Yes</button> : <button type="submit" className="btn btn-primary" disabled onClick={deleteTransfer}>Yes</button>}
					</div>
					</div>
				</div>
			</Modal>
			{isApprove ? (<ApprovalModal
				isApprove={isApprove}
				hide={toggleModal2}
				handleYes={handleYes}
				approval_status={approval_status}
			/>) : null
			}
		</>
	)
}

export default AccountToAccountConfig