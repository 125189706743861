import { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'; 
import { API_URL } from "../constant/API_Settings";
import swal from "sweetalert";

const SuccessUsername = () => {


    const handleReturnToSignIn = () => {
        // Replace the URL with the appropriate login screen URL
        window.location.href = "/";
      };


    return (
        <>
        <div className="body">
        <div class="card">
    <h2>Forgot Username Request</h2>
    <p>Check your email for your Username. If it doesn’t appear within a few minutes, check your spam folder.</p>
  
   <button className="button" onClick={handleReturnToSignIn}>Return to Sign In</button>
 

      <style jsx>{`

      .body {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100vh;
        margin: 0;
        padding: 50px;
        box-sizing: border-box;
      }
      
      .card {
        width: 400px;
        height: 220px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 4px;
        text-align: center;
        background-color: #f5f5f5;
        margin-top: 50px;
      }
      
      .button {
        display: inline-block;
        padding: 12px 24px;
        color: #ffffff;
        text-decoration: none;
        font-weight: bold;
        border-radius: 4px;
        background-color: #5d38dbeb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      }
      `}</style>
    </div>
    </div>
    </>
    );
}

export default SuccessUsername;