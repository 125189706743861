import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import ReceiptModal from './ReceiptModal.component';
import Loader from '../../Employee/Loader.component';
import ManagerApprovalConfig from '../ManagerApprovalConfig.component'
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { convertAmountToWords } from "../../../layouts/Operational_Sidebar_Progress.component";

const ApprovalModal = (props) => ReactDOM.createPortal(
	<ManagerApprovalConfig  {...props} />, document.body
);
const ReceiptvModal = (props) => ReactDOM.createPortal(
	<ReceiptModal  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const ReceiptConfig = (props) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
	const location = useLocation();
	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [delete_id, deleteId] = useState([])
	const [double, setDouble] = useState(true);
	const [inputValues, setInputValues] = useState({});
	const [denomination, setDenomination] = useState([])
	const [input, setInput] = useState({});
	const [outstanding, setOutstanding] = useState({});
	const [editamount, setEditAmount] = useState({});
	const [cashbalance, setCashbalance] = useState({});
	const [permission, setPermission] = useState([])
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [acCategory, setacCategory] = useState([])
	const [dropdownData, setDropdownData] = useState([])
	const [accountData, setDropdown] = useState([])
	const [search, setSearch] = useState('')
	const [selected_options, setSelectedOptions] = useState([])
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState({})
	const [startDate, setDate] = useState([])
	const [c_date, c_setDate] = useState([])
	const [success, setSuccess] = useState([])
	const [denm, setDenm] = useState([])
	const [loading, setLoading] = useState(false)
	const[day_closing_date,setDayclosedate]=useState({date:location?.state||""})  
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		check_url();
		is_day_closed();

	}, []);
	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);

					ListReceipt(pages.current_page);
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	const clearState = () => {
		setDenomination([]);
	};
	//-------------------end
	function getDenomination() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get-denomination", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				responseData.denomination.forEach((amount) => {
					setDenm((denm) => [
						...denm,
						{
							amount
						},
					]);
				});

			})
			.catch(e => {
				console.log(e);

			});
	}

	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				c_setDate(responseData)
				

				// responseData.denomination.forEach((amount) => {
				// 	setDenm((denm) => [
				// 		...denm,
				// 		{
				// 			amount
				// 		},
				// 	]);
				// });

			})
			.catch(e => {
				console.log(e);

			});

	}

	// setInput({
	// 	...input,
	// 	[e.target.name]: e.target.value
	// });
	// convertAmountToWords(e, e.target.value)

	function getoutstanding(id) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_outstanding", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setOutstanding(responseData.outstanding)
				setCashbalance(responseData.cashbalance)

				var fromout = responseData.outstanding.before_approval;
				if (fromout == null) {
					fromout = 0;
				}
				var amount = input.amount;
				if (amount == null) {
					amount = 0;
				}
				var cfromout = responseData.cashbalance.before_approval;
				if (cfromout == null) {
					cfromout = 0;
				}
				var edt_amount = 0;
				if (editamount > 0) {
					edt_amount = editamount;
				}
				var outs = Number(fromout) - Number(amount) + Number(edt_amount);
				var cbal = Number(cfromout) + Number(amount) - Number(edt_amount)
				if (amount != 0) {
					document.getElementById("outs_before_approval").value = parseFloat(outs).toFixed(2);
					document.getElementById("cashb_before_approval").value = parseFloat(cbal).toFixed(2);
				} else {

					document.getElementById("outs_before_approval").value = '';
					document.getElementById("cashb_before_approval").value = '';
				}
			})
			.catch(e => {
				console.log(e);

			});
	}
	function ListReceipt(page) {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/receipt-voucher?search_keyword=${search}&page=${page}&day_close_date=${day_closing_date?.date}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setDate(responseData.start_date)
				setList(responseData.data.data)

				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true)
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListReceipt(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if(c_date ==1){
				swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
			}else{
				getAcCategory()
			if (isShowing) {
				setDenm([])
				setInput({})
				setInputValues({})
				setSelectedOptions([])
				setOutstanding({})
				setEditAmount({})
				setCashbalance({})
				setErrors({})
				setSuccess({})
				setFields([''])
				setDouble(true)
				const denomi = [...denomination];
				if (denomi != "") {
					denomi.forEach((obj, index) => {
						obj.multiple = ""
						obj.count = ""
					})
					setDenomination([])
				}
				else {
					setDenomination([])
				}

			}
			if (!isShowing) {
				getDenomination()
				setErrors({})
				setDouble(true)

			}
			setIsShowing(!isShowing);
		}
	}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	//---onchange functions---//
	function handleChange(event, idx) {
		const denomi = [...denomination];

		if (denomi[idx]) {
			var value = event.target.value
			denomi[idx]['multiple'] = (denomi[idx].amount * event.target.value)
			denomi[idx]['count'] = event.target.value
			var amount = 0
			var changeValue = 0
			var currentValue = 0
			var els = document.getElementsByClassName('elem');
			for (var i = 0; i < els.length; i++) {
				var changeValue = document.getElementById('change' + i).value;
				var currentValue = document.getElementById('multiple' + idx).value;
				amount += denomi[i].amount * changeValue
				// denomination['count'] =changeValue
				// denomination['amount'] =currentValue
			}


			document.getElementById('amountww').value = amount
			document.getElementById('amount').value = amount
			setDenomination(denomi)
			setInputValues({ ...inputValues })
			setInput({ ...input, denomination })
			var t = document.getElementById('amountww').value
			if(Number(input.amount) > 0){
			if (Number(t) > Number(input.amount)) {
				swal("", "Total is greater than amount", "warning")
			}
		}
			if (event.target.name == "multiple") {
				setInput({ ...input, amount })
			}
		}
		else {

			const denomination = [...denm];
			var value = event.target.value
			denomination[idx]['multiple'] = (denomination[idx].amount * event.target.value)
			denomination[idx]['count'] = event.target.value
			var amount = 0
			var changeValue = 0
			var currentValue = 0
			var els = document.getElementsByClassName('elem');

			for (var i = 0; i < els.length; i++) {
				var changeValue = document.getElementById('change' + i).value;
				var currentValue = document.getElementById('multiple' + idx).value;
				amount += denomination[i].amount * changeValue
				// denomination['count'] =changeValue
				// denomination['amount'] =currentValue
			}


			document.getElementById('amountww').value = amount
			document.getElementById('amount').value = amount
			setDenomination(denomination)
			setInputValues({ ...inputValues })
			setInput({ ...input, denomination })
			if(Number(input.amount) > 0){
			if (Number(denomination[idx]['multiple']) > Number(input.amount)) {
				swal("", "Total is greater than amount", "warning")
			}
		}
			if (event.target.name != "multiple") {


			}
			else {
				setInput({ ...input, amount })
			}
		}
	}

	const handleInputs = (e) => {


		setInput({
			...input,
			[e.target.name]: e.target.value
		});
		convertAmountToWords(e, e.target.value)

		if ((e.target.name == 'amount') && (e.target.value != 0)) {
			var fromout = outstanding.before_approval;
			if (fromout == null) {
				fromout = 0;
			}
			var edt_amount = 0;
			if (editamount > 0) {
				edt_amount = editamount;
			}
			var outs = Number(fromout) - Number(e.target.value) + Number(edt_amount)
			document.getElementById("outs_before_approval").value = parseFloat(outs).toFixed(2);

			var cfromout = cashbalance.before_approval;
			if (cfromout == null) {
				cfromout = 0;
			}
			var cbal = Number(cfromout) + Number(e.target.value) - Number(edt_amount)
			document.getElementById("cashb_before_approval").value = parseFloat(cbal).toFixed(3);
		}


	};
	const handleSelect = (items) => {
		console.log(items.value, "dgdfg")
		setInput({
			...input,
			ac_name_slug: items.value,
		});
		setSelectedOptions(items.value);
		getoutstanding(items.value);
	};
	const handleClick = (e) => {
		var id = e.target.value
		//  getAccountName(id)


	};

	// const handleSelect = (e) => {
	// 	var id = e.target.value
	// 	getAccountCategory(id)
	// }

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		// if (validateForm())
		sendReciept()
	}
	//validations//

	function validateForm() {
		let errors = []
		let formIsValid = true
		if (!input.op_date) {
			formIsValid = false;
			errors["op_date"] = "Date Required"
		}
		if (!input.ac_name_slug) {
			formIsValid = false;
			errors["ac_name_slug"] = "Account Name Required"
		}
		if (!input.amount) {
			formIsValid = false;
			errors["amount"] = "Enter Amount"
		}


		setErrors(errors)

		return formIsValid;


	}
	const getAcCategory = () => {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				check_case: "RECEIPT_VOUCHER",
				from: "mngr",
			}),
		};
		fetch(API_URL + "/common-get-ac-category", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setacCategory(responseData.group);
			})
			.catch(e => {
				console.log(e);

			});
	}
	//---Create EmployeeList---//
	function sendReciept() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				sys_voucher_no: input.sys_voucher_no,
				voucher_no: input.voucher_no,
				note: input.note,
				amount: document.getElementById("amount").value,
				id: input.id,
				ac_name_slug: input.ac_name_slug,
				denomination,
				Manager: "yes",
				receipt_type: 1,
				op_date: document.getElementById("m_op_date").value,


			})
		};
		fetch(API_URL + "/receipt-voucher", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {


				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["amount"] = responseData.response.amount
					errors["ac_name_slug"] = responseData.response.ac_name_slug
					errors["op_date"] = responseData.response.op_date
					// errors["phone"] = responseData.response.phone
					// errors["whatsapp_no"] = responseData.response.whatsapp_no
					setDouble(true)


				}
				if (responseData.status == 3) {

					swal("", responseData.message, "warning")
					setDouble(true)

				}
				if (responseData.status == 9) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)
				}
				if (responseData.status == 8) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)


				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/receipt")
					setErrors([''])
					input.sys_voucher_no = ""
					input.voucher_no = ""
					input.note = ""
					input.amount = ""
					input.ac_name_slug = ""
					input.op_date = ""
					setInput(input)
					denomination.map(elem => {

						var multiple = elem.multiple = ""
						var count = elem.count = ""
						setDenomination({
							...denomination,

							multiple: multiple,
							count: count
						})
					})
					toggle();
					setDouble(true)
					ListReceipt(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				swal("Something went wrong", "", "warning")
				console.log(e,"DG")
				setDouble(true)
			});
	}
	//---Edit employee list---//	
	function edit_receipt(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/receipt-voucher/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setInput(responseData.voucher_data)
				let product_names = []
				product_names.push({
					value: responseData.voucher_data.ac_name_slug,
					label: responseData.voucher_data.ac_name_slug_name,
				})
				setSelectedOptions(product_names)
				setDenomination(responseData.denomination)
				setOutstanding(responseData.outstanding)
				setCashbalance(responseData.cashbalance)

				setEditAmount(responseData.voucher_data.amount)
				toggle();
				document.getElementById('amountww').value = responseData.voucher_data.amount
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	//---Delete EmployeeList---//	
	function delete_receipt(id) {
		if(c_date ==1){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
		}else{

		deleteId(id)

		toggleModal()
	}
}

	function deleteReceipt() {
		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({ delete_id: delete_id, manager: "yes" })
		};
		fetch(API_URL + "/receipt-voucher/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				ListReceipt(pages.current_page);
				toggleModal()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				console.log(err)
				swal("Something went wrong", "", "warning")

			})
	}
	/*****approval******/
	const UseApproveModal = () => {
		const [isApprove, setApprove] = useState(false);
		function toggleModal2() {
			setApprove(!isApprove);
		}
		return {
			isApprove,
			toggleModal2,
		}
	};
	const { isApprove, toggleModal2 } = UseApproveModal();
	function handleManagerAproval(id, status) {
		
		if(c_date ==1){
			swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
		}else{

		approvalId(id)
		approvalStatus(status)
		toggleModal2()
	}

	}

	function handleYes() {
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				id: approval_id,
				status: approval_status
			})
		};
		fetch(`${API_URL}/receipt_voucher_approve`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getDenomination()
				//getAccount()
				getAcCategory()
				ListReceipt(pages.current_page);
				toggleModal2()
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={12} operation={2} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div className="container pb-3 tab-view">
							<div className="row mt-md-5 mt-3 form-container">
								<div className="">

									<div className="form-wrap ">
									{permissions.includes("Customer Attendant")?
									<div className="form-wrap mt-5">
										<div className="text-center">
											You don't have permission to access this page
										</div></div> : permission.status == 3 ?
											<div className="form-wrap mt-5">
												<div className="text-center">
													{permission.message}
												</div></div> :
											<>

												<div>
													{loading ? ListReceipt : <Loader />}
													<div className="tab-content" id="myTabContent">
														<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
															<div className="">
																<div className="row mb-3">
																	<div className="col-md">
																		<div className="row">

																			<div className="col-md col pl-0">
																				<h3 className="table-title">Receipt Voucher </h3>
																			</div>

																		</div>
																	</div>
																	<div className="col-md-4">
																		<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Receipt Voucher</a>
																	</div>
																	<div className="col-md-4">
																		<div className="input-group mb-3 search">
																			<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																			</span>
																			<input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch} />
																			<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																		</div>
																	</div>
																</div>


																<div className="table-widget">
																	<table className="table border">
																		<thead>
																			<tr>

																				<th hidden>System Voucher Number</th>
																				<th>Account Category</th>
																				<th >Amount</th>
																				<th>Description</th>
																				<th>Voucher Number</th>
																				<th>Date</th>
																				<th>Action</th>

																			</tr>
																		</thead>
																		<tbody>
																			{
																				list_data.map(obj => (
																					<tr>
																						<td hidden>{obj.sys_voucher_no}</td>
																						<td>{obj.ac_name_slug}</td>
																						<td>{obj.amount}</td>
																						<td>{obj.note}</td>
																						<td>{obj.voucher_no}</td>
																						<td>{moment(obj.date).format("DD-MM-YYYY")}</td>

																						<td className="py-2">
																							{obj.status < 3 ? <a href="#" className="text-secondary" onClick={() => edit_receipt(obj.id)} ><i className="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a> : ''}
																							{obj.status < 4 ? <a href="#" className="text-secondary" onClick={() => delete_receipt(obj.id)}><i className="fa fa-trash-o pe-2" aria-hidden="true"></i></a> : ''}
																							{permissions.includes("Deny Approval") == true ?
																								<>
																									{obj.status < 4 ? <a href="#" className="text-secondary" onClick={() => handleManagerAproval(obj.id, obj.status)}>{(obj.status == 3) ? <i class="text-success fa fa-check" aria-hidden="true"></i> : <i class="text-warning fa fa-check" aria-hidden="true"></i>}</a> : <a><i className="btn btn-success btn-xs" aria-hidden="true">Day Close</i></a>}
																								</>
																								: ''}
																						</td>
																					</tr>
																				))}
																		</tbody>
																	</table>
																	{loading ? <PaginationComp
																		pages={pages}
																		handlePagination={handlePagination}
																	/> : ""
																	}


																</div>

															</div>
														</div>

													</div>
												</div>
											</>}

									</div>
								</div>
								<div className="col-md-3">

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<ReceiptvModal
				isShowing={isShowing}
				hide={toggle}
				handleSelect={handleSelect}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fields={fields}
				input={input}
				double={double}
				outstanding={outstanding}
				cashbalance={cashbalance}
				dropdownData={dropdownData}
				denm={denm}
				selected_options={selected_options}
				startDate={startDate}
				acCategory={acCategory}
				handleClick={handleClick}
				accountData={accountData}
				success={success}
				c_date={c_date}
				denomination={denomination}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}


			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className="modal-content">
					<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">Receipt Voucher</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div className=" modal-body  modal-body"><p>Are you sure you want to delete this Receipt</p></div>
					<div className="justify-content-end  modal-footer"><div className="d-flex">
						<button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						{double == true ? <button type="submit" className="btn btn-primary" onClick={deleteReceipt}>Yes</button> : <button type="submit" className="btn btn-primary" disabled onClick={deleteReceipt}>Yes</button>}
					</div>
					</div>
				</div>



			</Modal>
			{isApprove ? (<ApprovalModal
				isApprove={isApprove}
				hide={toggleModal2}
				handleYes={handleYes}
				approval_status={approval_status}
			/>) : null
			}
		</>
	)
}

export default ReceiptConfig