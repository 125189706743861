import React from "react";
import ReactTooltip from 'react-tooltip'
import ReactDOM from "react-dom";
const CreditDebitModal = ({
  isShowing,preventNonNumericalInput,prevNonNumericalWithDec,
  success,
  hide,
  errors,
  inputValues,
  double,
  handleSubmit,
  stat_operation,
  handleInputs,handleInput
}) => {

 
  return (
    <React.Fragment>
      <div className="modal-overlay" />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal modalm">
          <div className="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Creditors/Debtors
            </h5>
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="form-group mt-2">
            <label>Name <sup class="star">*</sup></label>
            <input
              type="text"
              className="form-control "
              name="name"
              value={inputValues["name"]}
              onChange={handleInputs}
            />
            <div className="errorMsg">{errors.name}</div>
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="address"
              value={inputValues["address"]}
              onChange={handleInputs}
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="number"
              className="form-control hide-arrow ph_no"
              onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
              id={"phone"}
              name="phone"
              value={inputValues["phone"]}
              onChange={handleInputs}
            />
            {<div className="errorMsg">{errors.phone}</div>}
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={inputValues["email"]}
              onChange={handleInputs}
            />
            {<div className="errorMsg">{errors.email}</div>}
          </div>
          <div className="form-group">
            <label>Whatsapp No.</label>
            <input
              type="number"
              className="form-control hide-arrow ph_no"
              onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
              id={"whatspp"}
              name="whatsapp_no"
              value={inputValues["whatsapp_no"]}
              onChange={handleInputs}
            />
            {<div className="errorMsg">{errors.whatsapp_no}</div>}
          </div>
     {console.log(stat_operation,"OI")}  
          <div className="row">
          {stat_operation != "Yes" ?
            <div className="col-md-8 ">
              <div className="form-group">
              <label>Opening Balance<sup class="star">*</sup></label>
                <input
                  type="number"
                  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                  className="form-control hide-arrow"
                  id={"open"}
                  name="opening_balance"
                  value={inputValues["opening_balance"]}
                  onChange={handleInput}
                  readOnly={inputValues["ob_is_dayclose"]}
                />
                <div className="errorMsg">{errors.opening_balance}</div>
                <div className="errorMsg">{errors.debit_credit}</div>

<span  class="amount-words"></span>
              </div>
            </div>: ""}

            <div className="col-md-4 d-flex">
            {stat_operation != "Yes" ?   
              <div className="form-check col-sm mt-4 pr-0">
                   {
                   inputValues.ob_is_dayclose == true ? 
                   <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Need to pay the party'  name="debit_credit"  value="Cr"   onClick={ handleInputs} checked={inputValues.debit_credit === 'Cr'} disabled />:
                   <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Need to pay the party'  name="debit_credit"  value="Cr"   onClick={ handleInputs} checked={inputValues.debit_credit === 'Cr'}  />
                   }
                  
                <label
                  className="form-check-label col-form-label"
                  for="exampleRadios1"
                >
                  {" "}Credit{" "}
                </label>
                <ReactTooltip />
              </div>
              : ""}
{stat_operation != "Yes" ?
              <div className="form-check col-sm mt-4 pr-0">
              
                {/* {...inputValues.debit_credit == "Cr" && "checked"} 
                    {...inputValues.debit_credit == "Dr" && "checked"} */}
               {inputValues.ob_is_dayclose == true ? <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Party to pay us' name="debit_credit" value="Dr" onClick={ handleInputs} disabled checked={inputValues.debit_credit === 'Dr'} />:
                   <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Party to pay us' name="debit_credit" value="Dr" onClick={ handleInputs} checked={inputValues.debit_credit === 'Dr'} />}
                <label
                  className="form-check-label col-form-label"
                  for="exampleRadios1"
                >
                  {" "}Debit{" "}
                </label>
                <ReactTooltip />
              </div>
            :""}
            </div>
          


            <div className="row mt-3">
        <div className="col-md-5">
              <div className="form-group row mx-0">
                <div className="form-check col-sm-12 pr-0">
                  {inputValues.credit_amount ===1 && inputValues.credit_limit !=null ? (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked
                      name="credit_amount"
                      onClick={handleInputs}
                    />
                  ) : (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="credit_amount"
                        onClick={handleInputs}
                      />
                    )}
                  <label
                    className="form-check-label col-form-label"
                    for="exampleRadios1"
                  >
                    {" "}
                    Credit Limit Amount{" "}
                  </label>
                </div>
                </div>
              
                {inputValues.credit_amount === 1 ? (
                  
                  <div className="col-sm-12 px-0 ">
                    <div className="form-group">
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      placeholder=""
                      name="credit_limit"
                      value={inputValues["credit_limit"]}
                      onChange={handleInputs}
                    />
                    
                     {(inputValues.credit_amount ==1 && !inputValues.credit_limit) ? <div className="errorMsg">{errors.credit_limit}</div>:errors.credit_limit=""}
                  
                     <span  class="amount-words"></span>
                    </div>
                  </div>
                ):inputValues.credit_limit=""}
              </div>

            </div>
            <div className="col-md-7">
              <div className="form-group row mx-0 ">
                <div className="form-check col-sm-12 pr-0">
                  {inputValues.credit_period ===1 && inputValues.credit_limit_period !=null? (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked
                      name="credit_period"
                      onClick={handleInputs}
                    />
                  ) : (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="credit_period"
                        onClick={handleInputs}
                      />
                    )}
                  <label
                    className="form-check-label col-form-label"
                    for="exampleRadios1"
                  >
                    {" "}
                    Credit Period
                  </label>
                </div>
                {inputValues.credit_period == 1 ? (
                  <div className="col-sm-8 px-0 ">
                    <div className="select-container">
                      <select
                        id="Reliance"
                        className="form-control"
                        name="credit_limit_period"
                        value={inputValues["credit_limit_period"]}
                        onChange={handleInputs}
                      >
                        <option value="select">Select</option>

                        {inputValues.credit_limit_period === "no_of_days" ? (
                          <option value="no_of_days" selected>
                            {" "}
                            Number of days
                          </option>
                        ) : (
                            <option value="no_of_days"> Number of days</option>
                          )}

                        <option value="one_time">One time</option>
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                        <option value="half_of_month">Half of month</option>
                      </select>
                    </div>
                    {(inputValues.credit_period ==1 && !inputValues.credit_limit_period) ? <div className="errorMsg">{errors.credit_limit_period}</div>:errors.credit_limit_period=""}            
                  </div>
                  
                ) :inputValues.credit_limit_period=""}
                <div className="col-sm-4">
                  {inputValues.credit_limit_period == "no_of_days" ? (
                    <div className="form-group">
                      <input
                        type="number"
                        onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
                        className="form-control"
                        placeholder="Days"
                        name="credit_days"
                        min={1}
                        max={100}
                        value={inputValues["credit_days"]}
                        onChange={handleInputs}
                      />
                    </div>
                  ): inputValues.credit_days=""}
                 {(inputValues.credit_limit_period=="no_of_days" && !inputValues.credit_days)?<div className="errorMsg">{errors.credit_days}</div>:errors.credit_days=""}    
                </div>
               
              </div>
            </div>
            {/* {(inputValues.debit_credit == "Cr" || inputValues.debit_credit =="Dr") && */}



            <div class="modal-footer">
              {double=== true ? (
                <button
                  type="submit"
                  class="btn btn-primary"
                 
                  onClick={handleSubmit}
                >
                  Save
                </button>
              ) : (
                  <button
                    type="submit"
                    class="btn btn-primary"
                    disabled
                  >
                    Save
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CreditDebitModal;
