import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const DeleteModalConfig = ({ isClose,hide,deleteReceipt,double }) => {
    return (
        <React.Fragment>
            <div className="modal-overlay" />
                <div className="modal-wrapper" >
        <div className="modal modal-dialog">
            <div class="modal-content">
					<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Receipt</h5><button type="button" onClick={hide} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Receipt</p></div>
					<div class="justify-content-end  modal-footer"><div class="d-flex">
						<button type="button" class="btn btn-secondary me-2" onClick={hide}>No</button>
						{double == true ? <button type="submit" class="btn btn-primary" onClick={deleteReceipt}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={deleteReceipt}>Yes</button>}
					</div>
					</div>
				</div>
                </div>
</div>


       </React.Fragment>

    );
}
export default DeleteModalConfig