import { Component } from "react";
import React, { useState } from 'react';
import logo from '../../../img/logo.svg';
import { Link } from "react-router-dom";
import { API_URL } from "../../../constant/API_Settings";
import { useEffect } from 'react';
import LoginHeaderOperational from '../LoginHeaderOperational';
import Sidebar_Progress from '../../layouts/Operational_Sidebar_Progress.component'
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import Loader from "./Loader.component";

const EmployeeWorkSheet = (props, summary) => {


   const [dropdownData, setEmployee] = useState([])
   const [errors, setErrors] = useState({})

   const [fuel_sales, setFuleSale] = useState([])
   const [fuel_sales_sum, setFuleSaleSum] = useState([])

   const [summery_fuel_sales, setSummeryFuleSale] = useState([])
   const [summery_fuel_sales_sum, setSummeryFuleSaleSum] = useState([])

   const [loose_oil_sales, setLOilSale] = useState([])
   const [loose_oil_sales_sum, setLOilSaleSum] = useState([])

   let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

   const [packed_oil_sales, setLPOilSale] = useState([])
   const [packed_oil_sales_sum, setLPOilSaleSum] = useState([])

   const [recipt_voucher, setReciptVoucher] = useState([])
   const [recipt_voucher_sum, setReciptVoucherSum] = useState([])

   const [credit_sales, setCRSale] = useState([])
   const [credit_sales_sum, setCRSaleSum] = useState([])

   const [credit_sales_digital_payment, setCRSaleDig] = useState([])
   const [credit_sales_digital_payment_sum, setCRSaleDigSum] = useState([])

   const [fleet_card, setFleet] = useState([])
   const [fleet_card_sum, setFleetSum] = useState([])

   const [recoup, setRecoup] = useState([])
   const [denomination, setDenomination] = useState([])
   const [recoup_sum, setRecoupSum] = useState([])

   const [mla_coupon, setMLA] = useState([])
   const [mla_coupon_sum, setMLASum] = useState([])

   const [paymentVoucher, setPayVoucher] = useState([])
   const [paymentVoucher_sum, setPayVoucherSum] = useState([])

   const [expense, setExpense] = useState([])
   const [expense_sum, setExpenseSum] = useState([])

   const [bag_collection, setBag] = useState([])
   const [bag_collection_sum, setBagSum] = useState([])

   const [closing_cash, setClose] = useState([])
   const [closing_cash_sum, setCloseSum] = useState([])

   const [inputValues, setInputValues] = useState({});
   const [input, setInput] = useState({});
   const [input2, setInput2] = useState([]);
   const history = useHistory()


   const [list_data, setList] = useState([])
   const [list_datatime, setListtime] = useState([])


   const [selected_options, setSelectedOptions] = useState([])

   const [autocall, setAutoCall] = useState(2);
   const [Loading, setLoading] = useState(false);
   

   useEffect(() => {
      check_url()
      check_meta_date_opdate()

   }, []);

   function check_meta_date_opdate() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				localStorage.setItem("op_date", responseData.op_date)

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}

   function check_url() {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            pathurl: window.location.pathname,
         }),
      };
      fetch(`${API_URL}/check_url`, requestOptions)
         .then(response => { return response.json(); })

         .then(responseData => {
            if (responseData.allowurl == 1) {
               history.push(responseData.url);
               get_emp_names();
               allocated_employee_list();        
            }
            else {
               history.goBack();
            }

            return responseData;
         })
         .catch(e => {
            console.log(e)
         });

   }

   
   const handleInput = (e) => {
      setInput({
         ...input,
         [e.target.name]: e.target.value
      });
      if ((e.target.name == "employee_id") && (input.start_date) && (input.start_date == input.end_date)) {
         var emp_id = e.target.value
         var op_date = input.start_date
         getshifttime(emp_id, op_date);
      }

      if ((e.target.name == "start_date") && (input.employee_id) && (e.target.value == input.end_date)) {
         var emp_id = input.employee_id
         var op_date = e.target.value
         getshifttime(emp_id, op_date);
      }
      if ((e.target.name == "end_date") && (input.employee_id) && (e.target.value == input.start_date)) {
         var emp_id = input.employee_id
         var op_date = e.target.value
         getshifttime(emp_id, op_date);
      }

   };

   const handleSelect = (items) => {
      console.log(items)
      var shift_time = []

      items && items.map((item) => {
         shift_time.push(item)
      })

      setInput({
         ...input,
         op_emp_id: shift_time.map(product => product.value),
         op_emp_label: shift_time.map(product => product.label+',')
      });

      list_data.forEach((elem) => {
         if (elem.value == shift_time.map(product => product.value)) {
            setInput2({
               ...input2,
               shift_label: elem.label
            });
         }
      });
      setSelectedOptions(shift_time);

   };


   function getshifttime(emp_id, op_date) {


      setSelectedOptions([])
      setList([])
      const requestOptions = {
         method: 'post',
         body: JSON.stringify({ emp_id: emp_id, op_date: op_date }),
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },

      };

      fetch(API_URL + "/getshifttime", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {

            responseData.forEach((elem) => {
               setList((list_data) => [
                  ...list_data,
                  {
                     value: elem.op_emp_id,
                     label: elem.shift_time,
                  },
               ]);
            });
            return responseData;
         })
         .catch(e => {
            console.log(e);

         });
   }
   const handleSubmit = (event) => {
   
      if (validateForm()) {
         getEmpWorkSheet();
      }
   }

   function validateForm() {
      let errors = []
      let formIsValid = true
      if (!input.start_date) {
         formIsValid = false;
         errors["start_date"] = "Choose Date"
      }
      else {
         errors["start_date"] = ""
      }
      if (!input.end_date) {
         formIsValid = false;
         errors["end_date"] = "Choose Date"
      }
      else {
         errors["end_date"] = ""
      }
      if (!input.employee_id) {
         formIsValid = false;
         errors["employee_id"] = "Choose Employee Name"
      }
      else {
         errors["employee_id"] = ""
      }

      setErrors(errors)

      return formIsValid;

   }
   function downloadPDF() {
      if (validateForm()) {
         const requestOptions = {
            method: 'post',
            headers: {
               "Access-Control-Allow-Origin": "*",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
               "Accept": "application/json"
            },
            body: JSON.stringify({
               from_date: input.start_date,
               to_date: input.end_date,
               emp_id: input.employee_id,
               op_emp_id: input.op_emp_id,
               pdf: 1,
               shift_label: input2.shift_label,
            })
         };
         fetch(API_URL + "/get_emp_work_sheet", requestOptions)
            .then(response => { return response.blob(); })
            .then(responseData => {
               var blob = new Blob([responseData]);
               var link = document.createElement('a');
               link.href = window.URL.createObjectURL(blob);
               link.download = "EmployeeWorkSheet.pdf";
               link.click();

               return responseData;
            })
            .catch(e => {
               console.log(e);
            });
      }
   }


   function getEmpWorkSheetAutoCall() {
     
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },
         body: JSON.stringify({
            from_date: input.start_date,
            to_date: input.end_date,
            emp_id: input.employee_id,
            op_emp_id: input.op_emp_id,
         })
      };
      fetch(API_URL + "/get_emp_work_sheet", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            console.log(responseData.loose_oil_sales)
            setFuleSale(responseData.fuel_sales)
            setFuleSaleSum(responseData.fuel_sales_sum)

            setSummeryFuleSale(responseData.summery_fuel_sales)
            setSummeryFuleSaleSum(responseData.summery_fuel_sales_sum)

            setLOilSale(responseData.loose_oil_sales)
            setLOilSaleSum(responseData.loose_oil_sales_sum)

            setLPOilSale(responseData.packed_oil_sales)
            setLPOilSaleSum(responseData.packed_oil_sales_sum)

            setReciptVoucher(responseData.recipt_voucher)
            setReciptVoucherSum(responseData.recipt_voucher_sum)

            setCRSale(responseData.credit_sales)
            setCRSaleSum(responseData.credit_sales_sum)

            setCRSaleDig(responseData.credit_sales_digital_payment)
            setCRSaleDigSum(responseData.credit_sales_digital_payment_sum)

            setFleet(responseData.fleet_card)
            setFleetSum(responseData.fleet_card_sum)

            setRecoup(responseData.recoup)
            setRecoupSum(responseData.recoup_sum)

            setMLA(responseData.mla_coupon)
            setMLASum(responseData.mla_coupon_sum)

            setPayVoucher(responseData.paymentVoucher)
            setPayVoucherSum(responseData.paymentVoucher_sum)

            setExpense(responseData.expense)
            setExpenseSum(responseData.expense_sum)

            setBag(responseData.bag_collection)
            responseData.bag_collection.map((obj) => {
               console.log(obj)
               setDenomination(obj)
            })
            setBagSum(responseData.bag_collection_sum)

            setClose(responseData.closing_cash)
            setCloseSum(responseData.closing_cash_sum)


            setInputValues({
               ...inputValues,
               total_sale_amount: responseData.total_sale_amount,
               total_settle_amount: responseData.total_settle_amount,
               opening_cash: responseData.opening_cash,
               credit_sales_settle: responseData.credit_sales_settle,
               credit_sales_digital_payment_settle: responseData.credit_sales_digital_payment_settle,
               fleet_card_sum_settle: responseData.fleet_card_sum_settle,
               recoup_sum_settle: responseData.recoup_sum_settle,
               mla_coupon_sum_settle: responseData.mla_coupon_sum_settle,
               paymentVoucher_sum_settle: responseData.paymentVoucher_sum_settle,
               expense_sum_settle: responseData.expense_sum_settle,
               bag_collection_sum_settle: responseData.bag_collection_sum_settle,
               closing_cash_sum_settle: responseData.closing_cash_sum_settle,
               excess: responseData.excess,
               short: responseData.short,
               emp_name: responseData.emp_name,
               pump_name: responseData.pump_name,
               

            });
            setLoading(true);
         })
         .catch(e => {
         });
   }

   function getEmpWorkSheet() {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },
         body: JSON.stringify({
            from_date: input.start_date,
            to_date: input.end_date,
            emp_id: input.employee_id,
            op_emp_id: input.op_emp_id,
         })
      };
      fetch(API_URL + "/get_emp_work_sheet", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            console.log(responseData.loose_oil_sales)
            setFuleSale(responseData.fuel_sales)
            setFuleSaleSum(responseData.fuel_sales_sum)

            setSummeryFuleSale(responseData.summery_fuel_sales)
            setSummeryFuleSaleSum(responseData.summery_fuel_sales_sum)

            setLOilSale(responseData.loose_oil_sales)
            setLOilSaleSum(responseData.loose_oil_sales_sum)

            setLPOilSale(responseData.packed_oil_sales)
            setLPOilSaleSum(responseData.packed_oil_sales_sum)

            setReciptVoucher(responseData.recipt_voucher)
            setReciptVoucherSum(responseData.recipt_voucher_sum)

            setCRSale(responseData.credit_sales)
            setCRSaleSum(responseData.credit_sales_sum)

            setCRSaleDig(responseData.credit_sales_digital_payment)
            setCRSaleDigSum(responseData.credit_sales_digital_payment_sum)

            setFleet(responseData.fleet_card)
            setFleetSum(responseData.fleet_card_sum)

            setRecoup(responseData.recoup)
            setRecoupSum(responseData.recoup_sum)

            setMLA(responseData.mla_coupon)
            setMLASum(responseData.mla_coupon_sum)

            setPayVoucher(responseData.paymentVoucher)
            setPayVoucherSum(responseData.paymentVoucher_sum)

            setExpense(responseData.expense)
            setExpenseSum(responseData.expense_sum)

            setBag(responseData.bag_collection)
            responseData.bag_collection.map((obj) => {
               console.log(obj)
               setDenomination(obj)
            })
            setBagSum(responseData.bag_collection_sum)

            setClose(responseData.closing_cash)
            setCloseSum(responseData.closing_cash_sum)


            setInputValues({
               ...inputValues,
               total_sale_amount: responseData.total_sale_amount,
               total_settle_amount: responseData.total_settle_amount,
               opening_cash: responseData.opening_cash,
               credit_sales_settle: responseData.credit_sales_settle,
               credit_sales_digital_payment_settle: responseData.credit_sales_digital_payment_settle,
               fleet_card_sum_settle: responseData.fleet_card_sum_settle,
               recoup_sum_settle: responseData.recoup_sum_settle,
               mla_coupon_sum_settle: responseData.mla_coupon_sum_settle,
               paymentVoucher_sum_settle: responseData.paymentVoucher_sum_settle,
               expense_sum_settle: responseData.expense_sum_settle,
               bag_collection_sum_settle: responseData.bag_collection_sum_settle,
               closing_cash_sum_settle: responseData.closing_cash_sum_settle,
               excess: responseData.excess,
               short: responseData.short,
               emp_name: responseData.emp_name,
               pump_name: responseData.pump_name,

            });
         })
         .catch(e => {
         });
   }
   function allocated_employee_list() {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         }
      };
      fetch(API_URL + "/allocated-employee-list", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
				if (responseData.status == 3) {
					// setPermission({ ...permission, status: responseData.status, message: responseData.message })

				}
				else {
					setListtime(responseData.data)
               console.log(list_datatime,"jgg")
					setInput2({ ...input2, isdayclose: responseData.isdayclose,has_price_change: responseData.has_price_change  })
					// setpages({
					// 	...pages,
					// 	current_page: responseData.data.current_page,
					// 	last_page: responseData.data.last_page,
					// 	per_pages: responseData.data.per_page,
					// 	total_pages: responseData.data.total,

					// })

				}
				// setLoading(true)
				return responseData;
			})
			.catch(e => {
			});
      }
   function get_emp_names() {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         }
      };
      fetch(API_URL + "/get_emp_names", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            var datas = []
            responseData.forEach(emp => {
               datas.push([emp.id, emp.emp_name])
            })
            setEmployee(datas);
            setInput({
               ...input,
               employee_id: localStorage.getItem("emp_id"),
               start_date: localStorage.getItem("op_date"),
               end_date: localStorage.getItem("op_date")
            })
               setAutoCall(1)
            
         })
         .catch(e => {
         });
   }
console.log(autocall,"test")
   if((input.employee_id && input.start_date && input.end_date) && autocall == 1
    ){
       getEmpWorkSheetAutoCall();
       setAutoCall(3)

    }
   var today = new Date().toISOString().split('T')[0];

   return (
      <React.Fragment>

         <div className="main-container page-wrap">
            <div className="d-md-flex">
               <Sidebar_Progress props={props} progress={9} operation={1} />
             <div className="content-area">
                  <LoginHeaderOperational props={props} page_title="" />
                  {permissions.includes("All Reports" ) || permissions.includes("Employee Worksheet" )  ?
	             <div className="container pb-3">
                     <div className="row justify-content-center mt-md-5 mt-3 form-container">
                     <div className="col-md-12 mt-2"><Link to="/dashboard">
	<a href="/dashboard" class="float-end btn btn-gray" >Back</a></Link>
	 </div>
   <div className="row">
											{Loading ? getEmpWorkSheetAutoCall :
												// <div class="loader text-primary">Loading...</div>
												<Loader />
											}
										</div> 
                        <div className="col-md-12">
                           <div className="col-md-12 d-flex">
                              <div className="text-start d-flex pe-1 mt-3">
                                 <div className="form-group">
                                    <label  >Start date</label>
                                    {/* {list_datatime.map((obj) => {
                                          console.log(obj,"vhvnj")
                                          // return <option value={data[0]}>{data[1]}</option>
                                       }) */}

                                    <input type="date" className="form-control  " placeholder="" name="start_date" max={today} value={input.start_date} onChange={handleInput}></input>
                                    <div className="errorMsg">{errors.start_date}</div>
                                 </div>
                              </div>

                              <div className="text-start d-flex pe-3 mt-3">
                                 <div className="form-group">
                                    <label  >End date</label>
                                    <input type="date" className="form-control  " placeholder="" name="end_date" max={today} value={input.end_date} min={input.start_date} onChange={handleInput}></input>
                                    <div className="errorMsg">{errors.end_date}</div>
                                 </div>
                              </div>

                              <div className="form-row mt-3 pe-3 ">
                                 <label>Employee Name</label>
                                 <div className="form-group select-container">
                                    <select id="" className="form-control" name="employee_id" value={input.employee_id} onChange={handleInput} >
                                       <option value=''>select</option>
                                       {dropdownData.map((data) => {
                                          console.log(data,"vhv")
                                          console.log(localStorage.getItem("op_date"),"opdate")
                                          return <option value={data[0]}>{data[1]}</option>
                                       })
                                       }
                                    </select>
                                    <div className="errorMsg">{errors.employee_id}</div>
                                 </div>
                              </div>


                              <div className="form-row col-3 mt-3 pe-4 ">
                                 <label>Shift Time</label>
                                 <Select
                                    isMulti

                                    value={selected_options}
                                    onChange={handleSelect}
                                    options={list_data}
                                 >
                                 </Select>
                              </div>
                              <div className="text-start d-flex pe-5 mt-5">
                                 <div className="form-group">
                                    <a className="btn btn-primary mb-md-0  ms-1" onClick={handleSubmit}>Submit </a>
                                 </div>
                              </div>
                           </div>
                           <div className="form-wrap balace-sheetwrap ">
                              <div className="row mb-3 head-sec">

                                 <div className="col-md-12">
                                    <h2 className="table-title  mb-1">{inputValues.pump_name}</h2>
                                    <h5 >Employee worksheet</h5>
                                    <h4 className="table-title mb-3">{inputValues.emp_name}</h4>
                                 </div>
                                 <div className="col-md">
                                    <div className="text-left">
                                       <label className="me-3"><b>Start Date</b></label><span>{input.start_date?moment(input.start_date).format("DD-MM-YYYY"):''}</span><br />
                                       <label className="me-3"><b>End Date</b></label><span>{input.end_date?moment(input.end_date).format("DD-MM-YYYY"):''}</span>
                                    </div>
                                    {console.log(input.op_emp_label)}
                                    <div className="text-left">
                                       <label className="me-3"><b>Shift Time</b></label><span>{input.op_emp_label}</span>
                                    </div>
                                 </div>
                              </div>
                              <div className="table-widget">
                                 <h3 className="table-title text-start" >Fuel Sales</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Product Name</th>
                                          <th>Nozzle Name</th>
                                          <th>Opening Reading </th>
                                          <th>Closing Reading</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Sale Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          fuel_sales.map((obj) => (

                                             <tr>
                                                <td>{obj.fuel_name}</td>
                                                <td>{obj.nozzle_name}</td>
                                                <td>{obj.opening_reading}</td>
                                                <td>{obj.close_reading}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="6" className="text-end"><b>Total</b></td>
                                          <td>{fuel_sales_sum}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div className="table-widget">
                                 <h3 className="table-title text-start" >Summary of Fuel Sales</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Product Name</th>
                                          <th>Total Quantity</th>
                                          <th>Price</th>
                                          <th>Total Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          summery_fuel_sales.map((obj) => (
                                             <tr>
                                                <td>{obj.fuel_name}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="3" className="text-end"><b>Total</b></td>
                                          <td>{summery_fuel_sales_sum}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              {/* <div className="table-widget">
                                 <h3 className="table-title text-start" >Loose Oil</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Product Name</th>
                                          <th>Customer Name</th>
                                          <th>Total Quantity</th>
                                          <th>Price</th>
                                          <th>Sale Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          loose_oil_sales.map((obj) => (
                                             <tr>
                                                <td>{obj.product_name}</td>
                                                <td>{obj.sales_type == 1 ? obj.name : '--'}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.sales_price}</td>
                                                <td>{obj.sales_value}</td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="4" className="text-end"><b>Total</b></td>
                                          <td>{loose_oil_sales_sum}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div> */}

                              <div className="table-widget">
                                 <h3 className="table-title text-start" >Packed Oil & Other Product</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Product Name</th>
                                          <th>Customer Name</th>
                                          <th>Total Quantity</th>
                                          <th>Price</th>
                                          <th>Sale Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          packed_oil_sales.map((obj) => (
                                             <tr>
                                                <td>{obj.product_name}</td>
                                                <td>{obj.sales_type == 1 ? obj.name : '--'}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.sales_price}</td>
                                                <td>{obj.sales_value}</td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="4" className="text-end"><b>Total</b></td>
                                          <td>{packed_oil_sales_sum}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="d-flex">
                                 <h3 className="me-3"><b>Total Sales Amount</b></h3><span>{inputValues.total_sale_amount}</span><br />
                              </div>

                              <div className="table-widget">
                                 <h3 className="table-title text-start" >Receipt Voucher</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Account Name</th>
                                          <th>Description</th>
                                          <th>Amount</th>

                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          recipt_voucher.map((obj) => (
                                             <tr>
                                                <td>{obj.ac_name_slug}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.amount}</td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="2" className="text-end"><b>Total</b></td>
                                          <td>{recipt_voucher_sum}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              {/* <div className="d-flex">
                                 <h3 className="me-3"><b>Opening Cash</b></h3><span>{inputValues.opening_cash}</span><br />
                                          </div> */}
                              <div className="d-flex">
                                 <h3 className="me-3"><b>Total Settlement Amount</b></h3><span>{inputValues.total_settle_amount}</span><br />
                              </div>

                              <div className="table-widget ">
                                 <h3 className="table-title text-start" >Credit Sale</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Customer Name</th>
                                          <th>Description</th>
                                          <th>Intent No</th>
                                          <th>Vehicle No</th>
                                          <th>Product Name</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th>Settled amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          credit_sales.map((obj) => (
                                             <tr>
                                                <td>{obj.name ? obj.name : 'New Customer'}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.intent_no}</td>
                                                <td>{obj.vehicle_no}</td>
                                                <td>{obj.fuel_name}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="7" className="text-end"><b>Total</b></td>
                                          <td>{credit_sales_sum}</td>
                                          <td>{inputValues.credit_sales_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget ">
                                 <h3 className="table-title text-start" >Card / Digital Payment</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Payment Type</th>
                                          <th>Card Number</th>
                                          <th>Description</th>
                                          <th>Product Name</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th> Settled amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          credit_sales_digital_payment.map((obj) => (
                                             <tr>
                                                <td>{obj.method_name}</td>
                                                <td>{obj.card_no}</td>
                                                <td>{obj.note}</td>
                                                <td></td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="6" className="text-end"><b>Total</b></td>
                                          <td>{credit_sales_digital_payment_sum}</td>
                                          <td>{inputValues.credit_sales_digital_payment_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget ">
                                 <h3 className="table-title text-start" >Fleet card sales</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>

                                          <th>Card Number</th>
                                          <th>Description</th>
                                          <th>Product Name</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th> Settled amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          fleet_card.map((obj) => (
                                             <tr>
                                                <td>{obj.card_no}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.fuel_name}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="5" className="text-end"><b>Total fleet card sales</b></td>
                                          <td>{fleet_card_sum}</td>
                                          <td>{inputValues.fleet_card_sum_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget ">
                                 <h3 className="table-title text-start" >Recoup</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>

                                          <th>Product Name</th>
                                          <th>Description</th>
                                          <th>Recoup Tank</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th> Settled amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          recoup.map((obj) => (
                                             <tr>
                                                <td>{obj.fuel_name}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.tank_name}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>


                                          <td><b>Total Product wise</b></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>

                                       </tr>
                                       <tr>
                                          <td colspan="5" className="text-end"><b>TOTAL RECOUP</b></td>
                                          <td>{recoup_sum}</td>
                                          <td>{inputValues.recoup_sum_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget ">
                                 <h3 className="table-title text-start">MLA Coupon</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Coupon No</th>
                                          <th>Issue Date</th>
                                          <th>Expiry Date</th>
                                          <th>Description</th>
                                          <th>Product Name</th>
                                          <th>Quantity</th>
                                          <th>Price</th>
                                          <th>Amount</th>
                                          <th>Settled amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          mla_coupon.map((obj) => (
                                             <tr>
                                                <td>{obj.coupon_no}</td>
                                                <td>{obj.issue_date}</td>
                                                <td>{obj.expiry_date}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.fuel_name}</td>
                                                <td>{obj.quantity}</td>
                                                <td>{obj.price}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="7" className="text-end"><b>Total</b></td>
                                          <td>{mla_coupon_sum}</td>
                                          <td>{inputValues.mla_coupon_sum_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget">
                                 <h3 className="table-title text-start">Payment Voucher</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Account Name</th>
                                          <th>Description</th>
                                          <th>Amount</th>
                                          <th>Settled Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          paymentVoucher.map((obj) => (
                                             <tr>
                                                <td>{obj.ac_name_slug}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="2" className="text-end"><b>Total Amount</b></td>
                                          <td>{paymentVoucher_sum}</td>
                                          <td>{inputValues.paymentVoucher_sum_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget">
                                 <h3 className="table-title text-start">Expense Voucher</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Expense Head</th>
                                          <th>Description</th>
                                          <th>Amount</th>
                                          <th>Settled Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          expense.map((obj) => (
                                             <tr>
                                                <td>{obj.name}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.amount}</td>
                                                <td></td>
                                             </tr>
                                          ))}
                                       <tr>
                                          <td colspan="2" className="text-end"><b>Total Amount</b></td>
                                          <td>{expense_sum}</td>
                                          <td>{inputValues.expense_sum_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              {/* <div className="table-widget">
                                 <h3 className="table-title text-start">Bag Collection</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Reference Number</th>
                                          <th>Description</th>
                                          <th>Amount</th>
                                          <th>Denomination</th>
                                          <th>Settled Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          bag_collection.map((obj) => (
                                             <tr>
                                                <td>{obj.ref_no}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.amount}</td>
                                                <td>
                                                   {
                                                      obj.bag_denomination.map((obj) => (
                                                         <>
                                                            <td>
                                                                  <b>{obj.amount}*{obj.count}=<strong>{obj.multiple}</strong></b>
                                                               </td>
                                                           </>
                                                      ))}

                                                </td>
                                               
                                             </tr>

                                          ))}
                                       <tr>
                                          <td colspan="2" className="text-end"><b>Total Amount</b></td>
                                          <td>{bag_collection_sum}</td>
                                          <td></td>
                                          <td>{inputValues.bag_collection_sum_settle}</td>

                                       </tr>
                                    </tbody>
                                 </table>
                              </div> */}
                              <div className="table-widget">
                                 <h3 className="table-title text-start">Bag Collection</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Reference Number</th>
                                          <th>Description</th>
                                          <th>Amount</th>
                                          <th>Denomination</th>
                                          <th>Settled Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          bag_collection.map((obj) => (
                                             <tr>
                                                <td>{obj.ref_no}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.amount}</td>
                                                <td>
                                                   {
                                                      obj.bag_denomination.map((obj) => (
                                                         <>
                                                            <tr>
                                                               <td className="col-md-1">{obj.amount}</td>

                                                               <td >{obj.count}</td>
                                                               <td >=</td>
                                                               <td ><strong>{obj.multiple}</strong></td>
                                                            </tr>

                                                         </>
                                                      ))}

                                                </td>
                                                <td></td>
                                             </tr>

                                          ))}
                                       <tr>
                                          <td colspan="2" className="text-end"><b>Total Amount</b></td>
                                          <td>{bag_collection_sum}</td>
                                          <td></td>
                                          <td>{inputValues.bag_collection_sum_settle}</td>

                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div className="table-widget">
                                 <h3 className="table-title text-start">Closing Cash</h3>
                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th>Reference Number</th>
                                          <th>Description</th>
                                          <th>Amount</th>
                                          <th>Denomination</th>
                                          <th>Settled Amount</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {
                                          closing_cash.map((obj) => (
                                             <tr>
                                                <td>{obj.ref_no}</td>
                                                <td>{obj.note}</td>
                                                <td>{obj.amount}</td>
                                                <td>
                                                   {
                                                      obj.close_denomination.map((obj) => (
                                                         <>
                                                            <tr>
                                                               <td className="col-md-1">{obj.amount}</td>

                                                               <td>{obj.count}</td>
                                                               <td>=</td>
                                                               <td ><strong>{obj.multiple}</strong></td>
                                                            </tr>

                                                         </>
                                                      ))}
                                                </td>
                                                <td></td>
                                             </tr>

                                          ))}
                                       <tr>
                                          <td colspan="2" className="text-end"><b>Total Amount</b></td>
                                          <td>{closing_cash_sum}</td>
                                          <td></td>
                                          <td>{inputValues.short > 0 ? "-" +inputValues.closing_cash_sum_settle:inputValues.closing_cash_sum_settle}</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>



                              <div className="row">
                                 <div className="col-md-3">
                                    <h3 className=" mt-2 text-start"><b>Cash Received Amount</b></h3></div>
                                 <div className="col-md-3">
                                    <input type="text" value={closing_cash_sum} className=" form-control highlight" />
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-md-3">
                                    <h3 className=" mt-2 text-start"><b>Short</b></h3></div>
                                 <div className="col-md-3">
                                    <input type="text" value={inputValues.short > 0 ? "-" +inputValues.short:inputValues.short} className=" form-control highlight" />
                                 </div>
                              </div>
                              <div className="row">
                                 <div className="col-md-3">
                                    <h3 className=" mt-2 text-start"><b>Excess</b></h3></div>
                                 <div className="col-md-3">
                                    <input type="text" value={inputValues.excess} className=" form-control highlight" />
                                 </div>
                              </div>

                              {/* <div className="table-widget">

                                 <table className="table border">
                                    <thead>
                                       <tr>
                                          <th className="text-center">Nozzle Name</th>
                                          <th colspan="2" className="text-center">Average Daily Sales</th>
                                          <th colspan="2" className="text-center">Target</th>

                                       </tr>
                                       <tr>
                                          <th>al ms</th>
                                          <th >Litre</th>
                                          <th >%</th>
                                          <th >Litre</th>
                                          <th >%</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                       </tr>

                                    </tbody>
                                 </table>
                              </div> */}

                              <div className="text-end py-2">

                                 <h6 className="text-end">Signature</h6>
                              </div>
                           </div>
                           <div className="col-md">
                              <div className="text-end mt-4"><a onClick={() => downloadPDF()} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a> </div>
                           </div>
                        </div>
                     </div>
                  </div>:<div  className="form-wrap mt-5">
  <div className="text-center">
  You don't have permission to access this page
  </div></div>
}
               </div>
            </div>
         </div>
      </React.Fragment>


   )
}


export default EmployeeWorkSheet