import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import DayClosingOperationConfig from './DayClosingOperationConfig.component';
const DayClosingOperationModal = ({ isShowing, hide, setVehicle, errors, success, inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs }) => {
    return (
        <React.Fragment>

            {console.log("dsff")}
            <div class="container pb-3">
              
            
        <div class="row mb-3 justify-content-center">
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal modal-dialog-xl modalxxl col-12 ">
                          <div class="modal-header mb-3">
                                  <h5 class="text-center" id="staticBackdropLabel">Day closing Operations</h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>
                    
				
                    


                  


<div clas="col-12">
<div class="row mb-3 ">
<div class="row g-3 justify-content-center  mb-2">
        <div class="col-4 ">
        <label class="mx-5 text-center">Day close</label>
        </div>
          <div class="col-4">
              
          <div class="select-container">
                    <select id="" class="form-control highlight ">
                      <option>3/12/2020</option>
                      <option>...</option>
                    </select>
                  </div>
                  </div>
<div class="col-4">
<label class="col-form-label"> Date After Last day Close date Only</label>
</div>
</div>
</div>

<div class="modal-footer text-center">
             
            
     <a href="#" class="btn btn-primary  w-auto ">Save</a>
   </div>      
        </div>
    </div>
    </div>
</div>
</div>

       </React.Fragment>

    );
}
export default DayClosingOperationModal