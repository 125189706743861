import React, { useMemo,useState } from 'react';
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import moment from 'moment';
import Loader from '../Employee/Loader.component'
import LoginHeaderOperational from '../LoginHeaderOperational';
import { WEB_URL } from "../../../constant/API_Settings"



const DayClosingConfig = (location) => {
	const backPage = useMemo(() => {

		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])



	const [input, setInput] = useState([]);
	const [input1, setInput1] = useState([]);
	const [inputValues, setInputValues] = useState([]);
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [list_data2, setList2] = useState([])
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState([])
	const [Loading, setLoading] = useState(false)
	const [permission, setPermission] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {

		check_url()
		check_meta_date()
		
	}, [search]);
	function check_meta_date() {

		const requestOptions = {
		  method: 'post',
		  headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
	
		  }
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
		  .then(response => { return response.json(); })
		  .then(responseData => {
			// this.setState({ op_date:responseData.op_date,userId:responseData.userId})
			setUser({ user_id: responseData.userId })
	
	
			return responseData;
		  })
		  .catch(e => {
		  });
	
	  }
	  const [user, setUser] = useState()
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					GetDate();
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	const handleChange = (e) => {
		setInputValues({ ...inputValues, op_date: e.target.value })
		GetDate(e.target.value)
	}

	function GetDate(date) { 
		if (date == '') {
			date = '';
		}
		const requestOptions = {      //---employee listing---//
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				op_date: date
			})
		};
		fetch(`${API_URL}/get_closing_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInput({
					...input,
					config_date: responseData.start_date,
					end_date: responseData.end_date
				})
				setInputValues({ ...inputValues, op_date: responseData.op_date })
				ListPendingDayClosing(pages.current_page, responseData.op_date)

				return responseData;
			})
			.catch(e => {
			});
	}

	function ListPendingDayClosing(page, date) {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}, body: JSON.stringify({
				day_closing_date: date,
			})
		};
		fetch(`${API_URL}/get_day_closing?page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				else {
					setInput1({
						...input1, flag: responseData.flag,
						isdayclose: responseData.isdayclose
					})
					setList(responseData.ar1)
					setList2(responseData.ar2)

					var total_pages = responseData.last_page

					var items = []
					for (let number = 1; number <= total_pages; number++) {
						number === page ? items.push(
							<li key={number} className="page-item active"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,) : items.push(
								<li key={number} className="page-item"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,)
					}

					setpages({
						...pages,
						current_page: responseData.current_page,
						last_page: responseData.last_page,
						total_pages: responseData.last_page,
						pagination_items: items
					})
				}
				setLoading(true);
				return responseData;
			})
			.catch(e => {


			});
	}

	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	const handleSubmit = (event) => {
		//event.preventDefault();
		if (validateForm());
		// alert(12334)

		
		if (input1.flag == 0) {
			sendcloseData()
			//swal("Confirmed", "You clicked Yes!", "success");
		}
	}
	//---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (input1.flag == 1) {
			formIsValid = false;
			errors["flag"] = "Close above transactins"
		}
		else {
			errors["flag"] = ""
		}
		setErrors(errors)
		return formIsValid;
	}
	function sendcloseData() {
		setLoading(false);
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				config_date: inputValues.op_date,
				flag: input1.flag
			})
		};
		fetch(API_URL + "/save_day_closing", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setLoading(true);

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {

					swal("", responseData.message, responseData.status ? "success" : "warning")
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					setErrors([''])
					ListPendingDayClosing(pages.current_page, inputValues.op_date);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}
// const go_order=(data)=>{
// 	history.push({
// 		pathname:data?.h,
// 		state: {id: 123} 
// 	});

// }

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress  progress={10} operation={1} />
					<div className="content-area">
						<LoginHeaderOperational page_title="" />

						<div className="container pb-3">
							{Loading ? ListPendingDayClosing : <Loader />}
							<div className="container pb-3">
							{Loading ?  sendcloseData: <Loader />}
							</div>
							<div class="row">
								<div className="col-md-12 mb-3 px-4 mt-3">
									<Link to={backPage||"/dashboard"}>
										<a href={backPage||"/dashboard"} className="float-end btn btn-gray">BACK</a>
									</Link></div></div>

						</div>
						<div class="container pb-3">
							<div class="row mt-md-5 mt-3 form-container">
								<div className="col-md-12">
									<div className="form-wrap list-wrp">

										<div className="row mb-3">
											<div className="col-md">
												<div className="row">
													<div className="col-md-1 col-2 pe-0">
														<img src={customer} width="25px" className="img-fluid" alt=".." />
													</div>
													<div className="col-md-6 col pl-0">
														<h3 className="table-title">Day Closing - {moment(inputValues.op_date).format("DD-MM-YYYY")}</h3>

													</div>
												</div>
											</div>
											<div className="col-md-3">



											</div>
											<div className="col-md-3">
												{/* <input type="date" class="form-control" name="startDate" min={input.config_date}  max={moment().format("YYYY-MM-DD")} onChange={handleChange} value={input.config_date}/> */}
												<input type="date" class="form-control" name="startDate" min={input.config_date} max={input.end_date} onChange={handleChange} value={inputValues.op_date} />

											</div>
										</div>

										{permission.status == 3 ?
											<div className="form-wrap">
												<div className="text-center">
													{permission.message}
												</div></div> :
											<>
												<div className="row">
													<div className="col-md-6">
														<div className="table-widget">
															<table className="table border">
																<thead>
																	<tr>
																		<th>Pending Employee Closing Transaction</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		list_data.map((obj) => (
																			<tr>
																				<td><Link
 																					to={{
    																				pathname: obj.url,
																					state:inputValues.op_date
																				}}
																				>{obj.name} - {obj.val}
																				</Link></td>
																				{/* <td> <a target="blank" href={obj.url} >{obj.name} - {obj.val}</a></td> */}
																			</tr>
																		))
																	}
																</tbody>

															</table>

														</div>
													</div>
													<div className="col-md-6">
														<div className="table-widget">
															<table className="table border">
																<thead>
																	<tr>
																		<th>Approval Pending Vouchers</th>
																	</tr>
																</thead>
																<tbody>
																	{
																		list_data2.map((obj) => (
																			<tr>
																				<td><Link
 																					to={{
    																				pathname: obj.url,
																					state:inputValues.op_date
																				}}
																				>{obj.name} - {obj.val}
																				</Link></td>
																				{/* <td><Link target="blank" to={{pathname:obj.url,state:{ prevPath: location.pathname }}}>{obj.name} - {obj.val}</Link></td> */}
																			</tr>
																		))
																	}
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<nav aria-label="Page navigation example">
													<ul className="pagination justify-content-end">
														{pages.total_pages > 1 && pages.pagination_item}
													</ul>
												</nav>
											</>
										}
										<div className="errorMsg">{errors.flag}</div>
										<div className="text-end mt-3">
											{input1.isdayclose == 1 ? (
												<div>
													<a class="btn btn-primary w-auto btn-green" disabled type="submit">Day Closed</a>
												</div>
											) : <a class="btn btn-primary w-auto" type="submit" onClick={handleSubmit}>Day Close</a>}
										</div>
										<div className="col-md">
											<div className="text-end mt-4">
												<a className="btn btn-success px-3 mb-md-0 mb-3 me-3" href="op-simplified-balance-sheet">Balance Sheet </a>
												<a className="btn btn-success px-3 mb-md-0 mb-3  md-3" href={`${WEB_URL}/other-report/${user?.user_id}/trade_loss_gain`}>Profit/Loss</a>
												{/* href="op_profit_loss" */}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
		</>
	)
}


export default DayClosingConfig