import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import Select from "react-select";
import swal from 'sweetalert';
const AccountToAccountModal = ({ preventNonNumericalInput,	edit_list,prevNonNumericalWithDec,isShowing, hide,double,startDate, setVehicle, errors, success, inputValues,selected_options,handleInput,selected_options1, handleSelect,handleAdd, handleRemove, fields, acCategory, handleSubmit, handleInputs, toCategory,fromoutstanding,tooutstanding,cashbalance }) => {

var today = new Date().toISOString().split('T')[0];
const handleSubmit1 = () => {
  swal("","After approval edit is not possible","warning")
  };
  // const DropDown = ({ data }) => (
  //   <select className="form-control highlight" name="from_ac_name_slug" onChange={handleInputs} value={inputValues.from_ac_name_slug}>
  //     <option selected value="">Account Name</option>
  //     {
  //       data.map(({ ac_cat, sub_cat }, key) => (
  //         <optgroup label={ac_cat} key={key}>
  //           {
  //             sub_cat.map(({ id, sub_head }) => (
  //               <option value={id}>
  //                 {sub_head}
  //               </option>
  //             ))
  //           }
  //         </optgroup>
  //       ))
  //     }
  //   </select>
  // )

  // const DropDownTo = ({ data }) => (
  //   <select className="form-control highlight" name="to_ac_name_slug" onChange={handleInput} value={inputValues.to_ac_name_slug}>
  //     <option selected disabled>Account Name</option>
  //     {
  //       data.map(({ ac_cat, sub_cat }, key) => (
  //         <optgroup label={ac_cat} key={key}>
  //           {
  //             sub_cat.map(({ id, sub_head }) => (
  //               <option value={id}>
  //                 {sub_head}
  //               </option>
  //             ))
  //           }
  //         </optgroup>
  //       ))
  //     }
  //   </select>
  // )
  return (
    <React.Fragment>
      <div className="container pb-3">


        <div className="row mb-3 justify-content-center">
          <div className="modal-overlay" />
          <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className="modal  modalxl col-12 ">
              <div class="modal-header mb-3">

                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="row mt-md-3 form-container justify-content-center">
                <div class="col-md-12">
                  <div class="">
                    <div class="row mb-3 justify-content-center">


                      <div class="col-md-12 col-12 pl-0 mb-4">
                        <h3 class="table-title text-center">Account to account transfer </h3>
                      </div>
                      <div class="d-md-flex">
                      <div clas="col-md-6">
                        <div class="form-wrap eq-ht">
                          <div class="row g-3 mb-2">
                            <div class="col-12">
                              <label class="col-form-label mx-2">From Account<sup class="star">*</sup></label>
                            </div>
                          </div>
                          <div class="row g-3 mb-2">
                            <div class="col-12">
                              {/* <div class="select-container"> */}
                              <Fragment>
                                    <Select name="from_ac_name_slug" options={acCategory} defaultValue={selected_options} onChange={handleSelect}/>
                                    </Fragment>
                                {/* <DropDown data={acCategory} /> */}
                              {/* </div> */}
                              <div className="errorMsg">{errors.from_ac_name_slug}</div>
                            </div>
                          </div>
                          <div class="row g-3 mb-2">
                            <div class="col-6">
                              <label class="col-form-label mx-2">Amount<sup class="star">*</sup></label>
                            </div>
                            <div class="col-6">
                              <input type="number" name="amount" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control highlight hide-arrow amount" value={inputValues.amount} onChange={(event) => handleInputs(event)}></input>
                              <span class="amount-words"></span>
                              <div className="errorMsg">{errors.amount}</div>
                            </div>
                          </div>
                          <div className="row g-3   mb-2">
                            <div className="col-6">
                              <label className="col-form-label mx-2">Description</label>
                            </div>
                            <div className="col-6">
                              <textarea type="text" name="note" className="form-control highlight" value={inputValues.note} placeholder="" onChange={(event) => handleInputs(event)}></textarea>
                            </div>

                          </div>
                          <div class="row g-3 mb-2">
                            <div className="col-6">
                              <label className="col-form-label mx-2">Date<sup class="star">*</sup></label>
                            </div>
                            <div className="col-6">
                            
                              <input type="date" name="date" className="form-control highlight" min={startDate}  max={today} id={"m_op_date"} value={inputValues.date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate):localStorage.getItem("m_op_date")) :inputValues.date} placeholder="yyyy-mm-dd" onChange={(event) => handleInputs(event)} disabled></input>
                           
                              <div className="errorMsg">{errors.date}</div>
                            </div>
                          </div>
                          
                            <section className="col-12 mt-3">
                              <div className="contaniner">



                                <div className="row g-2 justify-content-center mb-2">
                                  <div className="">
                                    <label className="col-form-label mx-2"><b>Outstanding Before This Transaction</b></label>
                                  </div>

                                  <div className="col-6">
                                    <label className="col-form-label mx-2">Before Approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={fromoutstanding.before_approval?parseFloat(fromoutstanding.before_approval).toFixed(2):'0'}   className="form-control highlight" readOnly={true}></input>
                                  </div>
                                  
                                  <div className="col-6">
                                    <label className="col-form-label mx-2">After Approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={fromoutstanding.after_approval?parseFloat(fromoutstanding.after_approval).toFixed(2):'0'} className="form-control highlight" readOnly={true}></input>
                                  </div>
                                  
                                  <div className="col-6">
                                    <label className="col-form-label mx-2">After Dayclosing</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={fromoutstanding.after_dayclose?parseFloat(fromoutstanding.after_dayclose).toFixed(2):'0'} className="form-control highlight" readOnly={true}></input>
                                  </div>
                                </div>
                              
                              </div>

                            </section>
                            
                          
                          
                            <section className="col-12 mt-3">
                              <div className="contaniner">
                                <div className="row g-2 justify-content-center mb-2">
                                  <div className="">
                                    <label className="col-form-label mx-2"><b>Outstanding  After This Transaction </b></label>
                                  </div>

                                  <div className="col-6">
                                    <label className="col-form-label mx-2"> Before Approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" id ="outs_before_approval" className="form-control highlight" readOnly={true}></input>
                                  </div>

                                </div>
                              </div>

                            </section>                
                        </div>
                        </div>
                        <div clas="col-md-6 ">
                        <div class="form-wrap  eq-ht ">
                          <div class="row   g-3 mb-2">
                            <div class="col-12">
                              <label class="col-form-label mx-2">To Account<sup class="star">*</sup></label>
                            </div>
                            <div class="col-12">
                              
                               
                                {/* <DropDownTo data={toCategory} /> */}
                                <Fragment>
                                    <Select name="to_ac_name_slug" options={toCategory} onChange={handleInput} defaultValue={selected_options1}/>
                                    </Fragment>
                             
                              <div className="errorMsg">{errors.to_ac_name_slug}</div>
                            </div>
                          </div>
                          <div className="row g-3   mb-2">
                            <div className="col-6">
                              <label className="col-form-label mx-2">Cheque Number</label>
                            </div>
                            <div className="col-6">
                              <input type="number" name="cheque_no" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} className="form-control highlight hide-arrow" value={inputValues.cheque_no} placeholder="Cheque Number" onChange={(event) => handleInputs(event)}></input>
                            </div>
                            </div>
                          
                            <section className="col-12 mt-3">
                              <div className="contaniner">
                                <div className="row g-2 justify-content-center mb-2">
                                  <div className="">
                                    <label className="col-form-label mx-2"><b>Outstanding Before This Transaction</b></label>
                                  </div>

                                  <div className="col-6">
                                    <label className="col-form-label mx-2">Before Approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={tooutstanding.before_approval?parseFloat(tooutstanding.before_approval).toFixed(2):'0'} className="form-control highlight" readOnly={true}></input>
                                  </div>
                                  <div className="col-6">
                                    <label className="col-form-label mx-2">After Approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={tooutstanding.after_approval?parseFloat(tooutstanding.after_approval).toFixed(2):'0'} className="form-control highlight" readOnly={true}></input>
                                  </div>
                                  <div className="col-6">
                                    <label className="col-form-label mx-2">After Dayclosing</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={tooutstanding.after_dayclose?parseFloat(tooutstanding.after_dayclose).toFixed(2):'0'} className="form-control highlight" readOnly={true}></input>
                                  </div>

                                </div>
                              
                              </div>

                            </section>
                            
                          
                          
                            <section className="col-12 mt-3">
                              <div className="contaniner">
                                <div className="row g-3 justify-content-center mb-2">
                                  <div className="">
                                    <label className="col-form-label mx-2"><b>Outstanding  After This Transaction </b></label>
                                  </div>

                                  <div className="col-6">
                                    <label className="col-form-label mx-2"> Before Approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" id ="outs2_before_approval" className="form-control highlight" readOnly={true}></input>
                                  </div>

                                </div>
                              </div>

                            </section>
                            
                         
                        </div>
                        </div>

                      </div>
                      <div>
                      {/* <div class="d-md-flex">
                        <div class="col-md-12 mt-3">
                      <div class="form-wrap ">
                        <div class="row g-3 mb-2">
                        <section className="col-md-6 mt-3">
                              <div className="contaniner">
                                <div className="row g-2 justify-content-center mb-2">
                                  <div className="">
                                    <label className="col-form-label mx-3"> <b>Cash Balance Before This</b></label>
                                  </div>

                                  <div className="col-6 ">
                                    <label className="col-form-label mx-3 ">Before approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={cashbalance.before_approval} className="form-control highlight" readOnly={true}></input>
                                  </div>
                                  <div className="col-6">
                                    <label className="col-form-label mx-3">After approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={cashbalance.after_approval}  className="form-control highlight" readOnly={true}></input>
                                  </div>
                                  
                                  <div className="col-6">
                                    <label className="col-form-label mx-3">After Dayclosing</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" value={cashbalance.after_dayclose}  className="form-control highlight" readOnly={true}></input>
                                  </div>
                                </div>

                              </div>
                            </section>

                        <section className="col-md-6 mt-3">
                              <div className="contaniner">
                                <div className="row g-2 justify-content-center mb-2">
                                  <div className="">
                                    <label className="col-form-label mx-3"><b>Cash Balance  After This</b></label>
                                  </div>

                                  <div className="col-6">
                                    <label className="col-form-label mx-3">Before approval</label>
                                  </div>
                                  <div className="col-6">
                                    <input type="text" id ="cashb_before_approval" className="form-control highlight" readOnly={true}></input>
                                  </div>
                                </div>
                              </div>
                            </section>


                        </div>
                        </div>
                        </div>
                      </div> */}
                      <div clas="">
                        <div class="text-end mt-3">
                          <a href="#" class="btn btn-danger px-5 me-3" onClick={hide}>Cancel </a>
                          {double === true ? edit_list.status != 3 ? <a href="#" class="btn btn-success px-5" onClick={(event) => handleSubmit(event)}>Transfer </a>: <a href="#" class="btn btn-success px-5" onClick={(event) => handleSubmit1(event)}>Transfer </a>:<a href="#" class="btn btn-success px-5" disabled >Transfer </a> }
                          {/* {(double === true  && edit_list.status ==3 )? <a href="#" class="btn btn-success px-5" onClick={(event) => handleSubmit1(event)}>Transfer </a>:<a href="#" class="btn btn-success px-5" onClick={(event) => handleSubmit(event)} >Transfer </a> } */}

                        </div>
                        </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>

  );
}
export default AccountToAccountModal