import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip' 
import moment from 'moment';


const MlacouponModal =({ preventNonNumericalInput,prevDec,isShowing, hide, double, errors,inputValues,productData,success, handleChange,new_price,  handleSubmit,onChange,handleProduct,input, handleInputs,handleChange1,handleInput }) => {

  return (
  <React.Fragment>
     

<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">
       
        <div class="modal-header mb-3">
        <h5 class=" text-center" id="staticBackdropLabel">MLA Coupon</h5>
        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
    <div className="modal-body">
                                    <div class="text-center mb-4">
                                      <h3 class="table-title">MLA Coupon</h3>
                                    </div>
                                       <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-6">
                                        <label  class="col-form-label font-weight-bolder">MLA Name (Default)</label>
                                      </div>
                                      <div class="col-6">
                                        <input type="text"  class="form-control "  name="mla_name" value="MLA Coupon" onChange={handleChange}  readOnly></input>
                                      </div>
                                     
                                    </div>
                                    <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-6">
                                        <label  class="col-form-label font-weight-bolder">Issue date </label>
                                      </div>
                                   
                                      <div class="col-6">
                                        <input  type="date"  id="txtDate" className="form-control unstyled" name="issue_date" max={localStorage.getItem("op_date")} value={inputValues.issue_date} onChange={handleChange} ></input>
                                      </div>
                                     
                                    </div>
                                    <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-6">
                                        <label  class="col-form-label font-weight-bolder">Coupon Number  </label>
                                      </div>
                                      <div class="col-6">
                                        <input type="text"  onKeyPress={(event)=>prevDec(event,event.target.value)}  class="form-control hide-arrow"  name="coupon_no"  value={inputValues.coupon_no} onChange={handleChange}></input>
                                      </div>
                                     
                                    </div>
                                    <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-6">
                                        <label  class="col-form-label font-weight-bolder" >Expiry Date  </label>
                                      </div>
                                      <div class="col-6">
                                        <input type="date"  class="form-control " name="expiry_date" defaultValue={inputValues.id?inputValues.expiry_date:""} min={inputValues.issue_date}  id="mydate"  onChange={handleChange1}></input>
                                      
                                      </div>
                                     
                                    </div>
                                    <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-6">
                                        <label  class="col-form-label font-weight-bolder">Description  </label>
                                      </div>
                                      <div class="col-6">
                                        <input type="text"  class="form-control " name="note"  value={inputValues.note} onChange={handleChange}></input>
                                      </div>
                                     
                                    </div>  
                                    <div class="row g-3 justify-content-center mb-2">
                                      <div class="col-6">
                                        <label  class="col-form-label">Product</label>
                                      </div>
                                      <div class="col-6">
                                      <div class="select-container">
                                        <select id="" class="form-control " name="product_id" value={inputValues.product_id} onChange={handleProduct}>
                                            <option value="">select</option>
                                            {
                                        productData.map((data)=>{

                                          return  <option value={data[0]}>{data[1]}</option>
                                        })}
                                        </select>
                                        <div className="errorMsg">{errors.product}</div>
                                    </div>
                                      </div>
                                     
                                    </div>
                                    <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Quantity</label>
                                </div>
                                <div class="col-6">
                                    <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control  hide-arrow" name="quantity" value={inputValues.quantity} onChange={handleInput} />
                                </div>
                            </div>

                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Price (auto)</label>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control " name="price" value={inputValues.price} onChange={handleInputs} readOnly />
                                </div>
                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Amount<sub class="star">*</sub></label>
                                </div>
                                <div class="col-6">
                                    <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control   hide-arrow" name="amount" value={inputValues.amount} onChange={handleInputs}  />
                                   
                                   <div className="errorMsg">{errors.amount}</div>
                                   <span  class="amount-words"></span>
                                    

                                </div>
                            </div>
                            
    {localStorage.getItem("save_hide") == 'false' ?
                            <div class="modal-footer text-center">

                                <a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
                                {double===true ?  <a class="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit}>SAVE</a>:<a class="btn btn-primary px-4 w-auto" type="submit"   disabled  >SAVE</a>}
                            </div>:''}

                        

   </div>
   </div>
    </div> 
   
    
</React.Fragment>

  );
}
       export default MlacouponModal