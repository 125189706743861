import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
// import BagCollectionModalConfig from './BagCollectionModalConfig.component'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import BagCollectionModal from './BagCollectionModal.component';
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import BagCollectionModal from '../BagCollection/BagCollectionModal.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Loader from '../Loader.component'
import DeleteModalConfig from './DeleteModalConfig.component'
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
import PaginationComponent from '../../../layouts/PaginationComponent';

import PrintModalConfig from './PrintModalConfig.component';
import moment from 'moment';

const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const BagModal = (props) => ReactDOM.createPortal(
	<BagCollectionModal  {...props} />, document.body
);
const DeleteModal = (props) => ReactDOM.createPortal(
	<DeleteModalConfig  {...props} />, document.body
);
const PrintModal = (props) => ReactDOM.createPortal(
	<PrintModalConfig  {...props} />, document.body
);
const BagCollectionConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [print_details, setPrint] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [input, setInput] = useState({});
	const [input1, setInput1] = useState({});
	const [input2, setInput2] = useState({});
	const [shift, setShit] = useState({});
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [total, setTotal] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [denomination, setDenomination] = useState([])
	const [denm, setDenm] = useState([])
	const [summary, setSummary] = useState([])
	const [double, setDouble] = useState(true);


	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {
		check_url()



	}, []);
	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput1({ ...input1, op_date: responseData.op_date })
					getSummerySheet();
					ListBagCollection(pages.current_page);
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});


	}
	function ListBagCollection(page) {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/bag-collection?search_keyword=${search}&page=${page}&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setList(responseData.data.data)
				setInput2({ ...input2, isdayclose: responseData.isdayclose })

				setShit({ ...shift, shift_time: responseData.shift_time })
				setLoading(true);

				setTotal({
					...total,
					totalamount: responseData.total_amount,
				})



				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})

				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	function getDenomination() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get-denomination", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				responseData.denomination.forEach((amount) => {
					setDenm((denm) => [
						...denm,
						{
							amount
						},
					]);
				});

			})
			.catch(e => {
				console.log(e);

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListBagCollection(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {

			var isdayclose = input2.isdayclose;
			if (isdayclose == 1) {
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else {
				if (isShowing) {
					setInput({})
					setInputValues({})
					setDouble(true)
					setDenm([])
					setErrors({})
					setSuccess({})
					setFields([''])
					const denomi = [...denomination];
					if (denomi != "") {
						denomi.forEach((obj, index) => {
							obj.multiple = ""
							obj.count = ""
						})
						setDenomination([])
					}
					else {
						setDenomination([])
					}


				}
				if (!isShowing) {
					setDouble(true);
					setErrors({});

					getDenomination();

				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	//---Delete popup function---//
	const UsePrintModal = () => {

		const [isOpen, setOpen] = useState(false);

		function togglePrint() {

			setOpen(!isOpen);
		}

		return {
			isOpen,
			togglePrint,
		}
	};
	const { isOpen, togglePrint } = UsePrintModal();

	const handleInput = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,

		});
		convertAmountToWords(e, e.target.value)



	};

	const handleInputs = (e) => {
		setInput({
			...input,
			[e.target.name]: e.target.value,

		});
	}

	//---onchange functions---//
	function handleChange(event, idx) {
		const denomi = [...denomination];

		if (denomi[idx]) {
	
			var value = event.target.value
			denomi[idx]['multiple'] = (denomi[idx].amount * event.target.value)
			denomi[idx]['count'] = event.target.value
			var amount = 0
			var changeValue = 0
			var currentValue = 0
			var els = document.getElementsByClassName('elem');
			for (var i = 0; i < els.length; i++) {
				var changeValue = document.getElementById('change' + i).value;
				var currentValue = document.getElementById('multiple' + idx).value;
				amount += denomi[i].amount * changeValue
				
				// denomination['count'] =changeValue
				// denomination['amount'] =currentValue
			}


			document.getElementById('amountww').value = amount
			document.getElementById('amount').value = amount
			setDenomination(denomi)
			setInputValues({ ...inputValues })
			setInput({ ...input, denomination })
			var t = document.getElementById('amountww').value
			if(Number(input.amount) > 0){
				if (Number(t) > Number(input.amount)) {
					console.log(Number(t),Number(input.amount),"okk")
					swal("", "Total is greater than amount", "warning")
				}
			}
			
			if (event.target.name == "multiple") {
				// alert(3)
				setInput({ ...input, amount })
			}
		}
		else {
			// alert(2)
			const denomination = [...denm];
			var value = event.target.value
			denomination[idx]['multiple'] = (denomination[idx].amount * event.target.value)
			denomination[idx]['count'] = event.target.value
			var amount = 0
			var changeValue = 0
			var currentValue = 0
			var els = document.getElementsByClassName('elem');

			for (var i = 0; i < els.length; i++) {
				var changeValue = document.getElementById('change' + i).value;
				var currentValue = document.getElementById('multiple' + idx).value;
				amount += denomination[i].amount * changeValue
				// denomination['count'] =changeValue
				// denomination['amount'] =currentValue
			}


			document.getElementById('amountww').value = amount
			document.getElementById('amount').value = amount
			setDenomination(denomination)
			setInputValues({ ...inputValues })
			setInput({ ...input, denomination })
			if(Number(input.amount) > 0){
			if (Number(denomination[idx]['multiple']) > Number(input.amount)) {
				swal("", "Total is greater than amount", "warning")
			}
		}
			if (event.target.name != "multiple") {


			}
			else {
				setInput({ ...input, amount })
			}
		}
	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		// if(validateForm())

		sendBagCollection()
	}
	//validations//

	function validateForm() {
		let errors = []
		let formIsValid = true
		if (!input.amount) {
			formIsValid = false;
			setDouble(true)
			errors["amount"] = "Enter Amount"
		}
		else if (Math.trunc(document.getElementById('amountww').value) != Math.trunc(input.amount)) {
			formIsValid = false;
			setDouble(true)
			errors["amount"] = "Not equal"
		}
		else {
			errors["amount"] = ""
		}

		setErrors(errors)

		return formIsValid;


	}
	//---Create EmployeeList---//
	function sendBagCollection() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

			body: JSON.stringify({

				note: input.note,
				amount: document.getElementById("amount").value,
				id: input.id,
				denomination,
				op_date: localStorage.getItem("op_date"),
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				closing_cash: 2
			})
		};
		fetch(API_URL + "/bag-collection", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["amount"] = responseData.response.amount
					// errors["opening_balance"] = responseData.response.opening_balance
					// errors["phone"] = responseData.response.phone
					// errors["whatsapp_no"] = responseData.response.whatsapp_no
					setDouble(true);
				}
				if (responseData.status == 9) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == 10) {
					setDouble(true)
					swal("", responseData.message)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/bag-collection")
					denomination.map(elem => {

						var multiple = elem.multiple = ""
						var count = elem.count = ""
						setDenomination({
							...denomination,

							multiple: multiple,
							count: count
						})
					})
					setDouble(true)
					setErrors([''])

					input.note = ""
					input.amount = ""
					input.id = ""

					setInput(input)
					setInputValues([])
					getSummerySheet()
					setDenomination(denomination)
					toggle();
					ListBagCollection(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}
	//---Edit employee list---//	
	function edit_BagCollection(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/bag-collection/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setInput(responseData.bag_collection)
				setDenomination(responseData.denomination)


				toggle();
				document.getElementById('amountww').value = responseData.bag_collection.amount
				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}
	//---Delete EmployeeList---//	
	function delete_BagCollection(id) {

		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}
	}
	function print_BagCollection(obj) {

		// deleteId(id)
		setPrint(obj)
		togglePrint()

	}

	function deleteBag() {

		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/bag-collection/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListBagCollection(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				swal("Something went wrong", "", "warning")
				console.log(err)
			})
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={10} />
									<div className="row">
										{Loading ? ListBagCollection :
											// <div class="loader text-primary">Loading...</div>
											<Loader />
										}
									</div>
									<div class="form-wrap ">

										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="bagcollection" role="tabpanel" aria-labelledby="bagcollection-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Bag Collection </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#bag" onClick={toggle} >Add Bag Collection </a>

														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
															</div>
														</div>
													</div>


													<div class="table-widget"  >
														<table class="table border">
															<thead>
																<tr>

																	<th>Description</th>
																	<th>Amount</th>
																	<th>Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(obj => (
																		<tr>

																			<td>{obj.note}</td>
																			<td>{obj.amount}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>


																			<td class="py-2"><a href="#" class="text-secondary" onClick={() => edit_BagCollection(obj.id)} ><i class="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																			{localStorage.getItem("save_hide") == 'false' ?
																			<a href="#" class="text-secondary" onClick={() => delete_BagCollection(obj.id)}><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																				:''}
																				<a href="#" class="text-secondary" onClick={() => print_BagCollection(obj)}>
																					<i class="fa fa-print" aria-hidden="true"></i>
																				</a>

																			</td>
																		</tr>
																	))}

																<tr>
																	<td colSpan={1} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={3}>
																		{total.totalamount}
																	</td>
																</tr>
															</tbody>
														</table>

														{Loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}

													</div>

												</div>

											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<BagModal
				isShowing={isShowing}
				hide={toggle}
				denm={denm}
				success={success}
				handleInput={handleInput}
				denomination={denomination}
				handleInputs={handleInputs}
				preventNonNumericalInput={preventNonNumericalInput}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				inputValues={inputValues}
				fields={fields}
				double={double}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				input={input}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}
			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				deleteBag={deleteBag}
				double={double}
			/>) : null
			}
			{isOpen ? (<PrintModal
				isOpen={isOpen}
				hide={togglePrint}
				print_details={print_details}
				shift={shift}


			/>) : null
			}
		</>
	)
}

export default BagCollectionConfig