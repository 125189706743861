import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput,prevNonNumericalWithDec} from "../../../layouts/Operational_Sidebar_Progress.component";

const DensityModal = ({ isShowing,handletank,errors,hide,inputDate,double,shows,handleDendity,handleIn,inputVal,inputNozzle,productData,Receipt,cummulative,handleDate,Test,input,inputFuel,Sales_sum,Loose,Packed,handleSubmit, handle,Others,fuelType,inputValues,handleValue,handleInputs,handleInput,data,drop,handleSelect,NozzleData,TankData,form_nozzle}) => 
{
  var today = new Date().toISOString().split('T')[0];

  return(
  <React.Fragment>
   
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">

        <div className="modal-header mb-3">
          <h5 className=" text-center" id="staticBackdropLabel">Density Report</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
            <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Date<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="date" className="form-control" name="date"  value={inputDate.date} onChange={handleInput} min={input.config_date} max={today} />
    <div className="errorMsg">{errors.date}</div>
  </div>
  </div>
                <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Product Name</label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="product_id"  value={inputNozzle.fuel_name} onChange={handleInputs} readOnly/>
    <input type="hidden" className="form-control highlight" name="product_id"  value={inputNozzle.product_id} onChange={handleInputs} readOnly/>
    
  </div>
  </div>
 
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Tank Name</label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="tank_id" value={inputValues.tank_name}  onChange={handleInputs}  readOnly />
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">HMV<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="number" className="form-control hide-arrow" name="hmv" placeholder="eg:655" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} onChange={handleInputs}/>
    <div className="errorMsg">{errors.hmv}</div>
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Temperature<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="number" className="form-control hide-arrow" name="temp"  placeholder="eg:5" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} onChange={handleInputs}/>
    <div className="errorMsg">{errors.temp}</div>
   <a href="#" class="link-text" onClick={handleDendity}>Find the Density</a> 

  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Density<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="density"  value={inputValues.density} readOnly />
    <div className="errorMsg">{errors.density}</div>
  </div>
  </div>
  {inputValues.density ?
  <div class="modal-footer text-center">

<a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>

{double==true?
<a class="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit} >SAVE</a>:<a class="btn btn-primary px-4 w-auto" type="submit" disabled >SAVE</a>}
</div>
:null}
                </div>
                </div>
                </div>
                  </div>
                  </div>
                
                 

    </React.Fragment>





)
}
export default DensityModal