import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PaginationComponent from '../../../layouts/PaginationComponent';
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import CashPaymentModal from './CashPaymentModal.component'
import Loader from '../Loader.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import DeleteModalConfig from './DeleteModalConfig.component'
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec,prevDec } from "../../../layouts/Operational_Sidebar_Progress.component";

import moment from 'moment';
const PaymentModal = (props) => ReactDOM.createPortal(
	<CashPaymentModal  {...props} />, document.body
);
const DeleteModal = (props) => ReactDOM.createPortal(
	<DeleteModalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const CashPaymentConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [input1, setInput1] = useState({});
	const [input2, setInput2] = useState({});
	const [fields, setFields] = useState(['']);
	const [input, setInput] = useState({});
	const [total, setTotal] = useState([])
	const history = useHistory()
	const [accountData, setDropdown] = useState([])
	const [denomination, setDenomination] = useState([])
	const [editamount, setEditAmount] = useState({});
	const [search, setSearch] = useState('')
	const [dropdownData, setDropdownData] = useState([])
	const [double, setDouble] = useState(true);
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [denm, setDenm] = useState([])
	const [summary, setSummary] = useState([])
	const [manager, setManager] = useState([]);
	const [outstanding, setOutstanding] = useState({});
	const [cashbalance, setCashbalance] = useState({});
	const [startDate, setDate] = useState([])
	const [selected_options, setSelectedOptions] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {
		check_url()

	}, []);
	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput1({ ...input1, op_date: responseData.op_date })
					getSummerySheet();
					ListCashPayment(pages.current_page);
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function getoutstanding(id) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_outstanding", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setOutstanding(responseData.outstanding)
				setCashbalance(responseData.cashbalance)

				var fromout = responseData.outstanding.before_approval;
				if (fromout == null) {
					fromout = 0;
				}
				var amount = input.amount;
				if (amount == null) {
					amount = 0;
				}
				var cfromout = responseData.cashbalance.before_approval;
				if (cfromout == null) {
					cfromout = 0;
				}
				var edt_amount = 0;
				if (editamount > 0) {
					edt_amount = editamount;
				}
				var outs = Number(fromout) + Number(amount) - Number(edt_amount);
				var cbal = Number(cfromout) - Number(amount) + Number(edt_amount);
				if (amount != 0) {
					document.getElementById("outs_before_approval").value = parseFloat(outs).toFixed(2);
					document.getElementById("cashb_before_approval").value = parseFloat(cbal).toFixed(2);
				} else {

					document.getElementById("outs_before_approval").value = '';
					document.getElementById("cashb_before_approval").value = '';
				}

			})
			.catch(e => {
				console.log(e);

			});
	}
	function getDenomination() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get-denomination", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				responseData.denomination.forEach((amount) => {
					setDenm((denm) => [
						...denm,
						{
							amount
						},
					]);
				});

			})
			.catch(e => {
				console.log(e);

			});
	}
	//---get account----//

	function getAccount() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				check_case: "PAYMENT_VOUCHER",
			}),
		};
		fetch(API_URL + "/common-get-ac-category", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				/*	var datas = []
	
					responseData.forEach(elem => {
	
						datas.push([elem.id, elem.accounts_head])
					})*/

				setDropdownData(responseData.group);
				//setDropdownData(datas);

			})
			.catch(e => {
				console.log(e);

			});
	}


	function ListCashPayment(page) {
		const requestOptions = {      //---cash listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/payment-voucher?search_keyword=${search}&page=${page}&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {


				setList(responseData.data.data)

				setDate(responseData.start_date)
				setInput2({ ...input2, isdayclose: responseData.isdayclose })
				setTotal({
					...total,
					totalamount: responseData.total_amount,
				})
				setManager({
					...manager,
					ismanager: responseData.Manager,
				})
				setLoading(true);


				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})

				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListCashPayment(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {

			getAccount();
			var isdayclose = input2.isdayclose;
			if (isdayclose == 1) {
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else {
				if (isShowing) {
					setInputValues({})
					setErrors({})
					setDenm([])
					setOutstanding({})
					setDouble(true)
					setSelectedOptions([])
					setCashbalance({})
					setEditAmount({})
					setInput({})
					setSuccess({})
					setFields([''])
					const denomi = [...denomination];
					if (denomi != "") {
						denomi.forEach((obj, index) => {
							obj.multiple = ""
							obj.count = ""
						})
						setDenomination([])
					}
					else {
						setDenomination([])
					}

				}
				if (!isShowing) {
					setDouble(true)
					setErrors({})
					getDenomination();
				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);

	const handleInputs = (e) => {

		convertAmountToWords(e, e.target.value)
		setInput({
			...input,
			[e.target.name]: e.target.value
		});

		if ((e.target.name == 'amount') && (e.target.value != 0)) {
			var fromout = outstanding.before_approval;
			if (fromout == null) {
				fromout = 0;
			}
			var edt_amount = 0;
			if (editamount > 0) {
				edt_amount = editamount;
			}
			var outs = Number(fromout) + Number(e.target.value) - Number(edt_amount)
			document.getElementById("outs_before_approval").value = parseFloat(outs).toFixed(2);

			var cfromout = cashbalance.before_approval;
			if (cfromout == null) {
				cfromout = 0;
			}
			var cbal = Number(cfromout) - Number(e.target.value) + Number(edt_amount)
			document.getElementById("cashb_before_approval").value = parseFloat(cbal).toFixed(2);
		}


	};

	const handleInput = (e) => {


		setInput({
			...input,
			[e.target.name]: e.target.value
		});



	};
	//---onchange functions---//
	function handleChange(event, idx) {
		const denomi = [...denomination];

		if (denomi[idx]) {
			var value = event.target.value
			denomi[idx]['multiple'] = (denomi[idx].amount * event.target.value)
			denomi[idx]['count'] = event.target.value
			var amount = 0
			var changeValue = 0
			var currentValue = 0
			var els = document.getElementsByClassName('elem');
			for (var i = 0; i < els.length; i++) {
				var changeValue = document.getElementById('change' + i).value;
				var currentValue = document.getElementById('multiple' + idx).value;
				amount += denomi[i].amount * changeValue
				// denomination['count'] =changeValue
				// denomination['amount'] =currentValue
			}


			document.getElementById('amountww').value = amount
			document.getElementById('amount').value = amount
			setDenomination(denomi)
			setInputValues({ ...inputValues })
			setInput({ ...input, denomination })
			var t = document.getElementById('amountww').value
			if(Number(input.amount) > 0){
			if (Number(t) > Number(input.amount)) {
				swal("", "Total is greater than amount", "warning")
			}
		}
			if (event.target.name == "multiple") {
				setInput({ ...input, amount })
			}
		}
		else {

			const denomination = [...denm];
			var value = event.target.value
			denomination[idx]['multiple'] = (denomination[idx].amount * event.target.value)
			denomination[idx]['count'] = event.target.value
			var amount = 0
			var changeValue = 0
			var currentValue = 0
			var els = document.getElementsByClassName('elem');

			for (var i = 0; i < els.length; i++) {
				var changeValue = document.getElementById('change' + i).value;
				var currentValue = document.getElementById('multiple' + idx).value;
				amount += denomination[i].amount * changeValue
				// denomination['count'] =changeValue
				// denomination['amount'] =currentValue
			}


			document.getElementById('amountww').value = amount
			document.getElementById('amount').value = amount
			setDenomination(denomination)
			setInputValues({ ...inputValues })
			setInput({ ...input, denomination })
			if(Number(input.amount) > 0){
			if (Number(denomination[idx]['multiple']) > Number(input.amount)) {
				swal("", "Total is greater than amount", "warning")
			}
		}
			if (event.target.name != "multiple") {


			}
			else {
				setInput({ ...input, amount })
			}
		}
	}

	const handleSelect = (items) => {
		console.log(items.value, "dgdfg")
		setInput({
			...input,
			ac_name_slug: items.value,
		});
		setSelectedOptions(items.value);


		getoutstanding(items.value);
	};
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();

		setDouble(false);
		sendCashPayment()
	}


	//---Create ---//
	function sendCashPayment() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				note: input.note,
				amount: document.getElementById("amount").value,
				id: input.id,
				ac_name_slug: input.ac_name_slug,
				denomination,
				voucher_no: input.voucher_no,
				reference_type: "cash_payment",
				op_date: localStorage.getItem("op_date"),
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				expense_voucher: 2,

			})
		};
		fetch(API_URL + "/payment-voucher", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				let errors = {}
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {
					errors["amount"] = responseData.response.amount
					errors["ac_name_slug"] = responseData.response.ac_name_slug
					errors["note"] = responseData.response.note
					setDouble(true)
				}
				if (responseData.status == 9) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)
				}
				if (responseData.status == 8) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/cashpayment")
					setErrors([''])
					setInputValues([])
					getSummerySheet()
					denomination.map(elem => {

						var multiple = elem.multiple = ""
						var count = elem.count = ""
						setDenomination({
							...denomination,

							multiple: multiple,
							count: count
						})
					})
					toggle();
					setDouble(true)
					ListCashPayment(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}
	//---Edit employee list---//	
	function edit_Cashpayment(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/payment-voucher/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInput(responseData.voucher_data)
				let product_names = []
				product_names.push({
					value: responseData.voucher_data.ac_name_slug,
					label: responseData.voucher_data.ac_name_slug_name,
				})
				setSelectedOptions(product_names)
				setDenomination(responseData.denomination)
				setOutstanding(responseData.outstanding)
				setCashbalance(responseData.cashbalance)


				setEditAmount(responseData.voucher_data.amount)
				toggle();
				document.getElementById('amountww').value = responseData.voucher_data.amount
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	//search
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	//---Delete EmployeeList---//	
	function delete_Cashpayment(id) {

		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}
	}

	function deleteCashPayment() {

		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/payment-voucher/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListCashPayment(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				console.log(err)
				swal("Something went wrong", "", "warning")
			})
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div className="container pb-3 tab-view">
							<div className="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={8} />
									<div className="row">
										{Loading ? ListCashPayment :
											// <div class="loader text-primary">Loading...</div>
											<Loader />
										}
									</div>
									<div className="form-wrap ">

										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade show active" id="cashpayment" role="tabpanel" aria-labelledby="cashpayment-tab">
												<div className="">
													<div className="row mb-3">
														<div className="col-md">
															<div className="row">

																<div className="col-md col pl-0">
																	<h3 className="table-title">Cash Payment </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>

														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Cash Payment</a>
														</div>
														<div className="col-md-4">
															<div className="input-group mb-3 search">
																<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search" value={search.search_keyword} onChange={handleSearch} />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
															</div>
														</div>
													</div>


													<div className="table-widget">
														<table className="table border">
															<thead>
																<tr>

																	<th>Account Name</th>
																	<th>Description</th>
																	<th >Amount</th>
																	<th>Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(obj => (
																		<tr>

																			<td>{obj.ac_name}</td>
																			<td>{obj.note}</td>
																			<td>{obj.amount}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>




																			<td className="py-2"><a href="#" className="text-secondary" onClick={() => edit_Cashpayment(obj.id)}><i className="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																			{localStorage.getItem("save_hide") == 'false' ?
																				<a href="#" className="text-secondary" onClick={() => delete_Cashpayment(obj.id)}><i className="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																			:''}
																				</td>
																		</tr>
																	))}

																<tr>
																	<td colSpan={3} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={3}> {total.totalamount}</td>
																</tr>

															</tbody>
														</table>

														{Loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}

													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<PaymentModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				inputValues={inputValues}
				handleSelect={handleSelect}
				fields={fields}
				input={input}
				double={double}
				manager={manager}
				handleInput={handleInput}
				outstanding={outstanding}
				cashbalance={cashbalance}
				dropdownData={dropdownData}
				startDate={startDate}
				denm={denm}
				selected_options={selected_options}
				preventNonNumericalInput={preventNonNumericalInput}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				prevDec={prevDec}
				accountData={accountData}
				success={success}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				denomination={denomination}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}


			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				deleteCashPayment={deleteCashPayment}
				double={double}
			/>) : null
			}

		</>
	)
}








export default CashPaymentConfig