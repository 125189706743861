import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Admin_Sidebar_Progress.component";
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../../Manager/ManagerLoginHeader.component'

import AdminSubscriptionModel from './AdminSubscriptionModel.component';

const SubscriptionModal = (props) => ReactDOM.createPortal(
	<AdminSubscriptionModel
      {...props} />, document.body
);
const AdminSubscriptionManagement= (props) => {

  const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({ vehicle_no: [''] });
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState({ vehicle_no: [''] })
	const [success, setSuccess] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		ListSubscription(pages.current_page);
	}, [search]);

	function ListSubscription(page) {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(`${API_URL}/admin-subscription-list?page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setList(responseData.data) 
				var total_pages = responseData.last_page

				var items = []
				for (let number = 1; number <= total_pages; number++) {
					number === page ? items.push(
						<li key={number} className="page-item active"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,) : items.push(
							<li key={number} className="page-item"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,)
				}

				setpages({
					...pages,
					current_page: responseData.current_page,
					last_page: responseData.last_page,
					total_pages: responseData.last_page,
					pagination_items: items
				})

				return responseData;
			})
			.catch(e => {


			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListSubscription(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues({ vehicle_no: [''] })
				setErrors({ vehicle_no: [''] })
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors({ vehicle_no: [''] })

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleInputs = (e) => {

			setInputValues({
				...inputValues,
				[e.target.name]: e.target.value
			});

	};
	//---onchange functions---//
	function handleChange(i, event) {
		const values = [...fields];
		values[i] = event.target.value;
		setFields(values);
		setInputValues({ ...inputValues, vehicle_no: values })
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();

		if (validateForm())
			sendSubData()
	}
	//---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (!inputValues.name) {
			formIsValid = false;
			errors["name"] = "Enter  Customer Name"
		}
		else {
			errors["name"] = ""
		}

		setErrors(errors)

		return formIsValid;
	}
	//---Create EmployeeList---//
	function sendSubData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify(inputValues)
		};
		fetch(API_URL + "/admin-subscription", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["opening_balance"] = responseData.response.opening_balance
					errors["phone"] = responseData.response.phone
					errors["whatsapp_no"] = responseData.response.whatsapp_no

				}
				if (responseData.status == true) {
					swal("", responseData.messsage, responseData.status ? "success" : "warning")
					history.push("/admin-subscription-management")
					setErrors([''])
					setInputValues([])
					toggle();
					ListSubscription(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}
	//---Edit employee list---//	
	function edit_subscription(id, ac_name) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,
				ac_name: ac_name

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/admin-subscription/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				let vehicledata = [];
				responseData.custom_vehicle.forEach((elem) => {

					vehicledata.push(elem.vehicle_no);

				})
				if (vehicledata.length < 1) {
					vehicledata = [''];
				}
				setFields(vehicledata);
				setInputValues(
					{
						...inputValues,
						id: responseData.id ? responseData.id : '',
					}
				);
				toggle();
				return responseData;
			})
			.catch(e => {


			});
	}


    return (
		<>
    <div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress  props={props} progress={21}/>
					<div className="content-area">
					<ManagerLoginHeader props={props}  page_title="" />
			 <div className="container pb-3">
       <div className="row mt-md-5 mt-3 form-container">
         
         <div className="form-wrap ">
                             
  <div className="tab-content" id="myTabContent">
<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
  <div className="">
    <div className="row mb-3">
      <div className="col-md">
        <div className="row">
         
          <div className="col-md col pl-0 col-12">
          <h3 className="table-title">Subscription Management</h3>
        </div>
        
        </div>
      </div>
      <div className="col-md-4">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} > Add Subscription Management </a>
						</div>
      <div className="col-md-4">
        <div className="input-group mb-3 search">
<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1"/>
</div>
      </div>
    </div>


    <div className="table-widget">
      	<table className="table border col-12">
			<thead>
				<tr>
					<th>Pump Name</th>
					<th>Subscription Status</th>
					<th>Subscription Expiry date</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
			{
			list_data.map(data => (   
				<tr>
					<td>{data.pump_name}</td>
					<td>{data.status ==1? <button class="btn btn-success">Active</button>:<button class="btn btn-danger">No Active</button>}</td>
					<td>{data.subscription_end_date}</td>
					<td>
						<div className="d-flex icon-btn">
							<button onClick={()=> edit_subscription(data.id)} >  <i className="fa fa-pencil" aria-hidden="true" ></i>
							</button>
     					</div>
				  	</td>
				</tr>
    		  ))}  
			</tbody>
		</table>
	</div>


</div>
</div>

</div>
</div>


         </div>
        
  </div>
</div>
</div>
</div>


			{isShowing ? (<SubscriptionModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fields={fields}
				success={success}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}
     
		</>
	)
}








export default AdminSubscriptionManagement