import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios'
import { Link } from "react-router-dom";
import Loader from '../operational-model/Employee/Loader.component'
const FuelConfig2 = () => {

  const [errors, setErrors] = useState([])
  const history = useHistory()
  const [inputValues, setInputValues] = useState([]);
  const [fuel_config, SetFuel] = useState([]);
  const [check_operation, SetSOperatrion] = useState([])
  const [fuel, SetNonFuel] = useState([])
  const [permission, setPermission] = useState([])
  const [double, setDouble] = useState(true);

  const [fuel_configs, SetFuelType] = useState([])
  useEffect(() => {
    check_url()

  }, []);
  //loader
  const [Loading, setLoading] = useState(false);
  const handleSubmit = (event, index) => {
    setDouble(false)
    event.preventDefault();

    if (validateForm())
      sendFuel(index)
    setLoading(true)

  }
  function validateForm() {
    let errors = []

    let formIsValid = true

    for (var key in (inputValues.fuel_type)) {
      try {
        if ((!inputValues.no_of_tank.hasOwnProperty(key))) {
          errors[key]["no_of_tank"] = "enter no of tank"
          formIsValid = false
          // send_fuel_configs.fuel_type[key]
        }
        else {
          if (inputValues.no_of_tank[key].length < 1) {
            errors[key]["no_of_tank"] = "enter no of tank"
            formIsValid = false
          }

          else {
            errors[key]["no_of_tank"] = ""
          }

        }
      }
      catch (Exception) {

      }
    }
    setErrors(errors)
    return formIsValid;
  }

  //---------------url(Athulya(12/06/2021))

  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {

        if (responseData.allowurl == 1) {
          history.push(responseData.url);
          getFuel();
          getReport();
        }
        else {

          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  //-----------------------end------------------

  function sendFuel(index) {
    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        fuel_type: inputValues.fuel_type,
        no_of_tank: inputValues.no_of_tank
      })

    };
    fetch(API_URL + "/fuel-config", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {

        fuel_config.map((fuelType, index) => {
          if (responseData.status === false) {
            setDouble(true)
            errors["fuel_type"] = responseData.response.fuel_type
            errors["no_of_tank"] = responseData.response.no_of_tank
            errors["no_of_tank"] = responseData.response.required

          }
        })

        if (responseData.status === false) {
          setDouble(true)
          errors["fuel_type"] = responseData.response.fuel_type
          errors["no_of_tank"] = responseData.response.no_of_tank
          errors["no_of_tank"] = responseData.response.required

        }

        if (responseData.status === true) {
          setDouble(true)
          swal("", responseData.message, responseData.status ? "success" : "danger")
          history.push("/tank-config");

        }
        setErrors(errors)



        return responseData;

      }).catch(e => {
        swal("Something went wrong", "", "warning")
        setDouble(true)
        console.log(e);
      });

  }
  const preventNonNumericalInput = (e) => {
    console.log("hfghf")
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/))
      e.preventDefault();
  }
  function toggleReadOnly(index) {

    let fuelConfigsData = fuel_config;

    if (fuelConfigsData[index].isReadonly == false) {
      fuelConfigsData[index].no_of_tank = ""
    }
    else {

      fuelConfigsData[index].no_of_tank = null
    }
    let fuelConfigData = fuelConfigsData[index];
    fuelConfigData.isReadonly = !fuelConfigData.isReadonly;
    fuelConfigData.isRequired = !fuelConfigData.isRequired;
    fuelConfigsData[index] = fuelConfigData;

    SetFuelType({ ...fuel_configs, fuelConfigsData });

    inputValues['no_of_tank'] = { ...inputValues['no_of_tank'], [index]: '' };
    setInputValues(inputValues)
  }
  function getFuel() {

    axios(
      {
        url: API_URL + '/fuel-config',
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        }
      }
    ).then(res => {


      if (res.data.status == 3) {
        setPermission({ ...permission, status: res.data.status, message: res.data.message })
      }

      let fuelConfigData = [];
      res.data.fuel_type.map(fuel_data => {
        fuelConfigData.push({
          fuel_name: fuel_data.fuel_name,
          id: fuel_data.id,
          no_of_tank: null,
          isReadonly: true,
          isRequired: false,
        });
      })
      SetFuel(fuelConfigData)
      SetSOperatrion({
        ...check_operation,
        stat_operation: res.data.stat_operation
      })


      setLoading(true)
      // var { fuel_configs, errors } = this.state

      // res.data.fuel_type.forEach(elem=>{

      // 	fuel_configs.push({
      // 		fuel_name: elem.fuel_name,
      // 		pump_id: elem.id
      // 		})

      // 	errors.push({
      // 		no_of_tank:'',
      // 		fuel_type:''
      // 	})
      // })

      // this.setState({fuel_configs:fuel_configs })
      // this.setState({errors:errors})	
    }).catch(error => {
      console.log(error)
    })
  }
  const handleInputs = (e, index, prop) => {

    if (e.target.name == "fuel_type") {
      if (e.target.checked) {
        if (inputValues[prop] === undefined) {
          //inputValues[prop] = [];
          inputValues[prop] = { ...inputValues[prop], [index]: '' };
        }
        //inputValues[prop][index] = e.target.value;
        inputValues[prop] = { ...inputValues[prop], [index]: e.target.value };
      }
      else {
        //  inputValues[prop].splice(index, 1);
        inputValues[prop] = { ...inputValues[prop], [index]: '' };
      }
    }
    else {
      inputValues[prop] = { ...inputValues[prop], [index]: e.target.value };
    }
    setInputValues(inputValues)

    //  setLoading(true)

  }

  const [fuelShow, SetfuelShow] = useState(false)
  //----------fuelconfig <listing-----/>

  function getReport(id) {

    axios(
      {
        url: API_URL + '/get-user-config',
        method: "get", data: { id: id },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        }
      }
    ).then(res => {
      let fuel = [];
      SetfuelShow(res.data.fule_status)

      res.data.fuel_config.forEach(elem => {
        fuel.push({

          fuel_name: elem.fuel.fuel_name,
          no_of_tank: elem.no_of_tank

        })
      })


      SetNonFuel(fuel)

      // setLoading(true)
      if (res.data.fuel_config == "") {

      }

    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress />
          <div className="content-area">
            <LoginHeader page_title="" />



            <div className="container pb-3">
              <div className="row justify-content-center mt-3 form-container">


                <div className="col-md-12 mt-2"><Link to="/pump-details">
                  <a href="/pump-details" class="float-end btn btn-gray" >Back</a></Link>
                </div>


                <div className="col-md-12">

                  <div className="row mb-3  mt-2 py-2">
                    <div className="form-wrap">


                      <h2 className="justify-content-center">Fuel Configuration</h2>
                     
                      {permission.status == 3 ?
                        <div className=" mt-5">
                          <div className="text-center">
                            {permission.message}
                          </div></div> :
                        <>

                          <div>
                            {Loading ? getFuel : <Loader />
                              // <div class="loader text-primary">Loading...</div>

                            }
                            <div className="form-content pb-2 pt-3">
                              <div className="col-md-12">
                                {fuelShow == false ? '' :



                                  <div className="table-widget">
                                    <table className="table border">
                                      <thead>
                                        <tr>
                                          <th>Fuel Name</th>
                                          <th>Number of tank</th>

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {fuel.map((obj) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{obj.fuel_name}  </td>
                                                <td>{obj.no_of_tank}</td>

                                              </tr>
                                            </>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>


                                }



                              </div>

                            </div>


                                
                            <div className="row form-content check-frm mt-2">
                            <label >Please select fuel products<sup class="star">*</sup></label>
                            <br></br>
                            <br></br>

                              {
                                fuel_config.map((fuelType, index) => {

                                  return (
                                    <div className="col-md-3" key={`fuel_config${index}`}>
                                      <div className="form-ceck check-wrap " >
                                        <label className="form-check-label me-4 fw-bold" htmlFor="flexCheckDefault">{fuelType.fuel_name}      </label>
                                        <input className="form-check-input" type="checkbox" value={fuelType.id} name="fuel_type" onChange={event => { handleInputs(event, index, event.target.name) }} onClick={() => { toggleReadOnly(index) }} />



                                      </div>
                                      <div className="input-group mb-3" >
                                        <label className="input-group-text" id="basic-addon1">Number of tank</label>
                                        <input type="number" className={`form-control check${fuelType.id}`} onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} placeholder="" aria-label="no_of_tank" min={1} name="no_of_tank" onChange={event => { handleInputs(event, index, event.target.name) }} readOnly={fuelType.isReadonly} value={fuelType.no_of_tank} required={fuelType.isRequired} />

                                        {/* <div className="errorMsg">{errors[index].no_of_tank}</div> */}
                                      </div>
                                    </div>
                                  )
                                })
                              }
                              <div class="col-md-12">

                                <div className="errorMsg">{errors.fuel_type}</div>
                                <div className="errorMsg">{errors.no_of_tank}</div>
                              </div>

                            </div>





                          </div>
                        </>}
                    </div>
                    <div className="text-end mt-4">


                      {(double == true) ? (check_operation.stat_operation == "Yes") ?
                        <a className="btn btn-primary w-auto" href="/tank-config" >NEXT</a> :
                        <a className="btn btn-primary w-auto" onClick={handleSubmit} >SAVE AND CONTINUE</a> :
                        (check_operation.stat_operation == "Yes") ?
                          <a className="btn btn-primary w-auto" disabled href="/tank-config" >NEXT</a> :
                          <a className="btn btn-primary w-auto" disabled onClick={handleSubmit} >SAVE AND CONTINUE</a>
                      }
                      {(double == true) ? (fuelShow != false && check_operation.stat_operation != "Yes") ? <a className="btn btn-primary mx-3 w-auto" href="/tank-config" >SKIP</a> : '' : (fuelShow != false && check_operation.stat_operation != "Yes") ? <a className="btn btn-primary mx-3 w-auto" disabled href="/tank-config" >SKIP</a> : ''}
                    </div>

                  </div>



                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default FuelConfig2