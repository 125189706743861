import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const DensityEdit = ({ isShowing,errors,hide,handleSubmitedit,handleDendity,inputDate,handleIn,inputNozzle,productData,Receipt,cummulative,handleDate,Test,input,inputFuel,Sales_sum,Loose,Packed,handleSubmit, handle,Others,fuelType,inputValues,handleValue,handleInputs,handleInput,data,drop,handleSelect,NozzleData,TankData,form_nozzle}) => (
  <React.Fragment>
  
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">

        <div className="modal-header mb-3">
          <h5 className=" text-center" id="staticBackdropLabel">Density Report</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
            <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Date<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="date" className="form-control" name="date"  value={inputDate.date} onChange={handleInput}  min={input.config_date} max={input.next_date} />
    
  </div>
  </div>
                <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Product Name</label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="fuel_name" value={inputNozzle.fuel_name}  onChange={handleInputs}/>
    
  </div>
  </div>
 
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Tank Name</label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="tank_name"  value={inputNozzle.tank_name} onChange={handleInputs}  />
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">HMV<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="hmv" value={inputValues.hmv}  onChange={handleInputs}/>
    
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Temperture<sub className="star">*</sub></label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="temp" value={inputValues.temp}  onChange={handleIn}/>
    <a href="#" class="link-text" onClick={handleDendity}>Find the Density</a>
  </div>
  </div>
  <div className="row g-2 align-items-center   form-group ">

<div className="col-5">
    <label className="col-form-label">Density</label>
  </div>
  <div className="col-md-5">
    <input type="text" className="form-control highlight" name="density" value={inputValues.density} onChange={handleInputs}  />
    
  </div>
  </div>
  <div class="modal-footer text-center">

<a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
<a class="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmitedit} >SAVE</a>
</div>
                </div>
                </div>
                </div>
                  </div>
                  </div>
                
                 

    </React.Fragment>





)
export default DensityEdit