
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg'


export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

const BalanceSheet1 = () => {


	const [list_data, setList] = useState([])
  const [input, setInput] = useState({});
     useEffect(() => {
    
      check_url();
      
   },[]) 

   function check_url() {
    const requestOptions = {      
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })
  
      .then(responseData => {
        console.log(responseData)
        if(responseData.allowurl == 1)
        {
          
     setInput({...input, stat_operation:responseData.stat_operation , configuration_report:responseData.configuration_report})
          history.push(responseData.url);
          getBalanceSheet();
        }
        else{
          
          history.goBack();
        }
  
        return responseData;
      })
      .catch(e => {
      });
  
  }
   async function getBalanceSheet()
   {
    setAll([])
       const requestOptions = {
       method: 'post',
       headers: {
         "Access-Control-Allow-Origin": "*",
         "Content-Type":"application/json",
         "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
         "Accept": "application/json"
     
        },
   };
    await fetch(API_URL+"/balance-sheet",requestOptions)
           .then(response => { return response.json();})
   .then(responseData => {
   responseData.all_entries.map((elem)=>
   {
    setAll(all=>[
      ...all,
      
   {
     ac_head:elem.title,
     spr_ac_head:elem.stitle,
     sub_head:elem.accounts_head,
     drtamount:elem.drtamount,
     crtamount:elem.crtamount
     
   }
 ])
   })
 
   setState(responseData)

     })
      .catch(e => {   
           });
   }
   function sendmailltoowner()
   {
     sendPDF();
     sendPDFConfiguration();

   }
   function sendPDF() {  
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
      },
      body:JSON.stringify({mail_data:list_data}),
    };
        fetch(API_URL+"/send_pdf_file_bal2",requestOptions)
            .then(response => { return response.json();})
        .then(responseData => {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          getBalanceSheet();
          check_url()
        return responseData;
          })
            .catch(e => {
              console.log(e);  
            });
  }
  function sendPDFConfiguration() {  
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
      },
      body:JSON.stringify({mail_data:list_data}),
    };
        fetch(API_URL+"/send_pdf_file_bal2_configuration",requestOptions)
            .then(response => { return response.json();})
        .then(responseData => {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          getBalanceSheet();
          check_url()
          
        return responseData;
          })
            .catch(e => {
              console.log(e);  
            });
  }


  function printDocument() {  

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
      }, 
      body: JSON.stringify({
        op_date:localStorage.getItem("op_date")
      })
    };
        fetch(API_URL+"/download_pdf_file_bal2",requestOptions)
            .then(response => { return response.blob();})
        .then(responseData => {
          var blob = new Blob([responseData]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "BalanceSheet.pdf";
          link.click();
         
          getBalanceSheet();
        
          check_url()
        return responseData;
          })
            .catch(e => {
              console.log(e);  
            });
  
    }
    function confprintDocument() {

      const requestOptions = {
        method: 'post',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type":"application/json",
          "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
          "Accept": "application/json"
        }, 
        body: JSON.stringify({
          op_date:localStorage.getItem("op_date")
        })
      };
          fetch(API_URL+"/download_configuration_report_pdf_file",requestOptions)
              .then(response => { return response.blob();})
          .then(responseData => {
            var blob = new Blob([responseData]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "ConfigurationReport.pdf";
            link.click();
  
            getBalanceSheet();
            check_url()
            
            // window.location.reload();
          
          return responseData;
            })
              .catch(e => {
                console.log(e);  
              });
    
      }
    
     const history = useHistory()
     const [state, setState] = useState([])
     const [all, setAll] = useState([])
     var buffer = '';var flag =0;
     var s_buffer = '';var s_flag =0;
     var profit_loss_amount =0; var org_amount =0; var profit_loss =''; var dr_total =0; var cr_total =0;
    return(
        <>
<div className="main-container page-wrap">
<div className="d-flex">
<Sidebar_Progress progress={9}/>
<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">
                                  <div className="row justify-content-center mt-md-5 mt-3 form-container">
                                    <div className="col-md-12 ">
                                      <div className="form-wrap balace-sheetwrap">
                                        <div className="row mb-3">
                                          <div className="col-md ">
                                          <h2 className="table-title  mb-1">{state.pump_name}</h2>
                                          <h3 className="table-title mb-1">{state.pump_address}</h3>
                                            <h4 className="table-title mb-1">Balance Sheet</h4>
                                            <h5>{state.config_date}</h5>
                                             
                                          </div>
                                         
                                          </div>
                                 
                                            <div className="sheet-wrp">
                                            <div className="row">
                                              <div className="col-md-6">
                                                {/* <h5 className="mb-0">ASSETS</h5> */}
                                               

                                            <table className="table table-bordered">
                                                <tr>
                                                   <th><h6 className="mb-0">ASSETS</h6></th>
                                                    <th className="text-end"><h6>Dr</h6></th>
                                                  </tr>

                                                 {all.map((account)=>{

                                                      if(Number(account.drtamount) > Number(account.crtamount))
                                                      {
                                                        var Dramt = account.drtamount - account.crtamount;
                                                        var Cramt = '';
                                                        Dramt = parseFloat(Dramt).toFixed(2);
                                                     
                                                        dr_total = Number(Dramt)+Number(dr_total);
                                                        dr_total = parseFloat(dr_total).toFixed(2);
                                                      }
                                                      
                                                     if(buffer == account.ac_head)
                                                        {flag = 1;}
                                                      else
                                                        {flag = 0;}
                                                      if(account.ac_head != null && Dramt > 0)
                                                        {
                                                          buffer = account.ac_head;
                                                        }  

                                                      if(s_buffer == account.spr_ac_head)
                                                        {s_flag = 1;}
                                                      else
                                                        {s_flag = 0;}
                                                      if(account.spr_ac_head != null && Dramt > 0)
                                                        {
                                                          s_buffer = account.spr_ac_head;
                                                        }     
                                                      return (
                                                    <>
                                                      {s_flag== 0 &&    
                                                        <>
                                                       {account.spr_ac_head ?
                                                      <tr><th colspan="2">{account.spr_ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                      {Dramt > 0 && flag== 0 &&   
                                                        <>
                                                       {account.ac_head ?
                                                      <tr><th colspan="2">{account.ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                      {account.sub_head  && Dramt >0?
                                                      <tr>
                                                        <td>{account.sub_head}</td>
                                                        <td className="text-end">{Dramt != '' && Dramt > 0  ?numberFormat(Dramt):''}</td>
                                                      </tr>: ''}
                                                    </>
                                                  )})}
                                                </table>
                                              
                                              </div>
                                              <div className="col-md-6">
                                                {/* <h5 className="mb-0">LIABILITIES</h5> */}
                                               
                                                        <table className="table table-bordered">
                                                        <tr>
                                                   <th><h6 className="mb-0">LIABILITIES</h6></th>
                                                    <th className="text-end"><h6>Cr</h6></th>
                                                  </tr>
                                                
                                                {all.map((account)=>{
                                                      if(Number(account.crtamount) > Number(account.drtamount))
                                                      {
                                                      
                                                        var Cramt = account.crtamount - account.drtamount;
                                                        Cramt = parseFloat(Cramt).toFixed(2);
                                                        cr_total = Number(Cramt) + Number(cr_total);
                                                        cr_total = parseFloat(cr_total).toFixed(2);
                                                      }
                                                      if(buffer == account.ac_head)
                                                        {flag = 1;}
                                                      else
                                                        {flag = 0;}
                                                      if(account.ac_head != null && Cramt > 0)
                                                        {
                                                          buffer = account.ac_head;
                                                        }  
                                                      if(s_buffer == account.spr_ac_head)
                                                        {s_flag = 1;}
                                                      else
                                                        {s_flag = 0;}
                                                      if(account.spr_ac_head != null && Cramt > 0)
                                                        {
                                                          s_buffer = account.spr_ac_head;
                                                        }      
                                                        
                                                      return (
                                                    <>
                                                       {s_flag== 0 &&    
                                                        <>
                                                       {account.spr_ac_head ?
                                                      <tr><th colspan="2">{account.spr_ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                      { Cramt >0 &&  flag== 0 &&    
                                                        <>
                                                       {account.ac_head ?
                                                      <tr><th colspan="2">{account.ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                      {account.sub_head && Cramt >0?
                                                      <tr>
                                                        <td>{account.sub_head}</td>
                                                        <td className="text-end">{Cramt != '' && Cramt > 0  ?numberFormat(Cramt):''}</td>
                                                      </tr>: ''}
                                                    </>
                                                  )})}
                                               
                                                </table>
                                              
                                              </div>
                                              </div>
                                              <span className="sno">
                                                    <>
                                                    {Number(dr_total) == Number(cr_total) ? 
                                                      (org_amount = parseFloat(dr_total).toFixed(2))
                                                    :''}

                                                      {Number(dr_total) > Number(cr_total)? 
                                                        (profit_loss_amount = parseFloat(dr_total - cr_total).toFixed(2),
                                                        profit_loss = 'profit',
                                                        org_amount = parseFloat(dr_total).toFixed(2)
                                                        )
                                                    :''}

                                                    {Number(cr_total) > Number(dr_total) ? 
                                                        (profit_loss_amount = parseFloat(cr_total - dr_total).toFixed(2),
                                                        profit_loss = 'loss',
                                                        org_amount = parseFloat(cr_total).toFixed(2)
                                                        )
                                                    :''}
                                                    </>   
                                                 </span>
                                       <div className="row">
                                            
                                        <div className="col-md-6 ">                              
                                          <table className="table table-bordered ">
                                            {profit_loss === "loss"   &&
                                            <tr>
                                            <th>Loss</th>
                                            <th className="text-end">{numberFormat(profit_loss_amount)}/-</th>
                                            </tr>
                                            }
                                          </table>
                                        </div>
                                        <div className="col-md-6">
                                        <table className="table table-bordered ">
                                             <tr>
                                             {profit_loss === "profit"   &&
                                                <>
                                               <th>Owners Fund</th>
                                               <th className="text-end">{numberFormat(profit_loss_amount)}/-</th>
                                                </>    }
                                             </tr>
                                           </table>
                                         </div>   
                                        </div>
                                        
                                            <div className="row">
                                         <div className="col-md-6">
                                            <table className="table table-bordered ">
                                              <tr>
                                                <th>Total</th>
                                                <th className="text-end">{numberFormat(org_amount)}/-</th>
                                              </tr>
                                            </table>
                                          </div>
                                            
                                          <div className="col-md-6">
                                            <table className="table table-bordered ">
                                             <tr>
                                               <th>Total</th>
                                               <th className="text-end">{numberFormat(org_amount)}/-</th>
                                             </tr>
                                            </table>
                                           </div>
                                           </div>
                                          
                                        </div>
                                        <div className="col-md">
                                              <div className="text-end mt-4"><a onClick={printDocument} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a> 
                                            <a className="btn btn-success px-3 mb-md-0 mb-3  me-3" onClick={sendPDF} >Send Mail</a>
                                            {input.stat_operation == "Yes" && input.configuration_report == "Yes" ?'': <a onClick={confprintDocument} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Configuration Report </a>}
                                           {input.stat_operation == "Yes" && input.configuration_report == "Yes" ? <a onClick={sendmailltoowner} href='operational-date' className="btn btn-primary px-3 mb-md-0 mb-2">Go to Operation</a>:''}
                                          <div>
                                          {input.stat_operation == "Yes" && input.configuration_report == "Yes" ? '' : <p className="note" style={{ marginTop: "25px" }}>Note: Click all three options to proceed to operations.</p>}
                                          {<p className="note" style={{ marginTop: "25px" }}>Note: You cant edit fuel stock after start operation.</p>} 
                                          </div>
                                          
                                            </div> 
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


</div>
</div>
</div>
</>
    )
}
    
export default BalanceSheet1