

import React from 'react';
const  TaskModalConfig = ({ prevNonNumericalWithDec,isShowing,double, hide,success, errors, inputValues,isDisabled,input,handleSelect,handleClick,handleChange, handleSubmit, handleInputs }) => {

    return (
    <React.Fragment>
     <div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Task and Reminders</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row justify-content-center  mt-3 form-container">
      
              {/* <div class="form-wrap ">	 */}
  {/* <div class="row mb-3 justify-content-center py-4"> */}
      <label class="col-sm-4 col-form-label mb-2">Task/Reminder <sup class="star">*</sup></label>
      <div class="col-sm-7">
        <input type="text" class="form-control mb-2" placeholder="" name="task_name" value={inputValues.task_name} readOnly={input.ob_is_dayclose} onChange={handleInputs}/>
        <div className="errorMsg">{errors.task_name}</div>
      </div>
       <label class="col-sm-4 col-form-label mb-2">Description<sup class="star">*</sup></label>
      <div class="col-sm-7">
        <input type="text" class="form-control mb-2" placeholder="" name="description" value={inputValues.description} readOnly={input.ob_is_dayclose}  onChange={handleInputs} />
        <div className="errorMsg">{errors.description}</div>
      </div>
      <label class="col-sm-4 col-form-label mb-2">Last Action Date<sup class="star">*</sup></label>
      <div class="col-sm-7">
        <input type="date" class="form-control mb-2" name="last_action" value={input.last_action } readOnly={input.ob_is_dayclose}   onChange={handleSelect}/>
        <div className="errorMsg">{errors.last_action}</div>
      </div>
      <label class="col-sm-4 col-form-label mb-2">Repeat Frequency <sup class="star">*</sup></label>
      <div class="col-sm-7">
        <div class="select-container mb-2"> 
        <select  class="form-control" name="frequency" value={input.frequency}  onChange={handleSelect} disabled={input.ob_is_dayclose} >
            <option value="">Select</option>
            <option value="monthly">Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="half_yearly">Half Yearly</option>
            <option value="annual">Annual</option>
            <option value="no_of_days">No of days</option>
       </select>
   </div>
   <div className="errorMsg">{errors.frequency}</div>
      </div>
  { input.frequency == "no_of_days" ?
  <>
      <label class="col-sm-4 col-form-label mb-2">Frequency Unit<sup class="star">*</sup></label>
      <div class="col-sm-7">
        <input type="number" class="form-control mb-2" placeholder="" min={0} onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="frequency_unit" readOnly={input.ob_is_dayclose}  value={input.frequency_unit}  onChange={handleSelect}/>
        <div className="errorMsg">{errors.frequency_unit}</div>
      </div></> :input.frequency_unit="" }
      <label class="col-sm-4 col-form-label mb-2">Next Action Date</label>
      <div class="col-sm-7">
        <input type="text" class="form-control mb-2" placeholder="" name="next_action" readOnly={true} value={inputValues.next_action}  onChange={handleInputs}/>
       
      </div>
      <label class="col-sm-4 col-form-label mb-2">Remind Before<sup class="star">*</sup></label>
      <div class="col-sm-7">
        <input type="number" class="form-control mb-2" min={0} onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} placeholder="Number of days" name="remind_before" readOnly={input.ob_is_dayclose}  value={inputValues.remind_before}  onChange={handleInputs}/>
        <div className="errorMsg">{errors.remind_before}</div>
      </div>
      <label class="col-sm-4 col-form-label mb-2">Stop After<sup class="star">*</sup></label>
      <div class="col-sm-7">
       <div class="select-container mb-2">
           <select class="form-control" name="stop_after" value={inputValues.stop_after} readOnly={input.ob_is_dayclose}  onChange={handleInputs}>
               <option value="">Select</option>
               <option value="continuous" >Continuous</option>
               <option value="no_of_time">No of Times</option>
               </select>
       </div>
       <div className="errorMsg">{errors.stop_after}</div>
      </div>
      {inputValues.stop_after =="no_of_time" &&
      <>
      <label class="col-sm-4 col-form-label mb-2">Stop After Days<sup class="star">*</sup></label>
      <div class="col-sm-7">
        <input type="number" class="form-control mb-2" placeholder="" min={0} onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="stop_after_unit" value={inputValues.stop_after_unit} readOnly={input.ob_is_dayclose}  onChange={handleInputs} />
        <div className="errorMsg">{errors.stop_after_unit}</div>
      </div>
     
      </>}
    </div>
    <div class="modal-footer ">
              {double===true?<a class="btn btn-primary w-auto px-4" type="submit" onClick={handleSubmit}>Save  </a>:<a class="btn btn-primary w-auto px-4" type="submit" disabled>Save  </a>}
          </div>
          {/* </div> */}
      </div>
      </div>		
        
          {/* </div> */}
          </React.Fragment>          );
        }
               export default TaskModalConfig;