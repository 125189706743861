import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput,prevNonNumericalWithDec} from '../layouts/Sidebar_Progress.component'

const CustomerConfigModal = ({ isShowing,handleIn, hide, setVehicle,double, errors,success,handleName, inputValues,handleAdd,handleDelete,handleRemove,handleChange ,fields,dropdownData, handleSubmit, handleInputs,operational,setInputValues}) => {
 
  return (
  <React.Fragment>


<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modalm">
        <div className="modal-header">
    {console.log(inputValues,"input")}      
{inputValues["id"] == undefined?(
  
  
        <h5 class="modal-title" id="exampleModalLabel">ADD NEW CUSTOMER</h5>
):(<h5 class="modal-title" id="exampleModalLabel">Edit Customer Details  ({inputValues["ac_date"]})</h5>)
}

          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
            
        
          </button>
        </div>
        
        <div className="form-group">
          <label>Name<sup class="star">*</sup></label>
<input type="text" className="form-control " name="name" value={inputValues["name"]}  onChange={handleName}  />
<div className="errorMsg">{errors.name}</div>
</div>
<div className="form-group">
  <label>Address</label>
  <input type="text" className="form-control" name="address" value={inputValues["address"]} onChange={ handleInputs} />
  </div>
  <div className="form-group">
    <label>Phone</label>
    <input type="text" className="form-control hide-arrow ph_no"  onKeyPress={(event)=>prevNonNumericalWithDec(event,inputValues["phone"])}  id={"phone"} name="phone" value={inputValues["phone"]} onChange={handleInputs} />
    <div className="errorMsg">{errors.phone}</div>
    </div>
    <div className="form-group">
      <label>Email</label>
      <input type="text" className="form-control" name="email" value={inputValues["email"]} onChange={handleInputs} />
      <div className="errorMsg">{errors.email}</div>
      </div>
      <div className="form-group">
        <label>Whatsapp No.</label>
        <input type="text" className="form-control hide-arrow ph_no"  onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} id={"whatspp"} name="whatsapp_no" value={inputValues["whatsapp_no"]} onChange={handleInputs}/>
        <div className="errorMsg">{errors.whatsapp_no}</div>
        </div>
        <div class="form-group ">
    <div class="row">
    <div class="col-md-10">
    <label>Vehicle Number <sup class="star">*</sup></label>
   
    {fields.map((inpuvalue, idx) => {

     
        return (
          <>
          <div class="row">
          <div class="col-md-10 mt-3">
    <input type="text" class="form-control mb-2 " name="vehicle_no" value={inpuvalue} 
    onChange={e => handleChange( idx, e)}  onBlur={()=>setVehicle()} />
    </div>
    <div class="col-md-2 mt-2">
    {idx!==0 ?
    <button class="btn-add mt-2  me-2" onClick={(e) => handleDelete(idx)}>x</button>:''}
    </div>
    </div>
    </>
    
    
);
  })} 
  <div className="errorMsg">{errors.vehicle_no}</div>
    </div>
   
    <div class="col-md-2 mt-5 d-flex">
    <button class="btn-add mt-2  me-2" onClick={() => handleAdd()}>+</button>
    
    <button class="btn-add mt-2 mt-2"  onClick={() => handleRemove()}>-
    </button>

    </div>
    </div>
</div>
{operational.status != "Yes" ?
<div className="row">
<div className="col-md-6 ">
 <div className="form-group">
                    <label>Opening Balance<sup class="star">*</sup></label>
                    <input type="number" className="form-control hide-arrow"  onKeyPress={(event)=>preventNonNumericalInput(event,inputValues["opening_balance"])}  id={"open"} name="opening_balance" value={inputValues["opening_balance"]} onChange={ handleIn} readOnly={inputValues["ob_is_dayclose"]}/>
                    <span id="words" class="amount-words"></span>
                    <div className="errorMsg">{errors.opening_balance}</div>
                    <div className="errorMsg">{errors.debit_credit}</div>
                    
                  </div>
                  
                </div>
               
              
                <div className="col-md-4 d-flex">
               
                  <div className="form-check col-sm mt-4">
                 
                   {inputValues.ob_is_dayclose == true ? <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Need to pay the party'  name="debit_credit"  value="Cr"   onClick={ handleInputs} checked={inputValues.debit_credit === 'Cr'} disabled />:
                   <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Need to pay the party'  name="debit_credit"  value="Cr"   onClick={ handleInputs} checked={inputValues.debit_credit === 'Cr'}  />}
                   <label className="form-check-label col-form-label" for="exampleRadios1">   Credit (+) 
                    </label>
                    <ReactTooltip />

                  </div>
                 
                  <div className="form-check col-sm mt-4">
              
                  {inputValues.ob_is_dayclose == true ? <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Party to pay us' name="debit_credit" value="Dr" onClick={ handleInputs} disabled checked={inputValues.debit_credit === 'Dr'} />:
                   <input className="form-check-input" type="radio"  id="exampleRadios1" data-tip='Party to pay us' name="debit_credit" value="Dr" onClick={ handleInputs} checked={inputValues.debit_credit === 'Dr'} />}
                   <label className="form-check-label col-form-label" for="exampleRadios1">  Debit (-)
                    </label>
                    <ReactTooltip />
                  </div>
                  
                </div>
                </div>
:""}

        <div className="row mt-3">
          
        <div className="col-md-5">
              <div className="form-group row mx-0">
                <div className="form-check col-sm-12 pr-0">
                  {inputValues.credit_amount ===1 && inputValues.credit_limit !=null ? (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked
                      name="credit_amount"
                      onClick={handleInputs}
                    />
                  ) : (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="credit_amount"
                        onClick={handleInputs}
                      />
                    )}
                  <label
                    className="form-check-label col-form-label"
                    for="exampleRadios1"
                  >
                    {" "}
                    Credit Limit Amount{" "}
                  </label>
                </div>

                {inputValues.credit_amount === 1 ? (
                  
                  <div className="col-sm-12 px-0">
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,inputValues["credit_limit"])}
                      className="form-control hide-arrow"
                      placeholder=""
                      name="credit_limit"
                      value={inputValues["credit_limit"]}
                      onChange={handleInputs}
                    />
                  {(inputValues.credit_amount ==1 && !inputValues.credit_limit) ? <div className="errorMsg">{errors.credit_limit}</div>:errors.credit_limit=""}
                  <span id="words" class="amount-words"></span>
                  </div>
                ):inputValues.credit_limit=""}

              </div>

            </div>
            <div className="col-md-7">
              <div className="form-group row mx-0 ">
                <div className="form-check col-sm-12 pr-0">
                  {inputValues.credit_period ===1 && inputValues.credit_limit_period !=null? (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked
                      name="credit_period"
                      onClick={handleInputs}
                    />
                  ) : (
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="credit_period"
                        onClick={handleInputs}
                      />
                    )}
                  <label
                    className="form-check-label col-form-label"
                    for="exampleRadios1"
                  >
                    {" "}
                    Credit Period
                  </label>
                </div>
                {inputValues.credit_period == 1 ? (
                  <div className="col-sm-8 px-0 ">
                    <div className="select-container">
                      <select
                        id="Reliance"
                        className="form-control"
                        name="credit_limit_period"
                        value={inputValues["credit_limit_period"]}
                        onChange={handleInputs}
                      >
                        <option value="select">Select</option>

                        {inputValues.credit_limit_period === "no_of_days" ? (
                          <option value="no_of_days" selected>
                            {" "}
                            Number of days
                          </option>
                        ) : (
                            <option value="no_of_days"> Number of days</option>
                          )}

                        <option value="one_time">One time</option>
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                        <option value="half_of_month">Half of month</option>
                      </select>
                    </div>
                    {(inputValues.credit_period ==1 && !inputValues.credit_limit_period) ? <div className="errorMsg">{errors.credit_limit_period}</div>:errors.credit_limit_period=""}                  </div>
                ) :inputValues.credit_limit_period =""}
                <div className="col-sm-4">
                  {inputValues.credit_limit_period == "no_of_days" ? (
                    <div className="form-group">
                      <input
                        type="text"
                        onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
                        className="form-control"
                        placeholder="Days"
                        name="credit_days"
                        min={1}
                        max={100}
                        value={inputValues["credit_days"]}
                        onChange={handleInputs}
                      />
                    </div>
                  ) : inputValues.credit_days=""}
          {(inputValues.credit_limit_period=="no_of_days" && !inputValues.credit_days)?<div className="errorMsg">{errors.credit_days}</div>:errors.credit_days=""}
                </div>
              </div>
            </div>      
              
                

                
               
                <div class="modal-footer">
              {double===true ?<button type="submit" class="btn btn-primary"  onClick={handleSubmit}>Save</button> : <button type="submit" class="btn btn-primary"  disabled>Save</button>}
                </div>
             
              </div>

      </div>
    </div>
</React.Fragment>
  );
}
       export default CustomerConfigModal;