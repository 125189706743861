
import React from 'react';
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from 'react-dom';
const OtherincomeModal = ({ isShowing, hide,success,double, errors, inputValues,isDisabled, handleSubmit, handleInputs }) => {

  return (
    <React.Fragment>  
     <div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Other Income Configuration</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
       
          <div className="row mb-6 justify-content-center py-4">
            <label className="col-sm-4 col-form-label mb-2">Other Income Subhead <sup class="star">*</sup></label>
              <div className="col-sm-6 d-flex">
                  <input type="text" className="form-control mb-2"  name="income_head" placeholder=""  value={inputValues.income_head} onChange={handleInputs} readOnly={inputValues.ob_is_dayclose}/>
              </div>
              <label className="col-sm-4"></label>
              <div className="col-sm-6 errorMsg"> {errors.income_head} </div>
          </div>
          

  <div className="modal-footer ">
  {double==true ?  <button className="btn btn-primary w-auto px-4" type="button" onClick={handleSubmit}>Save  Other Income Configuration</button> :  <button className="btn btn-primary w-auto px-4"  type="button" disabled>Save  Other Income Configuration</button> }
 
  </div>
  </div>
         
          </div>
    </React.Fragment>
          );
        }
               export default OtherincomeModal;