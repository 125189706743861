
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import React, { useState } from "react";
import museum from  '../../img/museum.svg'
import ReactDOM from 'react-dom';
import OtherincomeModal from './OtherIncomeModal'
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect,useMemo} from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import PaginationComponent from '../layouts/PaginationComponent';

var IncomeModal = (props) => ReactDOM.createPortal(
	<OtherincomeModal  {...props} />, document.body
		 );
     const  PaginationComp  = (props) => (
      < PaginationComponent  {...props} />
         );
const OtherIncome = (location) => {
  const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);
  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);
   
    function toggle() {
      var isdayclose = checkoption.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
      if(isShowing) 
			{
			   setInputValues([])
			   setErrors({})
         setSuccess({})
		   }
       if(!isShowing)
       {
        setDouble(true)
       }
      setIsShowing(!isShowing);
    }
    }
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])
  const handleInputs = (e) => {
    setInputValues({
       ...inputValues,
       [e.target.name]: e.target.value,
       
     });
   };
 
   function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.income_head){
      formIsValid = false;
      setDouble(true)
      errors["income_head"] = "Enter income head"
    }
    
    setErrors(errors)
    
     return formIsValid;

  }



   const handleSubmit = (event ) =>
   {
     event.preventDefault();
      setDouble(false)
      if(validateForm()){
     sendOtherIncome()
      }
     
   }
  
 const [search,setSearch]=useState('')
 const  handleSearch = (event) =>
 {
  setSearch(event.target.value)
 }


 useEffect(()=>{
check_url()
setLocation(location.location.pathname)	
 },[]); 
 const [path, setLocation] = useState([])
//------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
        if(location.location.pathname == "/other-income/other-income")
				{
					history.push(location.location.pathname);
				}
				else
				{
				history.push(responseData.url);
				}
			  ListOtherIncome(pages.current_page);
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
function check_meta_date() {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
//--------------------------//
 const [pages,setpages]=useState(
 {
 current_page:1,
 last_page:0,
 total_pages:0,
 pagination_item:[],
 total_pages:0,
})

const handlePagination = (number) =>
   {
       setpages({
         ...pages,
         current_page:number
       }
           )
 
           ListOtherIncome(number)
 
   }
   async function sendOtherIncome()
   {
 
    
           const requestOptions = {
           method: 'POST',
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
            },
              body: JSON.stringify(inputValues)
       };
       await fetch(API_URL+"/income",requestOptions)
               .then(response => 
                 { 
          
                    return response.json();})
       .then(responseData => {
      setSuccess({...success,
           disabled : responseData.status === true?true:false,
      })
          let errors = []
          errors["income_head"] = responseData?.message
          if(responseData.status == false)
          {
            if (responseData?.response?.income_head)
            {
          errors["income_head"] = responseData?.response?.income_head
            }
          setErrors(errors)
          }
          else{
         swal("",responseData.message,responseData.status?"success":"warning")
         toggle();
          }
        
        //  setDidabled({
        //    isDisabled:true
        //  })
       
       
          ListOtherIncome(pages.current_page);
          // setInputValues("")
          setDouble(true)
          return responseData;
         })  .catch(e => {
                   swal("Something went wrong","","warning")
                   setDouble(true)
                   console.log(e);     
               });
   }

const [permission, setPermission] = useState([])
const [checkoption, ckeckOption] = useState([])
   //loader
const [Loading,setLoading]=useState(false);

  function ListOtherIncome(page)
   {
           const requestOptions = {
           method: 'get',
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
         
            },
       };
      
        fetch(`${API_URL}/income?search_keyword=${search}&page=${page}` ,requestOptions)
               .then(response => { return response.json();})
       .then(responseData => {

        if(responseData.status==3) 
        {
          setPermission({...permission,status:responseData.status,message:responseData.message})
        }

      setList(responseData.data.data) 
      ckeckOption({...checkoption,
        isdayclose:responseData.isdayclose})

         
            setpages({
              ...pages,
              current_page: responseData.data.current_page,
              last_page: responseData.data.last_page,
              per_pages:  responseData.data.per_page,
              total_pages:responseData.data.total,
               
              })


        setLoading(true)
  return responseData;
         })
               .catch(e => {
                   console.log(e);
                  
               });
   }
   function delete_income(id)
  {
    
    deleteId(id)
   
    toggleModal()
  }
  function delete_income(id)
  {
    var isdayclose = checkoption.isdayclose;
    if(isdayclose == 1)
    {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else
    {
  
    deleteId(id)
   
    toggleModal()
  }
}
  function deleteIncome()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/income/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListOtherIncome(pages.current_page);
           toggleModal()
          setDouble(true)
       return responseData;
     
   
         })
   
     .catch(err=>{
       console.log(err)
       swal("Something went wrong","","warning")
            
         })
  }
      async function edit_income(id)
     {
  
           const requestOptions = {
           method: 'get', 
           data: {id:id},     
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
         
            },  
       };
        await fetch(API_URL+"/income/"+id+"/edit",requestOptions)
               .then(response => { return response.json();})
       .then(responseData => {
    
        // setId(responseData) 
        
        // console.log(payment_id)
        setInputValues(
         {
           income_head : responseData.data.accounts_head,
   
           id:responseData.data.id ? responseData.data.id : '',
           ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,
         }
       );
            toggle();
     
     return responseData;
         })
               .catch(e => {
                   console.log(e);
                  
               });
     }
   const [inputValues,setInputValues] = useState({});
  const {isShowing, toggle} = UseModal();
  const [list_data,setList] = useState([]) 
  const [success,setSuccess ] = useState([]) 
  const [errors, setErrors] = useState([ ])
  const [isDisabled,setDidabled ] = useState([false]) 
  const history = useHistory()
    return(
        <>

          <div className="main-container page-wrap">
<div className="d-md-flex">
{path == "/other-income/other-income"? <Sidebar_Progress path={path} />:<Sidebar_Progress />}

<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">
<div class="row">

 <div className="col-md-12 mb-3 px-4 mt-2">{path == "/other-income/other-income"?<Link to={backPage || "/operational-date"}>
        <a href="#" className="float-end btn btn-gray">Go Back</a>
      </Link>:<Link to="/tanker-expense">
        <a href="tanker-expense" className="float-end btn btn-gray">Back</a>
      </Link>}

      {/* </Link>:<Link to="/assets">
        <a href="assets" className="float-end btn btn-gray">Back</a>
      </Link>} */}
      </div>
 </div>
	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
    {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
                      {Loading ? ListOtherIncome : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col ps-0">
							<h3 className="table-title mt-2">Other Income Configuration</h3>

						</div>
						<div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 "  data-bs-target="#bank" onClick={toggle}>Add Other Income</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Other Income "  value={search.search_keyword} onChange={handleSearch}    />
	<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
</div>
					</div>
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Other Income Subhead</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {
list_data.map(income => (    
     <tr>
      <td>{income.accounts_head}</td>
  
     
      <td><div className="d-flex icon-btn">
      <button onClick={()=> edit_income(income.id)} >  <i className="fa fa-pencil" aria-hidden="true" ></i>
</button>
      <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_income(income.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
    </tr>
     
      ))}  
</tbody>
</table>
{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
				</div>
			</div>
      {path == "/other-income/other-income"?"":  <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/expenses")}>skip/Continue</a></div>
}</div>
    </>}
	</div>
  </div>
</div>
{isShowing ? (<IncomeModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        inputValues={inputValues}
        double={double}
	    	// isDisabled={isDisabled}
        // dropdownData ={dropdownData}
        success={success}
        handleSubmit={handleSubmit}
		   // handleChange={handleChange}
		// handleAdd={handleAdd}
		// handleRemove ={handleRemove}
         errors={errors}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">OTHER INCOME</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Other income</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
					{double == true ?<button type="submit" class="btn btn-primary"  onClick={ deleteIncome}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={ deleteIncome}>Yes</button>}
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>

</div>
</div>
</div> 

</>
    )
}
    
export default OtherIncome