import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput} from "../../../layouts/Operational_Sidebar_Progress.component"
const FuelSellPriceEditChangeModal = ({ isOpen, edit_tank_config,handleClick,handleEditPopup,handleEditPopupChange,handleEditClick,handleEditPopupNozzleChange,hide,edit_fields,edit_update_data,errors,handleEdiTankData }) => {
    return (
        <React.Fragment>

          
                 
<div className="modal-overlay" />
				<div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
					<div className="modal modal-dialog-xl ">
						<div className="modal-header mb-3">
							<h5 className="modal-title" id="staticBackdropLabel">{edit_fields.fuel_name}</h5>
							<button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row align-items-center">
								<div className="col-12">
								</div>
							</div>
                         
							<div className="row mb-2 align-items-center">
								<div className="col-6">
									<label>New Price</label>
								</div>
								<div className="col-6">
									<input type="number" className="form-control hide-arrow" id="new_price_change" name="new_price" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  value={edit_fields.new_price} onChange={(event) => handleEditPopup(event, event.target.name)} />
								</div>
							</div>
                            {edit_tank_config.map((tank, idx) => {
								return (
                                    <>
										<div className="row mb-2 align-items-center">
											<div className="col-6">
												<label>{tank.tank_name} (Stock)</label>
											</div>
											<div className="col-6">
												<input type="text" className="form-control " id={"stock" + idx} readOnly value={tank.tank_reading?tank.tank_reading.current_stock:''} name={`current_stock`} onChange={(event) => handleEdiTankData(event, idx, event.target.name)} />
											</div>
										</div>
										{tank.nozzles.map((nozzle, index) => {
										
											return (
												
												<div className="row mb-2 align-items-center">
													<div className="col-2"></div>
													<div className="col-4">
														<label >{nozzle.nozzle_name} (Reading)</label>
													</div>
													<div className="col-6">
														<input readOnly  type="text" className="form-control" id={"opening" + index} value={nozzle.nozzle_reading?nozzle.nozzle_reading.opening_reading : ''} name={`nozzle_opening_reading`} onChange={(event) => handleEditPopupNozzleChange(event, idx,index, event.target.name)} />
													</div>
												</div>
												)
										})}
										
									</>
								)
							})}

						</div>
						<div className="modal-footer text-center">
							<a className="btn btn-primary px-4" type="submit" onClick={event => { handleEditClick(event, edit_fields.product_id) }} >Save</a>
						</div>
					</div>
				</div>


       </React.Fragment>

    );
}
export default FuelSellPriceEditChangeModal