import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const CardSalesModal = ({ preventNonNumericalInput,prevDec,prevNonNumericalWithDec,isShowing, hide, double,setVehicle, errors, success, inputValues, dropdownData,payment,handleIn ,handleSubmit, handleInputs }) => {

    return (
        <React.Fragment>
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modal-dialog-xl modalxl">

                    <div class="modal-header mb-3">
                        <h5 class=" text-center" id="staticBackdropLabel">Card Sales/Digital Payment</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">


                        <div class="">

                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label font-weight-bolder" >Payment Type System<sub class="star">*</sub></label>
                                </div>
                                <div class="col-6">
                                    <div class="select-container">
                                        <select id="" class="form-control highlight"name="payment_method_id" value={inputValues.payment_method_id} onChange={handleInputs}>
                                            <option>Select</option>
                                            {
                                                payment.map((data) => {
                                                    return <option value={data[0]}>{data[1]}</option>
                                                })}
                                        </select>
                                        <div className="errorMsg">{errors.payment_method_id}</div>

                                    </div>
                                </div>

                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label font-weight-bolder">Description </label>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control highlight" name="note" value={inputValues.note} onChange={handleInputs} />
                                </div>

                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label font-weight-bolder">Card Number  </label>
                                </div>
                                <div class="col-6">
                                    <input type="text"  onKeyPress={(event)=>prevDec(event,event.target.value)}  class="form-control highlight hide-arrow" name="card_no" value={inputValues.card_no} onChange={handleInputs}/>
                                </div>

                            </div>
                           

                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label font-weight-bolder">Amount <sub class="star">*</sub>  </label>
                                </div>
                                <div class="col-6">
                                    <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control highlight hide-arrow" name="amount" value={inputValues.amount} onChange={handleIn}/>
                                    <span  class="amount-words"></span>
                                    <div className="errorMsg">{errors.amount}</div>
                                   
                                </div>

                            </div>
                            {localStorage.getItem("save_hide") == 'false' ?
                            <div class="modal-footer text-center">

                                <a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
                                {double===true ?    <a class="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit} >SAVE</a>:  <a class="btn btn-primary px-4 w-auto" type="submit"  disabled >SAVE</a>}
                            </div>
                            :''}
                        </div>





                    </div>
                </div>
            </div>



        </React.Fragment>

    );
}
export default CardSalesModal