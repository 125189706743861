import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const ManagerApprovalConfig = ({ isApprove,hide,handleYes,approval_status,aprove }) => {
    return (
        <React.Fragment>
            <div className="modal-overlay" />
            <div className="modal-wrapper" >
                <div className="modal modal-dialog">
                    <div className="modal-content">
                        <div className="modal-title modal-header "><button type="button" onClick={hide} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
                        <div className=" modal-body  modal-body"><p>Do you want to {approval_status == 1 ? 'Approve' : 'Reject'} ?</p></div>
                        {aprove ==1 ?<div className=" modal-body  modal-body"><p>The edit is not possible After approval </p></div>:''}
                        <div className="justify-content-end  modal-footer"><div className="d-flex">
                            <button type="button" className="btn btn-primary me-2 px-4" onClick={handleYes}>Yes</button>
                            <button type="submit" className="btn btn-secondary" onClick={hide}>No</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
       </React.Fragment>

    );
}
export default ManagerApprovalConfig