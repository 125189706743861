import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import { Link,useLocation } from "react-router-dom";
import {preventNonNumericalInput,prevNonNumericalWithDec} from "../../../layouts/Operational_Sidebar_Progress.component";

const CreditSalesModal = ({ isShowing, hide, double,setVehicle, errors, success,manager, handleIn, inputValues, new_price, handleAdd, handleRemove, handleChange, handleSelects, handleVehicleInputs, fields, dropdownData, dropdown, dropdowns, handleSubmit, handleInputs, handleInput, handleSelect }) => {
    const location_details = useLocation()
    return (
        <React.Fragment>

            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modal-dialog-xl modalxl">

                    <div className="modal-header mb-3">
                        <h5 className=" text-center" id="staticBackdropLabel">Credit sales edit</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="">
                            <div className="row g-3 justify-content-center  mb-2">
                                <div className="col-6">
                                    <label className="col-form-label">Customer Name</label><sub className="star">*</sub>

                                </div>
                                <div className="col-6">
                                    <div className="select-container">
                                        <select id="" class="form-control " name="customer_id" value={inputValues.customer_id} onChange={handleSelect} >
                                            <option value=''>Select</option>
                                            <option value={0}>New Customer</option>
                                            {dropdownData.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }

                                        </select>
                                        <div className="errorMsg">{errors.customer_id}</div>
                                    </div>

                                </div>

                            </div>

                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label">Description</label>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " name="note" value={inputValues.note} onChange={handleInputs} />
                                </div>
                            </div>
                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label">Intent Number</label>
                                </div>
                                <div className="col-6">
                                    <input type="text"  onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} className="form-control  hide-arrow" name="intent_no" value={inputValues.intent_no} onChange={handleInputs} />
                                </div>

                            </div>

                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label">Vehicle Number<sub className="star">*</sub></label>
                                </div>
                                <div className="col-6">
                                    <div className="select-container">
                                        <select id="" className="form-control " name="vehicle_id" value={inputValues.vehicle_id} onChange={handleVehicleInputs}>

                                            <option value=''>Select</option>
                                            <option value={0}>New Vehicle no</option>
                                            {

                                                dropdown.map((data) => {


                                                    return <option value={data.id}>{data.vehicle_no}</option>
                                                })
                                            }
                                        </select>
                                        <div className="errorMsg">{errors.vehicle_id}</div>
                                    </div>
                                    {manager.ismanager == 1? 
                                     <Link to={location => ({ ...location, fields:{ fields: true }, backpage: location_details.pathname, pathname: "/customers/credit-sales" })} class="link-text"><a href="/customers">Redirect to customers configuration</a></Link>
                                     : '' } 
                                    
                                </div>
                             </div>
                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label">Product Name <sub className="star">*</sub></label>
                                </div>
                                <div className="col-6">
                                    <div className="select-container">
                                        <select id="" className="form-control " name="product_id" value={inputValues.product_id} onChange={handleSelects}>
                                            <option value="">Select</option>
                                            {dropdowns.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                    <div className="errorMsg">{errors.product_id}</div>
                                </div>

                            </div>
                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label font-weight-bolder">Quantity <sub className="star">*</sub> </label>
                                </div>
                                <div className="col-6">
                                    <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  className="form-control hide-arrow" name="quantity" value={inputValues.quantity} onChange={handleInput} />
                                    <div className="errorMsg">{errors.quantity}</div>
                                </div>

                            </div>
                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label font-weight-bolder">Price(Default)  </label>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control " name="price" value={inputValues.price} onChange={handleInputs} readOnly />
                                </div>

                            </div>
                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <label className="col-form-label font-weight-bolder">Amount
                                    <sub className="star">*</sub> </label>
                                </div>
                                <div className="col-6">
                                    <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className="form-control  hide-arrow" name="amount" value={inputValues.amount} onChange={handleIn} />
                                    <div className="errorMsg">{errors.amount}</div>
                                    <span id="words" class="amount-words"></span>
                                </div>
                            </div>
                            
                            {localStorage.getItem("save_hide") == 'false' ?
                            <div className="modal-footer text-center">

                                <a className="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                                {double===true ?    <a className="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit}>SAVE</a>:  <a className="btn btn-primary px-4 w-auto" type="submit" disabled>SAVE</a>}
                            </div>
                            :''}
                            
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>

    );
}
export default CreditSalesModal