import React, { useEffect} from 'react'
import { Redirect, Route } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from './constant/API_Settings';




const PrivateRoute = ({ component: Component, ...rest }) => {
  document.addEventListener("wheel", function(event){
		if(document.activeElement.type === "number"){
			document.activeElement.blur();
		}
	});
  // Add your own authentication on the below line.
 try
  {
    var checkcookies = 'test';
    localStorage.setItem(checkcookies, checkcookies);
    localStorage.removeItem(checkcookies);
    var token = localStorage.getItem("AUTH_TOKEN");
  }
 catch(e)
 {
   var token = '';
 }

 
  const isLoggedIn = token ? true : false;



    //     if(!role_permissions)
    //     {
    //       axios({
    //    url: API_URL+"/get_role_permissions",
    //    method: "post",
    //    headers: {
    //             "Access-Control-Allow-Origin": "*",
    //             "Content-Type":"application/json",
    //             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
    //             "Accept": "application/json"
    //         } 
    //    }
    //    ).then(res=>{
    
    //  sessionStorage.setItem("ACCESS_PERMISSIONS",res.data)
     
    //    })
          
    //     }
 

        
       
       

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  )

  
}



export default PrivateRoute;