import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const TankEditDelete = ({ preventNonNumericalInput,isClose,hide,tankData,double,stockData,errors,handleAdd,handleRemove,fields,handleMovedQuantity,HandleDeleteSubmit,handleSaleQuantity,handleLossQuantity,handlePrice,handleLossPrice,handleSaleAmount,handleTank,TankName,quantity,inputValues,handleStockInput,handleDeleteQuantity}) => {

    return (
  
        <React.Fragment>
       <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modalm ">

                    <div class="modal-header mb-3">
                        <h5 class=" text-center" id="staticBackdropLabel">Delete Tank</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    <div class="row g-3 justify-content-center mb-2">
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Tank Name</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control highlight" readonly name="tank_name" value={TankName.tank_name} />
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Stock in Lt<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " name="stock" value={stockData} readOnly/>
                         <div className="errorMsg">{errors.stock}</div>
                     </div>
                     <div class="col-6">
                        <label  class="col-form-label font-weight-bolder">Physical Quantity in Lt<sub class="star">*</sub></label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow " name="physical_quantity" value={quantity.physical_quantity} onChange={handleDeleteQuantity} />
                         <input type="hidden"  class="form-control " name="actual_physical_quantity" value={inputValues.actual_physical_quantity} />
                         <div className="errorMsg">{errors.physical_quantity}</div>
                     </div>
                      
                  <div class="row justify-content-end g-2">
                     <div class="col-6">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"  name="stock_movement" onChange={handleStockInput}/>
                    <label class="form-check-label" for="flexCheckDefault">
                     Stock Movement
                    </label>
                     </div>
                  </div>
                <div className="col-6">
                <div class="text-end ">
                        <button class="btn-add mt-2 pe-3" onClick={() => handleAdd()}>+</button>
                        <button class="btn-add mt-2  "  onClick={(event) => handleRemove( event)} >-</button>
                      </div>
                      </div>
                  <div class="row addfield-wrp justify-content-end g-2">
                                     
                  {inputValues.stock_movement ==1 ?'':
                  <>
                  <div class="col-4">
                         <label  class="col-form-label font-weight-bolder mt-3">Moved Quantity in Lt.</label>
                      </div>
                      <div class="col-6">
                          <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow mt-3"  readOnly name="moved_quantity"  />
                      </div>
                      <div class="col-4">
                         <label  class="col-form-label font-weight-bolder ">Moved Tank</label>
                      </div>
                      <div class="col-6">
                         <div class="select-container" >
                            <select id="" class="form-control " name="moved_tank_id"  >
                                <option disabled>Select</option>
                            </select>
                         </div>
                         </div></>}
                     
                      { fields.map((inpuvalue, idx) => {
                            return ( 
                                <> 
                      {inputValues.stock_movement ==1 ?
                  <>
                         <div class="col-4">
                         <label  class="col-form-label font-weight-bolder mt-3">Moved Quantity in Lt</label>
                      </div>
                      <div class="col-6">
                          <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow mt-3" id={"moved_quantity"+idx} name="moved_quantity"  onChange={(event)=>handleMovedQuantity(idx,event,event.target.name)}/>

                      </div>
                      <div class="col-4">
                         <label  class="col-form-label font-weight-bolder ">Moved Tank</label>
                      </div>
                      <div class="col-6">
                         <div class="select-container" >
                            <select id="" class="form-control" name="moved_tank_id"  onChange={(e)=>handleTank(idx,e,e.target.name)} >
                                <option>Select</option>
                            {
                       tankData.map((data) => {
                          return <option value={data[0]}>{data[1]}</option>
                        })
                      }
                            </select>
                     <div className="errorMsg">{errors.moved_tank_id}</div>

                         </div>
                       
                      </div>
                     
                  </>:''} </>)})}
                   </div>
               
                 
                  </div>

                   <div class="row justify-content-end g-2">
                     <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox"  name="cash_sale" onClick={handleStockInput} />
                    <label class="form-check-label" for="flexCheckDefault">
                     Cash Sales
                    </label>
                     </div>
                  </div>
                  {
                         inputValues.cash_sale == 1 ?'':
                         <>
                   <div class="col-4">
                        <label  class="col-form-label font-weight-bolder"> Quantity in Lt.</label>
                     </div>
                     <div class="col-6">
                         <input type="number"  class="form-control " readOnly/>
                     </div>
                     <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Price</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " readOnly/>
                     </div>
                      <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Amount</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " readOnly/>
                     </div>
                     </>
                  }
                              {
                         inputValues.cash_sale == 1 ?
                         <>
                   <div class="col-4">
                        <label  class="col-form-label font-weight-bolder"> Quantity in Lt.</label>
                     </div>
                     <div class="col-6">
                         <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow" id={"sale_quantity"} name="sale_quantity" value={inputValues.sale_quantity} onChange={handleSaleQuantity}/>
                     </div>
                     <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Price</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow" name="sale_price" value={inputValues.sale_price} onChange={handlePrice}/>
                     </div>
                      <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Amount</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow" name="sale_amount" id={"sale_amount"} value={inputValues.sale_amount} onChange={handleSaleAmount}/>
                     </div></>:''
                     
                  }
                  </div>
                  <div class="row justify-content-end g-2">
                     <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" name="loss" onClick={handleStockInput} />
                    <label class="form-check-label" for="flexCheckDefault">
                    Loss
                    </label>
                     </div>
                  </div>
                  {
                         inputValues.loss == 1 ?'':
                         <>
                   <div class="col-4">
                        <label  class="col-form-label font-weight-bolder"> Quantity in Lt.</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  class="form-control hide-arrow" readOnly />
                     </div>
                     <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Price</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " readOnly />
                     </div>
                      <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Amount</label>
                     </div>
                     <div class="col-6">
                         <input type="text"  class="form-control " readOnly/>
                     </div>
                     </>
}
                 
                 {inputValues.loss == 1 ?
                         <>
                   <div class="col-4">
                        <label  class="col-form-label font-weight-bolder"> Quantity in Lt.</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  class="form-control hide-arrow " id={"loss_quantity"} name="loss_quantity" value={inputValues.loss_quantity} onChange={handleLossQuantity} />
                     </div>
                     <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Price</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  class="form-control hide-arrow" name="loss_price"  value={inputValues.loss_price} onChange={handleLossPrice}/>
                     </div>
                      <div class="col-4">
                        <label  class="col-form-label font-weight-bolder">Amount</label>
                     </div>
                     <div class="col-6">
                         <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow"  name="loss_amount" value={inputValues.loss_amount} onChange={handleSaleAmount}/>
                     </div>
                     </>:''
}
                  </div>
 
               </div>
                        <div className="modal-footer text-center">

                            <a className="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                            {double==true?<a className="btn btn-primary px-4 w-auto" type="submit" onClick={HandleDeleteSubmit}>SAVE</a>:<a className="btn btn-primary px-4 w-auto" type="submit" disabled>SAVE</a>}
                        </div>
                    </div>
                </div>
            </div>


       </React.Fragment>

    );
}
export default TankEditDelete