import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const DealershipModal = ({ isShowing, list, success, hide,double,preventNonNumericalInput, prevNonNumericalWithDec,errors, inputValues, list_data, dropdownData, handleSubmit, handleInputs,handleInput,stat_operation }) => {
 
  return (
    <React.Fragment>
     
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modalm">
          <div className="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Dealership Configuration</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control " name="name" value={list_data.dealer_data ? list_data.dealer_data.dealer_name : ""} onChange={handleInputs} readOnly />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input type="text" className="form-control" name="address" value={inputValues["address"]} onChange={handleInputs} />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input type="text" className="form-control hide-arrow ph_no" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="phone"id={"phone"} value={inputValues["phone"]} onChange={handleInputs} />
            <div className="errorMsg">{errors.phone}</div>
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="text" className="form-control" name="email" value={inputValues["email"]} onChange={handleInputs} />
            <div className="errorMsg">{errors.email}</div>
          </div>

          <div className="form-group">
            <label>Product Name</label>
            <input type="text" className="form-control" name="product_name" value={list.fuel_name} onChange={handleInputs} readOnly />
          </div>
          <div className="form-group">
            <label>Supply Terminal</label>
            <input type="text" className="form-control" name="supply_terminal" value={inputValues["supply_terminal"]} onChange={handleInputs} />
          </div>
          <div className="form-group">
            <label>Lead Time To Supply</label>
            <input type="number" min={0} max={100} className="form-control" onKeyPress={(event)=>prevNonNumericalWithDec(event,inputValues["lead_time"])} name="lead_time" value={inputValues["lead_time"]} onChange={handleInputs} />
            <div className="errorMsg">{errors.lead_time}</div>
          </div>
          <p className="note">Note: Lead time to supply is a time taken to deliver fuel once order is placed.</p>
          <div className="row">
          {stat_operation != "Yes" ?
            <div className="col-md-8 ">
              <div className="form-group">
              <label>Opening Balance<sup class="star">*</sup></label>
                <input type="text" className="form-control hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,inputValues["opening_balance"])}  name="opening_balance" id={"open"} value={inputValues["opening_balance"]}  readOnly={inputValues["ob_is_dayclose"]} onChange={handleInput} />
                <div className="errorMsg">{errors.opening_balance}</div>
                <div className="errorMsg">{errors.debit_credit}</div>
                <span  class="amount-words"></span>
              </div>
             
            </div>
:""}
            <div className="col-md-4 d-flex">
            {stat_operation != "Yes" ?
          
              <div className="form-check col-sm pr-0">
                
         
                <input className="form-check-input" type="radio" data-tip='Need to pay the party' id="exampleRadios1" name="debit_credit" value="Cr" onClick={handleInputs} checked={inputValues.debit_credit === 'Cr'} disabled={inputValues["ob_is_dayclose"]} />
                <label className="form-check-label col-form-label" for="exampleRadios1">   Credit
</label>

                <ReactTooltip />
              </div>
              :""}
              {stat_operation != "Yes" ?
              <div className="form-check col-sm pr-0">
             
                
                <input className="form-check-input" type="radio" data-tip='Party to pay us' id="exampleRadios1" name="debit_credit" value="Dr" onClick={handleInputs} checked={inputValues.debit_credit === 'Dr'}  disabled={inputValues["ob_is_dayclose"]}/>
                
                <label className="form-check-label col-form-label" for="exampleRadios1">  Debit
</label>
                <ReactTooltip />
              </div>
              
            
            :""}
            </div>
          



            <div class="modal-footer">

              {double === true ? <button type="submit" class="btn btn-primary w-auto"  onClick={handleSubmit}>SAVE DEALERSHIP CONFIGURATION</button> : <button type="submit" class="btn btn-primary w-auto"disabled>SAVE DEALERSHIP CONFIGURATION</button>}
            </div>

          </div>

        </div>
      </div>
    </React.Fragment>
  );
}
export default DealershipModal;