import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip' 
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';

const DesignConfigModal = ({ preventNonNumericalInput,handleTimePickerClick,handleClose,handleBlur,endDate,handleFocus,handleEndDateChange,handleInput,setTimePickerClicked,mulerror,handleAmount,handleInput1,total_diff,double,isShowing1,Values, hide, errors,success,open_reading, inputValues,handleAdd,handleRemove,handleChange_nozzle_id,fields,dropdownData,nozzledropdownData,handleSubmit1, handleInputs }) => {
  console.log(mulerror,"mulerrormulerror")
  let total_amount =0;
  let total_quantity=0;
  return (
  <React.Fragment>
 

<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxlnozzle">
       
        <div class="modal-header mb-3">
        <h5 class="modal-title" id="staticBackdropLabel">Nozzle Closing</h5>
        <input 
    type="text" 
    value={Values.length}
    readOnly
    disabled
    style={{ border: 'none', fontSize: '35px', padding: '13px', width: '100px', fontWeight: 'bold', color: 'black' }}
    // style={{  fontSize: '20px', padding: '13px', width:" 50px" }}
    // style={{ border: 'none', fontSize: '20px', padding: '13px', width: '50px' }}
/>
 
        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
      </div>

                                                           

           
     
          <div class="modal-body" style={{width: "auto"}}>
          <table class="table border">
           <thead>
																<tr>
																	<th>Nozzle Name</th>  
                                  <th className="col-2">Start Date<sup class="star">*</sup></th>	                   
																	<th style={{display:"none"}} className="col-2">Start Time<sup class="star">*</sup></th>
                                  <th className="col-2">Start Time<sup class="star">*</sup></th>
                                  <th className="col-2">Closing Date<sup class="star">*</sup></th>	
																	<th className="col-2">End Time<sup class="star">*</sup></th>
                                  <th className="col-2">Working Hours<sup class="star">*</sup></th>	
                                  <th>Opening Reading</th>																
																	<th>Closing Reading<sup class="star">*</sup></th>
																	<th >Quantity in Lt.<sup class="star">*</sup></th>
																	<th>Price</th>
																	<th>Amount<sup class="star">*</sup></th> 
																</tr>
            </thead>
          
            {/* {console.log(obj,"njlllljn")} */}

            
          {Values.map((obj, idx) => {
            console.log("Length of Values array:", Values.length);
            console.log(obj,"njjn")
           
            // function calculateTotalAmount() {
            //   let totalAmount = 0;
            //   Values.forEach(obj => {
            //     totalAmount += parseFloat(obj.amount);
            //   });
            //   return totalAmount.toFixed(2); // Adjust the decimal places as needed
            // }
         
            
      

return (
  <>

															
                                  <tbody style={{width:"100px"}}>
                                
                                      <tr>
                                          <td>{obj.nozzle_name}</td>
                                          <td><input style={{width: "108px"}} type="date" class="form-control" name="startDate"   value={obj.op_date} readOnly /></td>
                                          <td style={{display:"none",width:" 75px"}} className="time-picker-cell-in"> 
                                                                <TimePicker
                                                                    showSecond={false} 
                                                                    readOnly
                                                                    disabled
                                                                    style={{ color: "black" }}
                                                                    value={obj.start_from}
                                                                    clearIcon ={null}
                                                                    name="start_from"
                                                                    onChange={handleInput}
                                                                    
                                                               //  onChange={(event) => handleInput1(idx, event)}
                                                                   use12Hours
                                                                  />
                                          </td>
                                                                   {
                                                                   obj.nz_count == 'Yes' || obj.nz_count == "Yes1" ?
                                          <td className="time-picker-cell-in"> 
                                                                <TimePicker
                                                                    style={{width:" 90px"}}
                                                                    showSecond={false} 
                                                                    readOnly
                                                                    disabled
                                                                    defaultValue={ (obj.show_start_from == null) ? obj.show_start_from : obj.show_start_from }
                                                                    onClose={(event) => handleClose( idx,"start")} 
                                                                    value={obj.show_start_from}
                                                                    clearIcon ={null}
                                                                    onFocus={handleFocus}
                                                                    onFocusOut={handleBlur} 
                                                                    // onBlur={handleBlur} 
                                                                    // onBlur={handleBlur}
                                                                    name="show_start_from"
                                                                    onChange={(event) => handleAmount(idx,"show_start_from")}
                                                               //  onChange={(event) => handleInput1(idx, event)}
                                                                   use12Hours
                                                                  />
                                         </td>:
                                         <td className="time-picker-cell" > 
                                                                <TimePicker
                                                                    showSecond={false} 
                                                                    
                                                                    // readOnly
                                                                    // disabled
                                                                    onClose={(event) => handleClose(idx,"start")} 
                                                                    style={{width:" 90px"}}
                                                                    defaultValue={ (obj.show_start_from == null) ? obj.show_start_from : obj.show_start_from }
                                                                    value={obj.show_start_from}
                                                                    clearIcon ={null}
                                                                    onFocus={handleFocus}
                                                                    onFocusOut={handleBlur}
                                                                    // onBlur={handleBlur}
                                                                    name="show_start_from"
                                                                    onChange={(event) => handleAmount(idx, event,"show_start_from")}
                                                                    // onClick={handleTimePickerClick}
                                                                   use12Hours
                                                                   
                                                                  />
                                                                  
                                          </td>}
                                                      
                                                                   {
                                                                   obj.nz_count == 'Yes' || obj.nz_count == "Yes1" ?  
                                                                    <td >
                                                       <input type="date" class="form-control" name="endDate"  disabled value={obj.endDate} onChange={(event) => handleAmount(idx,event,"end")} min={obj.op_date} max={obj.end_date_max} style={{border: '1px solid #ded9d9',height:"33px",width:" 135px" }} /></td>:         
                                                      <td>
                                                        <input type="date" class="form-control" name="endDate"   value={obj.endDate} onChange={(event) => handleAmount(idx,event,"end")} min={obj.op_date} max={obj.end_date_max} style={{border: '1px solid #ded9d9',height:"33px",width:" 135px" }}  /></td>}
                                                

                                                                   {
                                                                   obj.nz_count == 'Yes' || obj.nz_count == "Yes1" ?  
                                                                   
                                                                          <td className="time-picker-cell-in"> 
                                                                   
                                                                                          <TimePicker
                                                                                            showSecond={false} 
                                                                                            readOnly
                                                                                            disabled
                                                                                            onClose={(event) => handleClose(idx,"end")} 
                                                                                            style={{width: "80px"}}
                                                                                            clearIcon ={null}
                                                                                            defaultValue={ (obj.end_from == null) ? obj.sugest_end_from : obj.end_from }
                                                                                            value={(obj.end_from == null) ? obj.sugest_end_from : obj.end_from}
                                                                                            name="end_from"
                                                                                            // onClick={handleTimePickerClick}  
                                                                                      //  onChange={handleInput}
                                                                                          onChange={(event) => handleAmount(idx, event)}
                                                                                          use12Hours             
                                                                                          /> 
                                                                          </td> : 
                                                                      <td className="time-picker-cell"> 
                                                                                              <TimePicker
                                                                                          showSecond={false} 
                                                                                          clearIcon
                                                                                          onClose={(event) => handleClose(idx,"end")} 
                                                                                          style={{width: "80px"}}
                                                                                          defaultValue={ (obj.end_from == null) ? obj.sugest_end_from : obj.end_from }
                                                                                          value={(obj.end_from == null) ? obj.sugest_end_from : obj.end_from}
                                                                                          name="end_from"
                                                                                    //  onChange={handleInput}
                                                                                        // onClick={handleTimePickerClick}  
                                                                                        onChange={(event) => handleAmount(idx, event,"end_from")}
                                                                                        use12Hours
                                                                                     
                                                                                        /> 
                                                                      </td>
                                                                      
                                                                      

                                                                      
                                                                }

                                                       {/* <td>{obj.op_date}</td> */}
                                                       {/* <input type="date" value={endDate} onChange={(event) => handleEndDateChange(idx,event)} style={{border: '1px solid #ded9d9',height:"33px" }} />
                                                      //  </td> */}
                                                      <td>
                                                                <input type="text"  name="total_diff"
                                                                 value={`${obj.total_hr} hr ${obj.total_min} min`}
                                                                 readOnly
                                                                 style={{width: "120px"}}
                                                                 class="form-control hide-arrow" />
                                                      </td>
                                                               {obj.nz_count == "Yes" || obj.nz_count =="Yes1" ?
                                                       <td>
                                                             <input readOnly disabled style={{ paddingLeft: '25px',paddingTop: '2px', width: '150px' }}  value={obj.open_reading} />
                                                            
                                                       </td>:
                                                               
                                                      <td>
                                                       <input  readOnly disabled style={{ paddingLeft: '25px',paddingTop: '2px', width: '150px' }}  value={obj.open_reading} /> 
                                                      </td>}
                                                                {obj.nz_count == "Yes" || obj.nz_count =="Yes1" ?
                                                         <td>
                                                                  <input type="number" onKeyPress={preventNonNumericalInput}
                                                                  name="clossing_reading" 
                                                                  readOnly
                                                                  style={{  width: '150px' }}
                                                                  disabled
                                                                  value={obj.clossing_reading} 
                                                                  onChange={(event) => handleAmount(idx,event)}
                                                                  class="form-control hide-arrow" />
                                                        </td>:
                                                         <td>
                                                                  <input type="number" onKeyPress={preventNonNumericalInput}
                                                                  name="clossing_reading" 
                                                                  style={{  width: '150px' }}
                                                                  value={obj.clossing_reading} 
                                                                  onChange={(event) => handleAmount(idx,event)}
                                                                  class="form-control hide-arrow" />
                                                         </td>}
                                                                  {obj.nz_count =="Yes" || obj.nz_count =="Yes1" ?
                                                          <td>
                                                                <input style={{width: "150px"}} type="number"  name="quantity" 
                                                                 onKeyPress={preventNonNumericalInput} 
                                                                 value={obj.quantity} 
                                                                 readOnly
                                                                 disabled
                                                               // onChange={handleInputs} 
                                                                onChange={(event) => handleAmount(idx, event)}
                                                                class="form-control hide-arrow" />
                                                          </td>:
                                                           <td>
                                                                <input style={{width: "150px"}} type="number"  name="quantity" 
                                                               onKeyPress={preventNonNumericalInput} 
                                                               value={obj.quantity} 
                                                             // onChange={handleInputs} 
                                                              onChange={(event) => handleAmount(idx, event)}
                                                              class="form-control hide-arrow" />
                                                           </td>}


                                                          <td>
                                                              <input style={{ width: "80px" }} type="text"
                                                               readOnly name="price"
                                                               value={obj.price}
                                                               class="form-control" />
                                                          </td>
                                                            
                                                            {obj.nz_count == "Yes" || obj.nz_count =="Yes1"?
                                                          <td>
                                                              <input style={{width: "150px"}} type="number"  name="amount"
                                                               onKeyPress={preventNonNumericalInput}
                                                               value={obj.amount} 
                                                               readOnly
                                                               disabled
                                                               
                                                               onChange={(event) => handleAmount(idx,event)} 
                                                               class="form-control hide-arrow" />
                                                          </td>:
                                                          <td>
                                                            <input type="number"  name="amount"
                                                             onKeyPress={preventNonNumericalInput}
                                                             value={obj.amount} 
                                                             style={{width: "150px"}}
                                                             onChange={(event) => handleAmount(idx,event)} 
                                                             class="form-control hide-arrow" />
                                                          </td>}

                                      </tr>
                <tr>
                    <td></td> 
                    <td></td>
                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['start_from_error']:""}</div></td>
                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['end_date_error']:""}</div></td>
                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['end_from_error']:""}</div></td>
                    <td></td>
                    <td></td>
                    <td><div className="errorMsg">{mulerror[idx]? mulerror[idx]['close_reading_error']:""}</div></td>
                    <td><div className="errorMsg">{mulerror[idx]?mulerror[idx]['quanity_error']:""}</div><div className="errorMsg">{mulerror[idx]?mulerror[idx]['current_stock_error']:""}</div></td>
                    <td></td>
                    <td><div className="errorMsg">{mulerror[idx] ? mulerror[idx]['amount_error']:""}</div></td>
                    <td hidden>{total_amount=total_amount+Number(obj.amount)}</td>
                    {/* <td hidden>{total_quantity=total_quantity+Number(obj.quantity)}</td> */}
                </tr>
              
                <tr>
                  <td colSpan={8}>
                    {obj.nz_count == 'Yes' ? 
                  <div className="errorMsg">Readings were updated so you can't edit this transaction</div> :''}
                    {obj.nz_count == 'Yes1' ? <div className="errorMsg">If you want to edit,you need to reject approval of next employee closing which is allocated with this nozzle</div> :''}
                  </td>

                </tr>

                </tbody>
                                                            

  
        </>

);
})}

<tr>
                                              <td><h6>TOTAL</h6></td>
                                              <td><h6></h6></td>
                                              <td><h6></h6></td>
                                              <td><h6></h6></td>
                                              <td><h6></h6></td>
                                              <td><h6></h6></td>
                                              <td><h6></h6></td>
                                              <td><h6 hidden style={{ paddingRight: '10px' }}>Total Quantity:</h6></td>
                                              <td><h6 hidden>{total_quantity.toFixed(2)}(L)</h6></td>
                                              <td><h6>Total Amount:</h6></td>
                                              <td><h6>{total_amount.toFixed(2)}</h6></td>
                                              </tr>

{/* <tfoot>
  <tr>
    <td colSpan="10" style={{textAlign: 'right'}}>Total Amount:</td>
    <td><input type="text" readOnly disabled value={9} /></td>
  </tr>
</tfoot> */}
        </table>
      </div>

    
          
      {localStorage.getItem("save_hide") == 'false' ?
      <div class="modal-footer text-center">
        {double===false || inputValues.nozzle_status ==2 || inputValues.nz_count == 'Yes' || inputValues.nz_count == 'Yes1' || (inputValues.nz_count == 'Yes2' &&  inputValues.quantity != 0) ?    <a class="btn btn-primary px-4" type="submit" disabled>Nozzle close</a>:   <a class="btn btn-primary px-4" type="submit" onClick={handleSubmit1}>Nozzle close</a>}
      </div>
      :''}
      <div class="modal-footer text-center">
          {inputValues.nozzle_status ==2 ? <div className="errorMsg">Currently this nozzle is inactive </div> :''} 
          {inputValues.nz_count == 'Yes' ? <div className="errorMsg">Readings were updated so you can't edit this transaction</div> :''}   
          {inputValues.nz_count == 'Yes1' ? <div className="errorMsg">If you want to edit,you need to reject approval of next employee closing which is allocated with this nozzle</div> :''} 
          {inputValues.nz_count == 'Yes2' &&  inputValues.quantity != 0 ? <div className="errorMsg">(This fuel is aproved in fuel unload after this date. You can close nozzle with 0 quantity)</div> :''} 
      </div>
      </div>
    </div>
</React.Fragment>
  );
}
       export default DesignConfigModal;