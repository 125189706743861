import { Component } from "react";
import inhand from '../../../img/inhand.png'
import store from '../../../img/store.png'
import money from '../../../img/money.png'
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { API_URL } from "../../../constant/API_Settings";
import { useState } from 'react';
import FuelLoginHeaderOperational from './FuelLoginHeader.component';
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";
import TankEditModal from './TankEditModal.Component'
import FuelTabHeader from "./FuelTabHeader.Component";
import TankEditDelete from "./TankEditDelete.Component";
import Modal from "react-modal";
import Loader from "../Employee/Loader.component";
import TankCapacityModal from "./TankCapacityModal.component"
import TankModalView from "./TankModalViewComponent";

import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import PaginationComponent from '../../layouts/PaginationComponent';

import { prevNonNumericalWithDec, preventNonNumericalInput } from '../../layouts/Operational_Sidebar_Progress.component'
const TankModal = (props) => ReactDOM.createPortal(
   <TankEditModal  {...props} />, document.body
);
const CapacityModal = (props) => ReactDOM.createPortal(
   < TankCapacityModal  {...props} />, document.body
);
const ViewModal = (props) => ReactDOM.createPortal(
   < TankModalView  {...props} />, document.body
);
const DeleteModal = (props) => ReactDOM.createPortal(
   <TankEditDelete  {...props} />, document.body
);
const PaginationComp = (props) => (
   < PaginationComponent  {...props} />
);
const TankEdit = (props, progress) => {
   // -----------------state declare----------------//
   const [list_data, setList] = useState([])
   const [nozzle, setNozzle] = useState([])
   const [fields, setFields] = useState(['']);
   const [double, setDouble] = useState(true);
   const [inputValues, setInputValues] = useState([]);
   const [new_capacity, setCapacity] = useState([])
   const [loading, setLoading] = useState(false)
   const [dropdownData, setDropdownData] = useState([])
   const [tankData, setTankData] = useState([])
   const [success, setSuccess] = useState([])
   const [stockData, setStock] = useState([])
   const [currentStock, setStockData] = useState([])
   const [quantity, setQuantity] = useState([])
   const [mvquantity, setMvQuantity] = useState([])
   const [TankName, SetTankName] = useState([])
   const history = useHistory()
   const [permission, setPermission] = useState([])
   const [errors, setErrors] = useState({})
   const [fuelId, SetFuelId] = useState([])
   const [pages, setpages] = useState(
      {
         current_page: 1,
         last_page: 0,
         total_pages: 0,
         pagination_item: [],
         total_pages: 0,
      })
   //---------------onchange function-----------------//
   const handlePagination = (number) => {
      setpages({
         ...pages,
         current_page: number        //---pagination---//
      }
      )

      ListTankEdit(number)
   }
   const handleSelect = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }

   const handleInput = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleCapacity = (e) => {
      setCapacity({
         ...new_capacity,
         [e.target.name]: e.target.value
      })
   }
   function handleAdd() {

      const values_nozzle = [...fields];


      values_nozzle.push('');
      setFields(values_nozzle);
      setErrors([])
   }
   function handleRemove(i, event) {
      var i = fields.length - 1
      if (i != 0) {
         const values_nozzle = [...fields];
         values_nozzle.splice(i, 1);
         setFields(values_nozzle);
         //  setInputValues({...inputValues, nozzle_id: values_nozzle})
      }

   }
   const handleChange = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleChange1 = (e) => {
      let stock = e.target.value

      //  var stock1= parseFloat(stock).toFixed(2)
      setInputValues({
         ...inputValues,
         [e.target.name]: stock
      })
   }
   const [search, setSearch] = useState('')
   const handleSearch = (event) => {
      setSearch(event.target.value)
   }
   const handleTank = (idx, e, prop) => {
      inputValues[prop] = { ...inputValues[prop], [idx]: e.target.value };
      setInputValues(inputValues)
   }
   const handlePrice = (e) => {
      if (e.target.name == "sale_price" && inputValues.sale_quantity) {
         var amount = (inputValues.sale_quantity * e.target.value) ? (inputValues.sale_quantity * e.target.value) : ''
      }

      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value,
         sale_amount: amount
      })
   }


   const handleLossPrice = (e) => {
      if (e.target.name == "loss_price" && inputValues.loss_quantity) {
         var amount = (inputValues.loss_quantity * e.target.value) ? (inputValues.loss_quantity * e.target.value) : ''
      }
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value,
         loss_amount: amount
      })
   }
   const handleSaleAmount = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleStockInput = (e) => {


      if ((e.target.name === "stock_movement" || e.target.name === "cash_sale" || e.target.name === "loss")) {

         setInputValues({
            ...inputValues,
            [e.target.name]: e.target.checked ? 1 : 0
         });
      }

      else
         setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value
         });

   };
   const handleNameChange = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleDeleteQuantity = (e) => {
      setQuantity({
         ...quantity,
         [e.target.name]: e.target.value
      })
      setInputValues({
         ...inputValues,
         quantity: e.target.value,
         actual_physical_quantity: e.target.value
      })
   }
   const handleMovedQuantity = (idx, e, prop) => {
      var values = [...fields]
      inputValues[prop] = { ...inputValues[prop], [idx]: e.target.value };
      setInputValues(inputValues)
      var sale = inputValues.sale_quantity == null ? 0 : inputValues.sale_quantity
      var loss = inputValues.loss_quantity == null ? 0 : inputValues.loss_quantity
      const stock1 = currentStock
      const q1 = inputValues.quantity
      var mq = 0
      for (var i = 0; i < values.length; i++) {
         mq = Number(document.getElementById("moved_quantity" + i).value) + Number(mq)
      }
      if (Number(currentStock) > Number(inputValues.quantity)) {
         var moved = Number(sale) + mq + Number(loss)
         if (Number(currentStock >= moved)) {
            var q = (q1 - (Number(sale) + mq + Number(loss)))
            var stock = (stock1 - (Number(sale) + mq + Number(loss)))
            var st = stock.toFixed(2)
            var q2 = q.toFixed(2)

         }
         else {
            swal("", "Quantity not exceeded than current stock", "warning")
            e.target.value = ""
         }
      }
      else if (Number(currentStock) < Number(inputValues.quantity)) {

         var moved = Number(sale) + mq + Number(loss)
         if (Number(inputValues.quantity >= moved)) {
            var q = (q1 - (Number(sale) + mq + Number(loss)))
            var stock = (stock1 - (Number(sale) + mq + Number(loss)))
            var st = stock.toFixed(2)
            var q2 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than Physical quantity", "warning")
            e.target.value = ""
         }
      }
      else {
         var moved = Number(sale) + mq + Number(loss)
         if (Number(currentStock >= moved)) {
            var q = (q1 - (Number(sale) + mq + Number(loss)))
            var stock = (stock1 - (Number(sale) + mq + Number(loss)))
            var st = stock.toFixed(2)
            var q2 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than current stock", "warning")
            e.target.value = ""
         }
      }
      setMvQuantity(mq)

      if (Number(st) >= 0) {
         setStock(st)
      }
      else {
         setStock(0)
      }
      if (Number(q) >= 0) {
         setQuantity({
            physical_quantity: q2
         })
      }
      else {
         setQuantity({
            physical_quantity: 0
         })
      }
   }
   const handleLossQuantity = (e) => {
      if (inputValues.loss_price) {
         var amount = (inputValues.loss_price * e.target.value) ? (inputValues.loss_price * e.target.value) : ''
      }
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value,
         loss_amount: amount
      })
      var sale = document.getElementById("loss_quantity").value == null ? 0 : document.getElementById("loss_quantity").value
      var moved = mvquantity == null ? 0 : mvquantity
      var loss = inputValues.sale_quantity == null ? 0 : inputValues.sale_quantity
      const stock2 = currentStock
      const q2 = inputValues.quantity

      if (Number(currentStock) > Number(inputValues.quantity)) {
         var loss_quantity = Number(sale) + Number(moved) + Number(loss)

         if (Number(currentStock) >= Number(loss_quantity)) {
            var stock = (stock2 - (Number(sale) + Number(moved) + Number(loss)))
            var st = stock.toFixed(2)
            var q = (q2 - (Number(sale) + Number(moved) + Number(loss)))
            var q1 = q.toFixed(2)

         }
         else {
            swal("", "Quantity not exceeded than tank current stock", "warning")
            e.target.value = ""
         }
      }
      else if (Number(currentStock) < Number(inputValues.quantity)) {
         var loss_quantity = Number(sale) + Number(moved) + Number(loss)

         if (Number(inputValues.quantity >= loss_quantity)) {
            var stock = (stock2 - (Number(sale) + Number(moved) + Number(loss)))
            var st = stock.toFixed(2)
            var q = (q2 - (Number(sale) + Number(moved) + Number(loss)))
            var q1 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than tank Physical quantity", "warning")
            e.target.value = ""
         }
      }
      else {
         var loss_quantity = Number(sale) + Number(moved) + Number(loss)

         if (Number(currentStock) >= Number(loss_quantity)) {
            var stock = (stock2 - (Number(sale) + Number(moved) + Number(loss)))
            var st = stock.toFixed(2)
            var q = (q2 - (Number(sale) + Number(moved) + Number(loss)))
            var q1 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than tank current stock", "warning")
            e.target.value = ""
         }

      }

      if (Number(st) >= 0) {
         setStock(st)
      }
      else {
         setStock(0)
      }
      if (Number(q) >= 0) {
         setQuantity({
            physical_quantity: q1
         })
      }
      else {
         setQuantity({
            physical_quantity: 0
         })
      }



   }
   const handleSaleQuantity = (e) => {


      if (inputValues.sale_price != "") {
         var amount = (inputValues.sale_price * e.target.value) ? (inputValues.sale_price * e.target.value) : ''
      }

      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value,
         sale_amount: amount

      })
      var loss = document.getElementById("sale_quantity").value == null ? 0 : document.getElementById("sale_quantity").value
      var moved = mvquantity == null ? 0 : mvquantity
      var sale = inputValues.loss_quantity == null ? 0 : inputValues.loss_quantity

      const stock3 = currentStock
      const q3 = inputValues.quantity

      if (Number(currentStock) > Number(inputValues.quantity)) {
         var sale_quantiy = Number(sale) + Number(moved) + Number(loss)
         if (Number(currentStock) >= Number(sale_quantiy)) {
            var stock = (stock3 - (Number(sale) + Number(moved) + Number(loss)))
            var st = stock.toFixed(2)
            var q = (q3 - (Number(sale) + Number(moved) + Number(loss)))
            var q1 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than tank current stock", "warning")
            e.target.value = ""
         }
      }
      else if (Number(currentStock) < Number(inputValues.quantity)) {

         var sale_quantiy = Number(sale) + Number(moved) + Number(loss)
         if (Number(inputValues.quantity >= sale_quantiy)) {
            var stock = (stock3 - (Number(sale) + Number(moved) + Number(loss)))
            var st = stock.toFixed(2)
            var q = (q3 - (Number(sale) + Number(moved) + Number(loss)))
            var q1 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than tank Physical quantity", "warning")
            e.target.value = ""
         }
      }
      else {
         var sale_quantiy = Number(sale) + Number(moved) + Number(loss)
         if (Number(currentStock) >= Number(sale_quantiy)) {
            var stock = (stock3 - (Number(sale) + Number(moved) + Number(loss)))
            var st = stock.toFixed(2)
            var q = (q3 - (Number(sale) + Number(moved) + Number(loss)))
            var q1 = q.toFixed(2)
         }
         else {
            swal("", "Quantity not exceeded than tank current stock", "warning")
            e.target.value = ""
         }
      }
      if (Number(st) >= 0) {
         setStock(st)
      }
      else {
         setStock(0)
      }
      if (Number(q) >= 0) {
         setQuantity({
            physical_quantity: q1
         })
      }
      else {
         setQuantity({
            physical_quantity: 0
         })
      }

   }
   const handleNozzleChange = (e, idx, prop) => {
      inputValues[prop] = { ...inputValues[prop], [idx]: e.target.value };

   }
   const handleInputs = (e) => {
      var nozzle = []
      for (var i = 1; i <= e.target.value; i++) {
         nozzle.push({
            nozzle_name: '',
            reading: ''
         })
      }
      setNozzle(nozzle)
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   // ---handlesubmit function---//
   const handleTankSubmit = (event) => {
      event.preventDefault();
      setDouble(false);
      sendTankEdit()

   }
   const handleNameSubmit = (event) => {
      event.preventDefault();
      setDouble(false)
      sendName()

   }
   const HandleDeleteSubmit = (event) => {
      event.preventDefault();
      setDouble(false)
      sendDelete()
   }
   //---On Load---//		
  
   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        if (search !== null || search === "") {
         ListTankEdit(1)
        }
      }, 500);
  
      return () => clearTimeout(delayDebounceFn);
    }, []);
   //------------------------modal--------------------//
   const UseModal = () => {

      const [isShowing, setIsShowing] = useState(false);

      function toggle() {
         if (isShowing) {
            setNozzle([])
            setInputValues([])
            setErrors([])
         }
         if (!isShowing) {
            setDouble(true)


         }
         setIsShowing(!isShowing);
      }

      return {
         isShowing,
         toggle,
      }
   };
   const { isShowing, toggle } = UseModal();
   //-------------Save (akhitha-15/7/2021)--------------//
   function sendTankEdit() {

      const requestOptions = {
         method: 'POST',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            capacity: inputValues.capacity,
            // current_stock: parseFloat(inputValues.current_stock).toFixed(2),
            diameter: inputValues.diameter,
            fuel_id: inputValues.fuel_id,
            // last_purchase_price:inputValues.last_purchase_price,
            length: inputValues.length,
            no_of_nozzles: inputValues.no_of_nozzles,
            nozzle_name: inputValues.nozzle_name,
            reading: inputValues.reading,
            // selling_price:inputValues.selling_price,
            tank_name: inputValues.tank_name
         })
      };
      fetch(API_URL + "/op-tank-configuration", requestOptions)
         .then(response => {
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            if (responseData.status == false) {
               errors["capacity"] = responseData.response.capacity
               errors["fuel_id"] = responseData.response.fuel_id
               errors["no_of_nozzles"] = responseData.response.no_of_nozzles
               errors["tank_name"] = responseData.response.tank_name
               errors["nozzle_name"] = responseData.response.nozzle_name
               errors["reading"] = responseData.response.reading
               setDouble(true)
            }
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")
               setDouble(true)

            }

            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               history.push("/op-tank-edit")
               setErrors([''])
               setInputValues([])
               toggle();
               ListTankEdit(pages.current_page);

            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }
   function sendName() {

      const requestOptions = {
         method: 'PUT',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            new_name: inputValues.new_name
         })
      };
      fetch(API_URL + "/op-tank-configuration/" + TankName.id, requestOptions)
         .then(response => {
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            if (responseData.status == false) {
               errors["new_name"] = responseData.response.new_name
               setDouble(true)

            }
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")
               setDouble(true)

            }

            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               history.push("/op-tank-edit")
               setErrors([''])
               setInputValues([])
               toggleModal();
               ListTankEdit(pages.current_page);

            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }
   function sendDelete() {

      const requestOptions = {
         method: 'Delete',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            tank_name: TankName.tank_name,
            stock: stockData,
            physical_quantity: quantity.physical_quantity,
            moved_quantity: inputValues.moved_quantity,
            moved_tank_id: inputValues.moved_tank_id,
            sale_quantity: inputValues.sale_quantity,
            sale_price: inputValues.sale_price,
            sale_amount: inputValues.sale_amount,
            loss_quantity: inputValues.loss_quantity,
            loss_price: inputValues.loss_price,
            loss_amount: inputValues.loss_amount,
            actual_physical_quantity: inputValues.actual_physical_quantity


         })
      };
      fetch(API_URL + "/op-tank-configuration/" + TankName.id, requestOptions)
         .then(response => {
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            if (responseData.status == false) {
               errors["physical_quantity"] = responseData.response.physical_quantity
               errors["moved_tank_id"] = responseData.response.moved_tank_id
               errors["stock"] = responseData.response.stock
               setDouble(true)

            }
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")
               setDouble(true)

            }

            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               history.push("/op-tank-edit")
               setErrors([''])
               setInputValues([])
               toggling();
               ListTankEdit(pages.current_page);

            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }
   // ------------Listing (akhitha-15/7/2021)---------//
   function ListTankEdit(page) {
      const requestOptions = {
         method: 'get',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },

      };
      fetch(`${API_URL}/op-tank-configuration?search_keyword=${search}&page=${page}`, requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            console.log(responseData)
            if (responseData.status == 3) {
               setPermission({ ...permission, status: responseData.status, message: responseData.message })
            }
            setList(responseData.tank_data.data)
            var datas = []
            responseData.fuel_type.forEach(elem => {
               datas.push([elem.id, elem.fuel_name])
            })
            setDropdownData(datas);

            setpages({
               ...pages,
               current_page: responseData.tank_data.current_page,
               last_page: responseData.tank_data.last_page,
               per_pages: responseData.tank_data.per_page,
               total_pages: responseData.tank_data.total,

            })

            setLoading(true)
            return responseData;
         })
         .catch(e => {


         });
   }
   function TankRename(tank_name, id) {

      SetTankName({
         ...TankName,
         tank_name: tank_name,
         id: id
      })

      toggleModal()
   }
   function TankCapacity(id, tank_name) {

      SetTankName({
         ...TankName,
         tank_name: tank_name,
         id: id
      })

      toggleCapacity()
   }

   function TankDelete(id, tank_name) {
      SetTankName({
         ...TankName,
         tank_name: tank_name,
         id: id
      })
      getTank(id)
      toggling()
   }
   function getTank(id) {

      const requestOptions = {
         method: 'get',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },
      };
      fetch(API_URL + "/op-tank-configuration/" + id + "/edit", requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            var datas = []
            responseData.tankData.forEach(elem => {
               datas.push([elem.id, elem.tank_name])
            })
            setTankData(datas);
            setStock(responseData.stock)
            setStockData(responseData.stock)
            return responseData;
         })
         .catch(e => {
            console.log(e);

         });
   }

   //------------------delete Modal--------------------//
   const UseDeleteModal = () => {

      const [isClose, setClose] = useState(false);

      function toggling() {
         if (isClose) {

            setInputValues([])
            setStock([])
            setQuantity([])
            setErrors([])
            setFields([''])

         }
         if (!isClose) {

            setDouble(true)

         }
         setClose(!isClose);
      }

      return {
         isClose,
         toggling,
      }
   };
   const { isClose, toggling } = UseDeleteModal();
   const UseViewModal = () => {

      const [showview, setShowView] = useState(false);

      function toggleView() {
         if (showview) {

         }
         if (!showview) {

         }
         setShowView(!showview);
      }

      return {
         showview,
         toggleView,
      }
   };
   const { showview, toggleView } = UseViewModal();
   //------------------Capacity Changing Modal--------------------//
   const UseCapacityModal = () => {

      const [show, setShow] = useState(false);

      function toggleCapacity() {
         if (show) {
            setErrors([])
            setCapacity([])
            SetTankName([])
         }
         if (!show) {
            setDouble(true)
         }
         setShow(!show);
      }

      return {
         show,
         toggleCapacity,
      }
   };
   const { show, toggleCapacity } = UseCapacityModal();
   function handleCapacitySubmit() {

      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            capacity: new_capacity.capacity,
            id: TankName.id


         })
      };
      fetch(API_URL + "/op_tank_update", requestOptions)
         .then(response => {
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            if (responseData.status == false) {
               errors["capacity"] = responseData.response.capacity

            }


            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               setErrors([''])
               setCapacity([])
               SetTankName([])
               toggleCapacity();
               ListTankEdit(pages.current_page);

            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }
   const [isOpen, setIsOpen] = useState(false);
   function toggleModal() {
      if (!isOpen) {
         setDouble(true)

      }
      setIsOpen(!isOpen);
      setErrors([''])
      setInputValues([])

   }
   return (
      <>
         <div className="main-container page-wrap">
            <div className="d-md-flex">
               <Sidebar_Progress props={props} progress={7} />
               <div className="content-area ">
                  <FuelLoginHeaderOperational props={props} page_title="" />
                  <div className="col-md-11 mt-1"><Link to="/nozzle-employee-list">
	<a href="/nozzle-employee-list" class="float-end btn btn-gray" >Back</a></Link>
	</div>

                  <div className="container pb-3 tab-view">
                     <div className="row mt-md-5 mt-3 form-container">
                        <div className="col-md-12">
                        <div className="form-wrap ">
                           <FuelTabHeader tab={2} />
                           {permission.status == 3 ?
                              <div className="form-wrap mt-5">
                                 <div className="text-center">
                                    {permission.message}
                                 </div></div> :
                              <>

                           {/* <div class="form-wrap "> */}
                              <div>
                                 {loading ? ListTankEdit : <Loader />}
                                    {/* <div className="form-wrap "> */}
                                    <div className="tab-content" id="myTabContent" >
                                       <div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
                                    <div class="">
                                       <div class="row mb-3">
                                          <div class="col-md">
                                             <div class="row">
                                                <div class="col-md-2 col pl-0">
                                                   <h3 class="table-title">Tanks</h3>
                                                </div>
                                                <div class="col-md col pl-0">
                                                   <a href="#" class="btn btn-primary " onClick={toggle}>Add New</a>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-md-4">
                                             <div class="input-group mb-3 search">
                                                <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                                <input type="text" class="form-control" placeholder="Search With Tank Name " value={search.search_keyword} onChange={handleSearch}  />
                                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={ListTankEdit} >Submit</a>

                                             </div>
                                          </div>
                                       </div>
                                       <div class="table-widget">
                                          <table class="table border">
                                             <thead>
                                                <tr>
                                                   <th> Tank Name</th>
                                                   <th>Fuel Name </th>
                                                   <th>Capacity in KL.</th>
                                                   {/* <th>Diameter</th>
                                                   <th>Length</th> */}
                                                   <th>Stock in Lt.</th>
                                                   <th>Selling Price</th>
                                                   <th>Purchase Price</th>
                                                   <th>Stock Value</th>
                                                   <th>Action</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {
                                                   list_data.map(obj => (
                                                      <tr>
                                                         <td>{obj.tank_name}</td>
                                                         <td>{obj.fuel_name}</td>
                                                         <td>{obj.capacity}</td>
                                                         {/* <td>{obj.diameter}</td>
                                                         <td>{obj.length}</td> */}
                                                         <td>{obj.current_stock}</td>
                                                         <td>{obj.selling_price}</td>
                                                         <td>{obj.last_purchase_price}</td>
                                                         <td>{obj.stock_value}</td>
                                                         <td><a href="#" class="text-secondary me-2" onClick={() => TankRename(obj.tank_name, obj.id)} ><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                            <a href="#" class="text-secondary me-2" onClick={() => TankDelete(obj.id, obj.tank_name)} ><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                                                            <a href="#" class="text-secondary me-2" onClick={() => TankCapacity(obj.id, obj.tank_name)} ><i class="fa fa-edit" aria-hidden="true"></i></a>
                                                            <a href="#" class="text-secondary" > <Link to={() => ({ id: obj.id,props, pathname: "/tank-view" })}><i class="fa fa-history" aria-hidden="true"></i></Link></a></td>

                                                      </tr>
                                                   ))}
                                             </tbody>
                                          </table>
                                          {loading ? <PaginationComp
                                             pages={pages}
                                             handlePagination={handlePagination}
                                          /> : ""
                                          }
                                       </div>
                                       <div>

                                       </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                           </>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {isShowing ? (<TankModal
            isShowing={isShowing}
            hide={toggle}
            handleInput={handleInput}
            handleChange={handleChange}
            handleChange1={handleChange1}
            handleSelect={handleSelect}
            handleTankSubmit={handleTankSubmit}
            prevNonNumericalWithDec={prevNonNumericalWithDec}
            preventNonNumericalInput={preventNonNumericalInput}
            handleInputs={handleInputs}
            dropdownData={dropdownData}
            nozzle={nozzle}
            errors={errors}
            double={double}
            handleNozzleChange={handleNozzleChange}
            inputValues={inputValues}
            fuelId={fuelId}

         />) : null
         }
         {isClose ? (<DeleteModal
            isClose={isClose}
            hide={toggling}
            handleDeleteQuantity={handleDeleteQuantity}
            handleMovedQuantity={handleMovedQuantity}
            prevNonNumericalWithDec={prevNonNumericalWithDec}
            preventNonNumericalInput={preventNonNumericalInput}
            handleTank={handleTank}
            TankName={TankName}
            HandleDeleteSubmit={HandleDeleteSubmit}
            quantity={quantity}
            fields={fields}
            errors={errors}
            double={double}
            handleStockInput={handleStockInput}
            handleAdd={handleAdd}
            handleRemove={handleRemove}
            handleSaleAmount={handleSaleAmount}
            handleLossQuantity={handleLossQuantity}
            handleSaleQuantity={handleSaleQuantity}
            handleLossPrice={handleLossPrice}
            handlePrice={handlePrice}
            inputValues={inputValues}
            tankData={tankData}
            stockData={stockData}
         />) : null
         }
         {show ? (<CapacityModal
            show={show}
            hide={toggleCapacity}
            handleCapacity={handleCapacity}
            handleCapacitySubmit={handleCapacitySubmit}
            errors={errors}
            preventNonNumericalInput={preventNonNumericalInput}
         />) : null
         }
         {showview ? (<ViewModal
            show={showview}
            hide={toggleView}
            handleCapacity={handleCapacity}
            handleCapacitySubmit={handleCapacitySubmit}
            errors={errors}
            preventNonNumericalInput={preventNonNumericalInput}
         />) : null
         }

         <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal custommodal"
            overlayClassName="myoverlay"
         >
            <div class="modal-content">
               <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Rename</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
               <div class=" modal-body  modal-body">
                  <div class="row g-3 justify-content-center mb-2">
                     <div class="col-6">
                        <label class="col-form-label font-weight-bolder">Old Name</label>
                     </div>
                     <div class="col-6">
                        <input type="text" class="form-control highlight" name="old_name" value={TankName.tank_name} readonly />
                     </div>
                     <div class="col-6">
                        <label class="col-form-label font-weight-bolder">New Name</label>
                     </div>
                     <div class="col-6">
                        <input type="text" class="form-control " name="new_name" value={inputValues.new_name} onChange={handleNameChange} />
                        <div className="errorMsg">{errors.new_name}</div>

                     </div>
                  </div>
               </div>

               <div class="modal-footer text-center">
                  {double == true ? <a class="btn btn-primary w-auto " onClick={handleNameSubmit}>Save</a> : <a class="btn btn-primary w-auto " disabled>Save</a>}
                  <a class="btn btn-danger w-auto" onClick={toggleModal}>Cancel</a>

               </div>

            </div>
         </Modal>
      </>
   )
}


export default TankEdit;

