import React from 'react';
const TankerExpModal = ({ register,double, hide, errors, inputValues, handleSubmitexpense, handleInputs }) => {

  return (
    <React.Fragment>
 
  <div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Own Tanker Expense</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="row mb-3 justify-content-center mt-2">
              </div>
  <div class="row px-3 mb-2">
      <label class="col-sm-5 col-form-label mb-2"> Registration Number<sup class="star">*</sup></label>
      <div class="col-sm-6 mb-1">
       {inputValues.status != undefined && <input type="text" class="form-control w-100 mb-1 " name="tanker_no" value={inputValues['tanker_no']} />}
      {inputValues.status === undefined &&
        <div class="select-container w-90">  <select  class="form-control "  name="tanker_id" value={inputValues["tanker_id"]} onChange={handleInputs}>
            <option value="">Select</option>
            {register.map((data)=>{

              return(inputValues["tanker_id"]===data.reg_number ? <option  selected={inputValues["tanker_id"]} >{data.reg_number}</option>: <option value={data.id} key={data.id}  >{data.reg_number}</option>)
            })
          }
            </select></div>}
            <div className="errorMsg">{errors.tanker_id}</div>
            </div>
      </div>
      <div class="row px-3 mb-2 ">

        <label  class="col-sm-5 col-form-label mb-2">Tanker Expense Head <sup class="star">*</sup></label>

          <div class="col-md-5 mb-2 pe-0">
                <input type="text" class="form-control w-100 mb-2" name="expense" value={inputValues["expense"]} onChange={handleInputs} />
                <div className="errorMsg">{errors.expense}</div>
          </div>
       </div>

  
    <div class="text-center ">
           {double==true?   <button type="submit" class="btn btn-primary w-auto" onClick={handleSubmitexpense} >Save  Tanker Expense </button>: <button type="submit" class="btn btn-primary w-auto" disabled>Save  Tanker Expense</button>}
              </div>

          </div>
        </div>
       
    </React.Fragment>
      );
    }
     export default TankerExpModal;