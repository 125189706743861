import { Component } from "react";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import { useEffect } from 'react';
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import DsrModal from './DsrModal.component'
import ViewModal from './ViewDsrModal.component'
import swal from "sweetalert";
import Loader from "../Loader.component"
import Modal from "react-modal";
import moment from 'moment';
import PaginationComponent from '../../../layouts/PaginationComponent';

const Dsr = (props) => ReactDOM.createPortal(
	<DsrModal  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const ViewModal1 = (props) => ReactDOM.createPortal(
	<ViewModal  {...props} />, document.body
);




const DsrReportComponent = (props) => {
	const history = useHistory()
	const [product_data, setProduct] = useState([])
	const [checkoption, ckeckOption] = useState([])
	const [TankData, setTankData] = useState([])
	const [input, setInput] = useState([])
	const [inputValues, setInputValues] = useState([])
	const [value, setValue] = useState({

	})
	const [errors, setError] = useState({})
	const [Dip_data, setDipData] = useState([])
	const [dip_data, setDip_datas] = useState({})
	const [loading, setLoading] = useState(false)
	const [list_data, setList] = useState([])
	const [delete_id, deleteId] = useState([])
	const [nozzle_data, setNozzleData] = useState([])
	const [Others, setOthers] = useState([])
	const [dip_error, setDiperror] = useState({})
	const [error1, setError1] = useState([])
	const [double, setDouble] = useState(true);
	const [permission, setPermission] = useState([])
	const [drop, setDrop] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	useEffect(() => {
		check_url()
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};
		fetch(API_URL + "/dsr", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var product_details = []

				responseData?.product_details?.forEach(
					elem => {
						product_details.push([
							elem?.id,
							elem?.fuel_name])
					})

				setProduct(product_details);
				setDrop(product_details)
				ckeckOption({
					...checkoption,
					isdayclose: responseData.isdayclose
				})
				return responseData;
			})
			.catch(e => {
				console.log(e)
			});


	}, [])
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);

				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	const handleSelect = (e) => {
		var id = e.target.value

		setInput({
			...input,
			[e.target.name]: e.target.value,
			id: id
		});


	}
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const UseView = () => {
		const [isOp, setIsOp] = useState(false);
		function toggleEdit() {

			if (isOp) {
			}
			if (!isOp) {
			}
			setIsOp(!isOp);
		}
		return {
			isOp,
			toggleEdit,
		}

	}
	const { isOp, toggleEdit } = UseView();
	const UseModal = () => {
const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
					setValue([])
					setDipData([])
					setInputValues([])
					setTankData([])
					setDiperror([])

				}
				if (!isShowing) {

					setDiperror([])

				}
				setIsShowing(!isShowing);


			}
		return {
			isShowing,
			toggle,
		}
	};

	const { isShowing, toggle } = UseModal();
	const send_date = () => {
		if (validateForm())
			setDouble(true)
		sendDate()
	}
	function sendDate() {


		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

			body: JSON.stringify({
				product_id: input?.id,
				date: value?.date


			})
		};
		fetch(API_URL + "/date-data", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				if (responseData.status == false) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					setDouble(true)
				}

				setTankData(responseData.form_tank_data || [])
				var fuel_name = responseData?.form_tank_data[0]?.fuel_name

				setValue
					({
						...value,
						fuel_name: fuel_name
					})


				return responseData;
			})
			.catch(e => {

			});
	}

	useEffect(() => {

		const requestOptions = {
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(`${API_URL}/get-next-date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				var next_date = responseData.next_date

				var confg_date = responseData.confg_date

				setValue({
					...value,
					next_date: next_date,
					config_date: confg_date
				})



				return responseData;
			})
			.catch(e => {


			});

	}, [])
	const handleDate = (e) => {

		var date = e.target.value
		setValue({
			...value,
			date: date
		})
	}
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (!value?.date) {

			formIsValid = false;
			errors["date"] = "Please Select the operational Date for get the Sales"
		}


		setError(errors)
		return formIsValid;
	}



	const handleSubmit1 = (event) => {

		ListDSRData(pages.current_page)

	}

	const handleDip = (i, e, id) => {


		let form_tank_data = []
		let dip_data = []
		form_tank_data = [...TankData]

		if (form_tank_data[i]) {
			const multiple = e.target.value
			dip_data.push({
				tank_id: form_tank_data[i].tank_id,
				current_stock: form_tank_data[i].current_stock,
				dip_value: multiple,

			})

			setDiperror([])

		}
		setDip_datas(dip_data);
		var tank_data = []
		if (form_tank_data[i]) {

			for (var i = 0; i < form_tank_data.length; i++) {
				const multiple = document.getElementById("dip_id" + i).value
				const multiple2 = document.getElementById("water_dip_id" + i).value
				const multiple3 = document.getElementById("physical_id" + i).value

				tank_data.push({
					tank_id: form_tank_data[i].tank_id,
					current_stock: form_tank_data[i].current_stock,
					dip_value: multiple,
					water_dip_value: multiple2,
					entered_opening_stock: multiple3

				})

			}

		}
		setDipData({
			...Dip_data,
			[e.target.name]: e.target.value,
			tank_data: tank_data
		})


	}

	function sendData() {
		const tank_data = Dip_data?.tank_data

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

			body: JSON.stringify({

				tank_data: tank_data || TankData,
				date: value?.date,
				product_id: input?.id,
				id:inputValues.id


			})
		};
		fetch(API_URL + "/dsr", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				let errors = {}
				let errors1 = []
				if (responseData.status == false) {
					// swal("",responseData.response.date)
					errors1["date"] = responseData?.response?.date
                    setError1(errors1)
					setDiperror(responseData?.message)
					setDouble(true)


				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/op-dsr-report")
					setTankData(TankData)
					setDiperror([])
					toggle();
					setDouble(true)
					ListDSRData(pages.current_page);


				}

				setError(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				setDouble(true)
			});
	}

	const handleSubmit = () => {
		setDouble(false)
		sendData()
	}

	useEffect(() => {
		ListDSRData(pages.current_page);

	}, [])

	const handleSelect1 = (e) => {
		var ida = e.target.value
		setValue({
			...value,

			[e.target.name]: e.target.value,
			ida: ida

		});
	}
	function ListDSRData(page) {


		const requestOptions = {
			method: "post",
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				product_id: value?.ida,
				fro_date: value?.fro_date,
				to_date: value?.to_date,

			}),
		};


		fetch(`${API_URL}/search-data?page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setList(responseData.table_data.data);
				setLoading(true)

				setpages({
					...pages,
					current_page: responseData.table_data.current_page,
					last_page: responseData.table_data.last_page,
					per_pages: responseData.table_data.per_page,
					total_pages: responseData.table_data.total,

				})


				return responseData;

			})
			.catch((e) => {


			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListDSRData(number)
	}



	function edit_DSR(id,product_id) {
		const requestOptions = {
			method: 'get',
			data: { id: id },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(API_URL + "/dsr/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				var tank_data = []

				responseData.tank_data.forEach((elem, index) => {

					tank_data.push({
						tank_id: elem.tank_id,
						tank_name: elem.tank_name,
						gain_loss: elem.gain_loss,
						current_stock: elem.currentStock,
						dip_value: elem.dip,
						water_dip_value: elem.water_dip,
						physical_stock: elem.physical_stock,
						entered_opening_stock: elem.physical_stock,
					})

					var fuel_name = responseData?.others?.fuel_name
					var date = responseData?.others?.others?.op_date
					var id = responseData?.others.others.product_id
					setValue({
						...value,
						date: date,
						fuel_name: fuel_name,
						
					})

				})
				setInputValues({ ...inputValues, id: id })
				setInput({ ...input, id: product_id })

				setTankData(tank_data)

				toggle()
				return responseData;
			})
			.catch(e => {


			});
	}

	async function view_DSR(id) {
		const requestOptions = {
			method: 'get',
			data: { id: id },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(API_URL + "/dsr/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {


				var nozzle_data = []

				responseData?.nozzle_data.forEach((elem) => {
					nozzle_data.push({
						dsr_tank_id: elem?.dsr_tank_id,
						nozzle_name: elem?.nozzle_name,
						reading: elem?.reading,
						nozzle_id: elem?.nozzle_id
					})
				})
				setNozzleData(nozzle_data)

				var tank_data = []
				responseData?.tank_data.forEach((elem) => {
					tank_data?.push({
						tank_id: elem?.tank_id,
						tank_name: elem?.tank_name,
						opening_stock: elem?.opening_stock,
						entered_opening_stock: elem?.physical_stock,
						water_dip: elem?.water_dip,
						dip: elem?.dip,
						gain_loss: elem?.gain_loss
					})
				})
				setTankData(tank_data)
				console.log(responseData?.others?.others?.op_date)
				var others = []


				others?.push({
					cumulative_sales: responseData?.others?.others?.cumulative_sales,
					loose_sale_litre: responseData?.others?.others?.loose_sale_litre,
					packed_sale_litre: responseData?.others?.others?.packed_sale_litre,
					op_date: responseData?.others?.others?.op_date,
					receipt: responseData?.others?.others?.receipt,
					sales: responseData?.others?.others?.sales,
					testing: responseData?.others?.others?.testing,
					total_gain_or_loss: responseData?.others?.others?.total_gain_or_loss,
					total_opening_stock: responseData?.others?.others?.total_opening_stock,
					total_stock: responseData?.others?.others?.total_stock,
					product_id: responseData?.others?.others?.product_id

				})

				setOthers(others)
				var fuel_name = responseData?.others?.fuel_name


				setValue({
					...value,
					fuel_name: fuel_name
				})
				toggleEdit();
				return responseData;
			})
			.catch(e => {
			});
	}


	function delete_DSR(id) {

			deleteId(id)

			toggleModal()
		
	}

	function deleteDSR() {
		setDouble(false)

		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/dsr/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				setDouble(true)
				ListDSRData(pages.current_page);
				toggleModal()

				return responseData;


			})

			.catch(err => {

			})
	}
	const handles = (e) => {

		setValue({
			...value,
			[e.target.name]: e.target.value
		})
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={3} operation={1} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div className="container pb-3">

							<div className="row justify-content-center  form-container">
								{permission.status == 3 ?
									<div className="form-wrap mt-5">
										<div className="text-center">
											{permission.message}
										</div></div> :
									<>

										<div>
											<div className="text-end mt-4">
												<a className="btn btn-success
									 px-3 mb-md-0 mb-2" onClick={(event) => history.push("/dsr")}>
													DSR Report
									</a>
											</div>
											<div className="col-md-12">

												{loading ? ListDSRData || toggle : <Loader />}

												<div className="form-wrap mt-3">
													<div class="">
														<div class="col-md">
															<div class="row">
																<div class="col-3">
																	<div class=" ">
																		<h3 class="table-title"> DSR REPORT</h3>

																	</div>
																</div>
																<div class="col-2 "></div>
																<div class="col-1"></div>
																<div class="col-3 mt-2">
																	<label>
																		<b>
																			Start Date
															</b>
																	</label>
																</div>
																<div class="col-2 mt-2">
																	<label>
																		<b>
																			End Date
															</b>
																	</label>
																</div>
															</div>
															<div class="row mt-3">

																<div class="col-2">
																	<div className="select-container">

																		<select className="form-control"
																			title="Please Select the Product then Click Add Button"
																			name="product_id"
																			value={input.product_id}
																			onChange={handleSelect}

																		>
																			<option>Products</option>
																			{
																				product_data.map((data) => {

																					return (
																						<option
																							value={data[0]}>
																							{data[1]}
																						</option>
																					)
																				})}

																		</select>
																	</div>
																</div>
																<div class="col-2">
																	<a class="btn btn-primary "
																		title="Please Select the
															 Product then Click Add Button"
																		onClick={toggle}>
																		Add</a>
																</div>
																<div class="col-2">
																	<div className="select-container">
																		<select id="3"
																			className="form-control"
																			name="product_id"
																			value={value?.ida} onChange={handleSelect1} >
																			<option>Products</option>
																			{
																				drop.map((data) => {

																					return <option value={data[0]}>{data[1]}</option>
																				})}
																		</select>

																	</div>
																</div>
																<div class="col-3">
																	<input type="date"
																		class="form-control "
																		name="fro_date"
																		value={value?.fro_date} onChange={handles} />
																</div>
																<div class="col-3 ">
																	<input type="date"
																		class="form-control"
																		name="to_date"
																		min={value?.fro_date}
																		value={value?.to_date} onChange={handles} />
																</div>
																<div class="col-2"></div>
																<div class="col-2"></div>
																<div class="col-2"></div>
																<div class="col-2"></div>
																<div class="col-2"></div>
																<div class="col-2 text-end mt-1">
																	<a type="submit" class="btn btn-primary  " onClick={handleSubmit1}>
																		Search</a>
																</div>
															</div>
															<div class="col-1"></div>
															<div class="row mt-2">
																<div class="col-3"></div>
																<div class="col-2"></div>
																<div class="col-1"></div>


															</div>


														</div>

													</div>





													<div className="table-widget mt-5">
														<table className="table border">
															<thead>
																<tr>
																	<th>SL No.</th>
																	<th>Date</th>
																	<th>Product Name </th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>

																{
																	list_data.map((dsr, id) => (
																		<tr>
																			<td> {id + 1} </td>
																			<td>{moment(dsr.op_date).format("DD-MM-YYYY")}</td>
																			<td>{dsr?.fuel_name}</td>


																			<td class="py-2">
																				<a href="#" class="text-secondary" type="submit"
																					onClick={() => edit_DSR(dsr?.id,dsr?.product_id)} >
																					<i class="fa fa-pencil-square-o pe-2"
																						aria-hidden="true"></i></a>
																				<a href="#" class="text-secondary"
																					type="submit"
																					onClick={() => delete_DSR(dsr?.id)}>
																					<i class="fa fa-trash-o pe-2"
																						aria-hidden="true"></i></a>
																				<a href="#"
																					class="text-secondary" type="submit"
																					onClick={() => view_DSR(dsr?.id)} >
																					<i class="fa fa-eye pe-2"
																						aria-hidden="true">
																					</i>
																				</a>
																			</td>
																		</tr>
																	))
																}





															</tbody>
														</table>

														{loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}
													</div>
												</div>

											</div>

										</div>
									</>
								}

							</div>

						</div>

					</div>
				</div>
			</div>









			{isShowing ? (<Dsr
				isShowing={isShowing}
				hide={toggle}
				value={value}
				error1={error1}
				TankData={TankData}
				dip_error={dip_error}
				handleDate={handleDate}
				send_date={send_date}
				handleDip={handleDip}
				handleSubmit={handleSubmit}
				double={double}

			/>
			)
				: null
			}


			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className="modal-content">
					<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">DSR Report</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div className=" modal-body  modal-body"><p>Are you sure you want to delete this DSR Details</p></div>
					<div className="justify-content-end  modal-footer"><div className="d-flex">
						<button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						{double == true ? <button type="submit" className="btn btn-primary" onClick={deleteDSR}>Yes</button> : <button type="submit" className="btn btn-primary" disabled onClick={deleteDSR}>Yes</button>}
					</div>
					</div>
				</div>
			</Modal>


			{isOp ? (<ViewModal1
				isOp={isOp}
				hide={toggleEdit}
				nozzle_data={nozzle_data}
				tank_data={TankData}
				Others={Others}
				value={value}
			// view_DSR={view_DSR}
			/>) : null
			}

		</>
	)


}

export default DsrReportComponent