import React, { useState } from 'react';
import customer from '../../../../img/customer-review.png'

// import { API_URL } from "../../../constant/API_Settings";
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import { useEffect } from 'react';
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import museum from '../../../../img/museum.svg'
import DsrModal from './DsrModal.component'
import ViewModal from './ViewDsrModal.component'

import swal from "sweetalert";
import Loader from "../Loader.component"
import Modal from "react-modal";
import { Link } from "react-router-dom";
import logo from '../../../../img/logo.svg';
import "jspdf-autotable";


const DsrConfig = (props) => {

  const [productData, setProduct] = useState([])
  const [inputValues, setInputValues] = useState({});
  const [inputVal, setInputVal] = useState({});
  const [inputdate, setInputDate] = useState({});
  const [input, setInput] = useState({});
  const [drop, setDrop] = useState([]);
  const [TankData, setTankData] = useState([]);
  const [search, setSearch] = useState('')
  const [NozzleData, setNozzleData] = useState([]);
  const [data, setData] = useState([]);
  const history = useHistory()
  const [errors, setErrors] = useState({})
  const [err, setErr] = useState({})
  const [form_nozzle, setFuel] = useState([]);
  const [Loose, setLoose] = useState([])
  const [Packed, setPacked] = useState([])
  const [Others, setOthers] = useState([])
  const [Sales_sum, setSalesum] = useState([])
  const [Test, setTest] = useState([])
  const [Receipt, setReceipt] = useState([])
  const [inputNozzle, setInputNozzle] = useState({});
  const [inputTank, setInputTank] = useState({});
  const [list_data, setList] = useState([])
  const [delete_id, deleteId] = useState([])
  const [inputFuel, setInputFuel] = useState([])
  const [list, setListSearch] = useState([])
  const [dip, setDip] = useState([])
  const [cummulative, setCummulative] = useState([])
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState([])
  const [gain_loss, setGainLoose] = useState([])
  const [report, setReport] = useState([])
  const [total_stock, setTotalOpenStock] = useState([])
  const [total, setTotalStock] = useState([])
  const [pump, setPump] = useState([])
  const [pages, setpages] = useState(
    {
      current_page: 1,
      last_page: 0,
      total_pages: 0,
      pagination_item: [],
      total_pages: 0,
    })
  //---On Load---//		
  useEffect(() => {
    getProduct()
    const currentYear = new Date().getFullYear()
    var startyear = 2010
    var array = []
    for (var i = startyear; i <= currentYear + 2; i++) {
      array.push([i])
    }

    console.log(array)
    setData({
      ...data,
      year: array
    })
  }, [search]);


  //---Create popup function---//
  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      if (isShowing) {
        setInputValues({ vehicle_no: [''] })
        setErrors({ vehicle_no: [''] })

      }
      if (!isShowing) {

        setErrors({ vehicle_no: [''] })

      }
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggle,
    }
  };
  const { isShowing, toggle } = UseModal();
  //---Delete popup function---//
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const handleSubmit2 = (event) => {


    getDSRReport()
  }
  //------------------get fuel name data- to dropdown--------------//
  function getProduct() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },

    };
    fetch(API_URL + "/dsr", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {

        var product_details = []

        responseData.product_details.forEach(elem => {
          product_details.push([elem.id, elem.fuel_name])
        })
        setProduct(product_details);
        setLoading(true)

        var pump_address = responseData.pump_data[0].pump_address
        var pump_name = responseData.pump_data[0].pump_name


        setInputValues({
          ...inputValues,
          pump_address: pump_address,
          pump_name: pump_name
        })







        return responseData;
      })
      .catch(e => {


      });
  }



  const handleSelect1 = (e) => {
    var id = e.target.value



    setInputValues({
      ...inputValues,

      [e.target.name]: e.target.value,
      id: id

    });
  }


  const handles = (e) => {

    setInputDate({
      ...inputdate,
      [e.target.name]: e.target.value
    })
  }

  //---------------Report------------------//
  function getDSRReport() {
    var month = inputdate.month
    var year = inputdate.year
    var date = year + "-" + month + "-" + "01"
    setInputDate({
      ...inputdate,
      fro_date: date
    })
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        product_id: inputValues.id,
        fro_date: date,
        to_date: inputValues.to_date,
      }),
    };

    fetch(API_URL + "/dsr-report", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {



        if (responseData.status == false) {
          swal("", responseData.message, responseData.status ? "success" : "warning")
        }

        var others = []
        var pump_data = []
        var others = []



        var product_details = []
        //  setProduct(responseData.product_details[0].)


        responseData.pump_data.forEach(elem => {
          pump_data.push([elem.pump_name, elem.pump_address])
        })

        var pump_address = responseData.pump_data[0].pump_address
        var pump_name = responseData.pump_data[0].pump_name
        // var date =new Date(responseData.others[0].op_date)
        var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        var date1 = new Date(responseData.others[0].op_date);
        //date.toLocaleString('default',{month:'long'})
        var month = (monthNames[date1.getMonth()]);


        var fuel_name = responseData.product_details[0].fuel_name

        setInputValues({
          ...inputValues,

          month: month,
          fuel_name: fuel_name
          //
          //month:month
        })


        setOthers(responseData.others)
        setTankData(responseData.tank_data)
        setNozzleData(responseData.nozzle)



        var fuel_name = responseData.product_details[0].fuel_name
        setInput({
          ...input,
          fuel_name: fuel_name
        })
        // var nozzle_data=[]
        //  for(var i=0;i<responseData.nozzle_data.length;i++)
        //  {
        //    nozzle_data.push({
        //      nozzle_name:responseData.nozzle_data[i].nozzle_name,
        //      reading:responseData.nozzle_data[i].reading

        //    })
        //  }
        //  setNozzleData(nozzle_data)  
        //console.log(nozzle_data)

        return responseData;

      })
      .catch((e) => {

      });
  }

  function printDocument() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        product_id: inputValues.id,
        fro_date: inputdate.fro_date,
        to_date: inputValues.to_date,
      })
    };
    fetch(API_URL + "/dsr-download", requestOptions)
      .then(response => { return response.blob(); })
      .then(responseData => {
        var blob = new Blob([responseData]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "DSRReport.pdf";
        link.click();
        // link.parentNode.removeChild(link)

        // getBalanceSheet();
        // check_url()

        // window.location.reload();

        return responseData;
      })
      .catch(e => {
        console.log(e);
      });

  }

  return (
    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={7} />
          <div className="content-area">
            <LoginHeaderOperational props={props} page_title="" />
            <div className="col-md-11  mt-2">
              <Link to="/op-dsr-report">
                <a href="op-dsr-report" className="float-end btn btn-gray">Back</a>
              </Link></div>
            <div className="container pb-3 mt-2">
              {loading ? getProduct : <Loader />}
              <div className="row justify-content-center mt-md-5 mt-4 form-container">
                <div className="col-md-12 mt-4">
                  <div class="form-wrap d-flex">
                    <div class="d-flex col-6">
                      <div class="col-4">
                        <div className="select-container"><select id="3" className="form-control" name="product_id" value={inputValues.id} onChange={handleSelect1}>
                          <option>Products</option>
                          {
                            productData.map((data) => {

                              return <option value={data[0]}>{data[1]}</option>
                            })}
                        </select>

                        </div>
                      </div>

                      <div class="col-3 mx-3">
                        <div class="select-container">
                          <select id="" class="form-control highlight" name="month" value={inputdate.month} onChange={handles}>
                            <option value="select">Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>



                        {/* <input type="month" name="" class="form-control " name="fro_date" value={inputValues.fro_date} onChange={handles}/> */}
                      </div>
                      <div class="col-3 mx-1">
                        <div class="select-container">
                          <select id="" class="form-control highlight" name="year" value={inputdate.year} onChange={handles}>
                            <option value="select">Year</option>
                            {data?.year?.map((data) => {

                              return <option value={data[0]} >{data[0]}</option>
                            })
                            }
                          </select>
                        </div>
                      </div>

                      <div class="col-2 mx-4 ">
                        <a class="btn btn-primary " onClick={handleSubmit2} > Submit</a>
                      </div>
                    </div>
                  </div>
                  <div className="form-wrap mt-5 ">
                    <div className="row mb-3">
                      <div className="col-md">
                        <div className="row">



                          <div className="container pb-3">
                            <div className="row justify-content-center mt-md-5 mt-3 form-container">

                              <div className="col-md-12">
                                <div className=" ">
                                  <div className="row mb-3">
                                    <div className="col-md">




                                    </div>

                                  </div>


                                  <div className="">
                                    <div className="col-md-12">

                                      <table className="table table-bordered border sheet-table">
                                        <thead>

                                          <tr>
                                            <th colspan="30" className=" text-center">{inputValues.pump_name}</th>
                                          </tr>
                                          <tr>
                                            <th colspan="30" className=" text-center">{inputValues.pump_address}</th>
                                          </tr>
                                          <tr>
                                            <th colspan="30" className="text-center">DSR Report</th>
                                          </tr>
                                          <tr>
                                            <th colspan="30" className="text-center"> Month:{inputValues.month}</th>
                                          </tr>
                                          <tr>
                                            <td colspan="30" className="text-center">Product :<b>{input.fuel_name}</b></td>
                                          </tr>
                                          <tr>
                                            <td rowspan="3">Date</td>
                                            {/* tank_name disply */}

                                            {TankData.map((dsr, i) => {





                                              return (
                                                <>

                                                  <td colspan="2">{dsr.tank_name}-{input.fuel_name} </td>

                                                  {/* <td rowspan="3"><b>{dsr.tank_name}</b>-Physical Stock</td> */}
                                                  <td rowspan="3">{dsr.tank_name}- Opening stock</td>

                                                </>
                                              )
                                            }
                                            )}

                                            {/* end */}

                                            <td rowspan="3"> Total Opening stock</td>
                                            <td rowspan="3">Receipt(L)</td>
                                            <td rowspan="3">Total Stock</td>

                                            <td rowspan="3">Testing</td>


                                            <td rowspan="3">Sales</td>
                                            {TankData.map((dsr, i) => {




                                              return (
                                                <>

                                                  <td rowspan="3"><b>{dsr.tank_name}</b>-gain/Loss</td>

                                                </>)
                                            })}

                                            <td rowspan="3">Total stock gain/Loss</td>
                                            <td rowspan="3">Cumulative Sales</td>
                                            <td colspan="2">Total engine and gear oil sales (figs in litres)</td>
                                            <td colspan="6">Opening meter reading</td>
                                          </tr>
                                          <tr>
                                            {TankData.map((dsr, i) => {

                                              return (
                                                <>
                                                  <td rowspan="2">DIP</td>
                                                  <td rowspan="2">Water dip</td>


                                                </>
                                              )
                                            }
                                            )}





                                            <td rowspan="2">Packed</td>
                                            <td rowspan="2">Loose</td>
                                            <td colspan="6">Pumps</td>

                                          </tr>
                                          <tr>


                                            {NozzleData.map((dsr, i) => {

                                              return (

                                                <>


                                                  <td><b>{dsr.nozzle_name} </b></td>
                                                </>
                                              )
                                            }




                                            )}


                                          </tr>

                                        </thead>

                                        <tbody>








                                          {


                                            Others.map((dsr1, i) => {
                                              let dsr = dsr1['tank_data'];
                                              let nozzle_ = dsr1['nozzle_data'];

                                              return (
                                                <>

                                                  <tr>
                                                    <td>{Others[i].op_date}</td>

                                                    {
                                                      dsr.map((tnk, k) => {
                                                        return (<>
                                                          <td>{tnk.dip === null ? 0 : tnk.dip}</td>
                                                          <td>{tnk.water_dip === null ? 0 : tnk.water_dip}</td>
                                                          {/* <td>{tnk.physical_stock}</td> */}
                                                          <td>{Number(tnk.physical_stock).toFixed(2)}</td>

                                                        </>)
                                                      })
                                                    }

                                                    <td>{Number(Others[i].total_opening_stock).toFixed(2)}</td>
                                                    <td>{Others[i].receipt}</td>


                                                    <td>{Others[i].total_stock}</td>
                                                    <td>{Others[i].testing}</td>


                                                    <td>{Number(Others[i].sales).toFixed(2)}</td>

                                                    {
                                                      dsr.map((tnk, k) => {
                                                        return (<>
                                                          <td>{tnk.gain_loss}</td>

                                                        </>)
                                                      })
                                                    }

                                                    <td>{Others[i].stock_gain_loss.toFixed(2)}</td>
                                                    <td>{Others[i].cumulative_sales}</td>
                                                    <td>{Others[i].packed_sale_litre}</td>
                                                    <td>{Others[i].loose_sale_litre}</td>

                                                    {
                                                      nozzle_.map((nzl, j) => {

                                                        return (<>



                                                          <td>{nzl.reading}</td>

                                                        </>)
                                                      })
                                                    }

                                                  </tr>
                                                </>
                                              )
                                            }

                                            )
                                          }











                                        </tbody>

                                      </table>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>











                  </div>
                </div>
                <div className="text-end mt-4"><a className="btn btn-success px-3 mb-md-0 mb-2" onClick={printDocument}> Download Report</a></div>

              </div>

            </div>
          </div>
        </div>
      </div>


    </>
  )
}








export default DsrConfig;