import { Component } from "react";


class Loader extends Component
{
   
    render()
    {
    return(
        
    <div class="load-container">
	<div class="container-fluid">
<div align="center" class="fond">
  <div class="contener_general">
      <div class="contener_mixte"><div class="ballcolor ball_1">&nbsp;</div></div>
      <div class="contener_mixte"><div class="ballcolor ball_2">&nbsp;</div></div>
      <div class="contener_mixte"><div class="ballcolor ball_3">&nbsp;</div></div>
      <div class="contener_mixte"><div class="ballcolor ball_4">&nbsp;</div></div>
  </div>
</div>

	</div>
</div>
)
    }

}

export default Loader