import React from 'react';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
const format = 'HH:mm:ss '


const DigitalPaymentModalConfig = ({ isShowing,dispatchTime, hide,success,double, handleSubmit, inputValues, errors, handleInputs,handleInput }) => {
  return (
      <React.Fragment>
       <div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">
        <div className="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Digital Payment Systems</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form>
        <div className="row  mt-2">   
         <div className="form-group  d-flex">
          <label class="col-sm-5 col-form-label mb-2">Digital Payment Type <sup class="star">*</sup></label>
          <div className="col-sm-6 ">
                            <input type="text" className="form-control "  name="method_name" placeholder=""  value={inputValues.method_name} onChange={handleInputs} />
                            <div className="errorMsg">{errors.method_name}</div>
                            </div>       
                           </div>
                  
                               {/* <div className="form-group d-flex">
                               <label class="col-sm-5 col-form-label mb-2">Reminder Time for Settlement</label>
                               <div className="col-sm-6 ">
                              <div className="digitaltimepicker">
                                <TimePicker
                                  showSecond={false} 
                                  defaultValue={inputValues.settlement_time}
                                  name="settlement_time"
                                  onChange={handleInput}
                                  use12Hours
                                
                                />
                                </div>
                            {/* <input type="time" className="form-control moztime " name="settlement_time" placeholder=""  value={inputValues.settlement_time} onChange={handleInputs} /> */}
                            {/* <div className="errorMsg">{errors.settlement_time}</div>  */}
                            {/* <p className="note">Note: Notification will send based on reminder time for settlement</p>
                          </div>
                          </div>  */}
                            <div class="modal-footer">
                            {double===true ?  <button type="submit" class="btn btn-primary" onClick={handleSubmit} >Save</button>:<button type="submit" class="btn btn-primary" disabled >Save</button>}
         <p className="text-center px-5 py-3">Eg: Swiping machine, Paytm, PhonePe ,Google pay, Oxygen</p>
      </div>
</div>
</form>
</div>
</div>
 </React.Fragment>
    );
}

export default DigitalPaymentModalConfig