import React from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput,prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
import swal from 'sweetalert';
const NonFuelEdit = ({rack_error,rack_error_single,isShowing,chek_rk,handle,err,er,handleAdd,double,handleRemove,handleDelete,startDate,quantity_details, handleDate,rack_details,selected_options,handleQuantity, sales_details,fields, handleChange,handleChangeType, handleRack, handleInput, hide, handleSelect, product, selling_price, errors, success, inputValues, dropdownData, handleeditSubmit, handleInputs,forms,handleSelectProductChange,getRack,selected_products,product_error,handleRemoveForm,handleSelectBoxChange,handleRemoveSelectBox,handleAddSelectBox,handlemrpChange,godown_error,mrp_error,handleInputChange,handlediscount,handleAddForm,edit_list}) => {
  var today = new Date().toISOString().split('T')[0];
  const handleSubmit = () => {
    swal("","After approval edit is not possible","warning")
    };
  return (
        <React.Fragment>
    
    <div className="modal-overlay" />
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div className="modal modalxl">
            
            <div className="modal-body">
           
           <div class="modal-header mb-3">
           <h5 class=" text-center" id="staticBackdropLabel">Non Fuel Product direct sales</h5>
           <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
             <form novalidate onSubmit={handleSubmit}>
               <div class="row g-3  justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label mx-5 font-weight-bolder">Sales Type <sup class="star">*</sup></label>
                 </div>
                 <div class="col-6">
                   <div class="select-container">
                     <select id="" class="form-control " name="sales_type" value={inputValues.sales_type} onChange={handleChangeType}>
                       <option value="" >Select</option>
                       <option value="0">Cash Sales</option>
                       <option value="1">Credit Sales</option>
                     </select>
                   </div>
                   <div className="errorMsg">{errors.sales_type}</div>
                 </div>
                 <div className="col-1"></div>
               </div>

               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Customer Name<sup class="star">*</sup></label>
                 </div>


                 <div class="col-6">
                   <div class="select-container">
                     <select id="" class="form-control " name="customer_id" value={inputValues.customer_id} onChange={handleChange} >
                       {inputValues.sales_type == 0 ? <option>Cash</option> : <option value = "">Select</option>}
                       {inputValues.sales_type == 1 &&

                         dropdownData.map((data) => {
                           return <option value={data[0]}>{data[1]}</option>
                         })
                       }

                     </select>
                   </div>

                   <div className="errorMsg">{errors.customer_id}</div>

                 </div>
                 <div className="col-1"></div>

               </div>
               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Description</label>
                 </div>
                 <div class="col-6">
                   <input type="text" class="form-control " name="note" value={inputValues.note} onChange={handle} />
                 </div>
                 <div className="col-1"></div>
               </div>

               {forms.map((form, formIndex) => (
                 <div key={formIndex}>
                   <h3>Product {formIndex + 1}</h3>
{/* {console.log(forms,"formssssss")} */}
                   <div class="row g-3 justify-content-center mb-2">
                     <div class="col-5">
                       <label class="col-form-label font-weight-bolder mx-5">Product Name <sup class="star">*</sup></label>
                     </div>
                     <div class="col-6">
                       <div class="select-container">
                         <select class="form-control"
                           value={form.product_data}
                           onChange={(e) => {
                             handleSelectProductChange(formIndex, e.target.value);


                             getRack(formIndex, e.target.value);
                           }}
                         >
                           {/* Options for the select box */}
                           <option value="">Select product</option>
                           {product?.map((item) => (
                             <option class={selected_products.includes(item.value) == true ? "d-none" : ''} key={item.value} value={item.value}>
                               {item.label}
                             </option>
                           ))}
                         </select>
                         
                         <div className="errorMsg">{product_error[formIndex]}</div>
                       </div>
                     </div>

                   </div>
                   {forms.length > 1 && (
                   <button type="button" onClick={() => handleRemoveForm(formIndex)}>
                     Remove product
                   </button>
                   )}


{/* {console.log(form.rack_data,"length")} 
{console.log(form.rack.length,"length")} */}
                   {form.rack?.length !== 0 && (
                     <>
                       {" "}
                       {form.rack_data.map((option, optionIndex) => (
                         <div class="row g-3 mt-1 justify-content-center mb-2" key={optionIndex}>
                           <div class="col-5">
                             <label class="col-form-label font-weight-bolder mx-5">Storage</label>
                           </div>
                           <div class="col-6">

                             <div class="select-container">
                               <select
                                 class={"form-control rack"+formIndex}
                                 value={option?.rack_id}
                                 name="rack_id"
                                 onChange={(e) =>
                                   handleSelectBoxChange(
                                     formIndex,
                                     optionIndex,
                                     e.target.value,
                                     "rack_id"
                                   )
                                 }
                               >


                                 {/* Options for the select box */}
                                 <option value="">Select a Rack</option>
                                 {form?.rack?.map((item) => {

                                   return (
                                     <>
                                       <option class={form.chek_rk.includes(item?.id) == true ? "d-none" : ''} key={item.id} value={item?.id}>
                                         {item.rack_name + ' (' + item.stock + ')'}
                                       </option>
                                     </>
                                   );
                                 })}
                               </select>
                             </div>
                           </div>


                           <div class="row g-3 justify-content-center mb-2">
                             <div class="col-5">
                               <label class="col-form-label font-weight-bolder mx-5">Quantity (No) </label>
                             </div>
                             <div class="col-6">
                               <input
                                 class="form-control hide-arrow "
                                 type="number"
                                 name="rack_q"
                                 value={option.rack_q}
                                 onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)}
                                 onChange={(e) =>
                                   handleSelectBoxChange(
                                     formIndex,
                                     optionIndex,
                                     e.target.value,
                                     "rack_q"
                                   )
                                 }
                               />
                             </div>
                             {/* {console.log(form.chek_rk[formIndex],"form.quantity_error_data[formIndex]form.quantity_error_data[formIndex]")} */}
                             {/* <div className="errorMsg">{form.quantity_error_data[1]}</div> */}
                           </div>
                           <button
                             type="button"
                             onClick={() =>
                               handleRemoveSelectBox(formIndex, optionIndex)
                             }
                           >
                             Remove Rack
                           </button>
                           
                   
                         </div>
                         
                       ))}
                     </>
                   )}
                   {/* {console.log(form.rack_data.length,"kkkkkkkkkkkkkkkkkk")} */}
           {form.rack?.length !== 0 &&(form.rack_data.length != form.rack?.length) && (
<button type="button" onClick={() => handleAddSelectBox(formIndex)}>
                     Add Rack
                   </button>)}
                   <div className="errorMsg">{rack_error[formIndex]}</div>
                    <div className="errorMsg">{rack_error_single[formIndex]}</div>

                   

                   <div class="row g-3 justify-content-center  mb-2">

                     <div class="col-5">
                       <label class="col-form-label font-weight-bolder mx-5">Mrp</label>
                     </div>
                     <div class="col-5">
                       <input
                         class="form-control hide-arrow "
                         type="number"
                         value={form.mrp}
                         onChange={(e) => handlemrpChange(formIndex, e.target.value)}
                         onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
                       />
                     </div>
                    
                     <div className="errorMsg">{mrp_error[formIndex]}</div>
                   </div>


                   <div>
                     <div class="row g-3 justify-content-center  mb-2">
                       <div class="col-5">
                         <label class="col-form-label font-weight-bolder mx-5">Sales from Godown Stock</label>
                       </div>
                       <div class="col-6">
                         <input
                           type="number"
                           class="form-control hide-arrow "
                           value={form.sales_from_godown}
                           onChange={(e) => handleInputChange(formIndex, e.target.value)}
                           onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)}
                         />
                       </div>
                       <div className="errorMsg">{godown_error[formIndex]}</div>
                     </div> 
                   </div>
                   <hr />

                 </div>
               ))}

         <div class="row g-3 justify-content-center mb-2">
             <div class="col-5">
               <label class="col-form-label font-weight-bolder mx-5">Operational Date<sup class="star">*</sup></label>
             </div>
             <div class="col-6">
               <input type="date" class="form-control " name="op_date" id={"m_op_date"}  value={inputValues.op_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :inputValues.op_date} min={startDate} max={today} onChange={handleDate}  />
               <div className="errorMsg">{errors["op_date"]}</div>
             </div>
             <div className="col-1"></div>
           </div>

               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Sub Total</label>
                 </div>
                 <div class="col-6">
                   <input type="text" class="form-control " name="subtotal" value={inputValues.subtotal} disabled />
                 </div>
                 <div className="col-1"></div>
               </div>

               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Discount</label>
                 </div>
                 <div class="col-6">
                   <input type="number" 
                   step="any"
                   class="form-control hide-arrow" 
                   name="discount"
                   id="discount"
                     value={inputValues.discount}
                     onChange={handlediscount}
                     onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}

                   />

                 </div>

                 <div className="col-1"></div>
               </div>

               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Total</label>
                 </div>
                 <div class="col-6">
                   <input disabled type="number" class="form-control hide-arrow" name="total" value={inputValues.total} />
                 </div>
                 <div className="col-1"></div>
               </div>

               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Invoice Amount</label>
                 </div>
                 <div class="col-6">
                   <input disabled type="number" class="form-control hide-arrow" name="amt_aft_discound" value={inputValues.amt_aft_discound} />
                 </div>
                 <div className="col-1"></div>
               </div>

               <div class="row g-3 justify-content-center mb-2">
                 <div class="col-5">
                   <label class="col-form-label font-weight-bolder mx-5">Roundoff</label>
                 </div>
                 <div class="col-6">
                   <input disabled type="number" class="form-control hide-arrow" name="roundoff" value={inputValues.roundoff} />
                 </div>
                 <div className="col-1"></div>
               </div>

               <button type="button" onClick={handleAddForm}>
                 Add Product
               </button>
               {/* <button type="submit">Submit</button> */}
             </form>
           </div>
              <div class="modal-footer text-center">

<a href="#" class="btn btn-danger w-auto" onClick={hide}>Cancel</a>
{edit_list.status == 3 ? <a href="#" class="btn btn-primary  w-auto " onClick={handleSubmit}>Save</a>:<a href="#" class="btn btn-primary  w-auto " onClick={handleeditSubmit}>Save</a>}
</div>
            </div>
          </div>
      
  
      
  
  
  
      </React.Fragment>

    );
}
export default NonFuelEdit 