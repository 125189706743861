import { extend } from "jquery";
import React, { Component } from "react";
import Sidebar_Progress from "../../../layouts/Admin_Sidebar_Progress.component";
import { API_URL } from "../../../../constant/API_Settings";
import axios from 'axios';
import swal from "sweetalert";
import { Modal, ModalFooter } from "react-bootstrap";
import ManagerLoginHeader from '../../Manager/ManagerLoginHeader.component'
import Loader from '../../Employee/Loader.component'

class UsersManager extends Component
{
    constructor(props)
    {
        super(props)
        this.state={
        user_result:[],
        currentPage:1,
        lastPage:0,
        role_data:{},
        total_pages:0,
        search_keyword: '',
        status:0,
        message:'',
        pagination_items:[],
        user_fields:{
        
        },
        user_form_errors:{},
        delete_userId:'',
        deactivate_userId:'',
        modalShow: false,
        showDeletemodel:false,
        showDeactivatemodel:false,
        deactivate_status:'',
        loading:false,
        errors:[]
        }
        
    }

    componentDidMount()
    {
      this.check_url()
    }
    //------------------------url
     check_url() 
     {
       
     let user_fields = this.state.user_fields;
      if(localStorage.getItem("admin_pump_id"))
      {
        user_fields['admin_pump_id']=localStorage.getItem("admin_pump_id")

        this.setState({user_fields:user_fields})

        this.callUser()
        this.getRole()
			}
			else{
				
				this.props.history.push("/pump_name");
			}
		}


//-------------------end
handleuserInputChange = (event) => {


    
  let user_fields = this.state.user_fields;
 
  user_fields[event.target.name] = event.target.value;
  this.setState({
    user_fields: user_fields
  });

}
handleuserInputChange1 = (event) => {

  console.log(this.state.role_data[3].id == event.target.value)
  console.log( event.target.value)
  this.state.role_data.map((elem)=>{
    if(elem.id == event.target.value)
    {
     var star1 = true
     this.setState({mandatory: star1})
   
    }
    else{
      var star1 = false
      this.setState({mandatory: star1})
    }
  })

     
     let user_fields = this.state.user_fields;
    
     user_fields[event.target.name] = event.target.value;
     this.setState({
       user_fields: user_fields
     });
 
   }
    handleuserInputChange = (event) => {
      console.log(event.target.value)
      if(event.target.value==35)
      {
       var star1 = document.getElementsByClassName('star');
       this.setState({mandatory:star1[0]})
     
      }
      let user_fields = this.state.user_fields;
      user_fields[event.target.name] = event.target.value;
      this.setState({
        user_fields: user_fields
      });   
       
    }
    validate_user_form=()=>{
      var validation_form = true;
      var { user_form_errors } = this.state;
     
      // if((this.state.user_fields.debit_credit ) && (!this.state.user_fields.opening_balance)){
      //   validation_form=false
      //   // user_form_errors["debit_credit"]="Enter Opening Balance"
      // }
      // if(this.state.user_fields.opening_balance !=undefined){
      //   // if((this.state.user_fields.opening_balance !="" ) && (this.state.user_fields.debit_credit == undefined || this.state.user_fields.debit_credit === "" ))
      //   // {
      //   //   validation_form=false
      //   //   user_form_errors["debit_credit"] = " Select Debit/Credit" 
      //   // }
      
       
      // }
      // if((this.state.user_fields.debit_credit ) && (!this.state.user_fields.opening_balance)){
      //   validation_form=false
      //   user_form_errors["debit_credit"]="Enter Opening Balance"
      //   }
      // if(this.state.user_fields.phone==="" )
      // {
      //   validation_form=false
      //   user_form_errors['phone']="please enter phone number"
      // }
      // else if(this.state.user_fields.phone.toString().length!=10)
      // {
        
      //   validation_form=false
      //   user_form_errors['phone']="please enter the phone number in 10 dights"
      // }
      // else{
      //   user_form_errors['phone']="" 
      // }
      // if(this.state.user_fields.email==="")
      // {
      //   validation_form=false
      //   user_form_errors['email']="please enter email"
      // }
      // else{
      //   user_form_errors['email']="" 
      // }
      // if(this.state.user_fields.user_name==="")
      // {
      //   validation_form=false
      //   user_form_errors['user_name']="please enter username"
      // }
      // else{
      //   user_form_errors['user_name']="" 
      // }
      //   if(this.state.user_fields.id==undefined || this.state.user_fields.id==""){

      // if(this.state.user_fields.password==="" )
      // {
      //   validation_form=false
      //   user_form_errors['password']="please enter password"
      // }
      // else if(this.state.user_fields.password.toString().length<8)
      // {
      //   validation_form=false
      //   user_form_errors['password']="please enter password in 8 characters"
      // }
      // else{
        
      //   user_form_errors['password']=""
      // }
      
    
      // }

      // if(this.state.user_fields.role_id==="")
      // {
      //   validation_form=false
      //   user_form_errors['role_id']="please select role"
      // }
      // else{
      //   user_form_errors['role_id']="" 
      // }
      this.setState({user_form_errors:user_form_errors})
      return validation_form
    }
    createuser=()=>{
    axios({
      url: API_URL+"/user",
      method: "POST",
      data: this.state.user_fields,
      headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type":"application/json",
      "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
      "Accept": "application/json"
      }
   }).then(res=>{

     if(res.data.status===false)
     {
       var user_form_errors = []
     
     
       user_form_errors['opening_balance']=res.data.response.opening_balance
       for(var key in res.data.response)
       {

         if(key==='email')
         {
          user_form_errors['email']=res.data.response[key][0]
         }
         if(key==='name')
         {
           user_form_errors['name']=res.data.response[key][0]
         }
         if(key==='user_name')
         {
          user_form_errors['user_name']=res.data.response[key][0] 
         }
         if(key==='phone')
         {
           user_form_errors['phone']=res.data.response[key][0]
         }
         if(key==='password')
         {
           user_form_errors['password']=res.data.response[key][0]
         }
         if(key==='role_id')
         {
           user_form_errors['role_id']=res.data.response[key][0]
         }
         if(key==='debit_credit')
         {
           user_form_errors['debit_credit']=res.data.response[key][0]
         }
       }
      //  swal("",res.data.response.message,res.data.success?"warning":"success")  
       this.setState({user_form_errors:user_form_errors})

     }
     if(res.data.status == true)
     {
      var user_fields = this.state.user_fields  
      user_fields.password=""
    
    
      this.setState({
      
        user_fields: user_fields
    })
       this.setState({modalShow:false })
      //  this.setState({user_form_errors:[""]})
  
       swal("",res.data.message,res.data.success?"warning":"success") 
       
       this.componentDidMount()
     }
     else{
    
     }
   


      }).catch(err=>{
         
      })
     
    }
    handleusersubmit=(event)=>{
  
      event.preventDefault();
      // this.setState({ user_form_errors:[""]})
      if(this.validate_user_form())
      {
        this.createuser()
      }
     

    }
   
handleSearch = (event) =>
{
  console.log(event.target.value)
  console.log(event.target.name)
   this.setState({
    [event.target.name]:event.target.value
   })
}
    edit_user=(id,ac_name,ac_name1)=>{
      this.state.user_form_errors =""
      this.setState({user_id:id})
        axios({
          url: API_URL+"/user/"+id+"/edit",
          method: "GET",
          data: {
                  id:id,
                  ac_name:ac_name,
                  ac_name1:ac_name1,
                  admin_pump_id:localStorage.getItem("admin_pump_id")
                  },
          headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type":"application/json",
          "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
          "Accept": "application/json"
          }
       }).then(res=>{
        var user_fields  =this.state.user_fields
        user_fields['id']=res.data.data.id ? res.data.data.id : ''
        user_fields['ac_name']=res.data.data.ac_name ? res.data.data.ac_name : ''
        user_fields['ac_name1']=res.data.data.ac_name1 ? res.data.data.ac_name1 : ''
        user_fields['name']=res.data.data.name
        user_fields['password']=res.data.data.password
        user_fields['phone']=res.data.data.phone
        user_fields['user_name']=res.data.data.user_name
        user_fields['op_date']=res.data.data.ac_date
        user_fields['email']=res.data.data.email
        user_fields['role_id']=res.data.data.role_id
        user_fields['address']=res.data.data.address 
        user_fields['designation']=res.data.data.designation
        user_fields['debit_credit']=res.data.data.debit_credit 
        user_fields['opening_balance']=res.data.data.opening_balance

        this.setState({user_fields:user_fields,modalShow:true})
        // this.setModalShow(true)
    
          }).catch(err=>{
             
          })
         
    }
  
    getRole=()=>{
    var { role_data } = this.state
    axios({
       url:`${API_URL}/get-role?admin_pump_id=${localStorage.getItem("admin_pump_id")}`,
            
       method: "GET",
       headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
       },
       data: {
        admin_pump_id:localStorage.getItem("admin_pump_id")
        },
    }).then(res=>
    {
      this.setState({role_data:res.data})

     
    }).catch(error=>{
       console.log(error)
    })
  }
    callUser=()=>{
             axios(
          {   
              url:`${API_URL}/user?search_keyword=${this.state.search_keyword}&page=${this.state.currentPage}&admin_pump_id=${localStorage.getItem("admin_pump_id")}`,
              method: "get",
              // data: {
              //   search_keyword:
              // },
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type":"application/json",
                "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
                "Accept": "application/json"
            }
          }
      ).then(res=>{
        if(res.data.status===3)
        {
          this.setState({
          status:res.data.status,message:res.data.message
          })
        }
  
         this.setState({
           user_result: res.data.data.data,
           currentPage:res.data.data.current_page,
           owner_status:res.data.data.data[res.data.data.data.length-1].id,
           lastPage:res.data.data.last_page,
           total_pages:res.data.data.last_page,
          });
          var total_pages = this.state.total_pages
           
          var items = []
         for (let number = 1; number <= total_pages; number++) {
           number===this.state.currentPage?items.push(
               <li key={number} className="page-item active"><a class="page-link" onClick={()=>this.handlePagination(number)}>{number}</a></li>
               ,):items.push(
               <li key={number} className="page-item"><a class="page-link" onClick={()=>this.handlePagination(number)}>{number}</a></li>
               ,)
                }
                this.setState(
                    {
                        'pagination_items': items
                    }
                )
this.setState({
  'loading':true
})

        }).catch(err=>{
           
        })
      }

        

    handlePagination = (number) =>
    {
        this.state.currentPage = number
        this.componentDidMount()
    }
  
    setModalShow(event,show){
      
      let user_form_errors=[]
  var user_fields = this.state.user_fields
  user_fields.name=""
  user_fields.id=""
 user_fields.phone=""
user_fields.email=""
  user_fields.password=""
 user_fields.user_name=""
 user_fields.total_stock=""
 user_fields.op_date=""
 user_fields.role_id=""
  user_fields.address=""
 user_fields.designation=""
  user_fields.opening_balance=""
  user_fields.debit_credit=""
  this.state.mandatory=""

    this.setState({
      user_form_errors,
      user_fields:user_fields,
      modalShow:show

    })
    }
    setDeleteModalShow(event,show)
    {
      this.setState({
        showDeletemodel:show
      })
    }
    delete_user=(id)=>{

      this.setState({delete_userId:id,showDeletemodel:true})
 
      
    }
    setDeactivateModalShow(event,show)
    {
      this.setState({
        showDeactivatemodel:show,
        
      })
    }
    change_status=(id,status)=>{

      this.setState({deactivate_userId:id,showDeactivatemodel:true,deactivate_status:status})
 
      
    }
/////Password///

setPasswordModalShow(event, show) {
  var user_fields = this.state.user_fields  
  user_fields.password=""
user_fields.password_confirmation=""

  this.setState({
    showPasswordmodel: show,
    errors:[],
  
    user_fields: user_fields
})
}
    edit_password=(id, password)=>{

      this.setState({edit_passwordId:id,showPasswordmodel:true})
 
      
    }

    submitpassworduser=()=>{
      var user_id= this.state.edit_passwordId
      console.log("wasasdsd")
      console.log(this.state.user_fields);
      axios({
        url: API_URL+"/change-password",
        method: "POST",
        data: {id:user_id,password: this.state.user_fields['password'],password_confirmation: this.state.user_fields['password_confirmation']},
        headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
        }
     }).then(res=>{
      if(res.data.status==true)
      {
        this.setState({edit_passwordId:'',showPasswordmodel:false})
        swal("",res.data.message,res.data.status?"success":"warning") 
        var user_fields = this.state.user_fields  
        user_fields.password=""
      user_fields.password_confirmation=""
      
        this.setState({
        
          user_fields: user_fields
      })
        this.componentDidMount()
      }
      let errors = []
      if(res.data.status == false)
      {
        
        errors['password'] = res.data.response.password
      }
      this.setState({errors:errors})
  
        }).catch(err=>{
           
        })
       

    }


    setPasswordmodel = (props) => {
      return (
        <Modal 
        {...props}
        
        >
        <Modal.Header className="modal-title">
        <h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event)=>this.setPasswordModalShow(event,false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
         <form> 
        <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" name="password" value={this.state.user_fields.password} onChange={this.handleuserInputChange}   />
            <div className="errorMsg">{this.state.errors.password}</div>
            
        </div>
        <div className="form-group">
              <label>Confirm Password</label>
            <input type="password" className="form-control" name="password_confirmation" value={this.state.user_fields.password_confirmation} onChange={this.handleuserInputChange}   />
            <div className="errorMsg">{this.state.errors.password_confirmation}</div>
       </div>
     </form>

        </Modal.Body>
        <ModalFooter className="justify-content-center">
      {/*<div className="modal-footer text-center">*/}
        <button type="submit" onClick={this.submitpassworduser} className="btn btn-primary">Submit</button>
      {/*</div> */}
      </ModalFooter>
        </Modal>
      )
}


///////////////
    submitdeactivateuser=()=>{
      var user_id= this.state.deactivate_userId
      var status =this.state.deactivate_status

      axios({
        url: API_URL+"/user/deactivate",
        method: "post",
        data: {user_id,status},
        headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
        }
     }).then(res=>{
       console.log(res.data.status)
      if(res.data.status===true)
      {
        this.setState({deactivate_userId:'',showDeactivatemodel:false,deactivate_status:''})

        swal("",res.data.message,res.data.status===true?"success":"warning") 
        this.componentDidMount()
      }
      
      // this.setModalShow(true)
  
        }).catch(err=>{
           
        })
       

    }
    submitdeleteuser=()=>{
      var user_id= this.state.delete_userId
      axios({
        url: API_URL+"/user/"+user_id,
        method: "DELETE",
        data: {id:user_id},
        headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
        }
     }).then(res=>{
      if(res.data.status==true)
      {
        this.setState({delete_userId:'',showDeletemodel:false})
        swal("",res.data.message,res.data.success?"warning":"success") 
        this.componentDidMount()
      }
      
      // this.setModalShow(true)
  
        }).catch(err=>{
           
        })
       

    }
    UserDeleteModal = (props) => {
          return (
            <Modal 
            {...props}
            
            >
            <Modal.Header className="modal-title">
            <h5 className="modal-title" id="exampleModalLongTitle">Users</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event)=>this.setDeleteModalShow(event,false)}>
              </button>
            </Modal.Header>
            <Modal.Body className=" modal-body ">
              <p>Are you sure you want to delete this user</p>
            </Modal.Body>
            <ModalFooter className="justify-content-end ">
            <div className="d-flex">
            <button type="button"  onClick={(event)=>this.setDeleteModalShow(event,false)} className="btn btn-secondary me-2">No</button>
            <button type="button" onClick={()=>this.submitdeleteuser()} className="btn btn-primary">Yes</button>
            </div>
              </ModalFooter>
            </Modal>
          )
    }
    UserDeactivateModal = (props) => {
      return (
        <Modal 
        {...props}
        
        >
        <Modal.Header className="modal-title">
        <h5 className="modal-title" id="exampleModalLongTitle">Users</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event)=>this.setDeactivateModalShow(event,false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <p>Are you sure you want to {this.state.deactivate_status==1 ? 'Active': 'Deactive'} this user</p>
        </Modal.Body>
        <ModalFooter className="justify-content-end">
         <div className="d-flex">
        <button type="button"  onClick={(event)=>this.setDeactivateModalShow(event,false)} className="btn btn-secondary me-2">No</button>
        <button type="button" onClick={()=>this.submitdeactivateuser()} className="btn btn-primary">Yes</button>
        </div>
          </ModalFooter>
        </Modal>
      )
}
    MyVerticallyCenteredModal = (props) => {

      return (
    <Modal
    {...props}  
        
      >  
    {/*<div className="modal fade" id="edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">*/}
 {/*} <div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content"> */}
      {/*<div className="modal-header">*/}
      <Modal.Header className="modal-title">
        <h5 className="modal-title" id="exampleModalLongTitle">Users</h5>
        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event)=>this.setModalShow(event,false)}>
        </button>
        </Modal.Header>

    {/*}  </div> */}
    <Modal.Body className=" modal-body ">
    {/*  <div className="modal-body"> */}
      <div className="form-group">
        <label>Name<sub className="star">*</sub></label>
       <input type="text" className="form-control" name="name" value={this.state.user_fields.name} onChange={this.handleuserInputChange}  />
       <div className="errorMsg">{this.state.user_form_errors.name}</div>
     </div>
     <div className="dropdown">
           <label>Role<sub className="star">*</sub></label>
           {this.state.owner_status==this.state.user_id?
           <div className="select-container">
              <select
                            className="form-control"
                            disabled
                            onChange={this.handleuserInputChange1}
                            name="role_id"
                            value={this.state.user_fields.role_id}
                          >
                            <option value="">select role</option>
                    {
                      
                      Object.keys(this.state.role_data).length != 0 ?
                          this.state.role_data.map((obj,index)=>{
                        return  this.state.user_fields.role_id==obj.id ?  
                        <option  selected value={obj.id}>{obj.role_name}</option> :  
                        <option value={obj.id}>{obj.role_name}</option>
                      }) :''
                    }

              </select>
           </div>:
             <div className="select-container">
             <select
                           className="form-control"
                       
                           onChange={this.handleuserInputChange1}
                           name="role_id"
                           value={this.state.user_fields.role_id}
                         >
                           <option value="">select role</option>
                   {
                     
                     Object.keys(this.state.role_data).length != 0 ?
                         this.state.role_data.map((obj,index)=>{
                       return  this.state.user_fields.role_id==obj.id ?  
                       <option  selected value={obj.id}>{obj.role_name}</option> :  
                       <option value={obj.id}>{obj.role_name}</option>
                     }) :''
                   }

             </select>
          </div>}
            <div className="errorMsg">{this.state.user_form_errors.role_id}</div>
        </div>
     <div className="form-group">
       <label>Phone Number<sub class={this.state.mandatory==true ? "d-none" : "star"}>*</sub></label>
       <input type="number"  className="form-control hide-arrow  ph_no" name="phone" value={this.state.user_fields.phone}  onChange={this.handleuserInputChange}   />
       <div className="errorMsg">{this.state.user_form_errors.phone}</div>
     </div>
     {/* <div className="form-group">
  <label>Address</label>
  <input type="text" className="form-control" name="address" value={this.state.user_fields.address} onChange={this.handleuserInputChange} />
  </div> */}
     <div className="form-group">
       <label>Email<sub class={this.state.mandatory==true ? "d-none" : "star"}>*</sub></label>
       <input type="text" className="form-control" name="email" value={this.state.user_fields.email} onChange={this.handleuserInputChange}    />
       <div className="errorMsg">{this.state.user_form_errors.email}</div>
     </div>
     <div className="form-group">
       <label>Date</label>
       <input type="date" className="form-control" name="op_date" value={this.state.user_fields.op_date} onChange={this.handleuserInputChange}    />
       <div className="errorMsg">{this.state.user_form_errors.date}</div>
     </div>
     {/* <div className="form-group">
  <label>Designation</label>
  <input type="text" className="form-control" name="designation" value={this.state.user_fields.designation} onChange={this.handleuserInputChange} />
  </div> */}
  

     <div className="form-group">
       <label>Username<sub class={this.state.mandatory==true ? "d-none" : "star"}>*</sub></label>
       <input type="text" className="form-control" name="user_name" value={this.state.user_fields.user_name} onChange={this.handleuserInputChange}    />
       <div className="errorMsg">{this.state.user_form_errors.user_name}</div>
     </div>
     {
       !this.state.user_fields.id ? 
     <div className="form-group">
       <label>Password<sub class={this.state.mandatory==true ? "d-none" : "star"}>*</sub></label>
       <input type="password" className="form-control"  autoComplete="new-password" name="password" value={this.state.user_fields.password} onChange={this.handleuserInputChange}   />
       <div className="errorMsg">{this.state.user_form_errors.password}</div>
     </div> : ''

    }
     <div className="form-group">
        
    
          <div class="form-group mb-3">
            <label>Address </label>
            <textarea className="form-control" name="address" value={this.state.user_fields.address} onChange={this.handleuserInputChange}></textarea>
            <div className="errorMsg">{this.state.user_form_errors.address}</div>

            <label>Designation </label>
            <input type="text" className="form-control" name="designation" value={this.state.user_fields.designation} onChange={this.handleuserInputChange}   />
            <div className="errorMsg">{this.state.user_form_errors.designation}</div>
         
            <div class="row">
            <div class="col-md-6 ">
                <div class="form-group">
                  <label>Opening balance</label>
                    <input type="number" class="form-control hide-arrow"  name="opening_balance" value={this.state.user_fields.opening_balance} onChange={this.handleuserInputChange}/>
                    <div className="errorMsg">{this.state.user_form_errors.opening_balance}</div>
                </div>
              </div>

              <div class="col-md-4 d-flex">
                <div class="form-check col-sm mt-4 pr-0">
                 
                  <input class="form-check-input" type="radio"  id="exampleRadios1"  name="debit_credit" value="Cr" onChange={this.handleuserInputChange} checked={this.state.user_fields.debit_credit === 'Cr'}/>
                  <label class="form-check-label col-form-label" for="exampleRadios1">   Credit 
                  </label>
                </div>
                <div class="form-check col-sm mt-4 pr-1">
                  <input class="form-check-input" type="radio"  id="exampleRadios1"  name="debit_credit" value="Dr" onChange={this.handleuserInputChange} checked={this.state.user_fields.debit_credit === 'Dr'} />
                  <label class="form-check-label col-form-label" for="exampleRadios1">  Debit
                  </label>
                </div>
             
              </div>
              <div className="errorMsg">{this.state.user_form_errors.debit_credit}</div>
            </div>
          </div> 
             
     

       </div>

     {/* </div> */}
     </Modal.Body>
      <ModalFooter className="justify-content-center">
      {/*<div className="modal-footer text-center">*/}
     
        <button type="button" onClick={this.handleusersubmit} className="btn btn-primary">Save User</button>
      {/*</div> */}
      </ModalFooter>
   {/* </div> 
  </div>
</div>*/}
</Modal>
		)}

    render()
    {
        return(<>
        <div className="main-container page-wrap">
	<div className="d-md-flex">
  <Sidebar_Progress progress ={20}/>
      <div className="content-area">
    	<ManagerLoginHeader page_title="" />
        <div className="container pb-3">

 

	<div className="row justify-content-center mt-md-5 mt-3 form-container">
		<div className="col-md-12">
			  {this.state.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{this.state.message}
											</div></div>:
											<>
<div>
{this.state.loading ? this.callUser :<Loader />}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							
						</div>
							<div className="col pl-0">
							<h3 className="table-title">User Management</h3>
						</div>
						<div className="col-md">
							<a  onClick={(event)=>this.setModalShow(event,true)} className="btn btn-primary px-3 mb-md-0 mb-2 ">Add USER</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search" name="search_keyword" aria-describedby="basic-addon1" value={this.state.search_keyword}  onChange={this.handleSearch} 
    
/>
<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={this.check_url} >Submit</a>
</div>
					</div>
				</div>


				<div className="table-widget">
					<table className="table border">
          
  <thead>
    <tr>
      <th>Name</th>
      <th>Phone Number</th>
      <th>Email Address</th>
      <th>Username</th>
      
      <th >Date</th>
      <th >Default (Owner)</th>
      <th >Status</th>
      <th >Actions</th>
    </tr>
  </thead>
  {this.state.user_result.map((user,index)=>{
    let userStatus = user.status == 1 ? 2: 1;
    // console.log(user.role.role_name)
    return (

 
  <tbody>
    
    <tr>
      <td>{user.name}</td>
      <td>{user.phone}</td>
      <td>{user.email}</td>
      <td>{user.user_name}</td>
      <td>{user.ac_date}</td>
   
       <td><a className="btn btn-success" href="#"> {user.role ? user.role.role_name : "Deafult Owner" } </a></td>
       <td>{user.status == 1 ? "Active" : "Inactive" }</td>
      <td><div className="d-flex icon-btn">
      <button  data-bs-toggle="tooltip"data-bs-placement="bottom" onClick={()=>this.edit_user(user.id,user.ac_name,user.ac_name1)} title="Edit">	<i className="fa fa-pencil" aria-hidden="true"></i>
</button>
{user.user_name ?<button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={()=>this.edit_password(user.id)} title="Change Password" >	<i className="fa fa-key" aria-hidden="true"></i>
</button>:""}
 {
  user.role.role_slug != "owner" ? <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={()=>this.delete_user(user.id)} title="Delete" >	<i className="fa fa-trash" aria-hidden="true"></i>

</button>  : ''
}
      
{ user.role.role_slug != "owner" ? <div className="dropdown">
  <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
  </button> 
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">{

  }
    <li><a className="dropdown-item" onClick={()=>this.change_status(user.id, userStatus)} href="#"><i className="fa fa-user me-2" aria-hidden="true"></i> {(user.status==1) ? 'Deactvate' : 'Activate'} user</a></li>
  
    
  </ul>
</div>

:''}
      </div></td>
    </tr>
  
</tbody>
  )})}
</table>
<nav aria-label="Page navigation example">
    <ul className="pagination justify-content-end">
    {this.state.total_pages>1 && this.state.pagination_items}
      </ul>
    </nav>
				</div>
			</div>
      <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => this.props.history.push("/customers")}>Continue</a></div>
		</div>
 </>}
	</div></div>
</div>


</div>
</div>
</div>
<this.UserDeactivateModal
                  show={this.state.showDeactivatemodel}
                  selectedvalue={this.state.selectedvalue}
                  selectedid={this.state.selectedid}
                  onHide={(event) => this.setDeleteModalShow()}
                  data={this.state}
                />
<this.UserDeleteModal
                  show={this.state.showDeletemodel}
                  selectedvalue={this.state.selectedvalue}
                  selectedid={this.state.selectedid}
                  onHide={(event) => this.setDeleteModalShow()}
                  data={this.state}
                />
<this.MyVerticallyCenteredModal
                  show={this.state.modalShow}
                  selectedvalue={this.state.selectedvalue}
                  selectedid={this.state.selectedid}
                  onHide={(event) => this.setModalShow()}
                  data={this.state}
                />
                <this.setPasswordmodel
                  show={this.state.showPasswordmodel}
                  selectedvalue={this.state.selectedvalue}
                  selectedid={this.state.selectedid}
                  onHide={(event) => this.setPasswordModalShow()}
                  data={this.state}
                /> 

</>
        )
    }
}

export default UsersManager