import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import LoginHeaderOperational from '../../LoginHeaderOperational';
import FuelSellPriceChangeModal from './FuelSellPriceChangeModal.component'
import FuelSellPriceEditChangeModal from './FuelSellPriceEditChangeModal.component'
import Loader from '../../Employee/Loader.component'
import moment from 'moment';

const FuelModal = (props) => ReactDOM.createPortal(
	<FuelSellPriceChangeModal  {...props} />, document.body
);
const FuelEditModal = (props) => ReactDOM.createPortal(
	<FuelSellPriceEditChangeModal  {...props} />, document.body
);
const FuelSellPriceConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState([]);
	const [input, setInput] = useState([]);
	const [fields, setFields] = useState(['']);
	const [edit_fields, setEditFields] = useState(['']);

	const [tank_config, setTank] = useState([]);
	const [edit_tank_config, setEditTank] = useState([]);

	const [nozzle_config, setNozzle] = useState(['']);
	const history = useHistory()
	const [update_data, setData] = useState([])
	const [edit_update_data, setEditData] = useState([])
	const [nozzle_data, setNozzleData] = useState([])
	const [stock, setStock] = useState(0)
	const [editStock, setEditStock] = useState(0)

	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState([])
	const [fuelData, setFuelData] = useState([])
	const [sellingData, setSellingData] = useState([])
	const [loading, setLoading] = useState(false)
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		check_url()

	}, [search]);

	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				console.log(responseData)
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_fuel_date();
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}

	function check_meta_fuel_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_fuel_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (!responseData.next_date) {
					history.push("/operational-date");
				}
				else {
					setInput({ ...input, next_date: responseData.next_date })
					getOwnFuel(responseData.next_date);
				}

				return responseData;
			})
			.catch(e => {
			});

	}
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				// setInputValues({  })
				setErrors({})
				setSuccess({})
				setFields([''])
				setEditTank([''])
			}
			if (!isShowing) {

				setErrors({})

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const UseEditModal = () => {

		const [isOpen, setIsOpen] = useState(false);

		function toggling() {
			if (isOpen) {
				// setInputValues({  })
				setErrors({})
				setSuccess({})
				setFields([''])
			}
			if (!isOpen) {

				setErrors({})

			}
			setIsOpen(!isOpen);
		}

		return {
			isOpen,
			toggling,
		}
	};
	const { isOpen, toggling } = UseEditModal();
	function getOwnFuel(nextdate) {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				next_date: nextdate
			})
		};
		fetch(API_URL + "/list-own-fuel", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []
				var fuelPrice = {}
				var flag = 0
				responseData.fuel_product.forEach(elem => {
					datas.push([elem.fuel_id, elem.fuel_name])
				})
				console.log(Number(responseData.fuel_product.length), "hh")
				console.log(Number(responseData.fuel_product.length), "hh")
				var len = Number(responseData.fuel_product.length) - Number(responseData.selling_data.length)
				console.log(len, "df")
				setInputValues(responseData.selling_data)
				if (responseData.fuel_product.length != responseData.selling_data) {
					for (var i = 0; i <= len; i++) {
						setInputValues((inputValues) => [
							...inputValues,
							{

								flag

							},
						]);
					}
				}

				setFuelData(datas)

				setSellingData(responseData.selling_data)
				setLoading(true)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}


	const handleInputs = (e, index, prop, fuel_id) => {
		const inputValues = [...sellingData];
		if (inputValues[index]) {
			if (inputValues[prop] === undefined) {
				inputValues[prop] = [];
			}

			inputValues[index][prop] = e.target.value
			inputValues[index]['fuel_id'] = fuel_id
			if (inputValues[index]['new_price'] && inputValues[index]['current_price'])
				inputValues[index]['difference'] = inputValues[index]['new_price'] - inputValues[index]['current_price']
			else
				inputValues[index]['difference'] = ''
			setInputValues({
				...inputValues,
				[e.target.name]: e.target.value,
				difference: inputValues[index]['difference'],
				fuel_id: fuel_id
			});
		}
		else {
			const inputValues = [...fuelData];

			if (inputValues[prop] === undefined) {
				inputValues[prop] = [];
			}

			inputValues[index][prop] = e.target.value
			inputValues[index]['fuel_id'] = fuel_id
			if (inputValues[index]['new_price'] && inputValues[index]['current_price'])
				inputValues[index]['difference'] = inputValues[index]['new_price'] - inputValues[index]['current_price']
			else
				inputValues[index]['difference'] = ''
			setInputValues({
				...inputValues,
				[e.target.name]: e.target.value,
				difference: inputValues[index]['difference'],
				fuel_id: fuel_id
			});
		}
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	const handlePopup = (event) => {
		setData({
			...update_data,
			new_price: event.target.value
		})
	}
	const handleEditPopup = (event, prop) => {
		const editField = [...edit_tank_config]

		editField[event.target.name] = event.target.value
		editField.current_price = edit_fields.current_price
		editField.fuel_name = edit_fields.fuel_name
		editField.product_id = edit_fields.product_id

		setEditFields(editField)



	}
	const handleEditPopupChange = (e, index, prop) => {
		const edit_update_data = [...edit_tank_config];

		if (edit_update_data[index]) {
			if (edit_update_data[prop] === undefined) {
				edit_update_data[prop] = [];
			}

			edit_update_data[index][prop] = e.target.value
			setEditData({
				...edit_update_data,
				[e.target.name]: e.target.value,
			});
		}
		else {
			// const edit_update_data = [...edit_update_data];
			// console.log(edit_update_data)
			// if (edit_update_data[prop] === undefined) {
			// 	edit_update_data[prop] = [];
			// }

			// edit_update_data[index][prop] = e.target.value
			// setEditData({
			// 	...edit_update_data,
			// 	[e.target.name]: e.target.value,
			// });
		}
	}
	const handleEditPopupNozzleChange = (e, idx, index, prop,) => {
		const nozzleRead = [...edit_tank_config]
		var NozzleAmount = nozzleRead[idx].nozzle_total.tank_nozzle_total
		// for (var i = 0; i < nozzleRead[idx].nozzles.length; i++) {
		// 	console.log(nozzleRead[idx].nozzles[i].nozzle_reading.opening_reading)
		// 	NozzleAmount = Number(NozzleAmount) + Number(nozzleRead[idx].nozzles[i].nozzle_reading.opening_reading)
		// }

		nozzleRead[idx].nozzles[index].nozzle_reading.opening_reading = e.target.value
		setNozzleData({ ...edit_tank_config, nozzleRead })
		var amount = 0
		for (var i = 0; i < nozzleRead[idx].nozzles.length; i++) {
			amount = Number(amount) + Number(nozzleRead[idx].nozzles[i].nozzle_reading.opening_reading)
			console.log(nozzleRead[idx].nozzles[i].nozzle_reading.opening_reading)
		}

		var difference = NozzleAmount - amount
		console.log(difference)
		if (editStock === 0)
			setEditStock(nozzleRead[idx].tank_reading.current_stock)

		if (editStock !== 0) {
			var tank_stock = Number(editStock) + Number(difference)
		}
		else {

			var tank_stock = Number(nozzleRead[idx].tank_reading.current_stock) + Number(difference)
		}

		nozzleRead[idx].tank_reading.current_stock = tank_stock
		setEditData({ ...edit_update_data, nozzleRead })
		setNozzleData({ ...edit_tank_config, nozzleRead })

	}
	const handleEdiTankData = (e, index, prop,) => {
		const tankRead = [...edit_tank_config]
		tankRead[index].tank_reading.current_stock = e.target.value
		setNozzleData({ ...edit_tank_config, tankRead })

		// nozzleRead[idx].nozzles[index].nozzle_reading.opening_reading= e.target.value	
		// setNozzleData({...edit_tank_config,nozzleRead})

	}
	const handlePopupChange = (e, index, prop) => {
		const update_data = [...tank_config];
		if (update_data[index]) {
			if (update_data[prop] === undefined) {
				update_data[prop] = [];
			}

			update_data[index][prop] = e.target.value
			setData({
				...update_data,
				[e.target.name]: e.target.value,
			});

		}
		else {
			const update_data = [...update_data];
			if (update_data[prop] === undefined) {
				update_data[prop] = [];
			}

			update_data[index][prop] = e.target.value
			setData({
				...update_data,
				[e.target.name]: e.target.value,
			});
		}
	}
	const handlePopupNozzleChange = (e, index, obj_index, prop) => {
		const update_data = [...tank_config];
		console.log(update_data)
		var NozzleAmount = update_data[index].nozzle_total.tank_nozzle_total
		// for (var i = 0; i<update_data[index].nozzles.length; i++) {
		// 	NozzleAmount = Number(NozzleAmount) + Number(update_data[index].nozzles[i].opening_reading)
		// }

		// if (update_data[index].nozzles[index]) {
		if (update_data[prop] === undefined) {
			update_data[prop] = [];
		}
		update_data[index].nozzles[obj_index][prop] = e.target.value
		setData({
			...update_data,
			[e.target.name]: e.target.value,
		});
		setNozzleData({ ...tank_config, update_data })

		// }
		// else {
		// 	const update_data = [...nozzle_data];
		// 	if (update_data[prop] === undefined) {
		// 		update_data[prop] = [];
		// 	}

		// 	update_data[index].nozzles[obj_index][prop] = e.target.value
		// 	setData({
		// 		...update_data,
		// 		[e.target.name]: e.target.value,
		// 	});

		// }
		// setNozzleData({ ...tank_config, update_data })
		var Amount = 0
		for (var i = 0; i < update_data[index].nozzles.length; i++) {
			Amount = Number(Amount) + Number(update_data[index].nozzles[i].opening_reading)
		}
		console.log(Amount)
		console.log(NozzleAmount)
		var difference = NozzleAmount - Amount
		if (stock === 0)
			setStock(update_data[index].current_stock)
		if (stock !== 0) {
			var tank_stock = Number(stock) + Number(difference)
		}
		else {
			var tank_stock = Number(update_data[index].current_stock) + Number(difference)
		}

		update_data[index].current_stock = tank_stock
		setNozzleData({ ...tank_config, update_data })
	}

	// ---handlesubmit function---//
	const handleSubmit = (event, index, fuel_id) => {
		const inputValues = [...sellingData];
		var product_price_id = inputValues[index].id
		getPriceData(fuel_id, product_price_id)
		localStorage.setItem('product_id', fuel_id);
		event.preventDefault();
		// if (validateForm(fuel_id))
		// sendFuelData(fuel_id)
	}
	const handleClick = (event, fuel_id) => {

		sendFuelData(fuel_id)
	}
	const handleEditClick = (event, fuel_id) => {
		sendEditFuelData(fuel_id)
	}
	function getPriceData(fuel_id, product_price_id) {
		var product_id = fuel_id

		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				product_id: product_id,
				product_price_id: product_price_id,
				next_date: input.next_date

			})
		};
		fetch(`${API_URL}/update-price`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == "create") {
					toggle();
					setData(responseData.tank_Data.tank_data)
					setNozzleData(responseData.tank_Data.tank_data)

					setTank(responseData.tank_Data.tank_data)
					setFields({
						...fields,
						fuel_name: responseData.fuel_name,
						product_id: responseData.product_id,

					})
				}
				if (responseData.status == "edit") {


					setEditData(responseData.tank_Data)

					setEditTank(responseData.tank_Data.tank_data)

					// setEditTank(responseData.tank_Data!=""?responseData.tank_Data.tank_data:'')
					// setNozzleData(responseData.tank_Data.tank_data)

					setEditFields({
						...edit_fields,
						current_price: responseData.current_price,
						fuel_name: responseData.fuel_name,
						new_price: responseData.new_price,
						product_id: responseData.product_id,

					})

					toggling();
				}

				return responseData;
			})
			.catch(e => {


			});
	}
	//---validations---//	
	function validateForm(fuel_id) {
		let errors = []
		let formIsValid = true
		let currentPrice = document.getElementById('current_price_' + fuel_id).value
		let newPrice = document.getElementById('new_price' + fuel_id).value
		if (currentPrice == '') {
			formIsValid = false;
			document.getElementById("current_price_error_" + fuel_id).innerHTML = "Enter Current Price"
		}
		else if (isNaN(currentPrice)) {
			formIsValid = false;
			document.getElementById("current_price_error_" + fuel_id).innerHTML = "Current Price Must Be Number"
		}
		else if (currentPrice < 0) {
			formIsValid = false;
			document.getElementById("current_price_error_" + fuel_id).innerHTML = "Current Price Must Be Greater Than Zero"
			return false
		}
		else {
			formIsValid = true;
			document.getElementById("current_price_error_" + fuel_id).innerHTML = ""
		}

		if (newPrice == '') {
			formIsValid = false;
			document.getElementById("new_price_error_" + fuel_id).innerHTML = "Enter New Price"
		}
		else if (isNaN(newPrice)) {
			formIsValid = false;
			document.getElementById("current_price_error_" + fuel_id).innerHTML = "New Price Must Be Number"
		}

		else if (newPrice < 0) {
			formIsValid = false;
			document.getElementById("new_price_error_" + fuel_id).innerHTML = "New Price Must Be Greater Than Zero"
			return false
		}
		else {
			formIsValid = true;
			document.getElementById("new_price_error_" + fuel_id).innerHTML = ""
		}

		return formIsValid;
	}


	function sendFuelData(fuel_id) {
		let fuelId = fuel_id
		let currentPrice = document.getElementById('current_price_' + fuel_id).value
		let newPrice = document.getElementById('new_price_change').value
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				product_id: fuelId,
				current_price: currentPrice,
				new_price: newPrice,
				update_data,
				next_date: input.next_date
			})
		};
		fetch(API_URL + "/fuel-selling-price", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
          let errors = []
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {

					errors["new_price"] = responseData.response.new_price
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/fuel-sell-price")
					getOwnFuel(input.next_date)
					setErrors([''])
					// setInputValues([])
					toggle();

				}

				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}
	function sendEditFuelData(fuel_id) {

		let fuelId = fuel_id
		let new_price = edit_fields.new_price
		let current_price = edit_fields.current_price
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				new_price: new_price,
				current_price: current_price,
				product_id: fuelId,
				edit_update_data,
				next_date: input.next_date
			})
		};
		fetch(API_URL + "/update-fuel-price-reading", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {


				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/fuel-sell-price")
					getOwnFuel(input.next_date)
					setErrors([''])
					// setInputValues([])
					toggling();

				}
				// setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div className="container pb-3">
							<div className="row  mt-3 form-container">
								<div className="col-md-12 mb-3"><Link to="/operational-date"><a href="/operational-date" className="float-end btn btn-gray">Back</a></Link></div>
								<div className="form-wrap ">
									{loading ? getOwnFuel : <Loader />}
									<div className="tab-content" id="myTabContent">
										<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
											<div className="">
												<div className="row mb-3">
													<div className="col-md">
														<div className="row">

															<div className="col-md col pl-0 col-12">
																<h3 className="table-title"> FUEL Selling price change ({moment(input.next_date).format("DD-MM-YYYY")})</h3>
															</div>

														</div>
													</div>
													<div className="col-md-4">
														<div className="input-group mb-3 search">

														</div>
													</div>
												</div>


												<div className=" conatiner pb-3">


													<div className="row mt-md-5 mt-3 form-container ">
														<div className="">
															<div className=" ">
																<div className="row mb-3 justify-content-center">


																	<div className="col-md-12 col-12 pl-0 mb-4">

																	</div>
																	<div className="col-md-10">
																		<div className="d-flex">
																			<div className=" py-2  w-50"></div>
																			<div className="w-100 text-center">
																				<b><p>Current Price</p></b>
																			</div>
																			<div className=" py-2  w-50"></div>
																			<div className="w-100 text-center ">
																				<b><p>New Price</p></b>
																			</div>
																			<div className=" py-2  w-50"></div>
																			<div className="w-100 text-center ">
																				<b><p className="">Difference</p></b>
																			</div>
																			<div className=" py-2  w-50"></div>
																			<div className="w-100 text-center"></div>
																		</div>
																	</div>

																	{fuelData.map((data, index) => {
																		{ console.log(inputValues) }
																		return (
																			<div className="">
																				<div className="row">
																					<div className="py-2 col-md-2"><b><p>{data[1]}</p></b></div>
																					<div className=" col-md text-center pe-3">
																						<input type="text" readOnly name="current_price" id={`current_price_${data[0]}`} className="form-control" value={inputValues[index] ? inputValues[index]['current_price'] : ''} onChange={event => { handleInputs(event, index, event.target.name, data[0]) }}></input>
																						<div className="errorMsg" id={`current_price_error_${data[0]}`}></div>
																					</div>
																					<div className=" col-md text-center pe-3">
																						<input type="text" readOnly name="new_price" className="form-control" id={`new_price${data[0]}`} value={inputValues[index] ? inputValues[index]['new_price'] : ''} onChange={event => { handleInputs(event, index, event.target.name, data[0]) }}></input>
																						<div className="errorMsg" id={`new_price_error_${data[0]}`}></div>

																					</div>
																					<div className="col-md text-center pe-3">
																						<input type="text" readOnly name="difference" id={`difference${data[0]}`} value={inputValues[index] ? inputValues[index]['difference'] : ''} className="form-control" onChange={event => { handleInputs(event, index, event.target.name, data[0]) }}></input>
																					</div>
																					<div className="col-md text-center pe-3">
																						{console.log(inputValues[index])}
																						{inputValues[index]?.["flag"] == 0 ? <button className="btn btn-success d-none " disabled onClick={event => { handleSubmit(event, index, data[0]) }}>Update <i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></button> : <button className="btn btn-success  " onClick={event => { handleSubmit(event, index, data[0]) }}>Update<i className="fa fa-long-arrow-right float-end " aria-hidden="true"></i></button>}
																					</div>
																				</div>
																			</div>
																		)
																	})}


																</div>



															</div>

														</div>
													</div>

												</div>


											</div>
										</div>
									</div>
								</div>
								<div className="text-end mt-3">
									<a href="nozzle-employee-list" className="btn btn-primary px-5">Continue</a>
								</div>
							</div>


						</div>
					</div>
				</div>
			</div>


			{isShowing ? (<FuelModal

				isShowing={isShowing}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fields={fields}
				success={success}
				tank_config={tank_config}
				update_data={update_data}
				handleClick={handleClick}
				handleSubmit={handleSubmit}

				handlePopup={handlePopup}
				handlePopupChange={handlePopupChange}
				handlePopupNozzleChange={handlePopupNozzleChange}
				hide={toggle}
				errors={errors}
			/>) : null
			}
			{isOpen ? (<FuelEditModal

				isOpen={isOpen}
				handleInputs={handleInputs}
				inputValues={inputValues}
				edit_fields={edit_fields}
				success={success}
				edit_tank_config={edit_tank_config}
				edit_update_data={edit_update_data}

				handleSubmit={handleSubmit}
				handleEditPopup={handleEditPopup}
				handleEditClick={handleEditClick}
				handleEditPopupChange={handleEditPopupChange}
				handleEditPopupNozzleChange={handleEditPopupNozzleChange}
				handleEdiTankData={handleEdiTankData}
				hide={toggling}
			// errors={errors}
			/>) : null
			}

		</>
	)
}








export default FuelSellPriceConfig