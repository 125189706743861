import React from 'react';
import moment from 'moment';
const MachineriesConfigModal = ({ preventNonNumericalInput,isShowing, hide, handleSubmit,double, inputValues, errors, handleInputs,handleInput }) => {

  return (
        <React.Fragment>
        <div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden  role="dialog">
      <div className="modal">
        <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Machinery/Asset</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form >
        <div className="row mb-3 justify-content-center mt-3">
         
    <label className="col-sm-4 col-form-label mb-2">Machinery/Asset Name <sup className="star">*</sup></label>
    <div className="col-sm-6 ">
      <input type="text" key="asset_name"   className="form-control mb-2"  name="asset_name" placeholder=""  value={inputValues["asset_name"]} onChange={handleInputs} readOnly={inputValues.ob_is_dayclose}/>  
      {/* <FormInput type="text" ref={(button) => { textInput = button; }} className="form-control mb-2" name="asset_name" placeholder=""  value={inputValues.asset_name} onChange={handleInputs} placeholder=""  /> */}
      <div className="errorMsg">{errors.asset_name}</div>
      <div className="errorMsg">{errors.asset}</div>
    </div>
     <label className="col-sm-4 col-form-label mb-2" >Date of Purchase</label>
    <div className="col-sm-6 ">
      <input type="date" className="form-control mb-2 " max={moment().format("YYYY-MM-DD")} name="date_of_purchase"  placeholder=""  value={inputValues["date_of_purchase"]} onChange={handleInputs}  readOnly={inputValues.ob_is_dayclose}/>
      <div className="errorMsg">{errors.date_of_purchase}</div>
     
    </div>
    <label className="col-sm-4 col-form-label mb-2">Purchase Price </label>
    <div className="col-sm-6 ">
      <input type="number" className="form-control mb-2 hide-arrow " onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  name="purchase_price" placeholder="" value={inputValues["purchase_price"]} onChange={handleInputs} readOnly={inputValues.ob_is_dayclose} />
      <div className="errorMsg">{errors.purchase_price}</div>
      <span class="amount-words"></span>
    </div>
    <label className="col-sm-4 col-form-label mb-2">Current Value </label>
    <div className="col-sm-6 ">
      <input type="number" className="form-control hide-arrow mb-2 " onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="current_price" placeholder="" value={inputValues["current_price"]} onChange={handleInput} readOnly={inputValues.ob_is_dayclose}/>
      <div className="errorMsg">{errors.current_price}</div>
      <span class="amount-words"></span>
    </div>
  </div>
  <div className="modal-footer">
        {double==true? <button type="submit" className="btn btn-primary" onClick={handleSubmit} >Save</button>: <button type="submit" className="btn btn-primary" disabled>Save</button>}
      </div>	
      </form>
      </div> 
 </div>
 </React.Fragment>
    );
}

export default MachineriesConfigModal;