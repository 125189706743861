import { Component } from "react";
import LoginHeaderOperational from './LoginHeaderOperational';
import Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg';
import jsPDF from "jspdf";
import "jspdf-autotable";

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

const SimplifiedBalanceSheet = (props) => {
  let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
  const [inputValues, setInputValues] = useState({});
  const [double, setDouble] = useState(true);
  const [list_data, setList] = useState([])
  const[pdf,setPdf]=useState({})
  useEffect(() => {
    check_url()
  }, [])
  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        if (responseData.allowurl == 1) {
          history.push(responseData.url);
        }
        else {
          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  const handleInput = (e) => {


    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

    setDouble(true)
  };
  const handleSubmit = (event) => {
    setAll([])
    setDouble(false);
    if (validateForm()) {
      getBalanceSheet();
    }

  }

  function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.op_bl_date) {
      formIsValid = false;
      setDouble(true)
      errors["op_bl_date"] = "Choose Date"
    }
    else {
      errors["op_bl_date"] = ""
    }


    setErrors(errors)

    return formIsValid;


  }
  async function getBalanceSheet() {
    setPdf(0)
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify({
        op_bl_date: inputValues.op_bl_date,
        type: inputValues.type
      })
    };
    await fetch(API_URL + "/s-op-balance-sheet", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        console.log(responseData)
        if(responseData.status===false){
          swal("", responseData.message, responseData.status ? "success" : "warning")
          setPdf(1);
        }
        setList(responseData.all_entries)     
        responseData.all_entries.map((elem) => {
          setAll(all => [
            ...all,

            {
              ac_head: elem.title,
              spr_ac_head: elem.stitle,
              sub_head: elem.accounts_head,
              drtamount: elem.drtamount,
              crtamount: elem.crtamount

            }
          ])
        })

        setState(responseData)
        setDouble(true)
        // responseData.cr_entries.forEach((elem) => {	
        //   console.log(elem.name)
        //   setCredit({[
        //     ...credit ,
        //   account_name : elem.name,
        //   name: elem.name,
        //   amount:elem.amount]

        //   })
        // })
        // setCredit(responseData.cr_entries)
      })
      .catch(e => {


        setDouble(true)

      });
  }

  const history = useHistory()
  const [state, setState] = useState([])
  const [all, setAll] = useState([])
  const [errors, setErrors] = useState({})
  var buffer = ''; var flag = 0;
  var s_buffer = ''; var s_flag = 0;
  var ss_buffer = ''; var ss_flag = 0; var accsub_head = '';
  var profit_loss_amount = 0; var org_amount = 0; var profit_loss = ''; var dr_total = 0; var cr_total = 0;

  function downloadPDF() {
    if (validateForm()) {
      const requestOptions = {
        method: 'post',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          op_bl_date: inputValues.op_bl_date,
          type: inputValues.type
        })
      };
      fetch(API_URL + "/download_pdf_file_op", requestOptions)
        .then(response => { return response.blob(); })
        .then(responseData => {
          var blob = new Blob([responseData]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "BalanceSheet.pdf";
          link.click();

          return responseData;
        })
        .catch(e => {
          console.log(e);
        });
    }
  }


  var today = new Date().toISOString().split('T')[0];

  return (
    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={28} operation={4} op={3} />
          <div className="content-area">
            <LoginHeaderOperational props={props} page_title="  " />

            {(permissions.includes("All Reports") || permissions.includes("Simplified Balance Sheet"))== false ?
	<div  className="form-wrap mt-5">
  <div className="text-center">
  You don't have permission to access this page
  </div></div>:
            <div className="container pb-3">
              <div className="row justify-content-center  mt-3 form-container">
                <div className="col-md-12">
                  <div className="row mb-3">
                    <div className="col-md d-flex">
                      <div className="text-start d-flex mt-4">

                        <div className="text-start d-flex pe-1 mt-3">
                          <div className="form-group">
                            <label  >Date <sup class="star">*</sup></label>
                            <input type="date" className="form-control cal-date " placeholder="" name="op_bl_date" value={inputValues.op_bl_date} min={state.yesterday} max={today} onChange={handleInput}></input>
                            <div className="errorMsg">{errors.op_bl_date}</div>
                          </div>
                        </div>
                        <div className="text-start d-flex pe-1 mt-3">
                          <div className="form-group">
                            <label  >Type</label>
                            <select id="" class="form-control highlight" name="type" value={inputValues.type} onChange={handleInput}>
                              <option value="">All</option>
                              <option value="2">Approved</option>
                              <option value="4">Day Closed</option>
                            </select>
                          </div>
                        </div>


                        <div className="text-start d-flex mt-4">
                          
                          {double === true ? <a className="btn btn-primary mb-md-0 px-3 mb-3 mt-3 ms-2" onClick={handleSubmit}>Submit </a> : <a className="btn btn-primary mb-md-0 px-3 mb-3 mt-3 ms-2" >Submit </a>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-wrap balace-sheetwrap">

                    <div className="row mb-3">
                      <div className="col-md ">
                        <h2 className="table-title  mb-1">{state.pump_name}</h2>
                        <h3 className="table-title mb-1">{state.dealer_name}</h3>
                        <h3 className="table-title mb-1">{state.pump_address}</h3>
                        <h4 className="table-title mb-1">Balance Sheet</h4>
                        <h5>{state.op_bl_date}</h5>


                      </div>
                    </div>

                    <div className="row sheet-wrp">
                      <div className="col-md-12">
                        <table id="pdfdiv" className="table table-bordered border sheet-table">
                          <thead>
                            {/* <tr>
                                                          <td colspan="4"><h2 className="table-title mb-1">{state.pump_name}</h2></td>
                                                        </tr>
                                                           <tr>
                                                          <td colspan="4">{state.dealer_name}</td>
                                                        </tr>
                                                        <tr>
                                                          <td colspan="4">{state.pump_address}</td>
                                                        </tr>

                                                             <tr>
                                                          <td colspan="4"><b>BALANCESHEET</b></td>
                                                        </tr>
                                                         <tr>
                                                          <td colspan="4">{state.config_date}</td>
                                                        </tr>  */}


                            {/* <tr>
                                                          <td className="text-center " colspan="4"><img src={logo} className="img-fluid py-4" width="100px"/></td>
                                                        </tr> */}
                            <tr>
                              <th className="text-start">Account Name</th>

                              <th className="text-end">Credit</th>
                              <th className="text-end">Debit</th>
                            </tr>
                          </thead>
                          <tbody >
                            {all.map((account) => {

                              if (Number(account.drtamount) > Number(account.crtamount)) {
                                var Dramt = parseFloat(account.drtamount - account.crtamount).toFixed(2);

                                var Cramt = '';
                                dr_total = Number(Dramt) + Number(dr_total);
                              }
                              else if (Number(account.crtamount) > Number(account.drtamount)) {
                                var Dramt = '';
                                var Cramt = parseFloat(account.crtamount - account.drtamount).toFixed(2);
                                cr_total = Number(Cramt) + Number(cr_total);
                              }

                              if (buffer == account.ac_head) { flag = 1; }
                              else { flag = 0; }
                              if (account.ac_head != null && Cramt != Dramt) {
                                buffer = account.ac_head;
                              }
                              if (s_buffer == account.spr_ac_head) { s_flag = 1; }
                              else { s_flag = 0; }
                              if (account.spr_ac_head != null && Cramt != Dramt) {
                                s_buffer = account.spr_ac_head;
                              }

                              if (ss_buffer == account.sub_head) {
                                ss_flag = 1;
                                accsub_head = '';
                              }
                              else {
                                ss_flag = 0;
                                accsub_head = account.sub_head;
                              }
                              if (account.sub_head != null && Cramt != Dramt) {
                                ss_buffer = account.sub_head;
                              }

                              return (
                                <>
                                  {s_flag == 0 && Cramt != Dramt &&
                                    <>
                                      {account.spr_ac_head ?
                                        <tr><th colspan="3">{account.spr_ac_head}</th></tr> : ''}
                                    </>
                                  }
                                  {flag == 0 && Cramt != Dramt &&
                                    <>
                                      {account.ac_head ?
                                        <tr><th colspan="3">{account.ac_head}</th></tr> : ''}
                                    </>
                                  }
                                  {
                                    <>
                                      <tr> 
                                        {
                                          (Cramt > 0 || Dramt >0) ?<th>
                                          <span className="sub_head">{accsub_head}</span>
                                        </th> : ''
                                        }
                                        

                                        <td className="text-end">{Cramt != '' && Cramt > 0 ? numberFormat(Cramt) : ''}</td>
                                        <td className="text-end">{Dramt != '' && Dramt > 0 ? numberFormat(Dramt) : ''}</td>
                                      </tr>
                                    </>
                                  }
                                </>
                              )
                            })}

                            <span className="sno">
                              <>
                                {Number(dr_total) == Number(cr_total) ?
                                  (org_amount = parseFloat(dr_total).toFixed(2))
                                  : ''}

                                {Number(dr_total) > Number(cr_total) ?
                                  (profit_loss_amount = parseFloat(dr_total - cr_total).toFixed(2),
                                    profit_loss = 'profit',
                                    org_amount = parseFloat(dr_total).toFixed(2)
                                  )
                                  : ''}

                                {Number(cr_total) > Number(dr_total) ?
                                  (profit_loss_amount = parseFloat(cr_total - dr_total).toFixed(2),
                                    profit_loss = 'loss',
                                    org_amount = parseFloat(cr_total).toFixed(2)
                                  )
                                  : ''}
                              </>
                            </span>
                            <tr>
                              {profit_loss == "profit" &&
                                <>

                                  <th>Owners Fund</th>


                                  <th className="text-end">{numberFormat(profit_loss_amount)}</th>
                                  <td></td>
                                </>
                              }
                            </tr>
                            <tr>
                              {profit_loss == "loss" &&
                                <>

                                  <th>Loss</th>
                                  <td></td>
                                  <th className="text-end">{numberFormat(profit_loss_amount)}</th>


                                </>
                              }
                            </tr>
                            <tr>
                              <th>Total</th>

                              <th className="text-end">{numberFormat(org_amount)}</th>

                              <th className="text-end">{numberFormat(org_amount)}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                  <div className="col-md">
                    {pdf===0?
                    <div className="text-end mt-4"><a onClick={() => downloadPDF()} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a>                   
                    </div>
                    :null}
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      {/* <div className="container pb-3">
	<div className="row justify-content-center mt-md-5 mt-3 form-container">
		<div className="col-md-7">
			
          <div className="settings-wrapper p-10">
          <div className="col-md-12 mb-3 px-4 ">
           <p className="balance-para">{state.pump_name} {state.dealer_name} {state.pump_address}<br></br>BalanceSheet</p>
           </div>
             <div className="col-md-12 mb-3 px-4 "><p className="asset-para"><span className="asset">ASSETS</span><span className="debit">Dr</span></p><p className="p-lib"><span className="liability">LIABILITIES</span><span className="credit">Cr</span></p>
          </div><br></br>
          </div>
          <div className="col-6 p-2 balance-content"></div>
    
		</div>
	</div>
</div> */}


    </>
  )
}

function printDocument() {

  const requestOptions = {
    method: 'post',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Accept": "application/json"
    },
    body: JSON.stringify({
      op_date: localStorage.getItem("op_date")
    })
  };
  fetch(API_URL + "/download_pdf_file", requestOptions)
    .then(response => { return response.json(); })
    .then(responseData => {

      return responseData;
    })
    .catch(e => {
      console.log(e);
    });

}


export default SimplifiedBalanceSheet