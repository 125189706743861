import { Component } from "react";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import { useEffect } from 'react';
import swal from "sweetalert";
// import swal2 from "./sweet";
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import museum from '../../../../img/museum.svg'
import DensityModal from './DensityModal.component'
import DensityView from './DensityView.component'
import DensityEdit from './DensityEdit.component'
import Loader from "../Loader.component"
import Modal from "react-modal";
import moment from 'moment';
import { Link } from "react-router-dom";
import PaginationComponent from '../../../layouts/PaginationComponent';
import { OverlayTrigger } from "react-bootstrap";


const Density = (props) => ReactDOM.createPortal(
    <DensityModal  {...props} />, document.body
);
const PaginationComp = (props) => (
    < PaginationComponent  {...props} />
);

const View = (props) => ReactDOM.createPortal(
    <DensityView  {...props} />, document.body
);
const Edit = (props) => ReactDOM.createPortal(
    <DensityEdit  {...props} />, document.body
);

//   const Report = (props) => ReactDOM.createPortal(
// 	<DsrConfig  {...props} />, document.body
//   );
//   const Edit = (props) => ReactDOM.createPortal(
// 	<DsrEdit  {...props} />, document.body
//   );

const DensityReport = (props) => {
    const [productData, setProduct] = useState([])
    const [inputValues, setInputValues] = useState({});
    const [inputVal, setInputVal] = useState({});
    const [input, setInput] = useState({});
    const [dropTank, setDropTank] = useState([]);
    const [drop, setDrop] = useState([]);
    const [TankData, setTankData] = useState([]);
    const [inputNo, setNozzleNo] = useState([]);
    const [inputTa, setTankNo] = useState([]);
    const [search, setSearch] = useState('')
    const [NozzleData, setNozzleData] = useState([]);
    const [data, setData] = useState([]);
    const history = useHistory()
    const [errors, setErrors] = useState({})
    const [form_nozzle, setFuel] = useState([]);
    const [Loose, setLoose] = useState([])
    const [Packed, setPacked] = useState([])
    const [Others, setOthers] = useState([])
    const [double, setDouble] = useState(true)
    const [Sales_sum, setSalesum] = useState([])
    const [Test, setTest] = useState([])
    const [Receipt, setReceipt] = useState([])
    const [inputNozzle, setInputNozzle] = useState({});
    const [list_data, setList] = useState([])
    const [delete_id, deleteId] = useState([])
    const [inputFuel, setInputFuel] = useState({})
    const [list, setListSearch] = useState([])
    const [dip, setDip] = useState([])
    const [dis, setDis] = useState([])
    const [cummulative, setCummulative] = useState([])
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState([])
    const [gain_loss, setGainLoose] = useState([])
    const [report, setReport] = useState([])
    const [total_stock, setTotalOpenStock] = useState([])
    const [inputTankEdit, setInputTankEdit] = useState([])
    const [inputNozzleEdit, setInputNozzleEdit] = useState([])
    const [inputDate, setInputDate] = useState([])
    const [total, setTotalStock] = useState([])
    const [pump, setPump] = useState({})
    const [permission, setPermission] = useState([])
    const [err, setErr] = useState({})

    const [pages, setpages] = useState(
        {
            current_page: 1,
            last_page: 0,
            total_pages: 0,
            pagination_item: [],
            total_pages: 0,
        })
    //loader


    //-----on load---//
    useEffect(() => {
        check_url()



    }, [])
    function check_url() {
        const requestOptions = {
            method: 'post',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                pathurl: window.location.pathname,
            }),
        };
        fetch(`${API_URL}/check_url`, requestOptions)
            .then(response => { return response.json(); })

            .then(responseData => {
                if (responseData.allowurl == 1) {
                    history.push(responseData.url);
                    getProduct();
                    getPro();
                    ListDensityData(pages.current_page);
                    getDate();
                }
                else {
                    history.goBack();
                }

                return responseData;
            })
            .catch(e => {
                console.log(e)
            });

    }
    //---Create popup function---//
    const [isOpen, setIsOpen] = useState(false);
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    const [isOpenModal, setIsOpenModal] = useState(false);
    function toggleModal2() {
        setIsOpenModal(!isOpenModal);
    }
    const [isOp, setIsOp] = useState(false);
    function toggleView() {
        setIsOp(!isOp);
    }

    const [isOpe, setIsOpe] = useState(false);
    function toggleEdit() {

        setIsOpe(!isOpe);

    }


    const UseModal = () => {


        const [isShowing, setIsShowing] = useState(false);

        function toggle() {

            if (isShowing) {

                window.location.reload(true);
                // setNozzleData(['']);
                setInputDate({})
                setInputValues({})
                setInputVal({})


            }
            if (!isShowing) {
                setDouble(true)
                setErrors({})



            }
            setIsShowing(!isShowing);

        }
        return {
            isShowing,
            toggle,
        }
    };

    const { isShowing, toggle } = UseModal();


    //-------------Athulya -----getData---------//

    const sumb = (e) => {

    }

    //------------------get fuel name data- to dropdown--------------//
    function getProduct() {
        const requestOptions = {
            method: 'get',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },

        };
        fetch(API_URL + "/density", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {
                console.log(responseData.message, "ll")


                var product_data = []
                responseData.product_data.forEach(elem => {
                    product_data.push([elem.product_id, elem.fuel_name, elem.tank_name])
                })
                setProduct(product_data)
                ckeckOption({
                    ...checkoption,
                    isdayclose: responseData.isdayclose
                })



                //var fuel_name=responseData.product_data[0].fuel_name
                //var tank_name=responseData.product_data[1].tank_name
                //             setInputFuel({
                //                 ...inputFuel,
                // fuel_name:fuel_name,


                //             })


                return responseData;
            })
            .catch(e => {

            });
    }


    function getPro() {
        const requestOptions = {
            method: 'get',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },

        };
        fetch(API_URL + "/density", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {


                var product_data = []
                responseData.product_data.forEach(elem => {
                    product_data.push([elem.product_id, elem.fuel_name, elem.tank_name])
                })

                setDrop(product_data)


                //var fuel_name=responseData.product_data[0].fuel_name
                //var tank_name=responseData.product_data[1].tank_name
                //             setInputFuel({
                //                 ...inputFuel,
                // fuel_name:fuel_name,


                //             })


                return responseData;
            })
            .catch(e => {

            });
    }



    const handleSelect = (e) => {
        var product_id = e.target.value
        var date = e.target.value

        getTank(product_id);

        setInputVal({
            ...inputVal,

            [e.target.name]: e.target.value,
            product_id: product_id

        });

    }
    const handleTankId = (e, data) => {



    }
    const handleSelect1 = (e) => {
        var ida = e.target.value
        var date = e.target.value

        getTa(ida);
        console.log(ida)
        setInputValues({
            ...inputValues,

            [e.target.name]: e.target.value,
            ida: ida

        });


    }
    const handletank = (e, data) => {
        console.log(e.target.value)
        var tank_id = e.target.value
        TankData.map((elem) => {

            if (e.target.value == elem[0]) {

                setInputValues({
                    ...inputValues,
                    tank_id: tank_id,
                    tank_name: elem[1]
                })
            }
        })

    }

    const handletank1 = (e, data) => {
        console.log(e.target.value)
        var tank_id = e.target.value
        dropTank.map((elem) => {

            if (e.target.value == elem[0]) {

                setInputVal({
                    ...inputVal,
                    tank_id: tank_id,
                    tank_name: elem[1]
                })
            }
        })

    }

    function getTank(product_id, ida, e) {


        const requestOptions = {
            method: 'post',
            body: JSON.stringify({
                product_id: product_id,

            }),
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
        };
        fetch(API_URL + "/get-tank-info", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {

                var tank_data = []

                // tank_data.push([responseData.tank_id,responseData[0].tank_name,responseData[0].fuel_name])
                responseData.tank.forEach(elem => {
                    tank_data.push([elem.tank_id, elem.tank_name])
                })

                var product_id = responseData.tank[0].product_id

                var fuel_name = responseData.tank[0].fuel_name

                setInputNozzle({
                    ...inputNozzle,
                    product_id: product_id,
                    fuel_name: fuel_name,


                })



                setTankData(tank_data)
                return responseData;
            })
            .catch(e => {

            });
    }

    function getTa(ida, e) {


        const requestOptions = {
            method: 'post',
            body: JSON.stringify({

                product_id: ida
            }),
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
        };
        fetch(API_URL + "/get-tank-info", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {

                var tank_data = []

                // tank_data.push([responseData.tank_id,responseData[0].tank_name,responseData[0].fuel_name])
                responseData.tank.forEach(elem => {
                    tank_data.push([elem.tank_id, elem.tank_name])
                })


                setDropTank(tank_data)





                return responseData;
            })
            .catch(e => {

            });
    }

    const handleInputs = (e) => {

        // getDensity();
        // var temp=e.target.value
        setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value,


        });

    }

    const handleIn = (e) => {

        //getDensity();
        // var temp=e.target.value
        setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value,


        });

    }
    const [shows, setShow] = useState(false)
    const handleDendity = (e) => {
        // e.preventDefault();
        if (validateForm1());
        getDensity();

        setShow(true)

    }

    const handleInput = (e) => {

        // getDensity();
        var date = e.target.value
        setInputDate({
            ...inputDate,
            [e.target.name]: e.target.value,
            //date:date

        });

    }

    //---------send the density------//
    const [Message, setMessage] = useState([])
    const [checkoption, ckeckOption] = useState([])
    function getDensity() {
        //const id=inputVal.id
        //const date=inputDate.date
        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },

            body: JSON.stringify({
                product_id: inputNozzle.product_id,
                hmv: inputValues.hmv,
                date: inputDate.date,
                temp: inputValues.temp,
                tank_id: inputValues.tank_id

            })
        };
        fetch(API_URL + "/get-density", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                if (responseData.status == true) {
                    var density = responseData.density
                }

                setInputValues({
                    ...inputValues,
                    density: density
                })



                if (responseData.status == false) {


                    var density = responseData.density

                    swal("", responseData.message, responseData.status ? "success" : "warning")
                }
                setInputValues({
                    ...inputValues,
                    density: density

                })
                console.log(density)

                if (responseData.status == true) {
                    swal("", responseData.message, responseData.status ? "success" : "danger")
                    // var density=responseData.density
                }

                // toggleModal2();
                if (responseData.flag == 3) {
                    swal("", responseData.flag, responseData.status ? "success" : "warning")
                }



                return responseData;
            })
            .catch(e => {

            });
    }

    const handleSubmit2 = (e) => {
        ListDensityData(pages.current_page)
    }

    const handleSubmit = (e) => {
        setDouble(false);
        // e.preventDefault();
        if (validateForm());

        sendData()
    }



    function sendData() {
        const date = inputDate.date

        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },

            body: JSON.stringify({
                id: inputValues.id,
                product_id: inputNozzle.product_id,
                hmv: inputValues.hmv,
                date: date,
                temp: inputValues.temp,
                tank_id: inputValues.tank_id,
                density: inputValues.density

            })
        };
        fetch(API_URL + "/density", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                if (responseData.status == false) {

                    swal("", responseData.message, responseData.status ? "success" : "warning")
                    errors["date"] = responseData.response.date
                    errors["hmv"] = responseData.response.hmv
                    errors["temp"] = responseData.response.temp
                    setDouble(true)

                }
                if (responseData.status == true) {
                    swal("", responseData.message, responseData.status ? "success" : "danger")
                    setDouble(true)
                }
                // inputVal.product_id=""
                inputValues.hmv = ""

                inputValues.temp = ""
                inputNozzle.tank_id = ""
                inputValues.density = ""

                setInputDate(date)




                toggle();
                ListDensityData(pages.current_page);
                return responseData;
            })
            .catch(e => {
                setDouble(true)
            });
    }

    const handlePagination = (number) => {
        setpages({
            ...pages,
            current_page: number        //---pagination---//
        }
        )
        ListDensityData(number)

    }
    function ListDensityData(page) {

        const requestOptions = {
            method: "post",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                product_id: inputValues.ida,
                tank_id: inputVal.tank_id,
                fro_date: inputValues.fro_date,
                to_date: inputValues.to_date,
            }),
        };

        fetch(`${API_URL}/search-density?page=${page}`, requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {

                setList(responseData.report_data.data)
                setLoading(true)
                // inputValues.ida = ""
                // inputValues.tank_id = ""
                // inputValues.fro_date = ""
                // inputValues.to_date = ""



                setpages({
                    ...pages,
                    current_page: responseData.report_data.current_page,
                    last_page: responseData.report_data.last_page,
                    per_pages: responseData.report_data.per_page,
                    total_pages: responseData.report_data.total,

                })



            })


            .catch((e) => {
                console.log(e);
            });
    }

    //---Delete ---//	
    function delete_Density(id) {

        deleteId(id)

        toggleModal()


    }
    function deleteDensity() {

        setDouble(false)
        const requestOptions = {
            method: 'DELETE',

            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"

            },
            body: JSON.stringify(delete_id)
        };
        fetch(API_URL + "/density/" + delete_id, requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {
                swal("", responseData.message, responseData.status ? "success" : "warning")
                setDouble(true)
                ListDensityData(pages.current_page);
                toggleModal()

                return responseData;


            })

            .catch(err => {

            })
    }

    function edit_Density(id) {


        const requestOptions = {
            method: 'get',
            data: { id: id },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"

            },

        };
        fetch(API_URL + "/density/" + id + "/edit", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {


                setInputValues({
                    ...inputValues,
                    id: responseData.density[0].id,
                    density: responseData.density[0].density,
                    density_diff: responseData.density[0].density_diff,
                    hmv: Math.trunc(responseData.density[0].hmv),
                    temp: responseData.density[0].temp,
                    date: responseData.density[0].op_date,
                })

                // var temp=   parseFloat(responseData.density[0].temp).toFixed(1)

                setInputNozzle({
                    ...inputNozzle,
                    tank_id: responseData.density[0].tank_id,
                    tank_name: responseData.density[0].tank_name,
                    fuel_name: responseData.density[0].fuel_name


                })
                setInputVal({
                    ...inputVal,
                    product_id: responseData.density[0].product_id

                })
                var date1 = responseData.density[0].op_date
                setInputDate({
                    ...inputDate,
                    date: responseData.density[0].op_date,
                    date1: date1
                })
                toggleEdit();

                return responseData;
            })
            .catch(e => {


            });
    }


    const handleSubmitedit = (e) => {
        if (validateForm())
            sendEditData()
    }


    function sendEditData() {
        //const date=inputDate.date

        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },

            body: JSON.stringify({
                id: inputValues.id,
                product_id: inputVal.product_id,
                hmv: inputValues.hmv,
                date: inputDate.date,
                temp: inputValues.temp,
                tank_id: inputNozzle.tank_id,
                density: inputValues.density

            })
        };
        fetch(API_URL + "/density", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                if (responseData.status == false) {
                    swal("", responseData.message, responseData.status ? "success" : "warning")
                }
                if (responseData.status == true) {
                    swal("", responseData.message, responseData.status ? "success" : "danger")
                }


                inputVal.product_id = ""
                inputValues.hmv = ""
                inputValues.temp = ""
                inputNozzle.tank_id = ""
                inputValues.density = ""
                // inputValues.date=""
                setInputDate(inputDate)
                setInputValues(inputValues)
                toggleEdit();
                ListDensityData(pages.current_page);
                return responseData;
            })
            .catch(e => {

            });
    }
    function View_Density(id) {


        const requestOptions = {
            method: 'get',
            data: { id: id },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"

            },

        };
        fetch(API_URL + "/density/" + id + "/edit", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {

                setInputValues({
                    ...inputValues,
                    density: responseData.density[0].density,
                    density_diff: responseData.density[0].density_diff,
                    hmv: responseData.density[0].hmv,
                    temp: responseData.density[0].temp,
                    date: responseData.density[0].op_date,
                })

                setInputNozzle({
                    ...inputNozzle,
                    tank_id: responseData.density[0].tank_id,
                    tank_name: responseData.density[0].tank_name,
                    fuel_name: responseData.density[0].fuel_name


                })
                setInputVal({
                    ...inputVal,
                    product_id: responseData.density[0].product_id

                })
                setInputDate({
                    ...inputDate,
                    date: responseData.density[0].op_date
                })
                toggleView();

                return responseData;
            })
            .catch(e => {


            });
    }

    function validatedrop() {


        let err = []
        let hasdata = true
        if (!inputVal.product_id) {

            hasdata = false;
            err["product"] = "Product name is Required"
        }
        if (!inputValues.tank_id) {
            hasdata = false;
            err["tank"] = "Tank name is Required"
        }
        setErr(err);
        return hasdata;
    }
    const handleAdd = (e) => {
        if (validatedrop()) 
            toggle()
        
    }

    function validateForm() {
        let errors = []
        let formIsValid = true
        if (!inputDate.date) {
            setDouble(true)
            formIsValid = false;
            errors["date"] = "Date  is Required"
        }
        if (!inputValues.temp) {
            setDouble(true)
            formIsValid = false;
            errors["temp"] = "Temperture is Required"
        }
        if (!inputValues.hmv) {
            setDouble(true)
            formIsValid = false;
            errors["hmv"] = "HMV is Required"
        }
        if (!inputValues.density) {
            setDouble(true)
            formIsValid = false;
            errors["density"] = "Density is Required"
        }

        setErrors(errors)

        return formIsValid;


    }

    function validateForm1() {
        let errors = []
        let formIsValid = true
        if (!inputDate.date) {
            setDouble(true)
            formIsValid = false;
            errors["date"] = "Date  is Required"
        }
        if (!inputValues.temp) {
            setDouble(true)
            formIsValid = false;
            errors["temp"] = "Temperture is Required"
        }
        if (!inputValues.hmv) {
            setDouble(true)
            formIsValid = false;
            errors["hmv"] = "HMV is Required"
        }


        setErrors(errors)

        return formIsValid;


    }
    function getDate() {
        // var x = document.getElementById("newpost");
        // x.style.display = "none";

        setInput(input)

        const requestOptions = {      //---employee listing---//
            method: 'post',

            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"

            },
            body: JSON.stringify({
                next_date: input.next_date

            })
        };
        fetch(`${API_URL}/get-next-date`, requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {

                setInput({
                    ...input,
                    next_date: responseData.next_date,
                    config_date: responseData.confg_date
                })

                //   setInputValues({config_date:responseData.confg_date})
                return responseData;
            })
            .catch(e => {


            });
    }

    //-------------------------//
    //-----------------------------//
    return (
        <>
            <div className="main-container page-wrap">
                <div className="d-md-flex">
                    <Sidebar_Progress props={props} progress={4} operation={1} />
                    <div className="content-area">
                        <LoginHeaderOperational props={props} page_title="" />
                        <div className="container pb-3">

                            <div className="row justify-content-center  form-container">
                                {permission.status == 3 ?
                                    <div className="form-wrap mt-5">
                                        <div className="text-center">
                                            {permission.message}
                                        </div></div> :
                                    loading ? getProduct : <Loader />}
                                <div className="text-end mt-4"><a className="btn btn-success px-3 mb-md-0 mb-2" onClick={(event) => history.push("/density")}> Density Report</a></div>
                                <div className="col-md-12">



                                    <div className="form-wrap mt-3">
                                        <div class="row">
                                            <div class="col-3">
                                                <div class="">
                                                    <h3 class="table-title"> DENSITY REPORT</h3>
                                                </div>
                                            </div>
                                            <div class="col-2"></div>
                                            <div class="col-1"></div>


                                            <div className="col-3"><b>Start Date</b></div>
                                            <div className="col-3"><b>End Date</b></div>
                                        </div>
                                        <div class="row">

                                            <div class="col-2">
                                                <div class="select-container">
                                                    <select className="form-control" name="product_id" value={inputVal.product_id} onChange={handleSelect}>
                                                        <option>Products</option>
                                                        {
                                                            productData.map((data) => {

                                                                return <option value={data[0]}>{data[1]}</option>
                                                            })}
                                                    </select>
                                                </div>
                                                <div className="errorMsg">{err.product}</div>
                                            </div>

                                            <div class="col-2"></div>
                                            <div class="col-2">
                                                <div class="select-container">
                                                    <select className="form-control" name="product_id" value={inputValues.ida} onChange={handleSelect1}  >
                                                        <option>Products</option>
                                                        {
                                                            drop.map((data) => {

                                                                return <option value={data[0]}>{data[1]}</option>
                                                            })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-3">
                                                <div class="">
                                                    <input className="form-control" type="date" value={inputValues.fro_date} name="fro_date" onChange={handleInputs}>

                                                    </input>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="">
                                                    <input className="form-control" type="date" name="to_date" min={inputValues.fro_date} value={inputValues.to_date} onChange={handleInputs}>

                                                    </input>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">



                                            <div class="col-2">
                                                <div class="select-container">
                                                    <select className="form-control" name="tank_id" value={inputValues.tank_id} onChange={handletank} >
                                                        <option>Tanks</option>
                                                        {
                                                            TankData.map((data) => {

                                                                return <option value={data[0]}>{data[1]}</option>
                                                            })}
                                                    </select>
                                                </div>
                                                <div className="errorMsg">{err.tank}</div>
                                            </div>
                                            <div class="col-2">
                                                <a class="btn btn-primary px-3  " onClick={handleAdd}>Add</a>
                                            </div>

                                            <div class="col-2">
                                                <div class="select-container">
                                                    <select className="form-control" name="tank_id" value={inputVal.tank_id} onChange={handletank1}>
                                                        <option>Tanks</option>
                                                        {
                                                            dropTank.map((data) => {

                                                                return <option value={data[0]}>{data[1]}</option>
                                                            })}
                                                    </select>
                                                </div>
                                            </div>







                                            <div class="col-2  mt-2 mx-4 text-end">
                                                <a class="btn btn-primary px-3  " onClick={handleSubmit2}>search</a>
                                            </div>

                                        </div>

                                        <div className="table-widget mt-5">
                                            <table className="table border">
                                                <thead>
                                                    <tr>
                                                        <th>SL No.</th>
                                                        <th>Date</th>
                                                        <th>Product Name </th>
                                                        <th>Tank Name </th>
                                                        <th>HMV</th>
                                                        <th>Temperature</th>
                                                        <th>Density</th>
                                                        <th>Density Difference</th>
                                                        <th>Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list_data.length?(
                                                        list_data.map((density, id) => (
                                                            <tr>
                                                                <td>{id + 1}</td>
                                                                <td>{moment(density.op_date).format("DD-MM-YYYY")}</td>
                                                                <td>{density.fuel_name}</td>
                                                                <td>{density.tank_name}</td>
                                                                <td>{density.hmv}</td>
                                                                <td>{density.temp}</td>
                                                                <td>{density.density}</td>
                                                                <td>{density.density_diff}</td>


                                                                <td class="py-2"><a href="#" class="text-secondary" type="submit"  ><i class="fa fa-pencil-square-o pe-2" aria-hidden="true" onClick={() => edit_Density(density.id)}></i></a>
                                                                    <a href="#" class="text-secondary" type="submit" onClick={() => delete_Density(density.id)} ><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
                                                                    <a href="#" class="text-secondary" type="submit" onClick={() => View_Density(density.id)}><i class="fa fa-eye pe-2" aria-hidden="true"></i></a>
                                                                </td>
                                                            </tr>

                                                        ))):
                                                        
                                                        (<tr>
                                                            <td style={{textAlign:"center",padding:"10px"}} colSpan={"9"}> No Record found</td>
                                                           </tr>
                                                            )}
                                                </tbody>
                                            </table>
                                            {loading ? <PaginationComp
                                                pages={pages}
                                                handlePagination={handlePagination}
                                            /> : ""
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

            {isShowing ? (<Density
                isShowing={isShowing}
                hide={toggle}
                handleSelect={handleSelect}
                inputValues={inputValues}
                handleInputs={handleInputs}
                handleInput={handleInput}
                inputFuel={inputFuel}
                inputDate={inputDate}
                input={input}
                double={double}
                shows={shows}
                // handleDenditySave={handleDenditySave}
                handleSubmit={handleSubmit}
                productData={productData}
                handleDendity={handleDendity}
                TankData={TankData}
                handleIn={handleIn}
                inputNozzle={inputNozzle}
                errors={errors}

            />) : null
            }
            {isOpe ? (<Edit
                isOpe={isOpe}
                hide={toggleEdit}
                handleSelect={handleSelect}
                inputValues={inputValues}
                handleInputs={handleInputs}
                handleInput={handleInput}
                inputFuel={inputFuel}
                inputDate={inputDate}
                handleDendity={handleDendity}
                input={input}
                handleSubmitedit={handleSubmitedit}
                handleSubmit={handleSubmit}
                productData={productData}
                TankData={TankData}
                handleIn={handleIn}
                inputNozzle={inputNozzle}
                edit_Density={edit_Density}

            />) : null
            }
            {isOp ? (<View
                hide={toggleView}
                isOp={isOp}
                handleSelect={handleSelect}
                inputValues={inputValues}
                handleInputs={handleInputs}
                handleInput={handleInput}
                inputFuel={inputFuel}
                inputDate={inputDate}
                handleSubmit={handleSubmit}
                productData={productData}
                TankData={TankData}

                inputNozzle={inputNozzle}
                View_Density={View_Density}
            />) : null}
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="My dialog"
                className="mymodal custommodal"
                overlayClassName="myoverlay"
            >
                <div className="modal-content">
                    <div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">Density Record</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
                    <div className=" modal-body  modal-body"><p>Are you sure you want to delete this Density Record</p></div>
                    <div className="justify-content-end  modal-footer"><div className="d-flex">
                        <button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
                        {double == true ? <button type="submit" className="btn btn-primary" onClick={deleteDensity} >Yes</button> : <button type="submit" className="btn btn-primary" disabled onClick={deleteDensity} >Yes</button>}
                    </div>
                    </div>
                </div>
            </Modal>
            {/* {Message.status==true ?
    <Modal
        isOpenModal={isOpenModal}
        onRequestClose={toggleModal2}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
    >
        <div className="modal-content">
            <div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">DSR Report</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
            <div className=" modal-body  modal-body"><p>{Message.message}</p></div>
            <div className="justify-content-end  modal-footer"><div className="d-flex">
                <button type="button" className="btn btn-secondary me-2" onClick={toggleModal2}>No</button>
                <button type="submit" className="btn btn-primary" >Yes</button>
            </div>
            </div>
        </div>
    </Modal>
:null} */}

        </>
    )
}

export default DensityReport