import React from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip' 
import { Link, useLocation } from "react-router-dom";
const VendorModal = ({ prevNonNumericalWithDec,preventNonNumericalInput,isShowing,setProduct, hide,success,double, handleSubmit,list, dropdownData,handleSelect, handleInput,inputValues,selected_options, errors,list_data, handleInputs,stat_operation }) => {
  const location_details = useLocation()
  return (
  <React.Fragment>


<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">
        <div className="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Vendor Configuration</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="form-group">
          <label>Name <sup class="star">*</sup></label>
<input type="text" className="form-control " name="name" value={inputValues["name"]} onChange={handleInputs}  readOnly={inputValues["ob_is_dayclose"]}/>
<div className="errorMsg">{errors.name}</div>
</div>
<div className="form-group">
  <label>Address</label>
  <input type="text" className="form-control" name="address" value={inputValues["address"]} onChange={handleInputs}  />
  </div>
  <div className="form-group">
    <label>Phone</label>
    <input type="number" className="form-control hide-arrow ph_no" name="phone" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} value={inputValues["phone"]} id={"phone"} onChange={handleInputs}  />
    <div className="errorMsg">{errors.phone}</div>
    </div>
    <div className="form-group">
      <label>Email</label>
      <input type="text" className="form-control" name="email" value={inputValues["email"]} onChange={handleInputs} />
      <div className="errorMsg">{errors.email}</div>
      </div>
      <div className="form-group">
        <label>Credit Period in Days</label>
        <input type="number" className="form-control" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="credit_period" min={1} max={100} value={inputValues["credit_period"]} onChange={handleInputs} />
        <div className="errorMsg">{errors.credit_period}</div>
         </div>
        <div className="form-group">
        <label>Product Name</label>
        <Select   
     isMulti
       value={selected_options}
      //  value={inputValue.product_name}
       onChange={handleSelect}
  //    onBlur={()=>setProduct()}
      //  onChange={handleSelect}
       options={list_data}
      > 
      </Select>
      <Link to={location => ({ ...location, backpage: location_details.pathname, pathname: "/products/products" })} class="link-text"><a href="">Click here to add new products</a></Link>
        </div>
      <div className="row">
      {stat_operation != "Yes" ?
           <div className="col-md-8 ">
            
              <div className="form-group">
                  <label>Opening Balance<sup class="star">*</sup></label>
                  <input type="number" className="form-control hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="opening_balance" id={"open"} value={inputValues["opening_balance"]} onChange={ handleInput} readOnly={inputValues["ob_is_dayclose"]} />
                  <div className="errorMsg">{errors.opening_balance}</div>
                  <div className="errorMsg">{errors.debit_credit}</div>
                  <span id="words" class="amount-words"></span>
              </div>
             
          </div>  
          :""} 
{stat_operation != "Yes" ?
          <div className="col-md-4 mt-4 d-flex">
              <div className="form-check col-sm pr-0">
                  {/* <input className="form-check-input" type="radio"  name="debit_credit" value="Cr" onClick={ handleInputs} checked={inputValues.debit_credit === 'Cr'} /> */}
                  <input className="form-check-input" type="radio" data-tip='Need to pay the party'  id="exampleRadios1"  name="debit_credit" value="Cr" onClick={ handleInputs} checked={inputValues.debit_credit === 'Cr'} disabled={inputValues["ob_is_dayclose"]} />
                  <label className="form-check-label col-form-label" for="exampleRadios1">   Credit 
                  </label>
                  <ReactTooltip />
              </div>
              
              <div className="form-check col-sm pr-0">
              <input className="form-check-input" type="radio" data-tip='Party to pay us'  id="exampleRadios1"  name="debit_credit" value="Dr" onClick={ handleInputs} checked={inputValues.debit_credit === 'Dr'} disabled={inputValues["ob_is_dayclose"]} />
               {/* <input className="form-check-input" type="radio" name="debit_credit"  value="Dr" onClick={ handleInputs} checked={inputValues.debit_credit === 'Dr'} /> */}
                  <label className="form-check-label col-form-label" for="exampleRadios1">  Debit
              
                  </label>
                  <ReactTooltip />
              </div>
         
          </div>
          :""}
          
          

          
              

          <div class="modal-footer">
          {double===true ?    <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Save Vendor</button> : <button type="submit" class="btn btn-primary"   disabled>Save Vendor</button>}
          </div>
                  
      </div>

  </div>
    </div>
</React.Fragment>
        );
      }
       export default VendorModal;