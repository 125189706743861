
import React, {useMemo, useState } from 'react';
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import customer from '../../img/customer-review.png'
import CustomerConfigModal from './CustomerConfigModal'
import ReactDOM from 'react-dom';
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import {convertAmountToWords} from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';

const CustomerModal = (props) => ReactDOM.createPortal(
	<CustomerConfigModal  {...props} />, document.body
);
const  PaginationComp  = (props) => (
	< PaginationComponent  {...props} />
	   );
const Customer = (location) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

	console.log(location)
		const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
	  const pathName = useMemo(() => {
 
		if (location?.location?.pathname) {
		  return location?.location?.pathname
		}
		else
		  return undefined
	  }, [location?.location?.backpage])
	const [double, setDouble] = useState(true);

	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else
			{
				if (isShowing) {
					setInputValues({ vehicle_no: [''] })
					setErrors({ vehicle_no: [''] })
					setSuccess({})
					setFields([''])
				}
				if (!isShowing) {
                    setDouble(true)
					setErrors({ vehicle_no: [''] })

				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleName = (e) =>{
		if (!e.target.value.match(/^[a-zA-Z ]*$/)) {
			e.preventDefault()
		   }
		   else{
			setInputValues({
				...inputValues,
				[e.target.name]: e.target.value
			});  
		   }
	}
	const handleInputs = (e) => {
	
       
		if ((e.target.name === "credit_period" || e.target.name === "credit_amount")) {

			setInputValues({
				...inputValues,
				[e.target.name]: e.target.checked ? 1 : 0
			});
		}

		else
			setInputValues({
				...inputValues,
				[e.target.name]: e.target.value
			});
			
convertAmountToWords(e,e.target.value)

	};

	function handleChange(i, event) {
		const values = [...fields];
		values[i] = event.target.value;
		setFields(values);
		setInputValues({ ...inputValues, vehicle_no: values })
	}

	function handleDelete(i)
	{
		const values = [...fields];

			values.splice(i, 1);
			setFields(values);
			setInputValues({ ...inputValues, vehicle_no: values })
	}

	function handleAdd() {
		const values = [...fields];

		values.push('');
		setFields(values);
		setErrors({ vehicle_no: [''] })
	}
	function handleRemove(i) {
	
		var i = fields.length - 1
		if (i != 0) {
			const values = [...fields];

			values.splice(i, 1);
		
			setFields(values);
			setInputValues({ ...inputValues, vehicle_no: values })
		}

	}



	 //------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
			
			setOperational({
				...operational,
				status:responseData.stat_operation})
          
			if(responseData.allowurl == 1)
			{
				if(pathName == "/customers/credit-sales")
				{
					history.push(pathName);
				}
				else if(pathName == "/customers/dashboard")
				{
					history.push(pathName);
				}
				else
				{
				history.push(responseData.url);
				}
				ListCustomer(pages.current_page);
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
			console.log(e)
		});

}




const handleIn= (e) => {

convertAmountToWords(e,e.target.value)
setInputValues({
...inputValues,
[e.target.name]: e.target.value,

});}
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false)
		if (validateForm())
			sendCustomerData()

	}
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (!inputValues.name) {
			setDouble(true)
			formIsValid = false;
			errors["name"] = "Enter  Customer Name"
		}
		else {
			errors["name"] = ""
		}
	


		fields.map((inpuvalue, idx) => {
		
			if (!inpuvalue) {
				setDouble(true)
				formIsValid = false;
				// errors[idx]["vehicle_no"]="Enter Vehicle No"
				errors["vehicle_no"] ="Enter Vehicle Number"


			}
			else {
				errors["vehicle_no"] = ""
			}

		})

		if (inputValues.opening_balance != undefined) {
			if ((inputValues.opening_balance != "") && (inputValues.debit_credit == undefined || inputValues.debit_credit === "")) {
				formIsValid = false;
				setDouble(true)
				errors["debit_credit"] = " Select Debit/Credit"
			}

		}

		if(operational.status != "Yes"){

		if (inputValues.opening_balance == undefined) {
			formIsValid = false;
				setDouble(true)
				errors["debit_credit"] = "Enter Opening Balance"
			
		}
		
		}
		// if(opening_balance.start_operation == "yes"){
		// 	if (!fields["opening_balance"]) {
		// 		formIsValid = false;
		// 		errors["opening_balance"] = "Enter opening balance ";
		// 	  } else {
		// 		errors["opening_balance"] = "";
		// 	  }
		

		if ((inputValues.debit_credit) && (!inputValues.opening_balance)) {
			formIsValid = false;
			setDouble(true)
			errors["debit_credit"] = "Enter Opening Balance"
		}

		if (inputValues.credit_amount === 1 && !inputValues.credit_limit) {
			setDouble(true)
			formIsValid = false;
			errors["credit_limit"] = " Enter Credit Amount"


		}


		if ((inputValues.credit_period === 1) && (!inputValues.credit_limit_period)) {
            setDouble(true)
			formIsValid = false;
			errors["credit_limit_period"] = " Enter Credit Limit period"


		}
		if ((inputValues.credit_limit_period == "no_of_days" && !inputValues.credit_days)) {
			setDouble(true)
			formIsValid = false;
			errors["credit_days"] = " Enter Credit Days"


		}
		if (inputValues["phone"] ) 
		{
		 
		  if (inputValues["phone"].length != 10) {
			formIsValid = false;
			setDouble(true);
			errors["phone"] = "Enter Valid Phone Number";
	
		 }
		}
		if (inputValues["email"]) 
		{
		if (typeof inputValues["email"] !== "undefined") {
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(inputValues["email"])) {
			formIsValid = false;
			setDouble(true)
			errors["email"] = "Enter valid Email Id"
		  }
		}

		
	  }
	  
	// function validationform() {
	// 	var lng = responseData.lng;

	// 	let fields = responseData.fields;
	// 	 let errors = {};
	// 	 let formIsValid = true;
	// 	 if (responseData.start_operation == "Yes") {
	// 		if (!fields["opening_balance"]) {
	// 		  formIsValid = false;
	// 		  errors["opening_balance"] = "Enter opening_balance";
	// 		} else {
	// 		  errors["opening_balance"] = "";
	// 		}
	// 	}
	// }
		// else{
		// 	errors["credit_limit_period"] =""
		// }

		// console.log(inputValues.credit_limit_period)
		// if((inputValues.credit_period === 1 ) && (inputValues.credit_limit_period === undefined) ){

		// 	formIsValid = false;
		// 	errors["credit_limit_period"] = " Enter Credit Limit period" 


		// }

		// if((inputValues.credit_period === 1 ) && (inputValues.credit_limit_period === "no_of_days")  && (!inputValues.no_of_days)){

		// 	formIsValid = false;
		// 	errors["credit_limit_period"] = " Enter Credit Limit period" 


		// }
		// else{
		// 	errors["credit_limit_period"] = ""
		// }

		setErrors(errors)

		return formIsValid;


	}
	const [path, setLocation] = useState([])
	const [search, setSearch] = useState('')
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}


	useEffect(() => {
	  
		check_url();
	}, [location.location.pathname,location.location.backpage]);


	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number
		}
		)

		ListCustomer(number)

	}



	function setVehicle() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(inputValues)
		};
		fetch(API_URL + "/check-vehicle-no", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				swal("", responseData.message)
				// setList(responseData) 
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	//loader
const [Loading,setLoading]=useState(false);
	function ListCustomer(page) {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};


		fetch(`${API_URL}/customer?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => 
				{ 
				 if(!response.ok)
				 {
					 throw Error("Could not fetch the data for that resource")
				 }
					 return response.json(); })
			.then(responseData => {
                console.log(responseData)
				if(responseData.status==3)
				{
					setPermission({...permission,status:responseData.status,message:responseData.message})
				}
				setList(responseData.data.data)
				ckeckOption({...checkoption,
					isdayclose:responseData.isdayclose})
			

				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages:  responseData.data.per_page,
					total_pages:responseData.data.total,
				   
				  })
			setLoading(true)

				return responseData;
			})
			.catch(e => {
				console.log(e);
			

			});
	}
	function edit_customer(id, ac_name) {
		setErrors([])
		var isdayclose = checkoption.isdayclose;
		if(isdayclose == 1)
		{
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else
		{
		const requestOptions = {
			method: 'get',
			data: {
				id: id,
				ac_name: ac_name

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/customer/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				let vehicledata = [];
				responseData.data.custom_vehicle.forEach((elem) => {
					
					vehicledata.push(elem.vehicle_no);

				})
				if (vehicledata.length < 1) {
					vehicledata = [''];
				}
				setFields(vehicledata);
				setInputValues(
					
					{
						...inputValues,
						
						id: responseData.data.id ? responseData.data.id : '',
						ac_name: responseData.data.ac_name ? responseData.data.ac_name : '',
						ac_date: responseData.data.ac_date ? format(new Date(responseData.data.ac_date), 'dd-MM-yyyy') : '',
						name: responseData.data.name,
						address: responseData.data.address,
						phone: responseData.data.phone,
						email: responseData.data.email,
						credit_limit: responseData.data.credit_limit,
						vehicle_no: vehicledata,
						whatsapp_no: responseData.data.whatsapp_no,
						debit_credit: responseData.data.debit_credit,
						credit_days: responseData.data.credit_limit_period,
						credit_limit_period: isNaN(parseInt(responseData.data.credit_limit_period)) == false ? 'no_of_days' : responseData.data.credit_limit_period,
						opening_balance: responseData.data.opening_balance,
						credit_amount: responseData.data.credit_limit === null ? 0 : 1,
						credit_period: responseData.data.credit_limit_period === null ? 0 : 1,
						start_operation: "No",
						
						ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,

					}
				);


				toggle();

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
		}
	}
	function delete_Customer(id,outstanding) {
		var isdayclose = checkoption.isdayclose;
		if(isdayclose == 1)
		{
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else if(outstanding != 0)
		{
			swal("", "You can delete only if the customer Opening balance is zero", false ? "success" : "warning")
		}
		else
		{
			deleteId(id)
			toggleModal()
		}
	}

	function deleteCustomer() {
		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/customer/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				ListCustomer(pages.current_page);
				toggleModal()
                setDouble(true)
				return responseData;


			})

			.catch(err => {
				swal("Something went wrong","","warning")
				console.log(err)

			})
		
	}

	
	function sendCustomerData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify(inputValues)
		};
		fetch(API_URL + "/customer", requestOptions)
			.then(response => {
				if(!response.ok)
				{
					alert("something went wrong")
				}
				return response.json();
			})
			.then(responseData => {
console.log(responseData,"ooo")
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors=[]
				if (responseData.status == false) {

					if(responseData.response){
						errors["name"] = responseData.response.name
						errors["opening_balance"] = responseData.response.opening_balance
						errors["phone"] = responseData.response.phone
						errors["whatsapp_no"] = responseData.response.whatsapp_no
	
						errors["email"] = responseData.response.email
						errors["vehicle_no"] = responseData.response.vehicle_no
						setDouble(true)
					}else{
						swal("", responseData.message, responseData.status ? "success" : "warning")
						setDouble(true)
					}
					
					
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					// history.push("/customers")
					setErrors([''])
					setInputValues([])
                     setDouble(true)
					toggle();
					ListCustomer(pages.current_page);
					//   setErrors({})
				}
				if (responseData.status == 5) {
					swal("", responseData.message)
					setDouble(true)
				}
				
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				swal("Something went wrong","","warning")
				console.log(e);
				setDouble(true)
			});
	}
	const [delete_id, deleteId] = useState([])
	const { isShowing, toggle } = UseModal();
	const [permission, setPermission] = useState([]);	
	const [checkoption, ckeckOption] = useState([])
	const [inputValues, setInputValues] = useState({ vehicle_no: [''] });
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [list_data, setList] = useState([])
	const [operational, setOperational] = useState([])
	const [errors, setErrors] = useState({ vehicle_no: [''] })
	const [success, setSuccess] = useState([])
	return (
		<>
			<div className="main-container page-wrap">
		
				<div className="d-md-flex">
				{pathName == "/customers/credit-sales" || pathName == "/customers/dashboard" ? <Sidebar_Progress  path={pathName} />:
				<Sidebar_Progress />}
					<div className="content-area">
						<LoginHeader page_title="" />
						{permissions.includes("Customers Opening Balance") == false ?
	<div  className="form-wrap mt-5">
  <div className="text-center">
  You don't have permission to access this page
  </div></div>:

						<div className="container pb-3">

											<div class="row">
								<div className="col-md-12 px-4 mt-3">
								{pathName == "/customers/credit-sales"? 
								<Link to={backPage || "/operational-date"}><a  className="float-end btn btn-gray" href="/credit-sales">Go Back</a></Link>
									:
									pathName == "/customers/dashboard"? 
								<Link to={backPage || "/dashboard"}><a  className="float-end btn btn-gray" href="/dashboard">Go Back</a></Link>
									:
									 <Link to="/users">
								<a href="users" className="float-end btn btn-gray">Back</a>
									</Link>}
									</div>

									</div>
							<div className="row justify-content-center mt-md-3 mt-3 form-container">
								<div className="col-md-12">
								{permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<> 

									<div className="form-wrap list-wrp">
									{Loading ? ListCustomer : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	
	}
										<div className="row mb-3">
											<div className="col-md">
												<div className="row">
													<div className="col-md-1 col-2 pe-0">
														<img src={customer} width="25px" className="img-fluid" alt=".." />
													</div>
													<div className="col-md-7 col pl-0">
														<h3 className="table-title">Credit Customer Opening Balance</h3>
													</div>
													<div className="col-md">
												<a className="btn btn-primary px-3 mb-md-0 mb-2 "data-bs-target="#add-customer" onClick={toggle}>ADD CUSTOMER</a>

													</div>
												</div>
											</div>
											<div className="col-md-4">
											<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch}  />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

															</div>
											</div>
										</div>


										<div className="table-widget">
											<table className="table border">
												<thead>
													<tr>
														<th>Name</th>
														<th>Address</th>
														<th>Phone</th>
														<th>Email</th>
														<th>Credit Limit</th>
														<th>Whatsapp No</th>
														<th>Vehicle No</th>
														<th>Configured Opening Balance</th>
														<th>Credit/Debit</th>
														<th>Action</th>
													</tr>
												</thead>
											

												<tbody>
													{
														list_data.map(customer => (
															<tr>
																<td>{customer.name}</td>
																<td>{customer.address}</td>
																<td>{customer.phone}</td>
																<td>{customer.email}</td>
																<td>{customer.credit_limit}</td>
																<td>{customer.whatsapp_no}</td>
																<td>{customer.vehicle_number}</td>
																<td className="text-end">{customer.opening_balance}</td>
																<td>{customer.debit_credit =="Cr" ?"Credit": customer.debit_credit =="Dr" ? "Debit" :''}</td>
																<td><div className="d-flex icon-btn">
																	<button onClick={() => edit_customer(customer.id, customer.ac_name)} >  <i className="fa fa-pencil" aria-hidden="true"></i>
																	</button>
																	<button data-bs-toggle="tooltip" type="submit" data-bs-placement="bottom" title="Delete" onClick={() => delete_Customer(customer.id,customer.outstanding)} >  <i className="fa fa-trash" aria-hidden="true"></i>

																	</button>


																</div></td>
															</tr>
														))}
												</tbody>
											</table>
											{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
										</div>
									</div>
									<div className="text-end mt-4">{pathName == "/customers/credit-sales" || pathName == "/customers/dashboard" ? "":<a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/creditor-debitor")}>Skip/Continue</a>}</div>
									</>
							}
								</div>
							</div>
							
						</div>

						}
					</div>
				</div>
			</div>
			{isShowing ? (<CustomerModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				handleIn={handleIn}
				inputValues={inputValues}
				fields={fields}
				success={success}
				double={double}
				handleName={handleName}
				setVehicle={setVehicle}
				// dropdownData ={dropdownData}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				handleAdd={handleAdd}
				handleDelete={handleDelete}
				handleRemove={handleRemove}
				errors={errors}
				operational={operational}
				setInputValues={setInputValues}
			/>) : null
			}

			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div class="modal-content">
					<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Customers</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div class=" modal-body  modal-body"><p>Are you sure you want to delete this customer</p></div>
					<div class="justify-content-end  modal-footer"><div class="d-flex">
						<button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						{double == true ?<button type="submit" class="btn btn-primary" onClick={deleteCustomer}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={deleteCustomer}>Yes</button>}
					</div>
					</div>
				</div>




			</Modal>
		</>
	)
}








export default Customer