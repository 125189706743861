import { Component } from "react";
import LoginHeaderOperational from './LoginHeaderOperational';
import Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg';
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';

const DailyTransactionSheet = (props) => {

  const [opening_balance, setOpeningBalance] = useState([])

  const [inputValues, setInputValues] = useState({})

  const [fule_sale, setFuleSale] = useState([])
  const [fule_sale_sum, setFuleSaleSum] = useState([])
  const [fule_sale_outstanding, setFuleSaleOutstanding] = useState([])

  const [Loose_oil, setLooseOil] = useState([])
  const [Loose_oil_sum, setLooseOilSum] = useState([])
  const [Loose_oil__outstanding, setLooseOilOutstanding] = useState([])

  const [paked_oil, setPakedOil] = useState([])
  const [paked_oil_sum, setPakedOilSum] = useState([])
  const [paked_oil_outstanding, setPakedOilOutstanding] = useState([])

  const [reciept, setReciept] = useState([])
  const [receipt_sum, setReceiptSum] = useState([])
  const [reciept_outstanding, setRecieptOutstanding] = useState([])

  const [other_income, setOtherIncome] = useState([])
  const [other_income_sum, setOtherIncomeSum] = useState([])
  const [other_income_outstanding, setOtherIncomeOutstanding] = useState([])

  const [credit_sales, setCreditsales] = useState([])
  const [credit_sales_sum, setCreditSalesSum] = useState([])
  const [credit_sales_outstanding, setCreditsalesOutstanding] = useState([])

  const [recoup, setRecoup] = useState([])
  const [recoup_sum, setRecoupSum] = useState([])
  const [recoup_outstanding, setRecoupOutsatanding] = useState([])

  const [mla_sales, setMLASales] = useState([])
  const [mla_sales_sum, setMLASalesSum] = useState([])
  const [mla_sales_outstanding, setMLASalesOutstanding] = useState([])

  const [fleet_sales, setFleetales] = useState([])
  const [fleet_sales_sum, setFleetSalesSum] = useState([])
  const [fleet_sales_outstanding, setFleetalesOutstanding] = useState([])

  const [expense_voucher, setExpenseVoucher] = useState([])
  const [expense_voucher_sum, setExpenseVoucherSum] = useState([])
  const [expense_voucher_outstanding, setExpenseVoucherOutstanding] = useState([])

  const [payment_voucher, setPaymentVoucher] = useState([])
  const [payment_voucher_sum, setPaymentVoucherSum] = useState([])
  const [payment_voucher_outstanding, setPaymentVoucherOutstanding] = useState([])

  const [digital_payment, setDigitalPayment] = useState([])
  const [digital_payment_sum, setDigitalPaymentSum] = useState([])
  const [digital_payment_outstanding, setDigitalPaymentOutstanding] = useState([])

  const [non_fuel_purchase, setNonFuelPurchase] = useState([])
  const [non_fuel_purchase_sum, setNonFuelPurchaseSum] = useState([])
  const [non_fuel_purchase_outstanding, setNonFuelPurchaseOutstanding] = useState([])

  const [short_excess, setShortExcess] = useState([])
  const [short_excess_sum, setShortExcessSum] = useState([])
  const [short_excess_outstanding, setShortExcessOutstanding] = useState([])

  const [fuel_purchase, setFuelPurchase] = useState([])
  const [fuel_purchase_sum, setFuelPurchaseSum] = useState([])
  const [fuel_purchase_outstanding, setFuelPurchaseOutstanding] = useState([])



  const [bank_transcation, setBankTranscation] = useState([])
  const [bank_transcation_sum, setBankTranscationSum] = useState([])
  const [bank_transcation_outstanding, setBankTranscationOutstanding] = useState([])
  const [closing_cash, setClosing] = useState([])

  const [pump, setPump] = useState({})
  const [input, setInput] = useState({})
  const [show, setShow] = useState(false)





  const history = useHistory()
  const [state, setState] = useState([])
  const [all, setAll] = useState([])
  var buffer = ''; var flag = 0;

  useEffect(() => {


  }, [])
  const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

  };
  const handleSubmit = (event) => {

    getDailySheet();
  }

  async function getDailySheet() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify({
        from_date: inputValues.start_date,
        to_date: inputValues.end_date,
        type: "1"
      })
    };
    fetch(API_URL + "/get_daily_transaction", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        console.log(responseData)

        setOpeningBalance(responseData.opening_balance)

        setFuleSale(responseData.fuel_sales_detailed)
        setFuleSaleSum(responseData.fuel_sales_detailed_sum)
        setFuleSaleOutstanding(responseData.fuel_sales_outstanding)

        setLooseOil(responseData.loose_oil_sales_detailed)
        setLooseOilSum(responseData.loose_oil_sales_detailed_sum)
        setLooseOilOutstanding(responseData.looseoil_outstanding)

        setPakedOil(responseData.packed_oil_sales_detailed)
        setPakedOilSum(responseData.packed_oil_sales_detailed_sum)
        setPakedOilOutstanding(responseData.packedoil_outstanding)

        setReciept(responseData.reciept_voucher_detailed)
        setReceiptSum(responseData.reciept_voucher_deailed_sum)
        setRecieptOutstanding(responseData.receipts_outstanding)

        setCreditsales(responseData.credit_sales_detailed)
        setCreditSalesSum(responseData.credit_sales_detailed_sum)
        setCreditsalesOutstanding(responseData.credit_sales_outstanding)

        setFleetales(responseData.fleet_card_detailed)
        setFleetSalesSum(responseData.fleet_card_detailed_sum)
        setFleetalesOutstanding(responseData.fleet_card_outstanding)

        setRecoup(responseData.recoup_detailed)
        setRecoupSum(responseData.recoup_detailed_sum)
        setRecoupOutsatanding(responseData.recoup_outstanding)

        setMLASales(responseData.mla_coupon_detailed)
        setMLASalesSum(responseData.mla_coupon_detailed_sum)
        setMLASalesOutstanding(responseData.mla_outstanding)

        setExpenseVoucher(responseData.expense_voucher_detailed)
        setExpenseVoucherSum(responseData.expense_voucher_detailed_sum)
        setExpenseVoucherOutstanding(responseData.expense_voucher_outstanding)


        setPaymentVoucher(responseData.payment_voucher_detailed)
        setPaymentVoucherSum(responseData.payment_voucher_detailed_sum)
        setPaymentVoucherOutstanding(responseData.payment_voucher_outstanding)



        setDigitalPayment(responseData.digital_payment_detailed)
        setDigitalPaymentSum(responseData.digital_payment_detailed_sum)
        setDigitalPaymentOutstanding(responseData.card_digital_outstanding)

        setNonFuelPurchase(responseData.non_fuel_purchase_detailed)
        setNonFuelPurchaseSum(responseData.non_fuel_purchase_detailed_sum)
        setNonFuelPurchaseOutstanding(responseData.non_fuel_purchase_outstanding)

        setShortExcess(responseData.short_excess_detailed)
        setShortExcessSum(responseData.short_excess_detailed_sum)
        setShortExcessOutstanding(responseData.short_excess_outstanding)

        setOtherIncome(responseData.other_income_detailed)
        setOtherIncomeSum(responseData.other_income_detailed_sum)
        setOtherIncomeOutstanding(responseData.other_income_outstanding)


        setBankTranscation(responseData.bank_transaction_detailed)
        setBankTranscationSum(responseData.bank_transaction_detailed_sum)
        setBankTranscationOutstanding(responseData.bannk_transaction_outstanding)

        setFuelPurchase(responseData.fule_purchase_transactions)
        setFuelPurchaseSum(responseData.fule_purchase_transactions_sum)
        setFuelPurchaseOutstanding(responseData.fuel_purchase_outstanding)

        setClosing(responseData.closing_cash)

        var pump_name = responseData.pump_data[0].pump_name
        var pump_address = responseData.pump_data[0].pump_address
        setPump({
          ...pump,
          pump_name: pump_name,
          pump_address: pump_address

        })

        var start_date = responseData.start_date
        var end_date = responseData.end_date
        console.log(end_date)
        setInput({
          ...input,
          start_date: start_date,
          end_date: end_date

        })






      })
      .catch(e => {
      });
  }
  function savesheet() {

  }
  var fuel_buffer = "";
  var sum = 0;
  var show_sum = 0;
  return (

    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={14} operation={3} op={3} />
          <div className="content-area">
            <LoginHeaderOperational props={props} page_title="  " />

            {

            }

            <div className="container pb-3">
              <div className="row justify-content-center  mt-3 form-container">
                <div className="col-md-12">
                  <div className="row mb-3">
                    <div className="col-md-8 d-flex">
                      <div className="text-start d-flex pe-1 mt-3">
                        <div className="form-group">
                          <label  >Start date</label>
                          <input type="date" className="form-control  " placeholder="" name="start_date" value={inputValues.start_date} onChange={handleInput}></input>
                        </div>
                      </div>
                      <div className="text-start d-flex pe-3 mt-3">
                        <div className="form-group">
                          <label  >End date</label>
                          <input type="date" className="form-control  " placeholder="" name="end_date" value={inputValues.end_date} min={inputValues.start_date} onChange={handleInput}></input>
                        </div>
                      </div>
                      <div className="text-start d-flex pe-4 mt-5">
                        <div className="form-group">
                          <a className="btn btn-primary mb-md-0  ms-1" onClick={handleSubmit}>Submit </a>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="text-end mt-5"><a className="btn btn-success px-3 mb-md-0 mb-3 me-3" onClick={(event) => history.push("/daily-transaction-summary")}>Daily Summary Report </a>
                        </div>
                      </div>
                    </div>


                  </div>

                  <div className="form-wrap balace-sheetwrap">

                    <div className="row mb-3">
                      <div className="col-md ">
                        <h2 className="table-title  mb-1">{pump.pump_name ? pump.pump_name : "COMPANY NAME"}</h2>
                        <h4 className="table-title mb-1">Daily Transaction Sheet Detailed</h4>
                        <h5>  {input.start_date ? <>{input.start_date} -- {input.end_date}</> : "DATE"}</h5>
                        <img src={logo} className="img-fluid float-end " width="100px" />
                      </div>
                    </div>






                    <div className="table-widget">
                      <table className="table border">
                        <thead>
                          <tr class="border">
                            <th>Opening balance</th>
                          </tr>

                        </thead>
                        <tbody>
                          <tr>
                            <td>{opening_balance}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="table-widget">
                      <h3 className="table-title text-start" >Fuel Sales</h3>
                      <table className="table border">

                        <thead>
                          <tr class="border">
                            <th class="">Product Name</th>
                            <th>Nozzle Name</th>
                            <th>Opening Reading </th>
                            <th>Closing Reading</th>
                            <th>Quantity</th>
                            <th>Price</th>

                            <th >Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>
                          </tr>
                        </thead>

                        <tbody>
                          {

                            fule_sale.map((obj) => {

                              if (fuel_buffer == '') {
                                fuel_buffer = obj.fuel_name;
                              }

                              if (fuel_buffer == obj.fuel_name) {
                                sum = sum + Number(obj.dr_amount);

                                return (
                                  <>
                                    <tr>
                                      <td>{obj.fuel_name}</td>
                                      <td>{obj.nozzle_name}</td>
                                      <td>{obj.opening_reading}</td>
                                      <td>{obj.close_reading}</td>
                                      <td>{obj.quantity}</td>
                                      <td>{obj.price}</td>
                                      <td>{obj.dr_amount}</td>
                                      <td></td>
                                      <td></td>

                                    </tr>

                                  </>
                                )

                              }
                              else {
                                fuel_buffer = obj.fuel_name;
                                show_sum = sum;
                                sum = Number(obj.dr_amount);
                                return (
                                  <>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>Product Wise sub Total</td>
                                      <td>{show_sum}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>

                                    <tr>
                                      <td>{obj.fuel_name}</td>
                                      <td>{obj.nozzle_name}</td>
                                      <td>{obj.opening_reading}</td>
                                      <td>{obj.close_reading}</td>
                                      <td>{obj.quantity}</td>
                                      <td>{obj.price}</td>
                                      <td>{obj.dr_amount}</td>
                                      <td></td>
                                      <td></td>
                                    </tr>

                                  </>
                                )
                              }
                            }
                            )}

                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Product Wise sub Total</td>
                            <td>{sum}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>


                          <tr class="">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total Fuel Sale </td>
                            <td> {fule_sale_sum}</td>
                            <td>{fule_sale_outstanding}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>



                    <div className="table-widget">
                      <h1 class="table-title text-start">Loose Oil Sales</h1>
                      <table className="table border">

                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th >Quantity</th>
                            <th >Price</th>
                            <th ></th>
                            <th >Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>




                          </tr>
                        </thead>

                        <tbody>
                          {
                            Loose_oil.map((obj) => (

                              <tr>
                                <td>{obj.product_name}</td>
                                <td>{obj.quantity}</td>
                                <td>{obj.selling_price}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.cr_amount}</td>
                                <td></td>



                              </tr>

                            ))}

                          <tr >

                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>




                          </tr>
                          <tr >
                            <td></td>
                            <td></td>
                            <td></td>
                            <td > Total Loose Oil Sales </td>
                            <td>{Loose_oil_sum}</td>
                            <td></td>
                            <td>{Loose_oil__outstanding}</td>

                          </tr>

                        </tbody>
                      </table>
                    </div>


                    <div className="table-widget">

                      <h3 className="table-title text-start" >Packed Oil  and Other Sales</h3>
                      <table className="table border">
                        <thead>
                          <tr class="border">
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>




                          </tr>
                        </thead>

                        <tbody>
                          {
                            paked_oil.map((obj) => (

                              <tr class="border">
                                <td>{obj.product_name}</td>
                                <td>{obj.quantity}</td>
                                <td>{obj.selling_price}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.cr_amount}</td>
                                <td></td>




                              </tr>

                            ))}

                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total Paked Oil  and Other Sales </td>
                            <td>{paked_oil_sum}</td>
                            <td></td>
                            <td>{paked_oil_outstanding}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="table-widget">
                      <h3 className="table-title text-start" >Receipts </h3>
                      <table class="table border ">
                        <thead>
                          <tr >
                            <th>Party Name </th>
                            <th>A/C Category</th>
                            <th>A/C Sub Category</th>
                            <th>System Voucher Number</th>
                            <th>Voucher Number</th>
                            <th>Description</th>

                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>


                          </tr>

                        </thead>
                        <tbody>
                          {
                            reciept.map((obj) => (

                              <tr class="border">
                                <td>{obj.name}</td>
                                <td>{obj.category}</td>
                                <td>{obj.ac_name_slug}</td>
                                <td>{obj.sys_voucher_no}</td>
                                <td>{obj.voucher_no}</td>
                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.amount}</td>
                                <td>{obj.cr_amount}</td>
                                <td></td>

                              </tr>

                            ))}

                          <tr class="border">

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>



                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total Receipts</td>
                            <td>  {receipt_sum}</td>
                            <td></td>
                            <td>{reciept_outstanding}</td>

                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <div className="table-widget">

                      <h3 className="table-title text-start" >Other Income</h3>
                      <table className="table border">
                        <thead>
                          <tr class="border">
                            <th>Account  Name</th>
                            <th>Income Sub Head</th>
                            <th> System Voucher Number </th>
                            <th>Payment Voucher Number</th>
                            <th>Description</th>
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>

                          </tr>

                        </thead>
                        <tbody>
                          {
                            other_income.map((obj) => (

                              <tr class="border">
                                <td>{obj.name}</td>
                                <td>{obj.ac_name_slug}</td>
                                <td>{obj.sys_voucher_no}</td>
                                <td>{obj.voucher_no}</td>
                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.amount}</td>
                                <td>{obj.cr_amount}</td>
                                <td></td>



                              </tr>

                            ))}

                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Total Other Income</td>
                            <td>{other_income_sum}</td>
                            <td></td>
                            <td>{other_income_outstanding}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <div class="table-widget">
                      <h3 className="table-title text-start" >Credit Sales </h3>


                      <table class="border table">
                        <thead>
                          <tr class="border">
                            <th>Coustmer Name </th>
                            <th>Intent Number</th>
                            <th>Vechile number</th>
                            <th> Product </th>
                            <th> Quantity</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>




                          </tr>

                        </thead>
                        <tbody>
                          {
                            credit_sales.map((obj) => (

                              <tr class="border">
                                <td>{obj.name}</td>
                                <td>{obj.intent_no}</td>
                                <td>{obj.vehicle_no}</td>
                                <td>{obj.fuel_name}</td>
                                <td>{obj.quantity}</td>
                                <td>{obj.price}</td>
                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>
                                <td></td>




                              </tr>

                            ))}

                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total Credit Sales-</td>
                            <td> {credit_sales_sum}</td>
                            <td></td>
                            <td>{credit_sales_outstanding}</td>
                          </tr>

                        </tbody>

                      </table>
                    </div>

                    <div class="table-widget">

                      <h3 className="table-title text-start" >Recoupe</h3>
                      <table class="table border">
                        <thead>
                          <tr class="border">
                            <th>Product Name </th>
                            <th>Recoup Tank</th>
                            <th>Quantitiy</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th></th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>OutStanding</th>



                          </tr>

                        </thead>
                        <tbody>
                          {
                            recoup.map((obj) => (

                              <tr class="border">
                                <td>{obj.fuel_name}</td>
                                <td>{obj.tank_name}</td>

                                <td>{obj.quantity}</td>
                                <td>{obj.price}</td>

                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>
                                <td></td>




                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td >Total Recoup  </td>
                            <td>{recoup_sum}</td>
                            <td></td>
                            <td>{recoup_outstanding}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <div class="table-widget">
                      <h3 className="table-title text-start" >MLA Coupon </h3>
                      <table class="table border">
                        <thead>
                          <tr class="border">
                            <th>Issue Date </th>
                            <th>Coupon Number</th>
                            <th>Expiry Date</th>
                            <th>Product </th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>
                          </tr>

                        </thead>
                        <tbody>
                          {
                            mla_sales.map((obj) => (

                              <tr>
                                <td>{obj.issue_date}</td>
                                <td>{obj.coupon_no}</td>
                                <td>{obj.expiry_date}</td>
                                <td>{obj.fuel_name}</td>
                                <td>{obj.quantity}</td>
                                <td>{obj.price}</td>
                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>
                                <td></td>


                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total MLA Coupon </td>
                            <td>{mla_sales_sum}</td>
                            <td></td>
                            <td>{mla_sales_outstanding}</td>
                          </tr>

                        </tbody>

                      </table>

                    </div>

                    <div class="table-widget">
                      <h3 className="table-title text-start" >Fleet card sales </h3>
                      <table class="table border">
                        <thead>
                          <tr class="border">
                            <th>card Number </th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>
                          </tr>

                        </thead>
                        <tbody>
                          {
                            fleet_sales.map((obj) => (

                              <tr class="border">
                                <td>{obj.card_no}</td>
                                <td>{obj.fuel_name}</td>

                                <td>{obj.quantity}</td>
                                <td>{obj.price}</td>
                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>
                                <td></td>

                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>


                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total fleet card sales</td>
                            <td>{fleet_sales_sum}</td>
                            <td></td>
                            <td>{fleet_sales_outstanding}</td>

                          </tr>

                        </tbody>

                      </table>
                    </div>

                    <div class="table-widget">
                      <h3 className="table-title text-start" >Expense Voucher</h3>
                      <table class="table border">
                        <thead>
                          <tr class="border">
                            <th>Account Name</th>
                            <th>System voucher Number</th>
                            <th>Voucher Number</th>
                            <th>Expense Category </th>
                            <th>Expense Sub Category</th>
                            <th>Description</th>

                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>


                          </tr>

                        </thead>
                        <tbody>
                          {
                            expense_voucher.map((obj) => (

                              <tr class="border">
                                <td>{obj.name}</td>
                                <td>{obj.sys_voucher_no}</td>
                                <td>{obj.voucher_no}</td>
                                <td>{obj.category}</td>
                                <td>{obj.ac_name_slug}</td>
                                <td>{obj.note}</td>

                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>
                                <td></td>





                              </tr>

                            ))}
                          < tr class="border ">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>


                            <td class="sub-total">Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">

                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total Expense Voucher </td>
                            <td>  {expense_voucher_sum}</td>
                            <td></td>
                            <td>{expense_voucher_outstanding}</td>
                          </tr>

                        </tbody>

                      </table>

                    </div>
                    <div class="table-widget">

                      <h1 className="table-title text-start" >Payment Voucher</h1>

                      <table class="table border">
                        <thead>
                          <tr class="border">
                            <th>Party Name</th>
                            <th>System Vocuher</th>
                            <th>Vocuher Number</th>
                            <th>A/c Category</th>
                            <th>A/c Sub Category</th>
                            <th>Descriptinon</th>

                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>


                          </tr>

                        </thead>
                        <tbody>
                          {
                            payment_voucher.map((obj) => (

                              <tr class="border">
                                <td>{obj.name}</td>
                                <td>{obj.sys_voucher_no}</td>
                                <td>{obj.voucher_no}</td>

                                <td>{obj.category}</td>
                                <td>{obj.ac_name_slug}</td>
                                <td>{obj.note}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>
                                <td></td>

                                {/* <td>{obj.amount}</td> */}
                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>



                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>


                            <td> Total Payment Voucher </td>
                            <td>{payment_voucher_sum}</td>
                            <td></td>
                            <td>{payment_voucher_outstanding}</td>


                          </tr>

                        </tbody>

                      </table>
                    </div>


                    <div class="table-widget">
                      <h3 className="table-title text-start" >Card/Digital Payment</h3>
                      <table class="border table">
                        <thead>
                          <tr>
                            <th>Payment Type</th>
                            <th>Bank Name </th>

                            <th>Card Number </th>
                            <th>Description</th>
                            {/* <th>Quantity</th> */}
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>
                          </tr>

                        </thead>
                        <tbody>
                          {
                            digital_payment.map((obj) => (

                              <tr>
                                <td>{obj.method_name}</td>
                                <td>{obj.bank_name}</td>
                                <td>{obj.card_no}</td>
                                <td>{obj.note}</td>
                                <td></td>


                                <td>{obj.dr_amount}</td>
                                <td>{obj.amount}</td>

                                <td></td>






                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total Card/Digital Payment </td>
                            <td>{digital_payment_sum}</td>
                            <td></td>
                            <td>{digital_payment_outstanding}</td>
                          </tr>

                        </tbody>

                      </table>
                    </div>


                    <div class="table-widget">
                      <h3 className="table-title text-start" >Short/Excess</h3>
                      <table class="border table">
                        <thead>
                          <tr class="border">
                            <th> Employee  Name</th>
                            <th>Short  Amount</th>
                            <th> Exces Amount  </th>
                            <th></th>
                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>

                          </tr>

                        </thead>
                        <tbody>
                          {
                            short_excess.map((obj) => (

                              <tr class="border">
                                <td>{obj.name}</td>
                                <td>{obj.short_excess_amount}</td>
                                <td>{obj.short_excess}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.cr_amount}</td>
                                <td></td>

                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>




                            <td>Product Wise sub Total</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>

                            <td>Total Short/Excess </td>
                            <td> {short_excess_sum}</td>
                            <td></td>
                            <td>{short_excess_outstanding}</td>

                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <div class="table-widget">

                      <h3 className="table-title text-start" >Bank Transactions</h3>
                      <table class="border table">
                        <thead>
                          <tr class="border">
                            <th>Transcation Type</th>
                            <th>Bank Name</th>
                            <th>Descripition</th>
                            <th>Party/Account Reffernce</th>
                            <th>Check Number</th>
                            <th></th>


                            <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th>


                          </tr>

                        </thead>
                        <tbody>
                          {
                            bank_transcation.map((obj) => (

                              <tr>
                                <td>{obj.transaction_type}</td>
                                <td>{obj.bank_name}</td>
                                <td>{obj.note}</td>

                                <td>{obj.partyorreference}</td>
                                <td>{obj.cheque_no}</td>
                                <td></td>

                                <td>{obj.dr_amount}</td>
                                <td>{obj.cr_amount}</td>
                                <td></td>

                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>



                            <td> sub Total </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>


                            <td>Total  Bank Transactions </td>
                            <td>{bank_transcation_sum}</td>
                            <td></td>
                            <td>{bank_transcation_outstanding}</td>

                          </tr>

                        </tbody>

                      </table>

                    </div>


                    <div className="table-widget">
                      <table className="table border">
                        <thead>
                          <tr class="border">
                            <th>Closing Cash</th>
                          </tr>

                        </thead>
                        <tbody>
                          <tr>
                            <td>{closing_cash}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="table-widget">






                      <h1 className="table-title text-start" >Other Information</h1>
                      <h1 className="table-title text-start" >Fuel Purchase Transaction</h1>

                      <table class="table border">
                        <thead>
                          <tr class="border">
                            <th>Product Name</th>
                            <th> Quantity</th>
                            <th>Vendor Name </th>
                            <th>Invoice Number </th>
                            <th>Amount</th>
                            <th></th>
                            {/* <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th> */}
                            <th></th>


                          </tr>

                        </thead>
                        <tbody>
                          {
                            fuel_purchase.map((obj) => (

                              <tr class="border">
                                <td>{obj.fuel_name}</td>
                                <td>{obj.quantity}</td>
                                <td>{obj.dealer_name}</td>
                                <td>{obj.invoice_no}</td>
                                <td>{obj.amount}</td>
                                <td></td>

                                {/* <td>{obj.dr_amount}</td>
                                <td>{obj.cr_amount}</td> */}
                                <td></td>

                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>


                            <td>Product Wise sub Total</td>

                            <td></td>
                            {/* <td></td>
                            <td></td> */}
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>


                            <td>Total Fuel Sale </td>
                            <td>{fuel_purchase_sum}</td>
                            {/* <td></td>
                            <td>{fuel_purchase_outstanding}</td> */}
                          </tr>

                        </tbody>
                      </table>

                    </div>

                    <div class="table-widget">

                      <h3 className="table-title text-start" >Non Fuel Purchase Transaction</h3>


                      <table class="table border">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Total Quantity </th>
                            <th>Invoice Number</th>
                            <th>Vendor Name</th>
                            <th>Amount</th>
                            <th></th>
                            {/* <th>Debit(RS)</th>
                            <th>Credit(RS)</th>
                            <th>Outstanding(RS)</th> */}
                            <th></th>

                          </tr>

                        </thead>
                        <tbody>
                          {
                            non_fuel_purchase.map((obj) => (

                              <tr>
                                <td>{obj.product_name}</td>
                                <td>{obj.quantity}</td>
                                <td>{obj.total_quantity}</td>
                                <td>{obj.invoice_number}</td>
                                <td>{obj.name}</td>

                                <td>{obj.amount}</td>

                                <td></td>

                                {/* <td>{obj.dr_amount}</td>
                                <td>{obj.cr_amount}</td> */}
                                <td></td>

                              </tr>

                            ))}
                          < tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>




                            <td>Product Wise sub Total</td>
                            <td></td>
                            {/* <td></td>
                            <td></td> */}
                          </tr>
                          <tr class="border">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>




                            <td>Total Non Fuel Purchase Transaction</td>
                            <td>{non_fuel_purchase_sum}</td>
                            {/* <td></td>
                            <td>{non_fuel_purchase_outstanding}</td> */}
                          </tr>

                        </tbody>
                      </table>

                    </div>




                  </div>
                </div>
                <div className="col-md">
                  <div className="text-end mt-4"><a className="btn btn-success px-3 mb-md-0 mb-3 me-3" onClick={savesheet}>Save Sheet </a>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>


    </>
  )
}


export default DailyTransactionSheet