import React, { useState } from "react";
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from "../../../../img/customer-review.png";
import { preventNonNumericalInput } from "../../../layouts/Operational_Sidebar_Progress.component";
import ReactDOM from "react-dom";
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from "../ManagerLoginHeader.component";
import PurchaseReturnNonFuelModal from "./PurchaseReturnNonFuelModal.component";
import Loader from "../../Employee/Loader.component";
import ManagerApprovalConfig from "../ManagerApprovalConfig.component";
import PaginationComponent from "../../../layouts/PaginationComponent";
import moment from "moment";

const ApprovalModal = (props) =>
  ReactDOM.createPortal(<ManagerApprovalConfig {...props} />, document.body);
const PaginationComp = (props) => <PaginationComponent {...props} />;
const PurchaseReturnModal = (props) =>
  ReactDOM.createPortal(
    <PurchaseReturnNonFuelModal {...props} />,
    document.body
  );
const PurchaseReturnNonFuelConfig = (props) => {
  let permissions = localStorage
    .getItem("PERMISSIONS", "PERMISSIONS")
    ?.split(",");

  const [approval_id, approvalId] = useState([]);
  const [approval_status, approvalStatus] = useState([]);
  const [delete_id, deleteId] = useState([]);
  const [fields, setFields] = useState([""]);
  const [inputValues, setInputValues] = useState("");
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [startDate, setDate] = useState([]);
  const [search, setSearch] = useState("");
  const [dropdownData, setDropdownData] = useState([]);
  const [dropdowns, setDropdowns] = useState([]);
  const [invoice_data, setInvoice] = useState([]);
  const [list_data, setList] = useState([]);
  const [success, setSuccess] = useState([]);
  const [edit_list, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [double, setDouble] = useState(true);
  const [permission, setPermission] = useState([]);
  const [pages, setpages] = useState({
    current_page: 1,
    last_page: 0,
    total_pages: 0,
    pagination_item: [],
    total_pages: 0,
  });
  //---On Load---//
  useEffect(() => {
    check_url();
  }, []);
  //------------------------url
  function check_url() {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then((response) => {
        return response.json();
      })

      .then((responseData) => {
        if (responseData.allowurl == 1) {
          history.push(responseData.url);
          ListPurchasereturn(pages.current_page);
        } else {
          history.goBack();
        }

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  //-------------------end
  function getVendor() {
    const requestOptions = {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    fetch(API_URL + "/get_vendors", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        var datas = [];

        responseData.forEach((elem) => {
          datas.push([elem.id, elem.name]);
        });

        setDropdownData(datas);

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function getInvoice() {
    const requestOptions = {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    fetch(API_URL + "/get_invoicenumber", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        var data = [];

        responseData.forEach((elem) => {
          data.push([elem.id, elem.invoice_number]);
        });

        setInvoice(data);

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getProductName(id) {
    const requestOptions = {
      method: "Post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        invoice_id: id,
      }),
    };
    fetch(API_URL + "/getinvoice_products", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        var datas = [];

        responseData.products.forEach((elem) => {
          datas.push([elem.id, elem.product_name]);
        });

        setDropdowns(datas);
        var data = [];

        responseData.vendor.forEach((elem) => {
          data.push([elem.id, elem.name]);
        });
       
        setDropdownData(data);
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function ListPurchasereturn(page) {
    const requestOptions = {
      //---employee listing---//
      method: "get",
      // data: {
      // 	op_date:28/4/2018,
      // 	search_keyword:search
      // },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      // body: JSON.stringify({
      // 	op_date:28/4/2018,
      // 	search_keyword:search
      // })
    };
    fetch(
      `${API_URL}/nf-purchase-return?search_keyword=${search}&page=${page}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == 3) {
          setPermission({
            ...permission,
            status: responseData.status,
            message: responseData.message,
          });
        }
        setList(responseData.data.data);
        setDate(responseData.start_date);

        setpages({
          ...pages,
          current_page: responseData.data.current_page,
          last_page: responseData.data.last_page,
          per_pages: responseData.data.per_page,
          total_pages: responseData.data.total,
        });
        setLoading(true);
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const handlePagination = (number) => {
    setpages({
      ...pages,
      current_page: number, //---pagination---//
    });

    ListPurchasereturn(number);
  };
  //---Create popup function---//
  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      getInvoice();
      if (isShowing) {
        setInputValues({});
        setErrors({});
        setSuccess({});
        setProductList([]);
        setDropdownData([]);
        setDropdowns([]);
        setFields([""]);
      }
      if (!isShowing) {
        //setErrors({ vehicle_no: [''] })
        setDouble(true);
      }
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggle,
    };
  };
  const { isShowing, toggle } = UseModal();
  //---Delete popup function---//
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const handleInput = (e) => {
    // setInputValues({ ...inputValues,  });
    if (e.target.name == "quantity" || !inputValues.price) {
      var value =
        inputValues.purchase_price * e.target.value
          ? inputValues.purchase_price * e.target.value
          : null;
      var value1 = parseFloat(value) ? parseFloat(value).toFixed(2) : value;
    }

    setInputValues({
      ...inputValues,
	  [e.target.name]: e.target.value,
      value: value1,
    });
  };
  const handleInputs = (e) => {
    if (e.target.name == "value" || !inputValues.price) {
      var quantity =
        inputValues.purchase_price / e.target.value
          ? inputValues.purchase_price / e.target.value
          : null;
      var q = quantity.toFixed(2);
    }

    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,

      quantity: q,
    });
  };

  const handleSelect = (e) => {
    setInputValues({
      ...inputValues,
      vendor_id: e.target.value,
    });
  };
  const handleInvoice = (e) => {
    setInputValues({
      ...inputValues,
      nf_purchase_id: e.target.value,
    });
    var id = e.target.value;
    getProductName(id);
  };

  const handleinputdata = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
      //vendor_id: e.target.value
    });

    //var id = e.target.value
    //getVehicleNumber(id);
    //getStorage(id);
  };

  const handleSelects = (e) => {
    setInputValues({
      ...inputValues,
      product_id: e.target.value,
    });
    var id = e.target.value;
    getPrice(id);
    //getStorage(id);
  };
  function getPrice(id) {
    const requestOptions = {
      method: "post",
      body: JSON.stringify({ id: id }),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    fetch(API_URL + "/nf-sellingprice", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // setInputValues({...inputValues,price:responseData[0].selling_price})
        var purchase_price =
          responseData[0].purchase_price == ""
            ? ""
            : responseData[0].purchase_price;
        setInputValues({
          ...inputValues,
          product_id: id,
          purchase_price: purchase_price,
        });
        //setPrice({ ...new_price, price: responseData[0].new_price })
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  //---onchange functions---//
  function handleChange(i, event) {
    const values = [...fields];
    values[i] = event.target.value;
    setFields(values);
    //setInputValues({ ...inputValues, vehicle_no: values })
  }

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  // ---handlesubmit function---//
  const handleSubmit = (event) => {
    event.preventDefault();
	console.log(inputValues)
    setDouble(false);
    if (validateForm()) 
    sendData();
  };
  function validateForm() {
    let errors = {};
    let formIsValid = true;
    if (!inputValues.nf_purchase_id) {
      formIsValid = false;
      setDouble(true);
      errors["invoice_number"] = "Invoice number is required";
    }
    if(!inputValues.quantity) {
      formIsValid = false;
      setDouble(true);
      errors["quantity"] = "Quantity is required";
    }
	else{
		errors["quantity"] =""
	}
    if (!inputValues.product_id) {
      formIsValid = false;
      setDouble(true);
      errors["product_id"] = "Product is required";
    }
    if (!inputValues.vendor_id) {
      formIsValid = false;
      setDouble(true);
      errors["vendor_id"] = "Vendor is required";
    }
    if (!inputValues.total_return_value) {
      formIsValid = false;
      setDouble(true);
      errors["total_return_value"] = "Total return value is required";
    }
    if (document.getElementById("m_op_date").value=='') {
      formIsValid = false;
      setDouble(true);
      errors["op_date"] = "Date is required";
    }

    setErrors(errors);

    return formIsValid;
  }
  //---validations---//

  //---Create EmployeeList---//
  function sendData() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        nf_purchase_id:inputValues.nf_purchase_id,
        product_id:inputValues.product_id,
        purchase_price:inputValues.purchase_price,
        value:inputValues.values,
        vendor_id:inputValues.vendor_id,
        op_date: document.getElementById("m_op_date").value,

      }),
    };
    fetch(API_URL + "/nf-purchase-return", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        });
        let errors = [];
        if (responseData.status == false) {
          errors["product_id"] = responseData.response.product_id;
          errors["vendor_id"] = responseData.response.vendor_id;
          errors["total_return_value"] =
            responseData.response.total_return_value;
          errors["quantity"] = responseData.response.quantity;
          setDouble(true);
        }
        if (responseData.status == 3) {
          swal(
            "",
            responseData.message,
            responseData.status ? "warning" : "warning"
          );
          setDouble(true);
        }

        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          history.push("/purchase-return-non-fuel");
          setErrors([""]);
          setInputValues([]);
          toggle();
          ListPurchasereturn(pages.current_page);
          setDouble(true);
        }
        setErrors(errors);
        return responseData;
      })
      .catch((e) => {
        swal("Something went wrong", "", "warning");
        console.log(e);
        setDouble(true);
      });
  }

  function handleEdit(id, status) {
    setErrors([]);
    setProductList({
      id: id,
      status: status,
    });
    editData(id);
  }
  function editData(id) {
    const requestOptions = {
      method: "get",
      data: {
        id: id,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    fetch(API_URL + "/nf-purchase-return/" + id + "/edit", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setInputValues({
          product_id: responseData.product_id,
          purchase_price: responseData.purchase_price,
          op_date: responseData.op_date,
          nf_purchase_id: responseData.nf_purchase_id,
          // product_id: responseData.product_id,
          // intent_no: responseData.intent_no,
          total_return_value: responseData.total_return_value,
          value: responseData.value,
          quantity: responseData.quantity,
          vendor_id: responseData.vendor_id,
          // sales_value: responseData.sales_value,
          id: responseData.id ? responseData.id : "",
        });

        getProductName(responseData.nf_purchase_id);
        //setDropdown(responseData.get_customer.custom_vehicle)
        toggle();

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function delete_data(id) {
    deleteId(id);

    toggleModal();
  }

  function deletenfpurchase() {
    setDouble(false);
    const requestOptions = {
      method: "DELETE",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify(delete_id),
    };
    fetch(API_URL + "/nf-purchase-return/" + delete_id, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        swal(
          "",
          responseData.message,
          responseData.status ? "success" : "warning"
        );
        ListPurchasereturn(pages.current_page);
        toggleModal();
        setDouble(true);
        return responseData;
      })

      .catch((err) => {
        swal("Something went wrong", "", "warning");
        console.log(err);
      });
  }
  /*****approval******/
  const UseApproveModal = () => {
    const [isApprove, setApprove] = useState(false);
    function toggleModal2() {
      setApprove(!isApprove);
    }
    return {
      isApprove,
      toggleModal2,
    };
  };
  const { isApprove, toggleModal2 } = UseApproveModal();
  function handleManagerAproval(id, status) {
    approvalId(id);
    approvalStatus(status);
    toggleModal2();
  }

  function handleManagerAproval(id, status) {
    approvalId(id);
    approvalStatus(status);
    toggleModal2();
  }

  function handleYes() {
    const requestOptions = {
      //---employee listing---//
      method: "post",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: approval_id,
        status: approval_status,
      }),
    };
    fetch(`${API_URL}/nf_product_purchase_return_approve`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        swal(
          "",
          responseData.message,
          responseData.status ? "success" : "warning"
        );
        ListPurchasereturn(pages.current_page);

        toggleModal2();
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={5} operation={1} />
          <div className="content-area">
            <ManagerLoginHeader props={props} page_title="" />
            <div className="container pb-3 tab-view">
              <div className="row mt-md-5 mt-3 form-container">
                <div className="">
                  <div className="form-wrap ">
                    {permission.status == 3 ? (
                      <div className="form-wrap mt-5">
                        <div className="text-center">{permission.message}</div>
                      </div>
                    ) : (
                      <>
                        <div>
                          {loading ? ListPurchasereturn : <Loader />}
                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="creditsales"
                              role="tabpanel"
                              aria-labelledby="creditsales-tab"
                            >
                              <div className="">
                                <div className="row mb-3">
                                  <div className="col-md">
                                    <div className="row">
                                      <div className="col-md-12 col pl-0">
                                        <h3 className="table-title">
                                          Purchase Return Non-Fuel Product
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <a
                                      className="btn btn-primary px-3 mb-md-0 mb-2 "
                                      data-bs-target="#mla"
                                      onClick={toggle}
                                    >
                                      Add Purchase Return Non-Fuel{" "}
                                    </a>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="input-group mb-3 search">
                                      <span
                                        className="input-group-text"
                                        id="basic-addon1"
                                      >
                                        <i
                                          className="fa fa-search"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Search"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={search.search_keyword}
                                        onChange={handleSearch}
                                      />
                                      <a
                                        className="btn btn-primary bt-search btn-sm"
                                        data-bs-target="#mla"
                                        onClick={check_url}
                                      >
                                        Submit
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="table-widget">
                                  <table className="table border">
                                    <thead>
                                      <tr>
                                        <th>Product Name</th>
                                        <th>Purchase Price</th>
                                        <th>Quantity</th>
                                        <th>Value</th>
                                        <th>Total Return Value</th>
                                        <th>Vendor Name</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {list_data.map((obj) => (
                                        <tr>
                                          <td>{obj.product_name}</td>
                                          <td>{obj.purchase_price}</td>
                                          <td>{obj.quantity}</td>
                                          <td>{obj.value}</td>
                                          <td>{obj.total_return_value}</td>
                                          <td>{obj.name}</td>
                                          <td>
                                            {moment(obj.op_date).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </td>

                                          <td className="py-2">
                                            <a
                                              href="#"
                                              className="text-secondary"
                                              onClick={() =>
                                                handleEdit(obj.id, obj.status)
                                              }
                                            >
                                              {obj.status == 3 ||
                                              obj.status == 4 ? (
                                                <i
                                                  className="fa fa-eye  pe-2"
                                                  aria-hidden="true"
                                                ></i>
                                              ) : (
                                                <i
                                                  className="fa fa-pencil-square-o  pe-2"
                                                  aria-hidden="true"
                                                ></i>
                                              )}
                                            </a>
                                            {obj.status < 4 ? (
                                              <a
                                                href="#"
                                                className="text-secondary"
                                              >
                                                <i
                                                  className="fa fa-trash-o pe-2"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    delete_data(obj.id)
                                                  }
                                                ></i>
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                            {permissions.includes(
                                              "Deny Approval"
                                            ) == true ? (
                                              <>
                                                {obj.status < 4 ? (
                                                  <a
                                                    href="#"
                                                    className="text-secondary"
                                                    onClick={() =>
                                                      handleManagerAproval(
                                                        obj.id,
                                                        obj.status
                                                      )
                                                    }
                                                  >
                                                    {obj.status == 3 ? (
                                                      <i
                                                        class="text-success fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    ) : (
                                                      <i
                                                        class="text-warning fa fa-check"
                                                        aria-hidden="true"
                                                      ></i>
                                                    )}
                                                  </a>
                                                ) : (
                                                  <a>
                                                    <i
                                                      className="btn btn-success btn-xs"
                                                      aria-hidden="true"
                                                    >
                                                      Day Close
                                                    </i>
                                                  </a>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>

                                  {loading ? (
                                    <PaginationComp
                                      pages={pages}
                                      handlePagination={handlePagination}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowing ? (
        <PurchaseReturnModal
          isShowing={isShowing}
          hide={toggle}
          handleInputs={handleInputs}
          handleInput={handleInput}
          handleSelects={handleSelects}
          handleSelect={handleSelect}
          inputValues={inputValues}
          fields={fields}
          dropdowns={dropdowns}
          preventNonNumericalInput={preventNonNumericalInput}
          double={double}
          startDate={startDate}
          handleInvoice={handleInvoice}
          invoice_data={invoice_data}
          dropdownData={dropdownData}
          success={success}
          edit_list={edit_list}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleinputdata={handleinputdata}
          // handleAdd={handleAdd}
          // handleRemove={handleRemove}
          errors={errors}
        />
      ) : null}

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >
        <div className="modal-content">
          <div className="modal-title modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              PURCHASE RETURN NON-FUEL PRODUCT
            </h5>
            <button
              type="button"
              onClick={toggleModal}
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className=" modal-body  modal-body">
            <p>Are you sure you want to delete this details</p>
          </div>
          <div className="justify-content-end  modal-footer">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-secondary me-2"
                onClick={toggleModal}
              >
                No
              </button>
              {double == true ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={deletenfpurchase}
                >
                  Yes
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled
                  onClick={deletenfpurchase}
                >
                  Yes
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {isApprove ? (
        <ApprovalModal
          isApprove={isApprove}
          hide={toggleModal2}
          handleYes={handleYes}
          approval_status={approval_status}
        />
      ) : null}
    </>
  );
};

export default PurchaseReturnNonFuelConfig;
