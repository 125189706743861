import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { Link } from "react-router-dom";
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import React, { useState } from 'react';
import Loader from '../operational-model/Employee/Loader.component'

const FuelUnloading = () => {
  const [Loading,setLoading]=useState(false);
  const [double, setDouble] = useState(true);
    const handleInputs = (e) => {
		setInputValues({   
		   ...inputValues,
		   [e.target.name]: e.target.value
		 });
	   };

     const handleSubmit = (event ) =>
     {
       setDouble(false)
       event.preventDefault();
        //  if(validateForm()){
      sendFuelUnload()
        //  }
     }
     useEffect(() => {
      check_url();   
   },[]) 
   
   const [permission, setPermission] = useState([])
     async function getOpenFuel()
     {
         const requestOptions = {
         method: 'get',
         headers: {
           "Access-Control-Allow-Origin": "*",
           "Content-Type":"application/json",
           "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
           "Accept": "application/json"
       
          },
     };
      await fetch(API_URL+"/fuel-unloading",requestOptions)
             .then(response => { return response.json();})
     .then(responseData => {
       
      if(responseData.status==3)
			{
				setPermission({...permission,status:responseData.status,message:responseData.message})
			}
     console.log(responseData)
       setInputValues({
         fuel_unload :responseData.fuel_unload})
         setLoading(true)
     return responseData;
       })
             .catch(e => {
                 console.log(e);
                
             });
     }

     function check_url() {
      const requestOptions = {      
        method: 'post',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          pathurl: window.location.pathname,
        }),
      };
      fetch(`${API_URL}/check_url`, requestOptions)
        .then(response => { return response.json(); })
    
        .then(responseData => {

          console.log(responseData)
          if(responseData.allowurl == 1)
          {
            history.push(responseData.url);
            getOpenFuel();
          }
          else{
            
            history.goBack();
          }
    
          return responseData;
        })
        .catch(e => {
        });
    
    }
     function sendFuelUnload()
     {
       setDouble(false)
         const requestOptions = {
         method: 'POST',
         headers: {
         "Access-Control-Allow-Origin": "*",
         "Content-Type":"application/json",
         "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
         "Accept": "application/json"
        },
          body: JSON.stringify({
            ...inputValues,
            // value is inserted directly without radio button selection
            fuel_unload:"No"
            // end
          })
       };
       fetch(API_URL+"/pump-meta",requestOptions)
           .then(response => 
           { 
            return response.json();})
       .then(responseData => {
        if(responseData.status == true)
        {
          swal("",responseData.message,responseData.status?"success":"danger")
         //  setInputValues("")
           setDouble(true)
           history.push("/config-report");
        }
        if(responseData.status == false)
        {
          setDouble(true)
         swal("",responseData.message,"warning")

        }
        return responseData;
       })  .catch(e => {
             setDouble(true)
             console.log(e);     
           });
     }
     const history = useHistory()
	const [inputValues,setInputValues] = useState([]);

  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress progress={8} />
          <div className="content-area">
            <LoginHeader page_title="" />
            <div className="container pb-3">
              <div className="row justify-content-center mt-md-5 mt-3 form-container">
                <div class="row">
                  <div className="col-md-12 mb-3 px-4 ">
                    <Link to="/task-config">
                      <a href="task-config" className="float-end btn btn-gray">
                        Back
                      </a>
                    </Link>
                  </div>
                </div>
                <div className="col-md-12"> {Loading ?  getOpenFuel : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
                {permission.status==3 ?
										<div  className="form-wrap mt-5">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>

									<div>
                  <div className="form-wrap mt-md-3 ">
                    <h2 className="text-start">FUEL UNLOADING CONFIGURATION</h2>
                    <div className="row mb-3  py-4">
                      {/* <label className="col-sm-6 col-form-label mb-2">
                        Do you want a full version of Fuel Unloading process
                        
                      </label> */}
                      <div className="col-md-12 p-1 d-flex">
                        <div className="form-check col-sm pr-0">
                     <input
                            className="form-check-input"
                            type="radio"
                            name="fuel_unload"
                            id="exampleRadios1"
                            value="Yes"
                            disabled
                            onClick={ handleInputs}
                            checked={inputValues.fuel_unload === "Yes"}  
                          /> 
    
                          <label
                            className="form-check-label"
                            for="exampleRadios1"
                          >
                            {" "}
                            {/* Yes */}
                            Full Method
                          </label>
                          <p style={{color:"#5d38dbeb"}}> (Note : This method will be available from next version)</p>
                        </div>
                        <div className="form-check col-sm ">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="fuel_unload"
                            id="exampleRadios1"
                            value="No"
                            onClick={ handleInputs}
                            // checked={inputValues.fuel_unload === "No"}
                          defaultChecked
                          />
                          <label
                            className="form-check-label "
                            for="exampleRadios1"
                          >
                            {" "}
                           {/* No */}
                           Simple Method
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-md-12 notes"> */}
                        {/* <h5>Note</h5> */}
                        {/* <p>
                          In full version all fields are mandatory So you need
                          to enter all field in fuel unloading screen. other
                          wise It will not work. In full version software will
                          capture tank wise nozzle Reading with corresponding
                          stock and variation of density before and after
                          decanting. This information is useful for Tank wise
                          gain/Loss calculation.
                        </p> */}
                        {/* <h5>If No</h5> */}

                        {/* <p>
                          You have only necessary fields as mandatory and
                          capturing all related accounts only.</p> 
                          <p>You can fill either mandatory fields only or all fields .
                            </p> */}
                          {/* <p> If you want to see this unloading screen please click here.
                        </p> */}
                      {/* </div> */}
                    </div>
                    {double == true ?<div className="text-center ">
                      <a className="btn btn-primary w-auto "  type="submit" 
                         onClick={handleSubmit}>
                        SAVE AND CONTINUE{" "}
                      </a>
                    </div>:<div className="text-center ">
                      <a className="btn btn-primary w-auto " disabled type="submit" 
                         onClick={handleSubmit}>
                        SAVE AND CONTINUE{" "}
                      </a>
                    </div>}
                  </div>
                  </div>
                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelUnloading;
