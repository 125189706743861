export const packing_unit = {
    "1" :"Litre",
    "2" :"kilogram",
    "3" :"Number"

}

export const selling_mode = {
    "1" : "Sold same as purchased",
    "2" : "Sold in loose quantities only",
    "3" : "Sold in same size as well as loose quantities"

}

export const user_role = {
"1" : "Owner",
"2" : "Manager",
"3" : "Customer Attendant",
"4" : "Accountant",
}

export const dealers = {
    "1":"IOC",
    "2":"HP",
    "3":"BP",
    "4":"RELIANCE",
    "5":"ESSAR",
}

export const account_type = {
    "1":"Current Account",
    "2":"Savings Account",
}