import React from 'react';

const ExpensesConfigModal = ({ preventNonNumericalInput,isShowing, hide,double, errors, inputValues,handleAdd,handleRemove,handleChange ,fields,dropdownData, handleSubmit, handleInputs,handleInput }) => {

  return (
    <React.Fragment>
        <div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">EXPENSES</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="row mb-3 justify-content-center mt-3">
      <label className="col-sm-3 col-form-label mb-2">Expense Head <sup class="star">*</sup></label>
      <div className="col-sm-7">
        <input type="text" className="form-control mb-2" name="expense_head" placeholder="" value={inputValues.expense_head} onChange={handleInputs} readOnly={inputValues.ob_is_dayclose}/>
         <div className="errorMsg">{errors.expense_head}</div>
      </div>
       <label className="col-sm-3 col-form-label mb-2">Expense Limit(amount)</label>
      <div className="col-sm-7">
        <input type="number" className="form-control hide-arrow mb-2" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} placeholder="" name="expense_amount" pattern="[0-9]" value={inputValues.expense_amount} onChange={handleInput} readOnly={inputValues.ob_is_dayclose}/>
         <div className="errorMsg">{errors.expense_amount}</div>
         <span class="amount-words"></span>
      </div>
    </div>
    <div className="modal-footer ">
           {double==true?   <a className="btn btn-primary w-auto px-4" type="submit" onClick={handleSubmit}>Save Expense Configuration</a>:<a className="btn btn-primary w-auto px-4" type="submit" disabled>Save Expense Configuration</a>}
          </div>
                  
          </div>
          </div>
    </React.Fragment>
      );
    }
           export default ExpensesConfigModal;