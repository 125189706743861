import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const ViewModal = ({ isOp, hide, nozzle_data, tank_data, Others, value }) => (
  
  <React.Fragment>
{console.log(isOp)}
{console.log(tank_data)}
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">

        <div className="modal-header mb-3">
          <h5 className=" text-center" id="staticBackdropLabel">Dsr Report View</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <table class="table " >
            <div className="row">
              <div className="col-md-6 view-dsr ">
                <div className="row g-1 mt-3 form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Date :</label>
                  </div>
                  <div className="col-4 text-end" >
                    {Others[0]?.op_date}
                  </div>

                </div>
                {
                 tank_data.map((fuelType, idx) => {


                    return (
                      <>

                        <input type="hidden" name="product_id" value={fuelType?.product_id} />
                      </>
                    )


                  }
                  )}

                <div className="row g-1 align-items-cente form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label ">Product Name :</label>
                  </div>
                  <div className="col-4 text-end">
                    <div className="text-success"> {value?.fuel_name} </div>

                  </div>
                </div>

                {tank_data.map((fuelType, idx) => {
                  return (
                    <>                    
                      <div className="form-group ">
                        <h6 name="tank_name"><b>{fuelType?.tank_name}</b></h6>
                      </div>
                      <div className="row g-2 align-items-center ps-4 view-dsr2">
                        <div className="col-6">
                          <label className="col-form-label">DIP :</label>
                        </div>
                        <div className="col-4 text-end">
                          {fuelType?.dip}
                        </div>
                      </div>
                      <div className="row g-2 align-items-center ps-4 view-dsr2 ">
                        <div className="col-6">
                          <label className="col-form-label">Water DIP :</label>
                        </div>
                        <div className="col-4  text-end" >
                          {fuelType?.water_dip}
                        </div>
                      </div>
                      <div className="row g-2 align-items-center ps-4 view-dsr2">
                        <div className="col-6">
                          <label className="col-form-label">Opening Stock :</label>
                        </div>
                        <div className="col-4  text-end">
                          {fuelType?.opening_stock}
                        </div>
                      </div>
                    </>
                  );


                }
                )}


                <div className="row g-2 align-items-center form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Total Opening Stock :
                    </label>
                  </div>
                  <div className="col-4  text-end">
                    {Others[0]?.total_opening_stock}
                  </div>
                </div>
                <div className="row g-1 align-items-center form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Receipt :</label>
                  </div>
                  <div className="col-4  text-end">
                    {Others[0]?.receipt}
                  </div>
                </div>
                <div className="row g-1 align-items-center  form-group ps-4 mb-3 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Total Stock :</label>
                  </div>
                  <div className="col-4  text-end">
                    {Others[0]?.total_stock}
                  </div>
                </div>
              </div>

              <div className="col-md-6 view-dsr">
            
                <div className="form-group  ">
                  <h6 className="text-dark">Opening Meter Reading </h6>
                </div>
                {nozzle_data.map((fuelType, idx) => {


                  return (
                    <>

                      <div className="row g-1 align-items-center ps-4  view-dsr2">
                        <div className="col-6">
                          <label className="col-form-label"> <b>{fuelType?.nozzle_name}
                          </b></label>
                        </div>
                        <div className="col-4  text-end
                        ">
                          {fuelType?.reading}
                        </div>
                      </div>
                    </>
                  );
                })}


                <div className="row g-1 align-items-center  form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Testing :</label>
                  </div>
                  <div className="col-4 text-end">
                    {Others[0]?.testing}
                  </div>
                </div>

                <div className="row g-1 align-items-center  form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Sales :</label>
                  </div>


                  <div className="col-4 text-end">
                    {Others[0]?.sales}
                  </div>


                </div>

                {tank_data.map((fuelType, idx) => {


                  return (
                    <>
                      <div className="form-group ">
                        <h6 name="tank_name"><b>{fuelType?.tank_name}</b></h6>
                      </div>
                      <div className="row g-2 align-items-center ps-4 view-dsr2">
                        <div className="col-6">
                          <label className="col-form-label">Gain/Loss :</label>
                        </div>
                        <div className="col-4 text-end">
                          {fuelType?.gain_loss}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className="row g-2 align-items-center form-group ps-4 view-dsr2 ">
                  <div className="col-6">
                    <label className="col-form-label">Total Stock Gain/Loss :</label>
                  </div>
                  <div className="col-4 text-end">
                    {Others[0]?.total_gain_or_loss}
                  </div>
                </div>
                <div className="row g-1 align-items-center  form-group ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Cumulative Sales :</label>
                  </div>
                  <div className="col-4 text-end">
                    {Others[0]?.cumulative_sales}
                  </div>
                </div>
                <div className="form-group ">
                  <h6>Total Engine and Gear oil sales</h6>
                </div>

                <div className="row g-1 align-items-center ps-4 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Packed :</label>
                  </div>

                  <div className="col-4 text-end">
                    {Others[0]?.packed_sale_litre}
                  </div>

                </div>

                <div className="row g-1 align-items-center ps-4 mb-3 view-dsr2">
                  <div className="col-6">
                    <label className="col-form-label">Loose :</label>
                  </div>

                  <div className="col-4 text-end">
                    {Others[0]?.loose_sale_litre}
                  </div>

                </div>

              </div>


            </div>
          </table>
        </div>
      </div>
    </div>



  </React.Fragment>

)
export default ViewModal