import React, { useMemo, useState } from "react";
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import CreditDebitModal from "./CreditDebitModal.component";
import payment from "../../img/payment-method.svg";
import ReactDOM from "react-dom";
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import { convertAmountToWords,prevNonNumericalWithDec,preventNonNumericalInput } from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';
const  PaginationComp  = (props) => (
  < PaginationComponent  {...props} />
     );
var CreditModal = (props) =>
  ReactDOM.createPortal(<CreditDebitModal {...props} />, document.body);
const CreditDebit = (location) => {

  const backPage = useMemo(() => {

    if (location?.location?.backpage) {
      return location?.location?.backpage
    }
    else
      return undefined
  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);
  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      var isdayclose = checkoption.isdayclose;
      if (isdayclose == 1) {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else {
        if (isShowing) {
          setInputValues([]);
          setErrors({});
          setSuccess({});
        }
        if (!isShowing) {
          setDouble(true)
          setErrors({})

        }
        setIsShowing(!isShowing);
      }
    }

    return {
      isShowing,
      toggle,
    };
  };
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [delete_id, deleteId] = useState([])
  const [permission, setPermission] = useState([])
  const [checkoption, ckeckOption] = useState([])
  const [errors, setErrors] = useState([]);
  const [search, setSearch] = useState('')
  const [stat_operation, setStartoperation] = useState('')
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }
  const [pages, setpages] = useState(
    {
      current_page: 1,
      last_page: 0,
      total_pages: 0,
      pagination_item: [],
      total_pages: 0,
    })

  const handlePagination = (number) => {
    setpages({
      ...pages,
      current_page: number
    }
    )
    ListCreditDebit(number)

  }

  
  useEffect(() => {
        check_url()
    setLocation(location.location.pathname)
  
  }, []);

  //------------------------url
  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        setStartoperation(responseData.stat_operation)
        
        if (responseData.allowurl == 1) {
          if (location.location.pathname == "/creditor-debitor/creditor-debitor") {
            history.push(location.location.pathname);
          }
          else {
            history.push(responseData.url);
          }
          ListCreditDebit(pages.current_page);

        }
        else {

          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  // console.log(stat_operation,"ol")
  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        //	console.log(responseData.op_date)


        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }
  //--------------------------//
  const handleInputs = (e) => {


    if (
      e.target.name === "credit_period" ||
      e.target.name === "credit_amount"
    ) {

      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.checked ? 1 : 0,
      });
    } else
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value,
      });
      
    convertAmountToWords(e, e.target.value)
  };

  const handleInput = (e) => {

    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

    convertAmountToWords(e, e.target.value)

  };

  function validateForm() {
    let errors = [];
    let formIsValid = true;
    if (!inputValues.name) {
      setDouble(true)
      formIsValid = false;
      errors["name"] = "Enter Name";
    } else {
      errors["name"] = "";
    }

    if(stat_operation!="Yes"){
    if (inputValues.opening_balance != undefined) {

      if ((inputValues.opening_balance != "") && (inputValues.debit_credit == undefined || inputValues.debit_credit === "")) {
        formIsValid = false;
        setDouble(true)
        errors["debit_credit"] = " Select Debit/Credit"
      }
    }

    if (!inputValues.opening_balance) {
      setDouble(true)
      formIsValid = false;
      errors["opening_balance"] = "Enter Opening Balance"
    }
    
    // if (inputValues.debit_credit == undefined) {
    //   setDouble(true)
    //   formIsValid = false;
    //   errors["debit_credit"] = "Enter Opening Balance"
    // }
    
    }



    if (inputValues.credit_amount === 1 && !inputValues.credit_limit) {
      setDouble(true)
      formIsValid = false;
      errors["credit_limit"] = " Enter Credit Amount"


    }


    if ((inputValues.credit_period === 1) && (!inputValues.credit_limit_period)) {
      setDouble(true)
      formIsValid = false;
      errors["credit_limit_period"] = " Enter Credit Limit period"


    }
    if ((inputValues.credit_limit_period == "no_of_days" && !inputValues.credit_days)) {
      setDouble(true)
      formIsValid = false;
      errors["credit_days"] = " Enter Credit Days"
    }
    if (inputValues["phone"]) {
    
      if ( inputValues["phone"].length != 10) {
        formIsValid = false;
        setDouble(true);
        errors["phone"] = "Enter Valid Phone Number";

      }
    }
    if (inputValues["email"]) {
      if (typeof inputValues["email"] !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(inputValues["email"])) {
          formIsValid = false;
          setDouble(true)
          errors["email"] = "Enter valid Email Id"
        }
      }
    }
    setErrors(errors);
    return formIsValid;
  }
console.log(errors,'errors')
  const handleSubmit = (event) => {
    event.preventDefault();
    setDouble(false)
    if (validateForm()) sendCreditDebit()
  };

  //loader
  const [Loading, setLoading] = useState(false);

  //Listing
  function ListCreditDebit(page) {
    const requestOptions = {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };

    fetch(`${API_URL}/creditor-debtor?search_keyword=${search}&page=${page}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == 3) {
          setPermission({ ...permission, status: responseData.status, message: responseData.message })
        }
        setList(responseData.data.data);
        ckeckOption({
          ...checkoption,
          isdayclose: responseData.isdayclose
        })

        setpages({
          ...pages,
          current_page: responseData.data.current_page,
          last_page: responseData.data.last_page,
          per_pages:  responseData.data.per_page,
          total_pages:responseData.data.total,
         
        })
        setLoading(true);
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function sendCreditDebit() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify(inputValues),
    };
    fetch(API_URL + "/creditor-debtor", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        })
        if (responseData.status == false) {
          errors["name"] = responseData.response.name
          errors["opening_balance"] = responseData.response.opening_balance
          errors["phone"] = responseData.response.phone
          errors["email"] = responseData.response.email
          errors["whatsapp_no"] = responseData.response.whatsapp_no
          setDouble(true)
        }
        else if (responseData.status == 3) {
          errors["name"] = responseData.message
          setDouble(true)
        }
        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );

          setDouble(true)
          toggle();
          ListCreditDebit(pages.current_page);

        }
        setErrors(errors)
        return responseData;
      })
      .catch((e) => {
        swal("Something went wrong", "", "warning")
        console.log(e);
        setDouble(true)
      });
  }

  function delete_Customer(id, outstanding) {
    var isdayclose = checkoption.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else if (outstanding != 0) {
      swal("", "You do not have permission to delete during have outstanding", false ? "success" : "warning")
    }
    else {
      deleteId(id)

      toggleModal()
    }
  }

  function deleteCustomer() {
    setDouble(false)
    // console.log(...delete_id)
    const requestOptions = {
      method: 'DELETE',

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify(delete_id)
    };
    fetch(API_URL + "/creditor-debtor/" + delete_id, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        ListCreditDebit(pages.current_page);
        toggleModal()
        setDouble(true)
        return responseData;


      })

      .catch(err => {
        swal("Something went wrong", "", "warning")
        console.log(err)
      })
  }

  async function edit_product(id, ac_name) {
    var isdayclose = checkoption.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {
      const requestOptions = {
        method: "get",
        data: { id: id, ac_name: ac_name },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          Accept: "application/json",
        },
      };
      await fetch(API_URL + "/creditor-debtor/" + id + "/edit", requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {

          setInputValues({
            id: responseData.data.id ? responseData.data.id : "",
            ac_name: responseData.data.ac_name ? responseData.data.ac_name : "",
            name: responseData.data.name,
            address: responseData.data.address,
            phone: responseData.data.phone,
            email: responseData.data.email,
            credit_limit: responseData.data.credit_limit,
            // credit_amount : responseData.credit_limit ==null ? 0 :1,
            whatsapp_no: responseData.data.whatsapp_no,
            debit_credit: responseData.data.debit_credit,
            credit_days: responseData.data.credit_limit_period,
            credit_limit_period:
              isNaN(parseInt(responseData.data.credit_limit_period)) == false
                ? "no_of_days"
                : responseData.data.credit_limit_period,
            opening_balance: responseData.data.opening_balance,
            credit_amount: responseData.data.credit_limit === null ? 0 : 1,
            credit_period: responseData.data.credit_limit_period === null ? 0 : 1,

            ob_is_dayclose: responseData.ob_is_dayclose == 1 ? true : false,
          });
          // })
          toggle();
          return responseData;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  const [path, setLocation] = useState([])
  const { isShowing, toggle } = UseModal();
  const [inputValues, setInputValues] = useState([]);
  const history = useHistory();
  const [list_data, setList] = useState([]);
  const [success, setSuccess] = useState([]);
  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          {path == "/creditor-debitor/creditor-debitor" ? <Sidebar_Progress path={path} /> : <Sidebar_Progress />}
          <div className="content-area">
            <LoginHeader page_title="" />

            <div className="container pb-3 mt-3">
              <div className="row">
                <div className="col-md-12 px-4">
                  {path == "/creditor-debitor/creditor-debitor" ? <Link to={backPage || "/operational-date"}>
                    <a href="#" className="float-end btn btn-gray">
                      Go Back
                    </a>
                  </Link> :
                    <Link to="/customers">
                      <a href="customers" className="float-end btn btn-gray">
                        Back
                      </a>
                    </Link>}
                </div>
              </div>

              <div className="row justify-content-center mt-md-3 mt-3 form-container">
                <div className="col-md-12">
                  {permission.status == 3 ?
                    <div className="form-wrap">
                      <div className="text-center">
                        {permission.message}
                      </div></div> :
                    <>
                      <div>
                        {Loading ? ListCreditDebit :
                          // <div class="loader text-primary">Loading...</div>
                          <Loader />
                        }
                        <div className="form-wrap list-wrp">
                          <div className="row mb-3">
                            <div className="col-md">
                              <div className="row">
                                <div className="col-md-1 col-2 pe-0">
                                  <img
                                    src={payment}
                                    width="25px"
                                    className="img-fluid"
                                    alt=".."
                                  />
                                </div>
                                <div className="col-md col pl-0">
                                  <h3 className="table-title">
                                    Creditors/Debtors OPENING BALANCES
                                  </h3>
                                </div>
                                <div className="col-md">
                                  <a
                                    className="btn btn-primary px-3 mb-md-0 mb-2 "
                                    data-bs-target="#add-customer"
                                    onClick={toggle}
                                  >
                                    ADD CREDITORS/DEBTORS
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="input-group mb-3 search">
                                <span className="input-group-text" id="basic-addon1">
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch}  />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
                              </div>
                            </div>
                          </div>
                          <div className="table-widget">
                            <table className="table border">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Address</th>
                                  <th>Phone</th>
                                  <th>Email</th>
                                  <th>Credit Limit</th>
                                  <th>Whatsapp No.</th>
                                  <th>Configured Opening Balance</th>
                                  <th>Credit/Debit</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {list_data.map((credit) => (
                                  <tr>
                                    <td>{credit.name}</td>
                                    <td>{credit.address}</td>
                                    <td>{credit.phone}</td>
                                    <td>{credit.email}</td>
                                    <td>{credit.credit_limit}</td>
                                    <td>{credit.whatsapp_no}</td>
                                    <td className="text-end">{credit.opening_balance}</td>
                                    <td>{credit.debit_credit == "Cr" ? "Credit" : credit.debit_credit == "Dr" ? "Debit" : ''}</td>
                                    <td>
                                      <div className="d-flex icon-btn">
                                        <button
                                          onClick={() =>
                                            edit_product(credit.id, credit.ac_name)
                                          }
                                        >
                                          {" "}
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <button
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="bottom"
                                          title="Delete"
                                          onClick={() => delete_Customer(credit.id, credit.outstanding)}
                                        >
                                          {" "}
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </button>


                                      </div>
                                    </td>
                                    {/* <td colspan="8" className="no-content">
                                               <h2>NOTHING TO 
                                                 <br/>SHOW
                                               </h2>
                                             </td> */}
                                  </tr>
                                ))}
                              </tbody>
                    
                            </table>
                    
                          </div>
                          {Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
                        </div>
                        
                      
                        <div className="text-end mt-4">
                          {path == "/creditor-debitor/creditor-debitor" ? "" : <a
                            className="btn btn-primary px-3 mb-md-0 mb-2"
                            onClick={(event) => history.push("/digital-payment")}
                          >
                            Skip/Continue
                          </a>}
                        </div>
                      </div>
                    </>}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {console.log(stat_operation,"ll")}
      {isShowing ? (
        <CreditModal
          isShowing={isShowing}
          hide={toggle}
          handleInputs={handleInputs}
          prevNonNumericalWithDec={prevNonNumericalWithDec}
          preventNonNumericalInput={preventNonNumericalInput}
          inputValues={inputValues}
          success={success}
          handleInput={handleInput}
          double={double}
          stat_operation={stat_operation}
          // fields={fields}
          // dropdownData ={dropdownData}
          handleSubmit={handleSubmit}
          // handleChange={handleChange}
          // handleAdd={handleAdd}
          // handleRemove ={handleRemove}
          errors={errors}
          
        />
      ) : null}

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >
        <div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">CREDITORS/DEBTORS</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body"><p>Are you sure you want to delete this CREDITORS/DEBTORS</p></div>
          <div class="justify-content-end  modal-footer"><div class="d-flex">
            <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
           {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteCustomer}>Yes</button>:<button type="submit" class="btn btn-primary" onClick={deleteCustomer} disabled>Yes</button>}
          </div>
          </div>
        </div>




      </Modal>

    </>
  );
};

export default CreditDebit;
