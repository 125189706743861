import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import DayClosingScreenConfig from './DayClosingScreenConfig.component';
const DayClosingScreenModal = ({ isShowing, hide, setVehicle, errors, success, inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs }) => {
    return (
        <React.Fragment>

            {console.log("dsff")}
            <div class="container pb-3">
              
            
        <div class="row mb-3 justify-content-center">
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal modal-dialog-xl modalxxl col-12 ">
                          <div class="modal-header mb-3">
                                  <h5 class="text-center" id="staticBackdropLabel">Day closing Screen</h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>
                    
				
                    
                           <div class="row g-2 justify-content-center mb-2">
                <div class="col-4">
                  <label class="col-form-label">Approval Pending Voucher</label>
                </div>
                <div class="col-3">
                  <input type="text" class="form-control highlight"></input>
                </div>  
                <div class="row g-2 justify-content-center mb-2">
                
                <div class="col-4">
                  <label class="col-form-label">Pending Employee Closing Transation</label>
                </div>
                <div class="col-3">
                  <input type="text" class="form-control highlight"></input>
                </div>  
                </div>
                </div>
                <div class="row g-2 justify-content-center mb-2 ">
                <div class="col-4">
                  <label class="col-form-label">Date</label>
                </div>
                <div class="col-3">
                  <input type="date" class="form-control highlight"></input>
                </div>  
</div>
               


              

                  




<div class="modal-footer text-center">
             
            
     <a href="#" class="btn btn-primary  w-auto ">Approve</a>
   </div>      
        </div>
    </div>
    </div>
</div>


       </React.Fragment>

    );
}
export default DayClosingScreenModal