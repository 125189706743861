import { Component } from "react";
import inhand from '../../../img/inhand.png'
import store from '../../../img/store.png'
import money from '../../../img/money.png'
import fuelstation from '../../../img/fuel-banner.png'
import { Link, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { API_URL } from '../../../constant/API_Settings'
import { WEB_URL } from "../../../constant/API_Settings"
import React, { useState } from 'react';
import FuelModal from "react-modal";
import BankModal from "react-modal";
import LoginHeaderOperational from '../LoginHeaderOperational';
import ReactTooltip from 'react-tooltip'
import DashBoardModal from './DashBoardModal.component'
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';





import ReactDOM from 'react-dom';
import Loader from "./Loader.component"
import { useHistory } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

const TankModal = (props) => ReactDOM.createPortal(
  <DashBoardModal {...props} />, document.body
);

const DashBoard = (props, progress) => {

  console.log(props)
  const location_details = useLocation()
  const [graph, setGraph] = useState([])
  const [color, setColor] = useState([])
  const history = useHistory()
  const [permission, setPermission] = useState([])
  const [graph_details, setGraphDetails] = useState([])
  const [cash, setCash] = useState([])
  const [fuel_data, setFuel] = useState([])
  const [fueldata, setFuelDetails] = useState([])
  const [tank_data, setTank] = useState([])
  const [Bank_data, setBank] = useState([])
  const [dealer_statement, setdealer_statement] = useState([])
  const [tankdata, setTankDetails] = useState([])
  const [cash_approval, setCashApproval] = useState([])
  const [bank_approval, setBankApproval] = useState([])
  const [total_cash, setTotalCash] = useState([])
  const [total_fuelsales, settotal_fuel] = useState([])
  const [total_nonfuelsales, settotal_nonfuel] = useState([])
  const [total_expenses, settotal_expenses] = useState([])
  const [critical_stock, setCriticalStock] = useState([])
  const [custm_outstanding, setOutStanding] = useState([])
  const [id, setId] = useState([])
  const [datestart, setdatestart] = useState([])
  const [datestartexp, setdatestartexp] = useState([])
  const [dateend, setdateend] = useState([])
  const [dateendexp, setdateendexp] = useState([])
  const [Loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem("m_op_date") || '');
  const [listcredit,SetCredit]=useState([]);
  console.log(localStorage.getItem("m_op_date"),"yyyyyyyyy")
  const [page, setpages] = useState(
    {

    })
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

const toggledate = () => {
  setOpen(!open);
};

const toggledateexp = () => {
  // alert(3)
  setOpen1(!open1);
};


const setDateRange = (date) => {
  console.log(date.startDate, date.endDate);
  console.log(date.startDate,"start");
  console.log(date.endDate,"end");

  let datestart = moment((date.startDate)).format("DD-MM-YYYY")
   setdatestart(datestart)

  let dateend = moment((date.endDate)).format("DD-MM-YYYY")
  setdateend(dateend)
  console.log(datestart,"converted")

  if(datestart && dateend){
 
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
        datestart:datestart,
        dateend:dateend,
      }),
    };
    fetch(`${API_URL}/totalsales`, requestOptions)
    .then(response => { return response.json(); })
    .then(responseData => {
     
   let totalfuels=responseData.data
   let totalnonfuel=responseData.data
   settotal_fuel(totalfuels);
   settotal_nonfuel(totalnonfuel);

    })

      .catch(e => {
        console.log(e)
      });
    toggledate()
  }

};

const setDateRangeexp = (date) => {
  console.log(date.startDate, date.endDate);
  console.log(date.startDate,"start");
  console.log(date.endDate,"end");

  let datestartex = moment((date.startDate)).format("DD-MM-YYYY")
   setdatestartexp(datestartex)

  let dateendex = moment((date.endDate)).format("DD-MM-YYYY")
  setdateendexp(dateendex)
  console.log(datestart,"converted")

  if(datestart && dateend){
 
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
        datestart:datestartex,
        dateend:dateendex,
      }),
    };
    fetch(`${API_URL}/totalexpense`, requestOptions)
    .then(response => { return response.json(); })
    .then(responseData => {
     
  
      let tot_exp=responseData.data.exp_data
      console.log(tot_exp,"tot_exptot_exp")
      settotal_expenses(tot_exp);
    })

      .catch(e => {
        console.log(e)
      });
    toggledateexp()
  }

};
////endexp///
function toggleModal() {
    setIsOpen(!isOpen);}

const handleDateChange = (date) => {
};

  function toggleModal1() {
    setIsOpen1(!isOpen1);
  }

  const [isClose, setIsClose] = useState(false);
  function toggle() {
    setIsClose(!isClose);

  }
  const [isClose1, setIsClose1] = useState(false);
 
  const data = [
    {
      name: "Page A",
      uv: 100,
      pv: 60,
      pp: 60

    },
    {
      name: "Page B",
      uv: 50,
      pv: 30

    },
    {
      name: "Page C",
      uv: 40,
      pv: 20,
      pp: 50
    },
    {
      name: "Page D",
      uv: 50,
      pv: 0,
      pp: 23
    },
    {
      name: "Page E",
      uv: 20,
      pv: 0,
      pp: 60

    },
    {
      name: "Page F",
      uv: 10,
      pv: 30,
      pp: 23
    },
    {
      name: "Page G",
      uv: 50,
      pv: 30,
      pp: 23
    }
  ];

  //---On Load---//		
 
  useEffect(() => {

    Credit_cust(selectedDate);
    // Other functions
    setpages(data);
    check_url();
    total_fuel();
    total_expense();
    check_meta_date();
  }, [selectedDate]);

  
  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        if (responseData.allowurl == 1) {
          history.push(responseData.url);
          ListDashBoardItem()
        }
        else {
          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }

  function total_fuel() {
    // alert(22)
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/totalsales`, requestOptions)
    .then(response => { return response.json(); })
    .then(responseData => {
     
   let totalfuels=responseData.data
   let totalnonfuel=responseData.data
   settotal_fuel(totalfuels);
   settotal_nonfuel(totalnonfuel);


    })

      .catch(e => {
        console.log(e)
      });
    
  }


  const handleDateChanges = (e) => {
    const { value } = e.target;
    setSelectedDate(value);
    localStorage.setItem("m_op_date", value); // Optionally store the new date in localStorage
    Credit_cust(value);
  };

  function Credit_cust(date) {
    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
        "Accept": "application/json"
      },
      body: JSON.stringify({
        selectedDate: date, // Use the updated value
      })
    };
    console.log(requestOptions, "requestOptions");
    fetch(API_URL + "/custcredit", requestOptions)
      .then(response => response.json())
      .then(responseData => {
        let creditcust=responseData.data.credit_sales
        SetCredit(creditcust);
      })
      .catch(e => {
        
      });
  }
console.log(listcredit,"gggggggggggg")

  function total_expense() {
    // alert(22)
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/totalexpense`, requestOptions)
    .then(response => { return response.json(); })
    .then(responseData => {
     
      let tot_exp=responseData.data.exp_data
      console.log(tot_exp,"tot_exptot_exp")
      settotal_expenses(tot_exp);


    })

      .catch(e => {
        console.log(e)
      });
    
  }
  // console.log(total_fuelsales.mindate,"xxxxxxxxxxxxxxxxxxxxxx")
  function handleFuelDetails(obj) {

    setFuelDetails(obj.fuel_details)

    toggleModal()
    // alert("nnnnnnnnnnnnnnnmmmmmmmmmmmmmmmmmmmmmm")
  }
  // function bankddddddddd() {

  //   //  setFuelDetails(obj.fuel_details)

  //   toggleModal1()
  //   alert("nnnnnnnnnnnnnnn")
    
  // }
  function handleTankDetails(obj) {
    setTankDetails(obj)
    toggle()

  }
  const check_meta_date = () => {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        setId(responseData.userId)

        return responseData;
      })
      .catch(e => {
      });

  }
  //-------------------Dashboard List Item ---(akhitha-20/7/2021)----------//
  function ListDashBoardItem() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
    };
    fetch(`${API_URL}/dashboard`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        if(responseData.status==3)
        {
            setPermission({...permission,status:responseData.status,message:responseData.message})
        }
        
     
        setCash({
          ...cash,
          cash_in_hand: responseData.cash_hand,
          cash_in_bank: responseData.cash_bank,
          total_cash: responseData.total_cash_available
        })
        console.log(responseData.cash_in_hand.cash_in_hand_after_approval)
        setCashApproval({
          ...cash_approval,
          cash_in_hand_after_approval: responseData.cash_in_hand.cash_in_hand_after_approval,
          cash_in_hand_before_approval: responseData.cash_in_hand.cash_in_hand_before_approval
        })
        setBankApproval({
          ...bank_approval,
          cash_at_bank_after_approval: responseData.cash_at_bank.cash_at_bank_after_apr,
          cash_at_bank_before_approval: responseData.cash_at_bank.cash_at_bank_before_apr
        })
        setTotalCash(responseData.total_cash)
        setCriticalStock(responseData.critical_non_fuel_stock)
        setOutStanding(responseData.Customer_outstanding)
        setFuel(responseData.fuelData)
        setTank(responseData.tankData)
        setBank(responseData.Bank_outstanding)
        setdealer_statement({
          ...dealer_statement,
          crtamount:responseData.outstanding_dealer[0].crtamount,
          drtamount:responseData.outstanding_dealer[0].drtamount,
          total_outstnd:responseData.outstanding_dealer[0].drtamount - responseData.outstanding_dealer[0].crtamount,
          
        })
        
        setGraphDetails(responseData.graph_data_fuels)
        var colors = ["#033E3E", "#fca374", "#8dc5a6", "#800000", "#8026f1", "#FFA500", "#808000", "#8dc5a6", "#C11B17", "#810541"]
        setColor(colors)
        // var myObject = JSON.parse(responseData.graph_data);
        setGraph(responseData.graph_data)
        // setCash(responseData)
        setLoading(true);
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }
  const thStyle = {
    position: 'sticky',
    top: '0',
    background: '#c7c7c7', // or any other background color
    zIndex: 1,
    padding: '8px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 2px -1px rgba(0, 0, 0, 0.4)'
  };

  let total_fuelamount =0;
  console.log(dealer_statement,"nnnnnnnnnnn")


  const groupedData = listcredit.reduce((acc, obj) => {
    if (!acc[obj.customer_name]) {
      acc[obj.customer_name] = { data: [], total_quantity: 0 };
    }
    acc[obj.customer_name].data.push(obj);
    acc[obj.customer_name].total_quantity += parseFloat(obj.quantity);  // Ensure quantities are added as numbers
    return acc;
  }, {});


  return (

    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={22} />
          <div className="content-area ">
            <LoginHeaderOperational props={props} page_title="" />
            <div className="container pb-3">

            {permission.status==3 ?
										<div  className="form-wrap mt-5">
											<div className="text-center">
											{permission.message}
											</div></div>:
              <div className="row mt-md-5 mt-3 form-container justify-content-center">
                <div className="col-md-12">
                  <div className=" ">
                    <div className="row mb-3 justify-content-center ">
                      <div className="col-md-3">
                        <div className="dash-card ">
                          <div className="row">
                            {Loading ? ListDashBoardItem :
                              // <div class="loader text-primary">Loading...</div>
                              <Loader />
                            }
                            
                            <div className="col-md-8">
                              <h3>{cash.cash_in_hand}</h3>
                              <p>Cash In Hand </p>
                            </div>
                            <div className="col-md-4">
                              <img src={inhand} className="img-fluid" />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="dash-card ">
                          <div className="row">
                            <div className="col-md-8">
                              <h3>{cash.cash_in_bank}</h3>
                              <p>Cash At Bank </p>
                              {/* <button>Cash At Bank </button> */}
                            </div>
                            <div className="col-md-4">
                            <img onClick={handleTankDetails} src={store} className="img-fluid" data-tip={`Bank Details(click Here)`}/>
                            
                            {/* onClick={() => handleFuelDetails(obj)} data-tip={`Current Stock-${obj.current_stock}L` */}
                           </div>
                          </div>

                        </div>
                      </div>
                      
                      <div className="col-md-3">
                        <div className="dash-card ">
                          <div className="row">
                            <div className="col-md-8">
                              <h3>{cash.total_cash}</h3>
                              <p>Total Cash Available </p>
                            </div>
                            <div className="col-md-4">
                              <img src={money} className="img-fluid" />
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="dash-card ">
                          <div className="row">
                            <div className="col-md-8">
                            {dealer_statement.total_outstnd > 0 ? (
                     <h3>{(dealer_statement.total_outstnd ? dealer_statement.total_outstnd : 0).toFixed(2)}</h3>
                     
                     ) : (
                     <h3>{(dealer_statement.total_outstnd ? dealer_statement.total_outstnd : 0).toFixed(2)}</h3>
                     
                    )}
                     {dealer_statement.total_outstnd > 0 ? (
                     <p><strong>(Debit)</strong></p>
                     ) : (
                     <p><strong>(Credit)</strong></p>
                    )}
                    <p>Dealer OutStanding </p>
                   
                    
                            </div>
                            <div className="col-md-4">
                              <img src={money} className="img-fluid" />
                            </div>
                          </div>

                        </div>
                      </div>

                      
                    </div>
                    <div className="row  mb-3">
                      
                      {/* <div className="col-md-6"> */}
                        
                        {/* <div className="dash-card d-cart  ">
                          <Link to={location => ({ backpage: location_details.pathname, pathname: "/users/users" })}><a href="/users" className="side-m"><button className="btn btn-success mb-3 w-100 ">User Management</button></a></Link>
                          <Link to={"/day-closing"}><button className="btn btn-violet mb-3 w-100">Day Closing</button></Link>
                        </div> */}
                      {/* </div> */}

                      <div className="col-md-4">
                        
                      <div className="dash-card table-card">
                           <div className="row">
                            {!open
                            ?
                            <>
                            <div className="col-md-6">
                           <p><strong>Start Date</strong></p>
                           <p><strong><input style={{width:"110px"}} readOnly type="text" value={datestart} onClick={toggledate} placeholder="DD/MM/YYYY"></input></strong></p>
                           
                           </div>
                           <div className="col-md-6">
                           <p><strong>End Date</strong></p>
                          <input style={{width:"110px"}} readOnly type="text" value={dateend} onClick={toggledate} placeholder="DD/MM/YYYY"></input>

                           </div>

                           <p><strong>Total Sales</strong></p>
                                {/* <div className="input-container">
                                   <input
                                   type="date"
                                   value={selectedDate}
                                   onChange={handleDateChange}
                                    className="date-input"
                                   />
                                </div> */}
                                
                                <div class="table-widget">
                           
                                <table class="table border">
                                  <thead>
                                    <tr>
                                      <th><p><strong>Fuel Type</strong></p></th>
                                      <th><p><strong>Quantity</strong></p></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {total_fuelsales?.fueldatas?.map(obj => (
                                  obj.amt > 0 ? (
                                 <tr>
                                 <td>{obj.fuel_name}</td>
                                 <td>{obj.amt}(L)</td>
                                 </tr>
                                 ) : null
                                 ))}
                                </tbody>

                                
                             <thead>
                               <tr>
                                 <th><p><strong>Non Fuel Type</strong></p></th>
                                 <th><p><strong>Quantity</strong></p></th>
                               </tr>
                             </thead>
                             <tbody>
                             {total_nonfuelsales?.nonfueldata?.map(obj => (
                             obj.amt > 0 ? (
                            <tr>
                            <td>{obj.product_full_name}</td>
                            <td>{obj.amt}</td>
                           
                             <td hidden>  {total_fuelamount=total_fuelamount+Number(obj.amt)}</td>
                          
                            </tr>
                            
                            ) : null
                            ))}
                            <tr>
                              <td>Total Non Fuel Sales</td>
                              <td><h6>{total_fuelamount.toFixed(2)}</h6></td>
                            </tr>
                           </tbody>
                                </table>
                                </div> 
                           </> 
                           :""}
                           </div>
                            
                           <div style={{width:"690px"}}>
                           <DateRangePicker
                           open={open}
                           toggle={toggledate}
                          //  minDate={total_fuelsales?.mindate? total_fuelsales?.mindate : null}
                           onChange={(date) => setDateRange(date)}
                           />
                           </div>
                           


                      </div>

                      </div>

                      
                      
                      <div className="col-md-8">
                        <div className="dash-card table-card">
                          <div className="row">
                            <div className="col-md-6">
                              {/* <div className="table-widget"> */}
                                {/* <table className="table ">
                                  <thead>
                                    <tr className="border-0">
                                      <th>Cash</th>
                                      <th>Before Approval</th>
                                      <th>After Approval</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Cash in hand</td>
                                      <td>{cash_approval.cash_in_hand_before_approval}</td>
                                      <td>{cash_approval.cash_in_hand_after_approval}</td>

                                    </tr>
                                    <tr>
                                      <td>Cash at bank</td>
                                      <td>{bank_approval.cash_at_bank_before_approval}</td>
                                      <td>{bank_approval.cash_at_bank_after_approval}</td>

                                    </tr>
                                    {console.log(cash)}
                                    <tr>
                                      <td>Total</td>
                                      <td>{total_cash.total_before_approval}</td>
                                      <td>{total_cash.total_after_approval}</td>

                                    </tr>
                                  </tbody>
                                </table> */}

                              {/* </div> */}
                              {console.log(id)}
                              <div className="btn-grp">
                                {/* <a href={`${WEB_URL}/other-report/${id}/dashboard`} className="btn btn-success w-100 mb-2">Quick Report <i className="fa fa-long-arrow-right float-end" aria-hidden="true"></i></a> */}
                                <Link to={"/op-simplified-balance-sheet"} className="btn btn-success w-100 mb-2" >Balance sheet <i className="fa fa-long-arrow-right float-end" aria-hidden="true"></i></Link>
                                {/* <Link to={"/op_profit_loss"} className="btn btn-success w-100 mb-2">Trade Profit Loss <i className="fa fa-long-arrow-right float-end" aria-hidden="true"></i></Link> */}
                                <a className="btn btn-success w-100 mb-2" href={`${WEB_URL}/other-report/${id}/trade_loss_gain`} >TRADE PROFIT AND LOSS<i className="fa fa-long-arrow-right float-end" aria-hidden="true"></i></a>
                                <a className="btn btn-success w-100 mb-2" href={`${WEB_URL}/other-report/${id}/daily-transaction-sheet`} >DAILY TRANSACTION REPORT<i className="fa fa-long-arrow-right float-end" aria-hidden="true"></i></a>
                                <Link to={"/op-dsr-report"} className="btn btn-success w-100 mb-2" >DSR REPORT<i className="fa fa-long-arrow-right float-end" aria-hidden="true"></i></Link>
                                 <Link to={"/day-closing"}><button className="btn btn-success w-100 mb-0">Day Closing</button></Link>
                                 
                              </div>
                              
                              
                            </div>
                            <div className="col-md-6">
                            <div>
                            <p><strong>Overall fuel stock (Toggle graph and table)</strong></p>
                            </div>
                            <div className="round-graph">
                               
                                <div className="row graph-color m-0">
                                  {fuel_data.map(obj => (
                                    <div className="col-md-4">
                                      <a onClick={() => handleFuelDetails(obj)} data-tip={`Current Stock-${obj.current_stock}L(click Here)`}>

                                        <CircularProgressbar
                                          background
                                          value={obj.percent}
                                          text={<> <tspan x="50">{obj.fuel_name}</tspan><tspan x="50" dy="15"> {obj.percent}%</tspan></>}
                                          backgroundPadding={6}

                                          styles={buildStyles({

                                            className: "gradient",
                                            textColor: "#fff",
                                            fill: "red",
                                            pathColor: "#fff",
                                            trailColor: "transparent"
                                          })}
                                        />
                                        <ReactTooltip backgroundColor="white" textColor="black" />
                                      </a>
                                    </div>
                                  ))}

                                </div>
                                <div className="py-3">

                                </div>

                              </div>
                              {/* <div>
                              <p><strong>Tank wise quantity (Toggle graph and table)</strong></p>
                              </div> */}
                              {/* <div className="round-graph">
                              
                                <div className="row graph-color m-0">
                                  {tank_data.map(obj => (
                                    <div className="col-md-4">
                                      <a onClick={() => handleTankDetails(obj)} data-tip={`Current Stock-${obj.current_stock}L`}>
                                        <CircularProgressbar
                                          background
                                          value={obj.percent}
                                          text={<><tspan x="50">{obj.tank_name}</tspan><tspan x="50" dy="15"> {obj.percent}%</tspan></>}
                                          backgroundPadding={6}
                                          styles={buildStyles({
                                            backgroundColor: "#3e98c7",
                                            textColor: "#fff",
                                            pathColor: "#fff",
                                            trailColor: "transparent"
                                          })}
                                        />
                                        <ReactTooltip backgroundColor="white" textColor="black" />
                                      </a>
                                    </div>
                                  ))}

                                </div>
                                <div className="py-3">

                                </div>
                              </div> */}
                            </div>
                          </div>


                        </div>

                      </div>
                    </div>

            


                    <div className="row mb-3">
                    <div hidden className="col-md-4">

<div hidden className="dash-card table-card">
 {/* <p><strong>EXPENSES</strong></p> */}
     <div className="row">
      {!open1
      ?
      <><div className="col-md-6">
     <p><strong>Start Date</strong></p>
     <p><strong><input style={{width:"110px"}} readOnly type="text" value={datestartexp} onClick={toggledateexp} placeholder="DD/MM/YYYY"></input></strong></p>
     
     </div>
     <div className="col-md-6">
     <p><strong>End Date</strong></p>
      <input style={{width:"110px"}} readOnly type="text" value={dateendexp} onClick={toggledateexp} placeholder="DD/MM/YYYY"></input>

     </div>

     <p><strong>Total Expenses</strong></p>
          {/* <div className="input-container">
             <input
             type="date"
             value={selectedDate}
             onChange={handleDateChange}
              className="date-input"
             />
          </div> */}
        
          <div class="table-widget">
     
          <table class="table border">
            <thead>
              <tr>
                <th style={{ ...thStyle }}><p><strong>Account Head</strong></p></th>
                <th style={{ ...thStyle }}><p><strong>DR</strong></p></th>
                <th style={{ ...thStyle }}><p><strong>CR</strong></p></th>
                <th style={{ ...thStyle }}><p><strong>Transaction Type</strong></p></th>
              </tr>
            </thead>
            <tbody>
               {total_expenses.map(obj => (
                <tr>
                <td>{obj.accounts_head}</td>
            
            {obj.debit_credit === "Dr" ? (
                <td>{obj.amount}</td>
            ) : (
                <td>0</td>
            )}
            {obj.debit_credit === "Cr" ? (
                <td>{obj.amount}</td>
            ) : (
                <td>0</td>
            )}
                <td>{obj.particulars}</td>
                  </tr>
                                ))}

                              </tbody>
          </table>
          </div>
     </> 
     :""}
     

      
     </div>
      
     <div style={{width:"690px"}}>
     <DateRangePicker
     open={open1}
     toggle={toggledateexp}
    //  minDate={total_fuelsales?.mindate? total_fuelsales?.mindate : null}
     onChange={(date) => setDateRangeexp(date)}
     />
     </div>
     


          </div>
 
</div>

<div className="col-md-4">
  <div className="dash-card table-card">
    <div className="row">
      {!open1 ? (
        <>
          <div className="col-md-6">
            {/* Start Date input can be added here if needed */}
          </div>

          <p><strong>Credit Customer</strong></p>
          <div className="input-container">
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChanges}
              className="date-input"
            />
          </div>
          {console.log(selectedDate, "selectedDate")}

          <div className="table-widget" style={{ maxHeight: '230px', overflowY: 'auto' }}>
            <table className="table table-bordered table-sm"> 
              <thead>
                <tr>
                  <th style={{ width: '30%' }}><strong>Customer Name</strong></th>
                  
                  <th style={{ width: '20%' }}><strong>Type</strong></th>
                  <th style={{ width: '20%' }}><strong>Amount</strong></th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedData).map((customerName, index) => (
                  <React.Fragment key={index}>
                    {groupedData[customerName].data.map((obj, idx) => (
                      <tr key={idx}>
                        <td>{obj.customer_name}</td>
                        
                        <td>{obj.ac_type}</td>
                        <td>{parseFloat(obj.quantity).toFixed(2)}</td> 
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'right' }}>  <strong>{customerName} :Total Aamount = {groupedData[customerName].total_quantity.toFixed(2)}</strong></td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  </div>
</div>


                      <div className="col-md-4">
                        <div className="dash-card line-graph">
                          <p><strong>Sales Trend</strong>   </p>
                          {console.log(typeof (graph))}

                          <LineChart
                            width={300}
                            height={300}
                            data={graph}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />

                            {graph_details.map((obj, idx) => (

                              <Line type="monotone" dataKey={obj} stroke={color[idx]} activeDot={{ r: 8 }} />
                            ))}
                          </LineChart>

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card table-card">
                          <p><strong>Top 10 Outstanding</strong>   </p>
                          <div className="table-widget mt-2">
                            <table className="table ">
                              <thead>
                                <tr className="border-0">
                                  <th>Employees Name</th>
                                  <th>Amount</th>
                                  <th>Action</th>

                                </tr>
                              </thead>
                              <tbody>
                                {custm_outstanding.map(obj => (
                                  <tr>
                                    <td>{obj.Customer_name}</td>
                                    <td>{obj.outstanding}</td>
                                   
                                    <td><a href={`${WEB_URL}/other-report/${id}/account_statements/${obj.id}`} className="action-link" ><i className="fa fa-ellipsis-h" aria-hidden="true"></i></a></td>

                                  </tr>
                                ))}

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card table-card">
                          <p><strong> Critical non fuel stock</strong> </p>
                          <div className="table-widget mt-2">
                            <table className="table ">
                              <thead>
                                <tr className="border-0">
                                  <th>Product Name</th>
                                  <th>Amount</th>


                                </tr>
                              </thead>
                              <tbody>
                                {critical_stock.map(obj => (

                                  <tr>
                                    <td>{obj.product_name}</td>
                                    <td>{obj.total_stock}</td>

                                  </tr>
                                ))}

                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
}
            </div>
          </div>
        </div>
      </div>
      {console.log(isOpen,"one")}
      <FuelModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >

        <div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Fuel Details</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body">

          </div>
          <div class="table-widget">
            <table class="table border">
              <thead>
                <tr>
                  <th>Tank Name</th>
                  <th>Capacity</th>
                  <th>Current Stock </th>


                </tr>
              </thead>
              <tbody>
                {
                  fueldata.map(obj => (
                    <tr>
                      <td>{obj.tank_name}</td>
                      <td>{obj.capacity}</td>
                      <td>{obj.current_stock}</td>


                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

        </div>

      </FuelModal>

      {console.log(isOpen1,"two")}
      <BankModal
        isOpen1={isOpen1}
        onRequestClose={toggleModal1}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >

<div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle1">Bank Details</h5><button type="button" onClick={toggleModal1} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body">

          </div>
          <div class="table-widget">
            <table class="table border">
              <thead>
                <tr>
                  <th>Tank Name</th>
                  <th>Capacity</th>
                  <th>Current Stock </th>


                </tr>
              </thead>
              <tbody>
                {/* {
                  fueldata.map(obj => (
                    <tr>
                      <td>{obj.tank_name}</td>
                      <td>{obj.capacity}</td>
                      <td>{obj.current_stock}</td>


                    </tr>
                  ))
                } */}
              </tbody>
            </table>
          </div>

        </div>

      </BankModal>

      {isClose ? (<TankModal
        isClose={isClose}
        hide={toggle}
        tankdata={tankdata}
        Bank_data={Bank_data}
      />) : null
      }




    </>
  )
}


export default DashBoard;

