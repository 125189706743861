import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import { selling_mode } from "../../../../constant/constant"
import { packing_unit } from "../../../../constant/constant"
import NonFuelProductSellingPriceChangeModal from './NonFuelProductSellingPriceChangeModal.component';
import Loader from '../../Employee/Loader.component'
import PaginationComponent from '../../../layouts/PaginationComponent';

const NonFuelProductSellingChangeModal = (props) => ReactDOM.createPortal(
	<NonFuelProductSellingPriceChangeModal   {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const NonFuelProductSellingPriceChangeConfig = (props) => {

	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [delete_id, deleteId] = useState([])
	const [startDate, setDate] = useState([])
	const [c_date, c_setDate] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [list_data, setList] = useState([])
	const [double, setDouble] = useState(true);
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [selling_price, setPrice] = useState({})
	const [rack_details, setRack] = useState([])
	const [sales_details, setSales] = useState({})
	const [dropdownData, setDropdownData] = useState([])
	const [mode, setSellingMode] = useState([])
	const [selected_options, setSelectedOptions] = useState([])
	const [productData, setProduct] = useState([])
	const [permission, setPermission] = useState([])
	const [loading, setLoading] = useState(false)
	const [search, setSearch] = useState('')
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {

		check_url();
		is_day_closed();


	}, []);
	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					ListNonfuelSellprice(pages.current_page);

				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	//-------------------end
	function ListNonfuelSellprice(page) {
		const requestOptions = {      //---Fleetacard listing---//
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(`${API_URL}/get-non-fuel-data?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setList(responseData.data.data)
				setDate(responseData.start_date)


				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true)
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}

	function getProducts() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/get_nonfuel_products", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				var datas = []

				responseData.non_fuel.forEach(elem => {
					datas.push([elem.id, elem.product_name, elem.selling_mode])
				})

				setProduct(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListNonfuelSellprice(number)
	}
	function getPrice(id) {
		const requestOptions = {
			method: 'POST',
			body: JSON.stringify({ id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(API_URL + "/get_price_non_fuel", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				// setInputValues({...inputValues,price:responseData[0].selling_price})
				setPrice({ ...selling_price, price: responseData[0].selling_price, loose_price: responseData[0].selling_price_loose })
				return responseData;
			})
			.catch(e => {
				console.log(e);



			});

	}
	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				c_setDate(responseData)

			})
			.catch(e => {
				console.log(e);

			});

	}


	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if(c_date ==1){
                swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false? "success":"warning")
            }else{

			getProducts();
			if (isShowing) {
				setInputValues({})
				setErrors({})
				setPrice({})
				setSellingMode([])
				setSales({})
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {
				setDouble(true)

				setErrors({})

			}
			setIsShowing(!isShowing);
		}
	}
		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleInputs = (e) => {

		if (e.target.name == "new_price") {

			var sales = e.target.value - selling_price.price ? e.target.value - selling_price.price : 0
			var value1 = parseFloat(sales) ? parseFloat(sales).toFixed(2) : sales;


		}
		if (e.target.name == "new_selling_price_loose") {

			var loose_sales = e.target.value - selling_price.loose_price ? e.target.value - selling_price.loose_price : 0
			var value2 = parseFloat(loose_sales) ? parseFloat(loose_sales).toFixed(2) : loose_sales;
		}


		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
			sales_price: e.target.value == "" ?"": value1,
			differene_loose: e.target.value == "" ?"": value2,
			price: selling_price.price
		});


	};

	const handleSelect = (e) => {
		if (e.target.value == "") {
			inputValues.new_price = ""
			inputValues.sales_price = ""
			inputValues.new_selling_price_loose = ""
			inputValues.differene_loose = ""
			selling_price.price = ""
			selling_price.loose_price = ""

		}
		if (e.target.name == "id") {
			inputValues.new_price = ""
			inputValues.sales_price = ""
			inputValues.new_selling_price_loose = ""
			inputValues.differene_loose = ""

		}
		setPrice(selling_price)
		productData.map((elem) => {
			if (e.target.value == elem[0]) {
				setSellingMode({
					...mode,
					selling_mode: elem[2]
				})
			}
		})
		setInputValues({
			...inputValues,
			id: e.target.value
		});
		var id = e.target.value
		getPrice(id);

	};


	const handleChange = (e) => {

		var id = e.target.value
		getPrice(id)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});
	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}

	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		sendNonfuelproductSale()
	}
	//---Create EmployeeList---//
	function sendNonfuelproductSale() {
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				id:inputValues.id,
				op_date:document.getElementById("m_op_date").value,
				new_price:inputValues.new_price,
				differene_loose:inputValues.differene_loose,
			})
		};
		fetch(API_URL + "/nf_selling_price_change", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["name"] = responseData.response.id
					errors["new_price"] = responseData.response.new_price
					setDouble(true)


				}

				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/NonFuel-product-Selling-Price-Change")
					setErrors([''])
					setInputValues([])

					toggle();
					ListNonfuelSellprice(pages.current_page);



				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				swal("Something went wrong", "", "warning")
				console.log(e)
				setDouble(true)
			});

		//product name dropdown api integration//
		function getProduct() {
			const requestOptions = {
				method: 'get',
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},
			};
			fetch(API_URL + "/get_product_name", requestOptions)
				.then(response => { return response.json(); })
				.then(responseData => {
					var datas = []

					responseData.forEach(elem => {
						datas.push([elem.id, elem.fuel_name])
					})

					setProduct(datas);
					return responseData;
				})
				.catch(e => {
					console.log(e);

				});
		}


	}

	/*****approval******/
	const UseApproveModal = () => {
		const [isApprove, setApprove] = useState(false);
		function toggleModal2() {
			setApprove(!isApprove);
		}
		return {
			isApprove,
			toggleModal2,
		}
	};


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={6} operation={1} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div className="container pb-3">
							<div className="row mt-md-5 mt-3 form-container">
								<div className="col-md-12 mb-3 px-4 mt-2"><Link to="/nonfuel-product-purchase">
									<a href="nonfuel-product-purchase" className="float-end btn btn-gray">Back</a>
								</Link></div>
								<div className="form-wrap ">
									{permission.status == 3 ?
										<div className="form-wrap mt-5">
											<div className="text-center">
												{permission.message}
											</div></div> :
										<>

											<div>
												{loading ? ListNonfuelSellprice : <Loader />}
												<div className="tab-content" id="myTabContent">
													<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
														<div className="">

															<div className="row mb-3">
																<div className="col-md">
																	<div className="row">

																		<div className="col-md col pl-0 col-12">
																			<h3 className="table-title">NON FUEL PRODUCT SELLING PRICE CHANGE	  </h3>
																		</div>

																	</div>
																</div>
																<div className="col-md-4">
																	<a className="btn btn-primary  mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Edit NonFuel Product Selling Price </a>
																</div>
																<div className="col-md-4">
																	<div className="input-group mb-3 search">
																		<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																		</span>
																		<input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch} />
																		<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																	</div>
																</div>

															</div>


															<div className="table-widget">
																<table className="table border col-12">
																	<thead>
																		<tr>

																			<th>Product Name</th>
																			<th>Packing Unit</th>
																			<th>Quantity</th>
																			<th>Product Full Name</th>
																			<th>Selling Mode</th>
																			<th>Total Stock</th>
																			<th>Selling Price</th>
																			<th>Loose Selling Price</th>
																			<th>Margin/Pack</th>
																			<th>Margin/Loose LT/</th>
																			<th>Stock Value</th>

																		</tr>
																	</thead>
																	<tbody>
																		{
																			list_data.map(obj => (
																				<>
																					<tr>

																						<td>{obj.product_name}</td>
																						<td>{packing_unit[obj.unit.toString()] ? packing_unit[obj.unit.toString()] : ''}</td>
																						<td>{obj.quantity}</td>
																						<td>{obj.product_full_name}</td>
																						<td> {selling_mode[obj.selling_mode] ? selling_mode[obj.selling_mode.toString()] : ''} </td>
																						<td>{obj.total_stock}</td>
																						<td> {obj.selling_price} </td>
																						<td> {obj.selling_price_loose} </td>
																						<td> {obj.margin_price} </td>
																						<td> {obj.margin_price_loose} </td>
																						<td>{obj.stock_value}</td>





																					</tr>

																				</>
																			))}

																	</tbody>
																</table>
																{loading ? <PaginationComp
																	pages={pages}
																	handlePagination={handlePagination}
																/> : ""
																}


															</div>

														</div>

													</div>

												</div>
											</div>
										</>}

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>



			{isShowing ? (<NonFuelProductSellingChangeModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				handleSelect={handleSelect}
				inputValues={inputValues}
				fields={fields}
				success={success}
				mode={mode}
				startDate={startDate}
				double={double}
				sales_details={sales_details}
				rack_details={rack_details}
				selling_price={selling_price}
				dropdownData={dropdownData}
				productData={productData}

				selected_options={selected_options}

				handleSubmit={handleSubmit}
				errors={errors}
			/>) : null
			}


			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				{/* <div className="modal-content">
					<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">Customers</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div className=" modal-body  modal-body"><p>Are you sure you want to delete this customer</p></div>
					<div className="justify-content-end  modal-footer"><div className="d-flex">
						<button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						<button type="submit" className="btn btn-primary" onClick={delete_NonfuelSellPrice}>Yes</button>
					</div>
					</div>
				</div> */}



			</Modal>

		</>
	)
}








export default NonFuelProductSellingPriceChangeConfig