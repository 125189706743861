import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {useRef} from 'react';
import {preventNonNumericalInput,prevNonNumericalWithDec} from "../../../layouts/Operational_Sidebar_Progress.component";

const FleetCardModal = ({ isShowing, hide,double, errors, inputValues,btnRef, productData,fleetcarddropdown, handleChange, new_price,handleSelect, handleSubmit, handleInputs,success, handleInput }) => {
   
    return (
        <React.Fragment>

          
            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modal-dialog-xl modalxl">

                    <div class="modal-header mb-3">
                        <h5 class=" text-center" id="staticBackdropLabel">Fleet Card sales</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">


                        <div class="">

                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Card Name</label>
                                </div>
                                <div class="col-6">
                                    <div class="select-container">
                                    <select id="" class="form-control " name="fleetcard_id" value={inputValues.fleetcard_id} onChange={handleInputs} >
                                    <option value ="">select</option>
                                            {
                                                fleetcarddropdown.map((datass) => {
                                                 
                                                    return <option value={datass[0]}>{datass[1]}</option>
                                                })}
                                        </select>
                                        </div>
                                        {/* <div className="errorMsg">{errors.fleet}</div> */}
                                        <div className="errorMsg">{errors.fleetcard_id}</div>
                                </div>
                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Card Number</label>
                                </div>
                                <div class="col-6">
                                    <input type="number" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} class="form-control  hide-arrow" name="card_no" value={inputValues.card_no} onChange={handleSelect} />
                                </div>
                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Description</label>
                                </div>
                                <div class="col-6">
                                    <input type="text" class="form-control " name="note" value={inputValues.note} onChange={handleSelect} />
                                </div>
                            </div>


                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Product<sub class="star">*</sub></label>
                                </div>
                                <div class="col-6">
                                    <div class="select-container">
                                        <select id="" class="form-control " name="product_id" value={inputValues.product_id} onChange={handleChange}>
                                            <option value ="">select</option>
                                            {
                                                productData.map((data) => {
                                                    console.log(data,"sa")
                                                 
                                                    return <option value={data[0]}>{data[1]}</option>
                                                })}
                                        </select>
                                    </div>
                                    <div className="errorMsg">{errors.product_id}</div>
                                </div>

                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Quantity</label>
                                </div>
                                <div class="col-6">
                                    <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control  hide-arrow " name="quantity" value={inputValues.quantity} onChange={handleInput} />
                                </div>
                            </div>

                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Price (auto)</label>
                                </div>
                                <div class="col-6">
                                   <input type="text" class="form-control " name="price" value={inputValues.product_id !="select"?inputValues.price:''} onChange={handleInputs} readOnly />
                            </div>
                            </div>
                            <div class="row g-3 justify-content-center mb-2">
                                <div class="col-6">
                                    <label class="col-form-label">Amount<sub class="star">*</sub></label>
                                </div>
                                <div class="col-6">
                                    <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control  hide-arrow " name="amount" value={inputValues.amount} onChange={handleInputs}  />
                                   
                                    <div className="errorMsg">{errors.amount}</div>
                                    <span  class="amount-words"></span>

                                </div>
                            </div>
                            {localStorage.getItem("save_hide") == 'false' ?
                            <div class="modal-footer text-center">
                                <a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
                                {double== true?<a className="btn btn-primary px-4" type="submit"   onClick={handleSubmit}>SAVE</a>: <a className="btn btn-primary px-4" type="submit"  disabled>SAVE</a>}
                            </div>
                            :''}

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default FleetCardModal