
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Admin_Sidebar_Progress.component";
import React, { useState } from "react";
import museum from  '../../img/museum.svg'
import ReactDOM from 'react-dom';
import PermissionModal from './PermissionModal.component'
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";

var PermModal = (props) => ReactDOM.createPortal(
	<PermissionModal  {...props} />, document.body
		 );

const PermissionConfig = () => {
  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);
   
    function toggle() {
      if(isShowing) 
			{
			   setInputValues([])
			   setErrors({})
         setSuccess({})
		   }
      setIsShowing(!isShowing);
    }
  
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])
  const handleInputs = (e) => {
    setInputValues({
       ...inputValues,
       [e.target.name]: e.target.value,
       
     });
   };
 
   function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.permission_name){
      formIsValid = false;
      errors["permission_name"] = "Enter Permission Name"
    }
    
    setErrors(errors)
    
     return formIsValid;

  }



   const handleSubmit = (event ) =>
   {
     event.preventDefault();
   
      if(validateForm()){
     savePermission()
      }
     
   }
  
 const [search,setSearch]=useState('')
 const  handleSearch = (event) =>
 {
  setSearch(event.target.value)
 }


 useEffect(()=>{

  ListPermissions(pages.current_page);
 },[search]); 


 const [pages,setpages]=useState(
 {
 current_page:1,
 last_page:0,
 total_pages:0,
 pagination_item:[],
 total_pages:0,
})

const handlePagination = (number) =>
   {
       setpages({
         ...pages,
         current_page:number
       }
           )
 
           ListPermissions(number)
 
   }
   async function savePermission()
   {
 
    
           const requestOptions = {
           method: 'POST',
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
            },
              body: JSON.stringify(inputValues)
       };
       await fetch(API_URL+"/permission",requestOptions)
               .then(response => 
                 { 
          
                    return response.json();})
       .then(responseData => {
      setSuccess({...success,
           disabled : responseData.status === true?true:false,
      })
      if (responseData.status == true) {
         swal("",responseData.message,responseData.status?"success":"warning")
         history.push("/permission")
        //  setDidabled({
        //    isDisabled:true
        //  })
         toggle();
          ListPermissions(pages.current_page);
          setInputValues("")
      }
      else if(responseData.status == false)
      {
        errors["permission_name"] = responseData.response.permission_name
      }
      setErrors(errors)
          return responseData;
         })  .catch(e => {
                   console.log(e);     
               });
   }
  function ListPermissions(page)
   {
           const requestOptions = {
           method: 'get',
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
         
            },
       };
      
        fetch(`${API_URL}/permission?search_keyword=${search}&page=${page}` ,requestOptions)
               .then(response => { return response.json();})
       .then(responseData => {
      setList(responseData.data) 
      var total_pages = responseData.last_page
    
      var items = []
     for (let number = 1; number <= total_pages; number++) {
        number===page?items.push(
           <li key={number} className="page-item active"><a className="page-link" onClick={()=>handlePagination(number)}>{number}</a></li>
           ,):items.push(
           <li key={number} className="page-item"><a className="page-link" onClick={()=>handlePagination(number)}>{number}</a></li>
           ,)
            }

            setpages({
             ...pages,
         current_page:responseData.current_page,
         last_page:responseData.last_page,
         total_pages:responseData.last_page,
         pagination_items:items
        })
  return responseData;
         })
               .catch(e => {
                   console.log(e);
                  
               });
   }
   function deletePerm(id)
  {
    console.log(id)
    deleteId(id)
   
    toggleModal()
  }
  function deletePermisson()
  {
   
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/permission/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListPermissions(pages.current_page);
           toggleModal()
 
       return responseData;
     
   
         })
   
     .catch(err=>{
            
         })
  }
      async function editPermission(id)
     {
  
           const requestOptions = {
           method: 'get', 
           data: {id:id},     
           headers: {
             "Access-Control-Allow-Origin": "*",
             "Content-Type":"application/json",
             "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
             "Accept": "application/json"
         
            },  
       };
        await fetch(API_URL+"/permission/"+id+"/edit",requestOptions)
               .then(response => { return response.json();})
       .then(responseData => {
       console.log(responseData)
        // setId(responseData) 
        
        // console.log(payment_id)
        setInputValues(
         {
          permission_name : responseData.permission_name,
   
           id:responseData.id ? responseData.id : ''
         }
       );
            toggle();
     
     return responseData;
         })
               .catch(e => {
                   console.log(e);
                  
               });
     }
   const [inputValues,setInputValues] = useState({});
  const {isShowing, toggle} = UseModal();
  const [list_data,setList] = useState([]) 
  const [success,setSuccess ] = useState([]) 
  const [errors, setErrors] = useState([ ])
  const [isDisabled,setDidabled ] = useState([false]) 
  const history = useHistory()
    return(
        <>

          <div className="main-container page-wrap">
<div className="d-md-flex">
<Sidebar_Progress />
<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3 mt-3">
<div class="row">

 </div>
	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col ps-0">
							<h3 className="table-title mt-2">Permission Management</h3>

						</div>
						<div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 "  data-bs-target="#bank" onClick={toggle}>Add Permission</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Permission Name"  value={search.search_keyword} onChange={handleSearch} onKeyPress={(event) => {
    var key = event.keyCode || event.which;
    if (key === 13) {
        
    }
}}    />
</div>
					</div>
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Permission Name</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {
list_data.map(data => (    
     <tr>
      <td>{data.permission_name}</td>
  
     
      <td><div className="d-flex icon-btn">
      <button onClick={()=> editPermission(data.id)} >  <i className="fa fa-pencil" aria-hidden="true" ></i>
</button>
      <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> deletePerm(data.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
    </tr>
     
      ))}  
</tbody>
</table>
<nav aria-label="Page navigation example">
             <ul className="pagination justify-content-end">
    {pages.total_pages>1 && pages.pagination_items}
  </ul>
</nav>
				</div>
			</div>
    	</div>
	</div>
</div>
{isShowing ? (<PermModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        inputValues={inputValues}
	    	// isDisabled={isDisabled}
        // dropdownData ={dropdownData}
        success={success}
        handleSubmit={handleSubmit}
		   // handleChange={handleChange}
		// handleAdd={handleAdd}
		// handleRemove ={handleRemove}
         errors={errors}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Permission</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Permission</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
					<button type="submit" class="btn btn-primary"  onClick={ deletePermisson}>Yes</button>
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>

</div>
</div>
</div> 

</>
    )
}
    
export default PermissionConfig