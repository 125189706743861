import React from "react";
import { Component } from "react";
import axios from 'axios';
import '../App.css';
import { API_URL } from '../constant/API_Settings'
import { Link } from "react-router-dom";
import { Modal, ModalFooter } from "react-bootstrap";
class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planDetail:{},
      backPage:this.props.location.backpage,
            billingDetail: {
                'billing_name': '',
                'billing_address': '',
                'billing_email': '',
                'billing_tel': '',
                'amount': '',
              },
        }
    }
    componentDidMount(){
    }

    subscribe = (id) =>{

    axios({
        url: `${API_URL}/checkout/${id}`,
        method: "get",
        data: {
            plan_id:id
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        }
      }).then(res => {

        var billingDetail = this.state.billingDetail
        billingDetail['billing_name'] = res.data.pump.owner_name
        billingDetail['billing_address'] = res.data.pump.pump_address
        billingDetail['billing_email'] = res.data.pump.pump_email
        billingDetail['billing_tel'] = res.data.pump.pump_phone
        billingDetail['amount'] = res.data.plan.amount
        billingDetail['plan_id'] = id
          this.setState({
            planDetail:res.data.plan,
            billingDetail:billingDetail,
            modalShow: true
          });
    }).catch(err => {

    })

}
checkout = () => {
    const requestOptions = {
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        },
        body: JSON.stringify(this.state.billingDetail),
    };
        fetch(API_URL + "/initiate_payment", requestOptions)
      .then((responseData) => {return responseData.json();
 })
 .then((responseData) => {
          var url = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction'
        var formElement = document.createElement("form");
        formElement.setAttribute("method", "post");
        formElement.setAttribute("action", url);
        var encRequest = document.createElement("input");
        encRequest.setAttribute("name", 'encRequest');
        encRequest.setAttribute("value", responseData.encrypted_data);
        formElement.appendChild(encRequest);
        var access_code = document.createElement("input");
        access_code.setAttribute("name", 'access_code');
        access_code.setAttribute("value", responseData.access_code);
        formElement.appendChild(access_code);
        document.body.appendChild(formElement);
        console.log(formElement)
    formElement.submit();
    return responseData.json();
    }).catch(err => {
        console.log(err)
    })
}



setModalShow(event, show) {

    this.setState({
      modalShow: show,
      billingDetail: {
        'billing_name': '',
        'billing_address': '',
        'billing_email': '',
        'billing_tel': '',
        'plan_id': '',
      },
    //   user_form_errors: [],


    })
  }

  handleInputs = (event) => {

    
    let billingDetail = this.state.billingDetail;
    billingDetail[event.target.name] = event.target.value;
    this.setState({
        billingDetail: billingDetail
    });

  }
subscriptionModel = (props) => {
    return (
      <Modal
        {...props}

      >
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">Subscription</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setModalShow(event, false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
        <div className="form-group">
            <label>Billing Name<sub className="star">*</sub></label>
            <input type="text" className="form-control" name="billing_name" value={this.state.billingDetail.billing_name} onChange={this.handleInputs} />
            <div className="errorMsg"></div>
          </div>
        <div className="form-group">
            <label>Billing Address<sub className="star">*</sub></label>
            <input type="text" className="form-control" name="billing_address" value={this.state.billingDetail.billing_address} onChange={this.handleInputs} />
            <div className="errorMsg"></div>
          </div>
        <div className="form-group">
            <label>Billing Email<sub className="star">*</sub></label>
            <input type="text" className="form-control" name="billing_email" value={this.state.billingDetail.billing_email} onChange={this.handleInputs} />
            <div className="errorMsg"></div>
          </div>
        <div className="form-group">
            <label>Billing Tel<sub className="star">*</sub></label>
            <input type="text" className="form-control" name="billing_tel" value={this.state.billingDetail.billing_tel} onChange={this.handleInputs} />
            <div className="errorMsg"></div>
          </div>
        <div className="form-group">
            <label>Amount<sub className="star">*</sub></label>
            <input type="text" className="form-control" name="amount" value={this.state.billingDetail.amount} readOnly />
            <div className="errorMsg"></div>
          </div>
        </Modal.Body>
        <ModalFooter className="justify-content-end ">
          <div className="d-flex">
            <button type="button" onClick={() => this.checkout()} className="btn btn-primary">Checkout</button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }


        render() {
        
            return(
    
    <>
    
      <div className="main-container section-container">
      <div className="container pt-1">
      { this.props.location.pathname == "/subscription/pay"?<div class="col-md-12 mb-3"><Link  to={this.state.backPage||"/operational-date"}><a href="#" class="float-end btn btn-gray">Go Back</a></Link></div>: ''}
            
              <div className="row mt-3 mb-2 ">
                  <div className="col-12 text-center"><h2>Subscription</h2><p>Simple Pricing, No surprises. Choose a plan that best fit for you.</p></div>
                </div>
              <div className="row" style={{"justify-content": "center"}}>
                  {this.props.location.pathname == "/subscription/pay"?'':<div className="col-12 col-md-3">
                      <div className="card subscription card-primary ">
                          <div className="card-header">Trial version</div>
                            <div className="card-body h-50" style={{"min-height": "82px"}}>
                              <ul>
                                 
                                 
                                  
                
                              </ul>
                              
                          </div>
                          <button type="submit" className="btn btn-primary btn-sm" onClick={(event) => this.props.history.push("/fuel-config")}>SUBSCRIBE</button>
                      </div>
                      <div hidden className="card subscription card-primary ">
                          <div className="card-header">Full version</div>
                            <div className="card-body h-100">
                              <ul>
                                  <li>Only for 15 days.</li>
                                  <li>Full functional version</li>
                                  <li>Full customer support</li>
                                  <li>Reports will not work after 15 days</li>
                                  <li>Data entry can continue after 15 days to 30days</li>
                                  <li>Once you pay you will automatically change to paid version</li>
                              </ul>
                              
                          </div>
                          <button type="submit" className="btn btn-primary btn-sm" onClick={(event) => this.props.history.push("/fuel-config")}>SUBSCRIBE</button>
                      </div>
                  </div>}
                  <div hidden className="col-12 col-md-3">
                      <div className="card subscription card-info">
                          <div className="card-header">Monthly package</div>
                            <div className="card-body  h-100">
                              <ul>
                                  <li>2500 + GST per month</li>
                                  <li>Full functional version</li>
                                  <li>Full customer support</li>
                                  <li>Reports will not work after 15 days</li>
                                  <li>Data entry can continue after 15 days to 30 days</li>
                                  <li>Once you pay you will automatically change
                                  to paid version</li>
                              </ul>
                              
                          </div>
                          <button type="submit" className="btn btn-primary btn-sm" onClick={(event) => this.subscribe(2)}>SUBSCRIBE</button>
                      </div>
                  </div>
                  <div hidden className="col-12 col-md-3">
                      <div className="card subscription card-warning">
                          <div className="card-header">Quarterly package</div>
                            <div className="card-body h-100">
                              <ul>
                                  <li>5% discount on price.
                                  You pay only 7375+GST for 3 months</li>
                                  <li>Full functional version</li>
                                  <li>Full customer support</li>
                                  <li>Reports will not work after 15 days</li>
                                  <li>Data entry can continue after 15 days to 30 days</li>
                                  <li>Once you pay you will automatically change
                                  to paid version</li>
                              </ul>
                             
                          </div>
                           <button type="submit" className="btn btn-primary btn-sm" onClick={(event) => this.subscribe(3)}>SUBSCRIBE</button>
                      </div>
                  </div>
                  <div hidden className="col-12 col-md-3">
                      <div className="card subscription card-success">
                          <div className="card-header">Annual Package</div>
                            <div className="card-body h-100">
                              <ul>
                                  <li>Full functional version &
                                  Full customer support</li>
                                  <li>Reports will not work after 15 days</li>
                                  <li>Dataentry can continue after 15 days to 30days</li>
                                  <li>Once you pay you will automatically change to paid version</li>
                                  <li>10% Discount on price You pay only 27000 + GST for 1 year</li>
                                  <li>Move to Payment  and Back for options other than trial version 
                                  </li>
                              </ul>
                              
                          </div>
                          <button type="submit" className="btn btn-primary btn-sm" onClick={(event) => this.subscribe(4)}>SUBSCRIBE</button>
                      </div>
                  </div>
              </div>
      </div>
    </div>
    <this.subscriptionModel
        show={this.state.modalShow}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setModalShow()}
        data={this.state}
      />
      </>
    )}}
    
    
export default Subscription;