//import constants from "../config/constant";
const getDetails = (props = null) => {
  let token = props?.user_token || localStorage.getItem("AUTH_TOKEN");
  let user_role = props?.user_role || localStorage.getItem("ROLE_ID");
  let user_id = props?.user_id || localStorage.getItem("USER_ID");
  let permissions =
    props?.permissions ||
    localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

  let user_details = {
    loggedin: token ? true : false,
    token: token,
    role: user_role,
    permissions: permissions || [],
    user_id: user_id,
  };

  return user_details;
};

export default getDetails;
