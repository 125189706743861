import React, { Component } from 'react'


class Type extends Component
{
    constructor(props)
    {
        super(props)
       
    }
    render()
    {
        return (<option key={this.props.dealer_id} value={this.props.dealer_id} full_name = {this.props.full_name}>{this.props.dealer_ID}</option>
        )
    }
}

export default Type