import { extend } from "jquery";
import React, { Component } from "react";
import customer_review from '../../img/customer-review.png'
import LoginHeader from '../layouts/LoginHeader.component'
import {AiFillEyeInvisible, AiFillEye} from 'react-icons/ai';
import Sidebar_Progress from '../layouts/Sidebar_Progress.component'
import { API_URL } from '../../constant/API_Settings'
import { user_role } from '../../constant/constant'
import axios from 'axios';
import swal from "sweetalert";
import { Modal, ModalFooter } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip' ;
import Loader from '../operational-model/Employee/Loader.component'
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';
import Pagination from "react-js-pagination";

class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_result: [],
      currentPage: 1,
      lastPage: 0,
      role_data: {},
      total_pages: 0,
      loading: false,
      status: 0,
      double: true,
      message: '',
      disable: true,
      search_keyword: '',
      pagination_items: [],
      user_fields: {},
      backPage: this.props.location.backpage,
      user_form_errors: {},
      delete_userId: '',
      deactivate_userId: '',
      modalShow: false,
      showDeletemodel: false,
      showDeactivatemodel: false,
      deactivate_status: '',
      errors: [],
    start_operation:'',

    }

  }


  //------------------------url
  check_url = () => {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        this.setState({
          start_operation: responseData.stat_operation
        })
        console.log(responseData)
        if (responseData.allowurl == 1) {
          if (this.props.location.pathname == "/products/products") {
            this.props.history.push(this.props.location.pathname);
          }
          else {
            this.props.history.push(responseData.url);
          }
          this.callUser(1)

        }
        else {

          this.props.history.goBack();
        }

        return responseData;
      })
      .catch(e => {
      });

  }



  //--------------------------//
  handleuserInputChange = (event) => {



    let user_fields = this.state.user_fields;

    user_fields[event.target.name] = event.target.value;
    this.setState({
      user_fields: user_fields
    });

  }
  handleUserInputs = (e) => {
    let user_fields = this.state.user_fields;
			if (!e.target.value.match(/^[a-zA-Z ]*$/)) {
			 e.preventDefault()
			}
      else
      {	  
    user_fields[e.target.name]= e.target.value;
      }
    this.setState({
      user_fields: user_fields
    });

  };
  handleuserInputChange1 = (event) => {

    var index = event.nativeEvent.target.selectedIndex;
    if (event.nativeEvent.target[index].text == "Customer Attendant") {
      var star1 = true
      this.setState({ mandatory: star1 })

    }
    else {
      var star1 = false
      this.setState({ mandatory: star1 })
    }
    let user_fields = this.state.user_fields;
    user_fields[event.target.name] = event.target.value;
    this.setState({
      user_fields: user_fields
    });

  }

  validate_user_form = () => {
    var validation_form = true;
    var { user_form_errors } = this.state;
    return validation_form
  }

  createuser = () => {
    axios({
      url: API_URL + "/user",
      method: "POST",
      data: this.state.user_fields,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {

      // if (res.data.status == false){
      //   swal("", res.data.message, res.data.success ?  "success" :"danger" )
      // }

      if (res.data.status === false) {
        var user_form_errors = []
        console.log(res.data.response.name)

       user_form_errors['opening_balance'] = res.data.response.opening_balance
        user_form_errors['email'] = res.data.response.email
        user_form_errors['name'] = res.data.response.name
        user_form_errors['user_name'] = res.data.response.user_name
        user_form_errors['phone'] = res.data.response.phone
        user_form_errors['password'] = res.data.response.password
        user_form_errors['role_id'] = res.data.response.role_id
        user_form_errors['debit_credit'] = res.data.response.debit_credit

        //  swal("",res.data.response.message,res.data.success?"warning":"success")  
        this.setState({ user_form_errors: user_form_errors, disable: true })

      }
      if (res.data.status == true) {
        var user_fields = this.state.user_fields
        user_fields.password = ""


        this.setState({

          user_fields: user_fields,
          disable: true
        })

        this.state.user_fields.name = ""
        this.state.user_fields.name = ""

        this.setState({ modalShow: false })
        //  this.setState({user_form_errors:[""]})

        swal("", res.data.message, res.data.success ? "warning" : "success")

        this.callUser(this.state.currentPage)
      }




    }).catch(err => {

    })

  }
  handleusersubmit = (event) => {

    event.preventDefault();
    this.setState({ disable: false })
    if (this.validate_user_form()) {
      this.createuser()
    }


  }

  handleSearch = (event) => {
    var value = event.target.value
    this.setState({
      search_keyword: value
    })
    console.log(this.state.search_keyword)
  }
  edit_user = (id, ac_name, ac_name1) => {
  
    this.state.user_form_errors = ""
    this.setState({ user_id: id })

    axios({
      url: API_URL + "/user/" + id + "/edit",
      method: "GET",
      data: {
        id: id,
        ac_name: ac_name,
        ac_name1: ac_name1
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      var user_fields = this.state.user_fields
      user_fields['id'] = res.data.data.id ? res.data.data.id : ''
      user_fields['ac_name'] = res.data.data.ac_name ? res.data.data.ac_name : ''
      user_fields['ac_name1'] = res.data.data.ac_name1 ? res.data.data.ac_name1 : ''
      user_fields['name'] = res.data.data.name
      user_fields['password'] = res.data.data.password
      user_fields['phone'] = res.data.data.phone
      user_fields['user_name'] = res.data.data.user_name
      user_fields['email'] = res.data.data.email
      user_fields['role_id'] = res.data.data.role_id
      user_fields['address'] = res.data.data.address
      user_fields['designation'] = res.data.data.designation
      user_fields['debit_credit'] = res.data.data.debit_credit
      user_fields['opening_balance'] = res.data.data.opening_balance
      user_fields['ob_is_dayclose'] = res.data.ob_is_dayclose == 1 ? true : false
      if (res.data.data.iscusatt == "1") {
        var star1 = true
        this.setState({ mandatory: star1 })
  
      }
      else {
        var star1 = false
        this.setState({ mandatory: star1 })
      }
      this.getRole();
      this.setState({ user_fields: user_fields, modalShow: true })
      // this.setModalShow(true)

    }).catch(err => {

    })

  }
  componentDidMount() {

    this.check_url();




  }
  getRole = () => {
    var { role_data } = this.state
    axios({
      url: API_URL + "/get-role",
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
     
      console.log(res.data)
      this.setState({ role_data: res?.data ? res?.data : "" })
        

    }).catch(error => {
      console.log(error)
    })
  }

  callUser = (page) => {
    axios(
      {
        url: `${API_URL}/user?search_keyword=${this.state.search_keyword}&page=${page}`,
        method: "get",
        // data: {
        //   search_keyword:
        // },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        }
      }
    ).then(res => {
      console.log(res.data.status)
      if (res.data.status === 3) {
        this.setState({
          status: res.data.status, message: res.data.message
        })
      }
      this.setState({
        user_result: res.data.data.data,
        owner_status: res.data.data.data[res.data.data.data.length - 1].id,
        isdayclose: res.data.isdayclose,
        currentPage: res.data.data.current_page,
        lastPage: res.data.data.last_page,
        total_pages: res.data.data.total,
        per_page: res.data.data.per_page
      });



      this.setState({
        loading: true
      })

    }).catch(err => {
      console.log(err)
    })
  }



  handlePagination = (number) => {
    this.state.currentPage = number
    this.callUser(number)
  }
  addUser = () => {
    this.getRole();
    let user_form_errors = []
    var user_fields = this.state.user_fields
    var owner_status = this.state.owner_status
    user_fields.name = ""
    user_fields.id = ""
    user_fields.phone = ""
    user_fields.email = ""
    user_fields.password = ""
    user_fields.user_name = ""
    user_fields.total_stock = ""
    user_fields.role_id = ""
    owner_status = ""
    user_fields.address = ""
    user_fields.designation = ""
    user_fields.opening_balance = ""
    user_fields.debit_credit = ""

    this.setState({
      user_form_errors,
      user_fields: user_fields,

      owner_status: owner_status

    })


    var isdayclose = this.state.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {
      this.setState({ modalShow: true })
    }
  }
  setModalShow(event, show) {
   if(show == false)
   {
     this.callUser(this.state.currentPage)
   }
   
    let user_form_errors=[]

    var user_fields = this.state.user_fields
    user_fields.name = ""
    user_fields.phone = ""
    user_fields.email = ""
    user_fields.password = ""
    user_fields.user_name = ""
    user_fields.total_stock = ""
    user_fields.role_id = ""
    user_fields.address = ""

    user_fields.designation = ""
    user_fields.opening_balance = ""
    user_fields.debit_credit = ""

    this.setState({
      modalShow: show,
      user_form_errors,
      user_fields: user_fields,

    })

  }
  setDeleteModalShow(event, show) {
    this.setState({
      showDeletemodel: show
    })
  }
  delete_user = (id) => {

    var isdayclose = this.state.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {
      this.setState({ delete_userId: id, showDeletemodel: true })
    }


  }
  setDeactivateModalShow(event, show) {
    this.setState({
      showDeactivatemodel: show,

    })
  }
  change_status = (id, status) => {

    this.setState({ deactivate_userId: id, showDeactivatemodel: true, deactivate_status: status })


  }
  /////Password///

  setPasswordModalShow(event, show) {
    var user_fields = this.state.user_fields
    user_fields.password = ""
    user_fields.password_confirmation = ""

    this.setState({
      showPasswordmodel: show,
      errors: [],

      user_fields: user_fields
    })
  }
  edit_password = (id, password) => {

    this.setState({ edit_passwordId: id, showPasswordmodel: true })


  }

  toggleShowPassword =()=> {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));

  };
  submitpassworduser = () => {
    var user_id = this.state.edit_passwordId
    axios({
      url: API_URL + "/change-password",
      method: "POST",
      data: { id: user_id, password: this.state.user_fields['password'], password_confirmation: this.state.user_fields['password_confirmation'] },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      if (res.data.status == true) {
        this.setState({ edit_passwordId: '', showPasswordmodel: false })
        swal("", res.data.message, res.data.status ? "success" : "danger")
        var user_fields = this.state.user_fields
        user_fields.password = ""
        user_fields.password_confirmation = ""

        this.setState({

          user_fields: user_fields
        })
        this.callUser(this.state.currentPage)
      }
      let errors = []
      if (res.data.status == false) {

        errors['password'] = res.data.response.password
      }
      this.setState({ errors: errors })
      console.log(errors)

      // this.setModalShow(true)

    }).catch(err => {

    })


  }


  //wording


  handleInputs = (e) => {

    let user_fields = this.state.user_fields;
    user_fields[e.target.name] = e.target.value;
    this.setState({
      user_fields: user_fields
    });
    convertAmountToWords(e, e.target.value)


  }

  setPasswordmodel = (props) => {
    return (
      <Modal
        {...props}

      >
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setPasswordModalShow(event, false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <form>
            <div className="form-group">
              <label>Password<sup class="star">*</sup></label>
              <div style={{display:"flex",border: '1px solid #bfbfbf',borderRadius: '5px'}} className="input-container">
              <input onPaste={(e)=>{
                                    e.preventDefault()
                                     return false;
                                    }} onCopy={(e)=>{
                                    e.preventDefault()
                                    return false;
              }} type={this.state.showPassword ? 'text' : 'password'} className="form-control" name="password" value={this.state.user_fields.password} onChange={this.handleuserInputChange} />
              <div className="p-2" onClick={this.toggleShowPassword}>
                {this.state.showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              <div className="errorMsg">{this.state.errors.password}</div>
              </div>

            </div>
            <div className="form-group">
              <label>Confirm Password<sup class="star">*</sup></label>
              <div style={{display:"flex",border: '1px solid #bfbfbf',borderRadius: '5px'}} className="input-container">
              <input onPaste={(e)=>{e.preventDefault()
              return false;
                }} onCopy={(e)=>{
                 e.preventDefault()
                return false;
                }} type={this.state.showPassword ? 'text' : 'password'} className="form-control" name="password_confirmation" value={this.state.user_fields.password_confirmation} onChange={this.handleuserInputChange} />
              <div className="p-1" onClick={this.toggleShowPassword}>
                {this.state.showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
              <div className="errorMsg">{this.state.errors.password_confirmation}</div>
            </div>
            </div>
          </form>

        </Modal.Body>
        <ModalFooter className="justify-content-center">
          {/*<div className="modal-footer text-center">*/}
          <button type="submit" onClick={this.submitpassworduser} className="btn btn-primary">Submit</button>
          {/*</div> */}
        </ModalFooter>
      </Modal>
    )
  }


  ///////////////
  submitdeactivateuser = () => {
    var user_id = this.state.deactivate_userId
    var status = this.state.deactivate_status

    axios({
      url: API_URL + "/user/deactivate",
      method: "post",
      data: { user_id, status },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      console.log(res.data.status)
      if (res.data.status === true) {
        this.setState({ deactivate_userId: '', showDeactivatemodel: false, deactivate_status: '' })

        swal("", res.data.message, res.data.status === true ? "success" : "warning")
        this.callUser(this.state.currentPage)
      }

      // this.setModalShow(true)

    }).catch(err => {

    })


  }
  submitdeleteuser = () => {
    var user_id = this.state.delete_userId
    var disable = this.state.disable
    disable = false
    this.setState({ disable: disable })
    axios({
      url: API_URL + "/user/" + user_id,
      method: "DELETE",
      data: { id: user_id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    }).then(res => {
      if (res.data.status == true) {

        this.setState({ delete_userId: '', showDeletemodel: false, disable: true })
        swal("", res.data.message, res.data.success ? "warning" : "success")
        this.callUser(this.state.currentPage)

      }
      if (res.data.status == false) {
        var disable = this.state.disable
        disable = true
        this.setState({ delete_userId: '', showDeletemodel: false, disable: true })
        swal("", res.data.message, res.data.success ? "success" : "warning")
        this.callUser(this.state.currentPage)
      }

      // this.setModalShow(true)

    }).catch(err => {

    })


  }
  UserDeleteModal = (props) => {
    return (
      <Modal
        {...props}

      >
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">Users</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setDeleteModalShow(event, false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <p>Are you sure you want to delete this user</p>
        </Modal.Body>
        <ModalFooter className="justify-content-end ">
          <div className="d-flex">
            <button type="button" onClick={(event) => this.setDeleteModalShow(event, false)} className="btn btn-secondary me-2">No</button>
            {this.state.disable == true ? <button type="button" onClick={() => this.submitdeleteuser()} className="btn btn-primary">Yes</button> : <button type="button" onClick={() => this.submitdeleteuser()} disabled className="btn btn-primary">Yes</button>}
          </div>
        </ModalFooter>
      </Modal>
    )
  }
  UserDeactivateModal = (props) => {
    return (
      <Modal
        {...props}

      >
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">Users</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setDeactivateModalShow(event, false)}>
          </button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <p>Are you sure you want to {this.state.deactivate_status == 1 ? 'Activate' : 'Deactivate'} this user</p>
        </Modal.Body>
        <ModalFooter className="justify-content-end">
          <div className="d-flex">
            <button type="button" onClick={(event) => this.setDeactivateModalShow(event, false)} className="btn btn-secondary me-2">No</button>
            <button type="button" onClick={() => this.submitdeactivateuser()} className="btn btn-primary">Yes</button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
  MyVerticallyCenteredModal = (props) => {

    return (
      <Modal
        {...props}

      >
        {/*<div className="modal fade" id="edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">*/}
        {/*} <div className="modal-dialog modal-dialog-centered" role="document">
  <div className="modal-content"> */}
        {/*<div className="modal-header">*/}
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">Users and Non users</h5>
          <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={(event) => this.setModalShow(event, false)}>
          </button>
        </Modal.Header>

        {/*}  </div> */}
        <Modal.Body className=" modal-body ">
          {/*  <div className="modal-body"> */}
          <div className="form-group">
            <label>Name<sub className="star">*</sub></label>
            <input type="text" className="form-control" name="name" value={this.state.user_fields.name} onChange={this.handleUserInputs} />
            <div className="errorMsg">{this.state.user_form_errors.name}</div>
          </div>
          <div className="dropdown">
            <label>Role<sub className="star">*</sub></label>

            {this.state.owner_status == this.state.user_id ?
              <div className="select-container">
                <select
                  className="form-control "
                  disabled
                  onClick={this.handleuserInputChange1}
                  name="role_id"
                  value={this.state.user_fields.role_id}
                >
                  <option value="">select role</option>
                  {

                    Object.keys(this.state.role_data).length != 0 ?
                      this.state.role_data.map((obj, index) => {
                        console.log(obj)
                        return this.state.user_fields.role_id == obj.id ?
                          <option selected value={obj.id}>{obj.role_name}</option> :
                          <option value={obj.id}>{obj.role_name}</option>
                      }) : ''
                  }

                </select>
              </div> :
              <div className="select-container">
                <select
                  className="form-control"
                  onChange={this.handleuserInputChange1}
                  name="role_id"
                  value={this.state.user_fields.role_id}
                >
                  <option value="">select role</option>
                  {

                    Object.keys(this.state.role_data).length != 0 ?
                      this.state.role_data.map((obj, index) => {
                        console.log(obj)
                        return this.state.user_fields.role_id == obj.id ?
                          <option selected value={obj.id}>{obj.role_name}</option> :
                          <option value={obj.id}>{obj.role_name}</option>
                      }) : ''
                  }

                </select>
              </div>}
            <div className="errorMsg">{this.state.user_form_errors.role_id}</div>
          </div>
          {console.log(this.state.mandatory)}
          <div className="form-group">
            <label>Phone Number<sub class={this.state.mandatory == true ? "d-none" : "star"}>*</sub></label>
            <input type="number" pattern="\d{3}[\-]\d{3}[\-]\d{4}" onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)} className="form-control hide-arrow  ph_no" name="phone" value={this.state.user_fields.phone} onChange={this.handleuserInputChange} />
            <div className="errorMsg">{this.state.user_form_errors.phone}</div>
          </div>
          {/* <div className="form-group">
  <label>Address</label>
  <input type="text" className="form-control" name="address" value={this.state.user_fields.address} onChange={this.handleuserInputChange} />
  </div> */}
          <div className="form-group">
            <label>Email<sub class={this.state.mandatory == true ? "d-none" : "star"}>*</sub></label>
            <input type="text" className="form-control" name="email" value={this.state.user_fields.email} onChange={this.handleuserInputChange} />
            <div className="errorMsg">{this.state.user_form_errors.email}</div>
          </div>
          {/* <div className="form-group">
  <label>Designation</label>
  <input type="text" className="form-control" name="designation" value={this.state.user_fields.designation} onChange={this.handleuserInputChange} />
  </div> */}

          {console.log(this.state.mandatory)}
          <div className="form-group">
            <label>Username<sub class={this.state.mandatory == true ? "d-none" : "star"}>*</sub></label>
            <input type="text" className="form-control" name="user_name" value={this.state.user_fields.user_name} onChange={this.handleuserInputChange} />
            <div className="errorMsg">{this.state.user_form_errors.user_name}</div>
          </div>
          {
            !this.state.user_fields.id ?
              <div className="form-group">
                <label>Passwords<sub class={this.state.mandatory == true ? "d-none" : "star"}>*</sub></label>
                <div style={{display:"flex",border: '1px solid #bfbfbf',borderRadius: '5px'}} className="input-container">
                
                <input type={this.state.showPassword ? 'text' : 'password'}className="form-control" autoComplete="new-password" name="password" value={this.state.user_fields.password} onChange={this.handleuserInputChange} />
                
                
                <div className="p-1" onClick={this.toggleShowPassword}>
                {this.state.showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                </div>
                </div>
                <div className="errorMsg">{this.state.user_form_errors.password}</div>
              </div> : ''

          }
          <div className="form-group">



            <div class="form-group mb-3">
              <label>Address </label>
              <textarea className="form-control" name="address" value={this.state.user_fields.address} onChange={this.handleuserInputChange}></textarea>
              <div className="errorMsg">{this.state.user_form_errors.address}</div>

              <label>Designation </label>
              <input type="text" className="form-control" name="designation" value={this.state.user_fields.designation} onChange={this.handleuserInputChange} />
              <div className="errorMsg">{this.state.user_form_errors.designation}</div>

              <div class="row">
                {this.state.start_operation!="Yes"?
                <div class="col-md-6 ">
                  <div class="form-group">
                    <label>Opening Balance<sup class="star">*</sup></label>
                    <input type="number" class="form-control hide-arrow amount" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} name="opening_balance" value={this.state.user_fields.opening_balance} onChange={this.handleInputs} readOnly={this.state.user_fields.ob_is_dayclose} />
                    <span id="words" class="amount-words"></span>

                    <div className="errorMsg">{this.state.user_form_errors.opening_balance}</div>
                  </div>
                </div>
                :""}

                <div class="col-md-4 d-flex">
                {this.state.start_operation!="Yes"?
                  <div class="form-check col-sm mt-4 pr-0">

                    <input class="form-check-input" type="radio" id="exampleRadios1" data-tip='Need to pay the party' name="debit_credit" value="Cr" onChange={this.handleuserInputChange} checked={this.state.user_fields.debit_credit === 'Cr'} />
                    <label class="form-check-label col-form-label" for="exampleRadios1">   Credit
                  </label>
                  <ReactTooltip />

                  </div>
                  
                  :""}

                  {this.state.start_operation!="Yes"?
                  <div class="form-check col-sm mt-4 pr-1">
                    <input class="form-check-input" type="radio" id="exampleRadios1" data-tip='Party to pay us' name="debit_credit" value="Dr" onChange={this.handleuserInputChange} checked={this.state.user_fields.debit_credit === 'Dr'} />
                    <label class="form-check-label col-form-label"  for="exampleRadios1">  Debit
                    <div className="errorMsg">{this.state.user_form_errors.debit_credit}</div>


                  </label>
                  <ReactTooltip />
                  </div>
:""}
                </div>
                
              </div>
            </div>



          </div>

          {/* </div> */}
        </Modal.Body>
        <ModalFooter className="justify-content-center">
          {/*<div className="modal-footer text-center">*/}

          {this.state.disable == true ? <button type="button" onClick={this.handleusersubmit} className="btn btn-primary">Save User</button> : <button type="button" onClick={this.handleusersubmit} disabled className="btn btn-primary">Save User</button>}
          {/*</div> */}
        </ModalFooter>
        {/* </div> 
  </div>
</div>*/}
      </Modal>
    )
  }

  render() {
    return (<>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          {this.props.location.pathname == "/users/users" ? <Sidebar_Progress path={this.props.location.pathname} progress={6} /> : <Sidebar_Progress progress={6} />}

          <div className="content-area ">
            <LoginHeader page_title='Registration setup' />
            <div className="container pb-3 mt-3">

              <div class="row">

                <div className="col-md-12  px-4 ">
                  {this.props.location.pathname == "/users/users" ? <div class="col-md-12 mb-3"><Link to={this.state.backPage || "/operational-date"}><a href="#" class="float-end btn btn-gray">Go Back</a></Link></div> : <div class="col-md-12 mb-3"><Link to="/rack-product-list"><a href="/rack-product-list" class="float-end btn btn-gray">Back</a></Link></div>}
                </div>
              </div>

              <div className="row justify-content-center  mt-3 form-container">
                <div className="col-md-12">

                  {this.state.status == 3 ?
                    <div className="form-wrap">
                      <div className="text-center">
                        {this.state.message}
                      </div></div> :
                    <>
                      <div>
                        {this.state.loading ? this.CallUser :
                          // <div class="loader text-primary">Loading...</div>
                          <Loader />
                        }
                        <div className="form-wrap list-wrp">
                          <div className="row mb-3">
                            <div className="col-md">
                              <div className="row">
                                <div className="col-md-1 col-2 pe-0">
                                  <img src={customer_review} width="25px" className="img-fluid" alt=".." />
                                </div>
                                <div className="col pl-0">
                                  <h3 className="table-title mt-2">Users and Non users</h3>
                                </div>
                                <div className="col-md">
                                  <a onClick={() => this.addUser()} className="btn btn-primary px-3 mb-md-0 mb-2 ">Add USER AND NON USER</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="input-group mb-3 search">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search" name="search_keyword" aria-describedby="basic-addon1" value={this.state.search_keyword} onChange={this.handleSearch}  />
                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={this.check_url} >Submit</a>

                              </div>
                            </div>
                          </div>


                          <div className="table-widget">
                            <table className="table border">

                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Phone Number</th>
                                  <th>Email Address</th>
                                  <th>Username</th>
                                  <th>Configured Opening Balance</th>
                                  <th>Credit/Debit</th>
                                  <th>Users Role</th>
                                  <th >Status</th>
                                  <th >Actions</th>
                                </tr>
                              </thead>
                              {this.state.user_result.map((user, index) => {
                                let userStatus = user.status == 1 ? 2 : 1;
                                // console.log(user.role.role_name)
                                return (


                                  <tbody>

                                    <tr>
                                      {/* <td>{this.state.start_operation}</td>  */}
                                      <td>{user.name} {user.isowner == 1 ? " (Owner)" : ''}</td>
                                      <td>{user.phone}</td>
                                      <td>{user.email}</td>
                                      <td>{user.user_name}</td>
                                      <td className="text-end">{user.opening_balance}</td>
                                      <td >{user.debit_credit == "Cr" ? "Credit" : user.debit_credit == "Dr" ? "Debit" : ''}</td>
                                      <td><a className="btn btn-success" href="#"> {user.role ? user.role.role_name : "Deafult Owner"} </a></td>
                                      <td>{user.status == 1 ? "Active" : "Inactive"}</td>
                                      <td><div className="d-flex icon-btn">
                                        {/* <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={() => this.edit_user(user.id, user.ac_name, user.ac_name1)} title="Edit">	<i className="fa fa-pencil" aria-hidden="true"></i>
                                        </button> */}

{localStorage.getItem("ROLE_ID") == "Owner" ?
                                          <button data-bs-toggle="tooltip" data-bs-placement="bottom"
                                            onClick={() => this.edit_user(user.id, user.ac_name, user.ac_name1)} title="Edit">
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                          </button>
                                          :
                                          user?.isowner == 1 ?
                                            null :
                                            <button data-bs-toggle="tooltip" data-bs-placement="bottom"
                                            onClick={() => this.edit_user(user.id, user.ac_name, user.ac_name1)} title="Edit">
                                             <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </button>
                                        }
{/*                                         
                                        {user.user_name ? <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={() => this.edit_password(user.id)} title="Change Password" >	<i className="fa fa-key" aria-hidden="true"></i>
                                        </button> : ""} */}
{localStorage.getItem("ROLE_ID") == "Owner" ?
                                          <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={() => this.edit_password(user.id)} title="Change Password" >	<i className="fa fa-key" aria-hidden="true"></i>
                                          </button>
                                          :
                                          user?.isowner == 1 ?
                                            null :
                                            <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={() => this.edit_password(user.id)} title="Change Password" >	<i className="fa fa-key" aria-hidden="true"></i>
                                        </button>
                                        }

                                        {
                                          user.role.role_slug != "owner" ? <button data-bs-toggle="tooltip" data-bs-placement="bottom" onClick={() => this.delete_user(user.id)} title="Delete" >	<i className="fa fa-trash" aria-hidden="true"></i>

                                          </button> : ''
                                        }

                                        {user.role.role_slug != "owner" ? <div className="dropdown">
                                          <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                          </button>
                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">{

                                          }
                                            <li><a className="dropdown-item" onClick={() => this.change_status(user.id, userStatus)} href="#"><i className="fa fa-user me-2" aria-hidden="true"></i> {(user.status == 1) ? 'Deactivate' : 'Activate'} user</a></li>


                                          </ul>
                                        </div>

                                          : ''}
                                      </div></td>
                                    </tr>

                                  </tbody>
                                )
                              })}
                            </table>
                            <div className="pagination mt-4 justify-content-end">
                              {this.state.loading ? this.state.total_pages > 10 && <Pagination
                                activePage={this.state.currentPage}
                                itemClass="123"
                                itemsCountPerPage={this.state.per_page}
                                totalItemsCount={this.state.total_pages || 1}
                                pageRangeDisplayed={10}
                                onChange={this.handlePagination}
                              /> : ""
                              }
                            </div>
                            {/* <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-end">
                          {this.state.total_pages > 1 && this.state.pagination_items}
                        </ul>
                      </nav> */}
                          </div>

                        </div>
                        <div className="text-end mt-4">
                          {this.props.location.pathname == "/users/users" ? "" : <a className="btn btn-primary px-3 " onClick={(event) => this.props.history.push("/customers")}>Skip/Continue</a>}</div>
                      </div>
                    </>}

                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
      <this.UserDeactivateModal
        show={this.state.showDeactivatemodel}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setDeleteModalShow()}
        data={this.state}
      />
      <this.UserDeleteModal
        show={this.state.showDeletemodel}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setDeleteModalShow()}
        data={this.state}
      />
      <this.MyVerticallyCenteredModal
        show={this.state.modalShow}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setModalShow()}
        data={this.state}
      />
      <this.setPasswordmodel
        show={this.state.showPasswordmodel}
        selectedvalue={this.state.selectedvalue}
        selectedid={this.state.selectedid}
        onHide={(event) => this.setPasswordModalShow()}
        data={this.state}
      />

    </>
    )
  }
}

export default UsersList