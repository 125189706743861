import { Component } from "react";
import customer_review from "../../img/customer-review.png";
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from "axios";
import { API_URL } from "../../constant/API_Settings";
import { packing_unit } from "../../constant/constant";
import { selling_mode } from "../../constant/constant";
import swal from "sweetalert";
import { Modal, ModalFooter } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../operational-model/Employee/Loader.component";
import {preventNonNumericalInput,preventNonNumericalInputNON ,prevNonNumericalWithDec,convertAmountToWords } from "../layouts/Sidebar_Progress.component";
// import {preventNonNumericalInput} from '../layouts/Sidebar_Progress.component'

import Pagination from "react-js-pagination";
import { message } from "antd";
import { event } from "jquery";

class Product extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.backpage);
    this.state = {
      fields: {},
      edit_status: 0,
      start_operation: "No",
      modalShow: false,
      data: [],
      errors: [],
      loading: false,
      status: 0,
      message: "",
      backPage: this.props.location.backpage,

      lastPage: 0,
      totalDatas: 1,
      total_pages: 0,
      stock_value: "",
      product_full_name: "",
      search_keyword: "",
      hsn:"",
      delete_product_id: "",
      current_page: 1,
      gst: 0,
      cgst: 0,
      sgst: 0,

      return_gst: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.check_url();

    var fields = {};
    fields["product_unit"] = "";

    this.setState({
      fields: fields,
    });
  }
  edit_product = (id) => {
    axios({
      url: API_URL + "/edit-non-fuel-config",
      method: "POST",
      data: { id: id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((res) => {
        var fields = this.state.fields;
        fields["id"] = res.data.id ? res.data.id : "";
        fields["product_name"] = res.data.product_name;
        fields["unit"] = res.data.unit;
        fields["quantity"] = res.data.quantity;
        fields["product_full_name"] = res.data.product_full_name;
        fields["selling_mode"] = res.data.selling_mode;
        fields["total_stock"] = res.data.total_stock;
        fields["pack_confg"] = res.data.pack_confg;
        fields["purchase_price"] = res.data.purchase_price;
        fields["purchase_price_exc_gst"] = res.data.purchase_price_exc_gst;
        fields["purchase_price_gst"] = res.data.purchase_price_gst;
        fields["selling_price"] = res.data.selling_price;
        fields["selling_price_loose"] = res.data.selling_price_loose;
        fields["margin_price"] = res.data.margin_price;
        fields["margin_price_loose"] = res.data.margin_price_loose;
        fields["stock_value"] = res.data.stock_value;
        fields["purchase_quantity"] = res.data.purchase_quantity;
        fields["return_gst"] = res.data.return_gst;
        fields["gst"] = res.data.gst;
        fields["sgst"] = res.data.sgst;
        fields["cgst"] = res.data.cgst;
        fields["hsn"] = res.data.hsn_code;

        this.setState({ fields: fields, modalShow: true });

        this.setState({ edit_status: res.data.blk_prdt_edt });
        this.setState({ start_operation: res.data.start_operation });
        console.log(this.state.edit_status, "vd");
      })
      .catch((err) => {});
  };
  check_url = () => {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pathurl: "/fuel-config-report",
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then((response) => {
        return response.json();
      })

      .then((responseData) => {
        console.log(responseData);
        if (responseData.allowurl == 1) {
          if (this.props.location.pathname == "/products/products") {
            this.props.history.push(this.props.location.pathname);
          } else {
            this.props.history.push('/products');
          }

          this.callProductList(1);
        } else {
          this.props.history.goBack();
        }

        return responseData;
      })
      .catch((e) => {});
  };

  // prevNonNumericalWithDec = (e,num) => {
  //   var parent = e.currentTarget.parentNode
	//  var selector = parent.querySelector('.ph_no')
	//  if (typeof selector !== 'undefined' && selector)
	//    {
	// 	 e = e || window.event;
	// 	 var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
	// 	 var charStr = String.fromCharCode(charCode);
	// 	 var fullChar = num ? num?.toString() + charStr : charStr;
	// 	 if (!fullChar.match(/^[0-9]+$/))
	// 	 e.preventDefault();
	//    }
	//    else
	//    {
	// 	 e = e || window.event;
	// 	 var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
	// 	 var charStr = String.fromCharCode(charCode);
	// 	 var fullChar = num ? num?.toString() + charStr : charStr;
	// 	 if (!fullChar.match(/^[0-9]+$/))
	// 	 e.preventDefault();
 
	// 	 var myArr =fullChar.toString().split('.');
	// 	 var num0 = myArr[0];
	// 	 if ((num0 = num0.toString()).length > 8)
	// 	 e.preventDefault();
	//    }
  // };
  // preventNonNumericalInput = (e, num) => {
  //   e = e || window.event;
  //   var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  //   var charStr = String.fromCharCode(charCode);
  //   var fullChar = num ? num?.toString() + charStr : charStr;
  //   if (!fullChar.match(/^[0-9]*(\.{1})?([0-91-9][0-9])?$/))
  //   e.preventDefault();
  
  //   var myArr =fullChar.toString().split('.');
  //   var num0 = myArr[0];
  //   if ((num0 = num0.toString()).length > 8)
  //   e.preventDefault();
  // };
  setDeleteModalShow(event, show) {
    this.setState({
      showDeletemodel: show,
    });
  }
  delete_product = (id) => {
    this.setState({ delete_product_id: id, showDeletemodel: true });
  };

  validateForm() {
    var lng = this.state.lng;

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    // if (this.state.start_operation == "Yes") {
    
    
    if (1==1) {
    //   if (!fields["product_name"]) {
    //     formIsValid = false;
    //     errors["product_name"] = "Enter Product Name";
    //   } else {
    //     errors["product_name"] = "";
    //   }
    //   if (fields["unit"] == 0) {
    //     formIsValid = false;
    //     errors["unit"] = "Enter Unit";
    //   } else {
    //     errors["unit"] = "";
    //   }
    //   if (fields["selling_mode"] == 0) {
    //     formIsValid = false;
    //     errors["selling_mode"] = "Enter Selling Mode";
    //   } else {
    //     errors["selling_mode"] = "";
    //   }
    // } else {
      if (this.state.start_operation != "Yes") {
      if (!fields["total_stock"]) {
        formIsValid = false;
        errors["total_stock"] = "Enter Total stock";
      } else {
        errors["total_stock"] = "";
      }
    }


        if (!fields["product_name"]) {
          formIsValid = false;
          errors["product_name"] = "Enter Product Name";
        } else {
          errors["product_name"] = "";
        }


      if (!fields["gst"]) {
        formIsValid = false;
        errors["gst"] = "Enter gst%";
      } else {
        errors["gst"] = "";
      }

      if (!fields["sgst"]) {
       // formIsValid = false;
       // errors["sgst"] = "Enter sgst%";
      } else {
        errors["sgst"] = "";
      }

      if (!fields["cgst"]) {
       // formIsValid = false;
      //  errors["cgst"] = "Enter cgst%";
      } else {
        errors["cgst"] = "";
      }

      if(!fields["selling_price"]
      ) {
        formIsValid = false;
        errors["selling_price"] = "Enter Selling Price";
      }else {
        errors["selling_price"] = "";
      }


      if (fields["unit"] == 0) {
        formIsValid = false;
        errors["unit"] = "Enter Unit";
      } else {
        errors["unit"] = "";
      }
      if ((fields["unit"] == 1 || fields["unit"] == 2) && !fields["quantity"]) {
        formIsValid = false;
        errors["quantity"] = "Enter Quantity";
      } else if (fields["quantity"] < 0) {
        formIsValid = false;
        errors["quantity"] = " Quantity Should Be Greater Than Zero";
      } else {
        errors["quantity"] = "";
      }
      // if (!fields["product_full_name"]) {
      //   formIsValid = false;
      //   errors["product_full_name"] = "Enter Product Fullname"
      // }

      // else{
      //   errors["product_full_name"]=""
      // }
      if (fields["selling_mode"] == 0) {
        // formIsValid = false;
        // errors["selling_mode"] = "Enter Selling Mode";
      } else {
        errors["selling_mode"] = "";
      }
      // if (!fields["total_stock"]) {
      //   formIsValid = false;
      //   errors["total_stock"] = "Enter Total Stock";
      // } else if (fields["total_stock"] < 0) {
      //   formIsValid = false;
      //   errors["total_stock"] = " Total Stock Should Be Greater Than Zero";
      // } else {
      //   errors["total_stock"] = "";
      // }
      if (fields["selling_mode"] == 3 && !fields["pack_confg"]) {
        formIsValid = false;
        errors["pack_confg"] = "Enter Pack Configuration";
      } else {
        errors["pack_config"] = "";
      }
      if (!fields["purchase_price"]) {
        formIsValid = false;
        errors["purchase_price"] = "Enter Purchase Price";
      } else {
        errors["purchase_price"] = "";
      }
      if (
        (fields["selling_mode"] == 3 || fields["selling_mode"] == 1) &&
        !fields["selling_price"]
      ) {
        formIsValid = false;
        errors["selling_price"] = "Enter Selling Price";
      } else {
        errors["selling_price"] = "";
      }
      if (
        (fields["selling_mode"] == 3 || fields["selling_mode"] == 2) &&
        !fields["selling_price_loose"]
      ) {
        formIsValid = false;
        errors["selling_price_loose"] = "Enter Loose Selling Price";
      } else {
        errors["selling_price_loose"] = "";
      }

      if (
        (fields["selling_mode"] == 3 || fields["selling_mode"] == 2) &&
        !fields["purchase_quantity"]
      ) {
        formIsValid = false;
        errors["purchase_quantity"] =
          "Enter purchase price of packing quantity";
      } else {
        errors["purchase_quantity"] = "";
      }
    }

    console.log(formIsValid,"errors")
    console.log(errors,"errors1")
    
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handleSelect = (e) => {
    let fields = this.state.fields;

    const qtys = this.state.fields.quantity ? this.state.fields.quantity : 0.00;
    let full_num = this.state.fields.quantity;
    if(qtys > 0){
      const [integerPart, decimalPart] = this.state.fields.quantity.split('.');
      console.log(integerPart,"integerPart");
      console.log(decimalPart,"decimalPart");
  
  
      if(decimalPart != undefined){
         if(decimalPart > 0){
          full_num = this.state.fields.quantity;
         }else{
          full_num = integerPart;
         }
      }
    }else{
      full_num = "";
    }




    console.log(full_num,"full_numfull_num")

    if (e.target.value == "1" || e.target.value == "2") {
      this.state.fields.stock_value = "";
    }
    if (e.target.name === "unit" && e.target.value === "3") {
      this.state.fields.quantity = "";
      this.state.fields.product_full_name = "";
      this.state.fields.pack_confg = "";
      this.state.fields.stock_value = "";
      this.state.fields.total_stock = "";
      this.state.fields.purchase_price = "";
      this.state.fields.purchase_price_exc_gst = "";
      this.state.fields.selling_price = "";
      this.state.fields.margin_price = "";
    }
    if (e.target.name === "unit" && e.target.value === "3") {
      var data = this.state.fields;
      data["selling_mode"] = 1;
    } else {
      var data = this.state.fields;
      //data["selling_mode"] = "";
      data["selling_mode"] = 1;
    }
    this.setState({ fields: data });
    fields[e.target.name] = e.target.value;
    if (e.target.value != 0) {
      
      var product_unit = e.target.options[e.target.options.selectedIndex].text;

      var units = product_unit;
      console.log(units);
      switch (units) {
        case "Kilogram":
          units = "Kg";
          break;
        case "Litre":
          units = "Lt";
          break;
      }

      console.log(product_unit,"product_unitproduct_unit"); 

      var product =
        this.state.fields.product_name +
        full_num +
        units 
        // +
        // this.state.fields.quantity
        // full_num
          ? this.state.fields.product_name +
            " " +
            full_num +
            " " +
            units
            // product_unit +
            // " " +
            // full_num
            // this.state.fields.quantity
          : 0;
      fields["product_full_name"] = product;
    } else {
      fields["product_full_name"] = "";
    }
    if (
      e.target.name == "product_full_name" ||
      e.target.name == "product_name" ||
      e.target.name == "unit" ||
      e.target.name == "quantity"
    ) {
      if (
        e.target.name == "product_name" ||
        e.target.name == "unit" ||
        e.target.name == "quantity"
      ) {
        fields[e.target.name] = e.target.value;
        if (!!this.state.fields.unit) {
          var unit = product_unit;
          console.log(unit);
          switch (unit) {
            case "Kilogram":
              unit = "Kg";
              break;
            case "Litre":
              unit = "Lt";
              break;
          }
          var product =
            this.state.fields.product_name +
            // this.state.fields.quantity + 
            full_num +
            this.state.fields.unit
              ? this.state.fields.product_name +
                " " +
                // this.state.fields.quantity +
                full_num +
                " " +
                unit
              : 0;
          fields["product_full-_name"] = product;
        }
      }
    }
    this.setState({
      fields: fields,
    });
  };
  handleSearch = (event) => {
    var search_keyword = this.state;
    this.setState({
      search_keyword: event.target.value,
    });
  
  };
  handleInput1Change = (event) => {
    let fields = this.state.fields;
    // console.log(event.target.value)
    // if(event.target.name=="margin_price" || event.target.name=="selling_price" || event.target.name=="purchase_price" ||!event.target.name=="selling_price_loose" ||!event.target.name=="purchase_quantity")
    // {
    //   if(event.target.name=="selling_price" || event.target.name=="purchase_price" ||!event.target.name=="selling_price_loose"  ||!event.target.name=="purchase_quantity")
    //   {
    //     fields[event.target.name] = event.target.value;
    //     if(!!this.state.fields.selling_price)
    //     {
    //       var stock = this.state.fields.selling_price-this.state.fields.purchase_price?this.state.fields.selling_price-this.state.fields.purchase_price:0
    //       fields["margin_price"] = stock
    //     }
    //      else{
    //       fields["margin_price"]=""
    //      }

    //   }

    // }
    // else
    // {
    fields[event.target.name] = event.target.value;

    this.setState({
      fields: fields,
    });
  };
  // }

  handleChange = (e) => {
    let errors = [];

    // if(e.target.value ===)
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.state.fields.total_stock = "";
    this.state.fields.pack_confg = "";
    this.state.fields.selling_price = "";
    this.state.fields.purchase_price = "";
    this.state.fields.purchase_price_exc_gst = "";
    this.state.fields.purchase_quantity = "";
    this.state.fields.selling_price_loose = "";
    this.state.fields.margin_price = "";
    this.state.fields.margin_price_loose = "";
    this.state.fields.stock_value = "";

    this.setState({
      fields: fields,
      errors: errors,
    });
  };

  handlePagination = (number) => {
    this.state.current_page = number;
    this.callProductList(number);
  };

  callProductList = (page) => {
    axios({
      url: API_URL + "/get-non-fuel-data?page=" + page,
      method: "post",
      data: {
        search_keyword:this.state.search_keyword,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((response) => {
        
        if (response.data.status === 3) {
          this.setState({
            status: response.data.status,
            message: response.data.message,
          });
        }
        let nonFuel = response.data.data.data.filter(each => each.is_loose_oil != "1")
        console.log(nonFuel);
        
        this.setState({
          data: nonFuel,
          current_page: response.data.data.current_page,
          lastPage: response.data.data.last_page,
          total_pages: response.data.data.total,
          per_page: response.data.data.per_page,
          totalDatas: response.data.data.total,
          start_operation: response.data.start_operation,
        });

        this.setState({
          loading: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleInputChange = (event) => {
    let fields = this.state.fields;
    console.log(fields);
if(this.state.fields.selling_mode==1||this.state.fields.selling_mode==2||this.state.fields.selling_mode==3){
  console.log("okkkkk done");
    fields[event.target.name] = event.target.value;
  console.log(this.state.fields.selling_price,"sp");
  console.log(this.state.fields.purchase_price,"pp");  
  console.log(this.state.fields.cgst,"cgst");
  console.log(this.state.fields.sgst,"sgst");
  if(!gst){
  
    fields["cgst"] = 0; 
      fields["sgst"] = 0;
      fields["purchase_price_gst"] = 0;
      fields["margin_price"] = 0;
      fields["stock_value"] = 0;
      

  }
  if( this.state.fields.purchase_price){
   
    var gst = parseFloat(this.state.fields.gst);
    var hsn=parseFloat(this.state.fields.hsn);
    var cgst = parseFloat(this.state.fields.cgst);
    var sgst = parseFloat(this.state.fields.sgst);
    var pp   = parseFloat(this.state.fields.purchase_price);
    var sp   = parseFloat(this.state.fields.selling_price);
    var total_stock = parseFloat(this.state.fields.total_stock);
    if(!sp){
      sp = 0;
    }

    //var total_gst = cgst + sgst;
    var total_gst = gst;
    // console.log(total_gst,"tot")
     
    if(total_gst >= 0 && pp){
      console.log(pp,"pp")
      var gst_percentage = pp * (total_gst/(100+total_gst))

      fields["purchase_price_gst"] = gst_percentage.toFixed(2);

      console.log(gst_percentage,"gst_percentage")

      var pp_exc_gst = pp - gst_percentage;

      if(pp_exc_gst && total_stock){

        console.log(pp_exc_gst * total_stock,"pppppppppppppppppppp")
        fields["stock_value"] = (pp_exc_gst.toFixed(2) * total_stock).toFixed(2);  
      }else{
        fields["stock_value"] = 0;
      }

      fields["purchase_price_exc_gst"] = pp_exc_gst.toFixed(2); 
      
      console.log(pp_exc_gst,"purchase_price_exc_gst")

      fields["cgst"] = (gst_percentage/2).toFixed(2); 
      fields["sgst"] = (gst_percentage/2).toFixed(2);

    }else{
      fields["purchase_price_exc_gst"] = 0; 
      fields["cgst"] = 0; 
      fields["sgst"] = 0;
    }
    var ans = 100/(100+total_gst);

    var ans1 = sp*ans;
        ans1 = ans1.toFixed(2);

  
    var ans2 = pp * (100/(100+total_gst));
        ans2 = ans2.toFixed(2);


    var ans3 = ans1 - ans2;
     
       var returngst = ans3 * ((total_gst)/100);

    fields["return_gst"] = returngst.toFixed(2); 

    if(returngst >= 0){
      // var returngsts = returngst.toFixed(2);
      console.log(returngst,"rgst");
      var marginpp = pp + returngst;
      console.log(sp,"sppppppp");
      console.log(marginpp,"marginpp");

      var marginperpiece = sp - marginpp;
      console.log(marginperpiece,"rgst");
      fields["margin_price"] = marginperpiece.toFixed(2);
     }

  }else{
    fields["return_gst"] = 0;
  }
}

    if (this.state.fields.unit == "2" || this.state.fields.unit == "3") {
      if (
        event.target.name == "margin_price" ||
        event.target.name == "selling_price" ||
        event.target.name == "purchase_price" ||
        !event.target.name == "selling_price_loose"
      ) {
        if (
          event.target.name == "selling_price" ||
          event.target.name == "purchase_price" ||
          !event.target.name == "selling_price_loose"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price) {
            console.log("crct");
            var margin =
              this.state.fields.selling_price - this.state.fields.purchase_price
                ? this.state.fields.selling_price -
                  this.state.fields.purchase_price
                : 0;
            console.log(margin);

            //  if(returngst){
            //   var returngst = returngst.toFixed(2);
            //   var marginperpiece = sp - (pp + returngst);
            //   fields["margin_price"] = marginperpiece.toFixed(2);
            //  }
             


            // fields["margin_price"] = margin.toFixed(2);

           // fields["margin_price"] = 77777;
          }
          //  else{
          //    console.log("wrong")
          //   fields["margin_price"]=""
          //  }
        }
      }
    }
    if (
      this.state.fields.unit == "2" &&
      this.state.fields.selling_mode == "3"
    ) {
      if (
        event.target.name == "margin_price_loose" ||
        event.target.name == "selling_price_loose" ||
        event.target.name == "purchase_price" ||
        !event.target.name == "selling_price"
      ) {
        if (
          event.target.name == "selling_price_loose" ||
          event.target.name == "purchase_price" ||
          !event.target.name == "selling_price"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price_loose) {
            console.log("crct");
            var margin =
              this.state.fields.selling_price_loose -
              this.state.fields.purchase_price
                ? this.state.fields.selling_price_loose -
                  this.state.fields.purchase_price
                : 0;
            console.log(margin);
            fields["margin_price_loose"] = margin.toFixed(2);
          }
          //  else{
          //    console.log("wrong")
          //   fields["margin_price"]=""
          //  }
        }
      }
    }
    if (
      this.state.fields.unit == "1" &&
      this.state.fields.selling_mode == "3"
    ) {
      if (
        event.target.name == "margin_price_loose" ||
        event.target.name == "selling_price_loose" ||
        event.target.name == "purchase_price" ||
        !event.target.name == "selling_price"
      ) {
        if (
          event.target.name == "selling_price_loose" ||
          event.target.name == "purchase_price" ||
          !event.target.name == "selling_price"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price_loose) {
            console.log("crct");
            var margin =
              this.state.fields.selling_price_loose -
              this.state.fields.purchase_price
                ? this.state.fields.selling_price_loose -
                  this.state.fields.purchase_price
                : 0;
            console.log(margin);
            fields["margin_price_loose"] = margin.toFixed(2);
          }
          //  else{
          //    console.log("wrong")
          //   fields["margin_price"]=""
          //  }
        }
      }
    }
    if (
      this.state.fields.unit == "2" &&
      this.state.fields.selling_mode == "2"
    ) {
      if (
        event.target.name == "margin_price" ||
        event.target.name == "selling_price_loose" ||
        event.target.name == "purchase_price" ||
        !event.target.name == "selling_price"
      ) {
        if (
          event.target.name == "selling_price_loose" ||
          event.target.name == "purchase_price" ||
          !event.target.name == "selling_price"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price_loose) {
            console.log("crct");
            var margin =
              this.state.fields.selling_price_loose -
              this.state.fields.purchase_price
                ? this.state.fields.selling_price_loose -
                  this.state.fields.purchase_price
                : 0;
            console.log(margin);
            // fields["margin_price"] = margin.toFixed(2);
          }
          //  else{
          //    console.log("wrong")
          //   fields["margin_price"]=""
          //  }
        }
      }
    }
    if (this.state.fields.unit == "1" && this.state.fields.selling_mode == 1) {
      if (
        event.target.name == "margin_price" ||
        event.target.name == "selling_price" ||
        event.target.name == "purchase_price" ||
        !event.target.name == "selling_price_loose"
      ) {
        if (
          event.target.name == "selling_price" ||
          event.target.name == "purchase_price" ||
          !event.target.name == "selling_price_loose"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price) {
            console.log("crct");
            var loose =
              this.state.fields.selling_price - this.state.fields.purchase_price
                ? this.state.fields.selling_price -
                  this.state.fields.purchase_price
                : 0;
            console.log(margin);
            // fields["margin_price"] = loose.toFixed(2);
          }
          //  else{
          //    console.log("wrong")
          //   fields["margin_price"]=""
          //  }
        }
      }
    }
    if (this.state.fields.unit == "1" && this.state.fields.selling_mode == 2) {
      if (
        event.target.name == "margin_price_loose" ||
        event.target.name == "selling_price_loose" ||
        event.target.name == "purchase_price" ||
        event.target.name == "purchase_quantity"
      ) {
        if (
          event.target.name == "selling_price_loose" ||
          event.target.name == "purchase_price" ||
          event.target.name == "purchase_quantity"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price_loose) {
            console.log("crct");
            var loose =
              this.state.fields.selling_price_loose -
              this.state.fields.purchase_price
                ? this.state.fields.selling_price_loose -
                  this.state.fields.purchase_price
                : 0;
            //  console.log(loose.toFixed(2))
            //  numb = loose.toFixed(2);
            fields["margin_price_loose"] = loose.toFixed(2);
          }
          //  else{
          //    console.log("wrong")
          //   fields["margin_price"]=""
          //  }
        }
      }
    }
    if (this.state.fields.selling_mode == "3") {
      if (
        event.target.name == "margin_price" ||
        event.target.name == "selling_price" ||
        event.target.name == "purchase_quantity" ||
        !event.target.name == "selling_price_loose" ||
        event.target.name == "purchase_price"
      ) {
        if (
          event.target.name == "selling_price" ||
          event.target.name == "purchase_quantity" ||
          !event.target.name == "selling_price_loose" ||
          !event.target.name == "purchase_price"
        ) {
          fields[event.target.name] = event.target.value;
          if (!!this.state.fields.selling_price) {
            var price =
              this.state.fields.selling_price -
              this.state.fields.purchase_quantity
                ? this.state.fields.selling_price -
                  this.state.fields.purchase_quantity
                : 0;
            // fields["margin_price"] = price.toFixed(2);
          } else {
            fields["margin_price"] = "";
          }
        }
      }
    }

    //   if(this.state.fields.product_unit=="Litre")
    //   {
    //   if(event.target.name=="margin_price_loose" || event.target.name=="selling_price_loose" || event.target.name=="purchase_price")
    //   {
    //     if(event.target.name=="selling_price_loose" || event.target.name=="purchase_price"  )
    //     {
    //       fields[event.target.name] = event.target.value;
    //       if(!!this.state.fields.selling_price_loose)
    //       {
    //       var loose = this.state.fields.selling_price_loose-this.state.fields.purchase_price?this.state.fields.selling_price_loose-this.state.fields.purchase_price:0
    //       fields["margin_price_loose"] = loose
    //       }

    //     }
    //   }
    // }
    if (
      event.target.name == "purchase_quantity" ||
      event.target.name == "quantity" ||
      event.target.name == "purchase_price"
    ) {
      if (
        event.target.name == "quantity" ||
        event.target.name == "purchase_price"
      ) {
        fields[event.target.name] = event.target.value;

        var quantity =
          this.state.fields.quantity * this.state.fields.purchase_price
            ? this.state.fields.quantity * this.state.fields.purchase_price
            : null;
        fields["purchase_quantity"] = parseFloat(quantity) ? parseFloat(quantity).toFixed(2):quantity;
      } else {
        fields["purchase_quantity"] = "";
      }
    }
    if (
      event.target.name == "purchase_price" ||
      event.target.name == "quantity" ||
      event.target.name == "purchase_quantity"
    ) {
      if (
        event.target.name == "quantity" ||
        event.target.name == "purchase_quantity"
      ) {
        fields[event.target.name] = event.target.value;

        var opt =
          this.state.fields.purchase_quantity / this.state.fields.quantity
            ? this.state.fields.purchase_quantity / this.state.fields.quantity
            : null;
        console.log(opt);
        var dec =  parseFloat(opt) ? parseFloat(opt).toFixed(2):opt;

        fields["purchase_price"] = dec;
      } else {
        fields["purchase_price"] = "";
      }
    }
    if (
      event.target.name == "pack_confg" ||
      event.target.name == "quantity" ||
      event.target.name == "total_stock"
    ) {
      if (
        event.target.name == "total_stock" ||
        event.target.name == "quantity"
      ) {
        fields[event.target.name] = event.target.value;
        var opt = this.state.fields.total_stock / this.state.fields.quantity;
        var str = opt.toString();
        if (str.includes(".")) {
          var [whole, decimal] = String(str).split(".");
          // var dec = Math.ceil(parseFloat('.'+decimal))
          var obj = whole + " " + "packet full and 1 packet open";
          fields["pack_confg"] = obj;
        } else {
          var obj = opt + " " + "packet full ";
          fields["pack_confg"] = obj;
        }
      }
    }
    if (
      event.target.name == "stock_value" ||
      event.target.name == "total_stock" ||
      event.target.name == "purchase_price" ||
      event.target.name == "purchase_quantity"
    ) {
      if (
        event.target.name == "total_stock" ||
        event.target.name == "purchase_price" ||
        event.target.name == "purchase_quantity"
      ) {
        fields[event.target.name] = event.target.value;
        console.log(this.state.fields.purchase_price);
        var stock_amount =
          this.state.fields.total_stock * this.state.fields.purchase_price
            ? this.state.fields.total_stock * this.state.fields.purchase_price
            : 0;
        // fields["stock_value"] = stock_amount.toFixed(2);
      } else {
        fields["stock_value"] = "";
      }
    }

    if (
      event.target.name == "product_full_name" ||
      event.target.name == "product_name" ||
      event.target.name == "unit" ||
      event.target.name == "quantity"
    ) {
      if (
        event.target.name == "product_name" ||
        event.target.name == "unit" ||
        event.target.name == "quantity"
      ) {
        fields[event.target.name] = event.target.value;
        if (this.state.fields.unit) {
          var product_unit = this.state.fields.unit;
          if (product_unit == 2) {
            var unit = "Kg";
          }
          if (product_unit == 1) {
            unit = "Lt";
          }
          console.log(unit);
          switch (unit) {
            case "Kilogram":
              unit = "Kg";
              break;
            case "Litre":
              unit = "Lt";
              break;
          }

          const qty = this.state.fields.quantity ? this.state.fields.quantity : 0.00;
          let full_num = this.state.fields.quantity;
          if(qty > 0){
            const [integerPart, decimalPart] = this.state?.fields?.quantity.split('.');
            console.log(integerPart,"integerPart");
            console.log(decimalPart,"decimalPart");
        
            
            if(decimalPart != undefined){
               if(decimalPart > 0){
                full_num = this.state.fields.quantity;
               }else{
                full_num = integerPart;
               }
            }
          }else{
            full_num = "";
          }



          var product =
            this.state.fields.product_name + 
            // this.state.fields.quantity +
            full_num +
             unit
              ? this.state.fields.product_name +
                " " +
                // this.state.fields.quantity +
                full_num +
                " " +
                unit
              : 0;
          fields["product_full_name"] = product;
        }
      }
    } else fields[event.target.name] = event.target.value;

    this.setState({
      fields: fields,
    });
  };
  setModalShow(event, show) {
    let errors = [];
    this.state.fields.product_name = "";
    this.state.fields.id = "";
    this.state.fields.unit = "";
    this.state.fields.quantity = "";
    this.state.fields.product_full_name = "";
    this.state.fields.selling_mode = "";
    this.state.fields.total_stock = "";
    this.state.fields.pack_confg = "";
    this.state.fields.selling_price = "";
    this.state.fields.purchase_price = "";
    this.state.fields.purchase_price_exc_gst = "";
    this.state.fields.purchase_price_gst ="";
    this.state.fields.purchase_quantity = "";
    this.state.fields.selling_price_loose = "";
    this.state.fields.margin_price = "";
    this.state.fields.margin_price_loose = "";
    this.state.fields.stock_value = "";
    this.state.fields.gst = "";
    this.state.fields.hsn="";
    this.state.fields.cgst = "";
    this.state.fields.sgst = "";
    this.state.fields.return_gst = ""; 
    this.setState({
      modalShow: show,
      errors: errors,
    });
  }

  submitdeleteproduct = () => {
    var product_id = this.state.delete_product_id;
    axios({
      url: API_URL + "/fuel-config/" + product_id,
      method: "DELETE",
      data: { id: product_id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.status == true) {
          this.setState({ delete_product_id: "", showDeletemodel: false });
          swal("", res.data.message, res.data.success ? "danger" : "success");
          this.callProductList();
        }else{
          if(res.data.status == false){
            this.setState({
              showDeletemodel: false,
            });
            swal("",res.data.message,res.data.success ? "danger ": "warning");
          }
        }

        // this.setModalShow(true)
      })
      .catch((err) => {});
      console.log(message,"uii")
  };

  ProductDeleteModal = (props) => {
    return (
      <Modal {...props}>
        <Modal.Header className="modal-title">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Product
          </h5>
          <button
            type="button"
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(event) => this.setDeleteModalShow(event, false)}
          ></button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <p>Are you sure you want to Delete this Product</p>
        </Modal.Body>
        <ModalFooter className="justify-content-end ">
          <div className="d-flex">
            <button
              type="button"
              onClick={(event) => this.setDeleteModalShow(event, false)}
              className="btn btn-secondary me-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={() => this.submitdeleteproduct()}
              className="btn btn-primary"
            >
              Yes
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  MyVerticallyCenteredModal = (props) => {
    return (
      <Modal className="modalxl" {...props}>
        <Modal.Header className="modal-title">
          <h5 id="staticBackdropLabel">Non Fuel Product configuration</h5>
          <button
            type="button"
            className="btn-close"
            onClick={(event) => this.setModalShow(event, false)}
          ></button>
        </Modal.Header>
        <Modal.Body className=" modal-body ">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label>
                  Product Name<sup class="star">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="product_name"
                  value={this.state.fields.product_name}
                  onChange={this.handleInputChange}
                />
                <div className="errorMsg">{this.state.errors.product_name}</div>
              </div>

              <div className="form-group mb-3">
                <label>
                  Select Packing Unit<sup class="star">*</sup>
                </label>
                <div className="dropdown">
                  <div className="select-container">
                    <select
                      className="form-control"
                      onChange={this.handleSelect}
                      name="unit"
                      value={this.state.fields.unit}
                    >
                      <option value="0">Select packing unit</option>
                      <option value="1">Litre</option>
                      <option value="2">Kilogram</option>
                      <option value="3">Number</option>
                    </select>
                  </div>
                </div>
                <div className="errorMsg">{this.state.errors.unit}</div>
              </div>
              {this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Packing Quantity in Litre<sup class="star">*</sup>(ex:0.5lt,0.75lt...etcs)
                  </label>

                  {this.state.edit_status == 1 ? (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInputNON(event,event.target.value)}
                      className="form-control hide-arrow "
                      disabled
                      name="quantity"
                      value={this.state.fields.quantity}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      className="form-control hide-arrow "
                      onKeyPress={(event)=>preventNonNumericalInputNON(event,event.target.value)}
                      name="quantity"
                      value={this.state.fields.quantity}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">{this.state.errors.quantity}</div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Packing Quantity in Kg<sup class="star">*</sup>(ex:0.5kg,0.75kg...etc)
                  </label>
                  {this.state.edit_status == 1 ? (
                    <input
                      type="number"
                      onKeyPress={(event)=> preventNonNumericalInputNON(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="quantity"
                      value={this.state.fields.quantity}
                      disabled
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=> preventNonNumericalInputNON(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="quantity"
                      value={this.state.fields.quantity}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">{this.state.errors.quantity}</div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Product Name with unit<sup class="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="product_full_name"
                    value={this.state.fields.product_full_name}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.product_full_name}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>Product Name with Unit</label>
                  <input
                    type="text"
                    className="form-control"
                    name="product_full_name"
                    value={this.state.fields.product_full_name}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.product_full_name}
                  </div>
                </div>
              ) : (
                ""
              )}
              
            

              {this.state.fields.selling_mode == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Total Stock(in pieces)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
                      disabled
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="text"
                      onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.total_stock}
                  </div>
                </div>
              ) : (
                ""
              )}

<div className="form-group mb-3">
                <label>
                  HSN Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="hsn"
                  onKeyPress={(event)=>preventNonNumericalInputNON(event,event.target.value)}
                  value={this.state.fields.hsn}
                  // placeholder="18%"
                  onChange={this.handleInputChange}
                />
                {/* <div className="errorMsg">{this.state.errors.gst}</div> */}
              </div>

{/* new field sgst and cgst */}

<div className="form-group mb-3">
                <label>
                  GST Percentage<sup class="star">*</sup>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="gst"
                  onKeyPress={(event)=>preventNonNumericalInputNON(event,event.target.value)}
                  value={this.state.fields.gst}
                  // placeholder="18%"
                  onChange={this.handleInputChange}
                />
                <div className="errorMsg">{this.state.errors.gst}</div>
              </div>

                <div className="form-group mb-3">
                <label>
                  SGST<sup class="star">*</sup>
                </label>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  name="sgst"
                  value={this.state.fields.sgst}
                 // onChange={this.handleInputChange}
                />
                <div className="errorMsg">{this.state.errors.sgst}</div>
              </div>

              <div className="form-group mb-3">
                <label>
                  CGST<sup class="star">*</sup>
                </label>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  name="cgst"
                  value={this.state.fields.cgst}
                  onChange={this.handleInputChange}
                />
                <div className="errorMsg">{this.state.errors.cgst}</div>
              </div>

{/* ***************** */}
              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Total Stock in Litre<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.total_stock}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Total Stock in Kg<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.total_stock}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Total Stock in Litre<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.total_stock}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Total Stock in Kg<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="total_stock"
                      value={this.state.fields.total_stock}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.total_stock}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-6">
              {/* {
       this.state.fields.selling_mode == 2 && 
       <div className="form-group mb-3">
       <label>Pack Configuration</label>
         <input type="text" className="form-control"  name="pack_confg" value={this.state.fields.pack_confg} onChange={this.handleInputChange}/>
       </div>
    } */}
              {this.state.fields.selling_mode == 3 ? (
                <div className="form-group mb-3">
                  <label>
                    Pack Configuration<sup class="star">*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="pack_confg"
                    value={this.state.fields.pack_confg}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">{this.state.errors.pack_confg}</div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price of Packing Quantity<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_quantity"
                      value={this.state.fields.purchase_quantity}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_quantity"
                      value={this.state.fields.purchase_quantity}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_quantity}
                  </div>
                </div>
              ) : (
                ""
              )}
              
              {this.state.fields.selling_mode == 3 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price of Packing Quantity<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_quantity"
                      value={this.state.fields.purchase_quantity}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_quantity"
                      value={this.state.fields.purchase_quantity}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_quantity}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                  Purchase Price per piece (inc gst)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      // disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      className="form-control hide-arrow"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 3 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price per piece (inc gst)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      // disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                  Purchase Price per piece (inc gst) <sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      // disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}
{/*  */}
{(this.state.fields.selling_mode == 1||this.state.fields.selling_mode == 2 ||this.state.fields.selling_mode == 3) &&
              (this.state.fields.unit == 2 ||this.state.fields.unit == 1||this.state.fields.unit == 3 ) ? (
                <div className="form-group mb-3">
                  <label>
                  Purchase Price per piece (exc gst)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      readOnly
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_price_exc_gst"
                      value={this.state.fields.purchase_price_exc_gst}
                     // onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                    readOnly
                      type="number"
                      className="form-control hide-arrow"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      name="purchase_price_exc_gst"
                      value={this.state.fields.purchase_price_exc_gst}
                    //  onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price_exc_gst}
                  </div>
                </div>
                
              ) : (
                ""
              )}
  

              {/*  */}
              {(this.state.fields.selling_mode == 1||this.state.fields.selling_mode == 2 ||this.state.fields.selling_mode == 3) &&
              (this.state.fields.unit == 2 ||this.state.fields.unit == 1||this.state.fields.unit == 3 ) ? (
                <div className="form-group mb-3">
                  <label>
                  Purchase Price/piece(GST)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      readOnly
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_price_gst"
                      value={this.state.fields.purchase_price_gst}
                     // onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                    readOnly
                      type="number"
                      className="form-control hide-arrow"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      name="purchase_price_gst"
                      value={this.state.fields.purchase_price_gst}
                    //  onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price_gst}
                  </div>
                </div>
                
              ) : (
                ""
              )}


              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price per Litre<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price per Unit<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price per Litre<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Purchase Price per Unit<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="purchase_price"
                      value={this.state.fields.purchase_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.purchase_price}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Selling Price per piece (inc gst) <sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      // disabled
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price}
                  </div>
                </div>
              ) : (
                ""
              )}





              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 3 ? (
                <div className="form-group mb-3">
                  <label>
                    Selling Price per piece (inc gst)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      // disabled
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price}
                  </div>
                </div>
              ) : (
                ""
              )}


              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Selling Price per piece (inc gst)<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      // disabled
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price}
                  </div>
                </div>
              ) : (
                ""
              )}


              {this.state.fields.selling_mode == 3 ? (
                <div className="form-group mb-3">
                  <label>
                    Selling Price of Packing Quantity<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      className="form-control hide-arrow"
                      name="selling_price"
                      value={this.state.fields.selling_price}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* gst return */}

              {this.state.fields.selling_mode == 1 &&
              (this.state.fields.unit == 1 || this.state.fields.unit == 2 || this.state.fields.unit == 3 )? (
                <div className="form-group mb-3">
                  <label>
                    Return gst per piece <sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="return_gst"
                      value={this.state.fields.return_gst}
                      onChange={this.handleInputChange}
                      readOnly
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="return_gst"
                      value={this.state.fields.return_gst}
                      onChange={this.handleInputChange}
                      readOnly
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.return_gst}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* ************ */}

              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Loose Selling Price/Litre<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Loose Selling Price/Kg<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>
                    Loose Selling Price/Litre<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>
                    Loose Selling Price/kg<sup class="star">*</sup>
                  </label>
                  {this.state.start_operation == "Yes" ? (
                    <input
                      type="number"
                      disabled
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  ) : (
                    <input
                      type="number"
                      onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
                      className="form-control hide-arrow"
                      name="selling_price_loose"
                      value={this.state.fields.selling_price_loose}
                      onChange={this.handleInputChange}
                    />
                  )}
                  <div className="errorMsg">
                    {this.state.errors.selling_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>Margin/piece</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price"
                    value={this.state.fields.margin_price}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>Margin to price</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price"
                    value={this.state.fields.margin_price}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 1 &&
              this.state.fields.unit == 3 ? (
                <div className="form-group mb-3">
                  <label>Margin to price</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price"
                    value={this.state.fields.margin_price}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price}
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>Margin per Pack</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price"
                    value={this.state.fields.margin_price}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 2 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>Margin per Litre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price_loose"
                    value={this.state.fields.margin_price_loose}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label>Margin per Unit</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price_loose"
                    value={this.state.fields.margin_price_loose}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 &&
              this.state.fields.unit == 1 ? (
                <div className="form-group mb-3">
                  <label>Margin per Litre</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price_loose"
                    value={this.state.fields.margin_price_loose}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price_loose}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.selling_mode == 3 ? (
                <div className="form-group mb-3">
                  <label>Margin per Pack</label>
                  <input
                    type="text"
                    className="form-control"
                    name="margin_price"
                    value={this.state.fields.margin_price}
                    onChange={this.handleInputChange}
                    readOnly
                  />
                  <div className="errorMsg">
                    {this.state.errors.margin_price}
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.fields.unit == 3 ||
              this.state.fields.unit == 1 ||
              this.state.fields.unit == 2 ? (
                <div className="form-group mb-3">
                  <label htmlFor="">Stock Value</label>
                  <input
                    type="text"
                    className="form-control"
                    name="stock_value"
                    value={this.state.fields.stock_value}
                    onChange={this.handleInputChange}
                    readOnly
                  ></input>
                  <div className="errorMsg">
                    {this.state.errors.stock_value}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {/* <div className="modal-footer justify-content-end"> */}
          <ModalFooter className="justify-content-center">
            <button
              type="button"
              className="btn btn-primary px-4"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </ModalFooter>
          {/* </div> */}
          {/* </div>
    </div>
  </div>  */}
        </Modal.Body>
      </Modal>
    );
  };
  sendCreateProduct = () => {
    console.log();
    var user = {
      id: this.state.fields.id,
      product_name: this.state.fields.product_name,
      unit: this.state.fields.unit,
      quantity: this.state.fields.quantity,
      product_full_name: this.state.fields.product_full_name,
      selling_mode: this.state.fields.selling_mode,
      total_stock: this.state.fields.total_stock,
      pack_confg: this.state.fields.pack_confg,
      purchase_price: this.state.fields.purchase_price,
      purchase_price_exc_gst: this.state.fields.purchase_price_exc_gst,
      purchase_price_gst: this.state.fields.purchase_price_gst,
      purchase_quantity: this.state.fields.purchase_quantity,
      selling_price: this.state.fields.selling_price,
      selling_price_loose: this.state.fields.selling_price_loose,
      margin_price: this.state.fields.margin_price,
      margin_price_loose: this.state.fields.margin_price_loose,
      stock_value: this.state.fields.stock_value,
      gst: this.state.fields.gst,
      hsn_code:this.state.fields.hsn,
      cgst: this.state.fields.cgst,
      sgst: this.state.fields.sgst,
      return_gst: this.state.fields.return_gst,
    };

    console.log(user,"bbbbbbbbbbbb");

    axios({
      url: API_URL + "/save-non-fuel-config",
      method: "post",
      data: user,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.status === false) {
          console.log(1);
          var errors = this.state.errors;
          errors["product_full_name"] = res.data.response.product_full_name;
          // swal("",res.data.response.message,res.data.status?"success":"warning"  )
          this.setState({ errors: errors });
        }
        //  else{
        if (res.data.status == true) {
          console.log(2);
          swal("", res.data.message, res.data.status ? "success" : "danger");

          this.callProductList();
          this.setState({
            modalShow: false,
          });
        } else {
          swal(
            "",
            res.data.response.message,
            res.data.status ? "success" : "warning"
          );
        }
        this.setState({
          message: res.data.message,
          status: res.data.status ? "success" : "danger",
        });

        // if (this.state.status === "success") {
        //   this.setState({
        //     modalShow: false,
        //   });
        // }
        // this.setState(
        // {
        //   errors: {}
        // }
        // )
      })
      .catch((error) => {
        console.log(error);
      });
  };

   printDocument = () => {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
        "Accept": "application/json"
      },
      body: JSON.stringify({
        op_date: localStorage.getItem("op_date")
      })
    };

    fetch(API_URL + "/download_pdf_products", requestOptions)
      .then(response => response.blob())
      .then(responseData => {
        const blob = new Blob([responseData]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "nonfuelstockreport.pdf";
        link.click();

        this.props.history.push('/products');

        return responseData;
      })
      .catch(e => {
        console.error(e);
      });
  };

  handleSubmit = (event) => {
    
    event.preventDefault();

    if (this.validateForm()) {
      this.sendCreateProduct();
    }
  };
  render() {
    return (
      <>
        <div className="main-container page-wrap">
          <div className="d-md-flex">
            {this.props.location.pathname == "/products/products" ? (
              <Sidebar_Progress
                path={this.props.location.pathname}
                progress={4}
              />
            ) : (
              <Sidebar_Progress progress={4} />
            )}
            <div className="content-area">
              <LoginHeader />

              <div className="container pb-3">
                <div className="row justify-content-center  mt-3 form-container">
                  {console.log(this.state.backPage, "ggg")}
                  {this.props.location.pathname == "/products/products" ? (
                    <div class="col-md-12 mb-3">
                      <Link to={this.state.backPage || "/operational-date"}>
                        <a href="#" class="float-end btn btn-gray">
                          Go Back
                        </a>
                      </Link>
                    </div>
                  ) : (
                    <div class="col-md-12 mb-3">
                      {/* <Link to="/target-config"> */}
                      <Link to="/Productsconfig">
                        <a href="/Productsconfig" class="float-end btn btn-gray">
                          Back
                        </a>
                      </Link>
                    </div>
                  )}
                  <div className="col-md-12">
                    <div className="form-wrap list-wrp">
                      {this.state.status == 3 ? (
                        <div className="form-wrap">
                          <div className="text-center">
                            {this.state.message}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <div className="row mb-3">
                              <div className="col-md">
                                <div className="row">
                                  <div className="col-md-1 col-2 pe-0">
                                    <img
                                      src={customer_review}
                                      width="25px"
                                      className="img-fluid"
                                      alt=".."
                                    />
                                  </div>
                                  {this.state.loading ? (
                                    this.callProductList
                                  ) : (
                                    // <div class="loader text-primary">Loading...</div>
                                    <Loader />
                                  )}
                                  <div className="col-md-4 ps-0">
                                    <h3 className="table-title">
                                      NON FUEL PRODUCTS
                                    </h3>
                                  </div>
                                  <div className="col-md">
                                    <a
                                      className="btn btn-primary px-3 mb-md-0 mb-2"
                                      onClick={(event) =>
                                        this.setModalShow(event, true)
                                      }
                                    >
                                      ADD NEW PRODUCT
                                    </a>
                                  </div>
                                  {this.state.data.length > 0 && (
  <div className="col-md">
    <a
      className="btn px-3 mb-md-0 mb-2"
      style={{ backgroundColor: 'green', borderColor: 'green', color: 'white' }}
      onClick={this.printDocument}
    >
      Non Fuel Report
    </a>
  </div>
)}



{console.log(this.state.data,"nnnnnnnnnnn")}

                                  {/* <div className="col-md">
            
            </div> */}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="input-group mb-3 search">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search With Product Name"
                                    name="search_keywords"
                                    aria-describedby="basic-addon1"
                                    value={this.state.search_keyword}
                                    onChange={this.handleSearch}
                                    
                                  />
                                   <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={this.check_url} >Submit</a>

                                </div>
                              </div>
                            </div>

                            <div className="table-widget">
                              <table className="table border">
                                <thead>
                                  <tr>
                                    <th>Product Name</th>
                                    <th>Packing Unit</th>
                                    <th>Packing Quantity in(Lt,kg,No:)</th>
                                    <th>Product Full Name</th>
                                    <th>HSN Code</th>
                                    <th>Total Stock</th>
                                    <th>Godown Stock</th>
                                    <th>Gst%</th>
                                    {/* <th>Pack Configuration</th> */}
                                    <th>Purchase Price/Piece</th>
                                    <th>Selling Price/Piece</th>
                                    <th>Purchase Price/gst</th>
                                    <th>Return gst/piece</th>
                                    
                                    {/* <th>Loose Oil Price</th> */}
                                    <th>Margin/Piece</th>
                                    {/* <th>Margin/Loose LT</th> */}
                                    {/* <th>Purchase Price Of Packing Quantity</th> */}
                                    <th>Stock Value</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.data.map((product_list) => {
                                    console.log(product_list,"product_list")
                                    return (
                                      <>
                                        {product_list.selling_mode === 1 && (
                                          <tr className="text-center purchased">
                                            <td>{product_list.product_name}</td>
                                            <td>
                                              {packing_unit[
                                                product_list.unit.toString()
                                              ]
                                                ? packing_unit[
                                                    product_list.unit.toString()
                                                  ]
                                                : ""}
                                            </td>
                                            <td>
                                              {product_list.quantity
                                                ? product_list.quantity
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.product_full_name
                                                ? product_list.product_full_name
                                                : "---"}
                                            </td>
                                            <td>
                                             {product_list.hsn_code?product_list.hsn_code:"---"}
                                            </td>
                                            <td>
                                              {product_list.total_stock
                                                ? product_list.total_stock
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.godown_stock
                                                ? product_list.godown_stock
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.gst
                                                ? product_list.gst
                                                : "---"}
                                            </td>
                                            {/* <td>
                                              {product_list.pack_confg
                                                ? product_list.pack_confg
                                                : "---"}
                                            </td> */}
                                            <td>
                                              {product_list.purchase_price
                                                ? product_list.purchase_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.selling_price
                                                ? product_list.selling_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.purchase_price_gst
                                                ? product_list.purchase_price_gst
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.return_gst
                                                ? product_list.return_gst
                                                : "---"}
                                            </td>
                                            {/* <td>
                                              {product_list.selling_price_loose
                                                ? product_list.selling_price_loose
                                                : "---"}
                                            </td> */}
                                            <td>
                                              {product_list.margin_price
                                                ? product_list.margin_price
                                                : "---"}
                                            </td>
                                            {/* <td>
                                              {product_list.margin_price_loose
                                                ? product_list.margin_price_loose
                                                : "---"}
                                            </td> */}
                                            {/* <td>
                                              {product_list.purchase_quantity
                                                ? "---"
                                                : "---"}
                                            </td> */}
                                            <td>
                                              {product_list.stock_value
                                                ? product_list.stock_value
                                                : "---"}
                                            </td>

                                            <td>
                                              <div className="d-flex icon-btn">
                                                <button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  onClick={() =>
                                                    this.edit_product(
                                                      product_list.id
                                                    )
                                                  }
                                                  title="Edit"
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                <button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  onClick={() =>
                                                    this.delete_product(
                                                      product_list.id
                                                    )
                                                  }
                                                  title="Delete"
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                {/* <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" >	<i className="fa fa-trash" aria-hidden="true"></i>

</button> */}

                                                <div className="dropdown">
                                                  <button
                                                    className="dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    {/* <i className="fa fa-ellipsis-h" aria-hidden="true"></i> */}
                                                  </button>
                                                  <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li>
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                      >
                                                        <i
                                                          className="fa fa-user mr-2"
                                                          aria-hidden="true"
                                                        ></i>
                                                        Action
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                      >
                                                        <i
                                                          className="fa fa-user mr-2"
                                                          aria-hidden="true"
                                                        ></i>
                                                        Another action
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                        {product_list.selling_mode === 2 && (
                                          <tr className="text-center loose-qnty">
                                            <td>{product_list.product_name}</td>
                                            <td>
                                              {packing_unit[
                                                product_list.unit.toString()
                                              ]
                                                ? packing_unit[
                                                    product_list.unit.toString()
                                                  ]
                                                : ""}
                                            </td>
                                            <td>
                                              {product_list.quantity
                                                ? product_list.quantity
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.product_full_name
                                                ? product_list.product_full_name
                                                : "---"}
                                            </td>
                                            <td>
                                              {selling_mode[
                                                product_list.selling_mode
                                              ]
                                                ? selling_mode[
                                                    product_list.selling_mode.toString()
                                                  ]
                                                : ""}
                                            </td>
                                            <td>
                                              {product_list.total_stock
                                                ? product_list.total_stock
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.godown_stock
                                                ? product_list.godown_stock
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.pack_confg
                                                ? product_list.pack_confg
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.purchase_price
                                                ? product_list.purchase_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.selling_price
                                                ? product_list.selling_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.selling_price_loose
                                                ? product_list.selling_price_loose
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.margin_price
                                                ? product_list.margin_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.margin_price_loose
                                                ? product_list.margin_price_loose
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.purchase_quantity
                                                ? product_list.purchase_quantity
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.stock_value
                                                ? product_list.stock_value
                                                : "---"}
                                            </td>

                                            <td>
                                              <div className="d-flex icon-btn">
                                                <button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  onClick={() =>
                                                    this.edit_product(
                                                      product_list.id
                                                    )
                                                  }
                                                  title="Edit"
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                <button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  onClick={() =>
                                                    this.delete_product(
                                                      product_list.id
                                                    )
                                                  }
                                                  title="Delete"
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                {/* <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" >	<i className="fa fa-trash" aria-hidden="true"></i>

</button> */}

                                                <div className="dropdown">
                                                  <button
                                                    className="dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                  >
                                                    {/* <i className="fa fa-ellipsis-h" aria-hidden="true"></i> */}
                                                  </button>
                                                  <ul
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton1"
                                                  >
                                                    <li>
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                      >
                                                        <i
                                                          className="fa fa-user mr-2"
                                                          aria-hidden="true"
                                                        ></i>
                                                        Action
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        className="dropdown-item"
                                                        href="#"
                                                      >
                                                        <i
                                                          className="fa fa-user mr-2"
                                                          aria-hidden="true"
                                                        ></i>
                                                        Another action
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                        {product_list.selling_mode === 3 && (
                                          <tr className="text-center same-size">
                                            <td>{product_list.product_name}</td>
                                            <td>
                                              {packing_unit[
                                                product_list.unit.toString()
                                              ]
                                                ? packing_unit[
                                                    product_list.unit.toString()
                                                  ]
                                                : ""}
                                            </td>
                                            <td>
                                              {product_list.quantity
                                                ? product_list.quantity
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.product_full_name
                                                ? product_list.product_full_name
                                                : "---"}
                                            </td>
                                            <td>
                                              {selling_mode[
                                                product_list.selling_mode
                                              ]
                                                ? selling_mode[
                                                    product_list.selling_mode.toString()
                                                  ]
                                                : ""}
                                            </td>
                                            <td>
                                              {product_list.total_stock
                                                ? product_list.total_stock
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.godown_stock
                                                ? product_list.godown_stock
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.pack_confg
                                                ? product_list.pack_confg
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.purchase_price
                                                ? product_list.purchase_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.selling_price
                                                ? product_list.selling_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.selling_price_loose
                                                ? product_list.selling_price_loose
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.margin_price
                                                ? product_list.margin_price
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.margin_price_loose
                                                ? product_list.margin_price_loose
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.purchase_quantity
                                                ? product_list.purchase_quantity
                                                : "---"}
                                            </td>
                                            <td>
                                              {product_list.stock_value
                                                ? product_list.stock_value
                                                : "---"}
                                            </td>

                                            <td>
                                              <div className="d-flex icon-btn">
                                                <button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  onClick={() =>
                                                    this.edit_product(
                                                      product_list.id
                                                    )
                                                  }
                                                  title="Edit"
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-pencil"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                <button
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="bottom"
                                                  onClick={() =>
                                                    this.delete_product(
                                                      product_list.id
                                                    )
                                                  }
                                                  title="Delete"
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-trash"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                {/* <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" >	<i className="fa fa-trash" aria-hidden="true"></i>

</button> */}

                                                {/* <div className="dropdown">
  <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a className="dropdown-item" href="#"><i className="fa fa-user mr-2" aria-hidden="true"></i>Action</a></li>
    <li><a className="dropdown-item" href="#"><i className="fa fa-user mr-2" aria-hidden="true"></i>Another action</a></li>
    
  </ul>
</div> */}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    );
                                  })}
                                </tbody>
                              </table>
                              <div className="pagination mt-4 justify-content-end">
                                {this.state.loading
                                  ? this.state.total_pages > 10 && (
                                      <Pagination
                                        activePage={this.state.current_page}
                                        itemClass="123"
                                        itemsCountPerPage={this.state.per_page}
                                        totalItemsCount={
                                          this.state.total_pages || 1
                                        }
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePagination}
                                      />
                                    )
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="text-end mt-4">
                      {/* <a className="btn btn-primary px-3 mb-md-0 mb-2 me-2">Skip</a> */}
                      {this.props.location.pathname == "/products/products" ? (
                        ""
                      ) : (
                        <a
                          className="btn btn-primary px-3 mb-md-0 mb-2"
                          onClick={(event) =>
                            this.props.history.push("/rack-config")
                          }
                        >
                          Skip/Continue
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <this.MyVerticallyCenteredModal
          show={this.state.modalShow}
          selectedvalue={this.state.selectedvalue}
          selectedid={this.state.selectedid}
          onHide={(event) => this.setModalShow()}
          data={this.state}
        />
        <this.ProductDeleteModal
          show={this.state.showDeletemodel}
          selectedvalue={this.state.selectedvalue}
          selectedid={this.state.selectedid}
          onHide={(event) => this.setDeleteModalShow()}
          data={this.state}
        />
      </>
    );
  }
}

export default Product;
