import React from "react";
import { Component } from "react";
import {AiFillEyeInvisible, AiFillEye} from 'react-icons/ai'

import logo from "../img/logo.svg";
import whatsapp from "../img/whatsapp.svg";
import "../App.css";
import { API_URL } from "../constant/API_Settings";
// import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Modal } from "react-bootstrap";
import OTPInput, { ResendOTP } from "otp-input-react";
import OtpTimer from 'otp-timer';
import swal from "sweetalert";
import { Link } from "react-router-dom";
import {
  convertAmountToWords,
  preventNonNumericalInput,
  prevNonNumericalWithDec,
} from "./layouts/Sidebar_Progress.component";
import Loader from "./operational-model/Employee/Loader.component";
const rx_live = /^\d*(?:\d*)?$/;
class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: {},
      seconds: 160,

      name: "",
      phone: "",
      email: "",
      user_name: "",
      password: "",
      showPassword: false,
      fields: {},
      otp: "",
      a_token:"",
      loading: false,

      errors: {},
      phone_response: "",
      c_password: "",
      showcPassword: false,
      validated: false,
      username_error: "",
      modalShow: false,
      password_error: "",

      authentication_error: "Invalid Credentials",
      authentication_error_open: false,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleCredentials = this.handleCredentials.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
  }
  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  componentDidMount() {}
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  toggleShowPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  };

  toggleShowcPassword = () => {
    this.setState(prevState => ({
      showcPassword: !prevState.showcPassword,
    }));
  };
  
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  handleCredentials = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
  };

  handleCredentials1 = (e) => {
    let fields = this.state.fields;
   
    fields[e.target.name] = e.target.value
    this.setState({
      fields: fields,
    });
  };
  handle = (e) => {
    let fields = this.state.fields;
    if (rx_live.test(e.target.value)) fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
  };
  preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
  }
  handleInputs = (event) => {
    console.log(event);
    this.setState({
      otp: event,
    });

    //   switch(event.target.name)
    //   {
    //   case "otp1":
    // 	this.myInputRef1.focus();

    // 	break;
    // 	case "otp2":
    // 	this.myInputRef2.focus();
    // 	break;
    // 	case "otp3":
    // 	this.myInputRef3.focus();
    // 	break;
    //   }
  };
  validateForm() {
    var lng = this.state.lng;

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["name"]) {
      formIsValid = false;
      errors["name"] = "Enter Name";
    }
    if (typeof fields["name"] !== "undefined") {
      if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["name"] = "Enter alphabet characters only.";
      }
    }
    

    if (!fields["email"] && fields["email"] !== "") {
      formIsValid = false;
      errors["email"] = " Enter Email Id";
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Enter valid email id";
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = " Enter Phone Number";
    }
    if (typeof fields["phone"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = "enter phone number.";
      } else if (fields["phone"].length != 10) {
        formIsValid = false;
        errors["phone"] = " Enter Valid Phone Number.";
      }
    }

    if (!fields["user_name"]) {
      formIsValid = false;
      errors["user_name"] = "Enter User Name";
    }
   

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Enter Password";
    }

    if (typeof fields["password"] !== "undefined") {
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if (fields["password"].length < 8) {
        formIsValid = false;
        errors["password"] = "Add Atleast 8 Character";
      }
      else if(!strongRegex.test(fields["password"])) {
        formIsValid = false;
        errors["password"] = "Password must include atleast one uppercase,lowercase,digits and special character";
      }
    }
    if (!fields["c_password"]) {
      formIsValid = false;
      errors["c_password"] = " enter confirm password";
    }

    if (typeof fields["c_password"] !== "undefined") {
      const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      if (fields["c_password"].length < 8) {
        formIsValid = false;
        errors["c_password"] = " Add Atleast 8 Character";
      }
      else if(!strongRegex.test(fields["c_password"])) {
        formIsValid = false;
        errors["c_password"] = "Password must include atleast one uppercase,lowercase,digits and special character";
      }
    }
    if (
      typeof fields["password"] !== "undefined" &&
      typeof fields["c_password"] !== "undefined"
    ) {
      if (fields["password"] !== fields["c_password"]) {
        formIsValid = false;
        errors["c_password"] = "Password Not Matching";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  //  resentotp = () => {
  //   console.log("button clicked");
  // };

  resentotp = async () => {
    var loginDetails = {
      otp: this.state.otp,
      access_token:localStorage.getItem("ACCESS_TOKEN") ? localStorage.getItem("ACCESS_TOKEN") : this.state.a_token
    };
    
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      Accept: "application/json",
    };

    await axios
    .post(API_URL + "/resent_otp", loginDetails, { headers: headers })
    .then((res) => {
      if (res.data.status == false) {

      }
      if (res.data.status === false) {
        swal("", res.data.message, "warning");
      }
      if (res.data.status) {
        
      }
      if (res.data.status === false) {
        // swal("", res.data.message, "warning");
      }
    })
    .catch((err) => {
      this.setState({
        phone_response: "Unable to Sent OTP",
      });
    });
    


  };

  sendOtp = async () => {
    var loginDetails = {
      otp: this.state.otp,
      access_token:localStorage.getItem("ACCESS_TOKEN") ? localStorage.getItem("ACCESS_TOKEN") : this.state.a_token
    };

    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      //Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      Accept: "application/json",
    };
    await axios
      .post(API_URL + "/check_reg_otp", loginDetails, { headers: headers })
      .then((res) => {
        if (res.data.status == false) {
          var errors = {};
          errors["otp4"] = res.data.message;
          for (var key in res.data.error) {
            console.log(key);
            if (res.data.error.hasOwnProperty(key)) {
              if (key === "otp") {
                errors["otp4"] = res.data.error[key];
              }
            }
          }

          this.setState({ errors: errors });
        }
        if (res.data.status) {
          
          swal("", res.data.message, res.data.status ? "success" : "warning");
          let token = res.data.success.token;
          localStorage.setItem("AUTH_TOKEN", token);
          localStorage.setItem("PROFILE_NAME", res.data.user.name);
          this.props.history.push("/pump-details");
        }
        if (res.data.status === false) {
          swal("", res.data.message, "warning");
        }
      })
      .catch((err) => {
        this.setState({
          phone_response: "Unable to Sent OTP",
        });
      });
  };

  async sendAuthentication() {
  
    var loginDetails = {
      name: this.state.fields.name,
      phone: this.state.fields.phone,
      email: this.state.fields.email,
      user_name: this.state.fields.user_name,
      password: this.state.fields.password,
      c_password: this.state.fields.c_password,
    };
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    await axios
      .post(API_URL + "/register", loginDetails, { headers: headers })
      .then((res) => {
        console.log(res.data,"ok");
        //    let token = res.data.success.token
        //    localStorage.setItem("AUTH_TOKEN",token)
        //    console.log(token)
        //  if(token){
        // 	 this.setModalShow();
        // 	  }
        if (res.data.status == false) {
          var errors = {};
          for (var key in res.data.error) {
            if (res.data.error.hasOwnProperty(key)) {
              if (key === "user_name") {
                errors["user_name"] = res.data.error[key];
              }
              if (key === "email") {
                errors["email"] = res.data.error[key];
              }
              if (key === "phone") {
                errors["phone"] = res.data.error[key];
              }
            }
          }
          this.setState({ errors: errors });
        } else {
          this.setModalShow(true);
           let token = res.data.success.token;
          localStorage.setItem("ACCESS_TOKEN", token);
          this.state.a_token = token;
          console.log(res.data.user);
          localStorage.setItem("PROFILE_NAME", res.data.user.name);

          // if (token) {
          //   this.setModalShow(true);
          // }
        }

        this.setState({
          loading: true,
        });
      })
      .catch((err) => {
        this.setState({
          authentication_error_open: true,
          authentication_error: "Invalid Credentials",
        });
      });
  }
  setModalShow(show) {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      modalShow: show,
      time: timeLeftVar,
    });
  }

  MyVerticallyCenteredModal = (props) => {
    return (
      <Modal {...props}>
        <div className="modal-header">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => this.setModalShow(false)}
          ></button>
        </div>
        <Modal.Body className=" modal-body text-center otp-container ">
          <h3 className="">Verification</h3>
          <h4 className="">
            Enter the four digit code we sent you <br />
            via your phone number
          </h4>

          <div className="row">
            <OTPInput
              value={this.state.otp}
              inputClassName="col otp p-1"
            
              onChange={this.handleInputs}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />

            {/* <div className="col p-1"><input type="number" onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)} max={9} className="form-control hide-arrow" name="otp1" value={this.state.otp1} onChange={this.handleInputs} /></div>
					  
       				<div className="col p-1"><input type="number"  onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)} maxLength="1" className="form-control hide-arrow" name="otp2" value={this.state.otp2} ref={c => (this.myInputRef1 = c)} onChange={this.handleInputs}/></div>
       				<div className="col p-1"><input type="number"  maxLength="1"  onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)} className="form-control hide-arrow" name="otp3"value={this.state.otp3} ref={c => (this.myInputRef2 = c)}onChange={this.handleInputs}/></div>
       				<div className="col p-1"><input type="number"  maxLength="1"  onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)} className="form-control hide-arrow" name="otp4"value={this.state.otp4} ref={c => (this.myInputRef3 = c)} onChange={this.handleInputs}/></div> */}
          </div>
          <div className="errorMsg">{this.state.errors.otp4}</div>
          <h4>
            {/* Code expire in:{" "} */}
            <span class="time">
              {" "}
              {/* {this.state.time.m} : {this.state.time.s} */}
              {/* <OtpTimer seconds= {30} minutes={0} resend={this.handleClick} /> */}
              <OtpTimer seconds= {30} 
                        minutes={0} 
                        ButtonText="Resend"
                        text="Resent OTP in: "
                        resend={this.resentotp} />
            </span>
          </h4>
          <button
            type="button"
            className="btn btn-success w-100"
            onClick={this.sendOtp}
          >
            {" "}
            Continue
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  handleSubmit = (event) => {
   
    event.preventDefault();
    if (this.validateForm()) {
      this.sendAuthentication();
    }
  };

  render() {
    const { name, email, phone, user_name, password, c_password } = this.state;

    return (
      <>
        <div className="main-container">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 login-left">
                <div className="row pt-5 pb-5 window-height justify-content-center align-items-center">
                  <div className="col-md-8">
                    <div className="login-banner">
                      <a className="logo-continer" href="#">
                        <img src={logo} />
                      </a>
                      <a className="btn btn-info" href="#" role="button">
                        REQUEST CALL BACK
                      </a>
                      <button type="button" className="btn btn-outline-primary">
                        <img src={whatsapp} />
                        Whatsapp
                      </button>
                      <h6>Petrosmartservice@gmail.com</h6>
                      <div className="border-top"></div>
                      <h6>91 8714554771</h6>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 login-right">
                <div className="row pt-2 pb-2 window-height justify-content-center align-items-center">
                  <div className="col-md-8">
                    {/* {this.state.loading ? this.componentDidMount() : <Loader />} */}
                    <div className="login-container">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-container">
                          <h2>Registration</h2>
                          <p>For New User</p>
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                id="Name"
                                type="text"
                                className="form-control"
                                // pattern="[A-Za-z]{3}"
                                name="name"
                                value={this.state.fields.name}
                                onChange={this.handleCredentials1}
                              />
                              <label htmlFor="Name">
                                Name<sup class="star">*</sup>
                              </label>
                              <div className="bar"></div>
                            </div>
                            <div className="errorMsg">
                              {this.state.errors.name}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                id="phone"
                                type="number"
                                onKeyPress={(event) =>
                                  prevNonNumericalWithDec(
                                    event,
                                    event.target.value
                                  )
                                }
                                className="form-control hide-arrow ph_no"
                                name="phone"
                                value={this.state.fields.phone}
                                onChange={this.handle}
                              />
                              <label htmlFor="Phone">
                                Phone Number<sup class="star">*</sup>
                              </label>
                              <div className="bar"></div>
                            </div>
                            <div className="errorMsg">
                              {this.state.errors.phone}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                id="Email"
                                type="text"
                                className="form-control"
                                name="email"
                                value={this.state.fields.email}
                                onChange={this.handleCredentials}
                              />
                              <label htmlFor="Email">
                                Email<sup class="star">*</sup>
                              </label>
                              <div className="bar"></div>
                            </div>
                            <div className="errorMsg">
                              {this.state.errors.email}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-container">
                              <input
                                id="USERNAME"
                                type="text"
                                className="form-control"
                                name="user_name"
                                value={this.state.fields.user_name}
                                onChange={this.handleCredentials1}
                              />
                              <label htmlFor="USER NAME">
                                Username<sup class="star">*</sup>
                              </label>
                              <div className="bar"></div>
                            </div>
                            <div className="errorMsg">
                              {this.state.errors.user_name}
                            </div>
                          </div>
                          <div  className="form-group">
                            <div style={{display:"flex",border: '1px solid #bfbfbf',borderRadius: '5px'}} className="justify-between items-center input-container" >
                              <input
                              onPaste={(e)=>{
                                e.preventDefault()
                                return false;
                              }} onCopy={(e)=>{
                                e.preventDefault()
                                return false;
                              }}
                                id="PASSWORD"
                                type={this.state.showPassword ? 'text' : 'password'}
                                className="form-control"
                                name="password"
                                value={this.state.fields.password}
                                onChange={this.handleCredentials}
                              />
                              <div className="p-2" onClick={this.toggleShowPassword}>
                                  {this.state.showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                              </div>
                              <label htmlFor="PASSWORD">
                                Password<sup class="star">*</sup>
                              </label>
                              

                              {/* <div className="bar"></div> */}
                            </div>
                            {/* <div><AiFillEyeInvisible /></div>
                            <div><AiFillEye /></div> */}
                            <div className="errorMsg">
                              {this.state.errors.password}
                            </div>
                          </div>
                          <div className="form-group mb-4">
                            <div style={{display:"flex",border: '1px solid #bfbfbf',borderRadius: '5px'}} className="input-container">
                              <input
                              onPaste={(e)=>{
                                e.preventDefault()
                                return false;
                              }} onCopy={(e)=>{
                                e.preventDefault()
                                return false;
                              }}
                                id="C_PASSWORD"
                                type={this.state.showcPassword ? 'text' : 'password'}
                                className="form-control"
                                name="c_password"
                                value={this.state.fields.c_password}
                                onChange={this.handleCredentials}
                              />
                              <label htmlFor="C_PASSWORD">
                                Confirm Password<sup class="star">*</sup>
                              </label>
                              <div className="p-2" onClick={this.toggleShowcPassword}>
            {this.state.showcPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
          </div>
                              {/* <div className="bar"></div> */}
                            </div>
                            <div className="errorMsg">
                              {this.state.errors.c_password}
                            </div>
                          </div>
                          <this.MyVerticallyCenteredModal
                            show={this.state.modalShow}
                            selectedvalue={this.state.selectedvalue}
                            selectedid={this.state.selectedid}
                            onHide={(event) => this.setModalShow()}
                            animation={false}
                            data={this.state}
                          />
                          <div className="form-group mb-3">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this.startTimer()}
                            >
                              Confirm
                            </button>
                            {/* {this.state.ButtonClicked && <this.otp_sent />} */}
                          </div>
                          <h4>
                            Please{" "}
                            <Link to="/" style={{ textDecoration: "none" }}>
                              Click Here{" "}
                            </Link>
                            to Login
                          </h4>
                          <h4>
                            By Signing in , you agree to the <b>Terms of Use</b>{" "}
                            and <br></br>
                            <b>Privacy Policy</b>.
                          </h4>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Registration;
