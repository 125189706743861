import React from 'react'
import notfound from '../img/not-found.png'

const PageNotFound = () => {
    return (
        <div className="main-container">
	<div className="container-fluid">
        <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
            <img src={notfound} className="img-fluid text-center " />
            
                <h3 className="text-center">This page could not be found</h3>
           
            </div>
            <div className="col-4"></div>

        </div >
        </div>
        </div>
    )
}

export default PageNotFound