import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import { Link,useLocation } from "react-router-dom";
import { isPlainObject } from 'jquery';
import moment from 'moment';
import swal from 'sweetalert';

const NonFuelEditPurchase = ({ isClose,product_data,er,err,errrpp,errrppp,gst,mrp,Values,startDate,fuel_config,errr,preventNonNumericalInput,prevNonNumericalWithDec,edit_list, hide,handleEditAdd,handleEditSubmit,handleEditRemove, setVehicle,vendor_data,chek_rk, errors,inputValues,handleFieldQuantityEdit, handleFieldProductsEdit, handleQuantityEdit,handleProductsEdit,input,handleDiscount, handleAdd, handleRemove, handleChange, fields, dropdownData, handleInputs }) => {
  const location_details = useLocation()
  var today = new Date().toISOString().split('T')[0];
  const handleSubmit = () => {
	swal("","After approval edit is not possible","warning")
	};
  return (
        <React.Fragment>

            <div className="container pb-3">
              
           
        <div className="row mb-3 justify-content-center">
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal modal-dialog-xl modalxxl col-22 ">
                          <div className="modal-header mb-3">
                                  <h5 className=" text-center" id="staticBackdropLabel">non fuel product purchase</h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>		
                           <div >                                  
        <div className="row mb-3 justify-content-center py-3">       
            <div className=" col-md-6 pe-0 ps-4">
              <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Select Vendor<sup className="star">*</sup></label>
                </div>
                <div className="col-6">
                  <div className="select-container">
                    <select id="vendor_id" className="form-control " name="vendor_id" value={input.vendor_id} onChange={handleInputs}>
                      <option value="">Select</option>
                      {vendor_data.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }
                    </select>
                  </div> 
                  <div className="errorMsg">{errors.vendor_id}</div>
                  <Link  to={location => ({ ...location, fields:{ fields: true },backpage: location_details.pathname, pathname: "/vendor-config/nf-purchase" })} className="link-text"><a href="">Redirect to Vendor configuration</a></Link>
                </div>  
                <div className="col-2"></div>
               </div>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Invoice Number<sup className="star">*</sup></label>
                </div>
                <div className="col-6">
                  <input type="text" className="form-control " id="invoice" name="invoice_number" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} value={input.invoice_number} onChange={handleInputs}></input>
                  <div className="errorMsg">{errors.invoice_number}</div>
                </div>  
                <div className="col-2"></div> 
               </div>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Invoice Date<sup className="star">*</sup></label>
                </div>
                <div className="col-6">
                  <input type="date" className="form-control " name="invoice_date"  min={startDate} max={today} id={"m_op_date"} value={input.invoice_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :input.invoice_date} onChange={handleInputs} ></input>
                  <div className="errorMsg">{errors.invoice_date}</div>
                </div>  
                <div className="col-2"></div>
               </div>
               {
               Values=="" ?
               fields.map((obj, idx) => {

                return (
                <>
           <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Product Name</label>
            </div>
            <div className="col-6">
              <div className="select-container">
                <select  className="form-control rackids product" name="product_id" id={"id"} defaultValue={obj.product_id}  onChange={(event) => handleFieldProductsEdit(idx, event)} >
                  <option>Select</option>
                {product_data.map((data) => {
                                            return <option  className={chek_rk.includes(data[0]) == true ? "d-none" : ''} value={data[0]}>{data[1]}</option>
                                        })
                                        }
                </select>
              </div>
            </div>  
            <div className="col-2">
                          <button className="btn-add   me-2"  onClick={handleEditAdd}>+</button>
                          <button className="btn-add   "  onClick={handleEditRemove}>-</button>

                        </div>
           </div> 
           <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Number of Purchase Quantity</label>
            </div>
            <div className="col-6">
              <input type="text" className="form-control " name="quantity"   id={"quantity"+idx} defaultValue={obj.quantity} onChange={(event) => handleFieldQuantityEdit(idx, event)}></input>
            </div>  
            <div className="col-2"></div> 
           </div>

           <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Purchase Price/Quantity</label>
            </div>
            <div className="col-6">
              <input type="number" className="form-control "  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="price" Value={obj.price}  id={"purchase-price"+idx}  onChange={(event) => handleFieldQuantityEdit(idx, event)}/>
            </div>  
            <div className="col-2"></div> 
           </div>
           {/* ****** */}

           <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Purchase Price/piece inc gst after discount</label>

                            </div>
                            <div class="col-6">
                              <input type="number" class="form-control hide-arrow" name="pp_inc_gst_aft_disc" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} Value={obj.pp_inc_gst_aft_disc} id={"pp_inc_gst_aft_disc" + idx} onChange={(event) => handleFieldQuantityEdit(idx, event)}></input>
                              <div className="errorMsg">{errrpp[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

           {/*  */}
           <div className="row g-3 justify-content-center mb-2">
            <div className="col-4">
              <label className="col-form-label">Total Purchase Price </label>
            </div>
            <div className="col-6">
              <input type="text" className="form-control " name="amount" id={"amount"+idx} defaultValue={obj.amount} readOnly></input>
            </div>  
            <div className="col-2"></div> 
           </div>
            </>
                  
            );
            }):
               Values.map((obj, idx) => {

                    return (
                    <>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Product Name<sup className="star">*</sup></label>
                </div>
                <div className="col-6">
                  <div className="select-container">
                    <select  className="form-control rackids product" name="product_id" id={"id"+idx} defaultValue={obj.product_id}  onChange={(event) => handleProductsEdit(idx, event)} >
                      <option value="Select">Select</option>
                    {product_data.map((data) => {
    
                                                return <option className={chek_rk.includes(data[0]) == true ? "d-none" : ''} value={data[0]}>{data[1]}</option>
                                            })
                                            }
                    </select>
                  </div>
                  <div className="errorMsg">{err[idx]}</div>
                </div>  
                <div className="col-2">
                              <button className="btn-add   me-2"  onClick={handleEditAdd}>+</button>
                              <button className="btn-add   "  onClick={handleEditRemove}>-</button>

                            </div>
               </div> 
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Number of Purchase Quantity<sup className="star">*</sup></label>
                </div>
                <div className="col-6">
                  <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className="form-control hide-arrow" name="quantity"  id={"quantity"+idx} defaultValue={obj.quantity} onChange={(event) => handleQuantityEdit(idx, event)}></input>
                  <div className="errorMsg">{er[idx]}</div>  
                </div>  
                <div className="col-2"></div> 
               </div>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  {/* <label className="col-form-label">Purchase Price / Quantity</label> */}
                </div>
                <div className="col-6">
                  <input hidden type="number" className="form-control hide-arrow " name="price" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} defaultValue={obj.price}  id={"purchase-price"+idx} onChange={(event) => handleQuantityEdit(idx, event)}></input>
                  <div className="errorMsg">{errr[idx]}</div>
                </div>  
                <div className="col-2"></div> 
               </div> 

               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Purchase Price/piece inc gst after discount</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control hide-arrow " name="price" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} defaultValue={obj.pp_inc_gstanddisc}  id={"pp_inc_gst_aft_disc"+idx} onChange={(event) => handleQuantityEdit(idx, event)}></input>
                  <div className="errorMsg">{errrpp[idx]}</div>
                </div>  
                <div className="col-2"></div> 
               </div>

               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  <label className="col-form-label">Total Purchase Price inc gst aft dics</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control hide-arrow " name="total_pp_inc_gst_aft_disc" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} defaultValue={obj.amount}  id={"total_pp_inc_gst_aft_disc"+idx} onChange={(event) => handleQuantityEdit(idx, event)}></input>
                  <div className="errorMsg">{errrppp[idx]}</div>
                </div>  
                <div className="col-2"></div> 
               </div>
               
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  {/* <label className="col-form-label">Current Stock</label> */}
                </div>
                <div className="col-6">
                  <input hidden type="number" className="form-control hide-arrow " name="current_stock" disabled defaultValue={obj.current_stock}  id={"current_stock"+idx} onChange={(event) => handleQuantityEdit(idx, event)}></input>
                  {/* <div className="errorMsg">{errr[idx]}</div> */}
                </div>  
                <div className="col-2"></div> 
               </div>

               <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">Previous Purchase Price inc gst</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden readOnly type="number" class="form-control hide-arrow" name="price" defaultValue={obj.price}  id={"purchase-price" + idx} ></input>
                              <div className="errorMsg">{errr[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>
                
                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">average Purchase Price inc gst</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden readOnly type="number" class="form-control hide-arrow" defaultValue={obj.avg_pp} name="avg_purchase_price"  id={"avg_purchase_price" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              {/* <label class="col-form-label">Diffrence in Purchase Price</label> */}

                            </div>
                            <div class="col-6">
                              <input hidden readOnly type="number" class="form-control hide-arrow" defaultValue={obj.diff_pp} name="diff_purchase_price"  id={"diff_purchase_price" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">GST</label>

                            </div>
                            <div class="col-6">
                              <input  type="number" class="form-control hide-arrow" defaultValue={obj.gst} name="gst"  id={"gst" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantityEdit(idx, event)}></input>
                              <div className="errorMsg">{gst[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Purchase price/piece exc gst</label>

                            </div>
                            <div class="col-6">
                              <input readOnly  type="number" class="form-control hide-arrow" defaultValue={obj.pp_per_piece_exc_gst} name="pp_per_piece_exc_gst"  id={"pp_per_piece_exc_gst" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantityEdit(idx, event)}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">gstamount</label>

                            </div>
                            <div class="col-6">
                              <input readOnly  type="number" class="form-control hide-arrow" defaultValue={obj.gst_amount} name="gst_amount"  id={"gst_amount" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantityEdit(idx, event)}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">CGST</label>

                            </div>
                            <div class="col-6">
                              <input readOnly type="number" class="form-control hide-arrow" defaultValue={obj.cgst} name="cgst"  id={"cgst" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">SGST</label>

                            </div>
                            <div class="col-6">
                              <input readOnly type="number" class="form-control hide-arrow" defaultValue={obj.sgst} name="sgst"  id={"sgst" + idx} ></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">Mrp/piece</label>

                            </div>
                            <div class="col-6">
                              <input  type="number" class="form-control hide-arrow" name="mrp" defaultValue={obj.mrp}  id={"mrp" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantityEdit(idx, event)}></input>
                              <div className="errorMsg">{mrp[idx]}</div>
                            </div>
                            <div class="col-2"></div>
                          </div>

                          <div class="row g-3 justify-content-center mb-2">
                            <div class="col-4">
                              <label class="col-form-label">GST return</label>

                            </div>
                            <div class="col-6">
                              <input readOnly  type="number" class="form-control hide-arrow" defaultValue={obj.gst_return}  name="gst_return"  id={"gst_return" + idx} onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  onChange={(event) => handleQuantityEdit(idx, event)}></input>
                              {/* <div className="errorMsg">{errr[idx]}</div> */}
                            </div>
                            <div class="col-2"></div>
                          </div>
               
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-4">
                  {/* <label className="col-form-label">Total Purchase Price </label> */}
                </div>
                <div className="col-6">
                  <input hidden type="text" className="form-control " name="amount" id={"amount"+idx} defaultValue={obj.amount} readOnly ></input>
                </div>  
                <div className="col-2"></div> 
               </div>
                </>
                      
                );
                }) }
                </div>
            <div className="col-md-6">
              <div className="row g-3 justify-content-center mb-2">
                <div className="col-5  ">
                  <label className="col-form-label ">Net Total</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control " name="net_amount" id={"net_amount"} value={input.net_amount} readOnly></input>
                </div>  
               
               </div>
              <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">Total Bill Amount Discount</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="discount" id={"discount"} value={input.discount} onChange={handleDiscount} ></input>
                </div>  
               
               </div>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">Amount After Discount</label>
                </div>
                <div className="col-6">
                  <input type="text" className="form-control " name="amount_after_discount" id={"amount_after_discount"}value={input.amount_after_discount} onChange={handleInputs} readOnly></input>
                </div>  
                
               </div> 
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">Round off</label>
                </div>
                <div className="col-6">
                  <input type="text" className="form-control " name="round_off" id={"round_off"} value={input.round_off} onChange={handleInputs} readOnly></input>
                </div>  
                
               </div>    
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">Invoice Amount</label>
                </div>
                <div className="col-6">
                  <input type="text" className="form-control " name="invoice_amount" id={"invoice_amount"} readOnly value={input.invoice_amount} onChange={handleInputs} ></input>
                </div>  
               
               </div> 
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">Transaction Type<sup className="star">*</sup></label>
                </div>
                <div className="col-6">
                  <div className="select-container">
                    <select  className="form-control " name="purchase_type" value={input.purchase_type} onChange={handleInputs}>
                      <option value="" >Select</option>
                      <option value="0">Cash</option>
                      <option value="1">Credit</option>
                    </select>
                  </div>
                  <div className="errorMsg">{errors.purchase_type}</div>
                </div>  
                
               </div>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">Description</label>
                </div>
                <div className="col-6">
                  <textarea type="text" className="form-control " name="note" value={input.note} onChange={handleInputs}></textarea>
                </div>  
        
               </div>
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">CGST</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control hide-arrow " onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} min={0} name="c_gst" value={input.c_gst} onChange={handleInputs}></input>
                </div>  
                
               </div>  
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">SGST</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control hide-arrow" min={0} name="s_gst"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={input.s_gst} onChange={handleInputs}></input>
                </div>  
                
               </div> 
               <div className="row g-3 justify-content-center mb-2">
                <div className="col-5">
                  <label className="col-form-label">IGST</label>
                </div>
                <div className="col-6">
                  <input type="number" className="form-control hide-arrow" min={0} name="i_gst"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={input.i_gst} onChange={handleInputs}></input>
                </div>  
                
               </div> 
              
              </div>
              <div className="modal-footer text-center">
             
             <a href="#" className="btn btn-danger w-auto" onClick={hide}>Cancel</a>
    {edit_list.status == 3 ? <a href="#" className="btn btn-primary  w-auto " onClick={handleSubmit}>Save</a>:<a href="#" className="btn btn-primary  w-auto " onClick={handleEditSubmit}>Save</a>}
   </div> 
            </div>
      <div className="text-end">
      <Link to="/NonFuel-product-Selling-Price-Change"><a href="" className=" text-danger text-end mx-3">Changing Selling Price</a></Link></div>
                                 
                                </div>
                              </div>
</div>
</div>
</div>



       </React.Fragment>

    );
}
export default NonFuelEditPurchase