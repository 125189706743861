import React from "react";
import { Component } from "react";
import succes from '../img/succes.svg'
import logo from '../img/logo.svg'
import whatsapp from '../img/whatsapp.svg';
import '../App.css';
import { API_URL } from "../constant/API_Settings"
import axios from 'axios'
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap'
import swal from "sweetalert";
import {AiFillEyeInvisible, AiFillEye} from 'react-icons/ai'
// import Sidebar from "./layouts/Sidebar";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {

            user_name: '',
            password: '',
            showPassword: false,
            validated: false,
            user_name_error: '',
            password_error: '',
            login_error: '',
            login_error_open: false,
            authentication_error: '',
            authentication_error_open: false,
            errors: {},
        }
        this.handleCredentials = this.handleCredentials.bind(this)
    }
    handleCredentials = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }

    toggleShowPassword =()=> {
        this.setState(prevState => ({
          showPassword: !prevState.showPassword,
        }));
   
      };

    componentDidMount() {
        // document.title = 'Login'
        try {
            let token = localStorage.getItem("AUTH_TOKEN")
            if (token) {
                this.props.history.push("/dashboard")
            }
            else {
                if (this.props.location.authentication_error_open) {
                    this.setState(
                        {
                            authentication_error: "Login Expired",
                            authentication_error_open: true
                        }
                    )
                }
            }
        }
        catch (e) {

        }
    }
    validateForm() {

        const { user_name, password } = this.state;
        let errors = {};

        let formIsValid = true;
        if (user_name === '') {
            formIsValid = false;
            errors["user_name"] = "Please Enter User Name"
        }

        if (password === '') {
            formIsValid = false;
            errors["password"] = "Please Enter Password"
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    handleSubmit = (event) => {

        event.preventDefault()
        event.stopPropagation();
        if (this.validateForm()) {
            this.sendAuthentication();
        }
    }



    async sendAuthentication() {

        try {
            var test = 'test';
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
        } catch (e) {
            this.setState(
                {
                    authentication_error_open: true,
                    authentication_error: "Please enable cookies in your browser"
                }
            )
            return;
        }

        var loginDetails = {
            user_name: this.state.user_name,
            password: this.state.password,
            web_login: "weblogin"
        }

        let headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
        }
        await axios.post(API_URL + '/login', loginDetails, { headers: headers })
            .then(res => {
                if (res.data.status == false) {
                    swal("", res.data.message)
                }
                console.log(res.data, "nnnooooooooooooooooooo")

                let token = res.data.success.token
                localStorage.setItem("AUTH_TOKEN", token)
                localStorage.setItem("ROLE_ID", res.data.role?.role_name)
                localStorage.setItem("PROFILE_NAME", res.data.user_name)
                localStorage.setItem("LAST_SCREEN", res.data.last_screen)
                localStorage.setItem(
                    "PERMISSIONS",
                    res.data?.permissions || []
                );


                if (res.data.last_screen) {
                    this.props.history.push({
                        pathname: res.data.last_screen,
                        userDetails: this.state
                    })
                }
                else {
                    this.props.history.push({
                        pathname: '/pump-details',
                        userDetails: this.state
                    })
                }
            }).catch(err => {

                this.setState(
                    {
                        authentication_error_open: true,
                        authentication_error: "Invalid Credentials"
                    }
                )
            })
    }


    render() {
        const { user_name, password, user_name_error, password_error } = this.state;
        return (
            <>

                <div className="main-container">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-md-6 login-left">
                                <div className="row pt-5 pb-5 window-height justify-content-center align-items-center">
                                    <div className="col-md-8">
                                        <div className="login-banner">
                                            <a className="logo-continer" href="#"><img src={logo} /></a>
                                            <a className="btn btn-info" href="#" role="button">REQUEST CALL BACK</a>
                                            <button type="button" className="btn btn-outline-primary"><img src={whatsapp} />Whatsapp</button>
                                            <h6>Petrosmartservice@gmail.com</h6>
                                            <div className="border-top"></div>
                                            <h6>+91 8714554771</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 login-right">
                                <div className="row pt-5 pb-5 window-height justify-content-center align-items-center">
                                    <div className="col-md-8">
                                        <div className="login-container">
                                            <Alert variant="danger" show={this.state.authentication_error_open}>{this.state.authentication_error}</Alert>
                                            <Alert variant="danger" show={this.state.login_error_open}>{this.state.login_error}</Alert>


                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-container">
                                                    <h2 class="mb-5">Login</h2>
                                                    <div className="form-group">
                                                        <div className="input-container">
                                                            <input id="user_name" type="text" className="form-control" name="user_name" value={user_name} error={user_name_error} onChange={this.handleCredentials} />

                                                            <label htmlFor="user_name">User Name</label>
                                                            <div className="bar"></div>
                                                            <div className="errorMsg">{this.state.errors.user_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div style={{display:"flex",border: '1px solid #bfbfbf',borderRadius: '5px'}} className="input-container">
                                                            <input onPaste={(e)=>{
                                                                e.preventDefault()
                                                                return false;
                                                            }} onCopy={(e)=>{
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                                id="Password" type={this.state.showPassword ? 'text' : 'password'} className="form-control" name="password" value={password} error={password_error} onChange={this.handleCredentials} />
                                                          
                                                            <label htmlFor="password">Password</label>
                                                            <div className="bar"></div>
                                                            
                                                            <div className="p-2" onClick={this.toggleShowPassword}>
                                  {this.state.showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                              </div>
                                                        </div>
                                                        <div className="errorMsg">{this.state.errors.password}</div>
                                                   
                                                        
                                                       
                                                    </div>


                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary">Login</button>
                                                    </div>
                                                    <div className="forgot" style={{display: 'flex', justifyContent:'center'}}>
                                                        {/* <h4><Link to="/forgot" style={{ textDecoration: 'none'}}><u>Forgot Username ?</u></Link> 
                                                        <Link to="/forgot" style={{ textDecoration: 'none'}}><u>Forgot Password ?</u></Link></h4> */}
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px', width: '70%' }}>
                                                        <h4><Link to="/forgotusername" style={{ textDecoration: 'none'}}><u>Forgot Username ?</u></Link></h4> 
                                                        <h4><Link to="/forgotpassword" style={{ textDecoration: 'none'}}><u>Forgot Password ?</u></Link></h4> 

                                                        </div>
                                                    </div>
                                                    <h4>Please <Link to="/register" style={{ textDecoration: 'none' }}>click here </Link>to register</h4>
                                                    <h4>By Signing in , you agree to the <b>Terms of Use</b> and <br /><b>Privacy Policy</b>.</h4>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
export default Login;