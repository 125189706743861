import { Component } from "react";
import inhand from '../../../img/inhand.png'
import store from '../../../img/store.png'
import money from '../../../img/money.png'
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
// import { API_URL } from "../../constant/API_Settings";
import { useState } from 'react';
import FuelLoginHeaderOperational from './FuelLoginHeader.component';
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";
import FuelTabHeader from "./FuelTabHeader.Component";
import { API_URL } from '../../../constant/API_Settings';
import PaginationComponent from '../../layouts/PaginationComponent';
import { preventNonNumericalInput } from '../../layouts/Operational_Sidebar_Progress.component'
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import Modal from "react-modal";

const  PaginationComp  = (props) => (
	< PaginationComponent  {...props} />
		 );
const TankModalView = (obj) => {
   const [list_data1, setList1] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
    console.log(obj)
    useEffect(() => {
        getView(obj.location.id,pages.current_page)
        }, []);
    function getView(id,page) {
     
        const requestOptions = {
           method: 'POST',
           headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
              "Accept": "application/json"
           },
           body: JSON.stringify({
              tank_id:id,
             
           })
        };
        fetch(`${API_URL}/get_tank_history?page=${page}`, requestOptions)

           .then(response => {
              return response.json();
           })
           .then(responseData => {
              setList1(responseData.data)
              setpages({
					...pages,
					current_page: responseData.current_page,
					last_page: responseData.last_page,
					per_pages:  responseData.per_page,
					total_pages:responseData.total,
				   
				  })
              return responseData;
           })
           .catch(e => {
               
           });
     }
     const handlePagination = (number) => {
      setpages({
         ...pages,
         current_page: number        //---pagination---//
      }
      )

      getView(obj.location.id,number)
   }
        return(
            <>
            <div className="main-container page-wrap">
            <div className="d-md-flex">
                <Sidebar_Progress props={obj.location.props} progress={7} />
                <div className="content-area ">
                    <FuelLoginHeaderOperational  props={obj.location.props}page_title="" />
           
            <div className="container pb-3 tab-view">
          
               <div class="row">
								<div className="col-md-12 px-4 mt-3">
               <Link to="/op-tank-edit">
								<a href="#" className="float-end btn btn-gray">Back</a>
									</Link>
                           </div></div>
                           <div className="row mt-md-5 mt-3 form-container px-4 ">
                  <div className="col-md-12">
							
                     <div class="form-wrap ">
                        <div class="tab-content" >
                             <div class="tab-pane fade show active" >
                              <div class="">
                                 <div class="row mb-3">
                                    <div class="col-md">
                                       <div class="row">
                                          <div class="col-md-2 col pl-0">
                                             <h3 class="table-title">Tank History</h3>
                                          </div>
                                         
                                       </div>
                                    </div>
                                   
                                 </div>
                                 <div class="table-widget">
                                    <table class="table border">
                                       <thead>
                                          <tr>
                                             <th>Tank Name</th>
                                             <th>Date</th>
                                             <th>Operation </th>
                                             <th>From Screen </th>
                                             <th>Capacity</th>
                                             <th>Last PP</th>
                                             <th>Current Stock</th>
                                             <th>Stock Value</th>
                                             <th>Created At</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                       {list_data1.map(obj => (
                                          <tr>
                                             <td>{obj.tank_name}</td>
                                             <td>{obj.op_date}</td>
                                             <td>{obj.operation}</td>
                                             <td>{obj.from_screen}</td>
                                             <td>{obj.capacity}</td>
                                             <td>{obj?.last_purchase_price}</td>
                                             <td>{obj.current_stock}</td>
                                             <td>{obj.stock_value}</td>
                                             <td>{obj.created_at}</td>
                                        </tr>
                                        ))} 
                                       </tbody>
                                    </table>
                                 <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>
                                 </div>
                                 <div>
                                  
                                 </div>
                                 
                              </div>
                           </div>
                        </div>
                     </div>
                    
                     
                     </div>
                     </div>
                     </div>
          </div>
          </div>
          </div>
          
			{/* {isShowing ? (<NozzleModal
				isShowing={isShowing}
				hide={toggle}
            double={double}
            preventNonNumericalInput={preventNonNumericalInput}
				dropdownData={dropdownData}
				handleInput={handleInput}
				handleSubmit={handleSubmit}
				inputValues={inputValues}
				errors={errors}
			
			/>) : null
			} */}
         {/* <Modal
              isOpen={isOpen}
              onRequestClose={toggleModal}
              contentLabel="My dialog"
              className="mymodal custommodal"
              overlayClassName="myoverlay"
            >
              <div class="modal-content">
                <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Nozzle</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
                <div class=" modal-body  modal-body"><p>Are you sure you want to delete this Nozzle</p></div>
                <div class="justify-content-end  modal-footer"><div class="d-flex">
                  <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
                 {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteNozzle}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={deleteNozzle}>Yes</button>}
                </div>
                </div>
              </div>




            </Modal> */}
          </>
        )
    }


export default TankModalView;
	
