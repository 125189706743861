import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import DayClosingOperationModal from './DayClosingOperationModal.component';

const DayClosingModal = (props) => ReactDOM.createPortal(
	<DayClosingOperationModal  {...props} />, document.body
);
const DayClosingOperationConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({ vehicle_no: [''] });
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState({ vehicle_no: [''] })
	const [success, setSuccess] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		ListCustomer(pages.current_page);
	}, [search]);

	function ListCustomer(page) {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/allocated-empoyee-list?page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				// setList(responseData.data)
				var total_pages = responseData.last_page

				var items = []
				for (let number = 1; number <= total_pages; number++) {
					number === page ? items.push(
						<li key={number} className="page-item active"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,) : items.push(
							<li key={number} className="page-item"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,)
				}

				setpages({
					...pages,
					current_page: responseData.current_page,
					last_page: responseData.last_page,
					total_pages: responseData.last_page,
					pagination_items: items
				})

				return responseData;
			})
			.catch(e => {


			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListCustomer(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues({ vehicle_no: [''] })
				setErrors({ vehicle_no: [''] })
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors({ vehicle_no: [''] })

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleInputs = (e) => {


		if ((e.target.name === "credit_period" || e.target.name === "credit_amount")) {

			setInputValues({
				...inputValues,
				[e.target.name]: e.target.checked ? 1 : 0
			});
		}

		else
			setInputValues({
				...inputValues,
				[e.target.name]: e.target.value
			});

	};
	//---onchange functions---//
	function handleChange(i, event) {
		const values = [...fields];
		values[i] = event.target.value;
		setFields(values);
		setInputValues({ ...inputValues, vehicle_no: values })
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();

		if (validateForm())
			sendCustomerData()
	}
	//---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true

		if (!inputValues.name) {
			formIsValid = false;
			errors["name"] = "Enter  Customer Name"
		}
		else {
			errors["name"] = ""
		}



		fields.map((inpuvalue, idx) => {

			if (!inpuvalue) {
				formIsValid = false;

				errors["vehicle_no"] = "Enter Vehicle no"


			}
			else {
				errors["vehicle_no"] = ""
			}

		})

		if (inputValues.opening_balance != undefined) {
			if ((inputValues.opening_balance != "") && (inputValues.debit_credit == undefined || inputValues.debit_credit === "")) {
				formIsValid = false;
				errors["debit_credit"] = " Select Debit/Credit"
			}

		}
		if ((inputValues.debit_credit) && (!inputValues.opening_balance)) {
			formIsValid = false;
			errors["debit_credit"] = "Enter Opening Balance"
		}

		if (inputValues.credit_amount === 1 && !inputValues.credit_limit) {

			formIsValid = false;
			errors["credit_limit"] = " Enter Credit Amount"


		}

		if (inputValues.credit_amount == 0) {
			setErrors([])
		}
		if ((inputValues.credit_period === 1) && (!inputValues.credit_limit_period)) {

			formIsValid = false;
			errors["credit_limit_period"] = " Enter Credit Limit period"


		}
		else {
			errors["credit_limit_period"] = ""
		}

		if (inputValues.credit_period === 0) {
			setErrors([])
		}
		setErrors(errors)

		return formIsValid;
	}
	//---Create EmployeeList---//
	function sendCustomerData() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify(inputValues)
		};
		fetch(API_URL + "/customer", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["opening_balance"] = responseData.response.opening_balance
					errors["phone"] = responseData.response.phone
					errors["whatsapp_no"] = responseData.response.whatsapp_no

				}
				if (responseData.status == true) {
					swal("", responseData.messsage, responseData.status ? "success" : "warning")
					history.push("/customers")
					setErrors([''])
					setInputValues([])
					toggle();
					ListCustomer(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}
	//---Edit employee list---//	
	function edit_customer(id, ac_name) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,
				ac_name: ac_name

			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/customer/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				let vehicledata = [];
				responseData.custom_vehicle.forEach((elem) => {

					vehicledata.push(elem.vehicle_no);

				})
				if (vehicledata.length < 1) {
					vehicledata = [''];
				}
				setFields(vehicledata);
				setInputValues(
					{
						...inputValues,
						id: responseData.id ? responseData.id : '',
						ac_name: responseData.ac_name ? responseData.ac_name : '',
						name: responseData.name,
						address: responseData.address,
						phone: responseData.phone,
						email: responseData.email,
						credit_limit: responseData.credit_limit,
						vehicle_no: vehicledata,
						whatsapp_no: responseData.whatsapp_no,
						debit_credit: responseData.debit_credit,
						credit_days: responseData.credit_limit_period,
						credit_limit_period: isNaN(parseInt(responseData.credit_limit_period)) == false ? 'no_of_days' : responseData.credit_limit_period,
						opening_balance: responseData.opening_balance,
						credit_amount: responseData.credit_limit === null ? 0 : 1,
						credit_period: responseData.credit_limit_period === null ? 0 : 1,
					}
				);
				toggle();
				return responseData;
			})
			.catch(e => {


			});
	}
	//---Delete EmployeeList---//	
	function delete_Customer(id) {

		deleteId(id)

		toggleModal()
	}

	function deleteCustomer() {


		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/customer/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.messsage, responseData.status ? "success" : "warning")
				ListCustomer(pages.current_page);
				toggleModal()

				return responseData;


			})

			.catch(err => {

			})
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div className="container pb-3 tab-view">
							<div className="row mt-md-5 mt-3 form-container">
								<div className="">

									<div className="form-wrap ">

										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
												<div className="">
													<div className="row mb-3">
														<div className="col-md">
															<div className="row">

																<div className="col-md col pl-0">
																	<h3 className="table-title">Day Closing operation</h3>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Day Closing operation</a>
														</div>
														<div className="col-md-4">
															<div className="input-group mb-3 search">
																<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
															</div>
														</div>
													</div>


													<div className="table-widget">
														<table className="table border">
															<thead>
																<tr>

																	<th>Day Close</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																<tr>

																	<td>SBI</td>


																	<td className="py-2"><a href="#" className="text-secondary"  ><i className="fa fa-pencil-square-o  pe-2" aria-hidden="true"></i></a>
																		<a href="#" className="text-secondary"><i className="fa fa-trash-o" aria-hidden="true"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>SBI</td>



																	<td className="py-2"><a href="#" className="text-secondary"><i className="fa fa-pencil-square-o  pe-2" aria-hidden="true"></i></a>
																		<a href="#" className="text-secondary"><i className="fa fa-trash-o" aria-hidden="true"></i></a>
																	</td>
																</tr>
																<tr>


																	<td>SBI</td>


																	<td className="py-2"><a href="#" className="text-secondary"><i className="fa fa-pencil-square-o  pe-2" aria-hidden="true"></i></a>
																		<a href="#" className="text-secondary"><i className="fa fa-trash-o" aria-hidden="true"></i></a>
																	</td>
																</tr>


															</tbody>
														</table>


													</div>


												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">

								</div>
								<div className="text-end mt-3">
									<a href="#" className="btn btn-primary px-5">Save</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<DayClosingModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fields={fields}
				success={success}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}


			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className="modal-content">
					<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">Customers</h5><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
					<div className=" modal-body  modal-body"><p>Are you sure you want to delete this customer</p></div>
					<div className="justify-content-end  modal-footer"><div className="d-flex">
						<button type="button" className="btn btn-secondary me-2" onClick={toggleModal}>No</button>
						<button type="submit" className="btn btn-primary" onClick={deleteCustomer}>Yes</button>
					</div>
					</div>
				</div>



			</Modal>
		</>
	)
}








export default DayClosingOperationConfig