import React from 'react';
const OwnTankerModal = ({ preventNonNumericalInput,isShowing, hide, errors,double, inputValues, handleSubmit, handleInputs }) => {
 
  return (
    <React.Fragment>
  
  <div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Own Tanker Configuration</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    
  <div class="row mb-3 justify-content-center mt-2">
      <label class="col-sm-5 col-form-label mb-2"> Registration Number<sup class="star">*</sup></label>
     
      <div class="col-sm-6 mb-2">
        <input type="text" class="form-control  w-90" name="reg_number" placeholder=""  value={inputValues.reg_number}  readOnly={inputValues.ob_is_dayclose } onChange={handleInputs} />               
        <div className="errorMsg mr-0">{errors.reg_number}</div>
      </div> 

       <label class="col-sm-5 col-form-label mb-2">Total Capacity in KL <sup class="star">*</sup> </label>
      <div class="col-sm-6 mb-2">
        <input type="number" class="form-control  w-90 hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="total_capacity" placeholder=""  value={inputValues.total_capacity}  readOnly={inputValues.ob_is_dayclose } onChange={handleInputs} />
        <div className="errorMsg">{errors.total_capacity}</div>
       </div>
       
      

      {/* <label class="col-sm-5 col-form-label mb-2">Number of Containers </label>
      <div class="col-sm-6 d-flex">
        <input type="number" class="form-control mb-2 w-90" placeholder="" min={1} max={4} name="no_of_containers" placeholder=""  value={inputValues.no_of_containers} onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_containers}</div>
      </div> */}
    {/* /////   */}
    <label class="col-sm-5 col-form-label mb-2">Number of Containers <sup class="star">*</sup></label>
                    <div class="col-sm-6 mb-2">
                        <select  className="form-control  w-90" name="no_of_containers"  value={inputValues.no_of_containers} onChange={handleInputs}  readOnly={inputValues.ob_is_dayclose }> 
                        <option value="0">Select</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        <div className="errorMsg">{errors.no_of_containers}</div>
                    </div>


      { inputValues.no_of_containers == 1  && 
         <> 
            <label class="col-sm-5 col-form-label mb-2">Container no 1 capacity in KL</label>
              <div class="col-sm-6 mb-2">
                <input type="number" class="form-control hide-arrow w-90" 
                        name="capacity_of_con1" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con1} onChange={handleInputs}  readOnly={inputValues.ob_is_dayclose }/>
                  <div className="errorMsg">{errors.capacity_of_con1}</div>
              
              </div>
         </>
      }
      { inputValues.no_of_containers == 2  && 
         <> 
            <label class="col-sm-5 col-form-label mb-2">Container no 1 capacity in KL</label>
                  <div class="col-sm-6 mb-2">
                    <input type="number" class="form-control hide-arrow w-90" 
                            name="capacity_of_con1" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  value={inputValues.capacity_of_con1} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                      <div className="errorMsg">{errors.capacity_of_con1}</div>
                  
                  </div>
                  <label class="col-sm-5 col-form-label mb-2">Container no 2 capacity in KL</label>
                      <div class="col-sm-6 mb-2">
                        <input type="number" class="form-control hide-arrow w-90" 
                                name="capacity_of_con2" placeholder=""  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con2} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                          <div className="errorMsg">{errors.capacity_of_con2}</div>
                      
                      </div>
         </>     
      }
      { inputValues.no_of_containers == 3  && 
         <> 
            <label class="col-sm-5 col-form-label mb-2">Container no 1 capacity in KL</label>
                  <div class="col-sm-6 mb-2">
                    <input type="number" class="form-control hide-arrow w-90" 
                            name="capacity_of_con1" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con1} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                      <div className="errorMsg">{errors.capacity_of_con1}</div>
                  
                  </div> 
                  <label class="col-sm-5 col-form-label mb-2">Container no 2 capacity in KL</label>
                      <div class="col-sm-6 mb-2">
                        <input type="number" class="form-control hide-arrow  w-90" 
                                name="capacity_of_con2" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con2} onChange={handleInputs}  readOnly={inputValues.ob_is_dayclose } />
                          <div className="errorMsg">{errors.capacity_of_con2}</div>
                      
                      </div>
                      <label class="col-sm-5 col-form-label mb-2">Container no 3 capacity in KL</label>
                      <div class="col-sm-6 mb-2">
                        <input type="number" class="form-control hide-arrow w-90" 
                                name="capacity_of_con3" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con3} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                          <div className="errorMsg">{errors.capacity_of_con3}</div>
                      
                      </div>
         </>     
      }
       { inputValues.no_of_containers == 4  && 
         <> 
            <label class="col-sm-5 col-form-label mb-2">Container no 1 capacity in KL</label>
                  <div class="col-sm-6  mb-2">
                    <input type="number" class="form-control hide-arrow w-90" 
                            name="capacity_of_con1" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con1} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                      <div className="errorMsg">{errors.capacity_of_con1}</div>
                  
                  </div>
                  <label class="col-sm-5 col-form-label mb-2">Container no 2 capacity in KL</label>
                      <div class="col-sm-6 mb-2">
                        <input type="number" class="form-control hide-arrow w-90" 
                                name="capacity_of_con2" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con2} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                          <div className="errorMsg">{errors.capacity_of_con2}</div>
                      
                      </div>
                      <label class="col-sm-5 col-form-label mb-2">Container no 3 capacity in KL</label>
                      <div class="col-sm-6 mb-2">
                        <input type="number" class="form-control hide-arrow w-90" 
                                name="capacity_of_con3" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}  value={inputValues.capacity_of_con3} onChange={handleInputs}  readOnly={inputValues.ob_is_dayclose } />
                          <div className="errorMsg">{errors.capacity_of_con3}</div>
                      
                      </div>
                      <label class="col-sm-5 col-form-label mb-2">Container no 4 capacity in KL</label>
                      <div class="col-sm-6 mb-2">
                        <input type="number" class="form-control hide-arrow w-90" 
                                name="capacity_of_con4" placeholder="" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} value={inputValues.capacity_of_con4} onChange={handleInputs}   readOnly={inputValues.ob_is_dayclose }/>
                          <div className="errorMsg">{errors.capacity_of_con4}</div>
                      
                      </div>
         </>     
      }



     {/* ////  */}

      {/* <label className="col-sm-5 col-form-label mb-2">Tanker Income Head </label>
      <div className="col-sm-6 mb-2">
        <input type="text" className="form-control  w-90" 
                name="ac_name" placeholder=""  value={inputValues.ac_name} onChange={handleInputs}  />
          <div className="errorMsg">{errors.ac_name}</div> 
      </div> */}
{/* 
      <label className="col-sm-5 col-form-label mb-2">Tanker Expense Head</label>
      <div className="col-sm-6 mb-2">
        <input type="text" className="form-control  w-90" 
         name="ac_name1" placeholder=""  value={inputValues.ac_name1} onChange={handleInputs}  />
           <div className="errorMsg">{errors.ac_name1}</div>
      </div> */}
    </div>
    
    <div class="text-center ">
              {/* <a class="btn btn-primary w-auto ">Save  Own Tanker Configuration</a> */}
          {double==true?    <button type="submit" class="btn btn-primary w-auto" onClick={handleSubmit} >Save  Own Tanker Configuration</button>:<button type="submit" class="btn btn-primary w-auto" disabled>Save  Own Tanker Configuration</button>}
         
          </div>
        </div>
        </div>
  
    </React.Fragment>
      );
    }
     export default OwnTankerModal;