import { Component } from "react";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg'
import LoginHeaderOperational from './LoginHeaderOperational';
import Operational_Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component";

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

const BalanceSheetOP = (props) => {
  let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
  const [inputValues, setInputValues] = useState({});
  const [double, setDouble] = useState(true);
  const [errors, setErrors] = useState({})
  const history = useHistory()
  const [state, setState] = useState([])
  const [all, setAll] = useState([])
  var s_buffer = ''; var buffer = ''; var s_flag = 0; var flag = 0;
  var profit_loss_amount = 0; var org_amount = 0; var profit_loss = ''; var dr_total = 0; var cr_total = 0;
  useEffect(() => {



  }, [])

  const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

    setDouble(true)
  };
  const handleSubmit = (event) => {
    setAll([])
    setDouble(false);
    if (validateForm()) {
      getBalanceSheet();
    }
  }

  function validateForm() {
    let errors = []
    let formIsValid = true
    if (!inputValues.op_bl_date) {
      formIsValid = false;
      setDouble(true)
      errors["op_bl_date"] = "Choose Date"
    }
    else {
      errors["op_bl_date"] = ""
    }



    setErrors(errors)

    return formIsValid;

  }

  async function getBalanceSheet() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify({
        op_bl_date: inputValues.op_bl_date,
        type: inputValues.type
      })
    };
    await fetch(API_URL + "/op-balance-sheet", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {

        responseData.all_entries.map((elem) => {
          setAll(all => [
            ...all,

            {
              ac_head: elem.title,
              spr_ac_head: elem.stitle,
              sub_head: elem.accounts_head,
              drtamount: elem.drtamount,
              crtamount: elem.crtamount

            }
          ])
        })

        setState(responseData)

        setDouble(true)
        return responseData;

      })
      .catch(e => {


        setDouble(true)
      });
  }



  var today = new Date().toISOString().split('T')[0];

  return (

    <>
      <div className="main-container page-wrap">
        <div className="d-flex">
          <Operational_Sidebar_Progress props={props} progress={4} />
          <div className="content-area">
            <LoginHeaderOperational props={props} page_title="" />
            {(permissions.includes("All Reports") || permissions.includes("Balance Sheet")) == false ?
	<div  className="form-wrap mt-5">
  <div className="text-center">
  You don't have permission to access this page
  </div></div>:
            <div className="container pb-3">
              <div className="row justify-content-center mt-md-5 mt-3 form-container">
                <div className="col-md-12 ">
                  <div className="row mb-3">'
                                          <div className="col-md d-flex">
                      <div className="text-start d-flex pe-1 mt-3">
                        <div className="form-group">
                          <label  >Date<sup class="star">*</sup></label>
                          <input type="date" className="form-control cal-date " placeholder="" name="op_bl_date" min={state.yesterday} max={today} value={inputValues.op_bl_date} onChange={handleInput}></input>
                          <div className="errorMsg">{errors.op_bl_date}</div>
                        </div>
                      </div>

                      <div className="text-start d-flex pe-1 mt-3">
                        <div className="form-group">
                          <label  >Type</label>
                          <select id="" class="form-control highlight" name="type" value={inputValues.type} onChange={handleInput}>
                            <option value="">Select</option>
                            <option value="2">Approved</option>
                            <option value="4">Day Closed</option>
                          </select>
                        </div>
                      </div>

                      <div className="text-start d-flex mt-4">
                        {double === true ? <a className="btn btn-primary mb-md-0 px-3 mb-3 mt-3 ms-2" onClick={handleSubmit}>Submit </a> : <a className="btn btn-primary mb-md-0 px-3 mb-3 mt-3 ms-2" >Submit </a>}

                      </div>
                    </div>
                    <div className="errorMsg">{errors.opdate}</div>
                    <div className="col-md">
                      <div className="text-end mt-4"></div>
                    </div>



                  </div>
                  <div className="form-wrap balace-sheetwrap">
                    <div className="row mb-3">
                      <div className="col-md ">
                        <h2 className="table-title  mb-1">{state.pump_name}</h2>
                        <h3 className="table-title mb-1">{state.pump_address}</h3>
                        <h4 className="table-title mb-1"> Balance Sheet</h4>
                        <h3>{  state.op_bl_date}</h3>

                      </div>

                    </div>

                    <div className="sheet-wrp">
                      <div className="row">
                        <div className="col-md-6">
                          {/* <h5 className="mb-0">ASSETS</h5> */}


                          <table className="table table-bordered">
                            <tr>
                              <th><h6 className="mb-0">ASSETS</h6></th>
                              <th className="text-end"><h6>Dr</h6></th>
                            </tr>
                            {all.map((account) => {

                              if (Number(account.drtamount) > Number(account.crtamount)) {
                                var Dramt = account.drtamount - account.crtamount;
                                var Cramt = '';
                                dr_total = Number(Dramt) + Number(dr_total);
                              }

                              if (buffer == account.ac_head) { flag = 1; }
                              else { flag = 0; }
                              if (account.ac_head != null && Dramt > 0) {
                                buffer = account.ac_head;
                              }

                              if (s_buffer == account.spr_ac_head) { s_flag = 1; }
                              else { s_flag = 0; }
                              if (account.spr_ac_head != null && Dramt > 0) {
                                s_buffer = account.spr_ac_head;
                              }
                              return (
                                <>
                                  {s_flag == 0 &&
                                    <>
                                      {account.spr_ac_head ?
                                        <tr><th colspan="2">{account.spr_ac_head}</th></tr> : ''}
                                    </>
                                  }
                                  {Dramt > 0 && flag == 0 &&
                                    <>
                                      {account.ac_head ?
                                        <tr><th colspan="2">{account.ac_head}</th></tr> : ''}
                                    </>
                                  }
                                  {account.sub_head && Dramt > 0 ?
                                    <tr>
                                      <td>{account.sub_head}</td>
                                      <td className="text-end">{Dramt != '' && Dramt > 0 ? numberFormat(Dramt) : ''}</td>
                                    </tr> : ''}
                                </>
                              )
                            })}
                          </table>

                        </div>
                        <div className="col-md-6">
                          {/* <h5 className="mb-0">LIABILITIES</h5> */}

                          <table className="table table-bordered">
                            <tr>
                              <th><h6 className="mb-0">LIABILITIES</h6></th>
                              <th className="text-end"><h6>Cr</h6></th>
                            </tr>

                            {all.map((account) => {

                              if (Number(account.crtamount) > Number(account.drtamount)) {

                                var Cramt = account.crtamount - account.drtamount;
                                cr_total = Number(Cramt) + Number(cr_total);
                              }

                              if (buffer == account.ac_head) { flag = 1; }
                              else { flag = 0; }
                              if (account.ac_head != null && Cramt > 0) {
                                buffer = account.ac_head;
                              }
                              if (s_buffer == account.spr_ac_head) { s_flag = 1; }
                              else { s_flag = 0; }
                              if (account.spr_ac_head != null && Cramt > 0) {
                                s_buffer = account.spr_ac_head;
                              }
                              return (
                                <>
                                  {s_flag == 0 &&
                                    <>
                                      {account.spr_ac_head ?
                                        <tr><th colspan="2">{account.spr_ac_head}</th></tr> : ''}
                                    </>
                                  }
                                  {Cramt > 0 && flag == 0 &&
                                    <>
                                      {account.ac_head ?
                                        <tr><th colspan="2">{account.ac_head}</th></tr> : ''}
                                    </>
                                  }
                                  {account.sub_head && Cramt > 0 ?
                                    <tr>
                                      <td>{account.sub_head}</td>
                                      <td className="text-end">{Cramt != '' && Cramt > 0 ? numberFormat(Cramt) : ''}</td>
                                    </tr> : ''}
                                </>
                              )
                            })}

                          </table>

                        </div>
                      </div>
                      <span className="sno">
                        <>
                          {Number(dr_total) == Number(cr_total) ?
                            (org_amount = parseFloat(dr_total).toFixed(2))
                            : ''}

                          {Number(dr_total) > Number(cr_total) ?
                            (profit_loss_amount = parseFloat(dr_total - cr_total).toFixed(2),
                              profit_loss = 'profit',
                              org_amount = parseFloat(dr_total).toFixed(2)
                            )
                            : ''}

                          {Number(cr_total) > Number(dr_total) ?
                            (profit_loss_amount = parseFloat(cr_total - dr_total).toFixed(2),
                              profit_loss = 'loss',
                              org_amount = parseFloat(cr_total).toFixed(2)
                            )
                            : ''}
                        </>
                      </span>
                      <div className="row">

                        <div className="col-md-6 ">
                          <table className="table table-bordered ">
                            {profit_loss === "loss" &&
                              <tr>
                                <th>Loss</th>
                                <th className="text-end">{numberFormat(profit_loss_amount)}/-</th>
                              </tr>
                            }
                          </table>
                        </div>
                        <div className="col-md-6">
                          <table className="table table-bordered ">
                            <tr>
                              {profit_loss === "profit" &&
                                <>
                                  <th>Owners Fund</th>
                                  <th className="text-end">{numberFormat(profit_loss_amount)}/-</th>
                                </>}
                            </tr>
                          </table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <table className="table table-bordered ">
                            <tr>
                              <th>Total</th>
                              <th className="text-end">{numberFormat(org_amount)}/-</th>
                            </tr>
                          </table>
                        </div>

                        <div className="col-md-6">
                          <table className="table table-bordered ">
                            <tr>
                              <th>Total</th>
                              <th className="text-end">{numberFormat(org_amount)}/-</th>
                            </tr>
                          </table>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <div className="col-md">
                                              <div className="text-end mt-4"><a  className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a> 
                                            <a className="btn btn-success px-3 mb-md-0 mb-3"  >Send Mail</a></div> 
                                          </div> */}
              </div>

            </div>

                                        }
          </div>
        </div>
      </div>
    </>
  )
}

export default BalanceSheetOP