import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
// import ExpenseConfig from './ExpenseConfig.component'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import ExpenseModal from './ExpenseModal.component';
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import ExpenseModal from './ExpenseModal.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Loader from '../Loader.component'
import ExpenseDeleteModal from './ExpenseDeleteModal.component'
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';
//Athulya ------------
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
//---------------------------------
const ExpModal = (props) => ReactDOM.createPortal(
	<ExpenseModal  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const DeleteModal = (props) => ReactDOM.createPortal(
	<ExpenseDeleteModal  {...props} />, document.body
);
const ExpenseConfig = (props) => {
	const [summary, setSummary] = useState([])
	const [double, setDouble] = useState(true);
	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [fields, setFields] = useState(['']);
	const [input, setInput] = useState({});
	const [input1, setInput1] = useState({});
	const [input2, setInput2] = useState({});
	const [selected_options, setSelectedOptions] = useState([])
	const history = useHistory()
	const [dropdownData, setDropdownData] = useState({})
	const [search, setSearch] = useState('')
	const [total, setTotal] = useState([])
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {
		check_url()

	}, []);
	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}



	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput1({ ...input1, op_date: responseData.op_date })
					getSummerySheet();
					ListExpenses(pages.current_page);
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function ListExpenses(page) {
		const requestOptions = {      //---employee listing---//
			method: 'get',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/payment-voucher?search_keyword=${search}&page=${page}&list_item=ev&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setLoading(true);
				setList(responseData.data.data)

				setInput2({ ...input2, isdayclose: responseData.isdayclose })
				setTotal({
					...total,
					totalamount: responseData.total_amount,
				})



				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	function getExpence() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				check_case: "EXPENSES",
			}),
		};
		fetch(API_URL + "/common-get-ac-category", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				/*	var datas = []
	
					responseData.forEach(elem => {
						if (elem != null) {
							datas.push([elem.id, elem.accounts_head])
						}
						else {
							datas.push([""])
						}
					})
	
					setDropdownData(datas)*/
				setDropdownData(responseData.group);

			})

			.catch(e => {
				console.log(e);

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListExpenses(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			getExpence();
			var isdayclose = input2.isdayclose;
			if (isdayclose == 1) {
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else {
				if (isShowing) {
					setInputValues({})
					setInput({})
					setErrors({})
					setSelectedOptions([])
					setSuccess({})
					setFields([''])
				}
				if (!isShowing) {
					setDouble(true)
					setErrors({})

				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//

	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	const [isOpen, setIsOpen] = useState(false);
	//---onchange functions---//
	const handleInputs = (e) => {

		convertAmountToWords(e, e.target.value)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});


	};


	const handleChange = (e) => {

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	const handleSelect = (items) => {
		console.log(items.value, "dgdfg")
		setInput({
			...input,
			ac_name_slug: items.value,
		});
		setSelectedOptions(items.value);



	};
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();

		setDouble(false);
		sendExpense()
	}

	//---Create EmployeeList---//
	function sendExpense() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				note: inputValues.note,
				amount: inputValues.amount,
				ac_name_slug: input.ac_name_slug,
				op_date: localStorage.getItem("op_date"),
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				voucher: "expense_voucher",
				expense_voucher: 1,
				id: inputValues.id

			})
		};
		fetch(API_URL + "/payment-voucher", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = {}
				if (responseData.status == false) {
					errors["amount"] = responseData.response.amount
					errors["note"] = responseData.response.note
					errors["ac_name_slug"] = responseData.response.ac_name_slug
					setDouble(true)

				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/expense")
					setErrors([''])
					inputValues.note = ""
					inputValues.amount = ""
					input.ac_name_slug = ""
					inputValues.id = ""
					setInputValues(inputValues)
					getSummerySheet()
					toggle();
					setDouble(true)
					ListExpenses(pages.current_page);


				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				swal("Something went wrong", "", "warning")
				console.log(e)

				setDouble(true)
			});
	}
	//---Edit employee list---//	
	function edit_Expense(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id
			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/payment-voucher/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInputValues({
					note: responseData.voucher_data.note,
					amount: responseData.voucher_data.amount,
					id: responseData.voucher_data.id ? responseData.voucher_data.id : ''
				})
				let product_names = []
				product_names.push({
					value: responseData.voucher_data.ac_name_slug,
					label: responseData.voucher_data.ac_name_slug_name,
				})
				setSelectedOptions(product_names)
				setInput({
					ac_name_slug: responseData.voucher_data.ac_name_slug
				})
				toggle();
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	//---Delete EmployeeList---//	
	function delete_Expense(id) {

		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}
	}

	function deleteExpense() {
		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/payment-voucher/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListExpenses(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				swal("Something went wrong", "", "warning")
				console.log(err)

			})
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div className="container pb-3 tab-view">
							<div className="row  mt-3 form-container">

								<div className="col-md-9">
									<TabHeader progress={12} />
									<div className="col-5">
										<div className="row">
											{Loading ? ListExpenses :
												// <div class="loader text-primary">Loading...</div>
												<Loader />
											}
										</div>
									</div>
									<div className="form-wrap ">

										<div className="tab-content" id="myTabContent">
											<div className="tab-pane fade show active" id="expense" role="tabpanel" aria-labelledby="expense-tab">
												<div className="">
													<div className="row mb-3">
														<div className="col-md">
															<div className="row">

																<div className="col-md col pl-0">
																	<h3 className="table-title">Expense </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#exp" onClick={toggle} >Add Expense</a>

														</div>
														<div className="col-md-4">
															<div className="input-group mb-3 search">
																<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
															</div>
														</div>
													</div>


													<div className="table-widget">
														<table className="table border">
															<thead>
																<tr>
																	<th>Expense Head</th>
																	<th>Description</th>
																	<th>Amount</th>
																	<th>Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(obj => (
																		<tr>
																			<td>{obj.ac_name}</td>
																			<td>{obj.note}</td>
																			<td>{obj.amount}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>
																			<td className="py-2"><a href="#" className="text-secondary" onClick={() => edit_Expense(obj.id)} ><i className="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																			{localStorage.getItem("save_hide") == 'false' ?
																				<a href="#" className="text-secondary" onClick={() => delete_Expense(obj.id)}><i className="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																			:''}
																			</td>
																		</tr>
																	))}
																<tr>
																	<td colSpan={2} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={3}>
																		{total.totalamount}
																	</td>
																</tr>
															</tbody>
														</table>
														{Loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}


													</div>

												</div>

											</div>

										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<ExpModal
				isShowing={isShowing}
				hide={toggle}
				dropdownData={dropdownData}
				handleInputs={handleInputs}
				inputValues={inputValues}
				handleSelect={handleSelect}
				fields={fields}
				success={success}
				double={double}
				selected_options={selected_options}
				preventNonNumericalInput={preventNonNumericalInput}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				input={input}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				errors={errors}
			/>) : null
			}
			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				deleteExpense={deleteExpense}
				double={double}
			/>) : null
			}

		</>

	)
}








export default ExpenseConfig