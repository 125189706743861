import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios'; 
import { API_URL } from "../constant/API_Settings";
import swal from "sweetalert";

const SetNewPasswordUser = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [cerror, setcError] = useState('');
  const [lerror, setlError] = useState('');
  const location = useLocation();

  const tokensplit = location.pathname.split("=");

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;

    if (newPassword == ""){
      setError('Passwords required');
    }else if (confirmPassword == ""){
      setcError('Confirm Passwords required');
      setError('');
      setlError('');
    }else if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setcError('');
      setlError('');
    } else if (!regex.test(newPassword)) {
      setlError(
        'Password should be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.'
      );
      setError('');
      setcError('');
    } else {
      // Passwords match and meet complexity requirements, perform further actions
      console.log('Password meets requirements');
      console.log(tokensplit[1],"kkkkkkkkkkkkkkk");
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    }

    var resetData = {
      password: newPassword,
      token: tokensplit[1],
    }

      try {
        // Call the POST API with the email as data
        await axios.post(API_URL + '/reset-password',resetData , { headers: headers })
        .then(res => {
            if (res.data.status == false) {
                swal("", res.data.message)
            }
            if (res.data.status == true) {
                
                swal({
                  title: "Password Updated!",
                  text: res.data.message,
                  type: "success"
              }).then(function() {
                  window.location = "/login";
              });

              //   window.setTimeout(function(){

              //     // Move to a new location or you can do something else
              //     window.location.href = "/login";
          
              // }, 5000);
                

                // window.location.href = "/successforgot";
            }

        }).catch(err => {

        })

        // Reset the form and error message
        //setEmail('');
      //  setErrorMessage('');
      } catch (error) {
        console.log('Error:', error);
        // Handle the API error if needed
      }
      setError(''); // Clear any previous errors
      setcError('');
      setlError('');
    }
    
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Card style={{ width: '400px' }}>
        <Card.Body>
          <Card.Title>Set New Password</Card.Title>
          {lerror && <Alert variant="danger">{lerror}</Alert>}
          {cerror && <Alert variant="danger">{cerror}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password: <span style={{ color: 'red' }}>*</span></Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  
                />
                <Button variant="light" onClick={handlePasswordVisibility}>
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </Button>
              </div>
              <div className="errorMsg">{error}</div>
            </Form.Group>
            <Form.Group controlId="confirmPassword" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Form.Label>Confirm Password: <span style={{ color: 'red' }}>*</span></Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  
                />
                <Button variant="light" onClick={handleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </Button>
              </div>
              <div className="errorMsg">{error}</div>
              <div className="errorMsg">{cerror}</div>
              
            </Form.Group>
            <div className="form-group">
              <Button className="btn btn-primary" type="submit" style={{height:40,fontSize:15}}>Change password</Button>
            </div>
          </Form>
          <div style={{marginTop:10, textAlign:'right'}}>
          <Link to="/login" style={{ textDecoration: 'none', fontSize: 15 }}>Sign in ? </Link>
          </div>
           
        </Card.Body>
      </Card>
    </div>
  );
};

export default SetNewPasswordUser;
