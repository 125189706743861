
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import {convertAmountToWords} from '../layouts/Sidebar_Progress.component'
import { preventNonNumericalInput } from '../layouts/Sidebar_Progress.component'
// import Loader from '../operational-model/Employee/Loader.component'
const OpenBalance = () => {
	const [double, setDouble] = useState(true);	

  const handleInputs = (e) => {
		setInputValues({
    
		   ...inputValues,
		   [e.target.name]: e.target.value
		 });
	   };

     const [errors,setErrors] = useState([ ])
   //loader
  //  const [Loading,setLoading]=useState(false);
  const handleSubmit = (event ) =>
  {
    event.preventDefault();
    setDouble(false)
       if(validateForm()){
   sendOpenBalance()
       }
  }
     function validateForm(){
      let errors = []
      let formIsValid = true
    
      if(!inputValues.cash_in_hand){
        formIsValid = false;
        setDouble(true)
        errors["cash_in_hand"]="Enter Opening Balance"
      }
      setErrors(errors)
   return formIsValid;
 }
 function setOpenBalance()
 {

if(inputValues.cash_in_hand == null)
{
 swal("Please Enter Opening Balance ")
}
else{
 history.push("/owntanker-config")
}
 }

  
     useEffect(() => {
   check_url()
     
   },[]) 


   ///amount----wording(07/06/2021{Athulya A(11:22 am)})


   const handleInput = (e) => {
		


   
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		
		});
    convertAmountToWords(e,e.target.value)
	};
  ///--------------------end-------------//

//------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
	
			if(responseData.allowurl == 1)
			{
				history.push(responseData.url);
        getOpenCash();
    
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
function check_meta_date() {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
//--------------------------//
const [permission, setPermission] = useState([])
const [checkoption, ckeckOption] = useState([])
const[input,setInput]=useState({})
   async function getOpenCash()
   {
     
       const requestOptions = {
       method: 'get',
       headers: {
         "Access-Control-Allow-Origin": "*",
         "Content-Type":"application/json",
         "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
         "Accept": "application/json"
     
        },
   };
    await fetch(API_URL+"/opening-cash",requestOptions)
           .then(response => { return response.json();})
   .then(responseData => {
    if(responseData.status==3) 
    {
      setPermission({...permission,status:responseData.status,message:responseData.message})
    }
    
     setInputValues({
       cash_in_hand :responseData.data.opening_cash})
       ckeckOption({...checkoption,
        isdayclose:responseData.isdayclose})

        var ob_is_dayclose = responseData.ob_is_dayclose;
        if(ob_is_dayclose==1)
        {
          setInput({
            ...input,
            ob_is_dayclose:ob_is_dayclose
          })
        }
    //  setLoading(true);
   return responseData;
     })
           .catch(e => {
               console.log(e);
              
           });
   }
     function sendOpenBalance()
     {
      var isdayclose = checkoption.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      var ob_is_dayclose = input.ob_is_dayclose;
      if(ob_is_dayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else{
         const requestOptions = {
         method: 'POST',
         headers: {
         "Access-Control-Allow-Origin": "*",
         "Content-Type":"application/json",
         "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
         "Accept": "application/json"
        },
          body: JSON.stringify(inputValues)
       };
       fetch(API_URL+"/opening-cash",requestOptions)
           .then(response => 
           { 
            return response.json();})
       .then(responseData => {
       swal("",responseData.message,responseData.status?"success":"warning")
      setDouble(true)
      //  setInputValues("")
   
        return responseData;
       })  .catch(e => {
             swal("Something went wrong","","warning")
             console.log(e); 
             setDouble(true)    
           });
          }
     }
     const history = useHistory()
	const [inputValues,setInputValues] = useState([]);
    return(
        <>
          <div className="main-container page-wrap">
<div className="d-md-flex">
<Sidebar_Progress />
<div className="content-area">
<LoginHeader page_title="" />
<div className="container pb-3">
	<div className="row justify-content-center  mt-3 form-container">
		<div className="col-md-7">
			
          <div class="row">
            
          {/* {Loading ? getOpenCash : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	} */}
             <div className="col-md-12 mb-3 px-4 "><Link to="/dealership-config">
               <a href="dealership-config" className="float-end btn btn-gray">Back</a>
               </Link></div>
          </div>

          {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
			<div className="form-wrap mt-md-3 ">

			

				<h2 >OPENING BALANCE - CASH</h2>
<div className="row mb-3 justify-content-center pt-3">
<label className="col-sm-4 col-form-label mb-2">Opening Cash Balance<sup class="star">*</sup></label>
    <div className="col-sm-7">
      <input type="number" className="form-control highlight mb-2 hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="cash_in_hand" value={inputValues["cash_in_hand"]}  onChange={handleInput} readOnly={input.isdayclose}/>
      <span  class="amount-words"></span>
      
      <div className="errorMsg">{errors.cash_in_hand}</div>
    </div>
    <div className="col-sm-11 text-end ">
     {/* <label className="col-form-label mb-2">Note: Debit Only</label> */}
     <div className="text-center ">
		
     {double==true? <button type="submit" class="btn btn-primary mt-3 w-auto px-4"   onClick={handleSubmit}>Save</button>:<button type="submit" class="btn btn-primary mt-3 w-auto px-4"   disabled>Save</button>}
		</div>
     </div>
      </div>
			</div>
    
		</div>
    </>}
    </div>
    <div className="col-md-7 text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={setOpenBalance}>Continue</a></div>
	
  
  </div>
</div>

</div>
</div>
</div>

</>
    )
}
    
export default OpenBalance