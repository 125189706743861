import { Component } from "react";
import notification_bell from '../../../img/bell.svg'
import avatar from '../../../img/avatar.svg'
import { Link } from "react-router-dom";
import { API_URL } from "../../../constant/API_Settings"
import { WEB_URL } from "../../../constant/API_Settings"
import downloadimage from '../../../img/download.png'
import moment from 'moment';
class ManagerLoginHeader extends Component {
  constructor(props) {
    super(props)

    this.state =
    {
      // inputValues={},
      op_date: '',
      'profile_name': localStorage.getItem("PROFILE_NAME"),
      // 'op_date': localStorage.getItem("op_date"),
      curTime: new Date().toLocaleString(),
      userId: '',
      permissions : localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(","),
      noti_count: '',
      set_notification: [],
      set_download: [],
      noti_count_state: ''
    }
    var time = this.state.curTime.split(',')
    var time1 = time[1]
    console.log(time1)
    localStorage.setItem('time', time1);

  }

  componentDidMount() {
    this.check_meta_date()  
    this.get_notification()
    setInterval(async () => {
      this.get_notification()
    }, 30000);

  }
  check_meta_date = () => {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        //	console.log(responseData.op_date)
        // if(!responseData.op_date )
        // {
        //   history.push("/nozzle-employee-list");
        // }
        // else{

        this.setState({ op_date: responseData.op_date, userId: responseData.userId, role_id: responseData.role_id, owner: responseData.owner })
        localStorage.setItem('m_op_date', responseData.op_date);
        // ListMLAcoupon(1)
        // }

        return responseData;
      })
      .catch(e => {
      });

  }

  approved_notification({ task_id }) {


    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify({
        task_id: task_id,


      })
    };
    fetch(API_URL + "/notifications_done", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
      })
      .catch(e => {
      });

  }

  get_notification = () => {

    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      }
    };
    fetch(API_URL + "/getnotification", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        this.setState({ set_notification: responseData?.data, noti_count: responseData?.noti_count, set_download: responseData?.data_statement, noti_count_state: responseData?.noti_count_statement })
        localStorage.setItem( "PERMISSIONS", responseData?.permissions || []);
        return responseData;
      })
      .catch(e => {
      });
  }

  render() {

    return (<div className=""><nav className="navbar navbar-expand-lg navbar-light bg-light top-menu ">
      <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="container-fluid">
        <h3 className="navbar-head" href="#">{this.props.page_title}</h3>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <ul className="navbar-nav ms-auto mb-1 mb-lg-1">
            <li className="nav-item  ">
              <p className="mt-3 me-3">

                {/* <i className="fa fa-clock-o me-2" aria-hidden="true"></i>  {localStorage.getItem("time")} */}

              </p>

            </li>
            {/* <li className="nav-item  ">

											<p className="mt-3 me-3"><i className="fa fa-calendar me-2" aria-hidden="true"></i>{this.state.op_date}</p>


										</li> */}
                    <li className="nav-item  ">
                      
<p className="mt-3 me-3"><i aria-hidden="true">Operational Date:</i>[{this.state.op_date?moment(this.state.op_date).format("DD-MM-YYYY"):""}]</p>


</li>
            <li className="nav-item dropdown notify">

              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={notification_bell} width="20px;" />
                <span className="badge bg-danger">{this.state.noti_count}</span>
              </a>
              <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
                <li><h4 className="notify-head">Notification</h4></li>
                {
                  this?.state?.set_notification?.map((config, index) => {

                    //                     return (
                    //                       <>
                    //                    <li>  <div className="dropdown-button"><div className="dropdown-item "  href={config.url} download><strong>{config.title}</strong><span>{config.note}</span><small>{config.created_at}</small></div></div>

                    //                         {(config.task_id && config.status == 1) ?
                    //                           <li><a className="btn btn-done ms-5" onClick={(task_id) => this.approved_notification({
                    //                             task_id: config.task_id
                    //                           })}>Done </a></li>
                    //                           : ''}
                    //                           </li>
                    // </>

                    //                     )
                    return (
                      <li><div className=" dropdown-item dropdown-button" ><a href={config.url} download><strong>{config.title}</strong><span>{config.note}</span><small>{config.created_at}</small></a>
                        {(config.task_id && config.status == 1) ?
                          <a className="btn btn-done ms-5" onClick={(task_id) => this.approved_notification({
                            task_id: config.task_id
                          })}>Done </a>
                          : ''}</div>
                      </li>
                    )

                  })
                }
                <li><a className="dropdown-item text-center notify-all" href="all_notifications/1">show all notifications</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown notify">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src={downloadimage} width="20px;" />
                <span className="badge bg-danger">{this.state.noti_count_state}</span>
              </a>
              <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
                <li><h4 className="notify-head">Notification</h4></li>

                {
                  this?.state?.set_download?.map((config, index) => {

                    return (
                      <li><a className="dropdown-item" href={config.url} download><strong>{config.title}</strong><span>{config.note}</span><small>{config.created_at}</small></a>

                        {(config.task_id && config.status == 1) ?
                          <li><a className="btn btn-done ms-5" onClick={(task_id) => this.approved_notification({
                            task_id: config.task_id
                          })}>Done </a></li>
                          : ''}

                      </li>
                    )

                  })
                }
                <li><a className="dropdown-item text-center notify-all" href="all_notifications/2">show all notifications</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle user-head" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img className="img-profile rounded-circle" src={avatar} />{this.state.profile_name}<span><i className="fa fa-angle-down font-weight-bolder" aria-hidden="true"></i></span>
              </a>

              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              
                {/* {this.state.permissions.includes("All Reports") == true ?
                  <li><a class="dropdown-item" href="#"><i class="fa fa-wrench me-2" aria-hidden="true"></i>Report &raquo; </a>
                    <ul class="submenu dropdown-menu">
                      <li><a class="dropdown-item" rel="noreferrer" target="_blank" href={`${WEB_URL}/other-report/${this.state.userId}/daily-transaction-sheet`}>Daily Transaction</a></li>
                      {this.state.owner == "owner" ?
                        <li><a class="dropdown-item" rel="noreferrer" target="_blank" href={`${WEB_URL}/other-report/${this.state.userId}/stock-gain-loss`}>Stock Gain/Loss</a></li>
                        : ''}
                       <li><Link to="op-simplified-balance-sheet"><a class="dropdown-item" href="#">Balance sheet Summary </a></Link>
                       
                      </li>
                      <li><Link to="/op-worksheet"><a class="dropdown-item" href="#">WorkSheet</a></Link></li>
                     
                      <li><a class="dropdown-item" rel="noreferrer" target="_blank" href={`${WEB_URL}/other-report/${this.state.userId}/daily-transaction-sheet`}>Reports</a></li>
                    </ul>
                  </li>
                  : ''}
              
                <li><hr className="dropdown-divider" /></li> */}
                <li><Link className="dropdown-item" to="/logout"><i class="fa fa-sign-out me-2" aria-hidden="true"></i>Logout</Link></li>
              </ul>
            </li>

          </ul>

        </div>
      </div>
    </nav>
    </div>)
  }

}

export default ManagerLoginHeader