import { Component } from "react";
import {Navigate } from 'react-router-dom'


class Logout extends Component
{
    constructor(props)
    {
        super(props)

        try {
        localStorage.clear()
        }
        catch(e) {

        }
       window.location.href = '/';
    }
    render()
    {
        return (<></>)
    }
}

export default Logout


