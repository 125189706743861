
import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from 'axios'
import FuelUnloadingDensityConfig from './FuelUnloadingDensityConfig.component'
import FuelUnloadingConfig1Modal2 from './FuelUnloadingModal2.component';
import DecantingMSConfig from './DecantingMSConfig.component';
import { convertAmountToWords, preventNonNumericalInput } from "../../../layouts/Operational_Sidebar_Progress.component";




const FuelUnloadingConfig2 = (unloading) => {

	const [delete_id, deleteId] = useState([])
	const [errors, setErrors] = useState([])
	const [err, setErr] = useState([])
	const [er, setEr] = useState([])
	const history = useHistory()
	const [unload_product, setUnloadProductId] = useState([])
	const [inputValues, setInputValues] = useState([]);
	const [fuel_config, SetFuel] = useState([])
	const [data_list, setData] = useState([])
	const [currentPrice, setCurrentPrice] = useState([])
	const [fuel_configs, SetFuelType] = useState([])
	const [dropDown, setDropdown] = useState([])
	const [fuelData, setFuelData] = useState([])
	const [sellingData, setSellingData] = useState([])
	const [fields, setFields] = useState(['']);
	const [list_data, setList] = useState([])
	const [version, setVersion] = useState([])

	const [fuel, SetNonFuel] = useState([])



	const [success, setSuccess] = useState([])

	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues([''])
				setErrors([''])
				setErr([])
				setEr([])
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors([''])

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,

		}


	};
	useEffect(() => {

		// ListDealership();
		getFuel();

	}, []);

	const { isShowing, toggle } = UseModal();


	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}

	const [search, setSearch] = useState('')
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}



	const [handleText, setHandleText] = useState("")


	const [ChangeHandler, setChangeHandler] = useState(false)
	const inputChangeHandler = (e) => {


		setChangeHandler(true)
		// }
	}



	const handleInput = (i, e) => {


		console.log(e.target.value)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});
	};


	//---------------Total PUrchase Price--------------//

	const handleTotal = (idx, e) => {
		let product_list = []
		let product = []
		// let product_id = []
		product_list = [...fuel_config]
		if (product_list[idx]) {
			var currentValue = 0
			for (var i = 0; i < product_list.length; i++) {
				var multiple = document.getElementById("amount" + i).value
				currentValue += Number(multiple)
				console.log(currentValue)
				var quantity = document.getElementById("quantity" + i).value
				product.push({
					invoice_quantity: quantity,
					invoice_amount: multiple,

				})

			}
		}
		var litre_quantity = document.getElementById("quantity" + idx).value * 1000
		console.log(litre_quantity)
		var current_price = document.getElementById("amount" + idx).value / litre_quantity
		console.log(current_price)
		console.log(current_price.toFixed(2))
		data_list[idx] = current_price.toFixed(2)
		setData(data_list)
		const nt_amnt = document.getElementById("total")
		var current = currentValue.toFixed(2)
		console.log(current)
		const rounded = Math.round(current);
		console.log(typeof (current))
		if (current % 1 != 0) {
			console.log(rounded)

			var dc
			if (rounded > current) {
				var d = rounded - current
				console.log(d)
				var dec = d.toFixed(2)

				dc = "+" + dec
			}
			else {
				var d = current - rounded
				console.log(d)
				var dec = d.toFixed(2)

				dc = "-" + dec
			}

		}
		else {
			dc = 0
		}
		const r = document.getElementById("round_off")
		r.value = dc

		const a = document.getElementById("net_amount")
		console.log(dc)
		a.value = rounded

		let invoice_amount
		setInputValues({
			...inputValues,
			// [e.target.name]: e.target.value,
			total: current,
			round_off: dc,
			net_amount: rounded,
			product: product,


		});



	}
	function getCurrentPrice() {
		var current_price_data = []
		current_price_data = inputValues.current

		axios(
			{
				url: API_URL + '/convert-price',
				method: "post",
				data: {
					current_price_data
				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},

			}
		).then(res => {
			let fuelConfigData = [];

		}).catch(error => {
			console.log(error)
		})



	}
	const handleRoundOff = (e) => {

	}

	//




	//-------------end-------------------//

	function toggleReadOnly(index, e) {

		let fuelConfigsData = fuel_config;
		if (fuelConfigsData[index].isReadonly == false) {
			document.getElementById("amount" + index).value = ""
			document.getElementById("quantity" + index).value = ""
			if (inputValues.product) {
				if (inputValues.product[index].invoice_amount != "") {
					inputValues.product[index].invoice_amount = ""
				}
				if (inputValues.product[index].invoice_quantity != "") {
					inputValues.product[index].invoice_quantity = ""
				}
				inputValues.product_id[index] = ""
				setInputValues(inputValues)
				let product_list = []
				product_list = [...fuel_config]
				if (product_list[index]) {
					var currentValue = 0
					console.log(inputValues.product)
					for (var i = 0; i < inputValues.product.length; i++) {

						console.log(inputValues.product[i])
						var multiple = inputValues.product[i].invoice_amount
						console.log(multiple)
						currentValue += Number(multiple)
						console.log(currentValue)
						var quantity = inputValues.product[i].invoice_quantity


					}
				}
				console.log(document.getElementById("quantity" + index).value)
				var litre_quantity = document.getElementById("quantity" + index).value != "" ? document.getElementById("quantity" + index).value * 1000 : 0
				console.log(currentValue)
				var current_price = document.getElementById("amount" + index).value ? document.getElementById("amount" + index).value / litre_quantity : 0
				console.log(current_price)
				console.log(current_price.toFixed(2))
				data_list[index] = current_price.toFixed(2)
				setData(data_list)
				const nt_amnt = document.getElementById("total")

				var current = currentValue.toFixed(2)
				nt_amnt.value = current

				console.log(current)
				inputValues.total = current
				setInputValues(inputValues)
				const rounded = Math.round(current);
				console.log(typeof (current))
				if (current % 1 != 0) {
					var dc
					if (rounded > current) {
						var d = rounded - current
						console.log(d)
						var dec = d.toFixed(2)

						dc = "+" + dec
					}
					else {
						var d = current - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = "-" + dec
					}
					const r = document.getElementById("round_off")
					r.value = dc

					const a = document.getElementById("net_amount")
					console.log(dc)
					a.value = rounded
				}
				else {
					dc = 0
				}
				let invoice_amount
				inputValues.round_off = dc
				inputValues.net_amount = rounded
				setInputValues(inputValues);

			}
		}
		else {
			fuelConfigsData[index].no_of_tank = null
			inputValues[e.target.name] = { ...inputValues[e.target.name], [index]: e.target.value };
			setInputValues(inputValues)
		}
		let fuelConfigData = fuelConfigsData[index];
		fuelConfigData.isReadonly = !fuelConfigData.isReadonly;
		fuelConfigData.isRequired = !fuelConfigData.isRequired;
		fuelConfigsData[index] = fuelConfigData;
		console.log(fuelConfigsData)
		SetFuelType({ ...fuel_configs, fuelConfigsData });
		console.log(fuel_configs)
		console.log(inputValues)
		setFuelData(inputValues)
	}



	function getFuel() {
		const unloading_id = unloading.unloading
		axios(
			{
				url: API_URL + '/unload-list',
				method: "POST",
				data: {
					unloading_id: unloading_id
				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},

			}
		).then(res => {
			console.log(res.data)
			let fuelConfigData = [];
			res.data.fuel_type.map(fuel_data => {
				console.log(fuel_data.price_per_litre)
				fuelConfigData.push({
					fuel_name: fuel_data.fuel_name,
					id: fuel_data.fuel_id,
					price_per_litre: fuel_data.price_per_litre,
					no_of_tank: null,
					isReadonly: true,
					isRequired: false,
				});

			})
			setVersion(res.data.full_version)
			// setUnloading(res.data.unloading_id)
			SetFuel(fuelConfigData)
			console.log(fuel_config)

		}).catch(error => {
			console.log(error)
		})
	}




	const handleInputs = (e, index, prop) => {

		if (e.target.name == "fuel_type") {
			if (e.target.checked) {
				if (inputValues[prop] === undefined) {
					inputValues[prop] = [];
				}
				inputValues[prop][index] = e.target.value;
			}
			else {

				inputValues[prop].splice(index, 1);
			}
		}
		else {
			inputValues[prop] = { ...inputValues[prop], [index]: e.target.value };
		}


		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,



		});


		//  setLoading(true)

	}

	//------------------------send data-------------------/

	const [showFuelTanker1, setShowFuelTanker1] = useState(false)
	const [showFuelTanker, setShowFuelTanker] = useState(false)
	const handleSubmit = (event, index) => {
		event.preventDefault();
		setInputValues({
			...inputValues,

		});
		if (validateForm()) {
			sendData()
		}
	}
	function validateForm() {
		let err = []
		let er = []
		let formIsValid = true
		let fuelConfigsData = fuel_config

		for (var i = 0; i < fuelConfigsData.length; i++) {

			if (fuelConfigsData[i].isReadonly == false) {
				if (fuelConfigsData[i].isReadonly == false && document.getElementById("quantity" + i)?.value == "") {
					formIsValid = false;
					console.log("sf")
					err[i] = "Invoice quantity is required"
				}
				else {
					err[i] = ""
				}
			}
			if (fuelConfigsData[i].isReadonly == false) {
				if (fuelConfigsData[i].isReadonly == false && document.getElementById("amount" + i)?.value == "") {
					formIsValid = false;
					er[i] = "Invoice amount is required"
				}
				else {
					er[i] = ""
				}
			}

		}
		setErr(err)
		setEr(er)

		return formIsValid;
	}
	//------------------------------end----------------//



	///---------------new------------price//

	function getPrice(id, e) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: id,
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};


		fetch(API_URL + "/unload-list", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setInputValues({ ...inputValues, responseData })

				return responseData;
			})
			.catch(e => {


			});
	}




	//--------------send Reqst(07/06/2021) -------------//

	function sendData() {

		const product = inputValues.product
		console.log(inputValues)

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({


				product,
				net_amount: inputValues.net_amount,
				unloading_id: unloading.unloading,
				round_off: inputValues.round_off,
				total: inputValues.total,
				product_id: inputValues.product_id,

			})
		};
		fetch(API_URL + "/unload-amount", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					// setUnloadProductId(responseData.unload_product_id)
					history.push("/op-fuel-unloading")
					setErrors([''])
					if (version == "No") {
						setShowFuelTanker(true)
					}
					else {
						setShowFuelTanker1(true)
					}
					// toggle();


				}
				let errors = []
				if (responseData.status == false) {
					errors["total"] = responseData.response.total
					errors["round_off"] = responseData.response.round_off
					errors["net_amount"] = responseData.response.net_amount

				}
				if (responseData.status == 3) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
				}

				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}



	//------------------------------------------//


	return (
		<>

			<h5 className="modal-title" id="exampleModalLabel">Fuel Other Tanker</h5>

			<div className="row mb-3 ">


				{console.log(fuel_config)}
				{
					fuel_config.map((fuelType, idx) => {

						console.log(fuelType.price_per_litre)
						return (<>
							<div className="col-md-4">
								<div className="form-check " >
									<input className="form-check-input" type="checkbox" value={fuelType.id} name="product_id" id={"product_id" + idx} onClick={(event) => { toggleReadOnly(idx, event) }} />
									<label className="form-check-label " htmlFor="flexCheckDefault">{fuelType.fuel_name}</label>
								</div>

								<div className="form-group mb-3 "><label>Invoice Quantity in KL. </label>

									<input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className={`form-control hide-arrow check${fuelType.id}`} name="invoice_quantity" id={"quantity" + idx} onChange={(event) => handleTotal(idx, event)} readOnly={fuelType.isReadonly}></input>
									<div className="errorMsg">{err[idx]}</div>
								</div>

								<div className="form-group mb-3 "><label>Product Wise Invoice Amount </label>
									<input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow amount" onClick={inputChangeHandler} id={"amount" + idx} name="invoice_amount" onChange={(event) => handleTotal(idx, event)} readOnly={fuelType.isReadonly}></input>
									<div className="errorMsg">{er[idx]}</div>
								</div>

								{version == "No" ? '' : ChangeHandler ? <> <div className="form-group mb-3 ">
									<label>Previous Invoice purchase price/Litre : </label>
									{fuelType.isReadonly == true ? <input type="text" className="form-control highlight" readOnly /> : <input type="text" className="form-control highlight" value={fuelType.price_per_litre} readOnly />}{" "}

								</div>
									{console.log(data_list)}
									<div className="form-group mb-3 ">
										<label>current Invoice purchase price/Litre:  </label><input type="text" name="price" className="form-control " value={data_list[idx]} readOnly />{" "}

									</div></> : null}
							</div></>
						)
					})}</div>

			<div className="row ">

				<div className="col-md-4">
					<div className="form-group mb-3 ">
						<label>Total <sub className="star">*</sub></label>

						<input type="text" className="form-control " readOnly id={"total"} name="total" value={inputValues.total} ></input>
						<div className="errorMsg">{errors.total}</div>
					</div>

				</div>
				<div class="col-md-4">
					<div class="form-group mb-3 ">
						<label>Round off<sub className="star">*</sub></label>
						<input type="text" className="form-control " name="round_off" id={"round_off"} readOnly value={inputValues.round_off} onChange={(e) => handleRoundOff(e)}></input>
						<div className="errorMsg">{errors.round_off}</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="form-group mb-3 ">
						<label>Net Amount<sub className="star">*</sub> </label>

						<input type="text" className="form-control  " name="net_amount" id={"net_amount"} readOnly value={inputValues.net_amount} onChange={(e) => handleRoundOff(e)}></input>
						<div className="errorMsg">{errors.net_amount}</div>
					</div>
				</div>




			</div>

			<div className="col-md-10">


			</div>

			<div className="row">

				<div className="container pb-3">



					<div className="modal-footer justify-content-end p-0 "><a className="btn btn-primary w-auto m-btn" onClick={handleSubmit}>save/Continue</a></div></div>
			</div>


			{/* </div> */}



			{version == "No" ?
				showFuelTanker ? <div>
					<DecantingMSConfig
						unloading={unloading}
						productid={fuelData}
					/>

				</div> : null : showFuelTanker1 ? <div>
					<FuelUnloadingDensityConfig
						unloading={unloading}
						productid={fuelData}
					/>

				</div> : null}



		</>
	)
}








export default FuelUnloadingConfig2