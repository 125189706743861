import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const ClosePaymentModal = ({ preventNonNumericalInput,prevNonNumericalWithDec,isShowing, hide,double, input, setVehicle, errors, success, inputValues, handleAdd, handleRemove, denm, handleChange, denomination, fields, dropdownData, handleSubmit,handleInput, handleInputs }) => {

    return (
        <React.Fragment>

            <div className="modal-overlay" />
            <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                <div className="modal modal-dialog-xl modalxl">

                    <div className="modal-header mb-3">
                        <h5 className=" text-center" id="staticBackdropLabel">Closing Cash</h5>
                        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className="">
                            <div className="row g-3 justify-content-center  mb-2">


                            </div>
                            {input.ref_no &&
                                <div className="row g-3 justify-content-center mb-2">
                                    <div className="col-5">
                                        <label className="col-form-label">Refernce Number(default)</label>
                                    </div>
                                    <div className="col-5">
                                        <input type="text" className="form-control highlight" name="ref_no" value={input.ref_no} onChange={handleInputs}></input>

                                    </div>

                                </div>}

                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-5">
                                    <label className="col-form-label font-weight-bolder">Description  </label>
                                </div>
                                <div className="col-5">
                                    <input type="text" className="form-control highlight" name="note"  id="description" value={input.note} onChange={handleInputs}></input>
                                </div>

                            </div>




                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-5">
                                    <label className="col-form-label font-weight-bolder" >Amount <sub className="star">*</sub>  </label>
                                </div>
                                <div className="col-5">
                                    <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className="form-control highlight hide-arrow" name="amount" id="amount" value={input.amount} onChange={handleInput}></input>
                                    <div className="errorMsg">{errors.amount}</div>
<span  class="amount-words"></span>
                                </div>

                            </div>



                            <div className="row g-3 justify-content-center mb-2">
                                <div className="col-6">
                                    <div className="accordion denomination mt-3" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button btn-success collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Denominationss
                                                                      </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                                data-bs-parent="#accordionExample" >
                                                <div class="accordion-body">
                                                    <div class="table-widget">
                                                        <table class="table border-0">
                                                            <tbody>
                                                                {denm.map((obj, idx) => {
                                                                    return (


                                                                        <tr>

                                                                            <td ><input type="text" class="form-control highlight elem" name="elem" value={obj.amount} id={"amount" + idx} onChange={event => { handleChange(event, idx) }} /></td>
                                                                            <td>*</td>
                                                                            <td><input type="number" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} min="0" class="form-control highlight hide-arrow" name="change" value={denomination[idx] ? denomination[idx]['count'] : ''} id={"change" + idx} onChange={event => { handleChange(event, idx) }} /></td>
                                                                            <td>=</td>
                                                                            <td width="110px"><strong><input type="text" readOnly class="form-control highlight" name="multiple" id={"multiple" + idx} value={denomination[idx] ? denomination[idx]['multiple'] : ''} onChange={event => { handleChange(event, idx) }} /></strong></td>

                                                                        </tr>
                                                                    )
                                                                })
                                                                }
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>Total</td>

                                                                    <td></td>
                                                                    <td><input type="label" className="form-control highlight" name="amount" id="amountww" ></input></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                                <div class="col-6">
                                    {/* <button class="btn btn-success    w-100 mt-3  "> Print<i class="fa  fa-long-arrow-right float-end" aria-hidden="true"></i></button> */}

                                </div>
                            </div>
                          {localStorage.getItem("save_hide") == 'false' ?
                            <div className="modal-footer text-center">

                                <a className="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
                                { double== true?  <a className="btn btn-primary px-4 w-auto" type="submit" onClick={handleSubmit} >SAVE</a>:  <a className="btn btn-primary px-4 w-auto" type="submit" disabled >SAVE</a>}
                            </div>
                            :''}
                        </div>
                    </div>
                </div>
            </div>


        </React.Fragment>

    );
}
export default ClosePaymentModal 