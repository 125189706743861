import React from 'react';
import ReactDOM from 'react-dom';

const AdminDashBoardModal = ({ isClose,hide,tankdata}) => {
    return (
        <React.Fragment>
            <div className="modal-overlay" />
                <div className="modal-wrapper" >
        <div className="modal modal-dialog">
      
            <div class="modal-content">
            <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Tank Details</h5><button type="button" onClick={hide} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
            <div class="table-widget">
                                          <table class="table border">
                                             <thead>
                                                <tr>
                                                   <th> Tank Name</th>
                                                   <th>Capacity</th>
                                                   <th>Current Stock </th>
                                                 
                                                  
                                                </tr>
                                             </thead>
                                             <tbody>
                                             
                                             <tr>
                                             <td> {tankdata.tank_name}</td>
                                                   <td>{tankdata.capacity}</td>
                                                   <td>{tankdata.current_stock}</td>
                                                  
                                                  
                                                </tr>
                                             
                                             </tbody>
                                          </table>
                                       </div>
				</div>
                </div>
</div>


       </React.Fragment>

    );
}
export default AdminDashBoardModal