
import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import FuelUnModal from './FuelUnModal.component';
 const FuelUndeModal = (props) => ReactDOM.createPortal(
	<FuelUnModal  {...props} />, document.body
		 );
	
const FuelUnConfig = (props) => {   
	const[delete_id ,deleteId] = useState([]) 
	
	const [inputValues,setInputValues] = useState({vehicle_no: ['']});
	const [fields,setFields] = useState(['']);
	const history = useHistory()  
	const [list_data,setList] = useState([]) 
	const [inputList,setInputList] = useState([])
	
	const [errors,setErrors] = useState({ vehicle_no:['']})
	const [success,setSuccess ] = useState([]) 
	const UseModal = () => {
 
		const [isShowing, setIsShowing] = useState(false);
	  
		function toggle() {
			if(isShowing) 
			{
			   setInputValues({vehicle_no: ['']})
			   setErrors({vehicle_no: ['']})
			   setSuccess({})
			   setFields([''])
		   }
		   if(!isShowing) 
			{
			  
			   setErrors({vehicle_no: ['']})
			 
		   }
		  setIsShowing(!isShowing);
		}
	  
		return {
		  isShowing,
		  toggle,
		}
		
	  };


	  const {isShowing, toggle} = UseModal();
	  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }

	  const[showFuelTanker,setShowFuelTanker]=useState(false)
	  const  Example=(e)=>
	   {
		console.log(e.target.checked)
		setInputValues({
		   ...inputValues,
		  [e.target.name]: e.target.checked?1:0
	  });

		setShowFuelTanker(true)
	   }
	   const[showFuelTanker1,setShowFuelTanker1]=useState(false)
	   const  Example1=(e)=>
		{
		 console.log(e.target.checked)
		 setInputValues({
			...inputValues,
		   [e.target.name]: e.target.checked?1:0
	   });
 
		 setShowFuelTanker1(true)
		}
		const[showFuelTanker2,setShowFuelTanker2]=useState(false)
		const  Example2=(e)=>
		 {
		  console.log(e.target.checked)
		  setInputValues({
			 ...inputValues,
			[e.target.name]: e.target.checked?1:0
		});
  
		  setShowFuelTanker2(true)
		 }
		 const[showFuelTanker3,setShowFuelTanker3]=useState(false)
		 const  Example3=(e)=>
		  {
		   console.log(e.target.checked)
		   setInputValues({
			  ...inputValues,
			 [e.target.name]: e.target.checked?1:0
		 });
   
		   setShowFuelTanker3(true)
		  }
		  const[showFuelTanker4,setShowFuelTanker4]=useState(false)
		  const  Example4=(e)=>
		   {
			console.log(e.target.checked)
			setInputValues({
			   ...inputValues,
			  [e.target.name]: e.target.checked?1:0
		  });
	
			setShowFuelTanker4(true)
		   }
		   
	  const handleInputs = (e) => {
	
		
		if((e.target.name ==="credit_period"  || e.target.name === "credit_amount" ))
		{
		  console.log(e.target.checked)
		  setInputValues({
			 ...inputValues,
			[e.target.name]: e.target.checked?1:0
		});
		}
	  
		else
		setInputValues({
		   ...inputValues,
		   [e.target.name]: e.target.value
		 });

	   };

	   
     function handleChange(i, event) {
      const values = [...fields];
      values[i] = event.target.value;
      setFields(values);
      setInputValues({...inputValues, vehicle_no: values})
      }
 	 
	   function handleAdd() {
		const values = [...fields];
		
    values.push( '' );
    setFields(values);
		setErrors({ vehicle_no:['']})
	  }
	  function handleRemove(i) {
		  console.log(fields)
		  var i = fields.length-1
		  if(i!=0)
		  {
			const values = [...fields];
		
			values.splice(i, 1);
			console.log(values)
			setFields(values);
			setInputValues({...inputValues, vehicle_no: values})
		  }
	
    }
	


	 
	   const handleSubmit = (event ) =>
   {
     event.preventDefault();
    
      if(validateForm())
    sendCustomerData()
   
   }
   function	validateForm() {
	let errors = []
	  let formIsValid = true
	  console.log(inputValues)
	  if (!inputValues.name) {
		  formIsValid = false;
		  errors["name"] = "Enter  Customer Name"
	  }
	  else{
		errors["name"] =""
	  }
	  console.log(fields)

	  
	  fields.map((inpuvalue,idx) => {
		  console.log(inpuvalue)
	  if ( !inpuvalue ) {
		// formIsValid = false;
		// // errors[idx]["vehicle_no"]="Enter Vehicle No"
		// errors["vehicle_no"] ="Enter Vehicle no"
		// console.log(errors)

	}
	else{
		errors["vehicle_no"] = ""
	  }

	})

	  if(inputValues.opening_balance !=undefined){
	  if((inputValues.opening_balance !="" ) && (inputValues.debit_credit == undefined || inputValues.debit_credit === "" ))
	  {
		formIsValid = false;
		errors["debit_credit"] = " Select Debit/Credit" 
	  }
	 
	}
	if((inputValues.debit_credit ) && (!inputValues.opening_balance)){
		formIsValid = false;
		errors["debit_credit"]="Enter Opening Balance"
	  }
	
	  if (inputValues.credit_amount === 1 && !inputValues.credit_limit) {

		formIsValid = false;
		errors["credit_limit"] = " Enter Credit Amount"
  
  
	  }
	
	
	  if((inputValues.credit_period === 1 ) && (!inputValues.credit_limit_period) ){
	
		formIsValid = false;
		errors["credit_limit_period"] = " Enter Credit Limit period" 

	 
	}
	if((inputValues.credit_limit_period=="no_of_days" && !inputValues.credit_days)){
	
		formIsValid = false;
		errors["credit_days"] = " Enter Credit Days" 

	 
	}
	// else{
	// 	errors["credit_limit_period"] =""
	// }
	
	// console.log(inputValues.credit_limit_period)
	// if((inputValues.credit_period === 1 ) && (inputValues.credit_limit_period === undefined) ){
	
	// 	formIsValid = false;
	// 	errors["credit_limit_period"] = " Enter Credit Limit period" 

	 
	// }

	// if((inputValues.credit_period === 1 ) && (inputValues.credit_limit_period === "no_of_days")  && (!inputValues.no_of_days)){
	
	// 	formIsValid = false;
	// 	errors["credit_limit_period"] = " Enter Credit Limit period" 

	 
	// }
	// else{
	// 	errors["credit_limit_period"] = ""
	// }
	
	setErrors(errors)
     
	  return formIsValid;
  
  
  }


  const [search,setSearch]=useState('')
  const  handleSearch = (event) =>
  {
	 setSearch(event.target.value)
  }

 
  useEffect(()=>{
	ListCustomer(pages.current_page);
  },[search]); 

 
  const [pages,setpages]=useState(
	{
	current_page:1,
	last_page:0,
	total_pages:0,
	pagination_item:[],
	total_pages:0,
})

const handlePagination = (number) =>
    {
        setpages({
          ...pages,
          current_page:number
        }
            )
  
            ListCustomer(number)
  
    }



 function setVehicle()
 {
         const requestOptions = {
         method: 'post',
         headers: {
           "Access-Control-Allow-Origin": "*",
           "Content-Type":"application/json",
           "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
           "Accept": "application/json"
       
          },
		  body: JSON.stringify(inputValues)
     };
  fetch(API_URL+"/check-vehicle-no",requestOptions)
             .then(response => { return response.json();})
     .then(responseData => {
		 console.log(responseData)
		 swal("",responseData.message)
    // setList(responseData) 
return responseData;
       })
             .catch(e => {
                 console.log(e);
                
             });
 }
 function ListCustomer(page)
 {
         const requestOptions = {
         method: 'get',
         headers: {
           "Access-Control-Allow-Origin": "*",
           "Content-Type":"application/json",
           "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
           "Accept": "application/json"
       
          },
		 
     };

	
  fetch(`${API_URL}/customer?search_keyword=${search}&page=${page}` ,requestOptions)
             .then(response => { return response.json();})
     .then(responseData => {

    setList(responseData.data) 
	var total_pages = responseData.last_page
       console.log(responseData.last_page)
                 var items = []
                for (let number = 1; number <= total_pages; number++) {
                   number===page?items.push(
                      <li key={number} className="page-item active"><a className="page-link" onClick={()=>handlePagination(number)}>{number}</a></li>
                      ,):items.push(
                      <li key={number} className="page-item"><a className="page-link" onClick={()=>handlePagination(number)}>{number}</a></li>
                      ,)
                       }
    
                       setpages({
                        ...pages,
                    current_page:responseData.current_page,
                    last_page:responseData.last_page,
                    total_pages:responseData.last_page,
                    pagination_items:items
                   })

return responseData;
       })
             .catch(e => {
                 console.log(e);
                
             });
 }
 function edit_customer(id,ac_name)
 {
	setErrors([]) 
   console.log(id)
	   const requestOptions = {
	   method: 'get',  
	   data: {id:id,
		ac_name:ac_name

	  },    
	   headers: {
		 "Access-Control-Allow-Origin": "*",
		 "Content-Type":"application/json",
		 "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
		 "Accept": "application/json"
	 
		},  
   };
 fetch(API_URL+"/customer/"+id+"/edit",requestOptions)
		   .then(response => { return response.json();})
   .then(responseData => {
    let vehicledata = [];
    responseData.custom_vehicle.forEach((elem) => {	
      console.log(elem)
      vehicledata.push(elem.vehicle_no);
  
    })
    if (vehicledata.length < 1)
    {
      vehicledata = [''];
    }
    setFields(vehicledata);
	setInputValues(
	 {
		...inputValues,
	   id:responseData.id ? responseData.id : '',
	   ac_name:responseData.ac_name ? responseData.ac_name : '',
	   name : responseData.name,
	   address : responseData.address,
	   phone : responseData.phone,
	   email : responseData.email,
	   credit_limit: responseData.credit_limit,
       vehicle_no : vehicledata,
	   whatsapp_no :responseData.whatsapp_no,
	   debit_credit: responseData.debit_credit,
	   credit_days :responseData.credit_limit_period,
	   credit_limit_period :isNaN(parseInt(responseData.credit_limit_period))==false ? 'no_of_days' : responseData.credit_limit_period ,
	   opening_balance :  responseData.opening_balance,
	   credit_amount: responseData.credit_limit === null ? 0 : 1,
	   credit_period: responseData.credit_limit_period === null ? 0 : 1,
	  
	 }
   );

 
		toggle();
		
 return responseData;
	 })
		   .catch(e => {
			   console.log(e);
			  
		   });
 }
 function delete_Customer(id)
 {
	 console.log(id)
   deleteId(id)
  
   toggleModal()
 }

 function deleteCustomer()
 {
	
	// console.log(...delete_id)
	const requestOptions = {
		method: 'DELETE',  
	
		headers: {
		  "Access-Control-Allow-Origin": "*",
		  "Content-Type":"application/json",
		  "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
		  "Accept": "application/json"
	  
		 }, 
		 body: JSON.stringify(delete_id)
	};
  fetch(API_URL+"/customer/"+delete_id,requestOptions)
			.then(response => { return response.json();})
			.then(responseData => {
				  swal("",responseData.message,responseData.status? "success":"warning")
				  ListCustomer(pages.current_page);
				  toggleModal()

			return responseData;
		
  
        })
	
		.catch(err=>{
           
        })
 }
  function sendCustomerData()
   {
	console.log(inputValues)
		   const requestOptions = {
		   method: 'POST',
		   headers: {
			 "Access-Control-Allow-Origin": "*",
			 "Content-Type":"application/json",
			 "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
			 "Accept": "application/json"
			},
			  body: JSON.stringify(inputValues)
	   };
	   fetch(API_URL+"/customer",requestOptions)
			   .then(response => 
				 { 
					return response.json();})
	   .then(responseData => {
		 console.log(responseData.status)
		 setSuccess({...success,
			disabled : responseData.status === true?true:false,
	   })
	   if(responseData.status == false)
        {
           errors["name"] = responseData.response.name 
		   errors["opening_balance"] = responseData.response.opening_balance
		   errors["phone"] = responseData.response.phone
           errors["whatsapp_no"] = responseData.response.whatsapp_no

		   errors["vehicle_no"] = responseData.response.vehicle_no
        }
	if(responseData.status == true)
	{
	   swal("",responseData.message,responseData.status? "success":"warning")
		 history.push("/customers")
		 setErrors ([''])
		 setInputValues([])
		 
		 toggle();
		  ListCustomer(pages.current_page);
		//   setErrors({})
		}
		setErrors(errors)
		  return responseData;
		 }) 
		  .catch(e => {
				   console.log(e);     
			   });
   }
  

   
  
  
  
  
  return(
        <>
<div className="main-container page-wrap">
<div className="d-md-flex">
<Sidebar_Progress props={props} progress={7}/>
   <div className="content-area">
   <LoginHeader page_title="" />


<div className="container pb-3">
<div class="row">
 <div className="col-md-12 px-4 mt-3">
<Link to="/users">
	<a href="users" className="float-end btn btn-gray">Back</a>
</Link></div></div>
	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
			

			<div className="form-wrap list-wrp">
			
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={customer} width="25px" className="img-fluid" alt=".."/>
						</div>
							<div className="col-md-7 col pl-0">
							<h3 className="table-title">Fuel unloading</h3>
						</div>
						
						
						</div>
					</div>
					<div className="col-md-4">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Fuel Unloading</a>
						</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Customer Name "  value={search.search_keyword} onChange={handleSearch} onKeyPress={(event) => {
    var key = event.keyCode || event.which;
    if (key === 13) {
        
    }
}}    />
</div>
					</div>
				</div>


				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Invoice Number</th>
      <th>Invoice Date</th>
      <th>Invoice Amount</th>
     
      <th>Action</th>
    </tr>
  </thead>
  {console.log(list_data)}

  <tbody>
  
   <tr>
   <td></td>
   <td></td>
   <td></td>
   
   <td><div className="d-flex icon-btn">
      <button onClick={()=> edit_customer(customer.id,customer.ac_name)} >  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>
      <button data-bs-toggle="tooltip" type="submit" data-bs-placement="bottom" title="Delete" onClick={()=> delete_Customer(customer.id)} >  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
   </tr>

</tbody>
</table>
<nav aria-label="Page navigation example">
             <ul className="pagination justify-content-end">
    {pages.total_pages>1 && pages.pagination_items}
  </ul>
</nav>
				</div>
			</div>
			<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/creditor-debitor")}>Skip/Continue</a></div>
		</div>
	</div>
</div>


</div>
</div>
</div>
{isShowing ? (<FuelUnModal
        isShowing={isShowing}
        hide={toggle}
		Example={Example}
		Example1={Example1}
		Example2={Example2}
		Example3={Example3}
		Example4={Example4}
	
        handleInputs={handleInputs}
        inputValues={inputValues}
		fields={fields}
		success={success}
		inputList={inputList}
		setVehicle={setVehicle}
		showFuelTanker={showFuelTanker}
		showFuelTanker1={showFuelTanker1}
		showFuelTanker2={showFuelTanker2}
		showFuelTanker3={showFuelTanker3}
		showFuelTanker4={showFuelTanker4}
	
        // dropdownData ={dropdownData}
        handleSubmit={handleSubmit}
		handleChange={handleChange}
		handleAdd={handleAdd}
	
		setInputList={setInputList}
		handleRemove ={handleRemove}
        errors={errors}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Customers</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this customer</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
					<button type="submit" class="btn btn-primary"  onClick={ deleteCustomer}>Yes</button>
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>	
        </>
        )
        }
        
        
         
          
          
          
            
        
 export default FuelUnConfig