import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import { Link } from "react-router-dom";
import Select from "react-select";
const ExpenseModal = ({ preventNonNumericalInput,prevNonNumericalWithDec,isShowing, hide, setVehicle, errors,selected_options, success,double, inputValues,input, handleAdd, handleRemove, handleChange,handleSelect, fields, dropdownData, handleSubmit, handleInputs }) => {
  const DropDown = ({data}) => (
    <select className="form-control highlight" name="ac_name_slug" onChange={handleSelect} value={input.ac_name_slug}>
      <option selected value="">Account Name</option>
      {
        data.map(({ac_cat, sub_cat}, key) => (
        <optgroup label={ac_cat} key={key}>
        {
          sub_cat.map(({id, sub_head}) => (
          <option value={id}>
            {sub_head}
          </option>
          ))
        }
        </optgroup>
      ))
      }
    </select>
    )

  return (
    <React.Fragment>

    
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modal-dialog-xl modalxl ht-modal">

          <div class="modal-header mb-3">
            <h5 className=" text-center" id="staticBackdropLabel">Expense</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <h3 className="table-title">Expense Voucher</h3>
            </div>

            <div className="row g-3 justify-content-center mb-2">

              <div className="col-6">
                <label className="col-form-label">Expense Head<sub className="star">*</sub>
                                        </label>
              </div>
              <div className="col-6">
                
                 <Fragment>
             <Select name="from_ac_name_slug" options={dropdownData} defaultValue={selected_options} onChange={handleSelect}/>
                                    </Fragment>
              
                  <div className="errorMsg">{errors.ac_name_slug}</div>
                  <Link to={location => ({ ...location, fields:{ fields: true }, pathname: "/expenses/expenses" })} class="link-text"><a href="/expenses/expenses">Redirect to Expense Head</a></Link>
              </div>

            </div>
            <div className="row g-3 justify-content-center mb-2">
              <div className="col-6">
                <label className="col-form-label font-weight-bolder">Description
                                        <sub className="star">*</sub>  </label>
              </div>
              <div className="col-6">
                <input type="text" class="form-control highlight" name="note" value={inputValues.note} onChange={handleChange}></input>
                <div className="errorMsg">{errors.note}</div>

              </div>

            </div>

            <div className="row g-3 justify-content-center mb-2">
              <div className="col-6">
                <label className="col-form-label font-weight-bolder">Amount <sub className="star">*</sub>  </label>
              </div>
              <div className="col-6">
                <input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control highlight hide-arrow" id={"amount"} name="amount" value={inputValues.amount} onChange={handleInputs} ></input>
                <div className="errorMsg">{errors.amount}</div>
                <span  class="amount-words"></span>
              </div>

            </div>
          <div class="modal-header mb-3"></div>
          
          {localStorage.getItem("save_hide") == 'false' ?
            <div className="modal-footer text-center">

              <a className="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
           {double== true?<a className="btn btn-primary px-4" type="submit"   onClick={handleSubmit}>SAVE</a>: <a className="btn btn-primary px-4" type="submit"  disabled>SAVE</a>}
            </div>
            :''}
          </div>
        </div>
      </div>


    </React.Fragment>

  );
}
export default ExpenseModal