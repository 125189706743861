import React from 'react';
import ReactDOM from 'react-dom';
 
const DashBoardModal = ({ isClose,hide,Bank_data,tankdata}) => {
   let cr_total =0;
   let dr_total =0;
    return (
        <React.Fragment>
            <div className="modal-overlay" />
                <div className="modal-wrapper" >
        <div className="modal modal-dialog">
      
            <div class="modal-content">
            <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Bank Details</h5><button type="button" onClick={hide} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
            <div class="table-widget">
                                          <table class="table border">
                                             <thead style={{ fontWeight: 'bold' }}>
                                                <tr>
                                                   <th>Bank Name</th>
                                                   <th>Credit</th>
                                                   <th>Debit</th>
                                                   <th>Outstanding</th>
                                                  
                                                </tr>
                                             </thead>
                                             <tbody>
                                                {console.log(Bank_data.data,"mmmmmm")}
                                                
                                             {Bank_data.data.map((obj, idx) => (
                                             
                                        

                                             <tr>
                                            <td hidden>{cr_total=cr_total+Number(obj.crtamount)}</td>
                                             <td hidden>{dr_total=dr_total+Number(obj.drtamount)}</td>
                                                   <td>{obj.accounts_head}</td>
                                                   <td>{obj.crtamount}</td>
                                                   <td>{obj.drtamount}</td>
                                                   <td>{obj.outstanding}</td>
                                                  
                                                  
                                                </tr>
                                              ))} 
                                              <tr>
                                              <td><h6>TOTAL</h6></td>
                                              <td><h6>{cr_total.toFixed(2)}</h6></td>
                                              <td><h6>{dr_total.toFixed(2)}</h6></td>
                                              <td><h6>{Bank_data.outstanding}</h6></td>
                                              </tr>
                                             </tbody>
                                          </table>
                                       </div>
				</div>
                </div>
</div>


       </React.Fragment>

    );
}
export default DashBoardModal