import { Component } from "react";
import { API_URL } from "../../constant/API_Settings";
import React, { useState } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg'
import LoginHeaderOperational from './LoginHeaderOperational';
import Sidebar_Progress from "../layouts/Operational_Sidebar_Progress.component";
import moment from 'moment';

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

const ProfitLossOP = (props) => {
  let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

	const [inputValues, setInputValues] = useState({ });
  const [errors, setErrors] = useState({})
  const history = useHistory()
  const [state, setState] = useState([])
  const [all, setAll] = useState([])
  const [sale_detail, setSaleDetail] = useState([])
  const [cost_detail, setCostDetail] = useState([])
	const [double, setDouble] = useState(true);
  var buffer = '';var flag =0; 
  var s_buffer = '';var s_flag =0;
  var profit_loss_amount =0; var org_amount =0; var profit_loss =''; var dr_total =0; var cr_total =0;
 var s_sum_cr =0, s_sum_dr=0, c_sum_cr =0, c_sum_dr=0;
     useEffect(() => {
      //  if(permissions.includes("All Reports") == false)
      //  {
      //    swal("you dont have permission")
      //  }
    // else{
     check_url()
    // }
      
   },[]) 
   function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
                   
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
   const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });
   
    setDouble(true)
};
const handleSubmit = (event) => {
  setAll([])
  setSaleDetail([])
  setCostDetail([])
  setDouble(false);
  if (validateForm())
  {
    getBalanceSheet();
  }
}

const get_detaisview = (head_id,type) => {
  //alert(head_id);
  get_detais_view(head_id,type);
}
const hide_detaisview = (type) => {

  if(type == 'sale')
  {
    setSaleDetail([])
  }
  if(type == 'cost')
  {
    setCostDetail([])
  }
}


async function get_detais_view(head_id,type)
{
    const requestOptions = {
    method: 'post',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type":"application/json",
      "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
      "Accept": "application/json"
  
     },
     body: JSON.stringify({
       start_date:inputValues.start_date,
       end_date:inputValues.end_date,
       head_id:head_id,
     })
};
 await fetch(API_URL+"/op_profit_lose_detais",requestOptions)
        .then(response => { return response.json();})
.then(responseData => {
  if(type == 'sale')
  {
    setSaleDetail(responseData)
  }
  if(type == 'cost')
  {
    setCostDetail(responseData)
  }
 return responseData;

  })
        .catch(e => {  
        });
}

function validateForm() {
  let errors = []
  let formIsValid = true
  if (!inputValues.start_date) {
    formIsValid = false;
    setDouble(true)
    errors["start_date"] = "Choose Date"
  }
  else {
    errors["start_date"] = ""
  }
  if (!inputValues.end_date) {
    formIsValid = false;
    setDouble(true)
    errors["end_date"] = "Choose Date"
  }
  else {
    errors["end_date"] = ""
  }

  setErrors(errors)

  return formIsValid;


}
   async function getBalanceSheet()
   {
       const requestOptions = {
       method: 'post',
       headers: {
         "Access-Control-Allow-Origin": "*",
         "Content-Type":"application/json",
         "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
         "Accept": "application/json"
     
        },
        body: JSON.stringify({
          start_date:inputValues.start_date,
          end_date:inputValues.end_date,
        })
   };
    await fetch(API_URL+"/op_profit_loss",requestOptions)
           .then(response => { return response.json();})
   .then(responseData => {

   responseData.all_entries.map((elem)=>
   {
   setAll(all=>[
    ...all,
    
  {
    ac_head:elem.title,
    spr_ac_head:elem.stitle,
    sub_head:elem.accounts_head,
    drtamount:elem.drtamount,
    crtamount:elem.crtamount,
    head_id:elem.head_id
    
  }
  ])
  })

    setState(responseData)
    
    setDouble(true)
    return responseData;

     })
           .catch(e => {
             
              
		      	setDouble(true)
           });
   }

   function downloadPDF() {
    if (validateForm()) {
      const requestOptions = {
        method: 'post',
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        },
        body: JSON.stringify({
          start_date:inputValues.start_date,
          end_date:inputValues.end_date
        })
      };
      fetch(API_URL + "/download_pdf_file_op_profit_loss", requestOptions)
        .then(response => { return response.blob(); })
        .then(responseData => {
          var blob = new Blob([responseData]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "ProfitLoss.pdf";
          link.click();

          return responseData;
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
  
   var today = new Date().toISOString().split('T')[0];

    return(
        <>
          <div className="main-container page-wrap">
<div className="d-flex">
<Sidebar_Progress props={props} progress={26} operation={4} op={3}/>
<div className="content-area">
<LoginHeaderOperational  props={props} page_title="" />

<div className="container pb-3"> 
{(permissions.includes("All Reports") || permissions.includes("Profit Loss"))== false ?
	<div  className="form-wrap mt-5">
  <div className="text-center">
  You don't have permission to access this page
  </div></div>:
                                  <div className="row justify-content-center mt-md-5 mt-3 form-container">
                                    <div className="col-md-12 ">
                                    <div className="row mb-3">'
                                          <div className="col-md d-flex">
                                              <div className="text-start d-flex pe-1 mt-3">
                                                <div className="form-group">
                                                <label  >Start date<sup class="star">*</sup></label>
                                                <input type="date" className="form-control  "  placeholder="" name="start_date" value={inputValues.start_date} max={today}onChange={handleInput}></input>
                                                <div className="errorMsg">{errors.start_date}</div>
                                                </div>
                                              </div> 
                                           
                                              <div className="text-start d-flex pe-3 mt-3">
                                                <div className="form-group">
                                                <label  >End date<sup class="star">*</sup></label>
                                                <input type="date" className="form-control  "  placeholder="" name="end_date" value={inputValues.end_date}max={today} min={inputValues.start_date} onChange={handleInput}></input>
                                                <div className="errorMsg">{errors.end_date}</div>
                                                </div>
                                              </div> 
                                              
                                              <div className="text-start d-flex mt-4">
                                              <label  ></label>
                                              {double === true ?   <a  className="btn btn-primary mb-md-0 px-3 mt-3 mb-3 ms-2" onClick={handleSubmit}>Submit </a> :  <a  className="btn btn-primary mb-md-0 px-3 mt-3 mb-3 ms-2">Submit </a>}
                                                
                                              </div>    
                                             </div>
                                          <div className="col-md">
                                          <div className="text-end mt-4"></div> 
                                          </div>
                                        
                                          
                      
                                          </div>
                                      <div className="form-wrap balace-sheetwrap">
                                        <div className="row mb-3">
                                          <div className="col-md ">
                                          <h2 className="table-title  mb-1">{state.pump_name}</h2>
                                          <h3 className="table-title mb-1">{state.pump_address}</h3>
                                            <h4 className="table-title mb-1">Profit and Loss</h4>
                                            <h3>{moment(state.start_date).format("DD-MM-YYYY")} - {moment(state.end_date).format("DD-MM-YYYY")}</h3>
                                             
                                          </div>
                                         
                                          </div>
                                 
                                            <div className="sheet-wrp">
                                            <div className="row">
                                               <div className="col-md-6">
                                               
                                                        <table className="table table-bordered">
                                                        <tr>
                                                   <th><h6 className="mb-0">Incomes</h6></th>
                                                    <th className="text-end"><h6>Cr</h6></th>
                                                  </tr>
              
                                                  {all.map((account)=>{
                                                     if(Number(account.crtamount) > Number(account.drtamount))
                                                     {
                                                     
                                                       var Cramt = account.crtamount - account.drtamount;
                                                       cr_total = Number(Cramt) + Number(cr_total);
                                                     }
                                                    if(buffer == account.ac_head)
                                                      {flag = 1;}
                                                    else
                                                      {flag = 0;}
                                                      if(account.ac_head != null && Cramt > 0)
                                                      {
                                                        buffer = account.ac_head;
                                                      }  
                                                      if(s_buffer == account.spr_ac_head)
                                                        {s_flag = 1;}
                                                      else
                                                        {s_flag = 0;}
                                                      if(account.spr_ac_head != null && Cramt > 0)
                                                        {
                                                          s_buffer = account.spr_ac_head;
                                                        }       
                                                    return (
                                                  <>
                                                   {s_flag== 0 && Cramt > 0 &&    
                                                        <>
                                                       {account.spr_ac_head ?
                                                      <tr><th colspan="2">{account.spr_ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                    { Cramt > 0  &&  flag== 0 &&   
                                                      <>
                                                     {account.ac_head ?
                                                    <tr>
                                                      <th colspan="2">{account.ac_head}</th>
                                                    </tr> : ''}
                                                      </>
                                                    } 
                                                    {account.sub_head && Cramt >0? <>
                                                    <tr>
                                                      <td>{account.sub_head} {account.sub_head =='Cost of Goods Sold' || account.sub_head=='Sale'? 
                                                      <>
                                                        <a   onClick={() => get_detaisview(account.head_id,'sale')}><i className="fa fa-arrow-down"></i></a>
                                                        {sale_detail.length>0?<a  onClick={() => hide_detaisview('sale')} ><i className="fa fa-arrow-up"></i> </a>:""}
                                                          </>: ''}</td>
                                                      <td className="text-end">{Cramt != '' && Cramt > 0  ?numberFormat(Cramt):''}</td>
                                                    </tr>
                                                    <tr>
                                                        {account.sub_head =='Cost of Goods Sold' || account.sub_head=='Sale'?
                                                            <>
                                                              {sale_detail.length>0?
                                                              <tr  style={{fontSize:15}}>
                                                                <th>Quantity</th>
                                                                <th>Sales price</th>
                                                                <th>Purchase price</th>
                                                                <th>Profit loss</th>
                                                                <th>Total</th>
                                                              </tr>:''}
                                                              {sale_detail.map((det)=>{
                                                                if(det.profit_lose_cr_dr=='Cr')
                                                                {
                                                                 // var s_cr = numberFormat(det.quantity * det.sales_price)
                                                                  s_sum_cr = Number(s_sum_cr) + Number(det.amount)
                                                                }
                                                                if(det.profit_lose_cr_dr =='Dr')
                                                                {
                                                                 // var s_dr = numberFormat(det.quantity * det.sales_price)
                                                                  s_sum_dr = Number(s_sum_dr) + Number(det.amount)
                                                                }
                                                                return(
                                                                  <>
                                                                  {det.purchase_price>0 ? <>
                                                                    <tr  style={{fontSize:12}}>
                                                                        <td>{det.quantity} </td>
                                                                        <td>{det.sales_price} </td>
                                                                        <td>{det.purchase_price}</td>
                                                                        <td>{det.profit_lose_cr_dr}</td>
                                                                        <td>{numberFormat(det.amount)}</td>
                                                                    </tr>
                                                                    </>:''}
                                                                  </>
                                                                  )
                                                                })}
                                                                {sale_detail.length>0?
                                                                    <tr style={{fontSize:13}}>
                                                                       <td colSpan={4}>Total (Cr-Dr)</td>
                                                                       <td > {numberFormat(s_sum_cr-s_sum_dr)}</td>
                                                                    </tr>:''}
                                                            </>:''}
                                                          
                                                      </tr>
                                                      </>
                                                    : ''}
                                                  </>
                                                )})}
                                               
                                                </table>
                                              
                                              </div>
                                             
                                              <div className="col-md-6">
                                               

                                            <table className="table table-bordered">
                                                <tr>
                                                   <th><h6 className="mb-0">EXpenses</h6></th>
                                                    <th className="text-end"><h6>Dr</h6></th>
                                                  </tr>
                                                  {all.map((account)=>{

                                                        if(Number(account.drtamount) > Number(account.crtamount))
                                                        {
                                                          var Dramt = account.drtamount - account.crtamount;
                                                          var Cramt = '';
                                                          dr_total = Number(Dramt)+Number(dr_total);
                                                        }

                                                      if(buffer == account.ac_head)
                                                        {flag = 1;}
                                                      else
                                                        {flag = 0;}
                                                        if(account.ac_head != null && Dramt > 0)
                                                        {
                                                          buffer = account.ac_head;
                                                        }   
                                                        
                                                      if(s_buffer == account.spr_ac_head)
                                                        {s_flag = 1;}
                                                      else
                                                        {s_flag = 0;}
                                                      if(account.spr_ac_head != null && Dramt > 0)
                                                        {
                                                          s_buffer = account.spr_ac_head;
                                                        }     
                                                      return (
                                                    <>
                                                      {s_flag== 0 && Dramt > 0 &&   
                                                        <>
                                                       {account.spr_ac_head ?
                                                      <tr><th colspan="2">{account.spr_ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                      {Dramt > 0  &&  flag== 0 &&   
                                                        <>
                                                       {account.ac_head ?
                                                      <tr><th colspan="2">{account.ac_head}</th></tr> : ''}
                                                        </>
                                                      } 
                                                      {account.sub_head && Dramt > 0?
                                                      <>
                                                      <tr>
                                                        <td>{account.sub_head} {account.sub_head =='Cost of Goods Sold' || account.sub_head=='Sale'?   
                                                          <>
                                                            <a  onClick={() => get_detaisview(account.head_id,'cost')} > <i className="fa fa-arrow-down"></i></a>
                                                            {cost_detail.length>0?<a  onClick={() => hide_detaisview('cost')} ><i className="fa fa-arrow-up"></i> </a>:''}
                                                          </>: ''}</td>
                                                        <td className="text-end">{Dramt != '' && Dramt > 0 ?numberFormat(Dramt):''}</td>
                                                      </tr>
                                                      <tr>
                                                        {account.sub_head =='Cost of Goods Sold' || account.sub_head=='Sale'?
                                                            <>
                                                              {cost_detail.length>0?<tr style={{fontSize:15}}>
                                                                <th>Quantity</th>
                                                                <th>Sales price</th>
                                                                <th>Purchase price</th>
                                                                <th>Profit loss</th>
                                                                <th>Total</th>
                                                              </tr>
                                                              :''}
                                                              {cost_detail.map((det)=>{
                                                                if(det.profit_lose_cr_dr=='Cr')
                                                                {
                                                                 // var c_cr = numberFormat(det.quantity * det.purchase_price)
                                                                  c_sum_cr = Number(c_sum_cr) + Number(det.amount)
                                                                }
                                                                if(det.profit_lose_cr_dr =='Dr')
                                                                {
                                                                  //var c_dr = numberFormat(det.quantity * det.purchase_price)
                                                                  c_sum_dr = Number(c_sum_dr) + Number(det.amount)
                                                                }
                                                                {console.log(det.sales_price)}
                                                                return(
                                                                  <>
                                                                    {det.purchase_price >0 ? 
                                                                      
                                                                      <tr  style={{fontSize:12}}>
                                                                        <td>{det.quantity} </td>
                                                                        <td>{det.sales_price} </td>
                                                                        <td>{det.purchase_price}</td>
                                                                        <td>{det.profit_lose_cr_dr}</td>
                                                                        <td>{numberFormat(det.amount)}</td>
                                                                       </tr> 
                                                                     :''}
                                                                  </>
                                                                  )
                                                                })}
                                                                {cost_detail.length>0?
                                                                    <tr style={{fontSize:13}}>
                                                                       <td colSpan={4}>Total (Dr-Cr)</td>
                                                                       <td > {numberFormat(c_sum_dr - c_sum_cr)}</td>
                                                                    </tr>:''}
                                                            </>:''}
                                                          
                                                      </tr>
                                                      
                                                      </>
                                                      : ''}
                                                    </>
                                                  )})}

                                           
                                                </table>
                                              
                                              </div>
                                              </div>
                                              <div className="row">
                                              
                                              <span className="sno">
                                                    <>
                                                    {Number(dr_total) == Number(cr_total) ? 
                                                      (org_amount = parseFloat(dr_total).toFixed(2))
                                                    :''}

                                                      {(Number(dr_total) > Number(cr_total)) ? 
                                                        (profit_loss_amount = parseFloat(dr_total - cr_total).toFixed(2),
                                                        profit_loss = 'loss',
                                                        org_amount = parseFloat(dr_total).toFixed(2)
                                                        )
                                                    :''}

                                                    {(Number(cr_total) > Number(dr_total)) ? 
                                                        (profit_loss_amount = parseFloat(cr_total - dr_total).toFixed(2),
                                                        profit_loss = 'profit',
                                                        org_amount = parseFloat(cr_total).toFixed(2)
                                                        )
                                                    :''}
                                                    </>   
                                                 </span>  
                                          <div className="col-md-6 ">                              
                                            <table className="table table-bordered ">
                                              {profit_loss === "loss"   &&
                                              <tr>
                                              <th>Loss</th>
                                              <th className="text-end">{numberFormat(profit_loss_amount)}/-</th>
                                              </tr>
                                              }
                                            </table>
                                          </div>
                                          <div className="col-md-6">
                                           <table className="table table-bordered ">
                                             <tr>
                                             {profit_loss === "profit"   &&
                                                <>
                                               <th>Profit</th>
                                               <th className="text-end">{numberFormat(profit_loss_amount)}/-</th>
                                                </>    }
                                             </tr>
                                           </table>
                                         </div>   
                                        
                                        </div>
                                        
                                            <div className="row">
                                         <div className="col-md-6">
                                            <table className="table table-bordered ">
                                              <tr>
                                                <th>Total</th>
                                                <th className="text-end">{numberFormat(org_amount)}/-</th>
                                              </tr>
                                            </table>
                                          </div>
                                            
                                          <div className="col-md-6">
                                            <table className="table table-bordered ">
                                             <tr>
                                               <th>Total</th>
                                               <th className="text-end">{numberFormat(org_amount)}/-</th>
                                             </tr>
                                            </table>
                                           </div>
                                           </div>
                                          
                                        </div>
                                        </div>
                                      </div>
                                    <div className="col-md">
                                       {/* <div className="text-end mt-4"><a onClick={() => downloadPDF()} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a>
                                        </div>*/}
                                      </div> 
                                    </div>
}
                                  </div>
                                 

</div>
</div>
</div>
</>
    )
}
    
export default ProfitLossOP