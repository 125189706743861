
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import museum from '../../img/museum.svg'
import DealershipModal from './DealershipModal'
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import {convertAmountToWords,preventNonNumericalInput,prevNonNumericalWithDec} from '../layouts/Sidebar_Progress.component'

var SaveModal = (props) => ReactDOM.createPortal(
  <DealershipModal  {...props} />, document.body
);

const DealershipConfig = () => {
	const [double, setDouble] = useState(true);	
  const [stat_operation, setStartoperation] = useState();	
  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      var isdayclose = checkoption.isdayclose;
    if(isdayclose == 1)
    {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else
    {
      if (isShowing) {
        setInputValues([])
        setErrors({})
        setSuccess({})
      }
      if (!isShowing) {
        setDouble(true)
        // setInputValues([])
        setErrors({})
        // setSuccess({})
      }
      setIsShowing(!isShowing);
    }
  }
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [delete_id, deleteId] = useState([])
  const [permission, setPermission] = useState([])
  const [search, setSearch] = useState({})
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }


  useEffect(() => {
    check_url();
    
  }, [search]);



   //------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {

      setStartoperation(responseData.stat_operation)
		
			if(responseData.allowurl == 1)
			{
				history.push(responseData.url);
        getProduct();
			  ListDealership();
   
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}

  const handleSubmit = (event) => {
    event.preventDefault();
    setDouble(false)
    if (validateForm())
      sendDealerShip()

  }
  function validateForm() {
    let errors = []
    let formIsValid = true
    
    if (inputValues.opening_balance != undefined) {
      if ((inputValues.opening_balance != "") && (inputValues.debit_credit == undefined || inputValues.debit_credit === "")) {
        formIsValid = false;
        setDouble(true)
        errors["debit_credit"] = " Select Debit/Credit"
      }
      else {
        errors["debit_credit"] = ''
      }

    }

  if (inputValues["phone"] ) 
    {
     
      if ( inputValues["phone"].length != 10) {
        formIsValid = false;
        setDouble(true);
        errors["phone"] = "Enter Valid Phone Number";

     }
    }
    if (inputValues["email"]) 
    {
    if (typeof inputValues["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(inputValues["email"])) {
        formIsValid = false;
        setDouble(true)
        errors["email"] = "Enter valid Email Id"
      }
    }
  }
    if ((inputValues.debit_credit) && (!inputValues.opening_balance)) {
      formIsValid = false;
      setDouble(true)
      errors["debit_credit"] = "Enter Opening Balance"
    }

    if(stat_operation!="Yes"){

      if (!inputValues.opening_balance) {
        setDouble(true)
        formIsValid = false;
        errors["opening_balance"] = "Enter Opening Balance"
      }else{
      errors["opening_balance"] = ""
    }
    }
    setErrors(errors)

    return formIsValid;


  }
  function setDealership() {

    if (list_dealership == '') {
      swal("Please Enter Opening Balance")
      return false;
    }
    if (list_dealership[0].opening_balance === null) {
      swal("Please Enter Opening Balance ")
    }
    else {
      history.push("/open-balance")
    }
  }
  async function edit_Dealer(id, ac_name) {
   
    setErrors({})
    const requestOptions = {
      method: 'get',
      data: {
        id: id,
        ac_name: ac_name

      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    await fetch(API_URL + "/dealership/" + id + "/edit", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
      
        setInputValues(
          {
            id: responseData.data.id ? responseData.data.id : '',
            ac_name: responseData.data.ac_name ? responseData.data.ac_name : '',
            name: responseData.data.name,
            address: responseData.data.address,
            phone: responseData.data.phone,
            email: responseData.data.email,
            lead_time: responseData.data.lead_time,
            supply_terminal: responseData.data.supply_terminal,
            product_name: responseData.data.product_name,
            debit_credit: responseData.data.debit_credit,
            opening_balance: responseData.data.opening_balance,
            ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,
          }
        );
        // }) 

        toggle();
        // setInputValues("")
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }
 
  async function sendDealerShip() {

    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify(inputValues)
    };
    await fetch(API_URL + "/dealership", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {
      
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        })
        if (responseData.status == false) {
          errors["opening_balance"] = responseData.response.opening_balance
          errors["lead_time"] = responseData.response.lead_time
          setDouble(true)

        }
        else if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "danger")
          history.push("/dealership-config")
          // setInputValues("")
          setDouble(true)
          toggle();
          ListDealership();
        }
        setErrors(errors)
        return responseData;
      }).catch(e => {
        swal("Something went wrong","","warning")
        console.log(e);
        setDouble(true)
      });
  }

  //loader
const [Loading,setLoading]=useState(false);
const [checkoption, ckeckOption] = useState([])

  async function ListDealership() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    await fetch(API_URL + "/dealership", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
       
        if(responseData.status==3) 
        {
          setPermission({...permission,status:responseData.status,message:responseData.message})
        }
        setDealershipList(responseData.data)

        setLoading(true)
        ckeckOption({...checkoption,
          isdayclose:responseData.isdayclose})
      })
      .catch(e => {
        console.log(e);

      });
  }

  function delete_dealership(id) {
    var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
      else
      {
 
    deleteId(id)

    toggleModal()
      }
  }

  function deleteDealership() {
    setDouble(false)
    // console.log(...delete_id)
    const requestOptions = {
      method: 'DELETE',

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify(delete_id)
    };
    fetch(API_URL + "/dealership/" + delete_id, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        ListDealership();
        toggleModal()
        setDouble(true)
        return responseData;


      })

      .catch(err => {
        console.log(err)
        swal("Something went wrong","","warning")
      })
  }
  //amout to word 

  const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

    convertAmountToWords(e,e.target.value)
  };


  const handleInputs = (e) => {
  
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });
  };
  async function getProduct() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    await fetch(API_URL + "/get-dealership-data", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
       
        setList(responseData)
        var fuel_name = ""
        responseData.fuel_data.map((elem) => {
          fuel_name += elem.fuel_name + ", "

        });
        // console.log(fuel_name.trim())
        // setProduct(fuel_name.trim())
        setProduct({
          ...list,
          fuel_name: fuel_name.trim().slice(0, -1)
        })
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }

  const [errors, setErrors] = useState({})
  const history = useHistory()
  const { isShowing, toggle } = UseModal();
  const [inputValues, setInputValues] = useState({});
  const [list_data, setList] = useState({})
  const [list_dealership, setDealershipList] = useState([])
  const [list, setProduct] = useState({})
  const [success, setSuccess] = useState([])
  return (
    <> 
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress />
          <div className="content-area">
            <LoginHeader page_title="" />
            <div className="container pb-3 mt-3">

              <div class="row">
                <div className="col-md-12 px-4">
                  <Link to="/vendor-config">
                    <a href="vendor-config" className="float-end btn btn-gray">Back</a>
                  </Link>
                </div>
              </div>

              <div className="row justify-content-center mt-md-3 mt-3 form-container">
                <div className="col-md-12">

                {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
                      {Loading ? ListDealership: 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
              
                  <div className="form-wrap list-wrp">
                    <div className="row mb-3">
                      <div className="col-md">
                        <div className="row">
                          <div className="col-md-1 col-2 pe-0">
                            <img src={museum} className="img-fluid" alt=".." width="25px" />
                          </div>
                          <div className="col ps-0">
                            <h3 className="table-title"> Dealership Configuration</h3>

                          </div>
                          <div className="col-md-6">
                            <a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#vendor" onClick={toggle}>Add Dealership Configuration</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        {/* <div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Name "  value={search.search_keyword} onChange={handleSearch} onKeyPress={(event) => {
    var key = event.keyCode || event.which;
    if (key === 13) {
        
    }
}}    />
</div> */}
                      </div>
                    </div>


                    <div className="table-widget">
                      <table className="table border">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Phone </th>
                            <th>Email</th>
                            <th>Opening Balance</th>
                            <th>Credit/Debit</th>
                            <th>Product Name</th>
                            <th>Supply Terminal</th>
                            <th>Lead Time to Supply</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            list_dealership.map(dealership => (
                              <tr>
                                <td>{dealership.get_pump.dealer.dealer_name}</td>
                                <td>{dealership.address}</td>
                                <td>{dealership.phone}</td>
                                <td>{dealership.email}</td>
                                <td className="text-end">{dealership.opening_balance}</td>
                                <td>{dealership.debit_credit=="Cr"?"Credit":dealership.debit_credit=="Dr"?"Debit":''}</td>
                                <td> <ul style={{ listStyleType: "none", marginBottom: 0 }}>{dealership.get_pump.fueltype.map(fuel => (
                                  <li style-type="none">{fuel.fuel_name}</li>
                                ))
                                } </ul>
                                </td>
                                <td>{dealership.supply_terminal}</td>
                                <td>{dealership.lead_time}</td>
                                <td><div className="d-flex icon-btn">
                                  <button onClick={() => edit_Dealer(dealership.id, dealership.ac_name)} >  <i className="fa fa-pencil" aria-hidden="true"></i>
                                  </button>
                                  <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={() => delete_dealership(dealership.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

                                  </button>




                                </div></td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={setDealership}>Continue</a></div>
                </div>
                </>}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowing ? (<SaveModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        handleInput={handleInput}
        inputValues={inputValues}
        list={list}
        double={double}
        list_data={list_data}
        prevNonNumericalWithDec={prevNonNumericalWithDec}
        preventNonNumericalInput={preventNonNumericalInput}
        // dropdownData ={dropdownData}
        handleSubmit={handleSubmit}
        errors={errors}
        success={success}
        stat_operation={stat_operation}
      />) : null
      }

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >
        <div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">DEALERSHIP CONFIGURATION</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body"><p>Are you sure you want to delete this Dealership</p></div>
          <div class="justify-content-end  modal-footer"><div class="d-flex">
            <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
            {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteDealership}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={deleteDealership}>Yes</button>}
          </div>
          </div>
        </div>




      </Modal>

    </>
  )
}

export default DealershipConfig