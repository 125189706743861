import React from "react";
import { Component } from "react";
import succes from '../../img/succes.svg'
import logo from '../../img/logo.svg'
import axios from 'axios'
import { API_URL } from "../../constant/API_Settings"
import { dealers } from "../../constant/constant"
import Types from './type.component'
import swal from "sweetalert";
import '../../App.css'
import moment from 'moment';
import Loader from '../operational-model/Employee/Loader.component'
import LoginHeader from "../layouts/LoginHeader.component";
import {  prevNonNumericalWithDec } from '../layouts/Sidebar_Progress.component'
import { getDefaultNormalizer } from "@testing-library/react";
class PumpDetails extends Component {
	constructor(props) {
		super(props);

		let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
		this.state = {
			permissions: permissions,
			type_component: [],
			types: [],
			fields: {},
			dealer_id: '',
			pump_name: '',
			pump_address: '',
			disable: true,
			name: '',
			pump_email: '',
			pump_phone: '',
			confg_date: '',
			pathurl: {},
			errors: {},
			loading: false,

		}

		this.handleInputs = this.handleInputs.bind(this);
		this.handleInputs1 = this.handleInputs1.bind(this);

	}

	componentDidMount() { //this.check_url(); // enable after subscription enable,, if 
		this.getDealer();
		this.state.fields.dealer_id = ""
		this.state.fields.pump_name = ""
		this.state.fields.pump_address = ""
		this.state.fields.name = ""
		this.state.fields.pump_email = ""
		this.state.fields.pump_phone = ""
		this.state.fields.confg_date = ""

	}

	check_url = () => {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					this.props.history.push(responseData.url);
					this.getDealer();
				}
				else {

					this.props.history.goBack();
				}

				return responseData;
			})
			.catch(e => {

				console.log(e)
			});

	}
	getDealer() {
		axios(
			{
				url: API_URL + '/get-dealer-list',
				method: "get",
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				}
			}
		).then(res => {


			var types = []

			this.setState({ types: res.data })
			for (var key in (res.data)) {
				if (res.data.hasOwnProperty(key)) {


					types.push(<Types key={`types_${key}`} dealer_id={res.data[key].id} dealer_ID={res.data[key].dealer_name} full_name={res.data[key].dealer_full_name} />)
				}
			}
			this.setState({ type_component: types })

		})
			.catch(error => {
				console.log(error)
			})



		axios(
			{
				url: API_URL + "/get-pump-details",
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				}
			}
		).then(res => {
			var fields = {}
			if (res.data.status === 3) {
				this.setState({
					status: res.data.status, message: res.data.message
				})
			}
			if (res.data.user.pump_id == null) {
				fields["pump_phone"] = res.data.user.phone
				fields["pump_email"] = res.data.user.email
			}
			else {
				fields["pump_name"] = res.data.pump_details.pump_name
				fields["pump_address"] = res.data.pump_details.pump_address
				fields["pump_phone"] = res.data.pump_details.pump_phone
				fields["pump_email"] = res.data.pump_details.pump_email
				fields["dealer_id"] = res.data.pump_details.dealer_id
				fields["name"] = res.data.pump_details.owner_name
				fields["confg_date"] = res.data.pump_details.confg_date
				fields["full_name"] = res.data.pump_details.dealer.dealer_full_name

			}
			if (fields["confg_date"]) {
				this.state.isReadonly = true;
			}
			else {
				this.state.isReadonly = false;
			}

			if (res.data.stat_operation == "Yes") {
				this.state.stat_operation = true;
			}
			else {
				this.state.stat_operation = false;
			}
			if (res.data.status === 3) {
				this.setState({
					status: res.data.status, message: res.data.message
				})
			}

			this.setState({ fields: fields })
			this.setState({
				'loading': true
			})
		}).catch(error => {
			console.log(error)
		})

	}

	handleInputs(e) {



		let fields = this.state.fields;


		fields[e.target.name] = e.target.value;

		this.setState({

			fields: fields
		});
	}
	handleInputs1 = (e) => {
		let fields = this.state.fields;
		// const result = e.target.value.replace(/[^a-z]/gi, "");
		fields[e.target.name] = e.target.value;
		this.setState({
		  fields: fields,
		});
	  };

	handleSelect = (e) => {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		fields['full_name'] = e.target.options[e.target.options.selectedIndex].getAttribute('full_name');
		this.setState({
			fields: fields,
			dealer_name: e.target.options[e.target.options.selectedIndex].text,
		});
	}

	handleSubmit = (event) => {
		let dis = this.state.disable
		dis = false
		event.preventDefault();
		this.setState({ disable: dis })
		if (this.validateForm()) {
			this.pumpRegister()
		}


	}

	validateForm() {
		var lng = this.state.lng
		// this.setState({'disable':false})
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		if (!fields["dealer_id"]) {
			formIsValid = false;
			this.setState({ disable: true })
			errors["dealer_id"] = " Select Dealership"
		}
		if (!fields["pump_name"]) {
			formIsValid = false;
			this.setState({ disable: true })
			errors["pump_name"] = " Enter Pump Name"
		}
		if (typeof fields["pump_name"] !== "undefined") {
			// if (!fields["pump_name"].match(/^[a-zA-Z ]*$/)) {
			//   formIsValid = false;
			//   this.setState({ disable: true })
			//   errors["pump_name"] = "Enter alphabet characters only.";
			// }
		  }
		if (!fields["pump_address"]) {
			formIsValid = false;
			this.setState({ disable: true })
			errors["pump_address"] = "Enter Pump Address"
		}



		if (!fields["name"]) {
			formIsValid = false;
			this.setState({ disable: true })
			errors["name"] = " Enter Owner Name"
		}
		if (typeof fields["name"] !== "undefined") {
			if (!fields["name"].match(/^[a-zA-Z ]*$/)) {
			  formIsValid = false;
			  this.setState({ disable: true })
			  errors["name"] = "Enter alphabet characters only.";
			}
		  }
		if (!fields["confg_date"]) {
			formIsValid = false;
			this.setState({ disable: true })
			errors["confg_date"] = "Configuration Date is Required"
		}


		if (!fields["pump_email"] && fields['pump_email'] !== "") {
			formIsValid = false;
			this.setState({ disable: true })
			errors["pump_email"] = " Enter Pump Email ID"
		}
		if (typeof fields["pump_email"] !== "undefined") {

			//regular expression for email validation
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			if (!pattern.test(fields["pump_email"])) {
				this.setState({ disable: true })
				formIsValid = false;
				errors["pump_email"] = " Enter Valid Email ID "
			}

		}

		if (!fields["pump_phone"]) {
			formIsValid = false;
			this.setState({ disable: true })
			errors["pump_phone"] = " Enter Pump Phone Number "
		}
		if (typeof fields["pump_phone"] !== "undefined") {



			var pattern = new RegExp(/^[0-9\b]+$/);

			if (!pattern.test(fields["pump_phone"])) {
				this.setState({ disable: true })
				formIsValid = false;

				errors["pump_phone"] = " Enter Only Phone Number.";

			} else if (fields["pump_phone"].length != 10) {
				this.setState({ disable: true })
				formIsValid = false;

				errors["pump_phone"] = " Enter Valid Phone Number.";

			}

		}


		this.setState({
			errors: errors,

		});

		return formIsValid;


	}

	pumpRegister() {
		var user = {
			dealer_id: this.state.fields.dealer_id,
			pump_name: this.state.fields.pump_name,
			pump_address: this.state.fields.pump_address,
			name: this.state.fields.name,
			pump_email: this.state.fields.pump_email,
			pump_phone: this.state.fields.pump_phone,
			confg_date: this.state.fields.confg_date,

		}

		axios(
			{
				url: API_URL + '/save-pump-details',
				method: "post",
				data: user,
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				}
			})
			.then(res => {



				this.setState({
					status: res.data.status ? "success" : "danger",

				}, () => {

					if (res.data.status === false) {

						swal("", res.data.message, res.data.status ? "success" : "danger")
						this.props.history.push("/pump-details")
						// this.setState({disable:true})
					}
					if (res.data.status === true) {

						swal("", res.data.message, res.data.status ? "success" : "danger")
						// this.props.history.push("/subscription")fuel-config
						this.props.history.push("/fuel-config")
					}
				})
			}).catch(error => {
				swal("Something went wrong", "", "warning")
				console.log(error)
				// this.setState({disable:true})
			})

	}








	render() {

		return (
			<div className="main-container">
				<div className="container-fluid">
					<div className="row">
					{this.state.permissions?.includes("Deny Pump Details") == true ?
							<div className="form-wrap">
								<div className="text-center">
									You don't have permission to access this page
								</div>
								<div  style={{textAlign:"center",marginTop:"20px"}}>
									<a href="/nozzle-employee-list">Click here to back</a>
								</div>
							</div> :
							<>



								{this.state.loading ? this.componentDidMount : <Loader />}
								<div className="col-md-6 login-left pumb-banner">
									<div className="row pt-5 pb-5 window-height justify-content-center align-items-center">
										<div className="col-md-8">
											<div className="login-banner">
												<a className="logo-continer" href="#"><img src={logo} /></a>
												<h1>Lets Start your <br />Fuel station</h1>
												<h6>Petrosmartservice@gmail.com</h6>
												<div className="border-top"></div>
												<h6>91 8714554771</h6>

											</div>
										</div>
									</div>
								</div>
								<div className="col-md-6 login-right">
									<LoginHeader page_title="" />
									<div className="row pt-3 pb-3 window-height justify-content-center align-items-center">
										<div className="col-md-8">
											<div className="login-container">

												<form >
													<div className="form-container small">
														<h2>Pump Details</h2>
														<label for="Dealership" class="mt-3"> <b>Dealership</b></label>
														<div className="row mt-2">
															<div className="col-md-4">
																<div className="form-group mt-4">
																	<div className="select-container" >
																		<select id="dealer_id" className="form-control" name="dealer_id" value={this.state.fields.dealer_id} disabled={this.state.stat_operation} onChange={this.handleSelect}>
																			<option value="">Select</option>
																			{

																				this.state.type_component.map(component => {
																					return (component)
																				})
																			}
																			{/* <option value="ioc">ioc</option>
													<option value="relaince">relaince</option> */}
																		</select>

																	</div>
																	<div className="errorMsg">{this.state.errors.dealer_id}</div>
																</div>
															</div>
															<div className="col-md-8">
																<div className="form-group">
																	<label htmlFor="PumpName">Pump Name<sup class="star">*</sup></label>
																	<div className="bar"></div>
																	<div className="input-container">
																		<input id="PumpName" type="text" className="form-control" name="pump_name" value={this.state.fields.pump_name} readOnly={this.state.stat_operation} onChange={this.handleInputs1} />



																	</div>
																	<div className="errorMsg">{this.state.errors.pump_name}</div>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-md-12">
																<div className="form-group">
																	<label htmlFor="address">Pump Address<sup class="star">*</sup></label>
																	<div className="bar"></div>
																	<div className="input-container">

																		<textarea name="pump_address" className="form-control" value={this.state.fields.pump_address} onChange={this.handleInputs} readOnly={this.state.stat_operation} />


																	</div>
																	<div className="errorMsg">{this.state.errors.pump_address}</div>
																</div>

															</div>

															<div class="col-md-12 mb-4">
																<div class="display-card">
																	<div className="row">

																		<div className="col-12 text-center">
																			<h5>{` ${this.state.fields.pump_name ? this.state.fields.pump_name : ''} ${this.state.fields.dealer_id ? `(` : ''}${this.state.fields.dealer_id ? (this.state.fields.full_name) : ''}${this.state.fields.dealer_id ? `)` : ''}`}</h5>
																			<p>{`${this.state.fields.pump_address ? this.state.fields.pump_address : ''} `}</p>
																			<div className="col-12  mt-2 text-end">
																				<img src={logo} className="img-fluid  col-2 float-right" />
																			</div>
																		</div>
																	</div>
																	{/* <h5>{`${this.state.dealer_name ? this.state.dealer_name : ''} ${this.state.fields.pump_name ? this.state.fields.pump_name : '' } ${this.state.fields.pump_address ? this.state.fields.pump_address : '' } `}</h5>
											<small>
												This will be the title of all reports
											</small> */}
																</div>
																<small class="d-block text-end py-1">
																<b>*This will be the title of all reports.</b>
											</small>
															</div>

															<div className="col-md-12">
																<div className="form-group">

																	<label >Operation Date<sup class="star">*</sup></label>
																	<input type="date" id="txtDate" className="form-control" name="confg_date" max={moment().format("YYYY-MM-DD")} value={this.state.fields.confg_date} onChange={this.handleInputs} readOnly={this.state.isReadonly} />

																	<div className="bar"></div>
																	<small class="d-block py-1"><b>*The selected date is considered as the operation date, while the Previous date is regarded as the configuration balancesheet date.</b></small>
																	<div className="errorMsg">{this.state.errors.confg_date}</div>
																</div>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																	<div className="input-container">
																		<input id="Name" type="text" className="form-control" name="name" value={this.state.fields.name} onChange={this.handleInputs1} />
																		<label htmlFor="Name">Owner Name<sup class="star">*</sup></label>
																		<div className="bar"></div>

																	</div>
																	<div className="errorMsg">{this.state.errors.name}</div>
																</div>
															</div>

															<div className="col-md-12">
																<div className="form-group">
																	<div className="input-container">
																		<input id="pump_email" type="pump_email" className="form-control" name="pump_email" value=
																			{this.state.fields.pump_email} onChange={this.handleInputs} />
																		<label htmlFor="pump_email">Owner Email<sup class="star">*</sup></label>
																		<div className="bar"></div>

																	</div>
																	<div className="errorMsg">{this.state.errors.pump_email}</div>
																</div>
															</div>
															<div className="col-md-12">
																<div className="form-group">
																	<div className="input-container">
																		<input type="number" pattern="\d{3}[\-]\d{3}[\-]\d{4}" onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)}  className="form-control hide-arrow ph_no" name="pump_phone" value={this.state.fields.pump_phone} onChange={this.handleInputs} />
																		<label htmlFor="pump_phone">Pump Phone<sup class="star">*</sup></label>
																		<div className="bar"></div>

																	</div>
																	<div className="errorMsg">{this.state.errors.pump_phone}</div>
																</div>
															</div>
														</div>

														<div className="form-group">
															{console.log(this.state.disable)}
															{this.state.disable == true ? <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Continue</button> : <button type="submit" className="btn btn-primary" disabled>Continue</button>}
														</div>
													</div>
												</form>
											</div>

										</div>
									</div>
								</div>
							</>
						}
					</div>

				</div>
			</div>



		)
	}
}
export default PumpDetails;