import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const RecoupModal = ({ preventNonNumericalInput,prevNonNumericalWithDec,isShowing, hide,double, errors,input, inputValues,success, productData, handleChange, new_price, handleSelect, fields, handleSubmit, handleInputs, handleInput }) => {

  return (
    <React.Fragment>
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modal-dialog-xl modalxl">

          <div class="modal-header mb-3">
            <h5 class=" text-center" id="staticBackdropLabel">Recoup</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div class="">
              <div class="row g-3 justify-content-center mb-2">
                <div class="col-6">
                  <label class="col-form-label font-weight-bolder">Product<sub className="star">*</sub></label>
                </div>
                <div class="col-6">
                  <div class="select-container">
                    <select  class="form-control " name="product_id" value={input.product_id} onChange={handleChange}>
                      <option value="">Select</option>
                      {
                        productData.map((data) => {
                          return <option value={data[0]}>{data[1]}</option>
                        })
                      }
                    </select>
                  </div>
                  <div className="errorMsg">{errors.product_id}</div>
                </div>
              </div>
              <div class="row g-3 justify-content-center mb-2">
                <div class="col-6">
                  <label class="col-form-label">Quantity<sub className="star">*</sub></label>
                </div>
                <div class="col-6">
                  <input type="number" class="form-control hide-arrow " onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="quantity" value={inputValues.quantity} onChange={handleInput} />
                  <div className="errorMsg">{errors.quantity}</div>

                </div>
              </div>
              <div class="row g-3 justify-content-center mb-2">
                <div class="col-6">
                  <label class="col-form-label">Price (default)</label>
                </div>
                <div class="col-6">
                  <input type="text" class="form-control " id="price" readOnly name="price" value={inputValues.price} onChange={handleInputs} />
                </div>
              </div>

              <div class="row g-3 justify-content-center mb-2">
                <div class="col-6">
                  <label class="col-form-label">Amount (auto)<sub className="star">*</sub></label>
                </div>
                <div class="col-6">
                  <input type="number" class="form-control hide-arrow " onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} name="amount" value={inputValues.amount} onChange={handleSelect} />
                  <div className="errorMsg">{errors.amount}</div>
                  <span  class="amount-words"></span>
                </div>
            
              </div>
              <div class="row g-3 justify-content-center mb-2">
                <div class="col-6">
                  <label class="col-form-label">Description</label>
                </div>
                <div class="col-6">
                  <input type="text" class="form-control " name="note" value={inputValues.note} onChange={handleInputs} />

                  {/* <div className="errorMsg">{errors.amount}</div> */}

                </div>
              </div>
              <div class="row g-3 justify-content-center mb-2">
                <div class="col-6">
                  <label class="col-form-label font-weight-bolder">Recoup Tank<sub className="star">*</sub></label>
                </div>
                <div class="col-6">
                  <div class="select-container">
                    {console.log(fields)}
                    <select  class="form-control " name="tank_id" value={inputValues.tank_id} onChange={handleInputs}>
                      <option value="" >Select</option>
                      {
                        fields.map((data) => {
                          return <option value={data[0]}>{data[1]}</option>
                        })
                      }
                    </select>
                    <div className="errorMsg">{errors.tank_id}</div>

                  </div>
                  {/* <div className="errorMsg">{errors.sales_type}</div> */}
                </div>
              </div>
              {localStorage.getItem("save_hide") == 'false' ?
              <div class="modal-footer text-center">

                <a class="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
                {double===true ? <a class="btn btn-primary px-4 w-auto" type="submit"  onClick={handleSubmit}>SAVE</a>:<a class="btn btn-primary px-4 w-auto" type="submit"  disabled>SAVE</a>}              </div>
              :''}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default RecoupModal