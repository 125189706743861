import { Component } from "react";
import logo from '../../img/logo.svg';
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { API_URL } from "../../constant/API_Settings";
import { useState } from 'react';
const Sidebar_Progress = (path) => {
	
	const [userName, setUserName] = useState(localStorage.getItem("PROFILE_NAME"))
	const [list_data, setList] = useState([])
	const [set_notification,setNotification] = useState([])
	const [list_data2, setList2] = useState([])
    const pathname = path.path
	
	//---On Load---//		
	useEffect(() => {
	
		get_sedebar();
	}, []);
	function get_sedebar() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}
		};
		fetch(API_URL + "/getSidebar", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setList(responseData.availabe_screen)
				setList2(responseData.nextScreen)

				return responseData;
			})
			.catch(e => {
			});
	}

	function get_notification() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}
		};
		fetch(API_URL + "/getnotification", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setNotification(responseData.notification)
				localStorage.setItem( "PERMISSIONS", responseData?.permissions || []);
				return responseData;
			})
			.catch(e => {
			});
	}

	var i=1;
        return(
            		<div className="left-wrap">
			<div className="d-flex">
			<div className="">
			<a className="logo-continer text-center" href="#"><img src={logo} /></a>
		</div>
		<div className="d-md-none pe-4 ms-auto">
			    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
     
        <li className="nav-item dropdown notify">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
         <img src="img/bell-white.svg" width="20px;" />
         <span className="badge bg-danger">2</span>
          </a>
          <ul className="dropdown-menu notification shadow" aria-labelledby="navbarDropdown">
          	  <li><h4 className="notify-head">Notification 2</h4></li>
            <li><a className="dropdown-item" href="#"><strong>Lorem Ipsum</strong><span>Lorem Ipsum is simply dummy text of the printing</span><small>2 day ago</small></a></li>
             <li><a className="dropdown-item" href="#"><strong>Lorem Ipsum</strong><span>Lorem Ipsum is simply dummy text of the printing</span><small>2 day ago</small></a></li>
            <li><Link><a className="dropdown-item text-center notify-all" href="#">show all notifications</a></Link></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle user-head" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img className="img-profile rounded-circle" src="img/avatar.svg" />{userName}<span><i className="fa fa-angle-down font-weight-bolder" aria-hidden="true"></i></span>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            {/* <li><a className="dropdown-item" href="#">Profile</a></li>
            <li><Link  to="/settings"><a className="dropdown-item" href="#">Settings</a></Link></li>
            <li><Link  to="/cbalance-sheet"><hr className="dropdown-divider" /></Link></li> */}
            <li><Link className="dropdown-item" to="/logout"><a className="dropdown-item" href="#">Logout</a></Link></li>
          </ul>
        </li>
        
      </ul>
			</div>
		</div>
    <div className="responsive">
		
			{pathname ?"" :
			<ul className="menu-line">
									{  
									 list_data.map((obj,index) => (
										 
										<> 
										{obj.name != null ?
										<li>    
											<Link to={obj.link}>
												<p><strong>{obj.name}</strong></p>
												<span className="steps ">{obj.key}</span>
												{list_data2 < obj.key ?	<span className="steps">{i}</span> : <span className="steps active">{i}</span>}
					
											</Link>
										</li>: ''}
										<span className="sno"> {obj.name != null ? i = i+1 :' '}</span>
										</>
									))}
									
			
			</ul>
}


		</div>
			<a href="#" class="btm-corner"><strong><i className="fa fa-question-circle-o p-2"></i>Help</strong></a>
		</div>
        )
    }


export default Sidebar_Progress;
export const convertAmountToWords = (e,nums) => 
	{


	var a = ['','One ','Two ','Three ','Four ', 'Five ','Six ','Seven ','Eight ','Nine ','Ten ','Eleven ','Twelve ','Thirteen ','Fourteen ','Fifteen ','Sixteen ','Seventeen ','Eighteen ','Nineteen '];
	var b = ['', '', 'Twenty','Thirty','Forty','Fifty', 'Sixty','Seventy','Eighty','Ninety'];
  var myArr = nums.split(".");
  var num = myArr[0];
  var num1 = myArr[1]?myArr[1]:'';
if ((num = num.toString()).length > 9) return 'overflow';
/****************************/
var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

if (!n) return; var str = '';
str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
// var selector = document.getElementsByClassName('amount-words')
/************************* */
if(num1 != '')
{
  if ((num1 = num1.toString()).length > 2) return 'overflow';
}

/****************************/
var p = ('000000000' + num1).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

var str1 = '';
if ((p) && (num1 != ''))
{ 
 
str1 += (p[5] != 0) ? ((str1 != '') ? 'And ' : '') + (a[Number(p[5])] || b[p[5][0]] + ' ' + a[p[5][1]]) + ' ' : '';
}
/************************* */
var parent = e.currentTarget.parentNode
var selector = parent.querySelector('.amount-words')
	if(typeof selector !== 'undefined' && selector)
	{
		
		if(str1 !='')
		{
			 if(str =='')
			 {
				 str = 'Zero';
			 }
		str = str+' Rupees And '+str1+' Paise ';
		}
		else if(str !='' && num != 1 && num != 0){
		str = str+' Rupees';
		}
		else if(num == 0 && str1 =='')
		{
		  str = str+' Zero';
		}
		else if(num == 1 && str1 =='')
		{
		  str = str+' Rupee';
		}	
		selector.innerHTML = str;
	}
	
		
}

export const preventNonNumericalInput=(e,num) =>{
	e = e || window.event;
	var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
	var charStr = String.fromCharCode(charCode);
	var fullChar = num ? num?.toString() + charStr : charStr;
	if (!fullChar.match(/^[0-9]*(\.{1})?([0-91-9][0-9])?$/))
	{
		e.preventDefault();
	}

	var myArr =fullChar.toString().split('.');
	var num0 = myArr[0];
	if ((num0 = num0.toString()).length > 8)
	e.preventDefault();
	}
	
	export const preventNonNumericalInputNON = (e, num) => {
		e = e || window.event;
		var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
		var charStr = String.fromCharCode(charCode);
		var fullChar = num ? num.toString() + charStr : charStr;
		
		if (!fullChar.match(/^\d*(\.\d{0,3})?$/)) {
			e.preventDefault();
		}
	
		var myArr = fullChar.toString().split('.');
		var num0 = myArr[0];
		if (num0.toString().length > 8) {
			e.preventDefault();
		}
	};
	
 
	export const prevNonNumericalWithDec=(e,num) =>{
 
	 var parent = e.currentTarget.parentNode
	 var selector = parent.querySelector('.ph_no')
	 if (typeof selector !== 'undefined' && selector)
	   {
		 e = e || window.event;
		 var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
		 var charStr = String.fromCharCode(charCode);
		 var fullChar = num ? num?.toString() + charStr : charStr;
		 if (!fullChar.match(/^[0-9]+$/))
		 e.preventDefault();
	   }
	   else
	   {
		 e = e || window.event;
		 var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
		 var charStr = String.fromCharCode(charCode);
		 var fullChar = num ? num?.toString() + charStr : charStr;
		 if (!fullChar.match(/^[0-9]+$/))
		 e.preventDefault();
 
		 var myArr =fullChar.toString().split('.');
		 var num0 = myArr[0];
		 if ((num0 = num0.toString()).length > 8)
		 e.preventDefault();
	   }
	 }