import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
const ProductTransferRackModal = ({  prevNonNumericalWithDec,isShowing,startDate, hide,stock_data,handleInput, setVehicle, errors,handleChange2,double, success, inputValues, handleAdd,handleChange1, handleRemove, handleChange, fields,product_data,rack_data, dropdown_data, handleSubmit, handleInputs }) => {
  var today = new Date().toISOString().split('T')[0];
  return (
        <React.Fragment>

         
            <div class="container pb-3">
              
            
        <div class="row mb-3 justify-content-center">
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal  ">
                          <div class="modal-header mb-3">
                                  <h5 class=" text-center" id="staticBackdropLabel">Product Transfer to rack</h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>
                    
				
                           <div class="">

                           <div class="row form-container justify-content-center">
                                    <div class="col-md-12">
                               <div class=" ">
        <div class="row mb-3 justify-content-center">
          
             
              
            <div class="col-md-12">
            <div class="form-row mb-2">
              <label class="text-secondary mb-2 ">Product Selection<sub class="star">*</sub></label>
              <div class="select-container">
                <select id="" class="form-control " name="nf_product_id" value={inputValues.nf_product_id} onChange={handleChange}>
                  <option value="">Select</option>
                  {product_data.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }
                </select>
              </div>
              <div className="errorMsg">{ errors.nf_product_id}</div>
            </div>
            <div class="form-row mb-2">
              <label class="text-secondary mb-2">Date<sub class="star">*</sub></label>
              <input type="date" class="form-control " name="op_date" min={startDate} max={today} id={"m_op_date"} onChange={handleChange1}  value={inputValues.op_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :inputValues.op_date} disabled ></input>
              <div className="errorMsg">{ errors.op_date}</div>
            </div>

           
            <div class="form-row mb-2">
              <label class="text-secondary mb-2">Move From<sub class="star">*</sub></label>
              <div class="select-container">
                <select id="" class="form-control " name="rack_id" value={inputValues.rack_id} onChange={handleInputs}>
                  <option value="">Select</option>
                  {dropdown_data.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }
                </select>
              </div>
              <div className="errorMsg">{ errors.rack_data}</div>
            </div>
            <div class="form-row mb-2">
              <label class="text-secondary mb-2">Stock</label>
              <input type="text" class="form-control " name="rack_stock" readOnly value={stock_data} onChange={handleInput}></input>
              <div className="errorMsg">{ errors.stock_data}</div>
              </div>
            <div class="form-row mb-2">
              <label class="text-secondary mb-2">Moving Quantity<sub class="star">*</sub></label>
              <input type="number"  onKeyPress={(event)=> prevNonNumericalWithDec(event,event.target.value)} class="form-control  hide-arrow" name="quantity" value={inputValues.quantity} onChange={handleChange1}></input>
              <div className="errorMsg">{ errors.quantity}</div>
            </div>
            <div class="form-row mb-2">
              <label class="text-secondary mb-2">Move To<sub class="star">*</sub></label>
              <div class="select-container">
                <select id="" class="form-control " name="id" value={inputValues.id} onChange={handleChange2}>
                  <option>Select</option>
                  {rack_data.map((data) => {
                                                return <option value={data[0]}>{data[1]}</option>
                                            })
                                            }
                </select>
              </div>
              <div className="errorMsg">{ errors.rack_quantity}</div>
            </div>

            <div class=" text-center mt-3">
              <a href="#" class="btn btn-danger px-5 me-3" onClick={hide}>Cancel</a>
              {double===true ? <a href="#" class="btn btn-primary  px-5 w-auto" onClick={handleSubmit}>Proceed</a>:  <a href="#" class="btn btn-primary  px-5 w-auto" disabled>Proceed</a>}
      
    </div>          
            </div>

          
            </div>
      

      </div>

                           
            </div>
      

      </div>
                                      </div>
                                     
                                </div>
                              </div>

</div>
</div>



       </React.Fragment>

    );
}
export default ProductTransferRackModal