
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { useState } from 'react';
import ReactDOM from 'react-dom';
import museum from  '../../img/museum.svg'
import React from 'react';

import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect ,useMemo} from 'react';
import { API_URL } from "../../constant/API_Settings";
import { Link } from "react-router-dom";
import TankerModal from './TankerModal.component';
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import PaginationComponent from '../layouts/PaginationComponent';

var TankModal = (props) => ReactDOM.createPortal(
	<TankerModal  {...props} />, document.body
		 );
     const  PaginationComp  = (props) => (
      < PaginationComponent  {...props} />
         );
const TankerConfig = (location) => {
  const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);	
  const UseModal = () => {
 
    const [isShowing, setIsShowing] = useState(false);
  
    function toggle() {
     
      var isdayclose = checkoption.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
      if (isShowing) {
        setInputValues([]);
        setFields([''])
        setInputFields([''])
        setErrors({})
        setRegister([])
      //  fields = ""
      }
      if(!isShowing)
      {
        getRegisterNumber();
        setDouble(true)
      }
      setIsShowing(!isShowing);
    }
  }
  
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])

  const {isShowing, toggle} = UseModal();
  const [inputValues, setInputValues] =useState({});
  const history = useHistory()
  const [list_data, setList] = useState([])
  const [register, setRegister] = useState([])
  const [payment_id, setId] = useState([])
  const [errors, setErrors] = useState([ ])
  const [checkoption, ckeckOption] = useState([])
  const handleInputs = (e ) => {
  
    setInputValues({
      ...inputValues,
    
      [e.target.name]: e.target.value,
     
    });
  };
  const [pages,setpages]=useState(
    {
    current_page:1,
    last_page:0,
    total_pages:0,
    pagination_item:[],
    total_pages:0,
  })

    const [fields,setFields] = useState(['']);
    const [inputfields,setInputFields] = useState(['']);
   const handlePagination = (number) =>
      {
          setpages({
            ...pages,
            current_page:number
          }
              )
    
              ListTankDetails(number)
    
      }
      const [search,setSearch]=useState('')
      // const [list_data,setList] = useState({}) 
      const  handleSearch = (event) =>
      {
       setSearch(event.target.value)
      }
      async function getRegisterNumber()
      {
          const requestOptions = {
          method: 'get',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
        
           },
      };
       await fetch(API_URL+"/get-tanker-list",requestOptions)
              .then(response => { return response.json();})
      .then(responseData => {
       
        setRegister(responseData)
        // var fuel_name = ""
      // responseData.map((elem) => {
      //   fuel_name += elem.fuel_name+" "
        
      //   });
        // console.log(fuel_name.trim())
        // setProduct(fuel_name.trim())
 
      return responseData;
        })
              .catch(e => {
                  console.log(e);
                 
              });
      }
      
  useEffect(() => {
    check_url();
    document.title = "Tanker Configuration"
    setLocation(location.location.pathname)	
  
   
  },[])
//------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
        if(location.location.pathname == "/tanker-income/tanker-income")
				{
					history.push(location.location.pathname);
				}
				else
				{
				history.push(responseData.url);
				}
        getRegisterNumber();
			  ListTankDetails(pages.current_page);
   
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
const [path, setLocation] = useState([])
function check_meta_date() {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
//--------------------------//


function validateFormincome() {
  let errors = []
  let formIsValid = true


  if ( !inputValues.tanker_id) {
    formIsValid = false;
    setDouble(true);
    errors["tanker_id"] ="Select Registration Number";
    }
   else{
     errors["tanker_id"] = "";
     }

  if ( !inputValues.income) {
  formIsValid = false;
  setDouble(true);
  errors["income"] ="Enter Tanker Income Head";
  }
 else{
 	errors["income"] = "";
   }
   
  setErrors(errors)
  return formIsValid;
}

  const handleSubmitincome = (event ) =>
  {
    // setInputValues({});
    event.preventDefault();
    setDouble(false)
    if(validateFormincome()){
      errors["income"] = ""
      saveTankerincomeConfig()
    }
  }

  function saveTankerincomeConfig()
    {
 
 
        const requestOptions = 
        {
          method: 'POST',
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type":"application/json",
              "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
              "Accept": "application/json"
            },
            body: JSON.stringify(inputValues)
        };
       fetch(API_URL+"/save-tanker-income",requestOptions)
            .then(response => 
              { 
                  return response.json();})
              .then(responseData => {
          
              let errors = []
              if(responseData.status == false)
              {
                
                errors["tanker_id"] = responseData.response.tanker_id
                errors["income"] = responseData.response.income
                setDouble(true)
                 setErrors(errors)
           
              }else if(responseData.status == true){
                  swal("", responseData.message,responseData.status ? "success":"danger")
                  setDouble(true)
                  toggle();
                  ListTankDetails(pages.current_page);
                  setDouble(true)
                  setInputValues("")
                  setErrors({})
                  return responseData;
                 
                }
                setErrors(errors)
                return responseData;
            })  .catch(e => {
              swal("Something went wrong","","warning")
                console.log(e);   
                setDouble(true)  
            });
    }

  async function edit_tankConfig(id)
    {
      toggle();
     
        const requestOptions = {
          method: 'get',
          data : {id:id},
          headers: {
            "Access-Control-Allow-Origin" : "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"

          },
        };
        await fetch(API_URL+"/edit-tanker-income/"+id,requestOptions)
              .then(response => {return response.json();})
            .then(responseData => {
              setInputValues({...inputValues, tanker_no :responseData.data.reg_number,
                 tanker_id :responseData.data.tanker_id , 
                 income:responseData.data.ac_head ,
                 id:responseData.data.id,
                 status:responseData.data.status})
              toggle();

              return responseData;
            })
        .catch(e => {
          console.log(e);
        });
    }

       	//loader
         const [permission, setPermission] = useState([])
const [Loading,setLoading]=useState(false);
  function ListTankDetails(page)
  {
          const requestOptions = {
          method: 'get',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
        
           },
      };
        // fetch(API_URL+"/tanker",requestOptions)
        fetch(`${API_URL}/list-tanker-income?search_keyword=${search}&page=${page}` ,requestOptions)
              .then(response => { return response.json();})
      .then(responseData => {
        if(responseData.status==3) 
        {
          setPermission({...permission,status:responseData.status,message:responseData.message})
        }
      
     setList(responseData.data.data) 
     ckeckOption({...checkoption,
			isdayclose:responseData.isdayclose})
    
                       setpages({
                        ...pages,
                        current_page: responseData.data.current_page,
                        last_page: responseData.data.last_page,
                        per_pages:  responseData.data.per_page,
                        total_pages:responseData.data.total,
                         
                        })
              
    
     setLoading(true)
 return responseData;
 
        })
              .catch(e => {
                  console.log(e);
                 
              });
  }

  function delete_tanker(id)
  {
    
    var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
      else
      {
  
    deleteId(id)
   
    toggleModal()
  }
}
  
  function deleteTank()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/delete-tanker-income/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {


        if(responseData.status === true)
        {
         setDouble(true)
         swal("",responseData.message,responseData.status? "success":"warning")
        }
        if(responseData.status === false)
        {
         setDouble(true)
         swal("",responseData.message,responseData.status? "warning":"warning")
        } 
          ListTankDetails(pages.current_page);
          toggleModal()
          setDouble(true)
          return responseData;
   
         })
   
     .catch(err=>{
            console.log(err)
            swal("Something went wrong","","warning")
         })
  }
  

    return(
        <>
          <div className="main-container page-wrap">
<div className="d-md-flex">
{path == "/tanker-income/tanker-income"?<Sidebar_Progress path={path}/>:<Sidebar_Progress />}
<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">
<div class="row">
 <div className="col-md-12 px-4 mt-3">{path == "/tanker-income/tanker-income"?<Link to={backPage || "/operational-date"}>
        <a href="#" className="float-end btn btn-gray">Go Back</a>
      </Link>:<Link to="/owntanker-config">
        <a href="owntanker-config" className="float-end btn btn-gray">Back</a>
      </Link>}</div>
 </div>

	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
    {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
                      {Loading ? ListTankDetails : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col-4 ps-0">
							<h3 className="table-title mt-2">Own Tanker Income</h3>
              {/* <div className="col-md-12 mb-3"><Link to="/fuel-config"><a href="fuel-config" className="float-end btn btn-gray">Back</a></Link></div> */}

						</div>
						<div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 "  data-bs-target="#bank" onClick={toggle}>Add Own Tanker income</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Registration No"  value={search.search_keyword} onChange={handleSearch}   />
<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

</div>
					</div>
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Registration Number</th>
      <th>Tanker Income Head</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {
list_data.map(container => (      
     <tr> 

<td>{container.reg_number}</td>
      <td>{container.ac_head}</td>
      
      <td><div className="d-flex icon-btn">
      <button  onClick={()=> edit_tankConfig(container.id)}>  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>
      <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_tanker(container.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
    </tr>
))}
</tbody>
</table>
{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
				</div>
			</div>
      {path == "/tanker-income/tanker-income"?"":  <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/tanker-expense")}>skip/Continue</a></div>	}
		</div>
    </>}
	</div>
</div>
</div>
{isShowing ? (<TankModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        inputValues={inputValues}
	    	fields={fields}
        inputfields={inputfields}
        setFields={setFields}
        register={register}
        setInputFields={setInputFields}
        double={double}
        handleSubmitincome={handleSubmitincome}
        errors={errors}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">TANKER INCOME</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Tanker Income</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
					{double == true ?<button type="submit" class="btn btn-primary"  onClick={ deleteTank}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={ deleteTank}>Yes</button>}
					</div>
					</div>
					</div>
      </Modal>

</div>
</div>
</div>

</>
    )
} 
      
export default TankerConfig