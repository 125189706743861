import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput} from "../../../layouts/Operational_Sidebar_Progress.component";

const NonFuelProductSellingPriceChangeModal = ({ handlePagination,double,startDate,isShowing,mode,productData,handleSelect,hide,selling_price, setVehicle, handleInput,errors,success, inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs }) => {
  var today = new Date().toISOString().split('T')[0];
  return (
        <React.Fragment>

           
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal  modalm col-12 ">
                          <div class="modal-header mb-3">
                                  <h5 class=" text-center" id="staticBackdropLabel"></h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>
                    
				
                    


                  

                           <h3 class="table-title text-center">Non fuel product selling price change</h3>


                           <div class="container pb-3">

                          
                           <div class="row mt-md-5 mt-3  justify-content-center">
                                    <div class="col-md-12">
                                   
                               <div class="">
        <div class="row mb-3 justify-content-center">
          
            <div class="col-md-12 ">
            
            <div class="row g-3 justify-content-center mb-2">
              <div class="col-6">
                <label class="col-form-label font-weight-bolder">Product Name<sub class="star">*</sub></label>
              </div>
              <div class="col-6">
                <div class="select-container">
                  <select id="" class="form-control " name="id" value={inputValues.product_name} onChange={handleSelect}>
                    <option value="">select</option>
                    {
                      productData.map((data) => {
                        return <option value={data[0]}>{data[1]}</option>
                      })
                    }
                  </select>
                  <div className="errorMsg">{errors.name}</div>
                </div>
                
              </div>
            </div>
            <div class="row g-3 justify-content-center mb-2">
              <div class="col-6">
                <label class="col-form-label font-weight-bolder"> Date</label>
              </div>
              <div class="col-6">
                <input type="date" class="form-control " name="date" min={startDate} max={today}  id={"m_op_date"}  value={inputValues.date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate) :localStorage.getItem("m_op_date")) :inputValues.date} onChange={handleInputs} disabled  />
              </div>
            </div>
            <div class="row g-3 justify-content-center mb-2">
              <div class="col-6">
                <label class="col-form-label font-weight-bolder"> Current Price</label>
              </div>
              <div class="col-6">
                <input type="text" class="form-control " name="price" value={selling_price.price} onChange={handleInputs} readOnly />
              </div>
            </div>
                   <div class="row g-3 justify-content-center mb-2">
                    <div class="col-6">
                      <label class="col-form-label">New Price<sub class="star">*</sub></label>
                    </div>
                    <div class="col-6">
                    <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow " name="new_price" value={inputValues.new_price} onChange={handleInputs} />
                    <div className="errorMsg">{errors.new_price}</div>
                    </div>
                   </div>  
                   <div class="row g-3 justify-content-center mb-2">
                    <div class="col-6">
                      <label class="col-form-label">Difference</label>
                    </div>
                    <div class="col-6">
                    <input type="text" class="form-control " name="sales_price" value={inputValues.sales_price} onChange={handleInput} readOnly />
                    </div>
                   </div>
                   {mode.selling_mode == 3 ?
                   <>
                   <div class="row g-3 justify-content-center mb-2">
                   <div class="col-6">
                     <label class="col-form-label font-weight-bolder">Loose Current Price</label>
                   </div>
                   <div class="col-6">
                     <input type="text" class="form-control " name="selling_price_loose" value={selling_price.loose_price} onChange={handleInputs} readOnly />
                   </div>
                 </div>
                        <div class="row g-3 justify-content-center mb-2">
                         <div class="col-6">
                           <label class="col-form-label">New Price</label>
                         </div>
                         <div class="col-6">
                         <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow " name="new_selling_price_loose" value={inputValues.new_selling_price_loose} onChange={handleInputs} />
                         </div>
                        </div>  
                        <div class="row g-3 justify-content-center mb-2">
                         <div class="col-6">
                           <label class="col-form-label">Difference</label>
                         </div>
                         <div class="col-6">
                         <input type="text" class="form-control " name="sales_price" value={inputValues.differene_loose}  readOnly />
                         </div>
                        </div>
                      </>  :''
                       
}
<div class="modal-footer text-center">
<a href="#" class="btn btn-danger w-auto" onClick={hide}>Cancel</a>
                   {double===true ?  <a class="btn btn-primary w-auto px-4" type="submit" onClick={handleSubmit} >SAVE</a>: <a class="btn btn-primary  w-auto px-4" type="submit"  disabled>SAVE</a>}
                  </div>
            </div>

      

      </div>
                                      
                                </div>
                              </div>
</div>
</div>
</div>
</div>



       </React.Fragment>

    );
}
export default NonFuelProductSellingPriceChangeModal  