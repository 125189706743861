import React from "react";
import ReactTooltip from 'react-tooltip'
import ReactDOM from "react-dom";
const FleetCardModal = ({
  isShowing,preventNonNumericalInput,prevNonNumericalWithDec,
  success,
  hide,
  errors,
  inputValues,
  double,
  handleSubmit,
  stat_operation,
  handleInputs,handleInput
}) => {

 
  return (
    <React.Fragment>
      <div className="modal-overlay" />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal modalm">
          <div className="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              ADD FLEET CARD
            </h5>
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          
          
      
          <div className="form-group mt-2">
            <label>Name <sup class="star">*</sup></label>
            <input
              type="text"
              className="form-control "
              name="name"
              value={inputValues["name"]}
              onChange={handleInputs}
            />
            <div className="errorMsg">{errors.name}</div>
          </div>
         
          {/* <div className="row mb-3 justify-content-center mt-3">
      <label className="col-sm-3 col-form-label mb-2">Name<sup class="star">*</sup></label>
      <div className="col-sm-7">
        <input type="text" className="form-control mb-2" name="name" placeholder="" value={inputValues["name"]} onChange={handleInputs} readOnly={inputValues.ob_is_dayclose}/>
         <div className="errorMsg">{errors.name}</div>
      </div>
      </div> */}
          {/* <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="address"
              value={inputValues["address"]}
              onChange={handleInputs}
            />
          </div> */}
          {/* <div className="form-group">
            <label>Phone</label>
            <input
              type="number"
              className="form-control hide-arrow ph_no"
              onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
              id={"phone"}
              name="phone"
              value={inputValues["phone"]}
              onChange={handleInputs}
            />
            {<div className="errorMsg">{errors.phone}</div>}
          </div> */}
          {/* <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={inputValues["email"]}
              onChange={handleInputs}
            />
            {<div className="errorMsg">{errors.email}</div>}
          </div> */}
          {/* <div className="form-group">
            <label>Whatsapp No.</label>
            <input
              type="number"
              className="form-control hide-arrow ph_no"
              onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
              id={"whatspp"}
              name="whatsapp_no"
              value={inputValues["whatsapp_no"]}
              onChange={handleInputs}
            />
            {<div className="errorMsg">{errors.whatsapp_no}</div>}
          </div> */}
     {console.log(stat_operation,"OI")}  
          <div className="row">
          {stat_operation != "Yes" ?
           <div className="form-group">
           <label>Opening Balance<sup class="star">*</sup></label>
             <input
               type="number"
               onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)}
               className="form-control hide-arrow"
               id={"open"}
               name="opening_balance"
               value={inputValues["opening_balance"]}
               onChange={handleInput}
               readOnly={inputValues["ob_is_dayclose"]}
             />
             <div className="errorMsg">{errors.opening_balance}</div>
           

<span  class="amount-words"></span>
           </div>
           :""}

            <div className="col-md-4 d-flex">
       

            </div>
          


            <div className="row mt-3">
       

            </div>
            <div className="col-md-7">
              
            </div>
            {/* {(inputValues.debit_credit == "Cr" || inputValues.debit_credit =="Dr") && */}



            <div class="modal-footer">
              {double=== true ? (
                <button
                  type="submit"
                  class="btn btn-primary"
                 
                  onClick={handleSubmit}
                >
                  Save
                </button>
              ) : (
                  <button
                    type="submit"
                    class="btn btn-primary"
                    disabled
                  >
                    Save
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FleetCardModal;
