import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import {preventNonNumericalInput} from "../../../layouts/Operational_Sidebar_Progress.component";

const FuelSellPriceModal = ({ isOpen, tank_config,handleClick,handlePopup,handlePopupChange,handlePopupNozzleChange,hide,inputValues,  fields,handlePopupNozzlekeyChange, fuelData, handleSubmit, handleInputs,errors }) => {
   
	
	return (
        <React.Fragment>

          
                 
<div className="modal-overlay" />
				<div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
					<div className="modal modal-dialog-xl ">

						<div className="modal-header mb-3">
							<h5 className="modal-title" id="staticBackdropLabel">{fields.fuel_name}</h5>
							<button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row align-items-center">
								<div className="col-12">
								</div>
							</div>
							<div className="row mb-2 align-items-center">
								<div className="col-6">
									<label>New Price<sub className="star">*</sub></label>
								</div>
								<div className="col-6">
									<input type="number" className="form-control hide-arrow"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} id="new_price_change" name="new_price" value={tank_config.new_price} onChange={handlePopup} />
									<div className="errorMsg">{errors.new_price}</div>
								</div>
							</div>
							{tank_config.map((tank, idx) => {
								return (
									<>

										<div className="row mb-2 align-items-center">
											<div className="col-6">
												<label>{tank.tank_name} (Stock)</label>
											</div>
											<div className="col-6">
												<input type="text" className="form-control" id={"stock" + idx} value={tank.current_stock} name={`current_stock`} onChange={(event) => handlePopupChange(event, idx, event.target.name)}  readOnly/>
											</div>
										</div>
										{tank.nozzles.map((nozzle, index) => {
											return (
												<div className="row mb-2 align-items-center">
													<div className="col-2"></div>
													<div className="col-4">
														<label >{nozzle.nozzle_name} (Reading)</label>
													</div>
													<div className="col-6">
														<input readOnly type="text" className="form-control hide-arrow"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} id={"opening" + idx} value={nozzle?nozzle.opening_reading:''} name={`opening_reading`} onChange={(event) => handlePopupNozzleChange(event, idx, index, event.target.name)} />
													</div>
												</div>
											)
										})}
									</>
								)
							})}

						</div>
						<div className="modal-footer text-center">
							<a className="btn btn-primary px-4" type="submit" onClick={event => { handleClick(event, fields.product_id) }} >Save</a>
						</div>
					</div>
				</div>


       </React.Fragment>

    );
}
export default FuelSellPriceModal