import { Component } from "react";
import inhand from '../../../img/inhand.png'
import store from '../../../img/store.png'
import money from '../../../img/money.png'
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { API_URL } from '../../../constant/API_Settings'
import { WEB_URL } from "../../../constant/API_Settings"
import { useState } from 'react';
import FuelModal from "react-modal";
import LoginHeaderOperational from '../LoginHeaderOperational';
import ReactTooltip from 'react-tooltip'
import DashBoardModal from './AdminDashBoardModal.component'
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import Sidebar_Progress from "../../layouts/Admin_Sidebar_Progress.component";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

const TankModal = (props) => ReactDOM.createPortal(
  <DashBoardModal {...props} />, document.body
);
const AdminDashBoard = (props, progress) => {
  const [graph, setGraph] = useState([])
  const [color, setColor] = useState([])
  const [graph_details, setGraphDetails] = useState([])
  const [cash, setCash] = useState([])
  const [fuel_data, setFuel] = useState([])
  const [fueldata, setFuelDetails] = useState([])
  const [tank_data, setTank] = useState([])
  const [tankdata, setTankDetails] = useState([])
  const [cash_approval, setCashApproval] = useState([])
  const [bank_approval, setBankApproval] = useState([])
  const [total_cash, setTotalCash] = useState([])
  const [critical_stock, setCriticalStock] = useState([])
  const [custm_outstanding, setOutStanding] = useState([])
  const [list_data2, setList2] = useState([])
  const history = useHistory()
  const [page, setpages] = useState(
    {

    })
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);

  }
  const [isClose, setIsClose] = useState(false);
  function toggle() {
    setIsClose(!isClose);

  }
  const data = [
    {
      name: "Page A",
      uv: 100,
      pv: 60,
      pp: 60

    },
    {
      name: "Page B",
      uv: 50,
      pv: 30

    },
    {
      name: "Page C",
      uv: 40,
      pv: 20,
      pp: 50
    },
    {
      name: "Page D",
      uv: 50,
      pv: 0,
      pp: 23
    },
    {
      name: "Page E",
      uv: 20,
      pv: 0,
      pp: 60

    },
    {
      name: "Page F",
      uv: 10,
      pv: 30,
      pp: 23
    },
    {
      name: "Page G",
      uv: 50,
      pv: 30,
      pp: 23
    }
  ];

  //---On Load---//		
  useEffect(() => {
    setpages(data)
    check_meta_date()
  }, []);
  function handleFuelDetails(obj) {

    setFuelDetails(obj.fuel_details)

    toggleModal()
  }
  function handleTankDetails(obj) {
    setTankDetails(obj)
    toggle()

  }

  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        if (responseData.role_id == 1) {
          ListDashBoardItem();
        }
        else {
          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
      });

  }
  //-------------------Dashboard List Item ---(akhitha-20/7/2021)----------//
  function ListDashBoardItem() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
    };
    fetch(`${API_URL}/dashboard`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        setCash({
          ...cash,
          cash_in_hand: responseData.cash_hand,
          cash_in_bank: responseData.cash_bank,
          total_cash: responseData.total_cash_available
        })
        console.log(responseData.cash_in_hand.cash_in_hand_after_approval)
        setCashApproval({
          ...cash_approval,
          cash_in_hand_after_approval: responseData.cash_in_hand.cash_in_hand_after_approval,
          cash_in_hand_before_approval: responseData.cash_in_hand.cash_in_hand_before_approval
        })
        setBankApproval({
          ...bank_approval,
          cash_at_bank_after_approval: responseData.cash_at_bank.cash_at_bank_after_apr,
          cash_at_bank_before_approval: responseData.cash_at_bank.cash_at_bank_before_apr
        })

        // setCash(responseData)
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }

  return (

    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={22} />
          <div className="content-area ">
            <LoginHeaderOperational props={props} page_title="" />
            <div className="container pb-3">


              <div className="row mt-md-5 mt-3 form-container justify-content-center">
                <div className="col-md-12">
                  <div className=" ">
                    <div className="row mb-3 justify-content-center ">
                      <div className="col-md-4">
                        <div className="dash-card ">
                          <div className="row">
                            <div className="col-md-8">
                              <h3>{cash.cash_in_hand}</h3>
                              <p>Cash In Hand </p>
                            </div>
                            <div className="col-md-4">
                              <img src={inhand} className="img-fluid" />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card ">
                          <div className="row">
                            <div className="col-md-8">
                              <h3>{cash.cash_in_bank}</h3>
                              <p>Cash At Bank </p>
                            </div>
                            <div className="col-md-4">
                              <img src={store} className="img-fluid" />
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dash-card ">
                          <div className="row">
                            <div className="col-md-8">
                              <h3>{cash.total_cash}</h3>
                              <p>Total Cash Available </p>
                            </div>
                            <div className="col-md-4">
                              <img src={money} className="img-fluid" />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>




                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <FuelModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >

        <div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Fuel Details</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body">

          </div>
          <div class="table-widget">
            <table class="table border">
              <thead>
                <tr>
                  <th> Tank Name</th>
                  <th>Capacity</th>
                  <th>Current Stock </th>


                </tr>
              </thead>
              <tbody>
                {
                  fueldata.map(obj => (
                    <tr>
                      <td>{obj.tank_name}</td>
                      <td>{obj.capacity}</td>
                      <td>{obj.current_stock}</td>


                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

        </div>

      </FuelModal>
      {isClose ? (<TankModal
        isClose={isClose}
        hide={toggle}
        tankdata={tankdata}
      />) : null
      }


    </>
  )
}


export default AdminDashBoard;

