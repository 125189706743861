import React from "react";
import { Component } from "react";
import axios from 'axios';
import '../../App.css';
import { API_URL } from '../../constant/API_Settings'
import { Link } from "react-router-dom";
import payment from '../../img/payment.png'

class PaymentSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseMsg:'',
            orderId:''
        }
    }
    componentDidMount(){

    }

    

        render() {
        
            return(
    
    <>
    
      <div className="main-container section-container  page-wraper success-msg">
      <div className="container">
              <div className="row justify-content-center ">
              <div class="col-9">
      <div class="form-wrap text-center py-5">
        <div class="img-container">
        <img src={payment} width="120px" alt=".."  />
      </div>
      <h3>Your Payment is Successfull</h3>
      <p>Thank you for your payment. Click here to continue.</p>
      <Link to="/fuel-config">
										<a href="/fuel-config" className="btn btn-primary w-auto">Continue</a>
									</Link>
  </div>
  </div>
</div>
      </div>
    </div>
      </>
    )}}
    
    
export default PaymentSuccess;