
import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom';
import axios from 'axios'
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import DensityAfterUnloadingConfig from './DensityAfterUnloadingConfig.component';
import { API_URL } from "../../../../constant/API_Settings";
import { convertAmountToWords, preventNonNumericalInput } from "../../../layouts/Operational_Sidebar_Progress.component";
import $ from 'jquery'
window.jquery = window.$ = $


const DecantingMSConfig = (unloading, list_data) => {

  const [delete_id, deleteId] = useState([])
  const [unloading_product, setUnloading] = useState([])
  const [inputValues, setInputValues] = useState([]);
  const [fields, setFields] = useState(['']);
  const history = useHistory()
  // const [list_data, setList] = useState([])
  const [inputList, setInputList] = useState([])
  const [fuel_config, SetFuel] = useState([])
  const [errors, setErrors] = useState([])
  const [success, setSuccess] = useState([])
  const radioRef = useRef();
  const [version, setVersion] = useState([])
  const [fuel_configs, SetFuelType] = useState([])
  const [comp, setComp] = useState([])
  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      if (isShowing) {
        setInputValues([])
        setErrors([])
        setSuccess({})
        setFields([''])
      }
      if (!isShowing) {

        setErrors([])

      }
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggle,
    }

  };


  const { isShowing, toggle } = UseModal();
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  const [showFuelTanker, setShowFuelTanker] = useState(false)

  const handleDecant = (e, index, idx, prop) => {
    inputValues["decanting_quantity"] = document.getElementById("decanting_quantity" + index + idx).value
    setInputValues(inputValues)

  }

  useEffect(() => {
    if (unloading.unloading.unloading.unloading) {
      getFuel();
      setInputList({
        ...inputList,
        unloading_id: unloading.unloading.unloading.unloading
      })
    }
    else {
      getFuel1();
      setInputList({
        ...inputList,
        unloading_id: unloading.unloading.unloading
      })
    }
  }, []);



  function toggleReadOnly(index, tankIndex, e) {

    let fuelConfigsData = fuel_config;
    let fuelConfigData = fuelConfigsData[index];

    setInputValues({
      ...inputValues,
      product_id: fuelConfigData.id,

      tank_id: fuelConfigData.tankName[tankIndex].id,
      unloadingProductId: fuelConfigData.unloadingProductId,

    });


  }



  function getFuel() {

    axios(
      {
        url: API_URL + '/unload-list',
        method: "post",
        data: {
          unloading_id: unloading.unloading.unloading.unloading,
          product_id: unloading.unloading.productid.product_id
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        },

      }
    ).then(res => {
      let fuelConfigData = [];
      res.data.fuel_type.map(fuel_data => {
        fuelConfigData.push({
          fuel_name: fuel_data.fuel_name,
          id: fuel_data.fuel_id,
          tankName: fuel_data.tankName,
          noOfComponent: fuel_data.noOfComponent,
          unloadingProductId: fuel_data.unloadingProductId,
          nozzleData: fuel_data.nozzleData,
          no_of_tank: null,
          isReadonly: true,
          isRequired: false,
        });
      })
      // res.data.fuel_type.map(fuel_data => {
      //  console.log(fuel_data.unloadingProductId)
      // setUnloading(fuel_data.unloadingProductId)

      // })
      //  console.log( unloading_product)
      SetFuel(fuelConfigData)


    }).catch(error => {
      console.log(error)
    })
  }
  function getFuel1() {

    axios(
      {
        url: API_URL + '/unload-list',
        method: "post",
        data: {
          unloading_id: unloading.unloading.unloading,
          product_id: unloading.productid.product_id
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
        },

      }
    ).then(res => {
      let fuelConfigData = [];
      res.data.fuel_type.map(fuel_data => {
        fuelConfigData.push({
          fuel_name: fuel_data.fuel_name,
          id: fuel_data.fuel_id,
          tankName: fuel_data.tankName,
          noOfComponent: fuel_data.noOfComponent,
          unloadingProductId: fuel_data.unloadingProductId,
          nozzleData: fuel_data.nozzleData,
          no_of_tank: null,
          isReadonly: true,
          isRequired: false,
        });
      })
      setVersion(res.data.full_version)
      // res.data.fuel_type.map(fuel_data => {
      //  console.log(fuel_data.unloadingProductId)
      // setUnloading(fuel_data.unloadingProductId)

      // })
      //  console.log( unloading_product)
      SetFuel(fuelConfigData)


    }).catch(error => {
      console.log(error)
    })
  }
  const handleDeQuantitySubmit = (event, index, tankIndex) => {
    event.preventDefault();

    sendQuantity(index, tankIndex)
  }

  const CompartmentReset = (index, idx) => {

    document.getElementById('resetSubmit' + index + idx).onclick = function () {
      var radio1 = document.querySelector("#comp1" + index + idx);
      var radio2 = document.querySelector("#comp2" + index + idx);
      var radio3 = document.querySelector("#comp3" + index + idx);
      var radio4 = document.querySelector("#comp4" + index + idx);

      if (radio1) {
        radio1.checked = false; radio1.disabled = false;
      }
      if (radio2) {
        radio2.checked = false; radio2.disabled = false;
      }
      if (radio3) {
        radio3.checked = false; radio3.disabled = false;
      }
      if (radio4) {
        radio4.checked = false; radio4.disabled = false;
      }

    }
    // var radios = document.getElementsByClassName("compartment");

    // for (var i=0, iLen=radios.length; i<iLen; i++) {
    //   radios[i].disabled = false;
    // }
    // var radios1 = document.getElementsByName("comp2");

    // for (var i=0, iLen=radios1.length; i<iLen; i++) {
    //   radios1[i].disabled = false;
    // }
    // var radios2 = document.getElementsByName("comp3");

    // for (var i=0, iLen=radios2.length; i<iLen; i++) {
    //   radios2[i].disabled = false;
    // }
    // var radios3 = document.getElementsByName("comp4");

    // for (var i=0, iLen=radios3.length; i<iLen; i++) {
    //   radios3[i].disabled = false;
    // }
    // document.getElementById(e.target.id).disabled = false

  }



  const handleReadings = (e, nozzleIdx, idx, index, prop) => {
    const nozzleRead = [...fuel_config]
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][prop] = e.target.value
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["close_reading"] = document.getElementById("close_reading" + index + idx + nozzleIdx).value
    const diff = document.getElementById("difference" + index + idx + nozzleIdx)
    for (var i = 0; i < nozzleRead[index].tankName.length; i++) {
      var difference = nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["close_reading"] - nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][prop]
      nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["difference"] = difference

    }

    let readDiff = 0
    for (var i = 0; i < nozzleRead[index].tankName[idx].tankNozzleData.length; i++) {

      readDiff = readDiff + Number(document.getElementById("difference" + index + idx + i).value)
      console.log(Number(document.getElementById("difference" + index + idx + i).value))
    }

    document.getElementById("reading_difference" + index + idx).value = readDiff

    SetFuel(nozzleRead)
  }
  const handleCloseReadings = (e, nozzleIdx, idx, index, prop) => {
    const nozzleRead = [...fuel_config]
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][prop] = e.target.value
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["opening_reading"] = document.getElementById("opening_reading" + index + idx + nozzleIdx).value
    const diff = document.getElementById("difference" + nozzleIdx)

    console.log(nozzleRead[index].tankName[idx].tankNozzleData.length)
    for (var i = 0; i < nozzleRead[index].tankName.length; i++) {
      var difference = nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["close_reading"] - nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["opening_reading"]
      nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["difference"] = difference
      // var eachDiff = document.getElementById("difference"+nozzleIdx)
      var s = document.getElementById("difference" + index + idx + nozzleIdx)
      s.value = difference


    }

    let readDiff = 0
    for (var i = 0; i < nozzleRead[index].tankName[idx].tankNozzleData.length; i++) {

      readDiff = readDiff + Number(document.getElementById("difference" + index + idx + i).value)
      console.log(Number(document.getElementById("difference" + index + idx + i).value))
    }
    document.getElementById("reading_difference" + index + idx).value = readDiff
    document.getElementById("calculated_quantity" + index + idx).value = Number(document.getElementById("stock_difference" + index + idx).value) + Number(document.getElementById("reading_difference" + index + idx).value)
    document.getElementById('eq_decanting_quantity' + index + idx).value = document.getElementById('decanting_quantity' + index + idx).value * 1000
    document.getElementById('excess_short' + index + idx).value = Number(document.getElementById("calculated_quantity" + index + idx).value) - Number(document.getElementById('eq_decanting_quantity' + index + idx).value)

    SetFuel(nozzleRead)
  }
  function sendQuantity(index, tankIndex) {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({

        unloading_id: inputList.unloading_id,
        unloading_product_id: fuel_config[index].unloadingProductId,
        tank_id: fuel_config[index].tankName[tankIndex].id,
        product_id: fuel_config[index].id,
        decanting_quantity: inputValues.decanting_quantity
      })
    };
    fetch(API_URL + "/load-decanting-quantity", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {

        // setSuccess({
        // 	...success,
        // 	disabled: responseData.status === true ? true : false,
        // })

        if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          var stockAfter = document.getElementById('stock_after' + index + tankIndex)
          var stock = responseData.stock_after
          stockAfter.value = stock.toFixed(2)
          var stockBefore = document.getElementById('stock_before' + index + tankIndex).value

          var stockDifference = responseData.stock_after - stockBefore
          document.getElementById('stock_difference' + index + tankIndex).value = stockDifference.toFixed(2)
          document.getElementById("reading_difference" + index + tankIndex).value = 0
          document.getElementById("calculated_quantity" + index + tankIndex).value = Number(document.getElementById("stock_difference" + index + tankIndex).value) + Number(document.getElementById("reading_difference" + index + tankIndex).value)
          document.getElementById('eq_decanting_quantity' + index + tankIndex).value = (document.getElementById('decanting_quantity' + index + tankIndex).value * 1000)
          console.log(Number(document.getElementById("calculated_quantity" + index + tankIndex).value))
          console.log(Number(document.getElementById('eq_decanting_quantity' + index + tankIndex).value))
          document.getElementById('excess_short' + index + tankIndex).value = Number(document.getElementById("calculated_quantity" + index + tankIndex).value) - Number(document.getElementById('eq_decanting_quantity' + index + tankIndex).value)

          history.push("/op-fuel-unloading")
          setErrors([''])
          // setInputValues([])
          toggle();


        }
        let errors = {}

        if (responseData.status == false) {
          errors["decanting_quantity"] = responseData.response.decanting_quantity



        }
        if (responseData.status === 3) {
          swal("", responseData.message, "warning")


        }
        console.log(responseData)
        setErrors(errors)
        return responseData;
      })
      .catch(e => {

      });
  }
  const saveDecant = (index, data) => {
    var tankNozzleData = []
    var stockDiff = []
    var readDiff = []
    var calcQuantity = []
    var eq_decanting_quantity = []
    var excess_short = []
    let lastIndex = fuel_config.length - 1;
    tankNozzleData = fuel_config[index]
    for (var i = 0; i < tankNozzleData.tankName.length; i++) {
      stockDiff.push(Number(document.getElementById("stock_difference" + index + i).value))
      readDiff.push(Number(document.getElementById("reading_difference" + index + i).value))
      calcQuantity.push(Number(document.getElementById("calculated_quantity" + index + i).value))
      eq_decanting_quantity.push(Number(document.getElementById("eq_decanting_quantity" + index + i).value))
      excess_short.push(Number(document.getElementById("excess_short" + index + i).value))
    }

    for (i = 0; i < tankNozzleData.tankName.length; i++) {
      var comp1 = document.getElementById("comp1" + index + i)
      var comp2 = document.getElementById("comp2" + index + i)
      var comp3 = document.getElementById("comp3" + index + i)
      var comp4 = document.getElementById("comp4" + index + i)

      if (comp1 && comp1.checked) {
        tankNozzleData.tankName[i].comp1 = Number(comp1.value)
      }
      else {
        tankNozzleData.tankName[i].comp1 = ''
      }

      if (comp2 && comp2.checked) {
        tankNozzleData.tankName[i].comp2 = Number(comp2.value)
      }
      else {
        tankNozzleData.tankName[i].comp2 = ''
      }
      if (comp3 && comp3.checked) {
        tankNozzleData.tankName[i].comp3 = Number(comp3.value)
      }
      else {
        tankNozzleData.tankName[i].comp3 = ''
      }
      if (comp4 && comp4.checked) {
        tankNozzleData.tankName[i].comp4 = Number(comp4.value)
      }
      else {
        tankNozzleData.tankName[i].comp4 = ''
      }
    }
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        fuelData: tankNozzleData,
        unloading_id: inputList.unloading_id,
        // unloading_product_id:
        stock_difference: stockDiff,
        reading_difference: readDiff,
        calculated_quantity: calcQuantity,
        eq_decanting_quantity: eq_decanting_quantity,
        unloading_product_id: fuel_config[index].unloadingProductId,
        excess_short: excess_short,

      })
    };
    fetch(API_URL + "/unload-decanting-density", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {

        if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          if (lastIndex == index)
            setShowFuelTanker(true)


        }
        if (responseData.status == false) {
          swal("", responseData.message, responseData.status ? "success" : "warning")


        }
        console.log(responseData)
        setErrors(errors)
        return responseData;
      })
      .catch(e => {

      });


  }
  const saveDecantFuel = (index, tankIndex) => {
    var data = fuel_config
    var tank_id = []
    data[index].tankName.map((obj, idx) => {
      tank_id.push(obj.id)
    })

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({

        unloading_id: inputList.unloading_id,
        unloading_product_id: fuel_config[index].unloadingProductId,
        product_id: fuel_config[index].id,
        tank_id: tank_id,
      })
    };
    fetch(API_URL + "/unload-decanting-density", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {

        if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          setShowFuelTanker(true)


        }
        var status = responseData.status == true ? true : false
        setFields(status)
        if (responseData.status == false) {
          swal("", responseData.message, responseData.status ? "success" : "warning")


        }
        console.log(responseData)
        setErrors(errors)
        return responseData;
      })
      .catch(e => {

      });


  }
  function printPage() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        unloading_id: inputList.unloading_id
      })
    };
    fetch(API_URL + "/download_fuel-unload", requestOptions)
      .then(response => { return response.blob(); })
      .then(responseData => {
        var blob = new Blob([responseData]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "Fuel_unload.pdf";
        link.click();
        setShowFuelTanker(true)
        return responseData;
      })
      .catch(e => {
        console.log(e);
      });
  }

  function handleRadio(e, index, idx) {

    setComp({
      ...comp,
      [e.target.name]: e.target.value
    })

    // var radios = document.getElementsByName(e.target.name);

    // for (var i=0, iLen=radios.length; i<iLen; i++) {
    //   radios[i].disabled = true;
    // }
    document.getElementById(e.target.id).disabled = false


  }



  return (
    <>
      {
        fuel_config.map((fuelType, index) => {
          console.log(fuelType)

          return (
            <>
              <h5 class="modal-title" id="exampleModalLabel">Decanting {fuelType.fuel_name} </h5>
              <div class="container pb-3">


                <div class="row mb-3 justify-content-center">

                  <div class="">
                    <div class="">
                      <div class="row">

                        <div class="mt-5" class="tab-pane fade show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">
                          <p className=""><b></b></p>

                          {fuelType.tankName.map((tank, idx) => {
                            return (
                              <div class="row mt-4 ">

                                <div class="row mt-3">

                                  {/* <div class="d-flex col-6"> */}
                                  {/* <div class="col-md-1 text-center   "></div> */}

                                  <div className="form-check col-md-3 d-flex   ">
                                    <div class=""><p>Decanting Quantity in KL</p></div>
                                    <a>  <input className="form-check-input col-md-3 " name="tank_id" type="checkbox" id={"tank_id" + idx} value={tank.id}
                                      onClick={(e) => { toggleReadOnly(index, idx, e) }} />
                                    </a><label className="form-check-label col-md-3 " for="examplecheckboxs1">  <h6>{tank.tank_name}</h6>
                                    </label>
                                  </div>
                                  <ReactTooltip />

                                  {/* <div class="row  "> */}

                                  {/* <div class="col-md-12  text-center    "> */}
                                  <div className="col-md-3 mx-3">
                                    <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow" name="decanting_quantity" placeholder="Decanting quantity" id={"decanting_quantity" + index + idx} onChange={(e) => handleDecant(e, index, idx, e.target.name)}></input>
                                    <div className="errorMsg">{errors.decanting_quantity}</div>

                                  </div>
                                  <div className="col-md-3 "><a className="btn btn-primary mb-2" onClick={(event) => handleDeQuantitySubmit(event, index, idx)}>save</a></div>
                                  {/* </div> */}
                                </div>

                                {version == "No" ? "" :

                                  <div class="row mt-3   ">

                                    <div class="col-3 ">
                                      <label className="" for="examplecheckboxs1">Stock Before(L)</label>
                                      <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow mb-2" name="stock_before" id={"stock_before" + index + idx} readOnly value={tank.stock}></input>
                                    </div>
                                    <div class="col-3 ">
                                      <label className="" for="examplecheckboxs1">Stock After(L)</label>
                                      <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow " name="stock_after" id={"stock_after" + index + idx} readOnly></input>
                                    </div>

                                    <div className=" d-flex mt-3">


                                      <div class="   col-md-3"><p>Compartment number </p></div>
                                      {fuelType.noOfComponent === 1 &&
                                        <>
                                          <div className="form-check col-md-2   ">

                                            <a>  <input className={"form-check-input compartment" + index + idx} type="radio" name="comp1" id={"comp1" + index + idx} value="1" onChange={handleRadio} />
                                            </a><label className="form-check-label " for="examplecheckboxs1">   01
                                            </label>
                                            <ReactTooltip />

                                          </div>
                                        </>}
                                      {fuelType.noOfComponent === 2 &&
                                        <>
                                          <div className="form-check col-md-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio"
                                              name="comp1" value="1" id={"comp1" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1" >  01
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                          <div className="form-check col-md-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio"
                                              name="comp2" value="2" id={"comp2" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  02
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                        </>}
                                      {fuelType.noOfComponent === 3 &&
                                        <>
                                          <div className="form-check col-md-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio"
                                              name="comp1" value="1" id={"comp1" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  01
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                          <div className="form-check col-md-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio" name="comp2"
                                              value="2" id={"comp2" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  02
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                          <div className="form-check col-md-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio" name="comp3"
                                              value="3" id={"comp3" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  03
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                        </>}

                                      {fuelType.noOfComponent === 4 &&
                                        <>
                                          <div className="form-check col-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio" name="comp1"
                                              value="1" id={"comp1" + index + idx} onClick={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  01
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                          <div className="form-check col-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio" name="comp2"
                                              value="2" id={"comp2" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  02
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                          <div className="form-check col-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio" name="comp3"
                                              value="3" id={"comp3" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  03
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                          <div className="form-check col-1 ">

                                            <input className={"form-check-input compartment" + index + idx} type="radio" name="comp4"
                                              value="4" id={"comp4" + index + idx} onChange={handleRadio} />
                                            <label className="form-check-label " for="examplecheckboxs1">  04
                                            </label>
                                            <ReactTooltip />
                                          </div>
                                        </>}
                                      <a class="reset_submit" id={"resetSubmit" + index + idx} onClick={() => CompartmentReset(index, idx)}> Reset <i class="fa fa-refresh" aria-hidden="true"></i> </a>
                                      {/* <a class="reset_submit"  onClick={CompartmentReset}> Reset <i class="fa fa-refresh" aria-hidden="true"></i> </a> */}
                                    </div>
                                  </div>}
                                {version == "No" ? "" :
                                  tank.tankNozzleData.map((nozzle, nozzleIdx) => {
                                    return (
                                      <>
                                        <div class="row mt-4">

                                          <div class=" py-2  col-md-3"><p>Nozzle Name(Auto)</p></div>
                                          <div class=" py-2  col-md-3"><p>Reading before decanting</p></div>
                                          <div class=" py-2  col-md-3"><p>Reading after decanting </p></div>
                                          <div class=" py-2  col-md-3"><p>Difference </p></div>
                                        </div>
                                        <div class="row">
                                          <div class="col-md text-center pe-2 ">
                                            <input type="text" class="form-control " name="nozzle_name" defaultValue={nozzle.nozzle_name} readOnly></input>
                                          </div>

                                          <div class="col-md text-center pe-2">
                                            <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow " name="opening_reading" id={"opening_reading" + index + idx + nozzleIdx} defaultValue={nozzle.close_reading == null ? nozzle.opening_reading : nozzle.close_reading} onChange={(event) => handleReadings(event, nozzleIdx, idx, index, event.target.name)}></input>
                                          </div>

                                          <div class="col-md text-center pe-2">
                                            <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow " name="close_reading" id={"close_reading" + index + idx + nozzleIdx} defaultValue={nozzle.close_reading == null ? nozzle.opening_reading : nozzle.close_reading} onChange={(event) => handleCloseReadings(event, nozzleIdx, idx, index, event.target.name)}></input>
                                          </div>
                                          <div class="col-md text-center pe-2">
                                            <input type="text" class="form-control  " name="difference" id={"difference" + index + idx + nozzleIdx} value={nozzle.difference} readOnly ></input>
                                          </div>

                                        </div>

                                      </>
                                    )
                                  })}
                                {version == "No" ? "" :
                                  <div class="row g-3 justify-content-center mt-3 mb-2">

                                    <div class="col ">
                                      <label class=" ">Stock Difference(L)</label>
                                      <div class="d-md-flex"><input type="text" name="stock_difference" id={"stock_difference" + index + idx} value={tank.stock_difference} class="form-control " readOnly />
                                        <p class="ps-2 pt-1">+</p></div>
                                    </div>
                                    <div class="col ">
                                      <label class=" ">Reading Difference(L)</label>
                                      {console.log(tank)}
                                      <div class="d-md-flex"><input type="text" name="reading_difference" id={"reading_difference" + index + idx} value={tank.reading_difference} class="form-control " readOnly />
                                        <p class="ps-2 pt-1">=</p></div>
                                    </div>
                                    <div class="col ">
                                      <label class="">Calulated Quantity(L)</label>
                                      <div class="d-md-flex"><input type="text" name="calculated_quantity" id={"calculated_quantity" + index + idx} readOnly class="form-control  " />
                                        <p class="ps-2 pt-1">-</p></div>
                                    </div>
                                    <div class="col ">
                                      <label class=" ">Decanting Quantity(L)</label>
                                      <input type="text" class="form-control " name="eq_decanting_quantity" id={"eq_decanting_quantity" + index + idx} readOnly />


                                    </div>
                                    <div class="col-2 ">
                                      <label class=" ">Excess/Short(L)</label>
                                      <input type="text" class="form-control  " name="excess_short" id={"excess_short" + index + idx} readOnly />

                                    </div>

                                  </div>
                                }
                              </div>
                            )
                          })}



                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                {version == "No" ? "" :
                  <div class="">
                    <div className="modal-footer justify-content-end p-0 "><a className="btn btn-primary m-btn w-auto " onClick={() => saveDecant(index, fuelType)}>save/Continue</a></div>

                  </div>}
                {version == "No" &&
                  <div class="">
                    <div className="modal-footer justify-content-end p-0 "><a className="btn btn-primary m-btn w-auto " onClick={() => saveDecantFuel(index, fuelType)}>save/Continue</a></div>

                  </div>}

              </div>
            </>)
        })}
      <div className="row justify-content-center">
        <div class="col-md-3 modal-footer">
          {fields == true && version == "No" ? <a onClick={printPage} className="btn btn-success  mb-2"  >print</a> : ""}</div>
      </div>
      {version == "No" ? "" :
        showFuelTanker ? <div>
          <DensityAfterUnloadingConfig unloading={unloading} />

        </div> : null}
    </>
  )
}








export default DecantingMSConfig