
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import React, { useState,useMemo } from "react";
import museum from  '../../img/museum.svg'
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import {  useEffect} from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PaginationComponent from '../layouts/PaginationComponent';

import MachineriesConfigModal from './MachineriesConfigModal';
import Loader from '../operational-model/Employee/Loader.component'
import {convertAmountToWords,preventNonNumericalInput} from '../layouts/Sidebar_Progress.component'
var MachineriesModal = (props) => ReactDOM.createPortal(
  <MachineriesConfigModal {...props} />, document.body
       );
       const  PaginationComp  = (props) => (
        < PaginationComponent  {...props} />
           );
const MachineriesConfig = (location) => {
  const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);
  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);
    function toggle() {
      var isdayclose = checkoption.isdayclose;
      if(isdayclose == 1)
      {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else
      {
      if(isShowing) 
     {
        setInputValues({})
        setErrors({})
    }
    if(!isShowing)
    {
      setDouble(true)
    }
      setIsShowing(!isShowing);
      
      
      
    } 
  }
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])
    const [permission, setPermission] = useState([])
  const [search,setSearch]=useState('')
  const  handleSearch = (event) =>
  {
	 setSearch(event.target.value)
  }
  const [pages,setpages]=useState(
    {
    current_page:1,
    last_page:0,
    total_pages:0,
    pagination_item:[],
    total_pages:0,
  })
  
  const handlePagination = (number) =>
      {
          setpages({
            ...pages,
            current_page:number
          }
              )
    
              ListAssets(number)
    
      }
 
  
  const [inputValues,setInputValues] = useState({});
  const [errors,setErrors] = useState({
    asset_name:"" ,
   date_of_purchase:"",
   purchase_price:"",
    current_price:""
  })
function	validateForm() {
  let errors = {}
    let formIsValid = true
    if (!inputValues.asset_name) {
      setDouble(true)
        formIsValid = false;
        errors["asset_name"] = "Enter Asset Name"
    }
    else{
      errors["asset_name"] =""
    }
  
  setErrors(errors)

    return formIsValid;


}

//------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
        if(location.location.pathname == "/assets/assets")
				{
					history.push(location.location.pathname);
				}
				else
				{
				history.push(responseData.url);
				}
			  ListAssets(pages.current_page);
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}


const handleInputs = (e) => {
  convertAmountToWords(e,e.target.value)
 setInputValues({
    ...inputValues,
    [e.target.name]: e.target.value
  });
};

const handleInput = (e) => {
  convertAmountToWords(e,e.target.value)
  setInputValues({
     ...inputValues,
     [e.target.name]: e.target.value
   });
 };
  const {isShowing, toggle} = UseModal();

  const history = useHistory()
  const handleSubmit = (event ) =>
  {
    event.preventDefault();
    setDouble(false)
    if(validateForm())
    sendNoteData()
  
  }
  const handleSkip = (event) =>
  {
    event.preventDefault();
    setDouble(false)
    history.push("other-income")
  }
 
  async function edit_product(id)
{
 
      const requestOptions = {
      method: 'get',  
      data: {id:id},    
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type":"application/json",
        "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
        "Accept": "application/json"
    
       },  
  };
   await fetch(API_URL+"/asset/"+id+"/edit",requestOptions)
          .then(response => { return response.json();})
  .then(responseData => {
   
   setInputValues(
    {
      id:responseData.data.id ? responseData.data.id : '',
      asset_name : responseData.data.asset_name,
      date_of_purchase:responseData.data.date_of_purchase,
      purchase_price:responseData.data.purchase_price,
      current_price:responseData.data.current_price,  
      ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,   
    }
  );
// }) 
       toggle();

return responseData;
    })
          .catch(e => {
              console.log(e);
             
          });
}
  async function sendNoteData()
  {
          const requestOptions = {
          method: 'POST',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
           },
             body: JSON.stringify(inputValues)
      };
      await fetch(API_URL+"/asset",requestOptions)
              .then(response => 
                { 
                   return response.json();})
      .then(responseData => { 
       
        let errors = []
        if(responseData.status == false)
              {
                errors["asset_name"] = responseData.response.asset_name
                setDouble(true)
              }
        else if(responseData.status == 3){
          errors["asset"] = responseData.message
                // swal("",responseData.message,responseData.status?"warning":"warning")
                setDouble(true)
              }
        else if(responseData.status == true){

        swal("",responseData.message,responseData.status?"success":"warning")
        setDouble(true)
        setInputValues("")
        // setErrors("")
        toggle();
        ListAssets(pages.current_page);
      }
      setErrors(errors)
      
         return responseData;
        })  .catch(e => {
                   swal("Something went wrong","","warning")
                  console.log(e);     
                  setDouble(true)
              });
  }
 
useEffect(() => {
  setLocation(location.location.pathname)	
          check_url()
       
       },[])  
       const [path, setLocation] = useState([])
   const [list_data,setList] = useState([]) 
   //loader
const [Loading,setLoading]=useState(false);

const [checkoption, ckeckOption] = useState([])
   async function ListAssets(page)
  {
          const requestOptions = {
          method: 'get',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
        
           },
      };
      
       fetch(`${API_URL}/asset?search_keyword=${search}&page=${page}` ,requestOptions)
              .then(response => { return response.json();})
      .then(responseData => {
        if(responseData.status==3)
			{
				setPermission({...permission,status:responseData.status,message:responseData.message})
			}
    
     setList(responseData.data.data)
     ckeckOption({...checkoption,
			isdayclose:responseData.isdayclose})
 

        
           setpages({
            ...pages,
            current_page: responseData.data.current_page,
            last_page: responseData.data.last_page,
            per_pages:  responseData.data.per_page,
            total_pages:responseData.data.total,
             
            })
  
 setLoading(true)
 return responseData;
        })
              .catch(e => {
                  console.log(e);
                 
              });
  }
  function delete_assets(id)
  {
    var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
      else
      {
  
    deleteId(id)
   
    toggleModal()
  }
}
  function deleteAssets()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/asset/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListAssets(pages.current_page);
           toggleModal()
           setDouble(true)
       return responseData;
     
   
         })
   
     .catch(err=>{
      swal("Something went wrong","","warning")
      console.log(err)
         })
  }

return(
        <>
          <div className="main-container page-wrap">
<div className="d-md-flex">
{path == "/assets/assets"? <Sidebar_Progress path={path} />:<Sidebar_Progress />}
<div className="content-area">
<LoginHeader page_title="" />
<div className="container pb-3">

<div className="row">

 <div className="col-md-12 mb-3 px-4 mt-3">{path == "/assets/assets"? <Link to={backPage || "/operational-date"}>
        <a href="#" className="float-end btn btn-gray">Go Back</a>
      </Link>: <Link to="/tanker-expense">
        <a href="tanker-expense" className="float-end btn btn-gray">Back</a>
      </Link>}</div>
 </div>

	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
    {permission.status==3 ?
										<div  className="form-wrap mt-5">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>

									<div>
                  {Loading ?  ListAssets: 
	<Loader/>
	}
			<div className="form-wrap list-wrp" >
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
            
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col-md-4 ps-0">
							<h3 className="table-title mt-2">Machineries/Assets</h3>
						</div>
            <span align='center'><p style={{color:"red",marginTop: "120px",fontSize: "25px"}}>Currently not available</p></span>
						<span align='center'><p style={{color:"red",marginTop: "-15px",fontSize: "15px"}}>You can access this feature from next version.</p></span>
            {/* <div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#bank" onClick={toggle}>Add Machineries/Assets</a>
						</div> */}
						</div>
					</div>
					{/* <div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Machinery/Asset Name "  value={search.search_keyword} onChange={handleSearch}    />
<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

</div>
					</div> */}
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    {/* <tr>
      <th>Machinery/Asset Name</th>
      <th>Date of Purchase</th>
      <th>Purchase Price</th>
      <th>Current Value</th>
      <th>Actions</th>
    </tr> */}
   
  </thead>
  <tbody>

  {
list_data.map(asset => (
  <tr>
      <td>{asset.asset_name}</td>
       <td>{asset.date_of_purchase}</td>
       <td>{asset.purchase_price}</td>
       <td>{asset.current_price}</td>
      <td><div className="d-flex icon-btn">
      {/* <td onDoubleClick={(event)=>history.push("/customers/edit/"+customer.id)}><strong>{customer.name}</strong></td> */}
      <button  onClick={()=> edit_product(asset.id)} >  <i className="fa fa-pencil" aria-hidden="true" > </i>
</button>
      <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_assets(asset.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>
</button>

      </div>
      </td>
    </tr>   
))}
</tbody>
</table>
{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
				</div>
			</div>
      {path == "/assets/assets"?"": double == true ? <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={handleSkip}>skip/Continue</a></div>:<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" disabled onClick={handleSkip}>skip/Continue</a></div>}
		</div>
    </>}
    </div>
	</div>

</div>
</div>
</div>
</div>
{isShowing ? (<MachineriesModal
        isShowing={isShowing}
        hide={toggle}
        double={double}
        handleInputs={handleInputs}
        preventNonNumericalInput={preventNonNumericalInput}
        handleInput={handleInput}
        inputValues={inputValues}
        errors={errors}
        handleSubmit={handleSubmit}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div className="modal-content">
				<div className="modal-title modal-header"><h5 className="modal-title" id="exampleModalLongTitle">MACHINERIES/ASSETS</h5><button type="button"   onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div className=" modal-body  modal-body"><p>Are you sure you want to delete this MACHINERIES/ASSETS</p></div>
				<div className="justify-content-end  modal-footer"><div className="d-flex">
					<button type="button" className="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
				{double == true ?	<button type="submit" className="btn btn-primary"  onClick={ deleteAssets}>Yes</button>:<button type="submit" className="btn btn-primary" disabled  onClick={ deleteAssets}>Yes</button>}
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>
     

</>
    )
}
    
export default MachineriesConfig