
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { useState ,useMemo } from 'react';
import ReactDOM from 'react-dom';
import museum from  '../../img/museum.svg'
import React from 'react';
import OwnTankerModal from './OwnTankerModal'
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import {  useEffect} from 'react';
import { API_URL } from "../../constant/API_Settings";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import { preventNonNumericalInput } from '../layouts/Sidebar_Progress.component'
import PaginationComponent from '../layouts/PaginationComponent';

var TankerModal = (props) => ReactDOM.createPortal(
	<OwnTankerModal  {...props} />, document.body
		 );
     const  PaginationComp  = (props) => (
      < PaginationComponent  {...props} />
         );
const OwnTankerConfig = (location) => {
  const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
  const [double, setDouble] = useState(true);	
  const UseModal = () => {
 
    const [isShowing, setIsShowing] = useState(false);
  
    function toggle() {
      var isdayclose = checkoption.isdayclose;
    if(isdayclose == 1)
    {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else
    {
      if(isShowing) 
      {
         setInputValues({})
      
         setErrors({})
        
     }
     if(!isShowing) 
     {
        // setInputValues({})
        setDouble(true)
        setErrors({})
       
    }
      setIsShowing(!isShowing);
    }
  }
  
    return {
      isShowing,
      toggle,
    }
  };
  const [isOpen, setIsOpen] = useState(false);
	  function toggleModal() {
		setIsOpen(!isOpen);
	  }
    const[delete_id ,deleteId] = useState([])
    const [checkoption, ckeckOption] = useState([])

  const {isShowing, toggle} = UseModal();
  const [inputValues, setInputValues] =useState({});
  const history = useHistory()
  const [list_data, setList] = useState([])
  const [payment_id, setId] = useState([])
  const [permission, setPermission] = useState([])
  const [errors, setErrors] = useState([ ])
  const handleInputs = (e) => {


    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelect = (e) => {
 

    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };
  const [pages,setpages]=useState(
    {
    current_page:1,
    last_page:0,
    total_pages:0,
    pagination_item:[],
    total_pages:0,
  })
  
  const handlePagination = (number) =>
      {
          setpages({
            ...pages,
            current_page:number
          }
              )
    
              ListTankDetails(number)
    
      }
      const [search,setSearch]=useState('')
      const  handleSearch = (event) =>
      {
       setSearch(event.target.value)
      }
  useEffect(() => {
    check_url();
    document.title = "Tanker Configuration"
    setLocation(location.location.pathname)	
  
  
  },[])
  //------------------------url
function check_url() {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
        if(location.location.pathname == "/owntanker-config/owntanker-config")
				{
					history.push(location.location.pathname);
				}
				else
				{
				history.push(responseData.url);
				}
			  ListTankDetails(pages.current_page);
			}
			else{
				
				history.goBack();
			}

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
function check_meta_date() {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
      console.log(e)
		});

}
//--------------------------//

  function validateForm() {
    let errors = []
    let formIsValid = true

    if(!inputValues.reg_number){
      formIsValid = false;
      setDouble(true)
      errors["reg_number"] = "Enter Registration Number"
    }
    if(!inputValues.total_capacity){
      formIsValid = false;
      setDouble(true)
      errors["total_capacity"] = "Enter total capacity"
    }
    if(inputValues.total_capacity >200){
      formIsValid = false;
      setDouble(true)
      errors["total_capacity"] = "Capacity should less than 200 Kl"
    }
    if(!inputValues.no_of_containers){
      formIsValid = false;
      setDouble(true)
      errors["no_of_containers"] = "Choose number of containers"
    }
    /*if(!inputValues.capacity_of_con1){
      formIsValid = false;
      errors["capacity_of_con1"] = "Enter capacity of container 1"
    }
    if(!inputValues.capacity_of_con2){
      formIsValid = false;
      errors["capacity_of_con2"] = "Enter capacity of container 2"
    }
    if(!inputValues.capacity_of_con3){
      formIsValid = false;
      errors["capacity_of_con3"] = "Enter capacity of container 3"
    }
    if(!inputValues.capacity_of_con4){ 
      formIsValid = false;
      errors["capacity_of_con4"] = "Enter capacity of container 4"
    }
    if(!inputValues.ac_name){
      formIsValid = false;
      errors["ac_name"] = "Enter Tanker Income Head"
    }
    if(!inputValues.ac_name1){
      formIsValid = false;
      errors["ac_name1"] = "Enter Tanker Expense"
    }*/
    setErrors(errors)
    return formIsValid;

  }

  const handleSubmit = (event ) =>
  {
    event.preventDefault();
  	setDouble(false)
    if(validateForm()){
      saveTankerConfig()
    }
  }

  function saveTankerConfig()
    {
    
        const requestOptions = 
        {
          method: 'POST',
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type":"application/json",
              "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
              "Accept": "application/json"
            },
            body: JSON.stringify(inputValues)
        };
         fetch(API_URL+"/tanker",requestOptions)
            .then(response => 
              { 
                  return response.json();})
              .then(responseData => {
            
              let errors = []
              if(responseData.status == false)
              {
                
                errors["reg_number"] = responseData.response.reg_number
                errors["total_capacity"] = responseData.response.message
                errors["capacity_of_con1"] = responseData.response.capacity_of_con1
                errors["capacity_of_con2"] = responseData.response.capacity_of_con2
                errors["capacity_of_con3"] = responseData.response.capacity_of_con3
                errors["capacity_of_con4"] = responseData.response.capacity_of_con4
                errors["ac_name1"] = responseData.response.expense_error_msg
                errors["ac_name"] = responseData.response.income_error_msg
                setDouble(true)
            
              }else if(responseData.status == true){
                  swal("", responseData.message,responseData.status ? "success":"danger")
                  setDouble(true)
                  toggle();
                  ListTankDetails(pages.current_page);
                  setInputValues("")
                  return responseData;
                  setErrors({})
                }
                setErrors(errors)
                return responseData;
            })  .catch(e => {
              swal("Something went wrong","","warning")
                console.log(e);  
                setDouble(true)   
            });
    }

  async function edit_tankConfig(id)
    {
        const requestOptions = {
          method: 'get',
          data : {id:id},
          headers: {
            "Access-Control-Allow-Origin" : "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"

          },
        };
        await fetch(API_URL+"/tanker/"+id+"/edit",requestOptions)
              .then(response => {return response.json();})
            .then(responseData => {
              setInputValues(
                {
                  reg_number : responseData.data.reg_number,
                  total_capacity: responseData.data.total_capacity,
                  no_of_containers: responseData.data.no_of_containers,
                  capacity_of_con1: responseData.data.capacity_of_con1,
                  capacity_of_con2: responseData.data.capacity_of_con2,
                  capacity_of_con3: responseData.data.capacity_of_con3,
                  capacity_of_con4: responseData.data.capacity_of_con4,
                  ac_name:responseData.data.ac_name,
                  ac_name1:responseData.data.ac_name1,
                  id:responseData.data.id ? responseData.data.id: '',
                  ob_is_dayclose: responseData.ob_is_dayclose == 1? true :false,
                }
              );
              toggle();

              return responseData;
            })
        .catch(e => {
          console.log(e);
        });
    }

   
    	//loader
      const [path, setLocation] = useState([])
const [Loading,setLoading]=useState(false);
  function ListTankDetails(page)
  {
          const requestOptions = {
          method: 'get',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
        
           },
      };
        // fetch(API_URL+"/tanker",requestOptions)
        fetch(`${API_URL}/tanker?search_keyword=${search}&page=${page}` ,requestOptions)
              .then(response => { return response.json();})
      .then(responseData => {
        if(responseData.status==3) 
        {
          setPermission({...permission,status:responseData.status,message:responseData.message})
        }
     setList(responseData.data.data) 
     ckeckOption({...checkoption,
			isdayclose:responseData.isdayclose})
     
                       setpages({
                        ...pages,
                        current_page: responseData.data.current_page,
                        last_page: responseData.data.last_page,
                        per_pages:  responseData.data.per_page,
                        total_pages:responseData.data.total,
                         
                        })
   
     setLoading(true)
 return responseData;
 
        })
              .catch(e => {
                  console.log(e);
                 
              });
  }

  function delete_tanker(id)
  {
    var isdayclose = checkoption.isdayclose;
			if(isdayclose == 1)
			{
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
      else
      {
  
    deleteId(id)
   
    toggleModal()
  }
  }
  function deleteTanker()
  {
   setDouble(false)
   // console.log(...delete_id)
   const requestOptions = {
     method: 'DELETE',  
   
     headers: {
       "Access-Control-Allow-Origin": "*",
       "Content-Type":"application/json",
       "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
       "Accept": "application/json"
     
      }, 
      body: JSON.stringify(delete_id)
   };
   fetch(API_URL+"/tanker/"+delete_id,requestOptions)
       .then(response => { return response.json();})
       .then(responseData => {
           swal("",responseData.message,responseData.status? "success":"warning")
           ListTankDetails(pages.current_page);
           toggleModal()
           setDouble(true)
       return responseData;
     
   
         })
   
     .catch(err=>{
            console.log(err)
            swal("Something went wrong","","warning")
         })
  }

    return(
        <>
          <div className="main-container page-wrap">
<div className="d-md-flex">
{path == "/owntanker-config/owntanker-config"?<Sidebar_Progress path={path}/>:<Sidebar_Progress />}
<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">
<div class="row">
 <div className="col-md-12  px-4 mt-3"> {path == "/owntanker-config/owntanker-config"?<Link to={backPage || "/operational-date"}>
        <a href="open-balance" className="float-end btn btn-gray">Go Back</a>
      </Link>:<Link to="/open-balance">
        <a href="open-balance" className="float-end btn btn-gray">Back</a>
      </Link>}</div>
 </div>
 
	<div className="row justify-content-center mt-md-3 mt-3 form-container">
		<div className="col-md-12">
    {permission.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{permission.message}
											</div></div>:
											<>
                      <div>
                      {Loading ? ListTankDetails : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	}
			<div className="form-wrap list-wrp">
				<div className="row mb-3">
					<div className="col-md">
						<div className="row">
							<div className="col-md-1 col-2 pe-0">
							<img src={museum} className="img-fluid" alt=".." width="25px"/>
						</div>
							<div className="col ps-0">
							<h3 className="table-title"> Own Tanker Configuration</h3>
              {/* <div className="col-md-12 mb-3"><Link to="/fuel-config"><a href="fuel-config" className="float-end btn btn-gray">Back</a></Link></div> */}

						</div>
						<div className="col-md">
							<a className="btn btn-primary px-3 mb-md-0 mb-2 "  data-bs-target="#bank" onClick={toggle}>Add Tanker</a>
						</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="input-group mb-3 search">
  <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
</span>
<input type="text" className="form-control" placeholder="Search With Registration No "  value={search.search_keyword} onChange={handleSearch}    />
<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

</div>
					</div>
				</div>
				<div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Registration Number</th>
      <th>Total Capacity in KL</th>
      <th>Number of Containers</th>
      <th>Capacity of Containers</th>
     
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
  {
list_data.map(container => (      
     <tr> 
      <td>{container.reg_number} </td>
       <td>{container.total_capacity}</td>
      <td>{container.no_of_containers}</td>
      <td>Total Capacity: {container.total_capacity}
      <ul style={{ listStyleType: "none" }}>
            {container.capacity_of_con1!=null && <li> Container 1 : {container.capacity_of_con1} </li>}
            {container.capacity_of_con2!=null && <li> Container 2 : {container.capacity_of_con2} </li>}
            {container.capacity_of_con3!=null && <li> Container 3 : {container.capacity_of_con3} </li>}
            {container.capacity_of_con4!=null && <li> Container 4 : {container.capacity_of_con4} </li>}
          </ul>
      </td>
      
      
      <td><div className="d-flex icon-btn">
      <button  onClick={()=> edit_tankConfig(container.id)}>  <i className="fa fa-pencil" aria-hidden="true"></i>
</button>
      <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={()=> delete_tanker(container.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>

</button>


      </div></td>
    </tr>
))}
</tbody>
</table>
{Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
				</div>
			</div>
      {path == "/owntanker-config/owntanker-config"? "":<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/tanker-income")}>Skip/Continue</a></div>	}
		</div>
    </>}
	</div>
</div>
</div>

{isShowing ? (<TankerModal
        isShowing={isShowing}
        hide={toggle}
        handleInputs={handleInputs}
        preventNonNumericalInput={preventNonNumericalInput}
     
        inputValues={inputValues}
        double={double}
		// fields={fields}
        // dropdownData ={dropdownData}
        handleSubmit={handleSubmit}
		// handleChange={handleChange}
		// handleAdd={handleAdd}
		// handleRemove ={handleRemove}
        errors={errors}
      />) : null
      }

<Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
        >
			<div class="modal-content">
				<div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">OWN TANKER CONFIGURATION</h5><button type="button"   onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
				<div class=" modal-body  modal-body"><p>Are you sure you want to delete this Tanker details</p></div>
				<div class="justify-content-end  modal-footer"><div class="d-flex">
					<button type="button" class="btn btn-secondary me-2"   onClick={toggleModal}>No</button>
				{double ==true	?<button type="submit" class="btn btn-primary"  onClick={ deleteTanker}>Yes</button>:	<button type="submit" class="btn btn-primary" disabled onClick={ deleteTanker}>Yes</button>}
					</div>
					</div>
					</div>
			
		
            
			
      </Modal>


</div>
</div>
</div>

</>
    )
} 
      
export default OwnTankerConfig