import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../Loader.component'
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import FleetCardModal from './FleetCardModal.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import DeleteModalConfig from './DeleteModalConfig.component'
import { useRef } from 'react';
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';
import { convertAmountToWords } from "../../../layouts/Operational_Sidebar_Progress.component";
const FleetModal = (props) => ReactDOM.createPortal(
	<FleetCardModal  {...props} />, document.body
);
const DeleteModal = (props) => ReactDOM.createPortal(
	<DeleteModalConfig  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const FleetCardSalesConfig = (props) => {

	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [input, setInput] = useState([]);
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [clickCount, setClickCount] = useState(0);
	const [summary, setSummary] = useState([])
	const [double, setDouble] = useState(true);
	const [search, setSearch] = useState('')
	const [new_price, setPrice] = useState({})
	const [list_data, setList] = useState([])
	const [input2, setInput2] = useState({});
	const [errors, setErrors] = useState({})
	const [total, setTotal] = useState([])
	const [success, setSuccess] = useState(true)
	const [productData, setProduct] = useState([])
	const [fleetcarddropdown, setfleetcarddropdown] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {
		check_url();

	}, []);


	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput({ ...input, op_date: responseData.op_date })
					getSummerySheet();
					ListFleetCard(pages.current_page)
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}

	//---------------------------end
	function ListFleetCard(page) {
		const requestOptions = {      //---Fleetacard listing---//
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(`${API_URL}/fleet_card_sale?search_keyword=${search}&page=${page}&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setList(responseData.data.data)

				setInput2({ ...input2, isdayclose: responseData.isdayclose })
				setTotal({
					...total,
					totalamount: responseData.total_amount,
				})

				setLoading(true);


				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});
	}
	//product name dropdown api integration//
	function getProduct() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(`${API_URL}/get_product_name?op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				
				var datas = []

				responseData.forEach(elem => {
					console.log(elem,"kakk")
					datas.push([elem.id, elem.fuel_name])
				})

				setProduct(datas);
			
				
				return responseData;
			})
			.catch(e => {
				console.log(e);
				

			});
	}
	function getfleetcarddropdown() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(`${API_URL}/fleet-card-all?op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datass = []

				responseData.data.forEach(elem1 => {
					console.log(elem1,"fgg")
					datass.push([elem1.id, elem1.accounts_head])
				})

				 setfleetcarddropdown(datass);
				console.log(responseData,"hii")
				return responseData;

			})
			.catch(e => {
				console.log(e);

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListFleetCard(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			getProduct();
			getfleetcarddropdown();
			var isdayclose = input2.isdayclose;
			if (isdayclose == 1) {
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else {
				if (isShowing) {
					setInputValues({})
					setErrors({})
					setPrice({})
					setSuccess({})
					// setFields([''])
				}
				if (!isShowing) {
					setDouble(true)
					setErrors({})

				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);

	//---onchange functions---//
	const handleInput = (e) => {

		if (e.target.name == "quantity" && inputValues.price) {
			var amount = (inputValues.price * e.target.value) ? (inputValues.price * e.target.value) : ''
		     var a =parseFloat(amount) ? amount.toFixed(2) : amount
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,

			amount: a,

		});
	};
	const handleSelect = (e) => {

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,


		});
	};
	const handleInputs = (e) => {
		if (e.target.name == "amount" && inputValues.price) {
			var quantity = (e.target.value / inputValues.price) ? (e.target.value / inputValues.price) : ''
			var q = parseFloat(quantity) ? quantity.toFixed(2) : quantity

		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
			quantity: q
		});
		convertAmountToWords(e, e.target.value)
	};
	const handleChange = (e) => {
		if (e.target.value == "") {
			inputValues.price = ""
			// inputValues.quantity =""
			// inputValues.amount =""
		}
		var id = e.target.value
		getPrice(id)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	let btnRef = useRef();
	const handleSubmit = (event) => {
		if (btnRef.current) {
			btnRef.current.setAttribute("disabled", "disabled");
		}
		event.preventDefault();
		setDouble(false);
		if (validateForm())

			sendFleetCard()
	}

	// ---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true
		if (!inputValues.product_id) {
			formIsValid = false;
			errors["product_id"] = "Select Product"
		}
		if (!inputValues.fleetcard_id) {
			setDouble(true)
			formIsValid = false;
			errors["fleetcard_id"] = "Select FleetCard"
		}else{
			errors["fleetcard_id"] =""
		
		}
		if (!inputValues.amount) {
			setDouble(true)
			formIsValid = false;
			errors["amount"] = "Enter Amount"
		}
		else {
			errors["amount"] = ""
		}

		setErrors(errors)

		return formIsValid;


	}

	//price field auto api integration//
	function getPrice(id, e) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: id,
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};

		fetch(API_URL + "/get_fuel_price", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var price = responseData[0].new_price == "" ? '' : responseData[0].new_price
				setInputValues({ ...inputValues, product_id: id, price: price })
				// setPrice({ ...new_price, price })

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//---Create FleetCard---//
	function sendFleetCard() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
				card_no: inputValues.card_no,
				amount: inputValues.amount,
				note: inputValues.note,
				product_id: inputValues.product_id,
				quantity: inputValues.quantity,
				price: inputValues.price,
				id: inputValues.id,
				fleetcard: inputValues.fleetcard_id

			})
		};
		fetch(API_URL + "/fleet_card_sale", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				// setSuccess({
				// 	...success,
				// 	disabled: responseData.status === true ? true : false,
				// })
				let errors = []
				if (responseData.status == false) {
					errors["amount"] = responseData.response.amount
					errors["fleet"] = responseData.response.fleetcard
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/fleet-card-sales")
					setErrors([''])
					inputValues.card_no = ""
					inputValues.amount = ""
					inputValues.note = ""
					inputValues.quantity = ""
					inputValues.product_id = ""
					inputValues.price = ""
					inputValues.fleetcard_id=""
					setInputValues(inputValues)
					getSummerySheet()
					setSuccess({
						...success,
						status: false
					})
					toggle();
					ListFleetCard(pages.current_page);
					setDouble(true)

				}

				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}
	//---Edit FleetCard list---//	
	function edit_FleetCard(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id
			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/fleet_card_sale/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInputValues({
					card_no: responseData.card_no,
					note: responseData.note,
					product_id: responseData.product_id,
					quantity: responseData.quantity,
					price: responseData.price,
					amount: responseData.amount,
					id: responseData.id ? responseData.id : '',
					fleetcard_id: responseData.fleetcard_id

				})
				setPrice({ price: responseData.price })

				toggle();
				return responseData;
			})
			.catch(e => {

				console.log(e)
			});
	}
	//---Delete EmployeeList---//	
	function delete_fleetcard(id) {
		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have permission to Delete  after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}
	}

	function deleteFleetcard() {
		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/fleet_card_sale/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListFleetCard(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				console.log(err)
			})
	}

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={4} />
									<div className="row">
										{Loading ? ListFleetCard :
											// <div class="loader text-primary">Loading...</div>
											<Loader />
										}
									</div>
									<div class="form-wrap ">

										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="fleetcard" role="tabpanel" aria-labelledby="fleetcard-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Fleet Card Sales </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Fleet Card Sales</a>
														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch}
																/>
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

															</div>
														</div>
													</div>


													<div class="table-widget">
														<table class="table border">
															<thead>
																<tr>


																	<th>Card Number</th>
																	<th>Card Name</th>
																	<th>Description</th>
																	<th>Product</th>
																	<th >Quantity</th>
																	<th>Price</th>
																	<th >Amount</th>
																	<th>Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(obj => (
																		<tr>
																			<td>{obj.card_no}</td>
																			<td>{obj.accounts_head}</td>
																			<td>{obj.note}</td>
																			<td>{obj.fuel_name}</td>
																			<td>{obj.quantity}</td>
																			<td>{obj.price}</td>
																			<td>{obj.amount}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>
																			<td class="py-2">
																				<a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => edit_FleetCard(obj.id)} ><i class="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																				{localStorage.getItem("save_hide") == 'false' ?
																				<a href="#" class="text-secondary" type="submit" onClick={() => delete_fleetcard(obj.id)}><i class="fa fa-trash-o  pe-2" aria-hidden="true"></i></a>
																			 	:''}
																				</td>
																		</tr>

																	))}
																<tr>
																	<td colSpan={5} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={3}>{total.totalamount}</td>
																</tr>
															</tbody>
														</table>
														{Loading ? <PaginationComp
															pages={pages}
															handlePagination={handlePagination}
														/> : ""
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<FleetModal
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fields={fields}
				new_price={new_price}
				btnRef={btnRef}
				success={success}
				double={double}
				handleSelect={handleSelect}
				productData={productData}
				fleetcarddropdown={fleetcarddropdown}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				handleInput={handleInput}
				errors={errors}
			/>) : null
			}
			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				deleteFleetcard={deleteFleetcard}
				double={double}
			/>) : null
			}
		</>
	)
}








export default FleetCardSalesConfig