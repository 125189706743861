import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../../Employee/Loader.component';
import ManagerLoginHeader from '../ManagerLoginHeader.component'

const SubscriptionManagementConfig = (props) => {
	const location_details = useLocation()
	const [inputValues, setInputValues] = useState({});
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const [permission, setPermission] = useState([])
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

	//---On Load---//		
	useEffect(() => {

		check_url()
	}, []);
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					ListSub()

				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function ListSub() {
		const requestOptions = {      //---employee listing---//
			method: 'get',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(`${API_URL}/list_pump_sub`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setInputValues({
					...inputValues,
					subscription_end_date: responseData.subscription_end_date,
					c_date: responseData.c_date
				})

				setLoading(true)

				return responseData;
			})

			.catch(e => {


			});
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						{permissions.includes("Subscription Management") == false ?
	<div  className="form-wrap mt-5">
  <div className="text-center">
You don't have permission to access this page
  </div></div>:
						<div className="container pb-3">
							<div className="row mt-md-5 mt-3 form-container">

								<div className="container pb-3">
									<div className="row mt-md-5 mt-3 form-container justify-content-center">
										<div className="col-md-8">
											<div className="form-wrap ">

												{permission.status == 3 ?
													<div className="form-wrap mt-5">
														<div className="text-center">
															{permission.message}
														</div></div> :
													<>
														{loading ? ListSub :
															<Loader />
														}
														<div className="row mb-3 justify-content-center py-4">
															<div className="col-md-12 col-12 pl-0 mb-4">
																<h3 className="table-title text-center">Subscription Management </h3>
															</div>
															<div className="col-md-10">
																<div className="row g-3 justify-content-center  mb-2">
																	<div className="col-4">
																		<label className="col-form-label">Subscription Status</label>
																	</div>
																	<div className="col-4">
																		{inputValues.subscription_end_date > inputValues.c_date ? <a href="#" className="btn btn-success px-5 rounded-btn">Active</a> : <a href="#" className="btn btn-success px-5 rounded-btn">InActive</a>}
																	</div>
																</div>
																<div className="row g-3 justify-content-center  mb-2">
																	<div className="col-4">
																		<label className="col-form-label">Subscription Date</label>
																	</div>
																	<div className="col-4">
																		<p>{inputValues.subscription_end_date}</p>
																	</div>
																</div>
																<div className="text-center mt-4">
																	<Link to={location => ({ backpage: location_details.pathname, pathname: "/subscription/pay" })}><a href="/subscription" class="btn btn-primary px-5">Renew</a></Link>
																</div>
															</div>
														</div>
													</>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>}
					</div>
				</div>
			</div>

		</>
	)
}
export default SubscriptionManagementConfig