
import React from "react";
import { Component } from "react";
import '../../App.css'
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from "axios";
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Loader from '../operational-model/Employee/Loader.component'
// import {convertAmountToWords} from '../layouts/Sidebar_Progress.component'
const rx_live = /^\d*(?:\d*)?$/;
class TargetConfig extends Component {
	constructor(props) {
        super(props);
     
        this.state = {
        errors:'',
        target:'',
		status:0,
		message:'',
		fields:{},
		loading:false,
        }
		this.handleChange = this.handleChange.bind(this)
	}

handleChange = (event) => {
	let fields = this.state.fields;
	if (rx_live.test(event.target.value))
    fields[event.target.name] = event.target.value;
	
	this.setState({
		fields: fields,
	})
}
		handleSubmit = () => {
			if(this.validateForm()){
						var user = {
				percentage:this.state.fields.percentage  
			}
			
			axios(
				{
					url: API_URL + '/pump-meta',
					method: "POST",
					data: user,
					headers: {
						"Access-Control-Allow-Origin": "*",
						"Content-Type":"application/json",
						"Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
						"Accept": "application/json"
					}
				}).then(res => {
				
					
					this.setState({
						status: res.data.status ? "success" : "danger",
					   
					}, () => {
						
						if(res.data.status===true)
						{
						   
						 swal("",res.data.message,res.data.status?"success":"danger")
						 this.props.history.push("/products")
						  }  
					})
					// this.setState({
					// 	'loading':true
					// })
				}).catch(error => {
					console.log(error)
				})
			}
		}
		validateForm() {
			var lng = this.state.lng
	
			let fields = this.state.fields;
			let errors = {};
			let formIsValid = true;
			if (!fields["percentage"]) {
				formIsValid = false;
				errors["percentage"] = "Please Enter Percentage"
			}
			if (isNaN(fields["percentage"])) {
				formIsValid = false;
				errors["percentage"] = "Please Enter Number"
			}
			if ((fields["percentage"] <= 0)) {
				formIsValid = false;
				errors["percentage"] = "Growth Rate Should Be Greater Than Zero"
			}
			this.setState({
				errors: errors
			});
			return formIsValid;
	
	
		}	

		getTarget()
		{
			axios(
				{
					url: API_URL+"/pump-meta",
					headers: {
						   "Access-Control-Allow-Origin": "*",
						   "Content-Type":"application/json",
						   "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
						   "Accept": "application/json"
					   }
				}
			).then(res=>
			{
				var fields = {}
				fields["percentage"] = res.data.percentage
				this.setState({fields: fields})
				
				if(res.data.status===3)
						{
						  this.setState({
							status:res.data.status,message:res.data.message
						  })
						}
			}).catch(error=>{
				console.log(error)
			})
		
		}
		componentDidMount() {
		
			this.check_url()          //on load//
			       //on load//
		  }
		check_url=() =>{
			const requestOptions = {      
				method: 'post',
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},
				body: JSON.stringify({
					pathurl: window.location.pathname,
				}),
			};
			fetch(`${API_URL}/check_url`, requestOptions)
				.then(response => { return response.json(); })
		
				.then(responseData => {
					console.log(responseData)
					if(responseData.allowurl == 1)
					{
					this.props.history.push(responseData.url);
					this.getTarget()   
					}
					else{
						
						this.props.history.goBack();
					}
		
					return responseData;
				})
				.catch(e => {
				});
		
		}
	

    render() {
        
        return(
            <>
       
       <div className="main-container page-wrap">
	<div className="d-md-flex">
<Sidebar_Progress />
		<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">


	<div className="row justify-content-center mt-md-5 mt-3 form-container">
	<div className="col-md-12 mb-2">
	<div className="col-md-12 mb-3"><Link to="/fuel-config-report"><a href="fuel-config-report" className="float-end btn btn-gray">Back</a></Link></div>

	{/* <a href="/products" class="float-end btn btn-gray">Back</a> */}
	</div>
		<div className="col-md-12">
		{this.state.status==3 ?
										<div  className="form-wrap">
											<div className="text-center">
											{this.state.message}
											</div></div>:
	                                          	<>
												  <div>
												  {/* {this.state.loading ? this.componentDidMount() : 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>
	} */}

			<div className="form-wrap form-content-sm py-5">
				<h2 className="mb-4">Target Configuration</h2>
				
				<div className="row mb-3 justify-content-center">
    <label  className="col-sm-3 col-form-label" style={{textAlign:'end', paddingRight:'1px'}}>Target Growth Rate %</label>
    <div className="col-sm-3">
      <input type="text" className="form-control hide-arrow"   pattern="\d*"  placeholder="Percentage" name="percentage" value={this.state.fields.percentage} onChange={this.handleChange}/>
	  <div className="errorMsg">{this.state.errors.percentage}</div>
   </div>
	
  </div>
				<p className="text-center px-5 py-3">You will receive a notification in mobile application on weekly basis (Monday).
This notification will start from 15th day only.  Details will be provide in the notification list.
Growth of all products will be calculated based on this one.</p>
		<div className="text-center mt-4">
			<a className="btn btn-primary w-auto" onClick={this.handleSubmit}>SAVE AND CONTINUE</a>
		</div>
			</div>
			
			</div>
			<div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => this.props.history.push("/products")}>SKIP</a></div>
		</>}
		</div>

	
</div>
		</div>
	
		</div>
	</div>
</div>




</>
        )
    }
}

export default TargetConfig;