import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import PaginationComponent from '../../../layouts/PaginationComponent';
import ProductTransferRackModal from './ProductTransferRackModal.component';
import Loader from '../../Employee/Loader.component';
import { convertAmountToWords, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";


const ProductTransferModal = (props) => ReactDOM.createPortal(
	<ProductTransferRackModal  {...props} />, document.body
);
const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const ProductTransferRackConfig = (props) => {

	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [delete_id, deleteId] = useState([])
	const [double, setDouble] = useState(true);
	const [startDate, setDate] = useState([])
	const [c_Date, c_setDate] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [stock_data, setStock] = useState([])
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [product_data, setProduct] = useState([])
	const [dropdown_data, setDropDown] = useState([])
	const [rack_data, setRack] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [loading, setLoading] = useState(false)
	const [permission, setPermission] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//---On Load---//		
	useEffect(() => {
		check_url();
		is_day_closed();

	}, []);
	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					ListRack(pages.current_page);

				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	//-------------------end
	function getProduct() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_products", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.packed_fule.forEach(elem => {
					datas.push([elem.id, elem.product_name])
				})

				setProduct(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				c_setDate(responseData)

			})
			.catch(e => {
				console.log(e);

			});

	}


	function ListRack(page) {
		const requestOptions = {      //---employee listing---//
			method: 'post',
			// data: {
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// },
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			// body: JSON.stringify({
			// 	op_date:28/4/2018,
			// 	search_keyword:search
			// })
		};
		fetch(`${API_URL}/getProductTransferlist?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				setList(responseData.data.data)
				setDate(responseData.start_date)


				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					per_pages: responseData.data.per_page,
					total_pages: responseData.data.total,

				})
				setLoading(true)
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListRack(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if(c_Date == 1){
				swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false ? "success":"warning")
			}else{
			getProduct();
			if (isShowing) {
				setInputValues({})
				setStock([])
				setErrors({})
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {
				setDouble(true)
				setErrors({})

			}
			setIsShowing(!isShowing);
		}
	}
		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	function handleChange(e) {
		if (e.target.name == "nf_product_id") {
			setStock([])
		}
		if (e.target.value == "") {
			setStock([])
			inputValues.rack_id = ""
		}

		var id = e.target.value
		localStorage.setItem('product_id', id);
		getMoveFrom(id)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		})
	}
	function handleChange1(e) {
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		})
	}
	function handleChange2(e) {

		setInputValues({
			...inputValues,
			id: e.target.value
		})

	}
	function getMoveFrom(id) {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({ product_id: id })
		};
		fetch(API_URL + "/get-rack", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.rack_name])
				})

				setDropDown(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	const handleInputs = (e) => {
		if (e.target.value == "") {
			setStock([])
		}
		var rack_id = e.target.value
		getToRack(rack_id)
		localStorage.setItem('id', rack_id);
		getStock(rack_id)
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});

	};
	const handleInput = (e) => {
		var rack_data = []


		rack_data.push({
			id: localStorage.getItem('id'),
			rack_stock: e.target.value
		})

		setInputValues({
			...inputValues,
			rack_data
		});

	};
	function getToRack(id) {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({ rack_id: id })
		};
		fetch(API_URL + "/get-to-rack", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.rack_name])
				})

				setRack(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function getStock(id) {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				rack_id: id,
				product_id: localStorage.getItem('product_id')
			})
		};
		fetch(API_URL + "/getStock", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
               var stock = responseData.stock
				setStock(stock);
				var rack_data = []
				rack_data.push({
					id: localStorage.getItem('id'),
					rack_stock: responseData.stock
				})
				setInputValues({
					...inputValues,
					rack_data
				});
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	//---onchange functions---//


	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		if (validateForm())
			sendRack()
	}
	//---validations---//	
	function validateForm() {
		let errors = []
		let formIsValid = true
		if (!inputValues.nf_product_id) {
			setDouble(true)
			formIsValid = false;
			errors["nf_product_id"] = "product is required"
		}
		if (document.getElementById("m_op_date").value=='') {
			setDouble(true)
			formIsValid = false;
			errors["op_date"] = "operational date is required"
		}
		if (!inputValues.quantity) {
			setDouble(true)
			formIsValid = false;
			errors["quantity"] = "moving quantity is required"
		}
		else if (inputValues.quantity <= 0) {
			setDouble(true)
			formIsValid = false;
			errors["quantity"] = "Moving quantity should be greater than zero"
		}

		if (!inputValues.rack_data) {
			setDouble(true)
			formIsValid = false;
			errors["rack_data"] = "move from field is required"
		}

		if (!inputValues.id) {
			setDouble(true)
			formIsValid = false;
			errors["rack_quantity"] = "move to field is required"
		}



		setErrors(errors)
		return formIsValid;
	}
	//---Create EmployeeList---//
	function sendRack() {

		var rack_quantity = []
		rack_quantity.push({
			id: inputValues.id,
			rack_stock: inputValues.quantity
		})
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				nf_product_id: inputValues.nf_product_id,
				op_date: document.getElementById("m_op_date").value,
				rack_data: inputValues.rack_data,
				rack_quantity,
				quantity: inputValues.quantity
			})
		};
		fetch(API_URL + "/product-rack-transfer", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["nf_product_id"] = responseData.response.nf_product_id;
					errors["op_date"] = responseData.response.op_date;
					errors["quantity"] = responseData.response.quantity;
					errors["rack_data"] = responseData.response.rack_data
					errors["rack_quantity"] = responseData.response.rack_quantity

					setDouble(true)
				}
				if (responseData.status == 5) {
					errors["quantity"] = responseData.message
					setDouble(true)
				}
				if (responseData.status == 4) {

					swal("", responseData.response.message, "warning")
					setDouble(true)
				}

				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/product-transfer-rack")
					setErrors([''])
					setInputValues([])
					setStock([])
					toggle();
					ListRack(pages.current_page);


				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={2} operation={1} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div className="container pb-3">
							<div className="row mt-md-5 mt-3 form-container">

								<div className="form-wrap ">
									{permission.status == 3 ?
										<div className="form-wrap mt-5">
											<div className="text-center">
												{permission.message}
											</div></div> :
										<>

											<div>
												{loading ? ListRack : <Loader />}
												<div className="tab-content" id="myTabContent">
													<div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
														<div className="">

															<div className="row mb-3">
																<div className="col-md">
																	<div className="row">

																		<div className="col-md col pl-0 col-12">
																			<h3 className="table-title">Product Transfer to rack</h3>
																		</div>

																	</div>
																</div>
																<div className="col-md-4">
																	<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add  Product Transfer TO Rack </a>
																</div>
																<div className="col-md-4">
																	<div className="input-group mb-3 search">
																		<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
																		</span>
																		<input type="text" className="form-control" placeholder="Search " value={search.search_keyword} onChange={handleSearch} />
																		<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																	</div>
																</div>
															</div>


															<div className="table-widget">
																<table className="table border col-12">
																	<thead>
																		<tr>

																			<th>Product Name</th>
																			<th>Total Stock</th>
																			<th >Godwon Stock</th>
																			<th>Rack Total Stock</th>

																		</tr>
																	</thead>
																	<tbody>
																		{list_data.map((obj) => (
																			<tr>

																				<td>{obj.product_name}</td>
																				<td>{obj.total_stock}</td>
																				<td>{obj.godown_stock}</td>
																				<td>{obj.rack_total_stock}</td>

																			</tr>
																		))}

																	</tbody>
																</table>


															</div>
															{loading ? <PaginationComp
																pages={pages}
																handlePagination={handlePagination}
															/> : ""
															}

														</div>
													</div>

												</div>

											</div>
										</>
									}
								</div>

							</div>


						</div>
					</div>
				</div>
			</div>


			{isShowing ? (<ProductTransferModal
				isShowing={isShowing}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				hide={toggle}
				handleInputs={handleInputs}
				inputValues={inputValues}
				fields={fields}
				handleInput={handleInput}
				dropdown_data={dropdown_data}
				product_data={product_data}
				stock_data={stock_data}
				startDate={startDate}
				success={success}
				rack_data={rack_data}
				double={double}
				handleSubmit={handleSubmit}
				handleChange={handleChange}
				handleChange1={handleChange1}
				handleChange2={handleChange2}
				// handleAdd={handleAdd}
				// handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}


		</>
	)
}
export default ProductTransferRackConfig