import React from "react";
import { Component } from "react";
import "../../App.css";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import LoginHeader from "../layouts/LoginHeader.component";
import axios from "axios";
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Loader from "../operational-model/Employee/Loader.component";
import { preventNonNumericalInput, prevNonNumericalWithDec } from "../layouts/Sidebar_Progress.component"

class TankConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tank_configs: [],
      tankParams: [],
      errors: [],
      tank: [],
      disable: true,
      loading: false,
      fuelShow: false,
      stat_operation: "",
      status: 0,
      message: "",
      fielderrors: [],
    };
  }

  //-------=----------------------------
  componentDidMount() {
    this.check_url();
  }

  check_url = () => {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then((response) => {
        return response.json();
      })

      .then((responseData) => {
        if (responseData.allowurl == 1) {
          this.props.history.push(responseData.url);
          this.getTank();
          this.getReport();
        } else {
          this.props.history.goBack();
        }

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getReport = (id) => {
    axios({
      url: API_URL + "/get-user-config",
      method: "GET",
      data: { id: id },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((res) => {
        var {
          report,
          fuel,
          tank,
          nozzle,
          rack_product,
          rack,
          product,
          customer,
          creditor_debtor,
          payment_methods,
          bank_details,
          fleet_card,
          mla_coupon,
          employee,
          vendor,
          asset,
          other_income,
          expense,
          dealership,
          tanker,
          task,
          fuel_unload,
          user,
        } = this.state;

        // res.data.pump_details.forEach(elem=>{
        //   console.log(elem)

        //  })
        this.setState({ fuelShow: res.data.tank_status });

        res.data.tank_config.forEach((elem) => {
          tank.push({
            tank_name: elem.tank_name,
            fuel_name: elem.get_fuel.fuel_name,
            no_of_nozzles: elem.no_of_nozzles,
            capacity: elem.capacity,
            diameter: elem.diameter,
            length: elem.length,
            stock_value: elem.stock_value,
            selling_price: elem.selling_price,
            current_stock: elem.current_stock,
            last_purchase_price: elem.last_purchase_price,
          });
        });

        this.setState({
          report: report,
          fuel: fuel,
          tank: tank,
          nozzle: nozzle,
          rack_product: rack_product,
          rack: rack,
          product: product,
        });

        //  this.setModalShow(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSubmit = (event) => {
    let dis = this.state.disable;
    dis = false;
    var { tank_configs } = this.state;
    event.preventDefault(0);
    if (this.validateForm()) {
      var string = JSON.stringify(tank_configs);
      axios({
        url: API_URL + "/tank-config",
        method: "POST",
        data: tank_configs,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          Accept: "application/json",
        },
      })
        .then((res) => {
          if (res.data.status == false) {
            this.setState({ disable: true });
            var st = "Duplicate entry";
            for (var key in res.data.message) {
              if (res.data.message.hasOwnProperty(key)) {
                st = st + " " + key + ",";
              }
            }
            var str = st.substr(0, st.length - 1);

            swal("", str, res.data.status ? "success" : "warning");
          }
          //  if(res.data.status==false)
          // {
          // 	swal("",res.data.message,res.data.status?"success":"danger")
          // 	this.props.history.push("/tank-config")
          // }
          if (res.data.status == true) {
            this.setState({ disable: true });
            swal("", res.data.message, res.data.status ? "success" : "danger");
            this.props.history.push("/nozzle-config");
          }
        })

        .catch((error) => {
          this.setState({ disable: true });
          swal("Something went wrong", "", "warning");
          console.log(error);
        });
    }
  };

  validateForm() {
    var { errors, tank_configs } = this.state;
    var formIsValid = true;

    tank_configs.map((tank, tank_index) => {
      var params = ["tank_name", "capacity", "no_of_nozzles"];
      var param_error_text = [
        "Enter Tank Name",
        "Enter Capacity",
        "Enter no of nozzles",
      ];

      params.forEach((param, param_index) => {
        for (var k = 0; k < tank[param].length; k++) {
          var na = k in tank[param];
          if (tank[param] == "" || tank[param] == undefined || !na) {
            formIsValid = false;
            this.setState({ disable: true });
            errors[tank_index][param][k] = param_error_text[param_index];
          } else if (
            tank["no_of_nozzles"][k] <= 0 ||
            tank["no_of_nozzles"][k] % 1 != 0
          ) {
            formIsValid = false;
            this.setState({ disable: true });
            errors[tank_index]["no_of_nozzles"][k] =
              "Number of nozzles should be greater than zero and not contain fraction number";
          } else if (tank["capacity"][k] > 100) {
            formIsValid = false;
            this.setState({ disable: true });
            errors[tank_index]["capacity"][k] =
              "Capacity should be less than or equal to 100 Kl";
          } else if (tank["capacity"][k] <= 0) {
            formIsValid = false;
            this.setState({ disable: true });
            errors[tank_index]["capacity"][k] =
              "Capacity should greater than 0 Kl";
          } else {
            this.setState({ disable: true });
            errors[tank_index][param][k] = "";
          }
        }
      });
    });

    this.setState({ errors: errors }, () => {
      console.log(errors);
    });

    return formIsValid;
  }

  handleTankChange = (event, fuel_id, index, prop) => {
    var { tank_configs } = this.state;

    var tank_config = tank_configs[fuel_id];
    //   tank_config[prop] = {...tank_config[prop], [index]:event.target.value};
    tank_config[prop][index] = event.target.value;
    tank_configs[fuel_id] = tank_config;
    this.setState({ tank_configs: tank_configs });
  };

  getTank = () => {
    axios({
      url: API_URL + "/tank-config",
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((res) => {
        if (res.data.status == 3) {
          this.setState({ status: res.data.status, message: res.data.message });
        }
        var { tank_configs, errors } = this.state;
        this.setState({ stat_operation: res.data.stat_operation });
        res.data.fuel_data.forEach((elem) => {
          tank_configs.push({
            fuel_id: elem.fuel.id,
            fuel_name: elem.fuel.fuel_name,
            tank_count: elem.no_of_tank,
            tank_name: Array(elem.no_of_tank),
            capacity: Array(elem.no_of_tank),
            diameter: Array(elem.no_of_tank),
            length: Array(elem.no_of_tank),
            no_of_nozzles: Array(elem.no_of_tank),
            tankData: [],
          });

          errors.push({
            tank_name: Array(elem.no_of_tank),
            capacity: Array(elem.no_of_tank),
            diameter: Array(elem.no_of_tank),
            length: Array(elem.no_of_tank),
            no_of_nozzles: Array(elem.no_of_tank),
          });
        });

        this.setState({ tank_configs: tank_configs, errors: errors });
        this.setState({
          loading: true,
        });
      })

      .catch((err) => {
        console.log(err);
      });
  };

  //--------tankerConfig list-----//

  render() {
    return (
      <>
        <div className="main-container page-wrap">
          <div className="d-md-flex">
            <Sidebar_Progress />
            <div className="content-area">
              <LoginHeader page_title="" />

              <div className="container pb-3">
                <div className="row justify-content-center mt-3 form-container">
                  <div className="col-md-12 mt-2">
                    <Link to="/fuel-config">
                      <a href="/fuel-config" class="float-end btn btn-gray">
                        Back
                      </a>
                    </Link>
                  </div>

                  <h2>Tank Configuration</h2>
                  {this.state.status == 3 ? (
                    <div className="form-wrap">
                      <div className="text-center">{this.state.message}</div>
                    </div>
                  ) : (
                    <>
                      <div>
                        {this.state.loading ? (
                          this.getTank
                        ) : (
                          // <div class="loader text-primary">Loading...</div>
                          <Loader />
                        )}
                        <div className="col-md-12">
                          <div className="row mb-3  mt-2 py-2">
                            <div className="form-wrap">
                              <div className="form-content pb-2 pt-3">
                                <div className="col-md-12">
                                  {this.state.fuelShow == false ? (
                                    ""
                                  ) : (
                                    <div className="table-widget">
                                      <table className="table border">
                                        <thead>
                                          <tr>
                                            <th>Tank Name</th>
                                            <th>Fuel Name</th>
                                            <th>Number of Nozzles</th>
                                            <th>Capacity</th>
                                            <th>Diameter</th>
                                            <th>Length</th>
                                            <th>Current stock</th>
                                            <th>Selling price </th>
                                            <th>Last purchase price </th>
                                            <th>Stock Value</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.tank.map((obj) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{obj.tank_name} </td>
                                                  <td>{obj.fuel_name}</td>
                                                  <td>{obj.no_of_nozzles}</td>
                                                  <td>{obj.capacity}</td>
                                                  <td>{obj.diameter}</td>
                                                  <td>{obj.length}</td>
                                                  <td>{obj.current_stock}</td>
                                                  <td>{obj.selling_price}</td>
                                                  <td>
                                                    {obj.last_purchase_price}
                                                  </td>
                                                  <td>{obj.stock_value}</td>
                                                </tr>
                                              </>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  )}
                                </div>

                                <div class="mt-5">
                                  {/* onChange={(event)=>this.handleTankChange(event,tank_config.fuel_id,index,"tank_name")} */}

                                  {this.state.tank_configs.map(
                                    (tank_config, config_index) => {
                                      var num = config_index;
                                      return (
                                        <div
                                          key={`tank_config${config_index}`}
                                          className="row form-content-sm mb-3"
                                        >
                                          <div className="col-md-12">
                                            <h5>
                                              {/* {"("}{config_index + 1}{")"}{" "} */}
                                              {tank_config.fuel_name}{" "}

                                            </h5>
                                          </div>
                                          {Array.from(
                                            { length: tank_config.tank_count },
                                            (item, index) => {
                                              return (
                                                <div
                                                  key={`tank_config_params${config_index}`}
                                                  className="row form-content-sm mb-3"
                                                >
                                                  <div className="col-md">
                                                    <input
                                                      type="text"
                                                      name="tank_name"
                                                      className="form-control"
                                                      placeholder="Tank Name*"
                                                      onChange={(event) =>
                                                        this.handleTankChange(
                                                          event,
                                                          config_index,
                                                          index,
                                                          event.target.name
                                                        )
                                                      }
                                                    />
                                                    <div className="errorMsg">
                                                      {this.state.errors[
                                                        config_index
                                                      ]
                                                        ? this.state.errors[
                                                          config_index
                                                        ].tank_name[index]
                                                        : ""}
                                                    </div>
                                                    {/* <div className="errorMsg">{this.state.fielderrors[index].tank_name}</div> */}
                                                  </div>

                                                  <div className="col-md">
                                                    <input
                                                      type="number"
                                                      name="capacity"
                                                      min={0}
                                                      onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)}
                                                      className="form-control hide-arrow"
                                                      placeholder="Capacity (KL)*"
                                                      onChange={(event) =>
                                                        this.handleTankChange(
                                                          event,
                                                          config_index,
                                                          index,
                                                          event.target.name
                                                        )
                                                      }
                                                    />
                                                    
                                                    <div className="errorMsg">
                                                      {this.state.errors[
                                                        config_index
                                                      ]
                                                        ? this.state.errors[
                                                          config_index
                                                        ].capacity[index]
                                                        : ""}
                                                    </div>
                                                  </div>
                                                {/*  
                                                  <div className="col-md">
                                                    <input
                                                      type="number"
                                                      name="diameter"
                                                      onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
                                                      className="form-control hide-arrow"
                                                      min={0}
                                                      placeholder="Diameter (M)"
                                                      onChange={(event) =>
                                                        this.handleTankChange(
                                                          event,
                                                          config_index,
                                                          index,
                                                          event.target.name
                                                        )
                                                      }
                                                    />
                                                    <div className="errorMsg">
                                                      {this.state.errors[
                                                        config_index
                                                      ]
                                                        ? this.state.errors[
                                                          config_index
                                                        ].diameter[index]
                                                        : ""}
                                                    </div>
                                                  </div>
                                               
                                                  <div className="col-md">
                                                    <input
                                                      type="number"
                                                      name="length"
                                                      onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
                                                      className="form-control hide-arrow"
                                                      min={0}
                                                      placeholder="Length (M)"
                                                      onChange={(event) =>
                                                        this.handleTankChange(
                                                          event,
                                                          config_index,
                                                          index,
                                                          event.target.name
                                                        )
                                                      }
                                                    />
                                                    <div className="errorMsg">
                                                      {this.state.errors[
                                                        config_index
                                                      ]
                                                        ? this.state.errors[
                                                          config_index
                                                        ].length[index]
                                                        : ""}
                                                    </div>
                                                  </div>
                                                  */}
                                                  <div className="col-md">
                                                    {/* <div className="select-container">
												<select id="Reliance" className="form-control"><option>No. of Nozzles*</option><option>...</option></select>
											</div> */}
                                                    <input
                                                      type="number"
                                                      name="no_of_nozzles"

                                                      onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)}
                                                      className="form-control"
                                                      placeholder="No of Nozzles*"
                                                      min={1}
                                                      onChange={(event) =>
                                                        this.handleTankChange(
                                                          event,
                                                          config_index,
                                                          index,
                                                          event.target.name
                                                        )
                                                      }
                                                    />
                                                    <div className="errorMsg">
                                                      {this.state.errors[
                                                        config_index
                                                      ]
                                                        ? this.state.errors[
                                                          config_index
                                                        ].no_of_nozzles[index]
                                                        : ""}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="text-end mt-4">
                              {this.state.disable == true ? (
                                this.state.stat_operation == "Yes" ? (
                                  <a
                                    className="btn btn-primary w-auto"
                                    href="/nozzle-config"
                                  >
                                    NEXT
                                  </a>
                                ) : (
                                  <a
                                    className="btn btn-primary w-auto"
                                    onClick={this.handleSubmit}
                                  >
                                    SAVE AND CONTINUE
                                  </a>
                                )
                              ) : this.state.stat_operation == "Yes" ? (
                                <a className="btn btn-primary w-auto" disabled>
                                  NEXT
                                </a>
                              ) : (
                                <a className="btn btn-primary w-auto" disabled>
                                  SAVE AND CONTINUE
                                </a>
                              )}

                              {this.state.disable == true ? (
                                this.state.fuelShow != false &&
                                  this.state.stat_operation != "Yes" ? (
                                  <a
                                    className="btn btn-primary mx-3 w-auto"
                                    href="/nozzle-config"
                                  >
                                    SKIP
                                  </a>
                                ) : (
                                  ""
                                )
                              ) : this.state.fuelShow != false &&
                                this.state.stat_operation != "Yes" ? (
                                <a
                                  className="btn btn-primary mx-3 w-auto"
                                  disabled
                                >
                                  SKIP
                                </a>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TankConfig;
