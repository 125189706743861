import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Admin_Sidebar_Progress.component";
import { API_URL } from '../../../../constant/API_Settings';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import LoginHeaderOperational from './../../LoginHeaderOperational';
import Modal from "react-modal";
import Loader from './../../Employee/Loader.component'

const SetPump = (props) => {

	const [loading, setLoading] = useState(false);
	const [input, setInput] = useState({});
	const [permission, setPermission] = useState([])
	const [dropdownData, setDropdownData] = useState([])

	const history = useHistory()
	const handleChange = (e) => {

		localStorage.setItem('admin_pump_id', e.target.value);
		setInput({ ...input, pump_id: e.target.value })
	}
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	const handleSubmit = () => {
		toggleModal()
	}
	useEffect(() => {
		check_meta_date();

	}, []);

	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.role_id == 1) {
					getAdminPump();
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}

	function getAdminPump() {
		const requestOptions = {
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/get-pump-name`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}

				if (localStorage.getItem("admin_pump_id")) {
					setInput({ ...input, pump_id: localStorage.getItem("admin_pump_id") })
				}
				var datas = []
				responseData.pump_detail.forEach(emp => {
					datas.push([emp.id, emp.pump_name])
				})
				setDropdownData(datas);
				setLoading(true)
				//   setInputValues({config_date:responseData.confg_date})
				return responseData;
			})
			.catch(e => {


			});
	}

	return (
		<>
			<div className="main-container page-wrap">

				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={1} operation={1} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div className="container pb-3">
							{permission.status == 3 ?
								<div className=" form-wrap mt-5">
									<div className="text-center">
										{permission.message}
									</div></div> :
								<>

									<div>
										{loading ? getAdminPump : <Loader />}
										<div className="row mt-md-5 mt-3 form-container justify-content-center">
											<div className="col-md-9">
												<div className="form-wrap ">
													<div className="row mb-3 justify-content-center">
														<div className="col-md-12 col-12 pl-0 mb-4">
															<h3 className="table-title text-center">Pump Change </h3>
														</div>
														<div className="col-md-7">
															<div className="form-row mb-2">
																<label className="me-5">Pump Name</label>
																<select id="" class="form-control highlight" name="pump_id" value={input.pump_id} onChange={handleChange}>
																	<option value=''>select</option>
																	{dropdownData.map((data) => {
																		return <option value={data[0]}>{data[1]}</option>
																	})
																	}
																</select>
															</div>

														</div>
													</div>


												</div>
											</div>

										</div>

									</div>
								</>}
						</div>
					</div>
				</div>
			</div>


		</>
	)
}

export default SetPump