import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip' 
import TimePicker from 'rc-time-picker';

const NozzEmpConfigModal = ({ preventNonNumericalInput,handleInput,handlestartInput,double,isShowing, hide, errors,success,open_reading, inputValues,handleAdd,handleRemove,handleChange_nozzle_id,fields,dropdownData,nozzledropdownData,handleSubmit, handleInputs }) => {
  return (
  <React.Fragment>


<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">
       
        <div class="modal-header mb-3">
        <h5 class="modal-title" id="staticBackdropLabel">Nozzle Closing </h5>
        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
          <div class="modal-body">

        <div class="row mb-3 justify-content-center">

        <div class="col-md-12">
        <div class="form-row  mb-2">
          <div class="row mt-3">
            <div class="col-md-6">
            <small>Nozzle Name : {localStorage.getItem('nozzle_name')}</small>
          </div>
          <div class="col-md-6">      
          <small>Opening Reading : {localStorage.getItem('opening_reading')}</small>
          </div>
          </div>
          </div>
{console.log(inputValues,"nono")}
        <div class="form-row  mb-2">
          <div class="row mt-3">
           
            <div  style={{display:"none"}} class="col-md-6">
            <label  class="col-form-label mb-2">start Time <sup class="star">*</sup> </label> 
            {/* <input type="text" readOnly name="start_from" value={inputValues.start_from} class="form-control" /> */}
            <div  className="digitaltimepicker">
            <TimePicker
                    showSecond={false} 
                    
                   value={inputValues.start_from}
                    name="start_from"
                    onChange={handleInput}
                    use12Hours
                    
                    
                    
                  /> 
</div>
            {/* <div className="errorMsg">{errors.end_from}</div> */}
            </div>
            {inputValues.nz_count=="Yes" || inputValues.nz_count=="Yes1" ?
             <div class="col-md-6">
            <label  class="col-form-label mb-2">Start Time <sup class="star">*</sup> </label> 
            {/* <input type="text" readOnly name="start_from" value={inputValues.start_from} class="form-control" /> */}
            <div  className="digitaltimepicker">
            <TimePicker
                    showSecond={false} 
                    disabled
                    value={inputValues.show_start_from}
                    name="show_start_from"
                    onChange={handlestartInput}
                    use12Hours
                    clearIcon 
                  /> 
</div>
<div className="errorMsg">{errors.show_start_from}</div>
            {/* <div className="errorMsg">{errors.end_from}</div> */}
            </div>:<div class="col-md-6">
            <label  class="col-form-label mb-2">Start Time <sup class="star">*</sup> </label> 
            {/* <input type="text" readOnly name="start_from" value={inputValues.start_from} class="form-control" /> */}
            <div  className="digitaltimepicker">
            <TimePicker
                    showSecond={false} 
      
                   value={inputValues.show_start_from}
                    name="show_start_from"
                    onChange={handlestartInput}
                    use12Hours
                    clearIcon 
                  /> 
</div>
<div className="errorMsg">{errors.show_start_from}</div>
            {/* <div className="errorMsg">{errors.end_from}</div> */}
            </div>}
            {inputValues.nz_count=="Yes" || inputValues.nz_count=="Yes1" ?
            <div class="col-md-6">
            <label  class="col-form-label mb-2">End Time <sup class="star">*</sup> </label>
                <div className="digitaltimepicker">
                 <TimePicker
                    showSecond={false} 
                    disabled
                    defaultValue={ (inputValues.end_from == null) ? inputValues.sugest_end_from : inputValues.end_from }
                    name="end_from"
                    onChange={handleInput}
                    use12Hours
                    clearIcon 
                    
                  /> 
                  {console.log(inputValues.sugest_end_from,"kakka")} 
                  </div> 
            <div className="errorMsg">{errors.end_from}</div>
            </div>: <div class="col-md-6">
            <label  class="col-form-label mb-2">End Time <sup class="star">*</sup> </label>
                <div className="digitaltimepicker">
                 <TimePicker
                    showSecond={false} 
                    defaultValue={ (inputValues.end_from == null) ? inputValues.sugest_end_from : inputValues.end_from }
                    name="end_from"
                    onChange={handleInput}
                    use12Hours
                    clearIcon 
                    
                  /> 
                  {console.log(inputValues.sugest_end_from,"kakka")} 
                  </div> 
            <div className="errorMsg">{errors.end_from}</div>
            </div>}
            {/*       //////////////////////////////////////////////////// */}
            <div class="col-md-6">
            <label  class="col-form-label mb-2">start date <sup class="star">*</sup> </label>
            <input type="date" class="form-control" name="startDate"   value={inputValues.op_date} readOnly />
           
                {/* <div className="digitaltimepicker">
                 <TimePicker
                    showSecond={false} 
                    defaultValue={ (inputValues.end_from == null) ? inputValues.sugest_end_from : inputValues.end_from }
                    name="end_from"
                    onChange={handleInput}
                    use12Hours
                    
                  /> 
                  {console.log(inputValues.sugest_end_from,"kakka")} 
                  </div>  */}
            {/* <div className="errorMsg">{errors.end_from}</div> */}
            </div>
            {inputValues.nz_count=="Yes" || inputValues.nz_count=="Yes1" ?
            <div class="col-md-6">
            <label  class="col-form-label mb-2">Closing Date <sup class="star">*</sup> </label>
            <input disabled type="date" class="form-control" name="endDate"  showClearButton={false}   value={inputValues.endDate}
             onChange={handleInputs} 
                  //  onChange={(event) => handleAmount(idx,event,"end")}
                   min={inputValues.op_date} max={inputValues.end_datee_max}
                  //  style={{border: '1px solid #ded9d9',height:"33px" }} 
               />

                {/* <div className="digitaltimepicker">
                 <TimePicker
                    showSecond={false} 
                    defaultValue={ (inputValues.end_from == null) ? inputValues.sugest_end_from : inputValues.end_from }
                    name="end_from"
                    onChange={handleInput}
                    use12Hours
                    
                  /> 
                  {console.log(inputValues.sugest_end_from,"kakka")} 
                  </div>  */}
            <div className="errorMsg">{errors.endDate}</div>
            </div>:<div class="col-md-6">
            <label  class="col-form-label mb-2">Closing Date <sup class="star">*</sup> </label>
            <input  type="date" class="form-control" name="endDate"  showClearButton={false}   value={inputValues.endDate}
             onChange={handleInputs} 
                  //  onChange={(event) => handleAmount(idx,event,"end")}
                   min={inputValues.op_date} max={inputValues.end_datee_max}
                  //  style={{border: '1px solid #ded9d9',height:"33px" }} 
               />

                {/* <div className="digitaltimepicker">
                 <TimePicker
                    showSecond={false} 
                    defaultValue={ (inputValues.end_from == null) ? inputValues.sugest_end_from : inputValues.end_from }
                    name="end_from"
                    onChange={handleInput}
                    use12Hours
                    
                  /> 
                  {console.log(inputValues.sugest_end_from,"kakka")} 
                  </div>  */}
            <div className="errorMsg">{errors.endDate}</div>
            </div>}
            <div class="col-md-6">
            <label  class="col-form-label mb-2">Working Hours<sup class="star">*</sup> </label>
            <input type="text"  name="total_diff"
             value={`${inputValues.total_hr} hr ${inputValues.total_min} min`}
             readOnly
             class="form-control hide-arrow" />
            </div>


            {/*  */}
            {inputValues.nz_count=="Yes" || inputValues.nz_count=="Yes1" ?
            <div class="col-md-6">
            <label  class="col-form-label mb-2">Closing Reading <sup class="star">*</sup> </label>
              <input disabled type="number" onKeyPress={preventNonNumericalInput} name="clossing_reading" value={inputValues.clossing_reading} onChange={handleInputs} class="form-control hide-arrow" />
              <div className="errorMsg">{errors.clossing_reading}</div>
            </div>: <div class="col-md-6">
            <label  class="col-form-label mb-2">Closing Reading <sup class="star">*</sup> </label>
              <input type="number" onKeyPress={preventNonNumericalInput} name="clossing_reading" value={inputValues.clossing_reading} onChange={handleInputs} class="form-control hide-arrow" />
              <div className="errorMsg">{errors.clossing_reading}</div>
            </div>}

          </div>
        </div>        
        <div class="form-row  mb-2">
          <div class="row mt-3">
          {inputValues.nz_count=="Yes" || inputValues.nz_count=="Yes1" ?
            <div class="col-md-4">
            <label  class="col-form-label mb-2">Quantity in Lt. <sup class="star">*</sup> </label>
              <input disabled type="number"  name="quantity" onKeyPress={preventNonNumericalInput} value={inputValues.quantity} onChange={handleInputs} class="form-control hide-arrow" />
              <div className="errorMsg">{errors.quantity}</div>
            </div>:<div class="col-md-4">
            <label  class="col-form-label mb-2">Quantity in Lt. <sup class="star">*</sup> </label>
              <input type="number"  name="quantity" onKeyPress={preventNonNumericalInput} value={inputValues.quantity} onChange={handleInputs} class="form-control hide-arrow" />
              <div className="errorMsg">{errors.quantity}</div>
            </div>}
            <div class="col-md-4">
            <label  class="col-form-label mb-2">Price ({inputValues.b_selling_price} {inputValues.comma} {inputValues.price})</label>
              <input  type="text" readOnly name="price" value={inputValues.price} class="form-control" />
              <div className="errorMsg">{errors.price}</div>
            </div>
            {inputValues.nz_count=="Yes" || inputValues.nz_count=="Yes1" ?
            <div class="col-md-4">
            <label  class="col-form-label mb-2">Amount <sup class="star">*</sup> </label>
              <input disabled type="number"  name="amount" onKeyPress={preventNonNumericalInput} value={inputValues.amount}  onChange={handleInputs} class="form-control hide-arrow" />
              <div className="errorMsg">{errors.amount}</div>
            </div>: <div class="col-md-4">
            <label  class="col-form-label mb-2">Amount <sup class="star">*</sup> </label>
              <input type="number"  name="amount" onKeyPress={preventNonNumericalInput} value={inputValues.amount}  onChange={handleInputs} class="form-control hide-arrow" />
              <div className="errorMsg">{errors.amount}</div>
            </div>}
            
          </div>
        </div>     
        </div>

        <div class="col-md-10">
          
     
        </div>
        </div>
      </div>
      
      {localStorage.getItem("save_hide") == 'false' ?
      
      <div class="modal-footer text-center">
        {double===false || inputValues.nozzle_status ==2 || inputValues.nz_count == 'Yes' || inputValues.nz_count == 'Yes1' || (inputValues.nz_count == 'Yes2' &&  inputValues.quantity != 0) ?    <a class="btn btn-primary px-4" type="submit" hidden disabled>Nozzle close</a>:   <a class="btn btn-primary px-4" type="submit" onClick={handleSubmit}>Nozzle close</a>}
      </div>
      :''}
      <div class="modal-footer text-center">
          {inputValues.nozzle_status ==2 ? <div className="errorMsg">Currently this nozzle is inactive </div> :''} 
          {inputValues.nz_count == 'Yes' ? <div className="errorMsg">Readings were updated so you can't edit this transaction</div> :''}   
          {inputValues.nz_count == 'Yes1' ? <div className="errorMsg">If you want to edit,you need to reject approval of next employee closing which is allocated with this nozzle</div> :''} 
          {inputValues.nz_count == 'Yes2' &&  inputValues.quantity != 0 ? <div className="errorMsg">(This fuel is aproved in fuel unload after this date. You can close nozzle with 0 quantity)</div> :''} 
      </div>
      </div>
    </div>
</React.Fragment>
  );
}
       export default NozzEmpConfigModal;