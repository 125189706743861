
import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from 'axios'
import DensityAfterUnloadingModal from './DensityAfterUnloadingModal.component'
import FuelUnloadingDensityModal from './FuelUnloadingDensityModal.component';
import DecantingMSConfig from './DecantingMSConfig.component';
import {convertAmountToWords,preventNonNumericalInput} from "../../../layouts/Operational_Sidebar_Progress.component";

const FuelUnloadingDensityConfig = (unloading ,productid) => {

	const [delete_id, deleteId] = useState([])

	const [inputValues, setInputValues] = useState({});
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [list_data, setList] = useState([])
	// const [unloading, setUnloading] = useState([])
	const [inputList, setInputList] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [fuel_configs, SetFuelType] = useState([])
	const [fuel_config, SetFuel] = useState([])
	const [version,setVersion] = useState([])
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues({})
				setErrors({})
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors({})

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}





	useEffect(() => {

// setList(unloading.unload_product)
		getFuel();

	}, []);


	//----------------------Get FuelName(10/06/2021) --------------------//


	function getFuel() {

		axios(
			{
				url: API_URL + '/unload-list',
				method: "post",
				data:{
					unloading_id:unloading.unloading.unloading,
					product_id:unloading.productid.product_id
				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},
			}
		).then(res => {

			let fuelConfigData = [];
			res.data.fuel_type.map(fuel_data => {
				fuelConfigData.push({
					fuel_name: fuel_data.fuel_name,
					id: fuel_data.fuel_id,

					no_of_tank: null,
					isReadonly: true,
					isRequired: false,
				});
			})
			setVersion(res.data.full_version)
			// setUnloading(res.data.unloading_id)
			SetFuel(fuelConfigData)

		}).catch(error => {
			console.log(error)
		})
	}

	//-----------------------------end---------------------------------------//

	//-------------Toogle Read---------------------//










	//---------------------------------------------//

	//----------------------send Data(10/06/2021)----------------//

	const [showFuelTanker, setShowFuelTanker] = useState(false)

	const handleSubmit = (e) => {

		sendData()
	
	}
	const handleInput = (i, e, prop) => {
		console.log(prop)
		inputValues[prop] = { ...inputValues[prop], [i]: e.target.value };
		setInputValues(inputValues)
		var op = document.getElementById("density_before" + i).value
		var d = document.getElementById("density" + i).value
		var difference = d - op
        var fixed = difference.toFixed(2)
		var diff = document.getElementById("density_diff" + i);
		diff.value = fixed
	}
	
	const handle = (i, e, prop) => {
		
		const hmv = document.getElementById("hmv_before" + i).value
		const temp = document.getElementById("temp_before" + i).value
		console.log(hmv)
		console.log(temp)
		getDensity()
		async function getDensity() {
			console.log(inputValues.product_id)
			const requestOptions = {
				method: 'post',

				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"

				}, body: JSON.stringify({ unloading_id: unloading, product_id: inputValues.product_id, hmv: hmv, temp: temp }),
			};
			await fetch(API_URL + "/observed-data", requestOptions)
				.then(response => { return response.json(); })
				.then(responseData => {
					if (responseData.status === true) {
						var op = responseData.observed_density;
						console.log(op)
						var s = document.getElementById("density_before" + i);
						s.value = op;
						var d = document.getElementById("density" + i);
						console.log(d.value)
						var difference = d.value - op
                        var d = difference.toFixed(2)
						var diff = document.getElementById("density_diff" + i);
						diff.value = d
					}
					let errors=[]
					if (responseData.status === false) {
						// errors["hmv_before_unload"] = responseData.message
						swal("", responseData.message, responseData.status ? "success" : "warning")
					}

                    setErrors(errors)

				})
				.catch(e => {
					console.log(e);

				});

		}
	

        const unloading_id = unloading.unloading.unloading
		inputValues[prop] = { ...inputValues[prop], [i]: e.target.value };
		inputValues["unloading_id"] =  unloading_id
		setInputValues(inputValues)


	}
	function toggleReadOnly(index, e, prop) {

		let fuelConfigsData = fuel_config;
		if (fuelConfigsData[index].isReadonly == false) {
		    if(document.getElementById("hmv_before" + index).value !="")
			{
			document.getElementById("hmv_before" + index).value=""
			}
			if( document.getElementById("temp_before" + index).value !="")
			{
		    document.getElementById("temp_before" + index).value=""
			}
			if( document.getElementById("density_before" + index).value !="")
			{
			document.getElementById("density_before" + index).value=""
			}
			if(document.getElementById("density" + index).value !="")
			{
			if(version !="No")
			{
			document.getElementById("density" + index).value=""
			document.getElementById("density_diff" + index).value=""
			}
		}
              
			for (var i=0;i<fuelConfigsData.length;i++)
		  {
			if(inputValues.hmv_before_unload)
			{
			let values = inputValues
           values.product_id[index]=""
		   values.hmv_before_unload[index]=""
		   values.invoice_density[index]=""
		   values.temp_before_unload[index]=""
			
		   console.log(values)
		   setInputValues(values)
			}
			}

		}
		else {
			inputValues[prop] = { ...inputValues[prop], [index]: e.target.value };
		}
		
		let fuelConfigData = fuelConfigsData[index];
		fuelConfigData.isReadonly = !fuelConfigData.isReadonly;
		fuelConfigData.isRequired = !fuelConfigData.isRequired;
		fuelConfigsData[index] = fuelConfigData;
	
		setInputValues(inputValues)
		SetFuelType({ ...fuel_configs, fuelConfigsData });
		


	}
	// 	setInputValues({
	// 		...inputValues,


	// 	 product:product,
	// 	 product_id:product_id

	// 	  });

	// 	}

	// setInputValues({
	// 	...inputValues,

	//  product_id:product_id

	//   });

	function sendData() {
	

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify(inputValues)
		};
		fetch(API_URL + "/unload-invoice-density", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})

				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/op-fuel-unloading")
					setErrors([''])
				
					setShowFuelTanker(true)


				}
                 let errors =[]
				if (responseData.status == false) {
                    errors["hmv_before_unload"] = responseData.response.hmv_before_unload
					errors["invoice_density"] = responseData.response.invoice_density
					errors["temp_before_unload"] = responseData.response.temp_before_unload
					// errors["unloading_id"] = responseData.response.unloading_id
                    
				}
				if (responseData.status == 4) {
					swal("", responseData.message, responseData.status ? "warning" : "success")

				}
				console.log(responseData)
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}


	///-------------------------------------------//

	return (
		<>



			<div class="row ">

			
					<h5 class="modal-title " id="exampleModalLabel">Density</h5>

				
						<div class="col-md">
							<div class="">
								
										{
											fuel_config.map((fuelType, idx) => {

												return (
													<div class="">

														<div className="col-md" key={`fuel_config${idx}`}>
															<div className=" form-check " >
																<label className="form-check-label me-5 mt-5 fw-bold" htmlFor="flexCheckDefault">{fuelType.fuel_name}      </label>
																<input className="form-check-input mt-5" type="checkbox" id="product_id" value={fuelType.id} name="product_id" onChange={(event) => { toggleReadOnly(idx, event, event.target.name) }} />



															</div>
														</div>
														<div class="row ">
															<div class=" py-2  col-md-6 mx-0"><p>Invoice Density in KL. <sub className="star">*</sub></p></div>
															<div class="col-md-6 text-center  ">
																<input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className={`form-control hide-arrow check${fuelType.id}`} name="invoice_density" id={"density" + idx} placeholder="eg:652" readOnly={fuelType.isReadonly} onChange={(e) => handleInput(idx, e, e.target.name)}></input>
																<div className="errorMsg">{errors.invoice_density}</div>
																
															</div>

														</div>
														<div class="row ">
															<div class=" py-2  col-md-6 mx-0"><p>HMV <sub className="star">*</sub> </p></div>
															<div class="col-md-6 text-center  ">
																<input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow" name="hmv_before_unload" placeholder="eg:660" id={"hmv_before" + idx} readOnly={fuelType.isReadonly} onBlur={(e) => handle(idx, e, e.target.name)}></input>
																<div className="errorMsg">{errors.hmv_before_unload}</div>
														
															</div>
														</div>
														<div class="row ">
															<div class=" py-2  col-md-6 mx-0"><p>Temperture <sub className="star">*</sub> </p></div>
															<div class="col-md-6 text-center  ">
																<input type="number" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow" name="temp_before_unload" placeholder="eg:5" id={"temp_before" + idx} readOnly={fuelType.isReadonly} onBlur={(e) => handle(idx, e, e.target.name)}></input>
																<div className="errorMsg">{errors.temp_before_unload}</div>
															
															</div>
														</div>
													
														<div class="row ">
															<div class=" py-2  col-md-6 mx-0"><p>Observerd Density  </p></div>
															<div class="col-md-6 text-center  ">
																<input type="text" class="form-control amount" name="density_before_unload" id={"density_before" + idx} readOnly onChange={(e) => handle(idx, e, e.target.name)}></input>
															</div>
														</div>
														<div class="row ">
															<div class=" py-2  col-md-6 mx-0"><p>Difference </p></div>
															<div class="col-md-6 text-center  ">
																<input type="text" class="form-control" name="density_difference" id={"density_diff" + idx} readOnly onChange={(e) => handle(idx, e, e.target.value)}></input>
																<div className="errorMsg">{errors.unloading_id}</div>
															
															</div>
														</div>
														
													</div>
												)
											})}
								
								</div>
								{/* </div>
</div>
</div> */}
						
							<div className="modal-footer  justify-content-end p-0 "><a className="btn btn-primary m-btn  w-auto " onClick={handleSubmit}>save/Continue</a></div>
						</div>
					
			
			</div>



			{showFuelTanker ? <div>
				<DecantingMSConfig  unloading={unloading}
				/>

			</div> : null}
		</>
	)
}








export default FuelUnloadingDensityConfig