import { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios'; 
import { API_URL } from "../constant/API_Settings";
import swal from "sweetalert";
import logo from '../img/logo.svg'
import Loader from "./operational-model/Employee/Loader.component";

const ForgotUserName = () => {

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [Loading, setIsLoading] = useState(false);


    let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    }

    var emailDetails = {
        email: email,
        type: "user_name",
    }

      const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission
    
        if (email.trim() === '') {
          setErrorMessage('Please enter an email address.');
        //   setIsLoading(true);
          console.log("gfhcgfc");
        } else if (!validateEmail(email)) {
          setErrorMessage('Please enter a valid email address.');
        } else {
          try {
            setIsLoading(true);
            // Call the POST API with the email as data
            await axios.post(API_URL + '/forgotpassword',emailDetails , { headers: headers })
            .then(res => {
                if (res.data.status == false) {
                    swal("", res.data.message)
                }
                if (res.data.status == true) {
                    window.location.href = "/username";
                    // setIsLoading(false);
                }

            }).catch(err => {

            })
            .finally(() => {
                setIsLoading(false); // Stop the loading indicator after API request completion
              });
    
            // Reset the form and error message
            //setEmail('');
            setErrorMessage('');
          } catch (error) {
            console.log('Error:', error);
            // Handle the API error if needed
          }
        }
      };

      const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
      };



    return (
        <>

                <div className="main-container">
                    <div className="container-fluid">
                    {Loading && <Loader />}
                        <div className="row">
                            
                    
                            <div className="col-md-6 login-left">
                                <div className="row pt-5 pb-5 window-height justify-content-center align-items-center">
                                    <div className="col-md-8">
                                        <div className="login-banner">
                                            <a className="logo-continer" href="#"><img src={logo} /></a>
                                            <a className="btn btn-info" href="#" role="button">REQUEST CALL BACK</a>
                                            <button type="button" className="btn btn-outline-primary"><img src="" />Whatsapp</button>
                                            <h6>Petrosmartservice@gmail.com</h6>
                                            <div className="border-top"></div>
                                            <h6>+91 8714554771</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 login-right">
                                <div className="row pt-5 pb-5 window-height justify-content-center align-items-center">
                                    <div className="col-md-8">
                                        <div className="login-container">
                                           


                                        <form onSubmit={handleSubmit}> 
                                        <div className="form-container">
                                            <h5 class="mb-1" style={{fontSize:17,color:'#5d38dbeb'}}>Trouble Logging In - Forgot Login Username?</h5>
                                            <h5 class="mb-5" style={{fontSize:15}}>Enter your registered email address to receive your Username.</h5>
                                            <div className="form-group">
                                                <div className="input-container">
                                                    <input type="text"
                                                            value={email}
                                                            onChange={(event) => setEmail(event.target.value)}
                                                            placeholder="Enter your email" />

                                                    <label htmlFor="user_name">Email: <span style={{ color: 'red' }}>*</span></label>
                                                    <div className="bar"></div>
                                                    
                                                </div>
                                                <div className="errorMsg">{errorMessage}</div>
                                            </div>
                                          


                                            <div className="form-group">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                            <h4>Please <Link to="/login" style={{ textDecoration: 'none' }}>click here </Link>to go back to login</h4>
                                            
                                        </div>
                                    </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
    );
}

export default ForgotUserName;