import React from "react";
import { Component } from "react";
import '../../App.css'
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import LoginHeader from "../layouts/LoginHeader.component";
import axios from 'axios'
import { API_URL } from "../../constant/API_Settings"
import swal from "sweetalert";
import { Link } from "react-router-dom";
class FuelConfig extends Component {
	constructor(props) {
        super(props);
        this.state = {
        types:[],
		errors:[],
	    fueltype:[],
		fuelname:'',
		fuelT:'',
		check:false,
		send_fuel_configs: [],
	      fuel_configs:[]
		
		
        }
		this.handleChange = this.handleChange.bind(this);
		// this.bindCheck = this.bindCheck.bind(this);
	  }
	
	  validateForm()
	  {
		var { errors , send_fuel_configs} = this.state
		var formIsValid = true
		// for (var key in ( send_fuel_configs)){
			if(typeof send_fuel_configs.no_of_tank == 'undefined' && typeof send_fuel_configs.fuel_type == 'undefined')
			{
				errors["no_of_tank"] = "Please Enter No of Tank"
				errors["fuel_type"] = "Please Select Fuel Type"
			}
			else{
				errors["no_of_tank"] = ""
				errors["fuel_type"] = ""
			}
		// }
		
		// for (var key in ( send_fuel_configs.fuel_type)) {
		// 	if(typeof send_fuel_configs.no_of_tank == 'undefined'){
		// 		errors[key] ["no_of_tank"]= "Enter No of tank"
		// 		}
		// 		else{
		// 			errors[key] ["no_of_tank"]= ""
		// 		}
		// 	}
		// 	for (var key in ( send_fuel_configs.no_of_tank)) {
		// 		if(typeof send_fuel_configs.fuel_type == 'undefined'){
		// 			errors[key]["fuel_type"]= "Enter fuel_type"
		// 			}
		// 			else{
		// 				errors[key]["fuel_type"]= ""
		// 			}
		// 		}
		// 		for (var key in ( send_fuel_configs.fuel_type)) {	
        //   try{
		//  console.log(send_fuel_configs.no_of_tank.hasOwnProperty(key))
		//  console.log(typeof send_fuel_configs.fue_type)
		// 	if(!send_fuel_configs.no_of_tank.hasOwnProperty(key))
				
		// 			if(typeof send_fuel_configs.fue_type == 'undefined'){
		// 			errors[key]["fuel_type"] = "Enter fueltype"
		// 			formIsValid = false
		// 			// send_fuel_configs.fuel_type[key]
				
		// 			}
					
					
					
		// 		else{
				
		// 			if(send_fuel_configs.fuel_type[key].length<1)
		// 			{
		// 				errors[key]["fuel_type"] = "Enter fueltype"
		// 				formIsValid = false
		// 			}
		// 			else
		// 			{
		// 				errors[key]["fuel_type"] = ""
		// 			}	}
				
		// 	}
		// 	catch{}
		// }
			// 	for (var key in ( send_fuel_configs.no_of_tank)) {
			// 		if(typeof send_fuel_configs.fuel_type == 'undefined'){
			// 			errors[key]["fuel_type"] = "Enter Fueltype"
			// 			}
			// 	if(!send_fuel_configs.fuel_type.hasOwnProperty(key)	)
			// 	{
			// 		if(typeof send_fuel_configs.no_of_tank == 'undefined'){
			// 		errors[key]["no_of_tank"] = "Enter no of tank"
			// 		formIsValid = false
			// 		}
			// 	}
			// 	else{
			// 		if(send_fuel_configs.fuel_type[key].length<2)
			// 		{
			// 			errors[key]["no_of_tank"] = "Enter no_of_tank"
			// 			formIsValid = false
			// 		}
					
			// 		else
			// 		{
			// 			errors[key]["no_of_tank"] = ""
			// 		}
			// 	}
			// }
		
	
//   catch{}
		
	
this.setState({
	errors: errors
  });  

//   return formIsValid;
return formIsValid;

}


	handleChange= (event,index,prop) =>
	{
	var { send_fuel_configs } = this.state
	
      var fuel_config = send_fuel_configs
	  console.log(prop)
	  console.log(event.target.checked)
	  if(event.target.name=="fuel_type")
	  {
		  if (event.target.checked)
		  {
			if ( fuel_config[prop] === undefined)
			{
				fuel_config[prop] = [];
			}
			fuel_config[prop][index] = event.target.value;
		  }
		  else {
			  fuel_config[prop].splice(index, 1);
		  }
	  }
	  else {
		fuel_config[prop] = {...fuel_config[prop], [index]:event.target.value};
	  }

	  console.log(fuel_config)
	  
	  send_fuel_configs = fuel_config
	this.setState({send_fuel_configs: send_fuel_configs})
	}

	handleSubmit = (event) => {
		var { send_fuel_configs,errors } = this.state
		
 if(this.validateForm()){
		 

        event.preventDefault();
        axios(
            {
                url: API_URL + '/fuel-config',
                method: "POST",
                data: {
					fuel_type: send_fuel_configs.fuel_type,
					no_of_tank: send_fuel_configs.no_of_tank
				},
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type":"application/json",
                    "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
                    "Accept": "application/json"
                }
            }).then(res => {
				
			
                  if(res.data.status==false)
              {
            //       if(res.data.response!==undefined)
            //       {
			// 		var errors={}
			// 		var sends_fuel_configs =this.state
			// 		for (var key in (res.data.response)) {
            //               console.log(sends_fuel_configs)
			// 			  if (res.data.response.hasOwnProperty(key)) {
			// 				res.data.message=res.data.response[key][0];
			// 				sends_fuel_configs.map((index)=>{
			// 	            if(key=="no_of_tank")
			// 				{
            //                  errors[index] =res.data.message
			// 				}
			// 		})
					
             
            //    }           
            //    } 
			// }
		}
                this.setState({
					errors:errors,
                    status: res.data.status ? "success" : "danger",
                   
                }, () => {
                
                    if(res.data.status===true)
                    {
                       
                    swal("",res.data.message,res.data.status?"success":"danger")
                    this.props.history.push("/tank-config")
                      }  
                })
            })
	 
     }
	 }

	

    componentDidMount()
	{ 
	  
		axios(
			{
				url: API_URL+'/fuel-config',
				method: "get",
				headers: {
				   "Access-Control-Allow-Origin": "*",
				   "Content-Type":"application/json",
				   "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
				   "Accept": "application/json"
			   }
			}
		).then(res=>
		{  
			var { fuel_configs, errors } = this.state
		
			res.data.fuel_type.forEach(elem=>{
			
				fuel_configs.push({
					fuel_name: elem.fuel_name,
					pump_id: elem.id
					})
                  
					errors.push({
						no_of_tank:'',
						fuel_type:''
					})
				})
			
				this.setState({fuel_configs:fuel_configs })
				this.setState({errors:errors})	
		}).catch(error=>
		{
			console.log(error)
		})
		
	// axios(
	// 	{
	// 		url: API_URL+"/get-pump-details",
	// 		headers: {
	// 			   "Access-Control-Allow-Origin": "*",
	// 			   "Content-Type":"application/json",
	// 			   "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
	// 			   "Accept": "application/json"
	// 		   }
	// 	}
	// ).then(res=>
	// {
	// 	console.log(res.data)
	// 	var fields = {}
	// 	fields["pump_name"] = res.data.pump_name
	// 	fields["pump_address"] = res.data.pump_address
	// 	fields["pump_phone"] = res.data.pump_phone
	// 	fields["pump_email"] = res.data.pump_email
	// 	fields["dealer_id"] = res.data.dealer_id
	// 	fields["name"] = res.data.owner_name
	// 	this.setState({fields: fields})
	// 	console.log(res.data.pump_address)
	// }).catch(error=>{
	// 	console.log(error)
	// })
	}




	render() {
       var types =this.state
	
		return(
			<> 
	
   <div className="main-container page-wrap">
	<div className="d-md-flex">
	<Sidebar_Progress progress={3}/>
		<div className="content-area">
		<LoginHeader page_title="" />		

		
<div className="container pb-3">
	
	<div className="row justify-content-center mt-md-5 mt-3 form-container">
	<div className="col-md-12 mb-3"><Link to="/pump-details"><a href="pump-details" className="float-end btn btn-gray">Back</a></Link></div>

		<div className="col-md-12">
			<div className="form-wrap">
				<h2>Fuel Configuration </h2>
				
				<div className="row form-content check-frm">
					{
						this.state.fuel_configs.map((fuelType,index) => {
							return (
								<div className="col-md-3" key={`fuel_config${index}`}>
									<div className="form-ceck check-wrap " >
							<label className="form-check-label me-4 fw-bold" htmlFor="flexCheckDefault">{fuelType.fuel_name}      </label>
  <input className="form-check-input" type="checkbox" value={fuelType.pump_id} name="fuel_type"  onChange={event => {this.handleChange(event,index,event.target.name)} }id="flexCheckDefault" />
  						
  {/* <div className="errorMsg">{this.state.errors["fuel_type"]}</div> */}

</div>

<div className="input-group mb-3" >
  <label className="input-group-text" id="basic-addon1">Number of tank</label>
  <input type="number" className="form-control" placeholder="" aria-label="no_of_tank" min={1} name="no_of_tank" onChange={event => {this.handleChange(event, index,event.target.name)}} />
 
  {/* <div className="errorMsg">{this.state.errors["no_of_tank"]}</div> */}
</div>
								</div>
							)
						})
					}
				<div class="col-md-12">
				  <div className="errorMsg">{this.state.errors["fuel_type"]}</div>
				  				<div className="errorMsg">{this.state.errors["no_of_tank"]} </div>
			</div>
					
				</div>

				


			</div>
			<div className="text-end mt-4">



			<a className="btn btn-primary w-auto" onClick={this.handleSubmit}>SAVE AND CONTINUE</a>
		</div>
		</div>
	</div>
</div>
		</div>
	
	</div>
</div>

</>
)

	}
}
export default FuelConfig;