import React from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'



const FuelUnModal = ({ isShowing, hide, setVehicle, showFuelTanker, showFuelTanker1, showFuelTanker5, showFuelTanker2, showFuelTanker3, showFuelTanker4, Example, Example4, Example1, Example5, Example2, Example3, toggle_div_fun, errors, success, inputValues, handleAdd, handleRemove, handleChange, fields, dropdownData, handleSubmit, handleInputs }) => {
  return (
    <React.Fragment>

      {console.log(inputValues)}
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modalm modalxxl">
          <div className="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Invoice details</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal " aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>


          <div class="row">

            <div class="container pb-3">


              <div class="row  mt-5 form-container">
                <div class="col-md-12">
                  <div class=" ">
                    <div class="row mb-3 justify-content-center">



                      <div class="">
                        <div class="">
                          <div class="">


                            <div className="col-md-12 d-flex">

                              <div className="form-check col-sm  mx-4">

                                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                  id="examplecheckboxs1" />
                                </a><label className="form-check-label " for="examplecheckboxs1">   Own Tanker
                                </label>
                                <ReactTooltip />

                              </div>

                              <div className="form-check col-sm ">

                                <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                  id="examplecheckboxs1" />
                                <label className="form-check-label " for="examplecheckboxs1">  Other Tanker
                                </label>
                                <ReactTooltip />
                              </div>

                            </div>


                          </div>
                        </div>


                        <div class=" mt-5">
                          <div class="d-flex">
                            <div class="w-100 text-center  ">
                              <label class="col-form-label">Registration Number</label>
                            </div>

                            <div class="w-100 text-center  mx-3">
                              <div class="select-container">
                                <select class="form-control highlight ">
                                  <option>---</option>
                                  <option>---</option>
                                </select>
                              </div>
                            </div>
                            <div class="">
                              <button class="btn-add mt-2  me-2" onClick={() => handleAdd()}>+</button>
                            </div>
                            <div class="">
                              <button class="btn-add mt-2  me-2" onClick={() => handleRemove()}>-
                              </button></div><div class="w-100 text-center   ">
                              <label class="col-form-label pe-2">Tanker Capacity</label>
                            </div>

                            <div class="w-100 text-center  ">
                              <div class="select-container">
                                <select class="form-control highlight">
                                  <option>select</option>
                                  <option>---</option>
                                </select>
                              </div>
                            </div>
                            <div class="w-100 text-center pe-2  ">
                              <label class="col-form-label">No of Compartment</label>
                            </div>

                            <div class="w-100 text-center pe-2 mx-2  ">
                              <div class="select-container">
                                <select class="form-control highlight ">
                                  <option>---</option>
                                  <option>---</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </div>



                      </div>

                      <div class="col-md-10">


                      </div>

                      <div class="mt-5">

                        <div class="row justify-content-center mb-2">
                          <div class="col-2">
                            <label class="col-form-label">Invoice Number</label>
                          </div>
                          <div class="col-2">
                            <input type="text" class="form-control highlight" name="quantity" />
                          </div>


                          <div class="col-2">
                            <label class="col-form-label">Invoice Date</label>
                          </div>
                          <div class="col-2">
                            <input type="text" class="form-control highlight " name="quantity" />
                          </div>



                          <div class="col-2">
                            <label class="col-form-label">Invoice Amount</label>
                          </div>
                          <div class="col-2">
                            <input type="text" class="form-control highlight" name="quantity" />
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                  <div class="col-md-10">


                  </div>
                </div>
                <div class="">
                  <div className="text-end mt-4 "><a className="btn btn-primary mb-2 " onClick={Example} >save/Continue</a></div></div>
              </div>
            </div>
          </div>

          {showFuelTanker ? <div>
            <div className="col-md-12 d-flex">
              <h5 class="modal-title" id="exampleModalLabel">Invoice details</h5>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#"
                  id="examplecheckboxs1"
                />
                </a><label className="form-check-label " for="examplecheckboxs1">   MS
                </label>
                <ReactTooltip />

              </div>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                  id="" />
                </a><label className="form-check-label " for="">  HSD
                </label>
                <ReactTooltip />

              </div>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                  id="examplecheckboxs1" />
                </a><label className="form-check-label " for="examplecheckboxs1">   XM
                </label>
                <ReactTooltip />

              </div>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                  id="examplecheckboxs1" />
                </a><label className="form-check-label " for="examplecheckboxs1">   XM
                </label>
                <ReactTooltip />

              </div>
            </div>
            <div class="container pb-3">


              <div class="row mt-md-5 mt-3 form-container">
                <div class="col-md-12">
                  <div class="">
                    <div class="row mb-3 justify-content-center">

                      <div class="col-md-12">
                        <div class="">
                          <div class="row">


                            <div class="mt-5" class="tab-pane fade show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">
                              <p className=""><b>MS</b></p>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Invoice Quantity in KL. </p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " readOnly></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " readOnly></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " readOnly></input>
                                </div>
                              </div>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Product Wise Invoice Amount </p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " readOnly></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " readOnly></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " readOnly></input>
                                </div>
                              </div>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Total </p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class=" py-2  col-md-2"><p>Round oFF</p></div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class=" py-2  col-md-2"><p>Net Amount </p></div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>

                            </div>
                          </div>






                        </div>

                        <div class="col-md-10">


                        </div>
                      </div>

                    </div>
                    <div class="">
                      <div className="text-end mt-4 "><a className="btn btn-primary mb-2" onClick={Example1}>save/Continue</a></div></div>

                  </div>
                </div>
              </div>
            </div></div> : null}
          {showFuelTanker1 ? <div>
            <h5 class="modal-title" id="exampleModalLabel">Density</h5>
            <div className="col-md-12 d-flex">

              <div className="form-check col-sm mt-4  mx-5">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#"
                  id="examplecheckboxs1"
                />
                </a><label className="form-check-label " for="examplecheckboxs1">   MS
                </label>
                <ReactTooltip />

              </div>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                  id="" />
                </a><label className="form-check-label " for="">  HSD
                </label>
                <ReactTooltip />

              </div>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                  id="examplecheckboxs1" />
                </a><label className="form-check-label " for="examplecheckboxs1">   XM
                </label>
                <ReactTooltip />

              </div>
              <div className="form-check col-sm mt-4 mx-4">

                <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                  id="examplecheckboxs1" />
                </a><label className="form-check-label " for="examplecheckboxs1">   XM
                </label>
                <ReactTooltip />

              </div>
            </div>



            <div class="row mt-md-5 mt-3 form-container">
              <div class="col-md-12">
                <div class="">
                  <div class="row mb-3 justify-content-center">



                    <div class="col-md-12">
                      <div class="">
                        <div class="row">


                          <div class="mt-5" class="tab-pane fade show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">

                            <div class="row ">
                              <div class=" py-2  col-md-2"><p>Invoice Density. </p></div>
                              <div class="col-md text-center pe-2 ">
                                <input type="text" class="form-control " readOnly></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control " readOnly></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control " readOnly ></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control " ></input>
                              </div>
                            </div>
                            <div class="row ">
                              <div class=" py-2  col-md-2"><p>HMV </p></div>
                              <div class="col-md text-center pe-2 ">
                                <input type="text" class="form-control " readOnly></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control " readOnly></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control " readOnly></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control " ></input>
                              </div>
                            </div>
                            <div class="row ">
                              <div class=" py-2  col-md-2"><p>Temperture </p></div>
                              <div class="col-md text-center pe-2 ">
                                <input type="text" class="form-control "></input>
                              </div>

                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>

                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>

                            </div>
                            <div class="row ">
                              <div class=" py-2  col-md-2"><p>Observerd Density(auto) </p></div>
                              <div class="col-md text-center pe-2 ">
                                <input type="text" class="form-control " ></input>
                              </div>

                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>

                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>

                            </div>
                            <div class="row ">
                              <div class=" py-2  col-md-2"><p>Differnce</p></div>
                              <div class="col-md text-center pe-2 ">
                                <input type="text" class="form-control "></input>
                              </div>

                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>

                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>
                              <div class="col-md text-center pe-2">
                                <input type="text" class="form-control "></input>
                              </div>

                            </div>
                          </div>
                        </div></div>
                      <div class="col-md-10">


                      </div>

                    </div>
                    <div class="">
                      <div className="text-end mt-4 "><a className="btn btn-primary mb-2" onClick={Example2}>save/Continue</a></div></div></div>
                </div></div></div> </div> : null}


          {showFuelTanker2 ? <div>
            <div class="container pb-3">
              <h5 class="modal-title" id="exampleModalLabel">Decanting MS</h5>

              <div class="row mt-md-5 mt-3 form-container">
                <div class="">
                  <div class="">
                    <div class="row mb-3 justify-content-center">

                      <div class="">
                        <div class="">
                          <div class="row">


                            <div class="mt-5" class="tab-pane fade show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">
                              <p className=""><b></b></p>
                              <div class="row ">
                                <div class="d-flex col-6">
                                  <div class="  mx-4 col-md-4"><p>Decenting Quantity </p></div>
                                  <div class="  mx-4 col-md-4"><input type="text" class="form-control"></input></div>



                                  <div class="  mx-3 col-md-5"><p>Compartment number </p></div>
                                  <div className="form-check col-md-2   ">

                                    <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    </a><label className="form-check-label " for="examplecheckboxs1">   01
                                    </label>
                                    <ReactTooltip />

                                  </div>

                                  <div className="form-check col-md-2 ">

                                    <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    <label className="form-check-label " for="examplecheckboxs1">  02
                                    </label>
                                    <ReactTooltip />
                                  </div>
                                  <div className="form-check col-2 ">

                                    <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    <label className="form-check-label " for="examplecheckboxs1">  03
                                    </label>
                                    <ReactTooltip />
                                  </div> <div className="form-check col-2 ">

                                    <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    <label className="form-check-label " for="examplecheckboxs1">  04
                                    </label>
                                    <ReactTooltip />
                                  </div>

                                </div>
                              </div>
                              <div class="row mt-5 ">
                                <div className=" d-flex mt-3">
                                  <div class="col-md-4 text-center pe-5  ">
                                    <input type="text" class="form-control " placeholder="stock"></input>
                                  </div>
                                  <div class="col-md text-center pe-2  "></div>
                                  <div class="col-md text-center pe-2  "></div>
                                  <div class="col-md text-center pe-2  "></div>
                                </div>
                              </div>
                              <div class="row mt-3 ">
                                <div className=" d-flex mt-3">
                                  <div class="col-md-4 text-center pe-5  ">
                                    <input type="text" class="form-control " placeholder="stock"></input>
                                  </div>
                                  <div class="col-md text-center pe-2  "></div>
                                  <div class="col-md text-center pe-2  "></div>
                                  <div class="col-md text-center pe-2  "></div>
                                </div>
                              </div>
                              <div>
                              </div>

                              <div class="row mt-4">
                                <div class=" py-2  col-md-3"><p>Nozzle Name(Auto) </p></div>
                                <div class=" py-2  col-md-3"><p>opening reading before decanting</p></div>
                                <div class=" py-2  col-md-3"><p>closing reading before decanting </p></div>
                                <div class=" py-2  col-md-3"><p>Differnce </p></div>
                              </div>
                              <div class="row">
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                              <div class=" mt-4  mx-4 d-flex">
                                <div class=" py-2  pe-5  mx-2 col-md-"><p>stock differnce </p></div>
                                <div class=" py-2 pe-5 col-md- mx-2"><p>Reading differnce</p></div>
                                <div class=" py-2  pe-5 col-md- " ><p>calculated quantity </p></div>
                                <div class=" py-2  pe-5  col-md-"><p>Decenting Quantity </p></div>
                                <div class=" py-2  pe-5 col-md- "><p>Excess/Short </p></div>
                              </div>
                              <div class="row">
                                <div class="col-md text-center   ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                {/* <div class="col-1  pe-1 "> +</div> */}
                                <div class="col-md text-center  ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                {/* <div class="col-1  "> =</div> */}
                                <div class="col-md text-center ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                {/* <span class="col-1  "> -</span> */}
                                <div class="col-md text-center ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center ">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                            </div>
                          </div>






                        </div>

                        <div class="col-md-10">


                        </div>
                      </div>

                    </div>
                    <div class="">
                      <div className="text-end mt-4 "><a className="btn btn-primary mb-2" onClick={Example3} >save/Continue</a></div></div>

                  </div>
                </div>
              </div>
            </div></div>
            : null}

          {showFuelTanker3 ? <div>
            <h5 class="modal-title" id="exampleModalLabel">Decanting HSD</h5>
            <div class="container pb-3">


              <div class="row mt-md-5 mt-3 form-container">
                <div class="">
                  <div class="">
                    <div class="row mb-3 justify-content-center">

                      <div class="">
                        <div class="">
                          <div class="row">


                            <div class="mt-5" class="tab-pane fade show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">
                              <p className=""><b></b></p>
                              <div class="row ">
                                <div class="  mx-4 col-md-4"><p>Decenting Quantity </p></div>
                                <div class="d-flex col-6">
                                  <div class="col-md-1 text-center   "></div>
                                  <div className="form-check col-md-4   ">

                                    <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    </a><label className="form-check-label " for="examplecheckboxs1">   Tank 1
                                    </label>
                                    <ReactTooltip />

                                  </div>
                                  <div class="col-md-1 text-center   "></div>
                                  <div className="form-check col-md-4  ">

                                    <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    </a><label className="form-check-label " for="examplecheckboxs1">   Tank 2
                                    </label>
                                    <ReactTooltip />

                                  </div>
                                  <div class="col-md-1 text-center   "></div>
                                  <div className="form-check col-md-4   ">

                                    <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    </a><label className="form-check-label " for="examplecheckboxs1">   Tank 3
                                    </label>
                                    <ReactTooltip />


                                  </div>
                                  <div class="col-md-1 text-center   "></div>
                                </div>
                                <div class="row ">
                                  <div class="col-md-1 text-center   "></div>
                                  <div class="col-md-1 text-center   "></div>
                                  <div class="col-md-1 text-center   "></div>
                                  <div class="col-md-1 text-center   "></div>
                                  <div class="col-md-1 text-center   "></div>

                                  <div class="col-md-2 text-center pe-2  ">
                                    <input type="text" class="form-control "></input>
                                  </div>
                                  <div class="col-md-2 text-center pe-2  ">
                                    <input type="text" class="form-control "></input>
                                  </div>
                                  <div class="col-md-2 text-center pe-2  ">
                                    <input type="text" class="form-control "></input>
                                  </div>

                                </div>
                                <div className=" d-flex mt-3">
                                  <div class="col-md-3 text-center pe-5  ">
                                    <input type="text" class="form-control " placeholder="stock"></input>
                                  </div>

                                  <div class="  mx-5 col-md-3"><p>Compartment number </p></div>
                                  <div className="form-check col-md-1   ">

                                    <a>  <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    </a><label className="form-check-label " for="examplecheckboxs1">   01
                                    </label>
                                    <ReactTooltip />

                                  </div>

                                  <div className="form-check col-md-1 ">

                                    <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    <label className="form-check-label " for="examplecheckboxs1">  02
                                    </label>
                                    <ReactTooltip />
                                  </div>
                                  <div className="form-check col-1 ">

                                    <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    <label className="form-check-label " for="examplecheckboxs1">  03
                                    </label>
                                    <ReactTooltip />
                                  </div> <div className="form-check col-1 ">

                                    <input className="form-check-input" type="checkbox" data-bs-target="#add-customer"
                                      id="examplecheckboxs1" />
                                    <label className="form-check-label " for="examplecheckboxs1">  04
                                    </label>
                                    <ReactTooltip />
                                  </div>

                                </div>
                              </div>
                              {/* <div class="row mt-5 ">


<div class="col-md text-center pe-2  "></div>
<div class="col-md text-center pe-2  "></div>
<div class="col-md text-center pe-2  "></div>


</div> */}




                              <div class="row mt-3 ">

                                <div class="col-md-3 text-center pe-5 ">
                                  <input type="text" class="form-control " placeholder="stock"></input>
                                </div>
                                <div class="col-md text-center pe-2  "></div>
                                <div class="col-md text-center pe-2  "></div>
                                <div class="col-md text-center pe-2  "></div>

                              </div>
                              <div>


                              </div>
                              <div class="row mt-4">
                                <div class=" py-2  col-md-3"><p>Nozzle Name(Auto) </p></div>
                                <div class=" py-2  col-md-3"><p>opening reading before decanting</p></div>
                                <div class=" py-2  col-md-3"><p>closing reading before decanting </p></div>
                                <div class=" py-2  col-md-3"><p>Differnce </p></div>
                              </div>
                              <div class="row">
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                              <div class=" mt-4  mx-4 d-flex">
                                <div class=" py-2  pe-5  mx-2 col-md-"><p>stock differnce </p></div>
                                <div class=" py-2 pe-5 col-md- mx-2"><p>Reading differnce</p></div>
                                <div class=" py-2  pe-5 col-md- " ><p>calculated quantity </p></div>
                                <div class=" py-2  pe-5  col-md-"><p>Decenting Quantity </p></div>
                                <div class=" py-2  pe-5 col-md- "><p>Excess/Short </p></div>
                              </div>
                              <div class="row">
                                <div class="col-md text-center pe-1 ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-1">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-1">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-1">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-1">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                            </div>
                          </div>






                        </div>

                        <div class="col-md-10">


                        </div>
                      </div>

                    </div>
                    <div class="">
                      <div className="text-end mt-4 "><a className="btn btn-primary mb-2" onclick={Example3} >save/Continue</a></div></div>

                  </div>
                </div>
              </div>
            </div> </div> : null}
          {showFuelTanker3 ?
            <div>
              <h5 class="modal-title" id="exampleModalLabel">Density After Unloading and Dispensing 50 LTr</h5>
              <div class="row mt-md-5 mt-3 form-container">
                <div class="col-md-12">
                  <div class="">
                    <div class="row mb-3 justify-content-center">



                      <div class="col-md-12">
                        <div class="">
                          <div class="row">


                            <div class="mt-5" class="tab-pane fade show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">

                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Product(Auto)
                                </p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " ></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "  ></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " ></input>
                                </div>
                              </div>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Tank Name(Auto)</p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control " ></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " ></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " ></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control " ></input>
                                </div>
                              </div>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>HMV </p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Temperature </p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                              <div class="row ">
                                <div class=" py-2  col-md-2"><p>Density(auto)</p></div>
                                <div class="col-md text-center pe-2 ">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>
                                <div class="col-md text-center pe-2">
                                  <input type="text" class="form-control "></input>
                                </div>

                              </div>
                            </div>
                          </div></div>
                        <div class="col-md-10">


                        </div>

                      </div>
                      <div class="">
                        <div className="text-end mt-4 "><a className="btn btn-primary mb-2"  >save/Continue</a></div></div></div>
                  </div></div></div>

              <div class="col-md modal-footer"><a className="btn btn-success mb-2"  >print</a></div>


            </div> : null}
        </div></div>

    </React.Fragment>
  );
}

export default FuelUnModal;