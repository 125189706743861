import React from 'react';

const MlaCouponModal = ({ preventNonNumericalInput,isShowing, hide, errors,stat_operation, inputValues,double, dropdownData, handleSubmit, handleInputs }) => {

  return (
  <React.Fragment>

<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal">
        <div className="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">MLA Coupon Configuration</h5>
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="row justify-content-center py-4">
    <label class="col-sm-5 col-form-label mb-3">Do you have MLA Coupon?  <sup class="star">*</sup>   </label>
    <div class="col-sm-4 mb-3 ">
    <div class="select-container highlight"><select  class="form-control" name="mla_coupon" value={inputValues.mla_coupon} onChange={handleInputs}><option value="">select</option><option selected={inputValues.id} value="yes">Yes</option><option value="no">No</option></select></div>
    <div class="errorMsg" >{errors.mla_coupon}</div>
    </div>
    {/* {console.log(stat_operation,"Ouy")}  */}
    { inputValues.mla_coupon =="yes" && 
  <>
{stat_operation != "Yes" ?
      <div class="col-sm-10 ">
      <input type="number" class="form-control mb-3 hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,inputValues.opening_balance)}  name="opening_balance"   readOnly={inputValues["ob_is_dayclose"]} value={inputValues.opening_balance} onChange={handleInputs} placeholder="Opening Balance"/>
      
      <div className="errorMsg">{errors.opening_balance}</div>
      <span  class="amount-words"></span>
    </div> 
     :""}
    </>}
    
     
    
    <div class="modal-footer text-center">
     
   {double==true?<button type="submit" class="btn btn-primary w-auto" onClick={handleSubmit}>Save MLA Coupon Configuration</button>:<button type="submit" class="btn btn-primary w-auto" disabled>Save MLA Coupon Configuration</button>}
   </div>
  </div>
     
        
      </div>
    
         </div>
  
              

      
    
</React.Fragment>   
  );
        }
             export default MlaCouponModal;