import { Component } from "react";
import inhand from '../../../img/inhand.png'
import store from '../../../img/store.png'
import money from '../../../img/money.png'
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { API_URL } from '../../../constant/API_Settings';
import { useState } from 'react';
import FuelLoginHeaderOperational from './FuelLoginHeader.component';
import Sidebar_Progress from "../../layouts/Operational_Sidebar_Progress.component";
import FuelEditModal from "./FuelEditModal.component";
import FuelTabHeader from "./FuelTabHeader.Component";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import TankEditModal from './TankEditModal.Component'
import Modal from "react-modal";
import Loader from "../Employee/Loader.component";
import { preventNonNumericalInput, prevNonNumericalWithDec } from '../../layouts/Operational_Sidebar_Progress.component'
import PaginationComponent from '../../layouts/PaginationComponent';
const PaginationComp = (props) => (
   < PaginationComponent  {...props} />
);
const FuelModal = (props) => ReactDOM.createPortal(
   <FuelEditModal  {...props} />, document.body
);
const TankModal = (props) => ReactDOM.createPortal(
   <TankEditModal  {...props} />, document.body
);
const FuelEdit = (props, progress) => {
   // -----------------state declare----------------//
   const [list_data, setList] = useState([])
   const [inputValues, setInputValues] = useState({});
   const [double, setDouble] = useState(true);
   const [loading, setLoading] = useState(false)
   const [nozzle, setNozzle] = useState([])
   const [fuelId, SetFuelId] = useState([])
   const [dropdownData, setDropdownData] = useState([])
   const [success, setSuccess] = useState([])
   const history = useHistory()
   const [errors, setErrors] = useState({})
   const [permission, setPermission] = useState([])
   const [pages, setpages] = useState(
      {
         current_page: 1,
         last_page: 0,
         total_pages: 0,
         pagination_item: [],
         total_pages: 0,
      })

   const [delete_id, deleteId] = useState([])

   //---------------onchange function-----------------//
   const handlePagination = (number) => {
      setpages({
         ...pages,
         current_page: number        //---pagination---//
      }
      )

      ListFuelEdit(number)
   }
   const handleSelect = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleInputs = (e) => {
      var nozzle = []
      for (var i = 1; i <= e.target.value; i++) {
         nozzle.push({
            nozzle_name: '',
            reading: ''
         })
      }
      setNozzle(nozzle)
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleInput = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const handleChange = (e) => {
      setInputValues({
         ...inputValues,
         [e.target.name]: e.target.value
      })
   }
   const [search, setSearch] = useState('')
   const handleSearch = (event) => {
      setSearch(event.target.value)
   }
   // ---handlesubmit function---//
   const handleSubmit = (event) => {
      event.preventDefault();
      setDouble(false);
      sendFuelEdit()

   }
   const handleChange1 = (e) => {
      let stock = e.target.value

      //  var stock1= parseFloat(stock).toFixed(2)
      setInputValues({
         ...inputValues,
         [e.target.name]: stock
      })
   }
   //---On Load---//		


   useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
         if (search !== null || search === "") {
            ListFuelEdit(1)

         }
      }, 500);

      return () => clearTimeout(delayDebounceFn);
   }, []);
   function check_url() {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            pathurl: window.location.pathname,
         }),
      };
      fetch(`${API_URL}/check_url`, requestOptions)
         .then(response => { return response.json(); })

         .then(responseData => {
            if (responseData.allowurl == 1) {
               history.push(responseData.url);
            }
            else {
               history.goBack();
            }

            return responseData;
         })
         .catch(e => {
            console.log(e)
         });

   }
   //------------------------modal--------------------//
   const UseModal = () => {

      const [isShowing, setIsShowing] = useState(false);

      function toggle() {
         if (isShowing) {
            setInputValues([])
            setNozzle([])
            setErrors([])
         }
         if (!isShowing) {
            setDouble(true)


         }
         setIsShowing(!isShowing);
      }

      return {
         isShowing,
         toggle,
      }
   };
   const { isShowing, toggle } = UseModal();
   const UseTankModal = () => {

      const [isShow, setShowing] = useState(false);

      function toggling() {


         if (isShow) {
            setInputValues([])
            setNozzle([])
         }
         if (!isShow) {
            setDouble(true)


         }
         setShowing(!isShow);
      }

      return {
         isShow,
         toggling,
      }
   };
   const { isShow, toggling } = UseTankModal();
   //-------------Save (akhitha-15/7/2021)--------------//
   function sendFuelEdit() {
      console.log(inputValues,"ollll")

      const requestOptions = {
         method: 'POST',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify(inputValues)
      };
      fetch(API_URL + "/op-fuel-configuration", requestOptions)
         .then(response => {
          
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            console.log(responseData,"okkkkk")
            if (responseData.status == false) {
               errors["fuel_id"] = responseData.response.fuel_id
               errors["capacity"] = responseData.response.capacity
               errors["no_of_nozzles"] = responseData.response.no_of_nozzles
               errors["tank_name"] = responseData.response.tank_name
               errors["fuel_price"]=responseData.response.selling_price
               errors["nozzle_name"] = responseData.response.nozzle_name
               errors["reading"] = responseData.response.reading
               setDouble(true)

            }
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")
               setDouble(true)

            }

            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               history.push("/op-fuel-edit")
               setErrors([''])
               inputValues.fuel_id = ""
               setInputValues(inputValues)
               toggle();
               ListFuelEdit(pages.current_page);
               setDouble(true)
            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }

   const handleNozzleChange = (e, idx, prop) => {
      inputValues[prop] = { ...inputValues[prop], [idx]: e.target.value };
      console.log(inputValues)
   }

   const handleTankSubmit = (event) => {
      event.preventDefault();
      setDouble(false)
      sendTankEdit()

   }
   function sendTankEdit() {

      const requestOptions = {
         method: 'POST',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            capacity: inputValues.capacity,
            diameter: inputValues.diameter,
            length: inputValues.length,
            no_of_nozzles: inputValues.no_of_nozzles,
            nozzle_name: inputValues.nozzle_name,
            reading: inputValues.reading,
            tank_name: inputValues.tank_name,
            
            current_stock: inputValues.current_stock,
            last_purchase_price: inputValues.last_purchase_price,
            selling_price: inputValues.selling_price,
            fuel_id: fuelId.fuel_id
         })
      };
      fetch(API_URL + "/op-tank-configuration", requestOptions)
         .then(response => {
            return response.json();
         })
         .then(responseData => {

            setSuccess({
               ...success,
               disabled: responseData.status === true ? true : false,
            })
            let errors = {}
            if (responseData.status == false) {
               errors["capacity"] = responseData.response.capacity
               errors["no_of_nozzles"] = responseData.response.no_of_nozzles
               errors["tank_name"] = responseData.response.tank_name
               errors["nozzle_name"] = responseData.response.nozzle_name
               errors["reading"] = responseData.response.reading
               

               setDouble(true)
            }
            if (responseData.status === 3) {
               swal("", responseData.message, "warning")
               setDouble(true)

            }

            if (responseData.status == true) {
               swal("", responseData.message, responseData.status ? "success" : "warning")
               history.push("/op-fuel-edit")
               setErrors([''])
               inputValues.fuel_id = ""
               inputValues.capacity = ""
               inputValues.diameter = ""
               inputValues.length = ""
               inputValues.no_of_nozzles = ""
               inputValues.nozzle_name = ""
               inputValues.reading = ""
               inputValues.tank_name = ""
               inputValues.fuel_price=""
               inputValues.current_stock = ""
               inputValues.last_purchase_price = ""
               fuelId.fuel_id = ""
               inputValues.selling_price = ""
               setInputValues(inputValues)
               toggling();
               ListFuelEdit(pages.current_page);

            }
            setErrors(errors)
            return responseData;
         })
         .catch(e => {
            setDouble(true)
         });
   }



   // ------------Listing (akhitha-15/7/2021)---------//
   function ListFuelEdit(page) {
      check_url()
      const requestOptions = {
         method: 'get',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },

      };
      fetch(`${API_URL}/op-fuel-configuration?search_keyword=${search}&page=${page}`, requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
          
            console.log(responseData.message)
            if (responseData.status == 3) {
               setPermission({ ...permission, status: responseData.status, message: responseData.message })
            }
            setList(responseData.fuelData.data)
            var datas = []
            responseData.fuelType.forEach(elem => {
               datas.push([elem.id, elem.fuel_name])
            })
            setDropdownData(datas);
            console.log(responseData)

            setpages({
               ...pages,
               current_page: responseData.fuelData.current_page,
               last_page: responseData.fuelData.last_page,
               per_pages: responseData.fuelData.per_page,
               total_pages: responseData.fuelData.total,

            })

            setLoading(true)
            return responseData;
         })
         .catch(e => {


         });
   }


   function check_url() {
      const requestOptions = {
         method: 'post',
         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"
         },
         body: JSON.stringify({
            pathurl: window.location.pathname,
         }),
      };
      fetch(`${API_URL}/check_url`, requestOptions)
         .then(response => { return response.json(); })

         .then(responseData => {
            if (responseData.allowurl == 1) {
               history.push(responseData.url);

            }
            else {
               history.goBack();
            }

            return responseData;
         })
         .catch(e => {
            console.log(e)
         });

   }
   //------------------tank edit-----------------//
   function TankEdit(id, fuel_name) {

      SetFuelId({
         ...fuelId,
         fuel_id: id,
         fuel_name: fuel_name
      })

      toggling()
   }
   function deleteFuel(id) {
      deleteId(id)

      toggleModal()
   }
   const [isOpen, setIsOpen] = useState(false);
   function toggleModal() {
      setIsOpen(!isOpen);
   }
   function deleteFuelAction() {
      setDouble(false)
      const requestOptions = {
         method: 'DELETE',

         headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
            "Accept": "application/json"

         },
         body: JSON.stringify(delete_id)
      };
      fetch(API_URL + "/op-fuel-configuration/" + delete_id, requestOptions)
         .then(response => { return response.json(); })
         .then(responseData => {
            if (responseData.status === true) {
               setDouble(true)
               swal("", responseData.message, responseData.status ? "success" : "warning")
            }

            if (responseData.status === 3) {
               setDouble(true)
               swal("", responseData.message, "warning")
            }
            ListFuelEdit(pages.current_page);
            toggleModal()

            return responseData;


         })

         .catch(err => {

         })
   }




   return (
      <>
         <div className="main-container page-wrap">
            <div className="d-md-flex">
               <Sidebar_Progress props={props} progress={30} operation={1} />
               <div className="content-area ">
                  <FuelLoginHeaderOperational props={props} page_title="" />
                  <div className="col-md-11 mt-1"><Link to="/nozzle-employee-list">
	<a href="/nozzle-employee-list" class="float-end btn btn-gray" >Back</a></Link>
	</div>

                  <div className="container pb-3 tab-view">
                     <div className="row mt-md-5 mt-3 form-container">
                        <div className="col-md-12">
                        <div className="form-wrap ">
                           <FuelTabHeader tab={1} />
                           {permission.status == 3 ?
                              <div className="form-wrap mt-5">
                                 <div className="text-center">
                                    {permission.message}
                                 </div></div> :
                              <>
                              

                                 <div>
                                    {loading ? ListFuelEdit : <Loader />}
                                    {/* <div className="form-wrap "> */}
                                    <div className="tab-content" id="myTabContent" >
                                       <div className="tab-pane fade show active" id="creditsales" role="tabpanel" aria-labelledby="creditsales-tab">
                                          <div className="">
                                             <div className="row mb-3">
                                                <div className="col-md">
                                                   <div className="row">

                                                      <div className="col-md-2 col pl-0">
                                                         <h3 className="table-title">Fuel</h3>
                                                      </div>
                                                      <div className="col-md col pl-0">
                                                         <a href="#" className="btn btn-primary" onClick={toggle}>Add New</a>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-md-4">
                                                   <div className="input-group mb-3 search">
                                                      <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
                                                      </span>
                                                      <input type="text" className="form-control" placeholder="Search With Fuel Name " value={search.search_keyword} onChange={handleSearch} />
                                                      <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={ListFuelEdit} >Submit</a>

                                                   </div>
                                                </div>
                                             </div>
                                             <div className="table-widget">
                                                <table className="table border">
                                                   <thead>
                                                      <tr>
                                                         <th> Name</th>
                                                         <th>Tank Name </th>
                                                         <th>Action</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {
                                                         list_data.map(obj => (

                                                            <tr>
                                                               <td>{obj.fuel_name}</td>
                                                               <td>{obj.tankName ? obj.tankName : <a href="#" onClick={() => TankEdit(obj.fuel_id, obj.fuel_name)}>Click here to add Tank</a>}</td>
                                                               <td><a href="#" className="text-secondary" onClick={() => deleteFuel(obj.id)}><i className="fa fa-trash-o" aria-hidden="true"></i></a></td>
                                                            </tr>
                                                         ))}
                                                   </tbody>
                                                </table>
                                                {loading ? <PaginationComp
                                                   pages={pages}
                                                   handlePagination={handlePagination}
                                                /> : ""
                                                }
                                             </div>
                                             <div>

                                             </div>

                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </>}
                              </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {isShowing ? (<FuelModal
            isShowing={isShowing}
            hide={toggle}
            handleInput={handleInput}
            handleChange={handleChange}
            handleChange1={handleChange1}
            preventNonNumericalInput={preventNonNumericalInput}
            prevNonNumericalWithDec={prevNonNumericalWithDec}
            handleSelect={handleSelect}
            handleSubmit={handleSubmit}
            handleInputs={handleInputs}
            dropdownData={dropdownData}
            nozzle={nozzle}
            errors={errors}
            double={double}
            handleNozzleChange={handleNozzleChange}
            inputValues={inputValues}
            fuelId={fuelId}
         />) : null
         }
         {isShow ? (<TankModal
            isShow={isShow}
            hide={toggling}
            handleInput={handleInput}
            handleSelect={handleSelect}
            handleSubmit={handleSubmit}
            double={double}
            handleInputs={handleInputs}
            handleChange={handleChange}
            preventNonNumericalInput={preventNonNumericalInput}
            prevNonNumericalWithDec={prevNonNumericalWithDec}
            handleNozzleChange={handleNozzleChange}
            handleTankSubmit={handleTankSubmit}
            dropdownData={dropdownData}
            fuelId={fuelId}
            nozzle={nozzle}
            errors={errors}
            inputValues={inputValues}
         />) : null
         }
         <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal custommodal"
            overlayClassName="myoverlay"
         >
            <div class="modal-content">
               <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">Fuel</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
               <div class=" modal-body  modal-body"><p>Are you sure you want to delete this Fuel</p></div>
               <div class="justify-content-end  modal-footer"><div class="d-flex">
                  <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
                  {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteFuelAction}>Yes</button> : <button type="submit" class="btn btn-primary" disabled onClick={deleteFuelAction}>Yes</button>}
               </div>
               </div>
            </div>




         </Modal>
      </>
   )
}


export default FuelEdit;

