
import React from 'react';
const RoleModal = ({ isShowing, hide, success, errors, inputValues, isDisabled, handleSubmit, handleInputs, permissions, handleCheckBox, isChecked }) => {
  return (
    <React.Fragment>
      <div className="modal-overlay" />
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modalxl">
          <div className="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Role Permission</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="row mb-6 justify-content-center py-4 role">
            <label className="col-sm-2 col-form-label mb-2">Role Name</label>
            <div className="col-sm-6 d-flex">
              <input type="text" className="form-control mb-2" id="role_name" name="role_name" placeholder="" value={inputValues.role_name} onChange={handleInputs} />
            </div>

            <div className="col-sm-6 d-flex errorMsg"> {errors.role_name} </div>
          </div>
          <div class="row m-0 align-items-center ">
            {permissions.map((data, index) => {
              if (isChecked.includes(data.id)) {
                return (
                  <>
                    <div className="col-md-4">
                      <div className="d-flex align-items-center">
                        <input type="checkbox" className="me-2" name="permission_name" value={data.id} defaultChecked onChange={event => { handleCheckBox(event, index, event.target.name) }} />
                        <label className="form-check-label" htmlFor="flexCheckDefault">{data.permission_name} </label>
                      </div>
                    </div>
                  </>

                )
              }
              else {
                return (

                  <div className="col-md-4">
                    <div className="d-flex align-items-center">
                      <input type="checkbox" className="me-2" name="permission_name" value={data.id} onChange={event => { handleCheckBox(event, index, event.target.name) }} />
                      <label className="form-check-label" htmlFor="flexCheckDefault">{data.permission_name} </label>
                    </div>
                  </div>
                )
              }
            })}
            <div className="col-sm-6 d-flex errorMsg"> {errors.permission_err} </div>
          </div>


          <div className="modal-footer ">
            {success.disabled === true ? <button className="btn btn-primary w-auto px-4" disabled type="button" onClick={handleSubmit}>Save</button> : <button className="btn btn-primary w-auto px-4" type="button" onClick={handleSubmit}>Save</button>}

          </div>
        </div>

      </div>
    </React.Fragment>
  );
}
export default RoleModal;