
import { Link, useLocation } from "react-router-dom";
import Pagination from "react-js-pagination";
import { useEffect } from 'react';
import React, { useState } from 'react';

const PaginationComponent = ({ pages,handlePagination }) => {
	return (
<div className="pagination mt-4 justify-content-end">
                                                 {pages.total_pages > 10 &&
													    <Pagination
														activePage={ pages.current_page}
														itemClass="123"
														itemsCountPerPage={ pages.per_pages }
														totalItemsCount={pages.total_pages || 1}
														pageRangeDisplayed={10}
														onChange={handlePagination}
														/> }
  </div>

  )
}


export default PaginationComponent