import { Component } from "react";

import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from "axios";
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import Name from './Name.component'
import { data } from "jquery";
import { Link } from "react-router-dom";
import Loader from '../operational-model/Employee/Loader.component'
import { prevNonNumericalWithDec } from '../layouts/Sidebar_Progress.component'
class ProductRack extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
       rack_configs:[],
       rack1_configs:"",
       rack:[],
       types:[],
       fields:{},
       errors:{},
       err:[],
       rackdata:[],
       godown_stock:'',
       total_stock:'',
       loading:false,
       name_component: [],
          }
          this.handleChange = this.handleChange.bind(this)
          this.handleRackChange = this.handleRackChange.bind(this)
    }


    //------------------------url
 check_url=()=> {
	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
		
			if(responseData.allowurl == 1)
			{
				this.history.push(responseData.url);
				this.check_meta_date();
			}
			else{
				
				this.history.goBack();
			}

			return responseData;
		})
		.catch(e => {
		});

}
check_meta_date=() =>{

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
		});

}
//--------------------------//
    
handleRackChange= (event,index,prop) =>
	{ 
	var { rackdata } = this.state
    var {rack_configs} = this.state
  
  
        rack_configs[index]["stock"] = event.target.value

    var racks = rackdata
    var amount = 0
  
for (var i=0;i<racks.length;i++)
{

    var currentValue = document.getElementById('rack_stock'+i).value;
 
      amount = Number(amount)+Number(currentValue)

      if(Number(amount) > Number(this.state.rack1_configs))
      {
     var stock =  this.state.rack1_configs - amount
      }
      else
      {
        var stock =   this.state.rack1_configs -amount
       
      }

    var godwon_stock = stock.toFixed(2)
}
 this.state.godown_stock = godwon_stock

      var racks = rackdata[index]
   
	  racks = {...racks, [prop]:event.target.value};

	  rackdata[index] = racks
	this.setState({rackdata: rackdata,rack_configs:rack_configs
    })
	}
    handleChange = (event) => {
  
 
           this.setState({
      [event.target.name]: event.target.value,
  
   })
}

     
	handleSelect = (event) =>
	{
        var id =  event.target.value;
        var {rack_configs} = this.state
        var i=0
        rack_configs.map(elem=>{
            console.log("Sf")
            console.log(rack_configs)
        rack_configs[i]['stock']=""
        
        i=i+1
    })
    this.setState({rack_configs:rack_configs})
        axios(
			{
				url: API_URL+'/rack-config/'+id,
				method: "get",
				headers: {
				   "Access-Control-Allow-Origin": "*",
				   "Content-Type":"application/json",
				   "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
				   "Accept": "application/json"
			   }
			}
		).then(res=>
		{ 
			var { rack1_configs,godown_stock,rack_configs,rackdata} = this.state
          var {stock } = this.state
  
         if(event.target.value !="0")
         {
            
            rack1_configs=res.data.non_fuel_product.total_stock
			stock = res.data.non_fuel_product.total_stock
			godown_stock = res.data.non_fuel_product.godown_stock
            if(res?.data?.non_fuel_stock.length !=0) 
            {
                console.log("Fdg")
                var i=0
                res.data.non_fuel_stock.forEach(elem=>{
                rack_configs[i]['stock']=elem.stock
                rackdata[i]['rack_stock']=elem.stock
           
                i=i+1
            })
        }
        else
        { 
            console.log("gfh")
            godown_stock = stock;
            var i=0
            rack_configs.map(elem=>{
            rack_configs[i]['stock']=""
            rackdata[i]['rack_stock']=""
            
            i=i+1
        })
            
        }  
         }
         else
        {
           
            rack1_configs=""
            stock=""
            godown_stock=""
        //        this.state.rack_configs.map((rack,i)=>{
        //     document.getElementById('rack_stock'+i).value=""
        // })
        }
      

          

				this.setState({rack1_configs:rack1_configs,productid:id ,stock:stock,godown_stock:godown_stock ,rack_configs:rack_configs,rackdata:rackdata})
		
               
		}).catch(error=>
		{
			console.log(error)
		})

	
	}
    componentDidMount()
	{ 
	  
        
		axios(
			{
				url: API_URL+'/rack-config',
				method: "get",
				headers: {
				   "Access-Control-Allow-Origin": "*",
				   "Content-Type":"application/json",
				   "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
				   "Accept": "application/json"
			   }
			}
		).then(res=>
		{  
			var { rack_configs,rackdata} = this.state

			res.data.rack_data.forEach(elem=>{
                
                rackdata.push({
                    id:elem.id
                })
                
				rack_configs.push({
					rack_name: elem.rack_name,
					 id:elem.id,
                     stock:""
					})
                  
				})
			
				this.setState({rack_configs:rack_configs })
                this.setState({
                    'loading':true
                })
		
		}).catch(error=>
		{
			console.log(error)
		})
        
		axios(
			{
				url: API_URL+'/get-products',
            
				method: "get",
				headers: {
				   "Access-Control-Allow-Origin": "*",
				   "Content-Type":"application/json",
				   "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
				   "Accept": "application/json"
			   }
			}
		).then(res=>
		{  
            var types = []
            this.setState({types: res.data})
            // this.setState({productid:res.data[0].id})
            
            res.data.map(product=>{
                 types.push(<Name value={`types_${product.id}`} id={product.id}  product_name={product.product_name} />)
            })
           
        
       this.setState({name_component:types})
		
		}).catch(error=>
		{
			console.log(error)
		})
		
	
	}

    handleSubmit = () => {

        if(this.validateForm()){      
        var {rackdata} =this.state
      var data ={
            godown_stock:this.state.stock,
            rack_data:rackdata,
            nf_product_id:this.state.productid,
            total_stock:this.state.rack1_configs
           }
    axios(
        {
            url: API_URL + '/save-product-rack',
            method: "POST",
            data: data,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type":"application/json",
                "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
                "Accept": "application/json"
            }
        }).then(res => {
            var errors={}
            if(res.data.status==false)
            {
                errors["godown_stock"] = res.data.message
            }
            this.setState({
                status: res.data.status ? "success" : "danger",
               errors:errors
            }, () => {

                // if(res.data.status===false)
                // {
                //     swal("",res.data.message,res.data.status?"success":"danger")
                // }
            
                
                if(res.data.status===true)
                {
                   
                 swal("",res.data.message,res.data.status?"success":"danger")
                 this.props.history.push("/rack-product-list")
                  }  

            })
        }).catch(error => {
            console.log(error)
        })
    }
}
validateForm() {
    var {rackdata} =this.state
    let err = [];
    let formIsValid = true;
 
      for(var i=0;i<rackdata.length;i++)
       if(rackdata[i].rack_stock <0)
       {
        formIsValid = false;
        swal("","Rack stock should be greater than zero","warning")
       }

    this.setState({
        err: err
    });
    return formIsValid;


}

    render()
    {
        return(
            <>
       <div className="main-container page-wrap">
    <div className="d-md-flex">
    <Sidebar_Progress progress={3}/>
        <div className="content-area">
    <LoginHeader page_title="" />
    { this.state.loading ?  this.componentDidMount: 
    // <div class="loader text-primary">Loading...</div>
	<Loader/>}
	
    <div className="container pb-3 ">
        <div className="row justify-content-center  mt-3 form-container">
        <div class="col-md-12 mb-3"><Link  to="/rack-product-list"><a href="/rack-product-list" class="float-end btn btn-gray">Back</a></Link></div>
            <div className="col-md-12">
                
    
                <div className="form-wrap form-content-sm">
                    <h2 className="mb-4">Non Fuel Product Transfer To Rack</h2>
                    
                    <div className="card p-5 mx-5 shadow">
                    <div className="row mb-3 justify-content-center">
                        <div className="col-sm-4">
        <label  className=" col-form-label">Product Name</label>
    </div>
    <div className=" select-container  col-sm-5">
	<select   className="form-control"  value={this.state.product_name} onChange={this.handleSelect}>
	<option value="0">Select</option> 
    {
                        
                        this.state.name_component.map(component => {
                        return(component)
                         })
                     }             
	  </select></div>
  
   </div>

        <div className="row mb-3 justify-content-center">
                        <div className="col-sm-4">
        <label  className=" col-form-label">Total Stock</label>
    </div>
        
        <div className="col-sm-5">
          <input type="text" className="form-control"  name="total_stock" defaultValue={this.state.rack1_configs ? this.state.rack1_configs : ''} onChange={this.handleRackChange}  readOnly/>
        </div>
      
    </div>

    <div className="row mb-3 justify-content-center">
                        <div className="col-sm-4">
        <label  className=" col-form-label">Main Godown Stock</label>
    </div>
        
        <div className="col-sm-5">
          <input type="text" className="form-control"  name="godown_stock" id="stock" value={this.state.godown_stock} onChange={this.handleChange} readOnly />
          <div className="errorMsg">{this.state.errors.godown_stock}</div>
        </div>
       
    </div>
   
    {
	this.state.rack_configs.map((rack,index) => {
	return (
    <div className="row mb-3 justify-content-center">
                        <div className="col-sm-4">
        <label  className=" col-form-label">{rack.rack_name}</label>
    </div>
        <div className="col-sm-5">
          <input type="number" className="form-control hide-arrow" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="rack_stock" id={"rack_stock"+index} value={rack.stock } onChange={(event)=>this.handleRackChange(event,index,event.target.name)} />
          <div className="errorMsg">{this.state.err}</div>

        </div>
                              
    </div>
  
                            )})}
  
        <div className="col-12 text-center ">
                <a className="btn btn-primary w-auto"  onClick={this.handleSubmit}>Save and continue</a>
            </div>
      
    
    </div>
                   
            
                </div>
                
            </div>
        </div>
    </div>
        </div>
        
    </div>
    </div>
    

    </>
        )
    }
    }

export default ProductRack